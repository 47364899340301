import { Fuel, Meter, ReadingFrequency } from '@/src/types/Response';

export const isSmartMeterType = (meterType: string | null) =>
  !!meterType &&
  (meterType.startsWith('S1') ||
    meterType.startsWith('S2') ||
    meterType.startsWith('2'));

export const isSMETS2MeterType = (meterType: string | null) =>
  !!meterType && (meterType.startsWith('S2') || meterType.startsWith('2'));

export const isHalfHourlySmartMeterReadsEnabled = (meters: Meter[]) => {
  const elecMeters = meters.filter(
    meter => meter.fuelType === Fuel.ELECTRICITY,
  );
  return (
    (Boolean(elecMeters.length) &&
      elecMeters.every(
        meter => meter.readingFrequency === ReadingFrequency.HALFHOURLY,
      )) ||
    false
  );
};
