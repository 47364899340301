import React from 'react';
import { GreenerEnergyContent } from './GreenerEnergyContent/GreenerEnergyContent';
import { GreenerEnergySignup } from './GreenerEnergySignup/GreenerEnergySignup';
import { SectionWrapper } from './GreenerEnergySignup/GreenerEnergySignup.styled';
import { SignupSuccess } from './GreenerEnergySignup/components/GreenerEnergySignupSuccess';
import { PageWithNavigation } from '@/src/components';

export const GreenerEnergy = () => (
  <PageWithNavigation title="Greener Energy">
    <GreenerEnergyContent />
  </PageWithNavigation>
);

export const GreenerEnergySignupPage = () => (
  <PageWithNavigation title="Greener Energy Signup">
    <SectionWrapper>
      <GreenerEnergySignup />
    </SectionWrapper>
  </PageWithNavigation>
);

export const GreenerEnergySignupSuccess = () => (
  <PageWithNavigation title="Greener Energy Signup Success">
    <SignupSuccess />
  </PageWithNavigation>
);
