import { Card, Heading3, Margin } from '@ovotech/nebula';
import React from 'react';
import { ErrorSection, FlexChild } from '@/src/components';
import { ACCOUNT_INFO_ERROR } from '@/src/constants/error-section-ids';
import { BalanceType, useBalance } from '@/src/pages/Payments/hooks/useBalance';
import { PaymentsCollectableBalanceSummary } from '@/src/pages/Payments/components/AccountPaymentSummary/PaymentsCollectableBalanceSummary';
import { PaymentsLiveBalanceSummary } from '@/src/pages/Payments/components/AccountPaymentSummary/PaymentsLiveBalanceSummary';
import { AccountInfoLoading } from '@/src/pages/Payments/components/AccountPaymentSummary/AccountInfoLoading';

const Error = () => (
  <FlexChild as={Card} data-testid="account-info-error-message">
    <ErrorSection headingComponent={Heading3} id={ACCOUNT_INFO_ERROR} />
  </FlexChild>
);

const AccountPaymentSummary = ({
  displayLastDD = true,
}: {
  displayLastDD?: boolean;
}) => {
  const { balanceType, collectableBalance, liveBalance, isLoading, isError } =
    useBalance();

  if (isLoading) {
    return <AccountInfoLoading />;
  }

  if (isError) {
    return <Error />;
  }

  const balance =
    balanceType === BalanceType.CollectableBalance && collectableBalance
      ? collectableBalance
      : liveBalance;

  if (!balance) {
    return null;
  }

  return (
    <Margin bottom={2}>
      {balanceType === BalanceType.CollectableBalance ? (
        <PaymentsCollectableBalanceSummary collectableBalance={balance} />
      ) : (
        <PaymentsLiveBalanceSummary
          liveBalance={balance}
          displayLastDD={displayLastDD}
        />
      )}
    </Margin>
  );
};

export default AccountPaymentSummary;
