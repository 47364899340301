import React from 'react';
import { Route, Redirect } from 'react-router-dom-v5';
import { CardPaymentSession } from './CardPaymentSession';
import { SwitchWithNotFound } from '@/src/components';
import {
  ROUTE_MAKE_CARD_PAYMENT,
  ROUTE_MAKE_CARD_PAYMENT_CONFIRM_FROM_APP,
  ROUTE_PAYMENTS,
} from '@/src/constants/routes';

export const CardPaymentSessionRoutes = () => (
  <SwitchWithNotFound>
    <Route
      exact
      path={ROUTE_MAKE_CARD_PAYMENT_CONFIRM_FROM_APP}
      component={() => <Redirect to={ROUTE_PAYMENTS} />}
    />
    <Route
      exact
      path={ROUTE_MAKE_CARD_PAYMENT}
      component={CardPaymentSession}
    />
  </SwitchWithNotFound>
);
