import {
  Card,
  Col,
  Heading2,
  Heading3,
  Margin,
  Row,
  Skeleton,
  SkeletonCTA,
  SkeletonHeading,
  SkeletonText,
} from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';

const StyledSkeletonHeading = styled(SkeletonHeading)<{ size: string }>(
  ({ theme, size }) => `
    background-color: ${theme.colors.borderMuted};
    width: ${size};
    height: ${theme.space[30]};
    padding: ${theme.space[8]}
  `,
);

const StyledSkeleton = styled(Skeleton)(
  ({ theme }) => `
    background-color: ${theme.colors.borderMuted};
    height: 200px;
  `,
);

const StyledSkeletonPrice = styled(Skeleton)(
  ({ theme }) => `
    background-color: ${theme.colors.borderMuted};
    width: ${theme.space[24]};
    height: ${theme.space[12]};
  `,
);

const StyledCard = styled(Card)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.space[4]};
  `,
);

export const OffersLoading = () => (
  <Margin data-testid="offers-loading" top={8}>
    <Card>
      <StyledSkeletonHeading size="100%" />
    </Card>
    <br />
    <Row isNested>
      <Col medium={6}>
        <StyledSkeleton />
        <StyledCard>
          <SkeletonHeading size="100%" headingComponent={Heading2} />
          <SkeletonText lines={2} />
          <SkeletonHeading size="100%" headingComponent={Heading3} />
          <SkeletonText lines={3} />
          <StyledSkeletonPrice />
          <SkeletonCTA />
        </StyledCard>
      </Col>
      <Col medium={6}>
        <StyledSkeleton />
        <StyledCard>
          <SkeletonHeading size="100%" headingComponent={Heading2} />
          <SkeletonText lines={2} />
          <SkeletonHeading size="100%" headingComponent={Heading3} />
          <SkeletonText lines={3} />
          <StyledSkeletonPrice />
          <SkeletonCTA />
        </StyledCard>
      </Col>
    </Row>
  </Margin>
);
