import { Row, Col } from '@ovotech/nebula';
import React, { forwardRef, ReactNode } from 'react';
import { StyledSection } from './Section.styled';

type Props = {
  children: ReactNode;
  isNested?: boolean;
  [key: string]: any;
};

const Section = forwardRef<HTMLElement, Props>(
  ({ children, isNested = true, ...rest }: Props, ref) => (
    <StyledSection {...rest} ref={ref}>
      <Row isNested={isNested}>
        <Col>{children}</Col>
      </Row>
    </StyledSection>
  ),
);

export default Section;
