import Big from 'big.js';
import { isEqual, startOfMonth } from 'date-fns';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  TextProps,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';

import { withTheme } from 'styled-components';

import ResponsiveChartWrapper, {
  ResponsiveChartRenderProps,
} from '../ResponsiveChartWrapper/ResponsiveChartWrapper';
import {
  StyledCostIndicator,
  StyledPredictedCostChart,
  StyledPredictedCostTooltip,
} from './ProjectedCostChart.styled';
import { Theme } from '@/src/types/Theme';

import { breakpoints } from '@/src/utils/mediaQuery';
import setSvgAttributes from '@/src/utils/set-svg-attributes';

export type ProjectedCostChartData = {
  electricityCost: null | Big;
  gasCost: null | Big;
  boltOnCost: null | Big;
  date: string;
  days: number;
};

type Props = {
  data: Array<ProjectedCostChartData>;
  recommendedDirectDebit: number;
  currentDirectDebit?: number;
  theme: Theme;
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<{ payload?: ProjectedCostChartData }>;
}) => {
  if (!active || !payload || !payload[0]?.payload) {
    return null;
  }

  const { date, days, electricityCost, gasCost, boltOnCost } =
    payload[0].payload;

  const isCurrentMonth = isEqual(date, startOfMonth(Date.now()));

  return (
    <StyledPredictedCostTooltip>
      <dl>
        <dt>{date}</dt>
        <dd>
          {days} day(s) {isCurrentMonth ? 'left' : ''}
        </dd>
        <br />
        {gasCost && (
          <dd>
            <StyledCostIndicator cost="gas" /> Gas: &pound;{gasCost.toFixed(2)}
          </dd>
        )}
        {electricityCost && (
          <dd>
            <StyledCostIndicator cost="electricity" /> Electricity: &pound;
            {electricityCost.toFixed(2)}
          </dd>
        )}
        {boltOnCost && (
          <dd>
            <StyledCostIndicator cost="boltOns" /> Upgrades: &pound;
            {boltOnCost.toFixed(2)}
          </dd>
        )}
        <dd>
          Total: &pound;
          {Number(
            Big(gasCost || 0)
              .plus(electricityCost || 0)
              .plus(boltOnCost || 0)
              .toFixed(2),
          )}
        </dd>
      </dl>
    </StyledPredictedCostTooltip>
  );
};

type MonthTickProps = TextProps & {
  payload: {
    value: string;
  };
};

const MonthTick = (isFlipped: boolean) => (props: MonthTickProps) =>
  (
    // @ts-ignore react-18 children props
    <Text {...props} verticalAnchor="middle" angle={!isFlipped ? -60 : 0}>
      {props.payload.value}
    </Text>
  );

class ProjectedCostChart extends React.Component<Props> {
  // eslint-disable-next-line react/sort-comp
  wrapper: null | HTMLElement = null;

  componentDidMount() {
    setTimeout(() => {
      setSvgAttributes(
        this.wrapper,
        'Bar graph showing monthly estimated energy costs',
      );
    }, 1000);
  }

  componentDidUpdate() {
    setSvgAttributes(
      this.wrapper,
      'Bar graph showing monthly estimated energy costs',
    );
  }

  getChartProps = (
    { currentDirectDebit, recommendedDirectDebit }: Props,
    isFlipped: boolean,
  ) => {
    const isSmallToMedium = window.matchMedia(
      breakpoints.smallToMedium,
    ).matches;

    const commonAxisProps = {
      tickLine: false,
      axisLine: false,
    };

    const timeAxisProps: XAxisProps | YAxisProps = {
      ...commonAxisProps,
      dataKey: 'month',
      interval: isSmallToMedium ? 1 : 0,
      tickMargin: isFlipped ? undefined : 12,
      type: 'category',
      tick: MonthTick(isFlipped),
    };

    const costAxisProps: XAxisProps | YAxisProps = {
      ...commonAxisProps,
      domain: [
        0,
        (dataMax: number) =>
          currentDirectDebit
            ? Math.max(
                currentDirectDebit,
                recommendedDirectDebit,
                Math.ceil(dataMax),
              )
            : Math.max(recommendedDirectDebit, Math.ceil(dataMax)),
      ],
      tickFormatter: (value: any) => `£${value}`,
      interval: 0,
      scale: 'linear',
      type: 'number',
    };

    return { costAxisProps, timeAxisProps };
  };

  setWrapper = (wrapper: HTMLElement) => {
    this.wrapper = wrapper;
  };

  getChart({
    isFlipped,
    keyAxisProps,
    valueAxisProps,
  }: ResponsiveChartRenderProps) {
    const {
      data,
      theme: { colors, space },
      recommendedDirectDebit: recommendedDD,
      currentDirectDebit: currentDD,
    } = this.props;

    const { costAxisProps, timeAxisProps } = this.getChartProps(
      this.props,
      isFlipped,
    );

    const costProps = {
      ...valueAxisProps,
      ...costAxisProps,
    };

    const timeProps = {
      ...keyAxisProps,
      ...timeAxisProps,
    };

    // tslint:disable-next-line no-object-literal-type-assertion
    const xAxisProps: XAxisProps = {
      ...(isFlipped ? costProps : timeProps),
      padding: {
        right: isFlipped ? 8 : 0,
      },
    } as XAxisProps;
    // tslint:disable-next-line no-object-literal-type-assertion
    const yAxisProps: YAxisProps = {
      ...(isFlipped ? timeProps : costProps),
      width: 42,
    } as YAxisProps;

    const hasGas = data.some(x => x.gasCost != null);
    const hasElectricity = data.some(x => x.electricityCost != null);
    const hasBoltOns = data.some(x => x.boltOnCost != null);

    const electricityLegendItem = {
      id: 'electricity',
      type: 'square',
      value: 'Electricity',
      color: colors.elec,
    } as const;

    const gasLegendItem = {
      id: 'gas',
      type: 'square',
      value: 'Gas',
      color: colors.gas,
    } as const;

    const boltOnLegendItem = {
      id: 'boltOns',
      type: 'square',
      value: 'Upgrades',
      color: colors.upgradesDark,
    } as const;

    return (
      <BarChart layout={isFlipped ? 'vertical' : 'horizontal'} data={data}>
        <CartesianGrid
          horizontal={!isFlipped}
          vertical={isFlipped}
          stroke={colors.chartCartesianGrid}
        />
        <Bar
          layout={isFlipped ? 'horizontal' : 'vertical'}
          dataKey="boltOnCost"
          stackId="cost"
          fill={colors.upgradesDark}
          isAnimationActive={false}
        />
        <Bar
          layout={isFlipped ? 'horizontal' : 'vertical'}
          dataKey="electricityCost"
          stackId="cost"
          fill={colors.elec}
          isAnimationActive={false}
        />
        <Bar
          layout={isFlipped ? 'horizontal' : 'vertical'}
          dataKey="gasCost"
          stackId="cost"
          fill={colors.gas}
          isAnimationActive={false}
        />
        <ReferenceLine
          x={isFlipped ? currentDD : undefined}
          y={isFlipped ? undefined : currentDD}
          stroke="black"
          strokeWidth={2}
        />
        <ReferenceLine
          x={isFlipped ? recommendedDD : undefined}
          y={isFlipped ? undefined : recommendedDD}
          stroke="#555C6B"
          strokeWidth={2}
          strokeDasharray="8 4"
        />
        <Legend
          verticalAlign="bottom"
          payload={[
            ...(hasGas ? [gasLegendItem] : []),
            ...(hasElectricity ? [electricityLegendItem] : []),
            ...(hasBoltOns ? [boltOnLegendItem] : []),
            {
              id: 'current-dd',
              type: 'plainline',
              value: 'Current Direct Debit',
              color: 'black',
              payload: { strokeDasharray: '1 0' },
            },
            {
              id: 'recommended-dd',
              type: 'plainline',
              value:
                'Recommended Direct Debit (including changes to your plan)',
              color: '#555C6B',
              payload: { strokeDasharray: '8 4' },
            },
          ]}
          iconSize={16}
          wrapperStyle={{
            bottom: `-${space[2]}`,
          }}
        />
        <Tooltip content={CustomTooltip} />
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />
      </BarChart>
    );
  }

  render() {
    return (
      <StyledPredictedCostChart aria-hidden="true">
        <ResponsiveChartWrapper flipBreakpoint={breakpoints.smallDown}>
          {renderProps => (
            <ResponsiveContainer
              width="100%"
              height={renderProps.isFlipped ? 500 : 300}
            >
              {this.getChart(renderProps)}
            </ResponsiveContainer>
          )}
        </ResponsiveChartWrapper>
      </StyledPredictedCostChart>
    );
  }
}

export default withTheme(ProjectedCostChart);
