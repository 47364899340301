import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getCustomerAccessToken } from '@/src/api/utils/getCustomerAccessToken';
import { storeGraphqlQueryNameByRequestId } from './datadog';

type Method = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';

type Options = Omit<AxiosRequestConfig, 'data'> & {
  url: string;
  method: Method;
  responseType?: 'json' | 'text';
  headers?: Record<string, string>;
  body?: Record<string, any>;
  timeout?: number;
};

async function request<TData>({
  method,
  headers = {},
  body,
  ...rest
}: Options): Promise<TData> {
  try {
    const token = await getCustomerAccessToken();

    const xTraceToken = uuidv4();
    if (body?.query) {
      // store graphql query name for use in datadog resource tracking
      storeGraphqlQueryNameByRequestId(xTraceToken, body.query);
    }
    const { data } = await axios({
      data: body,
      responseType: 'json',
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-Trace-Token': xTraceToken,
        authorization: `Bearer ${token.accessToken.value}`,
        ...headers,
      },
      withCredentials: false,
      adapter: 'fetch',
      ...rest,
    });

    return data;
  } catch (error) {
    if (error instanceof AxiosError && error?.response?.status === 401) {
      // this handles checking token is still valid and will logout if not
      await getCustomerAccessToken();
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
}

function get<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'GET',
    ...options,
  });
}

function post<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'POST',
    ...options,
  });
}

function put<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'PUT',
    ...options,
  });
}

function patch<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'PATCH',
    ...options,
  });
}

function del<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'DELETE',
    ...options,
  });
}

type GraphQLOptions = Omit<Options, 'method' | 'body'> & {
  body: {
    query: string;
    operationName?: string;
    variables?: Record<string, any>;
  };
};

type GraphQLResponse<T> = {
  data: T;
  errors?: Record<string, any>[];
};

function graphql<TData>(options: GraphQLOptions) {
  return post<GraphQLResponse<TData>>(options).then(response => {
    if (response.errors) {
      // For now, catch any errors from graphql.
      throw response.errors;
    }
    return response.data;
  });
}

const apiService = {
  get,
  post,
  put,
  patch,
  delete: del,
  graphql,
};

export { apiService };
