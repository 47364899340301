import { determinePlanDetails } from '@monovo/energy-renewals';
import { Tariff } from '../types/quoteRequest';

export function getPlanDetails(tariff: Tariff) {
  // Different names might be used in Q&S?
  const properName = tariff.name.includes('2 Year Fixed')
    ? '2 Year Fixed Energy'
    : tariff.name;

  const planDetails = determinePlanDetails(properName, tariff.contractLength);
  const monthlyCost = Math.ceil(
    (tariff.tils.Electricity.annualSpend +
      (tariff.tils.Gas?.annualSpend || 0)) /
      12,
  ).toFixed(0);

  const exitFees =
    tariff.tils.Electricity.exitFees + (tariff.tils.Gas?.exitFees || 0);

  return {
    exitFees,
    monthlyCost,
    planDetails,
  };
}
