import { OptimizelyDecision } from '@/src/utils/optimizely';
import { GetEligibilityInfoResult } from '../../SmartThermostats/hooks/useEligibilityInfo';
import { SMART_THERMOSTAT_FF_VARIABLE } from '../constants';

export const getIsPTZOffersAvailable = (
  featureFlagVariables: OptimizelyDecision['variables'],
  smartThermostatsEligibility: GetEligibilityInfoResult['eligible'],
) => {
  const keys = Object.keys(featureFlagVariables);
  const isPTZOffersAvailable: boolean = keys.reduce((acc, key) => {
    // in order to enable ptz smart thermostat offer we need to check not only the feature flag
    // but also we need to check if the user is eligible for smart thermostat
    if (key === SMART_THERMOSTAT_FF_VARIABLE) {
      const val = featureFlagVariables?.[key];
      // this check is simply to ensure that the value of ff and smartThermostatsEligibility is boolean
      if (
        typeof val === 'boolean' &&
        typeof smartThermostatsEligibility === 'boolean'
      ) {
        return acc || (val && smartThermostatsEligibility);
      }
      return acc;
    } else {
      const val = featureFlagVariables?.[key];
      // this check is simply to ensure that the value of ff is a boolean
      if (typeof val === 'boolean') {
        return acc || val;
      }
      return acc;
    }
  }, false);

  return isPTZOffersAvailable;
};
