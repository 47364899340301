import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { StyledNavHelpSupportLink } from '@/src/components/Header/Header.styled';
import { MENU } from '@/src/constants/analytics';
import { EXTERNAL_ROUTE_HELP } from '@/src/constants/routes';

const HelpAndSupportLink = () => (
  <StyledNavHelpSupportLink
    as={TextLink}
    href={EXTERNAL_ROUTE_HELP}
    opensInNewWindow
    data-event-name={MENU.HELP_AND_SUPPORT}
  >
    Help and support
  </StyledNavHelpSupportLink>
);

export default HelpAndSupportLink;
