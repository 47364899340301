import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { FootnoteReferencedContent } from '@/src/components';
import { BEYOND_TERMS, BOILER_COVER_REWARD_TERMS } from '@/src/constants/links';

const homeRecoverDisclaimer = (
  <>
    Terms, conditions, eligibility criteria, and exclusions and cancellation
    charges apply.
  </>
);

export const footnoteHomeRecoverPromo: FootnoteReferencedContent = {
  id: 'HomeRecoverPromo',
  body: [homeRecoverDisclaimer],
};

const homeRecoverQ1Savings = (
  <>
    Saving based on our zero excess cover. £50 excess still applies to taps and
    toilet repairs. Offer ends 21/03/24.
  </>
);

const homeRecoverQ1Disclaimer = (
  <>Terms, exclusions and cancellation fees apply.</>
);

export const footnoteHomeRecoverQ1PromoSavings: FootnoteReferencedContent = {
  id: 'HomeRecoverPromoSavings',
  body: [homeRecoverQ1Savings],
};
export const footnoteHomeRecoverQ1PromoDisclaimer: FootnoteReferencedContent = {
  id: 'HomeRecoverPromoDisclaimer',
  body: [homeRecoverQ1Disclaimer],
};

const freeForFourMonthsNoClaims = (
  <>
    No claims can be made in the first 30 days. Terms, conditions, eligibility
    criteria, exclusions and cancellation charges apply. Offer available to new
    customers only. Offer expires 12/12/2023.
  </>
);

export const footnoteFreeForFourMonthsNoClaims: FootnoteReferencedContent = {
  id: 'FreeForFourMonthsNoClaims',
  body: [freeForFourMonthsNoClaims],
};

const freeForFourMonthsTerms = (
  <>
    The £128 claim is based on a customer on our £0 excess Complete Plan,
    costing £32 per month. Note: a £50 excess still applies to external drains
    and taps and toilets claims.
  </>
);

export const footnoteFreeForFourMonthsTerms: FootnoteReferencedContent = {
  id: 'FreeForFourMonthsNoTerms',
  body: [freeForFourMonthsTerms],
};

const FreeForFourMonthsEnergyCredit = (
  <>
    To be eligible for this credit, you must meet the eligibility criteria set
    out in the{' '}
    <TextLink href={BOILER_COVER_REWARD_TERMS} opensInNewWindow>
      reward offer terms.
    </TextLink>
  </>
);

export const footNotesFreeForFourMonthsEnergyCredit: FootnoteReferencedContent =
  {
    id: 'footNotesFreeForFourMonthsEnergyCredit',
    body: [FreeForFourMonthsEnergyCredit],
  };

const freeForSixMonthsNoClaims = (
  <>
    No claims can be made in the first 30 days. Terms, conditions, eligibility
    criteria, exclusions and cancellation charges apply. Offer available to new
    customers only. Offer expires 30/11/2023.
  </>
);

export const footnoteFreeForSixMonthsNoClaims: FootnoteReferencedContent = {
  id: 'FreeForSixMonthsNoClaims',
  body: [freeForSixMonthsNoClaims],
};

const ohsQ1PromoOfferNoClaims = (
  <>
    No claims can be made in the first 30 days. Offer not available on all
    products or for existing CORGI HomePlan and OVO boiler cover customers. To
    be eligible for this offer you must meet the boiler cover eligibility
    criteria set out during sign up. Offer expires 20/03/2025
  </>
);

const ohsQ1PromoOfferFlashSaleNoClaims = (
  <>
    No claims can be made in the first 30 days. Offer not available on all
    products or for existing CORGI HomePlan and OVO boiler cover customers. To
    be eligible for this offer you must meet the boiler cover eligibility
    criteria set out during sign up. Offer expires 11/02/2025
  </>
);

export const footnoteOHSQ1PromoOfferNoClaims: FootnoteReferencedContent = {
  id: 'ohsQ1PromoOfferNoClaims',
  body: [ohsQ1PromoOfferNoClaims],
};

const ohsQ43monthsMaxSavingsFreePromo = (
  <>
    The maximum potential saving of £96 above is based on the Complete plan,
    with no excess, at £32 a month for 12 months, compared to to getting the
    first 3 months of cover free.
  </>
);

export const footnoteOHSQ1PromoFlashSaleOfferNoClaims: FootnoteReferencedContent =
  {
    id: 'ohsQ1PromoFlashSaleOfferNoClaims',
    body: [ohsQ1PromoOfferFlashSaleNoClaims],
  };

export const footnoteOHSQ43monthsMaxSavingsFreePromo: FootnoteReferencedContent =
  {
    id: 'ohsQ43monthsFreePromo-maxSavings',
    body: [ohsQ43monthsMaxSavingsFreePromo],
  };

const ohsQ46monthsMaxSavingsFreePromo = (
  <>
    The maximum potential saving of £192 above is based on the Complete plan,
    with no excess, at £32 a month for 12 months, compared to getting the first
    6 months of cover free.
  </>
);

export const footnoteOHSQ46monthsMaxSavingsFreePromo: FootnoteReferencedContent =
  {
    id: 'ohsQ46monthsFreePromo-maxSavings',
    body: [ohsQ46monthsMaxSavingsFreePromo],
  };

const ohsQ43monthsFreePromo = (
  <>
    No claims can be made in the first 30 days. New customers only, and not
    available for existing CORGI HomePlan and OVO Energy boiler cover customers.
    To be eligible for this offer you must meet the boiler cover eligibility
    criteria set out during sign up. Offer expires 12/12/2024
  </>
);

export const footnoteOHSQ43monthsFreePromo: FootnoteReferencedContent = {
  id: 'ohsQ43monthsFreePromo',
  body: [ohsQ43monthsFreePromo],
};

const ohsQ4FlashSalePromo = (
  <>
    No claims can be made in the first 30 days. New customers only, and not
    available for existing CORGI HomePlan and OVO Energy boiler cover customers.
    To be eligible for this offer you must meet the boiler cover eligibility
    criteria set out during sign up. Offer expires 17/10/2024
  </>
);

export const footnoteOHSQ4FlashSalePromo: FootnoteReferencedContent = {
  id: 'ohsQ4FlashSalePromo',
  body: [ohsQ4FlashSalePromo],
};

const ohsQ4BlackFridayPromo = (
  <>
    No claims can be made in the first 30 days. New customers only, and not
    available for existing CORGI HomePlan and OVO Energy boiler cover customers.
    To be eligible for this offer you must meet the boiler cover eligibility
    criteria set out during sign up. Offer expires 05/12/2024
  </>
);

export const footnoteOHSQ4BlackFridayPromo: FootnoteReferencedContent = {
  id: 'ohsQ4BlackFridayPromo',
  body: [ohsQ4BlackFridayPromo],
};

const ohsFixedQ1PromoOfferNoClaims = (
  <>
    Terms and eligibility criteria apply. 6 months half price offer ends
    21/03/2024. No claims accepted in the first 30 days.
  </>
);

export const footnoteOHSFixedQ1PromoOfferNoClaims: FootnoteReferencedContent = {
  id: 'ohsFixedQ1PromoOfferNoClaims',
  body: [ohsFixedQ1PromoOfferNoClaims],
};

const ohsFixedQ1PromoOfferSavings = (
  <>
    Saving based on 6 months of our Complete cover plan without excess and
    energy credit combined.
  </>
);

export const footnoteOHSFixedQ1PromoOfferSavings: FootnoteReferencedContent = {
  id: 'ohsFixedQ1PromoOfferSavings',
  body: [ohsFixedQ1PromoOfferSavings],
};

const beyondTerms = (
  <TextLink href={BEYOND_TERMS} opensInNewWindow>
    Beyond terms
  </TextLink>
);

export const bottomBeyondBannerTerms: FootnoteReferencedContent = {
  id: 'beyondTerms',
  body: [beyondTerms],
};
