import React from 'react';
import { Heading4, P, Disclosure, TextLink } from '@ovotech/nebula';
import { COMPATIBLE_EVS_AND_CHARGERS_LINK } from '@monovo/ev-anytime';

export const SolarPanelsIncompatible = () => {
  return (
    <>
      <Heading4>Stay up-to-date with solar compatibility</Heading4>
      <P>
        At the moment, if you have solar panels, you can only get Charge Anytime
        with specific smart chargers. Check our{' '}
        <TextLink opensInNewWindow href={COMPATIBLE_EVS_AND_CHARGERS_LINK}>
          Compatible EVs and chargers
        </TextLink>{' '}
        page to find out more.
      </P>
      <Disclosure title="More about solar compatibility">
        <P>
          Charge Anytime works out how much electricity your EV is using from
          the grid, so you can get our cheaper charging rate. If you have solar
          panels, you might use some of your solar energy to charge your EV.
          Only a few chargers currently have the technology to work out if the
          electricity is coming from the grid, or solar.
        </P>
      </Disclosure>
    </>
  );
};
