import { useHistory } from 'react-router-dom-v5';
import { SecondaryCTAButton } from '@ovotech/nebula';
import React from 'react';

export const ReturnToHomePage = () => {
  const history = useHistory();
  return (
    <SecondaryCTAButton
      onClick={() => {
        history.replace('/');
      }}
    >
      Return to the home page
    </SecondaryCTAButton>
  );
};
