import React, { useEffect } from 'react';
import { ActionCardBase } from '../../ActionCardBase/ActionCardBase';
import { useContentfulNextBestActionsQuery } from '@/src/api/contentful/use-contentful-next-best-actions-query';
import { SkeletonLoader } from './SkeletonLoader';
import { getFragmentData } from '@/src/api/contentful/__generated__';
import { ShowableCardsFragmentFragmentDoc } from '@/src/api/contentful/__generated__/graphql';
import { PackCTAFragment } from '@/src/utils/contentful/getCallToAction';
import { createCta } from '../hooks/utils/createCta';
import { getDocumentAsReactNode } from '../utils/getDocumentAsReactNode';
import { Document } from '@contentful/rich-text-types';
import { useMarketingPreferencesMutation } from '@/src/api/kapi/use-marketing-preferences-mutation';
import { CommunicationChannel } from '@monovo/kapi';
import { useAnalytics } from '@ovotech/ui-tools';
import { CUSTOMER_ACTION_CARD_EVENTS } from '@/src/constants/analytics';
import { useContentfulActionUrlMap } from '../hooks/useContentfulActionUrlMap';
import { MarketingChannel } from '@/src/constants/enums';

interface EmailConsentCardProps {
  onSuccess: () => void;
  onError: () => void;
  onDismiss: () => void;
  existingPreferences: readonly CommunicationChannel[];
  isFetching: boolean;
}

export const EmailConsentCard = ({
  onSuccess,
  onError,
  onDismiss,
  existingPreferences,
  isFetching,
}: EmailConsentCardProps) => {
  const {
    data: contentfulData,
    isLoading: isContentfulQueryLoading,
    isError: isContentfulQueryError,
  } = useContentfulNextBestActionsQuery(
    process.env.NX_CONTENTFUL_PRE_SUPPLY_ACTION_CARD_CONTAINER_ID!,
  );
  const { getActionByUrl } = useContentfulActionUrlMap();

  const { mutate, isLoading: isMarketingPreferencesMutationLoading } =
    useMarketingPreferencesMutation(onSuccess, onError);

  let updatedPreferences = existingPreferences;
  const updateMarketingPreferences = () => {
    if (
      !updatedPreferences.find(channel => channel === MarketingChannel.EMAIL)
    ) {
      updatedPreferences = [...updatedPreferences, MarketingChannel.EMAIL];
    }
    mutate(updatedPreferences);
  };

  const { dispatch } = useAnalytics();

  const cardData = contentfulData?.buildNextBestAction?.actionCardsCollection;
  const cardCollection = getFragmentData(
    ShowableCardsFragmentFragmentDoc,
    cardData,
  );
  const emailConsentCard = cardCollection?.items[0];
  const header = emailConsentCard?.text?.header;
  const cardName = emailConsentCard?.mixpanelId || undefined;
  const ctaBehaviour = emailConsentCard?.ctaBehaviour;

  const handleOnClick = () => {
    if (cardName) {
      dispatch({
        name: CUSTOMER_ACTION_CARD_EVENTS.cardTapped,
        type: 'click',
        properties: {
          cardName,
          screen: 'Pre-supply',
        },
      });
    }
    updateMarketingPreferences();
  };

  const handleOnDismiss = () => {
    if (cardName) {
      dispatch({
        name: CUSTOMER_ACTION_CARD_EVENTS.cardDismissed,
        type: 'click',
        properties: {
          cardName,
          screen: 'Pre-supply',
        },
      });
    }
    onDismiss();
  };

  useEffect(() => {
    if (cardName) {
      dispatch({
        name: CUSTOMER_ACTION_CARD_EVENTS.cardShown,
        type: 'view',
        properties: {
          cardName,
          screen: 'Pre-supply',
        },
      });
    }
    // dispatch is not stable across renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardName]);

  if (
    isContentfulQueryLoading ||
    isFetching ||
    isMarketingPreferencesMutationLoading
  ) {
    return <SkeletonLoader dataTestId="email-consent-card" />;
  }

  if (isContentfulQueryError || !cardData || !header) {
    return null;
  }

  const emailConsentCardBody = emailConsentCard?.text?.text?.json ? (
    <>
      {getDocumentAsReactNode(
        emailConsentCard?.text?.text?.json as Document,
        !!emailConsentCard?.inverted,
      )}
    </>
  ) : undefined;

  const cta =
    emailConsentCard?.cta &&
    getFragmentData(PackCTAFragment, emailConsentCard?.cta);

  const ctaInfo = createCta(cta, ctaBehaviour, getActionByUrl);

  return (
    <ActionCardBase
      title={header}
      body={emailConsentCardBody}
      inverted={!!emailConsentCard?.inverted}
      inline={false}
      onClickClose={handleOnDismiss}
      onClickCta={handleOnClick}
      ctaTitle={ctaInfo?.label ?? undefined}
    />
  );
};
