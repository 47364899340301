import { Card, ErrorNotification, P } from '@ovotech/nebula';
import React from 'react';

export const ConnectSmartChargerError = () => {
  return (
    <Card>
      <ErrorNotification
        id="connect-charger-error"
        data-testid="connect-smart-charger-error"
        title="Sorry, there’s been an error"
      >
        <P>
          We weren’t able to connect to your smart charger yet. Please check
          back later.
        </P>
      </ErrorNotification>
    </Card>
  );
};
