import React from 'react';

export const MeterWarningImage = () => (
  <svg
    width="119"
    height="122"
    viewBox="0 0 119 122"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#B6D727"
      d="M17.695 0l100.352 17.695-17.695 100.352L0 100.352z"
    />
    <mask
      id="a"
      maskUnits="userSpaceOnUse"
      x="2"
      y="1"
      width="114"
      height="113"
    >
      <circle cx="59.06" cy="57.5" r="56.5" fill="#C4C4C4" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M34.295 90v-7.158H48.61L34.295 90zM84.402 90v-7.158H70.086L84.402 90z"
        fill="#0D8426"
      />
      <path d="M91.56 64.946H27.138v17.896H91.56V64.946z" fill="#0A9928" />
      <path
        opacity=".3"
        d="M84.402 32.734c0-3.937-3.22-7.158-7.158-7.158-3.937 0-7.158 3.221-7.158 7.158H48.61c0-3.937-3.22-7.158-7.158-7.158-3.937 0-7.158 3.221-7.158 7.158h-7.158v32.212H91.56V32.734h-7.159zm-7.158 23.264h-35.79V41.682h35.79v14.316z"
        fill="#0A9928"
      />
    </g>
    <circle cx="97.598" cy="102.598" r="18.598" fill="#FFE600" />
    <path d="M99.195 107h-4V93h4v14zM95.195 109h4v4h-4z" fill="#555C6B" />
  </svg>
);
