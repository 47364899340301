import { MobileOS, getDeviceOS } from '@/src/utils/getDeviceOS';

export const URL_STORE = {
  google:
    'https://play.google.com/store/apps/details?id=com.ovo.energy&hl=en_GB&gl=US&pli=1',
  apple: 'https://apps.apple.com/gb/app/ovo-energy/id626316021?platform=iphone',
};

export enum LinkReferrer {
  BASE = 'referrer',
  TYPE_EMAIL = 'email',
}

const storeLookup = {
  [MobileOS.ANDROID]: URL_STORE.google,
  [MobileOS.IOS]: URL_STORE.apple,
};

/**
 * If on a mobile device: replace current url with that of the appropriate mobile store url
 * `returns true`
 *
 * If on desktop: no action taken. `returns false`
 * @returns boolean
 */
export const redirectToMobileStore = (): boolean => {
  const mobileOS = getDeviceOS();

  const mobileStoreUrl = mobileOS ? storeLookup[mobileOS] : null;

  if (!mobileStoreUrl || !window?.location) {
    return false;
  }

  window.location.replace(mobileStoreUrl);

  return true;
};
