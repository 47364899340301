import { P } from '@ovotech/nebula';
import React from 'react';
import {
  StyledActionButtonContainer,
  StyledTextContainer,
} from './TipInteractionButton.styled';
import {
  buttonCaptions,
  iconsActive,
  iconsInactive,
} from './TipInteractionButtonResources';
import { TipInteractionStatus } from '@/src/pages/EnergyInsights/hooks/useGetTipInteractionsFromLocalStorage';

type Props = {
  onClick: (tipInteractionStatus: TipInteractionStatus) => void;
  variant: TipInteractionStatus;
  currentTipInteractionStatus: TipInteractionStatus;
};

const TipInteractionButton = ({
  onClick,
  variant,
  currentTipInteractionStatus,
}: Props) => {
  const active = currentTipInteractionStatus === variant;
  const icon = active ? iconsActive[variant] : iconsInactive[variant];
  const caption = buttonCaptions[variant];
  const testId = `${variant}${active ? '-selected' : ''}`;
  return (
    <StyledActionButtonContainer
      data-testid={testId}
      onClick={() => onClick(variant)}
    >
      {icon}
      <StyledTextContainer selected={active}>
        <P aria-label={`${caption} - ${active ? 'active' : 'inactive'}`}>
          {caption}
        </P>
      </StyledTextContainer>
    </StyledActionButtonContainer>
  );
};

type NamedButtonProps = Omit<Props, 'variant'>;
export const WillDoButton = (p: NamedButtonProps) => (
  <TipInteractionButton {...p} variant={TipInteractionStatus.WillDo} />
);
export const NotForMeButton = (p: NamedButtonProps) => (
  <TipInteractionButton {...p} variant={TipInteractionStatus.NotForMe} />
);
export const AlreadyDoingButton = (p: NamedButtonProps) => (
  <TipInteractionButton {...p} variant={TipInteractionStatus.AlreadyDoing} />
);
