import { Card, P, CTALink } from '@ovotech/nebula';
import React, { useContext, forwardRef } from 'react';
import {
  StyledOfferBody,
  StyledOfferImage,
  StyledOfferPanel,
  StyledPromoImage,
} from './Offer.styled';
import {
  CLICKED_OFFER_ON_OFFERS_PAGE,
  CLICKED_OFFER_EVENT,
} from '@/src/constants/analytics';
import analyticsService from '@/src/services/analyticsService';
import { OptimizelyContext } from '@/src/utils/optimizely';

type Props = {
  image: string;
  promoImage?: string;
  children: React.ReactNode;
  linkUrl: string;
  linkText: React.ReactNode;
  offerId: string;
  mission?: boolean;
  disableCTA?: boolean;
  fullwidthCTA?: boolean;
  optimizelyExperiment?: string;
  lowerBody?: React.ReactNode;
  tracking?: () => void;
};

// forwardRef required for AnalyticsView to work correctly with this as child component
const Offer = forwardRef(
  (
    {
      children,
      image,
      promoImage,
      linkUrl,
      linkText,
      offerId,
      mission,
      disableCTA,
      fullwidthCTA,
      optimizelyExperiment,
      tracking,
      lowerBody,
    }: Props,
    ref,
  ) => {
    const { optimizely } = useContext(OptimizelyContext);

    return (
      <StyledOfferPanel data-testid={`offer-${offerId}`} as={Card} ref={ref}>
        <StyledOfferImage src={image} alt="" aria-hidden="true" />
        <StyledPromoImage src={promoImage} aria-hidden="true" />
        <StyledOfferBody data-testid={`offer-body-${offerId}`}>
          {children}

          {!disableCTA && (
            <P>
              <CTALink
                variant={mission ? 'missionPrimary' : 'secondary'}
                fullWidth={fullwidthCTA}
                href={linkUrl}
                data-testid={`offer-cta-${offerId}`}
                data-event-name={CLICKED_OFFER_ON_OFFERS_PAGE}
                data-event-props={JSON.stringify({ offerId })}
                onClick={() => {
                  analyticsService.dispatch(CLICKED_OFFER_EVENT, {
                    offerType: offerId,
                  });
                  if (optimizelyExperiment) {
                    optimizely && optimizely.track(optimizelyExperiment);
                  }
                  if (tracking) {
                    tracking();
                  }
                }}
              >
                {linkText}
              </CTALink>
            </P>
          )}
          {lowerBody}
        </StyledOfferBody>
      </StyledOfferPanel>
    );
  },
);

export default Offer;
