import { Card, Heading2, P } from '@ovotech/nebula';
import styled from 'styled-components';
import mq from '@/src/utils/mediaQuery';
import { FlexCardContent } from '@/src/pages/EnergyInsights/components';

export const StyledNoMarginH2 = styled(Heading2)`
  margin-bottom: 0px;
  font-size: 22px;
`;

export const StyledNoMarginP = styled(P)`
  margin-bottom: 8px;
  margin-top: 0px;
`;

export const CarbonComparisonFlexCardContent = styled(FlexCardContent)`
  padding: 0px;
`;

export const CarbonComparisonTextCard = styled(FlexCardContent)`
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;

  div {
    ${({ theme: { colors } }) => `
   background: ${colors.brand.grey.light}
   `};
    min-height: 300px;
    padding: 36px 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;

export const StyledContainers = styled.div`
  width: 50%;

  ${mq(`(max-width: 625px)`)(`
    width: 100%;
    `)}
`;

export const StyledFlexCard = styled(Card)`
  flex: 1 1 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px;
`;
