import { Card, Heading4 } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledCard = styled(Card)`
  position: relative;
  border-width: 0px;
  overflow: hidden;
`;

export const StyledShape = styled.div`
  position: absolute;
  border-radius: 4;
  top: 0px;
  right: 0px;

  ${mqSmallDown(`
    top: -10px;
    right: -14px;
  `)}
`;

export const StyledIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;

  ${mqSmallDown(`
    top: 7px;
    right: 7px;
  `)}
`;

export const StyledHeading = styled(Heading4)`
  max-width: 76%;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const StyledBody = styled.div`
  max-width: 90%;
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[1]};
    `}
`;
