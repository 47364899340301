import { useQuery, UseQueryResult } from 'react-query';
import { ENDPOINT_BILLING_GRAPHQL } from '@/src/constants/endpoints';
import { AccountPaymentsGraphqlResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

const query = `
query LatestPeriod($id: String!) {
  billingSummary(id: $id) {
    latestPeriod {
      data {
        payments {
          date
          description
          credit {
            pounds
          }
        }
        closingBalance {
          pounds
        }
      }
    }
  }
}`;

const graphqlBody = (request: { accountId: string }) => {
  return {
    query,
    operationName: 'LatestPeriod',
    variables: { id: request.accountId },
  };
};

export const useAccountPaymentsQuery = (
  accountId: string | null | undefined,
): UseQueryResult<AccountPaymentsGraphqlResponse> => {
  return useQuery(
    [`orion-billing-graphql-${accountId}-latest`],
    () =>
      apiService.graphql<AccountPaymentsGraphqlResponse>({
        url: ENDPOINT_BILLING_GRAPHQL,
        body: graphqlBody({ accountId: accountId ?? '' }),
      }),
    {
      enabled: !!accountId,
    },
  );
};
