import {
  DirectDebitResponse,
  RecommendedDirectDebitResponse,
  useDirectDebitLegacyQuery,
  useRecommendedDirectDebitLegacyQuery,
} from '@ovotech/energy-cx';
import { QueryResultRenderer, useAccountContext } from '@ovotech/ui-tools';

import React from 'react';
import { StruggleToPay } from '../../ChangeDirectDebitAmount/StruggleToPay';
import OneClickPaymentReduction from './OneClickPaymentReduction';
import { isValidRecommendedDirectDebit } from './utils';

const Data = ({
  props: { accountId, setErrorState },
  data: [directDebit, recommendedDirectDebit],
}: {
  props: {
    accountId: string;
    setErrorState: React.Dispatch<React.SetStateAction<boolean>>;
  };
  data: readonly [DirectDebitResponse, RecommendedDirectDebitResponse];
}) => {
  return isValidRecommendedDirectDebit(recommendedDirectDebit) ? (
    <OneClickPaymentReduction
      setErrorState={setErrorState}
      accountId={accountId}
      recommendedDirectDebit={recommendedDirectDebit}
      currentDirectDebit={directDebit}
    />
  ) : (
    <StruggleToPay />
  );
};

const OneClickPaymentAmountQuery = ({
  accountId,
  setErrorState,
}: {
  accountId: string;
  setErrorState: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const directDebitQueryResult = useDirectDebitLegacyQuery(accountId);
  const recommendedDirectDebitQueryResult =
    useRecommendedDirectDebitLegacyQuery(accountId);

  return (
    <QueryResultRenderer
      forwardProps={{ accountId, setErrorState }}
      queryResults={
        [directDebitQueryResult, recommendedDirectDebitQueryResult] as const
      }
      NoData={() => null}
      Error={() => <StruggleToPay />}
      Data={Data}
    />
  );
};

export const OneClickPaymentReductionMain = ({
  setErrorState,
}: {
  setErrorState: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { accountId } = useAccountContext();

  if (accountId == null) {
    return null;
  }

  return (
    <OneClickPaymentAmountQuery
      accountId={accountId}
      setErrorState={setErrorState}
    />
  );
};
