import Big from 'big.js';
import { CurrencyValueResponse } from '@/src/types/Response';

export const poundsToPence = (value: number): string =>
  (value * 100).toFixed(2);

export const formatPoundsAmount = (poundsAmount: number | string): string => {
  const parsedAmount =
    typeof poundsAmount === 'number' ? poundsAmount : parseFloat(poundsAmount);
  const normalisedAmount = Math.abs(parsedAmount).toFixed(2);
  return parsedAmount >= 0
    ? `\xA3${normalisedAmount}`
    : `-\xA3${normalisedAmount}`;
};

export const formatGBP = (poundsAmount: string): string => {
  const parsedAmount = parseFloat(poundsAmount);
  if (Number.isNaN(parsedAmount)) {
    return 'NaN';
  }
  return parsedAmount >= 0
    ? `\xA3${poundsAmount}`
    : `-\xA3${poundsAmount.substr(1)}`;
};

export const formatMoney = (amount: string, currencyUnit = 'GBP'): string => {
  switch (currencyUnit) {
    case 'GBP': {
      return formatGBP(amount);
    }
    default: {
      return 'Unknown';
    }
  }
};

type Options = {
  unit: 'GBP' | 'GBX';
  abs?: boolean;
  floor?: boolean;
  ceil?: boolean;
};

type OptionsForString = Options & { dp?: number };

function getCurrencyValueCore(
  options: Options,
  currencyValue: CurrencyValueResponse & { currencyCode?: 'GBP' | 'GBX' },
): Big {
  const currencyUnit = currencyValue.currencyUnit || currencyValue.currencyCode;
  const { unit, abs = false, ceil, floor } = options;

  let amount: Big = new Big(currencyValue.amount);

  if (abs) {
    amount = amount.abs();
  }

  if (currencyUnit !== unit) {
    amount =
      currencyUnit === 'GBP' && unit === 'GBX'
        ? amount.times(100)
        : (amount = amount.div(100)); // currencyUnit must be `GBX` and unit must be `GBP`
  }

  if (floor) {
    amount = amount.round(undefined, 0);
  }

  if (ceil) {
    amount = amount.round(undefined, 3);
  }

  return amount;
}

export function getCurrencyValueAsNumber(
  options: Options,
  currencyValue: CurrencyValueResponse & { currencyCode?: 'GBP' | 'GBX' },
): number {
  return Number(getCurrencyValueCore(options, currencyValue));
}

export function getCurrencyValue(
  options: OptionsForString,
  currencyValue: CurrencyValueResponse & { currencyCode?: 'GBP' | 'GBX' },
): string {
  const { dp } = options;
  const amount = getCurrencyValueCore(options, currencyValue);

  const result =
    typeof dp === 'number' ? amount.toFixed(dp) : amount.toString();
  return removeNegativeZero(result);
}

function removeNegativeZero(input: string): string {
  if (new Big(input).eq(new Big(0)) && input[0] === '-') {
    return input.substr(1);
  } else {
    return input;
  }
}

export const getPoundValue = getCurrencyValue.bind({}, { unit: 'GBP', dp: 2 });

export const getPoundValueAsNumber = getCurrencyValueAsNumber.bind(
  {},
  { unit: 'GBP' },
);

export function formatCurrencyValue(
  options: OptionsForString,
  currencyValue: CurrencyValueResponse & { currencyCode?: 'GBP' | 'GBX' },
): string {
  const { unit } = options;

  const amount = getCurrencyValue(options, currencyValue);
  if (unit === 'GBP') {
    if (amount[0] === '-') {
      return `-\xA3${amount.substr(1)}`;
    }

    return `\xA3${amount}`;
  }

  // unit must be `GBX`
  return `${amount}p`;
}

export const formatPounds = formatCurrencyValue.bind(
  {},
  { unit: 'GBP', dp: 2 },
);

// ts-unused-exports:disable-next-line
export const formatPoundsAbs = formatCurrencyValue.bind(
  {},
  { unit: 'GBP', dp: 2, abs: true },
);

export const formatBalance = (
  currencyValue: CurrencyValueResponse & { currencyCode?: 'GBP' | 'GBX' },
) => {
  const absPounds = formatPoundsAbs(currencyValue);
  const valueNumber = getCurrencyValueAsNumber({ unit: 'GBP' }, currencyValue);

  return `${absPounds} ${valueNumber < 0 ? 'in debit' : 'in credit'}`;
};

const parseNumber = (number: string | number) =>
  typeof number === 'number' ? number : parseFloat(number);

export const formatCurrencyValueWithSeparator = (
  number: string | number,
): string => {
  const parsedAmount = parseNumber(number);
  return parsedAmount.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
};

export const convertToPositiveNumber = (number: string | number) =>
  Math.abs(parseNumber(number));
