import { TextLink } from '@ovotech/nebula';
import React, { forwardRef, RefObject } from 'react';
import styled from 'styled-components';

const Button = styled.button`
  text-align: left;
  background-color: transparent;
  border: none;
  font: inherit;
  padding: 0;
  cursor: pointer;
`;

const ButtonLooksLikeLink = forwardRef(
  ({ children, ...props }: JSX.IntrinsicElements['button'], ref) => (
    <TextLink
      {...props}
      as={Button}
      type="button"
      ref={ref as RefObject<HTMLAnchorElement>}
    >
      {children}
    </TextLink>
  ),
);

export default ButtonLooksLikeLink;
