import React from 'react';
import { ClockArrowImage } from '@/src/resources/ClockArrowImage';
import { ClockWarningImage } from '@/src/resources/ClockWarningImage';
import { MeterTickImage } from '@/src/resources/MeterTickImage';
import { MeterWarningImage } from '@/src/resources/MeterWarningImage';

const modify = {
  ['meter-reads-summary-dueStatus-image']: () => <ClockArrowImage />,
  ['meter-reads-summary-traditionalWarningStatus-image']: () => (
    <ClockWarningImage />
  ),
  ['meter-reads-summary-okStatus-image']: () => <MeterTickImage />,
  ['meter-reads-summary-smartWarningStatus-image']: () => <MeterWarningImage />,
};

export { modify };
