import { Heading3 } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledSpotlightsWeekNavigation = styled.div`
  ${({ theme: { colors, space } }) => `
    color: ${colors.brand.grey.lightest};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1em;
    margin-top: ${space[8]};
  `}
`;

export const StyledWeeklySubHeading = styled(Heading3)`
  text-align: center;
  margin: 0;
`;

export const LineBreakContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${mqSmallDown(`
    font-size: 16px;
  `)}
`;

export const Line = styled.span`
  display: flex;
  white-space: nowrap;
`;
