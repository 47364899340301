import { Heading1, Heading3, Margin, P } from '@ovotech/nebula';
import React from 'react';
import {
  GreenerElectricityHomeHeaderStyled,
  GreenerElectricityHomeWelcome,
  GreenerElectricityHomeWelcomeInner,
  GreenerElectricityHomeHeaderAndLogoWrapper,
} from './GreenerElectricityHomeHeader.styled';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import testIds from '../../testIds';

interface GreenerElectricityHomeHeaderProps {
  headingContent?: string;
}

const GreenerElectricityHomeHeader = ({
  headingContent = 'Greener Electricity',
}: GreenerElectricityHomeHeaderProps) => {
  const profile = useProfileQueryKapi();

  const givenName = profile?.data?.customer_nextV1.givenName ?? '';

  return (
    <Margin top={8} bottom={8}>
      <Heading1 as={GreenerElectricityHomeHeaderStyled}>
        {headingContent}
      </Heading1>
      <GreenerElectricityHomeWelcome data-testid={testIds.home.header}>
        <GreenerElectricityHomeHeaderAndLogoWrapper>
          <GreenerElectricityHomeWelcomeInner>
            <Heading3>
              Welcome to Greener Electricity
              {givenName ? `, ${givenName}!` : ''}
            </Heading3>
            <P>
              Greener Electricity means growing a greener grid for the UK, for
              now and the future.
            </P>
          </GreenerElectricityHomeWelcomeInner>
        </GreenerElectricityHomeHeaderAndLogoWrapper>
      </GreenerElectricityHomeWelcome>
    </Margin>
  );
};

export default GreenerElectricityHomeHeader;
