import { LocationChangeAction, RouterAction } from 'connected-react-router';
import { AjaxError } from './Ajax';
import { ErrorResponse } from './ErrorResponse';
import { ProductId, SignupSource } from './Products';

import {
  AccountInfoResponse,
  BootstrapResponse,
  MonthlyUsageGroupedByYear,
  ProductCatalogue,
  ProductsResponse,
  ShowSmartMeterBookingBannerResponse,
  SmartMeterBookingResponse,
  SmartThermostatsTadoInfo,
  SmartThermostatsTadoStatus,
  SmartThermostatsOrderInfo,
  SmartThermostatsPricing,
  DirectDebitResponse,
  ProfileResponse,
  ContractsResponse,
} from './Response';
import {
  SmartThermostatsChartDataSeriesObj,
  ToolTipDates,
  Eligibility,
  IneligibilityReasons,
  MonthYear,
  RecommendedDirectDebitData,
  EVsInlifeDataMonthlyResponse,
  EVsInlifeVehicleDataResponse,
  EVsInlifeSavingsAndInsightDataResponse,
  EVsInlifeAnytimeSavingsDataResponse,
  SmartChargers,
} from './State';
import {
  OrderType,
  PaymentType,
} from '@/src/pages/SmartThermostats/utils/types';
import { FetchTariffCostsResponse } from '@/src/types/TariffCosts';
import { ProfileNextV1Query } from '../api/kapi/__generated__/graphql';

type ActionMeta = {
  analytics: {
    event?: string;
    props?: Record<string, unknown>;
    identify?: string;
    register?: Record<string, unknown>;
  };
};

export const FETCH_ACTION_KEY = Symbol();

export type WithFetchAction = {
  [FETCH_ACTION_KEY]?: undefined | string;
};

export type FetchStart = WithFetchAction & {
  isFetching: true;
  errorResponse?: never;
  error?: never;
};

export type FetchSuccess = WithFetchAction & {
  isFetching: false;
  isFetched?: false;
  errorResponse?: never;
  error?: never;
};

export type FetchError = WithFetchAction & {
  isFetching: false;
  error: AjaxError;
} & LegacyFetchError;

export type LegacyFetchError = {
  errorResponse: ErrorResponse;
};

type TestAction = {
  type: 'TEST';
  payload?: 'TEST PAYLOAD';
};

/**
 * @deprecated use `useAccountGraphqlQuery or `useBillingQuery` instead
 */
export type GetAccountInfoStart = FetchStart & {
  type: 'GET_ACCOUNT_INFO_START';
};

/**
 * @deprecated use `useAccountGraphqlQuery or `useBillingQuery` instead
 */
export type GetAccountInfoSuccess = FetchSuccess & {
  type: 'GET_ACCOUNT_INFO_SUCCESS';
  data: AccountInfoResponse;
};

/**
 * @deprecated use `useAccountGraphqlQuery or `useBillingQuery` instead
 */
export type GetAccountInfoError = FetchError & {
  type: 'GET_ACCOUNT_INFO_ERROR';
};

export type GetBootstrapSuccess = FetchSuccess & {
  type: 'GET_BOOTSTRAP_SUCCESS';
  data: BootstrapResponse;
  meta?: ActionMeta;
};

type ApiError = {
  type: 'API_ERROR';
  apiError: true;
};

type ResetApiError = {
  type: 'RESET_API_ERROR';
  apiError: false;
};

export type GetProductsStart = {
  type: 'GET_PRODUCTS_START';
  isFetching: true;
};

export type GetProductsSuccess = {
  type: 'GET_PRODUCTS_SUCCESS';
  isFetching: false;
  data: ProductsResponse;
};

export type GetProductsError = LegacyFetchError & {
  type: 'GET_PRODUCTS_ERROR';
  isFetching: false;
};

export type NoAction = {
  response: string;
};

export type GetProductCatalogueStart = {
  type: 'GET_PRODUCT_CATALOGUE_START';
  isFetching: true;
  promoCode?: string;
};

export type GetProductCatalogueSuccess = {
  type: 'GET_PRODUCT_CATALOGUE_SUCCESS';
  isFetching: false;
  data: ProductCatalogue;
};

export type GetProductCatalogueError = LegacyFetchError & {
  type: 'GET_PRODUCT_CATALOGUE_ERROR';
  isFetching: false;
};

export type ActivateProductStart = {
  type: 'ACTIVATE_PRODUCT_START';
  isFetching: true;
  productId: ProductId;
  options?: Partial<{ cost: number }>;
  versionId: string;
  promoCode?: string;
  displayName?: string;
  source?: SignupSource;
  additionalData?: {
    promo: string;
  };
};

export type ActivateProductSuccess = {
  type: 'ACTIVATE_PRODUCT_SUCCESS';
  isFetching: false;
  productId: ProductId;
  displayName?: string;
  meta: ActionMeta;
};

export type ActivateProductError = LegacyFetchError & {
  type: 'ACTIVATE_PRODUCT_ERROR';
  isFetching: false;
  productId: ProductId;
  displayName?: string;
};

export type CancelProductStart = {
  type: 'CANCEL_PRODUCT_START';
  isFetching: true;
  productId: ProductId;
};

export type CancelProductSuccess = {
  type: 'CANCEL_PRODUCT_SUCCESS';
  isFetching: false;
  productId: ProductId;
};

export type CancelProductError = LegacyFetchError & {
  type: 'CANCEL_PRODUCT_ERROR';
  isFetching: false;
  productId: ProductId;
};

export type ActivateAnytimeProductStart = {
  type: 'ACTIVATE_ANYTIME_PRODUCT_START';
  isFetching: true;
  productId: ProductId;
  options?: Partial<{ cost: number }>;
  versionId: string;
  promoCode?: string;
  displayName?: string;
  source?: SignupSource;
  additionalData?: {
    promo: string;
  };
};

export type ActivateAnytimeProductSuccess = {
  type: 'ACTIVATE_ANYTIME_PRODUCT_SUCCESS';
  isFetching: false;
  productId: ProductId;
  displayName?: string;
  meta: ActionMeta;
};

export type ActivateAnytimeProductError = LegacyFetchError & {
  type: 'ACTIVATE_ANYTIME_PRODUCT_ERROR';
  isFetching: false;
  productId: ProductId;
  displayName?: string;
};

export type CancelAnytimeProductStart = {
  type: 'CANCEL_ANYTIME_PRODUCT_START';
  isFetching: true;
  productId: ProductId;
};

export type CancelAnytimeProductSuccess = {
  type: 'CANCEL_ANYTIME_PRODUCT_SUCCESS';
  isFetching: false;
  productId: ProductId;
};

export type CancelAnytimeProductError = LegacyFetchError & {
  type: 'CANCEL_ANYTIME_PRODUCT_ERROR';
  isFetching: false;
  productId: ProductId;
};

export type GetDateRangeMonthlyUsageStart = {
  type: 'GET_DATE_RANGE_MONTHLY_USAGE_START';
  isFetchingUsageDataByYear: true;
  years: string[];
};

export type GetDateRangeMonthlyUsageSuccess = {
  type: 'GET_DATE_RANGE_MONTHLY_USAGE_SUCCESS';
  isFetchingUsageDataByYear: false;
  data: MonthlyUsageGroupedByYear;
};

export type GetDateRangeMonthlyUsageError = LegacyFetchError & {
  type: 'GET_DATE_RANGE_MONTHLY_USAGE_ERROR';
  isFetchingUsageDataByYear: false;
};

export type GetRecommendedDirectDebitStart = FetchStart & {
  type: 'GET_RECOMMENDED_DIRECT_DEBIT_START';
  selectedAccountId?: string | null;
};

export type GetRecommendedDirectDebitSuccess = FetchSuccess & {
  type: 'GET_RECOMMENDED_DIRECT_DEBIT_SUCCESS';
  data: RecommendedDirectDebitData | null;
};

export type GetRecommendedDirectDebitError = FetchError & {
  type: 'GET_RECOMMENDED_DIRECT_DEBIT_ERROR';
};

export type ShowModal = {
  type: 'SHOW_MODAL';
};

export type HideModal = {
  type: 'HIDE_MODAL';
};

export type SelectAccount = {
  type: 'SELECT_ACCOUNT';
  accountId: string;
  meta?: ActionMeta;
};

export type GetSmartMeterBookingStart = FetchStart & {
  type: 'GET_SMART_METER_BOOKING_START';
};

export type GetSmartMeterBookingSuccess = FetchSuccess & {
  type: 'GET_SMART_METER_BOOKING_SUCCESS';
  data: SmartMeterBookingResponse | null;
};

export type GetSmartMeterBookingError = FetchError & {
  type: 'GET_SMART_METER_BOOKING_ERROR';
};

export type GetShowSmartMeterBookingBannerStart = FetchStart & {
  type: 'GET_SHOW_SMART_METER_BOOKING_BANNER_START';
};

export type GetShowSmartMeterBookingBannerSuccess = FetchSuccess & {
  type: 'GET_SHOW_SMART_METER_BOOKING_BANNER_SUCCESS';
  data: ShowSmartMeterBookingBannerResponse | null;
};

export type GetShowSmartMeterBookingBannerError = FetchError & {
  type: 'GET_SHOW_SMART_METER_BOOKING_BANNER_ERROR';
};

export type FetchTariffCostsStart = {
  type: 'FETCH_TARIFF_COSTS_START';
};

export type FetchTariffCostsSuccess = {
  type: 'FETCH_TARIFF_COSTS_SUCCESS';
  data: FetchTariffCostsResponse;
};

export type FetchTariffCostsError = {
  type: 'FETCH_TARIFF_COSTS_ERROR';
};

export type SendKaluzaTokenStart = FetchStart & {
  type: 'SEND_KALUZA_TOKEN_START';
  token: string;
};

export type SendKaluzaTokenSuccess = FetchSuccess & {
  type: 'SEND_KALUZA_TOKEN_SUCCESS';
};

export type SendKaluzaTokenError = FetchError & {
  type: 'SEND_KALUZA_TOKEN_ERROR';
};

export type SendKaluzaAuthTokenStart = FetchStart & {
  type: 'SEND_KALUZA_AUTH_TOKEN_START';
  refreshToken: string;
};

export type SendKaluzaAuthTokenSuccess = FetchSuccess & {
  type: 'SEND_KALUZA_AUTH_TOKEN_SUCCESS';
};

export type SendKaluzaAuthTokenError = FetchError & {
  type: 'SEND_KALUZA_AUTH_TOKEN_ERROR';
};

export type GetKaluzaSmartChargerMonthlyDataStart = FetchStart & {
  type: 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_START';
  year: string;
  month: string;
};

export type GetKaluzaSmartChargerMonthlyDataSuccess = FetchSuccess & {
  type: 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_SUCCESS';
  monthlyData: EVsInlifeDataMonthlyResponse | null;
};

export type GetKaluzaSmartChargerMonthlyDataError = FetchError & {
  type: 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_ERROR';
};

export type ConnectionStatusToKaluzaSmartChargerStart = FetchStart & {
  type: 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_START';
};

export type ConnectionStatusToKaluzaSmartChargerSuccess = FetchSuccess & {
  type: 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_SUCCESS';
  connection: boolean;
};

export type ConnectionStatusToKaluzaSmartChargerError = FetchError & {
  type: 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_ERROR';
};

export type GetVehicleDataFromKaluzaSmartChargerStart = FetchStart & {
  type: 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_START';
};

export type GetVehicleDataFromKaluzaSmartChargerSuccess = FetchSuccess & {
  type: 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS';
  vehicleData: EVsInlifeVehicleDataResponse | null;
};

export type GetVehicleDataFromKaluzaSmartChargerError = FetchError & {
  type: 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_ERROR';
};

export type GetSavingsAndInsightDataFromKaluzaSmartChargerStart = FetchStart & {
  type: 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_START';
};

export type GetSavingsAndInsightDataFromKaluzaSmartChargerSuccess =
  FetchSuccess & {
    type: 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS';
    savingsAndInsight: EVsInlifeSavingsAndInsightDataResponse | null;
  };

export type GetSavingsAndInsightDataFromKaluzaSmartChargerError = FetchError & {
  type: 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_ERROR';
};

export type GetAnytimeSavingsDataFromKaluzaSmartChargerStart = FetchStart & {
  type: 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_START';
  year: string;
  month: string;
};

export type GetAnytimeSavingsDataFromKaluzaSmartChargerSuccess =
  FetchSuccess & {
    type: 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS';
    anytimeSavings: EVsInlifeAnytimeSavingsDataResponse | null;
  };

export type GetAnytimeSavingsDataFromKaluzaSmartChargerError = FetchError & {
  type: 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_ERROR';
};

export type DisconnectKaluzaSmartChargerDataStart = FetchStart & {
  type: 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_START';
};

export type DisconnectKaluzaSmartChargerDataSuccess = {
  type: 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_SUCCESS';
};

export type DisconnectKaluzaSmartChargerDataError = FetchError & {
  type: 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_ERROR';
};

export type GetSmartThermostatsInfoStart = FetchStart & {
  type: 'GET_SMART_THERMOSTATS_INFO_START';
  startDate: MonthYear;
  endDate: MonthYear;
  granularity: 'month' | 'day';
};

export type GetSmartThermostatsInfoSuccess = FetchSuccess & {
  type: 'GET_SMART_THERMOSTATS_INFO_SUCCESS';
  data: SmartThermostatsChartDataSeriesObj;
  toolTips: ToolTipDates;
};

export type GetSmartThermostatsInfoError = FetchError & {
  type: 'GET_SMART_THERMOSTATS_INFO_ERROR';
};

export type GetSmartThermostatsSavingsStart = FetchStart & {
  type: 'GET_SMART_THERMOSTATS_SAVINGS_START';
};

export type GetSmartThermostatsSavingsSuccess = FetchSuccess & {
  type: 'GET_SMART_THERMOSTATS_SAVINGS_SUCCESS';
  savings: string;
};

export type GetSmartThermostatsSavingsError = FetchError & {
  type: 'GET_SMART_THERMOSTATS_SAVINGS_ERROR';
};

export type GetSmartThermostatsTadoLinkStart = FetchStart & {
  type: 'GET_SMART_THERMOSTATS_TADO_LINK_START';
};

export type GetSmartThermostatsTadoLinkSuccess = FetchSuccess & {
  type: 'GET_SMART_THERMOSTATS_TADO_LINK_SUCCESS';
  link: string;
};

export type GetSmartThermostatsTadoLinkError = FetchError & {
  type: 'GET_SMART_THERMOSTATS_TADO_LINK_ERROR';
};

export type CheckEligibilityForSmartThermostatStart = FetchStart & {
  type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_START';
};

export type CheckEligibilityForSmartThermostatSuccess = FetchSuccess & {
  type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_SUCCESS';
  eligibility: Eligibility;
};

export type CheckEligibilityForSmartThermostatError = FetchError & {
  type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_ERROR';
};

export type CheckEligibilityForSmartThermostatProfessionalInstallStart =
  FetchStart & {
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_START';
    profile: ProfileNextV1Query;
  };

export type CheckEligibilityForSmartThermostatProfessionalInstallSuccess =
  FetchSuccess & {
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_SUCCESS';
    eligibility: Eligibility;
  };

export type CheckEligibilityForSmartThermostatProfessionalInstallError =
  FetchError & {
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_ERROR';
  };

export type CheckEligibilityForSmartThermostatPayMonthlyStart = FetchStart & {
  type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_START';
  // these are passed in like this as they historically were stored in redux, but now passed in via react query
  // once Smart Thermostats are migrated to react query too, these can just be passed into util hooks as needed
  directDebit: DirectDebitResponse;
  contracts: ContractsResponse;
};

export type CheckEligibilityForSmartThermostatPayMonthlySuccess =
  FetchSuccess & {
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_SUCCESS';
    eligibility: Eligibility;
  };

export type CheckEligibilityForSmartThermostatPayMonthlyError = FetchError & {
  type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_ERROR';
};

export type StoreEligibilityQuestionsAnswer = {
  type: 'STORE_ELIGIBILITY_QUESTIONS_ANSWER';
  answer: boolean;
};

export type SetEligibilityForSmartThermostatProfessionalInstall = {
  type: 'SET_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL';
  eligibility: Eligibility;
};

export type StoreOrderType = {
  type: 'STORE_ORDER_TYPE';
  orderType: OrderType;
};

export type StorePaymentType = {
  type: 'STORE_PAYMENT_TYPE';
  paymentType: PaymentType;
};

export type AddSmartThermostatsIneligibilityReason = {
  type: 'ADD_SMART_THERMOSTATS_INELIGIBILITY_REASON';
  reason: IneligibilityReasons;
};

export type AddAccountToThermostatsWaitlistStart = FetchStart & {
  type: 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_START';
};

export type AddAccountToThermostatsWaitlistSuccess = FetchSuccess & {
  type: 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_SUCCESS';
  onWaitlist: boolean;
};

export type AddAccountToThermostatsWaitlistError = FetchError & {
  type: 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_ERROR';
};

export type CheckAccountWaitlistStatusStart = FetchStart & {
  type: 'CHECK_ACCOUNT_WAITLIST_STATUS_START';
};

export type CheckAccountWaitlistStatusSuccess = FetchSuccess & {
  type: 'CHECK_ACCOUNT_WAITLIST_STATUS_SUCCESS';
  onWaitlist: boolean;
};

export type CheckAccountWaitlistStatusError = FetchError & {
  type: 'CHECK_ACCOUNT_WAITLIST_STATUS_ERROR';
};

export type RegisterSmartThermostatsOrderStart = FetchStart & {
  type: 'REGISTER_SMART_THERMOSTATS_ORDER_START';
  paymentToken?: string;
  paymentIntentId?: string;
  profile: ProfileResponse;
};

export type RegisterSmartThermostatsOrderSuccess = FetchSuccess & {
  type: 'REGISTER_SMART_THERMOSTATS_ORDER_SUCCESS';
  orderNo: string;
  paymentStatus: string;
  promoCode: string | undefined;
};

export type RegisterSmartThermostatsOrderRequiresAction = FetchSuccess & {
  type: 'REGISTER_SMART_THERMOSTATS_ORDER_REQUIRES_ACTION';
  clientSecret: string;
  paymentStatus: string;
};

export type RegisterSmartThermostatsOrderError = FetchError & {
  type: 'REGISTER_SMART_THERMOSTATS_ORDER_ERROR';
};

export type GetSmartThermostatsTadoInfoStart = FetchStart & {
  type: 'GET_SMART_THERMOSTATS_TADO_INFO_START';
};

export type UnsetPaymentStatusAndSecret = FetchStart & {
  type: 'UNSET_PAYMENT_STATUS_AND_SECRET';
};

export type GetSmartThermostatsTadoInfoSuccess = FetchSuccess & {
  type: 'GET_SMART_THERMOSTATS_TADO_INFO_SUCCESS';
  info: SmartThermostatsTadoInfo;
};

export type GetSmartThermostatsTadoInfoError = FetchError & {
  type: 'GET_SMART_THERMOSTATS_TADO_INFO_ERROR';
};

export type GetSmartThermostatsPricingSuccess = FetchSuccess & {
  type: 'GET_SMART_THERMOSTATS_PRICING_SUCCESS';
  pricing: SmartThermostatsPricing;
};

export type GetSmartThermostatsPricingStart = FetchStart & {
  type: 'GET_SMART_THERMOSTATS_PRICING_START';
  promoCode?: string;
};

export type GetSmartThermostatsPricingError = FetchError & {
  type: 'GET_SMART_THERMOSTATS_PRICING_ERROR';
};

export type GetSmartThermostatsTadoStatusStart = FetchStart & {
  type: 'GET_SMART_THERMOSTATS_TADO_STATUS_START';
};

export type GetSmartThermostatsTadoStatusSuccess = FetchSuccess & {
  type: 'GET_SMART_THERMOSTATS_TADO_STATUS_SUCCESS';
  status: SmartThermostatsTadoStatus;
};

export type GetSmartThermostatsTadoStatusError = FetchError & {
  type: 'GET_SMART_THERMOSTATS_TADO_STATUS_ERROR';
};

export type GetSmartThermostatsOrderInfoStart = FetchStart & {
  type: 'GET_SMART_THERMOSTATS_ORDER_INFO_START';
};

export type GetSmartThermostatsOrderInfoSuccess = FetchSuccess & {
  type: 'GET_SMART_THERMOSTATS_ORDER_INFO_SUCCESS';
  orderType: SmartThermostatsOrderInfo;
};

export type GetSmartThermostatsOrderInfoError = FetchError & {
  type: 'GET_SMART_THERMOSTATS_ORDER_INFO_ERROR';
};

export type ChangeSmartChargerSelection = {
  type: 'CHANGE_SMART_CHARGER_SELECTION';
  smartChargerSelection: SmartChargers;
};

export type UpdateCarMake = {
  type: 'UPDATE_CAR_MAKE';
  carMake: string | undefined;
};

export type UpdateCarModel = {
  type: 'UPDATE_CAR_MODEL';
  carModel: string | undefined;
};

export type UpdateCarYear = {
  type: 'UPDATE_CAR_YEAR';
  carYear: string | undefined;
};

export type UpdateInternetAccess = {
  type: 'UPDATE_INTERNET_ACCESS';
  internetAccess: boolean;
};

export type UpdateSolarPanels = {
  type: 'UPDATE_SOLAR_PANELS';
  solarPanels: boolean;
};

export type Action =
  | RouterAction
  | LocationChangeAction
  | TestAction
  | SelectAccount
  | GetAccountInfoStart
  | GetAccountInfoSuccess
  | GetAccountInfoError
  | ApiError
  | ResetApiError
  | GetProductCatalogueStart
  | GetProductCatalogueSuccess
  | GetProductCatalogueError
  | GetProductsStart
  | GetProductsSuccess
  | GetProductsError
  | ActivateProductStart
  | ActivateProductSuccess
  | ActivateProductError
  | CancelProductStart
  | CancelProductSuccess
  | CancelProductError
  | ActivateAnytimeProductStart
  | ActivateAnytimeProductSuccess
  | ActivateAnytimeProductError
  | CancelAnytimeProductStart
  | CancelAnytimeProductSuccess
  | CancelAnytimeProductError
  | GetBootstrapSuccess
  | GetDateRangeMonthlyUsageStart
  | GetDateRangeMonthlyUsageSuccess
  | GetDateRangeMonthlyUsageError
  | GetRecommendedDirectDebitStart
  | GetRecommendedDirectDebitSuccess
  | GetRecommendedDirectDebitError
  | GetShowSmartMeterBookingBannerStart
  | GetShowSmartMeterBookingBannerSuccess
  | GetShowSmartMeterBookingBannerError
  | GetSmartMeterBookingStart
  | GetSmartMeterBookingSuccess
  | GetSmartMeterBookingError
  | FetchTariffCostsStart
  | FetchTariffCostsSuccess
  | FetchTariffCostsError
  | SendKaluzaTokenStart
  | SendKaluzaTokenSuccess
  | SendKaluzaTokenError
  | SendKaluzaAuthTokenStart
  | SendKaluzaAuthTokenSuccess
  | SendKaluzaAuthTokenError
  | GetKaluzaSmartChargerMonthlyDataStart
  | GetKaluzaSmartChargerMonthlyDataSuccess
  | GetKaluzaSmartChargerMonthlyDataError
  | ConnectionStatusToKaluzaSmartChargerStart
  | ConnectionStatusToKaluzaSmartChargerSuccess
  | ConnectionStatusToKaluzaSmartChargerError
  | GetVehicleDataFromKaluzaSmartChargerStart
  | GetVehicleDataFromKaluzaSmartChargerSuccess
  | GetVehicleDataFromKaluzaSmartChargerError
  | GetSavingsAndInsightDataFromKaluzaSmartChargerStart
  | GetSavingsAndInsightDataFromKaluzaSmartChargerSuccess
  | GetSavingsAndInsightDataFromKaluzaSmartChargerError
  | GetAnytimeSavingsDataFromKaluzaSmartChargerStart
  | GetAnytimeSavingsDataFromKaluzaSmartChargerSuccess
  | GetAnytimeSavingsDataFromKaluzaSmartChargerError
  | DisconnectKaluzaSmartChargerDataStart
  | DisconnectKaluzaSmartChargerDataSuccess
  | DisconnectKaluzaSmartChargerDataError
  | GetSmartThermostatsInfoStart
  | GetSmartThermostatsInfoSuccess
  | GetSmartThermostatsInfoError
  | GetSmartThermostatsSavingsStart
  | GetSmartThermostatsSavingsSuccess
  | GetSmartThermostatsSavingsError
  | GetSmartThermostatsTadoLinkStart
  | GetSmartThermostatsTadoLinkSuccess
  | GetSmartThermostatsTadoLinkError
  | CheckEligibilityForSmartThermostatStart
  | CheckEligibilityForSmartThermostatSuccess
  | CheckEligibilityForSmartThermostatError
  | CheckEligibilityForSmartThermostatProfessionalInstallStart
  | CheckEligibilityForSmartThermostatProfessionalInstallSuccess
  | CheckEligibilityForSmartThermostatProfessionalInstallError
  | CheckEligibilityForSmartThermostatPayMonthlyStart
  | CheckEligibilityForSmartThermostatPayMonthlySuccess
  | CheckEligibilityForSmartThermostatPayMonthlyError
  | StoreEligibilityQuestionsAnswer
  | SetEligibilityForSmartThermostatProfessionalInstall
  | StoreOrderType
  | StorePaymentType
  | AddAccountToThermostatsWaitlistStart
  | AddAccountToThermostatsWaitlistSuccess
  | AddAccountToThermostatsWaitlistError
  | CheckAccountWaitlistStatusStart
  | CheckAccountWaitlistStatusSuccess
  | CheckAccountWaitlistStatusError
  | RegisterSmartThermostatsOrderStart
  | RegisterSmartThermostatsOrderSuccess
  | RegisterSmartThermostatsOrderRequiresAction
  | RegisterSmartThermostatsOrderError
  | GetSmartThermostatsTadoInfoStart
  | GetSmartThermostatsTadoInfoSuccess
  | GetSmartThermostatsTadoInfoError
  | GetSmartThermostatsPricingStart
  | GetSmartThermostatsPricingSuccess
  | GetSmartThermostatsPricingError
  | GetSmartThermostatsTadoStatusStart
  | GetSmartThermostatsTadoStatusSuccess
  | GetSmartThermostatsTadoStatusError
  | AddSmartThermostatsIneligibilityReason
  | UnsetPaymentStatusAndSecret
  | GetSmartThermostatsOrderInfoStart
  | GetSmartThermostatsOrderInfoSuccess
  | GetSmartThermostatsOrderInfoError
  | ShowModal
  | HideModal
  | ChangeSmartChargerSelection
  | UpdateCarMake
  | UpdateCarModel
  | UpdateCarYear
  | UpdateInternetAccess
  | UpdateSolarPanels;
