import { animated } from 'react-spring/web.cjs';
import styled from 'styled-components';
import { ReactComponent as GreenCirclesShape } from '@/src/resources/green-circles-shape.svg';
import { ReactComponent as GreenPolygonShape } from '@/src/resources/green-polygon.svg';
import { ReactComponent as GreenRectangleShape } from '@/src/resources/green-rectangle.svg';
import { ReactComponent as GreenSquare } from '@/src/resources/green-square.svg';
import { ReactComponent as GreenTriangleShape } from '@/src/resources/green-triangle-shape.svg';
import { ReactComponent as SmallGreenCirclesShape } from '@/src/resources/small-green-circles-shape.svg';
import { ReactComponent as YellowDonutShape } from '@/src/resources/yellow-donut-shape.svg';
import { ReactComponent as YellowDonutThin } from '@/src/resources/yellow-donut-thin.svg';
import mq, { mqIE11 } from '@/src/utils/mediaQuery';

// Custom media queries to handle shapes positioning
const mq1800AndLess = mq('(max-width: 1800px)');
const mq1400AndLess = mq('(max-width: 1400px)');
const mq1300AndLess = mq('(max-width: 1300px)');

// Width of the elements is calculated by dividing it's original width by 1400
// On 1400px wide screens we display shapes in their original dimensions
// 1250 is a breakpoint where we start showing shapes and they get bigger/smaller when window is being resized
export const StyledGreenTriangleShape = styled(animated(GreenTriangleShape))`
  position: absolute;
  left: -60px;
  top: 50px;
  width: 16.71%;
  height: auto;
  will-change: transform;

  ${mq1400AndLess(`
    left: -100px;
  `)}

  ${mq1300AndLess(`
    display: none;
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;

export const StyledGreenPolygonShape = styled(animated(GreenPolygonShape))`
  position: absolute;
  left: 0;
  top: 0;
  width: 25%;
  height: auto;
  will-change: transform;

  ${mq1300AndLess(`
    display: none;
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;

export const StyledYellowDonutShape = styled(animated(YellowDonutShape))`
  position: absolute;
  right: -60px;
  width: 13.14%;
  height: auto;
  top: 400px;
  will-change: transform;

  ${mq1400AndLess(`
    right: -100px;
  `)}

  ${mq1300AndLess(`
    display: none;
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;

export const StyledGreenCirclesShape = styled(animated(GreenCirclesShape))`
  position: absolute;
  left: 60px;
  width: 14%;
  height: auto;
  top: 1200px;
  will-change: transform;

  /* ((100% - 990px) / 2) is a distance between edge of the screen and the main content on desktop */
  /* 990px is a main content width on desktop */
  /* 14% is a shape width, 100px just to add some space between main content and the shape */
  ${mq1800AndLess(`
    left: calc(((100% - 990px) / 2) - 14% - 100px);
  `)}

  ${mq1300AndLess(`
    display: none;
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;

export const StyledSmallGreenCirclesShape = styled(
  animated(SmallGreenCirclesShape),
)`
  position: absolute;
  width: 4.82%;
  height: auto;
  right: 12%;
  top: 1300px;
  will-change: transform;

  /* (990px + (100% - 990px) / 2) is a x position of right side of the main content  */
  /* 100px just to add some space between main content and the shape */
  ${mq1800AndLess(`
    left: calc((990px + (100% - 990px) / 2) + 100px);
  `)}

  ${mq1300AndLess(`
    display: none;
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;

export const StyledGreenSquare = styled(animated(GreenSquare))`
  position: absolute;
  width: 8.82%;
  height: auto;
  right: 0;
  top: 800px;
  will-change: transform;

  ${mq1300AndLess(`
    display: none;
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;

export const StyledYellowDonutThin = styled(animated(YellowDonutThin))`
  position: absolute;
  width: 35%;
  top: 0;
  right: 0;
  height: auto;
  will-change: transform;

  ${mq1400AndLess(`
    right: -60px;
  `)}

  ${mq1300AndLess(`
    display: none;
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;

export const StyledGreenRectangle = styled(animated(GreenRectangleShape))`
  position: absolute;
  left: -60px;
  top: 0;
  width: 13%;
  height: auto;
  will-change: transform;

  ${mq1400AndLess(`
    left: -100px;
  `)}

  ${mq1300AndLess(`
    display: none;
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;
