import React, {
  FunctionComponent,
  MouseEvent,
  MouseEventHandler,
  forwardRef,
} from 'react';
import { Link, LinkProps } from 'react-router-dom-v5';
import routeManager from 'screen-reader-route-manager';

const callAll =
  (...fns: Array<undefined | MouseEventHandler>) =>
  (event: MouseEvent<HTMLElement>) =>
    fns.forEach(fn => fn && fn(event));

const moveFocus = () => {
  routeManager.beforeRouteChange();
};

export const RouterLink: FunctionComponent<LinkProps> = forwardRef(
  ({ onClick, ...rest }: LinkProps, ref) => {
    return (
      // @ts-ignore
      <Link onClick={callAll(moveFocus, onClick)} innerRef={ref} {...rest} />
    );
  },
);
