import { modify as navigationModify } from '@/src/components/Navigation/modify';
import { modify as homepageModify } from '@/src/pages/Home/modify';
import { modify as meterReadingsModify } from '@/src/pages/MeterReadings/modify';
import { modify as planModify } from '@/src/pages/Plan/modify';
import { modify as usageModify } from '@/src/pages/Usage/modify';
import { modify as globalErrorModify } from '@/src/pages/GlobalError/modify';

export const modify = {
  ...homepageModify,
  ...meterReadingsModify,
  ...planModify,
  ...navigationModify,
  ...usageModify,
  ...globalErrorModify,
};
