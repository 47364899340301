import { Heading3, Li, P, Strong, Ul } from '@ovotech/nebula';
import { AnalyticsRender, useAnalytics } from '@ovotech/ui-tools';

import React from 'react';
import {
  footnoteOHSQ1PromoOfferNoClaims,
  footnoteOHSQ1PromoFlashSaleOfferNoClaims,
} from '../FooterTermsAndConditions';
import Offer from './Offer/Offer';
import { FootnoteReferenced } from '@/src/components';

import { HOMEPLAN_FIXED_CONTRACT_Q1_PROMO_OFFER } from '@/src/constants/analytics-subjects';
import { createHomePlanUrl } from '@/src/constants/endpoints';
import image from '@/src/resources/offers/boiler-cover.jpg';
import { mqMediumUp } from '@/src/utils/mediaQuery';
import styled from 'styled-components';

type Props = {
  accountId: string;
};

const Roundel = styled.div`
  ${({ theme: { colors } }) => `
  width: 115px;
  height: 115px;
  position: absolute;
  font-weight: 700;
  right: 5%;
  top: -28%;
  line-height:16px;
  border-radius: 50%;
  background-color: ${colors.green.energised};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${colors.green.midnight};

 
    ${mqMediumUp(`
      
      top: -20%;
    `)}
  `}
`;

const FreeMonthNumber = styled.h2`
  font-size: 42px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
`;

const FreeStong = styled(Strong)`
  font-weight: 900;
`;

const flashSaleStartDate = new Date('2025-01-21T00:00:00');
const flashSaleEndDate = new Date('2025-02-11T23:59:59');

const getMonthsDiscount = () => {
  const currentDate = new Date();
  const isFlashSale =
    currentDate >= flashSaleStartDate && currentDate <= flashSaleEndDate;
  return isFlashSale ? 12 : 6;
};

export const HomePlanQ1Offer = ({ accountId }: Props) => {
  const analytics = useAnalytics();
  const monthsDiscount = getMonthsDiscount();

  const footnote =
    monthsDiscount === 6
      ? footnoteOHSQ1PromoOfferNoClaims
      : footnoteOHSQ1PromoFlashSaleOfferNoClaims;

  return (
    <AnalyticsRender name={HOMEPLAN_FIXED_CONTRACT_Q1_PROMO_OFFER}>
      <Offer
        image={image}
        offerId="q1PromoOffer"
        linkUrl={createHomePlanUrl(
          'offers-and-upgrades',
          accountId,
          '&utm_medium=banner&utm_source=offers-and-upgrades&utm_campaign=fixed_addon_q1_offer',
        )}
        linkText="Apply now"
        tracking={() => {
          analytics.dispatch({
            name: HOMEPLAN_FIXED_CONTRACT_Q1_PROMO_OFFER,
            type: 'click',
          });
        }}
      >
        <Heading3 as="h2">
          Get {monthsDiscount} months half price boiler cover
          <FootnoteReferenced content={footnote} />
        </Heading3>
        <Roundel>
          <FreeMonthNumber>{monthsDiscount}</FreeMonthNumber>
          <span>
            months
            <br />
            <span>
              <FreeStong>half price</FreeStong>
            </span>
          </span>
        </Roundel>

        <P>
          We helped with over 120,00 repairs last year so you know you can count
          on us. All our plans come with;
        </P>

        <Ul>
          <Li>An annual boiler service worth £84</Li>
          <Li>Unlimited callouts</Li>
          <Li>A 24/7 emergency helpline</Li>
          <Li>Access to a network of Gas Safe registered engineers</Li>
        </Ul>
      </Offer>
    </AnalyticsRender>
  );
};
