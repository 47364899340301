import { CTALink, SecondaryCTAButton, Sup } from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsRender } from '@ovotech/ui-tools';
import React from 'react';
import {
  StyledHeading2,
  StyledHomePlanContainerBodyXsell,
  StyledTermsAndConditions,
  StyledHomePlanContainer,
  StyledParagraph,
  HomePlanDefaultImageContainer,
  HomePlanDefaultImage,
} from './HomePlan.styled';
import { ActionCentreBanner } from '@/src/components/ActionCentre/Banners/Banner';
import { CTAWrapper } from '@/src/components/ActionCentre/Banners/banners.styled';
import { HOMEPLAN_ACTION_CENTRE_BANNER } from '@/src/constants/analytics-subjects';
import homePlanDefault from '@/src/resources/homeplan-default.png';

export const HomePlan: ActionCentreBanner = ({ dismiss, onClick }) => {
  return (
    <AnalyticsRender name={HOMEPLAN_ACTION_CENTRE_BANNER}>
      <StyledHomePlanContainer>
        <HomePlanDefaultImageContainer>
          <HomePlanDefaultImage src={homePlanDefault} alt="" />
        </HomePlanDefaultImageContainer>

        <StyledHomePlanContainerBodyXsell>
          <StyledHeading2>Boiler cover you can count on.</StyledHeading2>
          <StyledParagraph>
            Join the thousands who choose us for their boiler cover.
          </StyledParagraph>
          <CTAWrapper>
            <AnalyticsClick name={HOMEPLAN_ACTION_CENTRE_BANNER}>
              <CTALink
                href="https://homeservices.ovoenergy.com/?utm_medium=banner&utm_source=nba"
                onClick={onClick}
                variant="missionPrimary"
              >
                See our plans
              </CTALink>
            </AnalyticsClick>
            <SecondaryCTAButton onClick={dismiss}>Close</SecondaryCTAButton>
          </CTAWrapper>
          <StyledTermsAndConditions>
            <Sup style={{ fontSize: 7 }}>1</Sup>
            New customers only. T&Cs apply.
          </StyledTermsAndConditions>
        </StyledHomePlanContainerBodyXsell>
      </StyledHomePlanContainer>
    </AnalyticsRender>
  );
};
