import { KAPI_URL } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { useCustomerContext } from '@ovotech/ui-tools';
import { UseQueryResult, useQuery } from 'react-query';
import { buildGraphqlQueryBody } from '../utils/buildGraphqlQueryBody';
import { graphql } from './__generated__';
import { MarketingPreferencesQuery } from './__generated__/graphql';
import { CommunicationChannel } from '@monovo/kapi';

export const getMarketingPreferencesQueryCacheKey = (
  customerId?: string | null,
) => `marketing-preferences-${customerId}`;

const MARKETING_PREFERENCES_QUERY = graphql(/* kapi-codegen-graphql */ `
  query MarketingPreferences($customerId: ID!) {
    customer(id: $customerId) {
      marketingPreferences
    }
  }
`);

export const useMarketingPreferencesQuery = (): UseQueryResult<
  readonly CommunicationChannel[]
> => {
  const { customerId } = useCustomerContext();

  return useQuery(
    getMarketingPreferencesQueryCacheKey(customerId),
    () =>
      apiService.graphql<MarketingPreferencesQuery>({
        url: KAPI_URL!,
        body: buildGraphqlQueryBody(MARKETING_PREFERENCES_QUERY, {
          customerId: customerId as string,
        }),
      }),
    {
      select: customerMarketingPreferences =>
        customerMarketingPreferences.customer?.marketingPreferences ?? [],
      enabled: !!customerId,
    },
  );
};
