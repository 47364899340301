import { CTALink, SecondaryCTAButton, Strong } from '@ovotech/nebula';
import React from 'react';
import {
  ClippedImg,
  StyledDefaultImageContainer,
  StyledHeading2,
  StyledContainer,
  StyledContainerBody,
  StyledParagraph,
} from './HomePlanWinback.styled';
import { ActionCentreBanner } from '@/src/components/ActionCentre/Banners/Banner';
import { CTAWrapper } from '@/src/components/ActionCentre/Banners/banners.styled';
import { HOME_PLAN_BOILER_COVER_WINBACK } from '@/src/constants/routes';
import homePlanWinbackImg from '@/src/resources/homeplan-winback.jpg';

export const HomePlanWinback: ActionCentreBanner = ({ dismiss, onClick }) => {
  return (
    <StyledContainer>
      <StyledDefaultImageContainer>
        <ClippedImg src={homePlanWinbackImg} />
      </StyledDefaultImageContainer>
      <StyledContainerBody>
        <StyledHeading2>Boiler cover. It's worth it.</StyledHeading2>
        <StyledParagraph>
          Start your boiler cover again and we'll give you a{' '}
          <Strong>Gift Card worth up to £100.</Strong> Conditions Apply.
        </StyledParagraph>
        <CTAWrapper>
          <CTALink
            href={HOME_PLAN_BOILER_COVER_WINBACK}
            onClick={onClick}
            variant="primary"
            iconRight="off"
          >
            Get covered
          </CTALink>
          <SecondaryCTAButton onClick={dismiss}>Close</SecondaryCTAButton>
        </CTAWrapper>
      </StyledContainerBody>
    </StyledContainer>
  );
};
