import { Card, Margin, SkeletonHeading } from '@ovotech/nebula';
import React from 'react';

export const QuestionAndAnswersLoader = () => (
  <Margin top={10}>
    <Card>
      <SkeletonHeading />
      <SkeletonHeading />
      <SkeletonHeading />
    </Card>
  </Margin>
);
