import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PRODUCT_IDS } from '@/src/constants/products';
import { CatalogueBolton, SignupSource } from '@/src/types/Products';
import { ProductUpdateStatus, State } from '@/src/types/State';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import {
  activateProductStart,
  getProductCatalogueStart,
  getProductsStart,
} from '@/src/redux/ducks/products/products';
import {
  getGreenProducts,
  isGreenerEnergyCustomer,
  isGreenerEnergyBundleCustomer,
  getProductFromCatalogue,
} from '@/src/utils/products';

const defaultActivate: ProductUpdateStatus = {
  isUpdating: false,
  error: false,
  success: false,
};

enum HookStatus {
  INITIALIZING,
  COMPLETED,
}

export type UseGreenerEnergySignupResult = {
  isFetching: boolean;

  /**
   * Does the user have the Greener Energy add-on as a standalone item
   */
  hasStandaloneGreenerEnergyAddOn: boolean;

  /**
   * Does the user have the Greener Energy add-on via either tariff bundle
   * like 1 Year Fixed + Greener Energy or as a standalone add-on
   */
  hasAnyGreenerEnergyAddon: boolean;

  activateProduct: (product: CatalogueBolton, source?: SignupSource) => void;
  greenerEnergy: CatalogueBolton | null | undefined;
  activation: ProductUpdateStatus;
  greenProducts?: any;
};

export function useGreenerEnergySignup(): UseGreenerEnergySignupResult {
  const dispatch = useDispatch();

  const [hookStatus, setHookStatus] = useState(HookStatus.INITIALIZING);

  const activatedProducts = useSelector(
    (state: State) => state.products.activated,
  );

  const products = useSelector((state: State) => state.products);

  const greenerEnergy = products.catalogue?.data
    ? getProductFromCatalogue(
        products.catalogue.data,
        PRODUCT_IDS.greenerEnergy,
      )
    : null;

  const greenProducts = products.activated?.data
    ? getGreenProducts(products.activated.data)
    : [];
  const promoCode = getQueryParamValue('promoCode');

  const activeBoltons = activatedProducts.data?.boltons || [];

  const hasStandaloneGreenerEnergyAddOn =
    isGreenerEnergyCustomer(activeBoltons);

  const hasBundledGreenerEnergyAddOn =
    isGreenerEnergyBundleCustomer(activeBoltons);

  const activation =
    products.activate?.[PRODUCT_IDS.greenerEnergy] || defaultActivate;

  const isFetching =
    products.catalogue.isFetching ||
    products.activated.isFetching ||
    hookStatus === HookStatus.INITIALIZING;

  useEffect(() => {
    if (
      (!products.catalogue.isFetching && !products.catalogue.data) ||
      promoCode
    ) {
      dispatch(getProductCatalogueStart(promoCode));
    }
    if (
      !products.activated.data &&
      !products.activated.isFetching &&
      !products.activated?.errorResponse
    ) {
      dispatch(getProductsStart());
    }

    setHookStatus(HookStatus.COMPLETED);
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activateProduct = useCallback(
    (product: CatalogueBolton, source?: SignupSource) => {
      if (product.promoDiscount) {
        dispatch(
          activateProductStart(
            product.productId,
            product.versionId,
            product.promoDiscount.code,
            product.displayName,
            undefined,
            source,
            product.additionalData,
          ),
        );
      } else {
        dispatch(
          activateProductStart(
            product.productId,
            product.versionId,
            undefined,
            product.displayName,
            undefined,
            source,
            product.additionalData,
          ),
        );
      }
    },
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    isFetching,
    hasStandaloneGreenerEnergyAddOn,
    hasAnyGreenerEnergyAddon:
      hasStandaloneGreenerEnergyAddOn || hasBundledGreenerEnergyAddOn,
    activateProduct,
    greenerEnergy,
    activation,
    greenProducts,
  };
}
