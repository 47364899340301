import styled from 'styled-components';
import { CARBON_COMPARISON_GRAPH_BAR_WIDTH } from './CarbonComparisonGraph';

export const StyledLabel = styled.div`
  ${() => `
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${CARBON_COMPARISON_GRAPH_BAR_WIDTH}px;
  `}
`;

export const StyledLabelName = styled.div`
  ${() => `
    width: ${CARBON_COMPARISON_GRAPH_BAR_WIDTH}px;
    text-align: center;
    font-size: 16px;
   `}
`;

export const StyledCarbonTonnes = styled.div`
  ${({ theme }) => `
    font-family: inherit;
    font-weight: ${theme.fontWeights.semiBold};
    width: ${CARBON_COMPARISON_GRAPH_BAR_WIDTH}px;
    text-align: center;
    font-size: 20px;
  `}
`;
