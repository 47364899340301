import styled from 'styled-components';
import {
  mqMediumDown,
  mqMediumUp,
  mqSmallToMedium,
  mqSmallUp,
} from '@/src/utils/mediaQuery';

export const StyledImageBannerLockupWrap = styled.div`
  width: 100%;
  height: 160px;

  ${mqMediumUp(`
      position: absolute;
      margin: 0;
      top: 0;
      right: -1px;
      width: 35%;
      height: 100%;
    `)}

  ${mqSmallToMedium(`
    height: 360px;
  `)}
`;

export const StyledImageBannerBody = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[6]};
  `}
`;

export const StyledImageBanner = styled.div`
  ${({ theme: { colors, space } }) => `
    position: relative;
    background-color: ${colors.canvas};
    box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
    margin-top: ${space[4]};

    ${mqMediumUp(`
      padding-right: 30%;
    `)}

    &:first-of-type {
      margin-top: ${space[6]};

      ${mqSmallUp(`
      margin-top: ${space[8]};
    `)}
    }
  `}
`;

StyledImageBanner.displayName = 'ImageBanner';

export const BoldText = styled.strong`
  ${({ theme: { space } }) => `
    font-weight: bold;
    margin: ${space[2]} 0;
    display: block;
  `}
`;

export const ClippedImg = styled.div<{
  imgUrl: string;
  clipPath?: string;
}>`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;

  ${({ imgUrl }) => `
    background-image: url(${imgUrl});
    background-position: -130px center;
    background-size: 430px 100%;
  `}

  ${({ clipPath }) =>
    mqMediumUp(`
    clip-path: ${
      clipPath ? clipPath : 'polygon(0 0, 80% 0, 100% 100%, 0 100%)'
    };
    transform: rotateY(180deg);
  `)}

  ${mqMediumDown(`
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    background-size: cover;
    background-position: bottom;
  `)}
`;
