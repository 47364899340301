import React from 'react';
import theme from '@/src/core/theme';

export const BellIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    width="30"
    height="36"
    viewBox="0 0 30 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="18.5713" width="30" height="4.28571" fill="black" />
    <path
      d="M2.85742 11.4286C2.85742 5.11674 7.97417 0 14.286 0V0C20.5978 0 25.7146 5.11674 25.7146 11.4286V18.5714H2.85742V11.4286Z"
      fill="black"
    />
    <circle cx="14.2857" cy="31.4285" r="4.28571" fill="#9B9B9B" />
  </svg>
);

export const StarIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    id="star-icon"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="left-half"
      d="M11 18V0L7.5 7L0 8L5.5 13.5L4.5 21.5L11 18Z"
      fill="black"
    />
    <path
      id="right-half"
      d="M11 18V0L14.5 7L22 8L16.5 13.5L17.5 21.5L11 18Z"
      fill="#A7A7A7"
    />
  </svg>
);

export const BlackWarningIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 24L14 0L26.5 24H0Z" fill="black" />
    <path d="M9 16.5L15 9V13.5H18L12 21V16.5H9Z" fill="white" />
  </svg>
);

export const RedWarningSignIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    width="23"
    height="21"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 0L0 21H23L11.5 0ZM12.9375 19.3083H10.0625V17.15H12.9375V19.3083ZM10.0625 14.9333V14.2333V7.7H12.9375V14.9917H10.0625V14.9333Z"
      fill="#C7153E"
    />
  </svg>
);

export const Day = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path d="M5.2 6.38L9.61 5.2l1.19 4.41-4.42 1.19z" fill="currentColor" />
    <path
      d="M7.24 0h1.52v3.05H7.24zm0 13h1.52v3H7.24zM1.81 2.88L2.88 1.8 5 4 4 5zM11 12l1-1 2.16 2.16-1.04 1.04zM0 8.76V7.24h3.05v1.52zm13 0V7.24h3v1.52zM2.88 14.2L1.8 13.12 4 11l1 1zM12 5l-1-1 2.12-2.2 1.08 1.08z"
      fill="currentColor"
    />
  </svg>
);

export const Night = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M8.86 5.72A7.94 7.94 0 0111 .22a8.06 8.06 0 00-9.17 4.54 8 8 0 002.81 9.84 8 8 0 0010.23-1 9.37 9.37 0 01-3.26-1.92 8.18 8.18 0 01-2.75-5.96z"
      fill="currentColor"
    />
  </svg>
);

export const Linkedin = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M14.8 0H1.2C1 0 .9 0 .7.1.6.1.5.2.4.3.2.4.2.6.1.7 0 .8 0 1 0 1.1v13.7c0 .2 0 .3.1.4.1.1.1.3.3.4.1.1.2.2.4.2.1.2.2.2.4.2h13.6c.2 0 .3 0 .4-.1.1-.1.3-.1.4-.2.1-.1.2-.2.3-.4s.1-.3.1-.4V1.1c0-.2 0-.3-.1-.4-.1-.1-.1-.3-.3-.4-.1-.1-.2-.2-.4-.2-.1-.1-.2-.1-.4-.1zM4.9 13.4H2.4V6.2h2.4v7.2zM3.6 5.2c-.3 0-.6-.1-.8-.4-.3-.2-.4-.5-.4-.9 0-.3.1-.6.4-.9.2-.2.5-.3.8-.3.2 0 .4 0 .5.1.2 0 .4.1.5.2.1.1.2.3.3.4.1.2.2.4.2.5 0 .2 0 .4-.1.5-.1.2-.2.4-.4.5-.1.1-.3.2-.4.2-.2.1-.4.1-.6.1zm10 8.2h-2.4V9.5c0-1-.3-1.6-1.2-1.6-.3 0-.6.1-.8.3-.3.1-.4.3-.5.6-.1.2-.1.4-.1.6v4H6.2V6.2h2.4v1c.2-.4.5-.7.9-.9.4-.2.8-.3 1.3-.3 1.6 0 2.8 1 2.8 3.2v4.2z"
      fill="currentColor"
    />
  </svg>
);

export const Facebook = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M10.7 2.7h1.5V.1C11.4 0 10.7 0 10 0 7.8 0 6.3 1.3 6.3 3.8v2.1H3.8v2.8h2.5V16h2.9V8.7h2.4l.4-2.8H9.2V4c0-.8.3-1.3 1.5-1.3z"
      fill="currentColor"
    />
  </svg>
);

export const Twitter = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1.1 1.4-1.8-.6.4-1.4.7-2.1.8-.5-.5-1.1-.9-1.9-1-.7-.1-1.5 0-2.1.3-.5.4-1 1-1.3 1.6-.3.7-.4 1.4-.2 2.1-1.3-.1-2.6-.4-3.7-1C3 3.9 1.9 3.1 1.1 2.1.8 2.6.7 3.2.7 3.7s.1 1.1.4 1.5c.3.5.6.9 1.1 1.2-.5 0-1-.2-1.5-.4 0 .8.3 1.5.7 2.1s1.1 1 1.9 1.1c-.3.2-.6.2-.9.2-.2 0-.4 0-.6-.1.2.7.6 1.3 1.2 1.7.5.4 1.2.6 1.9.6-1.2.9-2.6 1.4-4 1.4H.1c1.5 1 3.2 1.5 5 1.5 1.2 0 2.5-.2 3.6-.7 1.1-.5 2.2-1.2 3-2 .9-.9 1.6-1.9 2-3 .5-1.1.7-2.4.7-3.6v-.4C15 4.3 15.6 3.7 16 3z"
      fill="currentColor"
    />
  </svg>
);

export const Instagram = (props: JSX.IntrinsicElements['svg']) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M8 6c-.4 0-.8.1-1.1.4-.3.2-.6.5-.7.8-.2.4-.2.8-.1 1.2.1.4.3.7.5 1 .3.3.6.5 1 .5.4.1.8.1 1.2-.1.4-.2.7-.4.9-.7.2-.3.3-.7.3-1.1 0-.3-.1-.5-.2-.8-.1-.2-.2-.4-.4-.6-.2-.2-.4-.3-.6-.4-.3-.1-.5-.2-.8-.2zm7.9-1.9c0-.6-.1-1.2-.3-1.8-.2-.4-.4-.8-.7-1.1-.3-.3-.7-.6-1.1-.7-.6-.2-1.2-.3-1.8-.3C10.9 0 10.6 0 8 0S5.1 0 4.1.1c-.6 0-1.2.1-1.8.3-.5.2-.9.4-1.2.7-.3.3-.5.7-.7 1.1-.2.6-.3 1.2-.3 1.9C0 5.1 0 5.4 0 8s0 2.9.1 3.9c0 .6.1 1.2.3 1.8.2.4.4.8.7 1.1.3.3.7.6 1.1.7.6.2 1.2.3 1.8.3 1.1.2 1.4.2 4 .2s2.9 0 3.9-.1c.6 0 1.2-.1 1.8-.3.4-.2.8-.4 1.1-.7.3-.3.6-.7.7-1.1.2-.6.3-1.2.3-1.8.2-1.1.2-1.4.2-4s0-2.9-.1-3.9zm-2 6.4c0 .5-.1 1-.3 1.4-.2.4-.4.7-.7 1-.3.3-.6.5-1 .7-.5.2-1 .3-1.4.3H5.7c-.5 0-1-.1-1.4-.3-.4-.1-.8-.4-1-.7-.3-.3-.5-.6-.7-1-.2-.5-.3-1-.3-1.4V8.1 5.7c0-.5.1-1 .3-1.4.1-.4.4-.8.7-1 .3-.3.6-.5 1-.7.5-.2 1-.3 1.4-.3h4.8c.5 0 1 .1 1.4.3.4.1.8.4 1 .7.3.3.5.6.7 1 .2.5.3 1 .3 1.4v4.8zm-1.3-6c-.1-.3-.2-.5-.4-.7-.2-.2-.4-.3-.7-.4-.4-.1-.7-.2-1.1-.2H5.6c-.4 0-.8.1-1.1.2-.3.1-.5.2-.7.4-.2.2-.3.4-.4.7-.1.4-.2.7-.2 1.1v4.8c0 .4.1.8.2 1.1.1.3.2.5.4.7.2.2.4.3.7.4.3.1.7.2 1.1.2h4.8c.4 0 .8-.1 1.1-.2.3-.1.5-.2.7-.4.2-.2.3-.4.4-.7.1-.4.2-.7.2-1.1V8 5.6c0-.4 0-.8-.2-1.1zM8 11.1c-.4 0-.8-.1-1.2-.2-.4-.2-.7-.4-1-.7-.3-.3-.5-.6-.7-1C5 8.8 5 8.4 5 8c0-.6.2-1.2.5-1.7s.8-.9 1.4-1.1S8 4.9 8.6 5c.6.1 1.1.4 1.6.8.4.4.7 1 .8 1.6.1.6.1 1.2-.2 1.8-.2.6-.6 1-1.1 1.4-.5.3-1.1.5-1.7.5zm3.2-5.6c-.2 0-.3-.1-.5-.2-.1-.1-.2-.3-.2-.5s.1-.3.2-.5c.1-.1.3-.2.5-.2s.3.1.5.2c.1.1.2.3.2.5s-.1.3-.2.5c-.2.1-.3.2-.5.2z"
      fill="currentColor"
    />
  </svg>
);

export const OVOCalculator = (props: JSX.IntrinsicElements['svg']) => (
  <svg viewBox="0 0 71.88 74.55" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 66.21h71.88v8.34H0zM0 0h7.99v58.46H0z" fill="#0d8426" />
    <path d="M12.78 11.69h7.99v46.77h-7.99z" fill="#6cbd27" />
    <path d="M25.56 23.38h7.99v35.08h-7.99z" fill="#9dce27" />
    <path d="M38.33 35.08h7.99v23.38h-7.99z" fill="#b6d727" />
    <path d="M51.11 23.38h7.99v35.08h-7.99z" fill="#9dce27" />
    <path d="M63.89 17.54h7.99v40.92h-7.99z" fill="#6cbd27" />
  </svg>
);

export const OVOComputer = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    width="48"
    height="40"
    viewBox="0 0 48 40"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path id="a" d="M0 40h48V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M31 31H17v-2H1v3a2 2 0 0 0 2 2h42a2 2 0 0 0 2-2v-3H31v2z"
        fill="#FFF"
      />
      <path
        d="M31 31H17v-2H1v3a2 2 0 0 0 2 2h42a2 2 0 0 0 2-2v-3H31v2z"
        stroke="#369A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 31h14v-2h13V4a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v25h13v2z"
        fill="#FFF"
      />
      <path
        d="M17 31h14v-2h13V4a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v25h13v2z"
        stroke="#369A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path fill="#CDE6C6" mask="url(#b)" d="M8 25h32V5H8z" />
      <path
        stroke="#369A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#b)"
        d="M8 25h32V5H8z"
      />
      <path
        d="M20.818 19.73c0 .939-1.027 1.27-2.313 1.27-1.513 0-2.087-.765-3.148-.765-.4 0-.905.139-1.409.365a.983.983 0 0 1-.436.087.916.916 0 0 1-.92-.921c0-.314.157-.644.503-.87.82-.54 1.304-1.183 1.304-1.861 0-.33-.086-.644-.207-.939h-1.496c-.383 0-.696-.313-.696-.731 0-.365.313-.678.696-.678h.591c-.434-.591-.8-1.235-.8-2.087 0-2.209 2.157-3.6 4.227-3.6 1.564 0 3.148.574 3.512 1.478.035.122.07.244.07.383 0 .643-.505 1.043-1.079 1.043-.139 0-.295-.017-.434-.069-.748-.296-1.009-.818-1.775-.818-1.025 0-1.826.644-1.826 1.635 0 .87.453 1.409.853 2.035h1.93c.366 0 .68.313.68.713a.695.695 0 0 1-.68.696h-1.39c0 .104.016.226.016.33 0 .817-.487 1.548-1.113 1.93.261-.086.591-.121.853-.121 1.008 0 1.408.574 2.278.574.383 0 .748-.174 1.096-.174.556 0 1.113.435 1.113 1.095"
        fill="#369A1A"
        mask="url(#b)"
      />
      <path
        d="M25 21h20a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H25a2 2 0 0 1-2-2V23a2 2 0 0 1 2-2"
        fill="#FFF"
        mask="url(#b)"
      />
      <path
        d="M25 21h20a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H25a2 2 0 0 1-2-2V23a2 2 0 0 1 2-2z"
        stroke="#369A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#b)"
      />
      <path fill="#FFF" mask="url(#b)" d="M27 29h5v-4h-5z" />
      <path
        stroke="#369A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#b)"
        d="M27 29h5v-4h-5z"
      />
      <path
        d="M44 26a2 2 0 1 1-4.001-.001A2 2 0 0 1 44 26"
        fill="#369A1A"
        mask="url(#b)"
      />
      <path
        d="M41.5 26a2 2 0 1 1-4.001-.001A2 2 0 0 1 41.5 26"
        fill="#369A1A"
        mask="url(#b)"
      />
      <path
        d="M27 35h16"
        stroke="#369A1A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#b)"
      />
    </g>
  </svg>
);

export const OVOCalendar = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path id="a" d="M0 48h48V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M44 47H4a3 3 0 0 1-3-3V12h46v32a3 3 0 0 1-3 3" fill="#FFF" />
      <path
        d="M44 47H4a3 3 0 0 1-3-3V12h46v32a3 3 0 0 1-3 3z"
        stroke="#469A00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 4H4a3 3 0 0 0-3 3v37a3 3 0 0 0 3 3h40a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3h-9.5M1 12h46M1 23.666h46M1 35.334h46M12.5 12v35M24 12v35m11.5-35v35"
        stroke="#469A00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path fill="#CFE6C4" mask="url(#b)" d="M24 35.167h11.5v-11.5H24z" />
      <path
        stroke="#469A00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#b)"
        d="M24 35.167h11.5v-11.5H24zM13.5 4a3 3 0 1 1 3 3m5 0a3 3 0 1 0 0-6m5 6a3 3 0 1 0 0-6m5 6a3 3 0 1 0 0-6m-4.25 25.916l5 5m0-5l-5 5"
      />
    </g>
  </svg>
);

export const OVORefunds = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M7.736 7.736A22.928 22.928 0 0 1 24 1c12.702 0 23 10.297 23 23 0 12.702-10.298 23-23 23S1 36.702 1 24M7.736 7.736V1.603M7.736 7.736h6.134"
        stroke="#469A00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5 27h-23a1 1 0 0 1-1-1V15a1 1 0 0 1 1-1h23a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1"
        fill="#C9E3BD"
      />
      <path
        d="M35.5 27h-23a1 1 0 0 1-1-1V15a1 1 0 0 1 1-1h23a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1zM11.5 31.081h25M11.5 35.081h25"
        stroke="#469A00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M27.5 20.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" fill="#C9E3BD" />
      <path
        d="M27.5 20.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
        stroke="#469A00"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const Wallet = (props: JSX.IntrinsicElements['svg']) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      opacity=".3"
      d="M41 30.975a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM6.778 13.308l30.889-8.222c1.11-.333 2.11.333 2.444 1.445l2.556 9.666c.333 1.111-.334 2.111-1.445 2.445l-30.889 8.333c-1.11.333-2.11-.333-2.444-1.445l-2.556-9.666c-.333-1.111.334-2.222 1.445-2.556Z"
    />

    <path
      d="M44 21.642c-1-.445-2-.667-3.222-.667C37.11 21.086 34 24.197 34 27.864c-.111 3.889 3.111 7.111 7 7.111 1.111 0 2.111-.222 3-.667v8.667H4v-32h40v10.667Z"
      fill="currentColor"
    />
  </svg>
);

export const LoadingIcon = () => (
  <svg
    data-testid="loading-spinner"
    width="20"
    height="20"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="loading"
  >
    <defs>
      <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
        <stop stopColor="#fff" stopOpacity="0" offset="0%" />
        <stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
        <stop stopColor="#fff" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          id="Oval-2"
          stroke="#fff"
          strokeWidth="2"
          // Prevent this being overridden by nebulas CTA icon styles.
          style={{ fill: 'none' }}
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </path>
        <circle fill="#fff" cx="36" cy="18" r="1">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </g>
  </svg>
);

export const ElectricityIcon = ({
  color = theme.colors.primary,
}: {
  color: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 3V10H18.5L10 21V14H5.5L14 3Z" fill={color} />
  </svg>
);

export const GasIcon = ({
  color = theme.colors.primary,
}: {
  color: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3V3.35C12 4.95 11.05 6.35 9.65 7C7.8 7.85 6.5 9.65 6.5 11.75C6.5 14.65 8.95 17 12 17V16.65C12 15.05 12.95 13.65 14.35 13C16.2 12.15 17.5 10.35 17.5 8.25C17.5 5.35 15.05 3 12 3Z"
      fill={color}
    />
    <path opacity="0.3" d="M4.5 19V21H19.5V19H4.5Z" fill={color} />
  </svg>
);
