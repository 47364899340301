import { Observable } from 'rxjs';

import { SMART_THERMOSTATS_ENDPOINT_V1 } from '@/src/constants/endpoints';
import { AjaxResponse } from '@/src/types/Ajax';
import apiService from '@/src/services/apiService';
import {
  SmartThermostatsInfo,
  SmartThermostatsTempDataPoint,
  SmartThermostatsSavings,
  SmartThermostatsTadoLink,
  SmartThermostatsTadoInfo,
  SmartThermostatsTadoStatus,
  SmartThermostatsTempDataSeries,
  SmartThermostatsOrderInfo,
  SmartThermostatsPricing,
} from '@/src/types/Response';
import {
  monthObjType,
  SmartThermostatsChartDataPoint,
  SmartThermostatsChartDataSeries,
  SmartThermostatsChartDataSeriesObj,
  ToolTipDates,
  weekdaysObjType,
  MonthYear,
} from '@/src/types/State';
import { DayEnum, MonthEnum } from '@/src/types/utils';

/*
  Types
*/
const endpointsEnum = {
  measurements: 'measurements',
  tadoLink: 'generate-tado-link',
  savings: 'savings',
};

const makeStatusEndpoint = (accountId: string) => {
  return `${SMART_THERMOSTATS_ENDPOINT_V1}/tado/${accountId}/status`;
};

type ZippedApiResponseType = {
  insideTemperature: SmartThermostatsTempDataPoint;
  outsideTemperature: SmartThermostatsTempDataPoint;
};

type ApiDataByDate = {
  [start: string]: any;
};

export type ZippedApiResponseSeriesType = ZippedApiResponseType[];

/*
  Chart Data Functions
*/

export const responseZip = (
  apiData: SmartThermostatsInfo,
): ZippedApiResponseSeriesType => {
  const apiDataByDate: ApiDataByDate = {};
  const dataGroups = ['insideTemperature', 'outsideTemperature'] as const;

  dataGroups.forEach(group => {
    const groupData: SmartThermostatsTempDataSeries = apiData[group];

    if (groupData) {
      groupData.data.forEach((data: SmartThermostatsTempDataPoint) => {
        const startAt = data.interval.start;

        if (!apiDataByDate[startAt]) {
          const temperatureDefaultData: SmartThermostatsTempDataPoint = {
            interval: data.interval,
            value: undefined,
          };

          const zippedDay = {
            insideTemperature: temperatureDefaultData,
            outsideTemperature: temperatureDefaultData,
          };

          apiDataByDate[startAt] = zippedDay;
        }

        apiDataByDate[startAt][group] = data;
      });
    }
  });

  return Object.values(apiDataByDate);
};

const getDateFromZipped = (zip: ZippedApiResponseType) => {
  // All of the dates should be the same for each data point
  const date = new Date(zip.insideTemperature.interval.start);
  return date.getDate();
};

export const mergeZipped = (
  zippedData: ZippedApiResponseSeriesType,
): SmartThermostatsChartDataSeries => {
  const mergeZipped = (
    zipped: ZippedApiResponseType,
  ): SmartThermostatsChartDataPoint => {
    const date = getDateFromZipped(zipped);
    return {
      day: date,
      ind: zipped.insideTemperature.value,
      out: zipped.outsideTemperature.value,
    };
  };
  return zippedData.map(mergeZipped);
};

export const processApiData = (
  apiData: SmartThermostatsInfo,
): SmartThermostatsChartDataSeries => {
  // Right now we're doing some processing client side
  // in order to allow us a bit more flexibility as we develop our API.
  const zippedApiData = responseZip(apiData);
  const mergedData = mergeZipped(zippedApiData);
  return mergedData;
};

const padNumber = (n: number): string => {
  return String(n).padStart(2, '0');
};

export const generateUrl = (
  startDate: MonthYear,
  endDate: MonthYear,
  customerId: number,
  granularity: string,
): string => {
  let url = SMART_THERMOSTATS_ENDPOINT_V1;
  url += '/';
  url += endpointsEnum.measurements;
  url += `?startDate=${startDate.year}-${padNumber(startDate.month)}-01`;
  url += `&endDate=${endDate.year}-${padNumber(endDate.month)}-01`;
  url += `&granularity=${granularity}`;
  url += `&accountId=${customerId}`;
  return url;
};

const getSmartThermostatInfoAPIData = (
  startDate: MonthYear,
  endDate: MonthYear,
  customerId: number,
  granularity: string,
): Observable<SmartThermostatsInfo> => {
  const url = generateUrl(startDate, endDate, customerId, granularity);
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

export const getSmartThermostatsData = (
  startDate: MonthYear,
  endDate: MonthYear,
  customerId: number,
  granularity: string,
): Observable<SmartThermostatsChartDataSeries> => {
  return getSmartThermostatInfoAPIData(
    startDate,
    endDate,
    customerId,
    granularity,
  ).map(resp => {
    return processApiData(resp);
  });
};

export const buildDateKey = (startDate: MonthYear, endDate: MonthYear) => {
  return `${startDate.year}-${startDate.month}->${endDate.year}-${endDate.month}`;
};

export const updateData = (
  allData: SmartThermostatsChartDataSeriesObj,
  series: SmartThermostatsChartDataSeries,
  startDate: MonthYear,
  endDate: MonthYear,
  granularity: 'month' | 'day',
): SmartThermostatsChartDataSeriesObj => {
  const granularityKey = granularity;
  const dateKey = buildDateKey(startDate, endDate);
  if (allData[granularityKey]) {
    return {
      ...allData,
      [granularityKey]: {
        ...allData[granularityKey],
        [dateKey]: series,
      },
    };
  }
  return {
    ...allData,
    [granularityKey]: { [dateKey]: series },
  };
};

/*
  Tooltip Functions
*/

const getLocale = () => {
  // Right now we're only interested in generating dates in English
  // But in the future this should allow us to generate the correct copy for each locale
  // TODO: Where is the language setting stored?
  return 'en-GB';
};

export const generateWeekdays = (date: MonthYear): weekdaysObjType => {
  const weekdays: weekdaysObjType = {};
  const month = new Date(date.year, date.month - 1, 0);
  const numDays = month.getDate();
  const days = [...Array(numDays).keys()];
  const locale = getLocale();
  days.forEach(day => {
    weekdays[day + 1] = new Date(
      date.year,
      date.month - 1,
      day + 1,
    ).toLocaleString(locale, {
      weekday: 'long',
    }) as DayEnum;
  });
  return weekdays;
};

export const getMonthName = (date: MonthYear): MonthEnum => {
  const month = new Date(date.year, date.month - 1, 1);
  const locale = getLocale();
  const monthName = month.toLocaleString(locale, {
    month: 'long',
  }) as MonthEnum;
  return monthName;
};

const generateTooltipDayObj = (date: MonthYear): monthObjType => {
  const weekdays = generateWeekdays(date);
  const monthName = getMonthName(date);
  return { month: monthName, weekdays };
};

export const extendDateObj = (
  state: ToolTipDates,
  date: MonthYear,
): ToolTipDates => {
  const newState = { ...state };
  if (newState[date.year] === undefined) {
    newState[date.year] = {};
  }
  newState[date.year][date.month] = generateTooltipDayObj(date);
  return newState;
};

/*
  Tado Functions
*/

export const getSmartThermostatsTadoInfo = (
  accountId: string,
): Observable<SmartThermostatsTadoInfo> => {
  const url = `${SMART_THERMOSTATS_ENDPOINT_V1}/tado/${accountId}/info`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

export const getSmartThermostatsTadoLink = (
  customerId: number,
): Observable<SmartThermostatsTadoLink> => {
  const url = `${SMART_THERMOSTATS_ENDPOINT_V1}/${endpointsEnum.tadoLink}?accountId=${customerId}`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

export const getSmartThermostatsTadoStatus = (
  accountId: string,
): Observable<SmartThermostatsTadoStatus> => {
  const url = makeStatusEndpoint(accountId);
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

/*
  Savings Functions
*/

export const getSmartThermostatsSavings = (
  customerId: number,
): Observable<SmartThermostatsSavings> => {
  const url = `${SMART_THERMOSTATS_ENDPOINT_V1}/${endpointsEnum.savings}?accountId=${customerId}`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

export const getSmartThermostatsOrderInfo = (
  customerId: number,
): Observable<SmartThermostatsOrderInfo> => {
  const url = `${SMART_THERMOSTATS_ENDPOINT_V1}/orders/${customerId}/info`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

export const getSmartThermostatsPricing = (
  accountId: string,
  customerId: string,
  promoCode?: string,
): Observable<SmartThermostatsPricing> => {
  const url = `${SMART_THERMOSTATS_ENDPOINT_V1}/pricing/account/${accountId}/customer/${customerId}${
    promoCode ? `?promoCode=${promoCode}` : ''
  }`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};
