import {
  apiService,
  configService,
  UpdateDirectDebitAmountResponse,
} from '@ovotech/energy-cx';
import { useReactMutation } from '@ovotech/ui-tools';

function useOneClickPaymentReductionLegacyMutation(
  accountId: string,
  onSuccess?: (response: UpdateDirectDebitAmountResponse) => void,
) {
  const { api } = configService.getConfig();
  const oneClickPaymentReductionEndpoint = `${api.baseUrl}/pace/payments/v1/${accountId}/covid-hardship/join`;

  return useReactMutation(
    ({ amount }: { amount: number }) =>
      apiService.post<UpdateDirectDebitAmountResponse>({
        responseType: 'json',
        url: oneClickPaymentReductionEndpoint,
        body: { amount },
      }),
    {
      onSuccess: response => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
    },
  );
}

export { useOneClickPaymentReductionLegacyMutation };
