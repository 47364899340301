import { MonthYear } from '@/src/types/State';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom-v5';

export const formatDateForUsageQuery = (date: Date) => format(date, 'YYYY-MM');

export const getDateFromParams = (): MonthYear => {
  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { year, month } = useParams<{ year: string; month: string }>();
  const date = { year: parseInt(year, 10), month: parseInt(month, 10) };
  return date;
};
