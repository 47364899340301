import styled from 'styled-components';

import { mqSmallUp } from '@/src/utils/mediaQuery';

const StyledCallout = styled.div`
  ${({ theme: { colors, space } }) => `

    ${mqSmallUp(`
      border-left: 5px solid ${colors.primary};
      padding-left: ${space[2]};
    `)}
  `}
`;

export default StyledCallout;
