import { useAccountContext } from '@ovotech/ui-tools';
import { useMutation } from 'react-query';
import { carbonZeroBffEndpoints } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';

export const useHalfHourlyOptInMutation = () => {
  const { accountId } = useAccountContext();

  return useMutation({
    mutationKey: `half-hourly-opt-in-${accountId}`,
    mutationFn: () =>
      !accountId
        ? Promise.reject()
        : apiService.put({
            responseType: 'json',
            url: carbonZeroBffEndpoints.putHalfHourlyOptIn(accountId),
          }),
  });
};
