import styled from 'styled-components';
import { mqIE11 } from '@/src/utils/mediaQuery';

export const Header = styled.div`
  ${({ theme: { space, colors } }) => `
    background-color: ${colors.brand.primary.base};
    position: relative;
    width: 75%;
    height: 88px;

    display: flex;
    flex-direction: column;
    padding-left: ${space[8]};
    justify-content: center;

    &:after {
      ${mqIE11(`
        display: none;
      `)}
      content: '';
      position: absolute;
      right: -59px;
      width: 60px;
      height: 100%;
      background-color: ${colors.brand.primary.base};
      clip-path: polygon(0 0, 60px 0, 1px 100%, 0 100%);
    }
  `}
`;

export const HeaderTitle = styled.h2`
  color: white;
  margin-bottom: 0;
`;

export const HeaderSubtitle = styled.span`
  ${({ theme: { fontSizes } }) => `
    color: white;
    font-size: ${fontSizes[1]};
  `}
`;
