import React from 'react';
import { HeaderTitle, HeaderDescription } from './PageHeader.styled';

type HeaderProps = {
  title: string;
  showDescription?: boolean;
  testId?: string;
};

export const PageHeader = (props: HeaderProps) => {
  const { title, showDescription, testId } = props;
  return (
    <>
      <HeaderTitle data-testid={testId}>{title}</HeaderTitle>
      {showDescription && (
        <HeaderDescription>
          A supercharged add-on for your OVO Energy plan
        </HeaderDescription>
      )}
    </>
  );
};

PageHeader.defaultProps = {
  showDescription: true,
};
