import { Card, Heading3, Heading4 } from '@ovotech/nebula';
import styled from 'styled-components';

export const Container = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: transparent;
`;

export const StyledTitle = styled(Heading3)`
  color: black;
`;
export const StyledSubtitle = styled(Heading4)`
  color: #635e58;
`;

export const Reasons = styled.div`
  width: 100%;
`;

export const Reason = styled(Card)`
  ${({ theme: { space } }) => `
      margin-bottom: ${space[8]};
      padding: ${space[6]};

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
      box-shadow: 0px 0px 6px 0px #00000040;
  `}
`;
