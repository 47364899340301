function setSvgAttributes(
  wrapper: null | undefined | HTMLElement,
  description: string,
) {
  if (wrapper) {
    const svg = wrapper.querySelector('svg');
    if (svg) {
      svg.setAttribute('role', 'img');
      svg.setAttribute('focusable', 'false');
      svg.setAttribute('aria-label', description);

      const textNodeList = svg.querySelectorAll('text');
      textNodeList.forEach(textNode => {
        textNode.setAttribute('aria-hidden', 'true');
      });
    }
  }
}

export default setSvgAttributes;
