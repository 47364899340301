import styled from 'styled-components';

import { ButtonLooksLikeLink } from '@/src/components';
import { ReactComponent as DownloadIcon } from '@/src/resources/download-icon.svg';
import { mqSmallUp } from '@/src/utils/mediaQuery';

export const StyledButtonLooksLikeLink = styled(ButtonLooksLikeLink)`
  display: flex;
  align-items: center;

  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[1]};
    ${mqSmallUp(`
      font-size: ${fontSizes[2]};
    `)}
  `}
`;

export const StyledDownloadIcon = styled(DownloadIcon)`
  margin-right: 6px;

  ${({ theme: { fontSizes } }) => `
    width: ${fontSizes[3]};
    ${mqSmallUp(`
      width: ${fontSizes[4]};
    `)}
  `}
`;
