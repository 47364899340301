import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSmartThermostatsPricingStart } from '@/src/redux/ducks/smartThermostats/smart-thermostats-info';
import { SMART_THERMOSTATS_MAINTENANCE } from '@/src/constants/feature-flags';
import { State } from '@/src/types/State';
import { useFeature } from '@/src/utils/optimizely';

export function usePricing(promoCode?: string) {
  const dispatch = useDispatch();
  const [maintenanceEnabled] = useFeature(SMART_THERMOSTATS_MAINTENANCE);

  const { pricing, error, isFetching } = useSelector(
    ({ smartThermostats }: State) => smartThermostats,
  );

  useEffect(() => {
    if (!pricing && !isFetching && !error && !maintenanceEnabled) {
      dispatch(getSmartThermostatsPricingStart(promoCode));
    }
  }, [dispatch, error, isFetching, pricing, promoCode, maintenanceEnabled]);

  return pricing;
}
