import { Card } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqMediumDown, mqMediumUp, mqSmallDown } from '@/src/utils/mediaQuery';
import { FlexCardContent } from '@/src/pages/EnergyInsights/components/FlexCard/FlexCard';

export const StyledActionSlide = styled(Card)`
  ${({ theme: { space } }) => `
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: ${space[6]};
  `}
  ${mqSmallDown(`
      padding: 0;
    `)}
`;

export const ActionSlideCard = styled(Card)`
  display: flex;

  padding: 0;
  ${mqMediumUp(`
    flex-basis: 48%;
  `)}
  ${mqMediumDown(`
    flex-basis: 100%;
  `)}

  h4 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.4rem;
  }
`;

export const ActionSlideCardContent = styled(FlexCardContent)`
  padding: 0;
  ${({ theme: { borderWidths, radii, colors } }) => `
    border: ${borderWidths.standard} solid ${colors.borderMuted};
    border-radius: ${radii.standard};
  `}
  justify-content: flex-start;
`;
