import { useQuery } from 'react-query';
import { carbonZeroBffEndpoints } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { CarbonIntensityResponse } from '@/src/types/Response';

export const useGetCarbonIntensity = () =>
  useQuery('carbonIntensity', () =>
    apiService.get<CarbonIntensityResponse>({
      url: carbonZeroBffEndpoints.getCarbonIntensity,
    }),
  );
