import React from 'react';
import { ActionCentreBanner } from '../Banner';
import { HomePlan } from './HomePlan';
import { HomePlanFreeForFourMonths } from './HomePlanFreeForFourMonths';
import { PROMO_FREE_FOR_FIRST_FOUR_MONTHS_ENABLED } from '@/src/constants/feature-flags';
import { OptimizelyFeature } from '@/src/utils/optimizely';

export const ControlHomePlanBanner: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  return (
    <OptimizelyFeature feature={PROMO_FREE_FOR_FIRST_FOUR_MONTHS_ENABLED}>
      {isEnabled =>
        isEnabled ? (
          <HomePlanFreeForFourMonths dismiss={dismiss} onClick={onClick} />
        ) : (
          <HomePlan dismiss={dismiss} onClick={onClick} />
        )
      }
    </OptimizelyFeature>
  );
};
