import { Heading3 } from '@ovotech/nebula';
import React, { PropsWithChildren } from 'react';
import {
  Card,
  CardBody,
  CardNumber,
  CardNumberWrapper,
} from './NumberedCard.styled';

type Props = {
  orderNumber: number;
};

const NumberedCard = ({ orderNumber, children }: PropsWithChildren<Props>) => {
  return (
    <Card>
      <CardNumberWrapper>
        <CardNumber as={Heading3}>{orderNumber}</CardNumber>
      </CardNumberWrapper>
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default NumberedCard;
