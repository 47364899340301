import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSmartThermostatsOrderInfoStart } from '@/src/redux/ducks/smartThermostats/smart-thermostats-info';

import { State } from '@/src/types/State';
import { OrderType } from '../utils/types';

export const useInstallType = (): OrderType | null => {
  const dispatch = useDispatch();

  const orderType = useSelector(
    (state: State) => state.smartThermostats.orderDetails.orderType,
  );
  const { errorResponse, isFetching } = useSelector(
    ({ smartThermostats }: State) => smartThermostats,
  );

  useEffect(() => {
    if (orderType === null && !errorResponse && !isFetching) {
      dispatch(getSmartThermostatsOrderInfoStart());
    }
  }, [dispatch, orderType, errorResponse, isFetching]);

  return orderType;
};
