import { useAccountContext } from '@ovotech/ui-tools';
import { isAfter, subMonths } from 'date-fns';
import { useHomeProfileWidgetQuery } from '@/src/api';
import { useLocalStorage } from '@/src/utils/useLocalStorage';
import { HomeProfileWidgetPosition } from '../EnergyInsights/components/HomeProfileWidget/HomeProfileWidget.types';

export const useUsageHomeProfileWidget = () => {
  const { accountId } = useAccountContext();
  const [dismissedDate, setDismissedDate] = useLocalStorage(
    `hp_widget_dismissed_usage_${accountId}`,
    '',
  );

  const isDismissed =
    !!dismissedDate &&
    isAfter(new Date(dismissedDate), subMonths(new Date(), 6));

  const { data } = useHomeProfileWidgetQuery({ enabled: !isDismissed });

  const widgetPosition: HomeProfileWidgetPosition =
    !data || isDismissed ? 'hidden' : data.recentlyUpdated ? 'bottom' : 'top';

  return {
    widgetPosition,
    data: widgetPosition === 'hidden' ? null : data,
    onClose: () => setDismissedDate(new Date().toISOString()),
  };
};
