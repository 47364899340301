import { Heading1, P } from '@ovotech/nebula';
import React from 'react';
import {
  Title,
  SubTitle,
  Grid,
  StyledSimplePageHeaderTitle,
  StyledSimpleHeaderSubTitle,
} from './SimplePageHeader.styled';

type Props = {
  title: string;
  subTitle?: string;
};

export const SimplePageHeader = ({ title, subTitle }: Props) => {
  return (
    <Grid>
      <Title>
        <StyledSimplePageHeaderTitle as={Heading1}>
          {title}
        </StyledSimplePageHeaderTitle>
      </Title>
      {subTitle && (
        <SubTitle>
          <StyledSimpleHeaderSubTitle as={P}>
            {subTitle}
          </StyledSimpleHeaderSubTitle>
        </SubTitle>
      )}
    </Grid>
  );
};
