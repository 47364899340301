import React, { forwardRef, MouseEventHandler, ReactElement } from 'react';
import { StyledNavLink, StyledNavLinkLabel } from '../../Header/Header.styled';
import { StyledLinkContent } from './NavLink.styled';
import Link from '../../Link/Link';
import AnalyticsService from '@/src/services/analyticsService';

type Props = {
  path: string;
  route: string;
  label: string;
  onClick?: MouseEventHandler;
  // eslint-disable-next-line react/require-default-props
  'data-testid'?: string;
  'data-event-name'?: string;
  'data-event-props'?: string;
  isActiveChild?: boolean;
  hasLightBackground?: boolean;
  badge?: ReactElement;
  showBadge?: boolean;
};

const assertNavLinkIsActive = (path: string, route: string) => {
  const routeParentMatcher = new RegExp(/^\/[^/$/]*/);
  const routeMatch = routeParentMatcher.exec(route) || [];

  // @ts-ignore
  return route === path || (route !== '/' && path.startsWith(routeMatch[0]));
};

const getActiveStatus = (
  path: string,
  route: string,
  isActiveChild?: boolean,
) => {
  const isActive = assertNavLinkIsActive(path, route);
  return isActiveChild !== undefined ? isActive && isActiveChild : isActive;
};

const onClickHandler = (
  onClick: MouseEventHandler,
  dataEventName?: string,
  dataEventProps?: string,
) => {
  if (dataEventName) {
    AnalyticsService.dispatch(dataEventName, { dataEventProps });
  }
  //@ts-ignore
  return onClick && onClick();
};

const NavLink = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      path,
      route,
      label,
      onClick,
      'data-testid': dataTestId,
      'data-event-name': dataEventName,
      'data-event-props': dataEventProps,
      isActiveChild,
      hasLightBackground,
      badge,
      showBadge,
    },
    ref,
  ) => (
    <StyledNavLink
      as={Link}
      ref={ref}
      data-testid={dataTestId}
      aria-current={route === path ? 'page' : undefined}
      onClick={() =>
        onClick && onClickHandler(onClick, dataEventName, dataEventProps)
      }
      to={route}
      className={
        getActiveStatus(path, route, isActiveChild) ? 'active' : undefined
      }
    >
      <StyledLinkContent>
        {showBadge && badge}
        <StyledNavLinkLabel hasLightBackground={hasLightBackground}>
          {label}
        </StyledNavLinkLabel>
      </StyledLinkContent>
    </StyledNavLink>
  ),
);

export default NavLink;
