import { Card } from '@ovotech/nebula';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom-v5';
import SideNavigationLinks from './Navigation/SideNavigationLinks';
import { StyledSideNavigationPanel } from './StyledSideNavigation.styled';
import { State } from '@/src/types/State';
import { isGreenerEnergyCustomer } from '@/src/utils/products';

const SideNavigationContainer = () => {
  const { pathname } = useLocation();

  const { activated } = useSelector((state: State) => state.products);
  const smartMeterBooking = useSelector(
    (state: State) => state.smartMeterBooking,
  );
  const { selectedAccountId: accountId } = useSelector(
    (state: State) => state.user,
  );

  const hasGreenerEnergy =
    !!activated.data && isGreenerEnergyCustomer(activated.data.boltons);
  const smartEligible = smartMeterBooking.showBanner;
  const hasSmartMeterBooking = smartMeterBooking.data?.booking.hasBooking;

  return (
    <StyledSideNavigationPanel as={Card} data-testid="sidenav">
      <SideNavigationLinks
        path={pathname}
        hasGreenerEnergy={hasGreenerEnergy}
        accountId={accountId || ''}
        smartEligible={smartEligible}
        hasSmartMeterBooking={hasSmartMeterBooking}
      />
    </StyledSideNavigationPanel>
  );
};

export default SideNavigationContainer;
