import { HomeProfileAnswers } from '../../../types';
import { homeProfileConfig } from './config';

export const filterEmptyObjectFields = <
  T extends Record<string, string | string[] | null | false>,
>(
  objectToFilter: T,
): Partial<T> => {
  for (const propName in objectToFilter) {
    const value = objectToFilter[propName];
    if (
      // optional fields seem to be given a value of `false` by default
      // if we send these to server we'll get a 400 (bad request)
      value === false ||
      value === null ||
      value === undefined ||
      (Array.isArray(value) && !value.length)
    ) {
      delete objectToFilter[propName];
    }
  }
  return objectToFilter;
};

export const checkStepDataIsFilled = (
  step: number,
  homeProfileData?: Partial<HomeProfileAnswers>,
): boolean => {
  if (!homeProfileData) {
    return false;
  }

  const stepConfig = homeProfileConfig.getQuestionStepConfig(step);

  return (
    stepConfig &&
    Object.keys(filterEmptyObjectFields(homeProfileData)).includes(
      stepConfig.fieldSetName,
    )
  );
};
