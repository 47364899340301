import { Heading2, Li, P, Ul } from '@ovotech/nebula';
import { AnalyticsRender } from '@ovotech/ui-tools';
import React, { FC } from 'react';
import { footnoteHomeRecoverPromo } from '../FooterTermsAndConditions';
import { FootnoteReferenced } from '@/src/components';
import { HOME_RECOVER_CARD } from '@/src/constants/analytics-subjects';
import { createHomeRecoverUrl } from '@/src/constants/endpoints';
import Offer from '@/src/pages/Offers/components/cards/Offer/Offer';
import image from '@/src/resources/offers/home-recover.jpg';

interface HomeRecoverPromoOfferProp {
  accountId: string;
}

export const HomeRecoverPromoOffer: FC<HomeRecoverPromoOfferProp> = ({
  accountId,
}) => {
  return (
    <AnalyticsRender name={HOME_RECOVER_CARD}>
      <Offer
        image={image}
        linkUrl={createHomeRecoverUrl('homeRecover', accountId)}
        linkText={'Find out more'}
        offerId={'homeRecover'}
      >
        <Heading2>Get a free repair with Home Recover</Heading2>
        <P>
          Got an issue with your boiler, heating, plumbing, electrics or
          internal drains? As an OVO energy customer, you can get Home Recover
          <FootnoteReferenced content={footnoteHomeRecoverPromo} />, which
          includes:
        </P>
        <Ul>
          <Li>your upfront repair completed for free</Li>
          <Li>our most comprehensive cover plan</Li>
          <Li>an annual boiler service</Li>
        </Ul>
        <P>Plans start from £24 a month.</P>
      </Offer>
    </AnalyticsRender>
  );
};
