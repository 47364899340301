import React from 'react';
import {
  Node,
  ProgressContainer,
} from '@/src/pages/MoveIn/components/ProgressBar.styled';

export type ProgressBarProps = {
  currentPage: number;
  numberOfPages: number;
};

export function ProgressBar({ currentPage, numberOfPages }: ProgressBarProps) {
  const range = [...new Array(numberOfPages).keys()];

  return (
    <ProgressContainer data-testid="progress-bar">
      {range.map(pageOrder => (
        <Node
          key={pageOrder}
          pageNumber={pageOrder + 1}
          activePage={currentPage + 1}
          numberOfPages={numberOfPages}
        />
      ))}
    </ProgressContainer>
  );
}
