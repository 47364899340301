import { TelField, TextField } from '@ovotech/nebula';
import React from 'react';
import { FormProps } from '../CustomerDetails';

export function FormContactDetails({ existingDetails, formErrors }: FormProps) {
  return (
    <>
      <TelField
        autoComplete="tel-national"
        id="phone"
        name="phone"
        label="Mobile number"
        maxLength={13}
        characters={13}
        error={formErrors.phone}
        defaultValue={
          existingDetails.status === 'success'
            ? existingDetails.data.customer_nextV1.contactPhoneNumbers[0] || ''
            : ''
        }
        hint={
          <>
            We'll use this to send you SMS updates on your OVO account.
            <br />
            If you don't have a mobile number, you can enter a landline number
            instead.
          </>
        }
      />
      <TextField
        autoComplete="email"
        label="Email"
        type="email"
        id="emailAddress"
        name="emailAddress"
        error={formErrors.emailAddress}
        defaultValue={
          existingDetails.status === 'success'
            ? existingDetails.data.customer_nextV1.contactEmail
            : ''
        }
        hint={
          <>
            You'll use this to log in to your account. <br />
            It's also how we'll keep in touch with you.
          </>
        }
      />
    </>
  );
}
