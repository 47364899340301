import React from 'react';

import { OffersAndUpgrades } from './OffersAndUpgrades';
import { PageWithNavigation } from '@/src/components';

export const OffersPage = () => (
  <PageWithNavigation title="Offers and Upgrades">
    <OffersAndUpgrades />
  </PageWithNavigation>
);
