import React from 'react';
import { CARBON_ZERO } from '@/src/constants/analytics';

import testIDs from '../../EnergyInsights/components/HomeProfileWidget/testIDs';
import {
  HomeProfileWidgetEvents,
  HomeProfileWidgetPosition,
  HomeProfileWidgetWithResponsiveContent,
} from '../../EnergyInsights/components/HomeProfileWidget';
import { useUsageHomeProfileWidget } from '../useUsageHomeProfileWidget';

type Props = {
  pagePosition: HomeProfileWidgetPosition;
};

const events: HomeProfileWidgetEvents = {
  viewed: CARBON_ZERO.HOME_PROFILE_WIDGET.VIEWED,
  ctaClicked: CARBON_ZERO.HOME_PROFILE_WIDGET.CTA_CLICKED,
  dismissed: CARBON_ZERO.HOME_PROFILE_WIDGET.CLOSE_BUTTON_CLICKED,
};

export const UsageHomeProfileWidget = ({ pagePosition }: Props) => {
  const { data, onClose } = useUsageHomeProfileWidget();

  if (!data) {
    return null;
  }

  return (
    <div data-testid={testIDs.widgetPosition(pagePosition)}>
      <HomeProfileWidgetWithResponsiveContent
        {...data}
        onDismiss={onClose}
        events={events}
      />
    </div>
  );
};
