import { useAccountContext } from '@ovotech/ui-tools';
import { useProductsQuery } from '../../../api/use-products-query';
import { hasSmartThermostatBolton } from '../utils/bolton';

export const useHasThermostatBolton = () => {
  const { accountId } = useAccountContext();
  const { data, isLoading } = useProductsQuery(accountId);
  const boltons = data?.boltons ?? [];
  const hasBolton =
    boltons.length > 0 ? hasSmartThermostatBolton(boltons) : false;

  return { hasBolton, isLoading };
};
