import React, { ComponentProps, useId } from 'react';
import styled from 'styled-components';

const StyledButtonGroup = styled.div(
  ({ theme: { mediaQueries, space } }) => `
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -${space[2]};

    ${mediaQueries.smallAndUp} {
      margin-bottom: -${space[3]};
      margin-right: -${space[3]};
    }
  `,
);

const StyledButtonGroupItem = styled.div(
  ({ theme: { mediaQueries, space } }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${space[2]};
    width: 100%;

    ${mediaQueries.smallAndUp} {
      width: auto;
      margin-right: ${space[3]};
      margin-bottom: ${space[3]};
    }
  `,
);

const ButtonGroup = ({
  children,
  ...rest
}: ComponentProps<typeof StyledButtonGroup>) => {
  const id = useId();
  return (
    <StyledButtonGroup {...rest}>
      {React.Children.map(children, (child, i) => (
        <StyledButtonGroupItem key={`${id}-${i}`}>
          {child}
        </StyledButtonGroupItem>
      ))}
    </StyledButtonGroup>
  );
};

export { ButtonGroup };
