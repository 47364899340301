import { useLocalStorage } from '@/src/utils/useLocalStorage';

type LocalTipInteractionRecord = Record<string, TipInteractionStatus>;
export type HandleUpdateTipInteractionStatus = (
  title: string,
) => (tipInteractionStatus: TipInteractionStatus) => void;

export type UseGetTipInteractionsFromLocalStorage = {
  tipInteractionStatuses: LocalTipInteractionRecord;
  setTipInteractionStatus: HandleUpdateTipInteractionStatus;
};
export enum TipInteractionStatus {
  NotForMe = 'not-for-me',
  WillDo = 'will-do',
  AlreadyDoing = 'already-doing',
}

const removeKey = (
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { [key]: _, ...rest }: LocalTipInteractionRecord,
) => rest;

export const useGetTipInteractionsFromLocalStorage =
  (): UseGetTipInteractionsFromLocalStorage => {
    const [tipInteractionStatuses, _setTipInteractionStatuses] =
      useLocalStorage<LocalTipInteractionRecord>('tip_interactions', {});

    const setTipInteractionStatus: HandleUpdateTipInteractionStatus =
      (interactionTitle: string) =>
      (status: TipInteractionStatus): void => {
        const newRecord =
          status === tipInteractionStatuses[interactionTitle]
            ? removeKey(interactionTitle, tipInteractionStatuses)
            : {
                ...tipInteractionStatuses,
                [interactionTitle]: status,
              };

        return _setTipInteractionStatuses(newRecord);
      };

    return {
      tipInteractionStatuses,
      setTipInteractionStatus,
    };
  };

type OldInteractionStatuses = 'tick' | 'plus' | 'cross';
export type OldRecord = { title: string; actioned: OldInteractionStatuses }[];
const oldInteractionStatusesToNew: Record<
  OldInteractionStatuses,
  TipInteractionStatus
> = {
  tick: TipInteractionStatus.AlreadyDoing,
  plus: TipInteractionStatus.WillDo,
  cross: TipInteractionStatus.NotForMe,
};

export const convertOldTipInteractionRecordToNew = (
  interactionArray: OldRecord,
): LocalTipInteractionRecord => {
  return interactionArray.reduce(
    (acc, { title, actioned }) => ({
      ...acc,
      [title]: oldInteractionStatusesToNew[actioned],
    }),
    {},
  );
};
