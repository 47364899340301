import { P } from '@ovotech/nebula';
import React from 'react';
import { StyledSkeletonCTA } from './SkeletonLoading.styled';

type Props = JSX.IntrinsicElements['div'] & {
  size?: string;
};
export const SkeletonCTA = ({ size = '25rem', ...rest }: Props) => (
  <StyledSkeletonCTA as={P} size={size} {...rest} />
);
