import { Small, TextLink } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import {
  HELP_OVO_ENERGY_INTEREST_REWARD_CALCULATION,
  OVO_ENERGY_ANYTIME_CREDIT_EXPLAINED,
} from '@/src/constants/links';

type Props = {
  description: string;
  context?: React.ReactNode;
};

const StyledContext = styled.div(
  ({ theme: { mediaQueries } }) => `
      white-space: nowrap;
      display: block;
      ${mediaQueries.smallAndUp} {
        display: inline-block;
      }
    `,
);

const contextLinks = [
  {
    'data-testid': 'interest-reward',
    description: 'Interest Reward',
    link: HELP_OVO_ENERGY_INTEREST_REWARD_CALCULATION,
  },
  {
    'data-testid': 'anytime-charging-credit',
    // description: 'Charge Anytime charging credit', @TODO: Change it after BASTC-6166 changes
    description: 'Anytime Charging Credit',
    link: OVO_ENERGY_ANYTIME_CREDIT_EXPLAINED,
  },
];

export const TransactionTitle = ({ description, context }: Props) => {
  const contextLink = contextLinks.find(
    item => item.description === description,
  );

  return contextLink ? (
    <>
      {description}
      {context && (
        <>
          {' '}
          <StyledContext as={Small}>{context}</StyledContext>
        </>
      )}

      {contextLink && (
        <>
          {' '}
          <StyledContext as={Small}>
            <TextLink
              data-testid={`transaction-help-link-${contextLink['data-testid']}`}
              href={contextLink.link}
            >
              How is this calculated?
            </TextLink>
          </StyledContext>
        </>
      )}
    </>
  ) : (
    <>
      {description} <StyledContext as={Small}>{context}</StyledContext>
    </>
  );
};
