import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import styled from 'styled-components';
import {
  Banner,
  BannerProps,
} from '@/src/components/ActionCentre/Banners/Banner';
import { breakpoints } from '@/src/utils/mediaQuery';
import { useLocalStorage } from '@/src/utils/useLocalStorage';

const StyledBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.space[6]};
  @media ${breakpoints.smallUp} {
    margin-bottom: ${({ theme }) => theme.space[8]};
  }
`;
interface OneTimeBannerProps extends BannerProps {
  path: string;
  dismiss?: () => void;
}

export const OneTimeBanner = (props: OneTimeBannerProps) => {
  const { path, icon, children, dismiss, ...rest } = props;
  const { accountId } = useAccountContext();
  const [shown, setShown] = useLocalStorage(`${path}-${accountId}`, false);

  const onDismissHandler = () => {
    setShown(true);
    dismiss?.();
  };

  if (shown) return null;

  return (
    <StyledBanner icon={icon} dismiss={onDismissHandler} {...rest}>
      {children}
    </StyledBanner>
  );
};
