import { CTALink, Heading1, P } from '@ovotech/nebula';
import { AnalyticsPage } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom-v5';
import testIds from '../../testIds';
import { ConfirmationWrapper } from '../GreenerElectricitySignup.styled';
import { useGreenerElectricitySignup } from '../hooks/useGreenerElectricitySignup';

import { GREENER_ELECTRICITY_SIGNUP_SUCCESS } from '@/src/constants/analytics-subjects';
import { PRODUCT_DISPLAY_NAMES, PRODUCT_IDS } from '@/src/constants/products';
import { ROUTE_GREENER_ELECTRICITY } from '@/src/constants/routes';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';

const signUpTestIds = testIds.signUp;

export const SignupSuccess = () => {
  const history = useHistory();
  const promoCode = getQueryParamValue('promoCode');

  const { hasStandaloneGreenerElectricityAddOn, activation } =
    useGreenerElectricitySignup();

  useEffect(() => {
    // Redirect to Greener Electricity
    if (hasStandaloneGreenerElectricityAddOn && !activation.success) {
      history.push(ROUTE_GREENER_ELECTRICITY);
    }
  }, [hasStandaloneGreenerElectricityAddOn, activation, history]);

  return (
    <AnalyticsPage
      name={GREENER_ELECTRICITY_SIGNUP_SUCCESS}
      properties={{ promoCode }}
    >
      <ConfirmationWrapper
        data-testid={signUpTestIds.confirmation.wrapper}
        role="status"
      >
        <Heading1>Nice one!</Heading1>
        <P>
          You’ve upgraded to{' '}
          {PRODUCT_DISPLAY_NAMES[PRODUCT_IDS.greenerElectricity]}
        </P>
        <CTALink
          href={ROUTE_GREENER_ELECTRICITY}
          variant="primary"
          data-testid={signUpTestIds.confirmation.cta}
        >
          Start your journey now
        </CTALink>
      </ConfirmationWrapper>
    </AnalyticsPage>
  );
};
