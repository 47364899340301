import { Card } from '@ovotech/nebula';

import React, { useMemo } from 'react';
import { hubTestIDs } from '../../testIDs';
import {
  StyledNoMarginH2,
  StyledNoMarginP,
} from '../CarbonComparison/CarbonComparison.styled';

import TipSlideshow from '../TipSlideshow/TipSlideshow';

import { GridContainer } from '@/src/pages/EnergyInsights/components';
import { useGetTipInteractionsFromLocalStorage } from '@/src/pages/EnergyInsights/hooks/useGetTipInteractionsFromLocalStorage';
import { pickRandomActions } from '@/src/pages/EnergyInsights/utils/spotlights';
import {
  TipContent,
  mergedTipsByCategory,
  thermostatTip,
} from '@/src/pages/EnergyInsights/utils/tips';

const NonSmartTopTips = () => {
  // EG-1759: We want the thermostat tip to always be shown and not in the first position
  const randomTips: TipContent[] = useMemo(() => {
    const nonSmartTips = {
      ...mergedTipsByCategory,
      heating: mergedTipsByCategory.heating.filter(
        tip => tip.title !== thermostatTip.title,
      ),
    };
    const randomTips = pickRandomActions(nonSmartTips, 5);
    randomTips.splice(Math.floor(Math.random() * 5) + 1, 0, thermostatTip);
    return randomTips;
  }, []);

  const tipInteractions = useGetTipInteractionsFromLocalStorage();

  return (
    <GridContainer>
      <StyledNoMarginH2 data-testid={hubTestIDs.nonSmartTips}>
        Simple energy-saving tips
      </StyledNoMarginH2>
      <StyledNoMarginP>
        Scroll through some great tricks that’ll help you kick carbon.
      </StyledNoMarginP>
      <Card>
        <TipSlideshow
          tipInteractions={tipInteractions}
          tips={randomTips}
          category={'randomTipsAllCategories'}
        />
      </Card>
    </GridContainer>
  );
};

export default NonSmartTopTips;
