import React from 'react';
import { Link as RouterLink } from 'react-router-dom-v5';
import styled from 'styled-components';

import { mqSmallUp } from '@/src/utils/mediaQuery';

const StyledSkipLinkStyle = styled.a`
  ${({ theme: { fontSizes, colors, space, fonts, fontWeights } }) => `

    position: absolute;
    top: ${space[4]};
    left: -999px;

    text-decoration: none;
    color: ${colors.primary};
    font-size: ${fontSizes[1]};
    font-family: ${fonts.display};
    font-weight: ${fontWeights.semiBold};
    background-color: ${colors.canvasMuted};
    border: 2px solid ${colors.shadeMidLight};
    padding: ${space[2]};

    &:focus {
      left: ${space[4]};
      outline: none;
    }

    ${mqSmallUp(`
      font-size: ${fontSizes[2]};
    `)}
  `}
`;

export const StyledSkipLink = (props: any) => (
  <StyledSkipLinkStyle {...props} as={RouterLink} />
);
