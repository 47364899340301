import styled from 'styled-components';

export const Title = styled.p`
  ${({ theme: { fontSizes } }) => `
  font-size: ${fontSizes[2]};
`}
`;

export const StyledSection = styled.div<{
  isTopStat?: boolean;
  isMiddle?: boolean;
}>(
  ({
    theme: { space, colors, borderWidths, mediaQueries },
    isTopStat,
    isMiddle,
  }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: ${
    isMiddle ? `${borderWidths.heavy} solid ${colors.borderMuted}` : ''
  };
  border-left: ${
    isMiddle ? `${borderWidths.heavy} solid ${colors.borderMuted}` : ''
  };
  flex: 2;
  min-width: 195px;
  margin: ${space[2]} 0px;
  padding: 0px ${space[4]};
  ${mediaQueries.smallAndUp} {
    flex: ${isTopStat ? 2 : 4};
  }
`,
);
