import React from 'react';

export const MeterTickImage = () => (
  <svg
    width="119"
    height="121"
    viewBox="0 0 119 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#B6D727"
      d="M17.695 0l100.352 17.695-17.695 100.352L0 100.352z"
    />
    <mask
      id="a"
      maskUnits="userSpaceOnUse"
      x="2"
      y="7"
      width="114"
      height="114"
    >
      <circle cx="59.06" cy="64.451" r="56.5" fill="#C4C4C4" />
    </mask>
    <g mask="url(#a)">
      <path
        d="M34.295 91.424v-7.158H48.61l-14.316 7.158zM84.402 91.424v-7.158H70.086l14.316 7.158z"
        fill="#0D8426"
      />
      <path d="M91.56 66.37H27.138v17.896H91.56V66.37z" fill="#0A9928" />
      <path
        opacity=".3"
        d="M84.402 34.158c0-3.937-3.22-7.158-7.158-7.158-3.937 0-7.158 3.221-7.158 7.158H48.61c0-3.937-3.22-7.158-7.158-7.158-3.937 0-7.158 3.221-7.158 7.158h-7.158V66.37H91.56V34.158h-7.159zm-7.158 23.264h-35.79V43.106h35.79v14.316z"
        fill="#0A9928"
      />
    </g>
    <circle cx="99.598" cy="101.598" r="18.598" fill="#258244" />
    <g clipPath="url(#clip0)">
      <path d="M92.195 102.695l4.5 4.5 9.5-9" stroke="#fff" strokeWidth="3" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="#fff"
          transform="translate(91.195 95.195)"
          d="M0 0h16v16H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
