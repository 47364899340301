import { P } from '@ovotech/nebula';
import React from 'react';
import { useParams } from 'react-router-dom-v5';
import { getDateFromParams, dateSuffix, mapToMonths } from '../../utils';
import { roundTo2DP } from '@/src/utils/number';
import {
  StyledToolTip,
  StyledToolTipDate,
  StyledToolTipInfo,
} from './ChartCustomTooltip.styled';
import { MONTHS_SHORT } from '@/src/constants/misc';
import { EVsChartDataPointFormatted } from '@/src/types/State';

const ToolTipValue = ({
  value,
  unit,
  label,
}: {
  value: number | string;
  unit: '£' | 'kWh';
  label: string;
}) => {
  const valueWithUnit =
    unit === '£'
      ? `${unit} ${roundTo2DP(value)}`
      : `${roundTo2DP(value)} ${unit} `;

  return (
    <StyledToolTipInfo>
      <P>{label}</P>
      {value ? <P>{valueWithUnit}</P> : <P>-</P>}
    </StyledToolTipInfo>
  );
};

const CustomTooltip = (info: any) => {
  const { active, payload, usageUnit }: TooltipType = info;
  const { year, month } = useParams<{ year: string; month: string }>();
  const date = getDateFromParams(month, year);
  const showValueInPounds = usageUnit === 'pounds';
  if (active && payload) {
    const payloadData = payload[0].payload;
    return (
      <StyledToolTip className="custom-tooltip">
        <StyledToolTipDate className="date" as={P}>
          {month &&
            year &&
            payloadData?.dayOfMonth &&
            `${payloadData.dayOfMonth}${dateSuffix(
              payloadData.dayOfMonth,
            )} ${mapToMonths(date.month)} ${date.year}`}
          {year &&
            !month &&
            payloadData?.monthOfYear &&
            `${MONTHS_SHORT[payloadData.monthOfYear - 1]} ${date.year}`}
        </StyledToolTipDate>
        <ToolTipValue
          label="Total"
          value={
            showValueInPounds
              ? payloadData.electricityHomeInPounds +
                payloadData.electricityCarInPounds
              : payloadData.electricityHomeInKWH +
                payloadData.electricityCarInKWH
          }
          unit={showValueInPounds ? '£' : 'kWh'}
        />
        <ToolTipValue
          label="Home"
          value={
            showValueInPounds
              ? payloadData.electricityHomeInPounds
              : payloadData.electricityHomeInKWH
          }
          unit={showValueInPounds ? '£' : 'kWh'}
        />
        <ToolTipValue
          label="EV"
          value={
            showValueInPounds
              ? payloadData.electricityCarInPounds
              : payloadData.electricityCarInKWH
          }
          unit={showValueInPounds ? '£' : 'kWh'}
        />
      </StyledToolTip>
    );
  }

  return null;
};

type UsageUnitType = 'kwh' | 'pounds';
interface TooltipType {
  active: boolean;
  payload: PayloadType[];
  usageUnit: UsageUnitType;
  label: string;
}

interface PayloadType {
  color: string;
  dataKey:
    | 'dayOfMonth'
    | 'monthOfYear'
    | 'electricityCarInPounds'
    | 'electricityHomeInPounds'
    | 'electricityHomeInKWH'
    | 'electricityCarInKWH';
  fill: string;
  formatter: unknown;
  name: string;
  payload: EVsChartDataPointFormatted;
  type: unknown;
  unit: unknown;
  value: number;
}

export default CustomTooltip;
