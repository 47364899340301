import { Heading2, Li, Margin, P, Strong, TextLink, Ul } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { RENEWAL_FAQ } from '@/src/constants/analytics-subjects';
import { HELP_OVO_ENERGY_EMERGENCY } from '@/src/constants/links';

const GoodToKnowHeading = ({ children }: { children: React.ReactNode }) => (
  <P style={{ marginBottom: 0 }}>
    <Strong>{children}</Strong>
  </P>
);

export const GoodToKnow = () => {
  return (
    <>
      <Heading2 as="h4">Good to know</Heading2>

      <GoodToKnowHeading>How to get free, independent advice</GoodToKnowHeading>
      <P style={{ marginTop: 0 }}>
        It’s important to know your rights when it comes to energy. You can get
        free advice from{' '}
        <TextLink opensInNewWindow href="https://www.citizensadvice.org.uk/">
          Citizens Advice
        </TextLink>
        , if you need a hand. Just visit their website, or call them on{' '}
        <TextLink href="tel:08082231133">0808 223 1133</TextLink> (Monday to
        Friday, 9am-5pm).
      </P>

      <GoodToKnowHeading>Tell us if something’s not right</GoodToKnowHeading>
      <P style={{ marginTop: 0 }}>
        If we ever make a mistake,{' '}
        <TextLink opensInNewWindow href="https://www.ovoenergy.com/feedback">
          please let us know right away
        </TextLink>{' '}
        so we can put things right for you as soon as possible.
      </P>

      <GoodToKnowHeading>Get your questions answered</GoodToKnowHeading>
      <P style={{ marginTop: 0, marginBottom: 0 }}>
        You've got lots of ways to do that:
      </P>
      <Ul>
        <Li>
          Chat with us via our{' '}
          <TextLink
            opensInNewWindow
            href="https://help.ovoenergy.com/#contact_us_container"
          >
            online chat
          </TextLink>{' '}
          (Mon-Fri 8am-6pm, Sat 9am-1pm)
        </Li>
        <Li>
          You can check out our online{' '}
          <TextLink opensInNewWindow href="https://help.ovoenergy.com/">
            Help pages
          </TextLink>
        </Li>
        <Li>
          Or ask other members on the{' '}
          <TextLink opensInNewWindow href="https://forum.ovoenergy.com/">
            OVO Forum
          </TextLink>
        </Li>
      </Ul>
      <P>
        If you can't find what you're looking for online, just give us a call on{' '}
        <TextLink href="tel:03303035063">0330 303 5063</TextLink> (Monday to
        Friday 8am-6pm). It could take us a little longer than usual to answer,
        so please bear with us. We’ll get to you as soon as we can.
      </P>

      <GoodToKnowHeading>Handy links for you</GoodToKnowHeading>
      <Ul>
        <Li>
          <AnalyticsClick
            name={RENEWAL_FAQ}
            properties={{
              question: 'What the most popular energy terms mean',
            }}
          >
            <TextLink
              opensInNewWindow
              href="https://www.ovoenergy.com/guides/energy-guides/what-is-a-kwh-kw-and-kwh-explained.html"
            >
              What the most popular energy terms mean
            </TextLink>
          </AnalyticsClick>
        </Li>
        <Li>
          <AnalyticsClick
            name={RENEWAL_FAQ}
            properties={{
              question: 'What to do in an emergency',
            }}
          >
            <TextLink opensInNewWindow href={HELP_OVO_ENERGY_EMERGENCY}>
              What to do in an emergency
            </TextLink>
          </AnalyticsClick>
        </Li>
        <Li>
          <AnalyticsClick
            name={RENEWAL_FAQ}
            properties={{
              question: 'How to contact your local network operator',
            }}
          >
            <TextLink
              opensInNewWindow
              href="https://help.ovoenergy.com/article/ce747f2b-c7b4-482a-9f0f-f082422a7588-orion"
            >
              How to contact your local network operator
            </TextLink>
          </AnalyticsClick>
        </Li>
      </Ul>

      <Margin bottom={6} />
    </>
  );
};
