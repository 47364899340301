import {
  CTAButton,
  Card,
  Heading2,
  Heading4,
  Margin,
  P,
} from '@ovotech/nebula';
import React from 'react';
import { PriceLabel } from './CostCard.styled';
import { Divider } from './Divider.styled';
import { Tariff } from '@/src/pages/MoveIn/types/quoteRequest';
import { getPlanDetails } from '@/src/pages/MoveIn/utils/planDetails';

type CostCardProps = {
  tariff: Tariff;
  continueMoveIn?: () => void;
};

export function CostCard({ tariff, continueMoveIn }: CostCardProps) {
  const { monthlyCost, planDetails } = getPlanDetails(tariff);

  return (
    <Card data-testid="cost-card">
      <Heading2>Monthly breakdown</Heading2>
      <P>PLAN</P>
      <PriceLabel>
        <Heading4>{planDetails.title}</Heading4>
        <Heading4 style={{ margin: 0 }}>£{monthlyCost}</Heading4>
      </PriceLabel>
      <P>{tariff.tils.Gas ? 'Electricity & Gas' : 'Electricity'}</P>

      <Divider />
      <PriceLabel>
        <Heading4>TOTAL</Heading4>
        <Heading4 style={{ margin: 0 }}>£{monthlyCost}</Heading4>
      </PriceLabel>

      <Margin vertical={4} />

      {continueMoveIn ? (
        <CTAButton
          data-testid="next-button"
          type="button"
          iconRight="chevron-right"
          variant="missionPrimary"
          onClick={continueMoveIn}
        >
          Next
        </CTAButton>
      ) : null}
    </Card>
  );
}
