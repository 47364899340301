import { Card, Heading3, P } from '@ovotech/nebula';
import React from 'react';
import {
  StyledDonateContainer,
  StyledDonateInfo,
  StyledDonateLogo,
  StyledDonatePicture,
  StyledCTALink,
  StyledMain,
  StyledTitle,
  StyledParagraph,
  StyledFormContainer,
} from './OvoFoundationDonate.styled';
import { DonateForm } from './components/DonateForm';
import { OVO_FOUNDATION_LINK } from '@/src/constants/links';
import { CatalogueBolton } from '@/src/types/Products';

type Props = {
  ovoFoundation: CatalogueBolton;
};

export const OvoFoundationDonate = ({ ovoFoundation }: Props) => (
  <div data-testid="foundation-donate-form">
    <StyledMain as={Card}>
      <StyledDonatePicture />
      <StyledDonateContainer>
        <StyledDonateInfo>
          <StyledDonateLogo />
          <StyledTitle as={Heading3}>
            <strong>Your donation has the power to change lives</strong>
          </StyledTitle>
          <StyledParagraph as={P}>
            Our charity, OVO Foundation, is creating a greener, brighter future
            for the next generation. We’re supporting climate action and
            education projects across the UK, helping kids to see they have the
            power to make a difference and protect the planet they love.
          </StyledParagraph>
          <StyledParagraph as={P}>
            100% of your donation goes directly to our projects, and OVO matches
            every penny donated. So, together, we can do double the good!
          </StyledParagraph>
          <StyledParagraph as={P}>
            <strong>"I feel great because there's hope."</strong> - Year 6
            student from Llanmartin primary school.
          </StyledParagraph>
          <StyledCTALink
            href={OVO_FOUNDATION_LINK}
            iconRight="off"
            opensInNewWindow
          >
            See our projects
          </StyledCTALink>
        </StyledDonateInfo>
        <StyledFormContainer>
          <DonateForm ovoFoundation={ovoFoundation} />
        </StyledFormContainer>
      </StyledDonateContainer>
    </StyledMain>
  </div>
);
