import React from 'react';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { ReactComponent as TickSelectedIcon } from '@/src/resources/tick-icon-green.svg';
import { TipInteractionStatus } from '@/src/pages/EnergyInsights/hooks/useGetTipInteractionsFromLocalStorage';
import { ReactComponent as CrossIconSelected } from '@/src/resources/cross_green_icon.svg';
import { ReactComponent as CrossIcon } from '@/src/resources/cross_grey_icon.svg';
import { ReactComponent as PlusSelectedIcon } from '@/src/resources/plus_green_icon.svg';
import { ReactComponent as PlusIcon } from '@/src/resources/plus_grey_icon.svg';
import { ReactComponent as TickIcon } from '@/src/resources/tick_grey_icon.svg';

export const iconsInactive: Record<TipInteractionStatus, JSX.Element> = {
  [TipInteractionStatus.NotForMe]: <CrossIcon />,
  [TipInteractionStatus.WillDo]: <PlusIcon />,
  [TipInteractionStatus.AlreadyDoing]: <TickIcon />,
};
export const iconsActive: Record<TipInteractionStatus, JSX.Element> = {
  [TipInteractionStatus.NotForMe]: <CrossIconSelected />,
  [TipInteractionStatus.WillDo]: <PlusSelectedIcon />,
  [TipInteractionStatus.AlreadyDoing]: <TickSelectedIcon />,
};

export const buttonCaptions: Record<TipInteractionStatus, string> = {
  [TipInteractionStatus.NotForMe]: 'Not for me',
  [TipInteractionStatus.AlreadyDoing]: 'Already doing',
  [TipInteractionStatus.WillDo]: 'Will do',
};

export const analyticEvents: Record<TipInteractionStatus, string> = {
  [TipInteractionStatus.NotForMe]: ENERGY_INSIGHTS.BUTTON_CLICK_TIP_NOT_FOR_ME,
  [TipInteractionStatus.WillDo]: ENERGY_INSIGHTS.BUTTON_CLICK_TIP_WILL_DO,
  [TipInteractionStatus.AlreadyDoing]:
    ENERGY_INSIGHTS.BUTTON_CLICK_TIP_ALREADY_DOING,
};
