import { useMoveOutDateQuery } from '@/src/api';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { ContactUsLink, ErrorSection } from '@/src/components';
import { BANNER_CTA_CLICKED } from '@/src/constants/analytics';
import { OVO_QUOTE_AND_SWITCH } from '@/src/constants/endpoints';
import { MOVE_OUT_IN_PROGRESS_ERROR } from '@/src/constants/error-section-ids';
import { SkeletonCard } from '@/src/pages/Home/components/SkeletonCard';
import { MoveOutDateResponse } from '@/src/types/MoveOut';
import { formatFullDate } from '@/src/utils/date';
import { formatPostalAddress } from '@/src/utils/format-postal-address';
import {
  Card,
  Heading3,
  Heading4,
  P,
  PrimaryCTALink,
  Strong,
} from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { ActionCentreBanner, Banner } from './Banner';
import { CollapsibleBlock } from './CollapsibleBlock';

export const MoveOutInProgress: ActionCentreBanner = () => {
  const { accountId } = useAccountContext();

  const moveOutQuery = useMoveOutDateQuery(accountId || '');
  const profile = useProfileQueryKapi();

  const formattedAddress = formatPostalAddress(
    profile?.data?.customer_nextV1.contactAddress?.postalAddress,
  );

  const moveOutDateFormatted = moveOutQuery.data?.moveOutDate
    ? formatFullDate(
        new Date(
          (moveOutQuery.data as MoveOutDateResponse).moveOutDate as string,
        ),
      )
    : null;

  if (moveOutQuery.isLoading || profile.isLoading) {
    return <SkeletonCard testId="loader" />;
  }

  if (moveOutQuery.isError || profile.isError) {
    return (
      <Card>
        <ErrorSection alertLevel="error" id={MOVE_OUT_IN_PROGRESS_ERROR} />
      </Card>
    );
  }

  return (
    <Banner icon="loss">
      <Heading3 as="h2">Goodbye from us!</Heading3>
      <P>
        Thanks for letting us know you’re moving out of{' '}
        <Strong>
          {formattedAddress ? formattedAddress : 'unknown address'}
        </Strong>
        {moveOutDateFormatted && (
          <>
            {' '}
            on the <Strong>{moveOutDateFormatted}</Strong>
          </>
        )}
        . We'll end your contract on this date, create a final statement and
        refund any money left on your account. You've been an awesome OVO
        member!
      </P>
      <P>
        Something not right? Moving out date changed?{' '}
        <ContactUsLink>Please let us know</ContactUsLink>
      </P>

      <CollapsibleBlock bannerId="moveOutInProgress">
        <Heading4 as="h3">Want us to power your new home?</Heading4>
        <PrimaryCTALink
          data-event-name={BANNER_CTA_CLICKED}
          data-event-props={JSON.stringify({
            bannerId: 'moveOutInProgress',
            ctaName: 'Get a quote',
          })}
          href={OVO_QUOTE_AND_SWITCH}
          opensInNewWindow
        >
          Get a quote
        </PrimaryCTALink>
      </CollapsibleBlock>
    </Banner>
  );
};
