import { homeProfileModalTestIDs } from './components/HomeProfileModal/testIDs';
import { hubTestIDs } from './pages/Hub/testIDs';

export const energyInsightsTestIDs = {
  hub: hubTestIDs,
  homeProfileModal: homeProfileModalTestIDs,
  common: {
    termsLink: 'energy-insights-terms-link',
    termsHeader: 'energy-insights-terms-and-conditions',
  },

  breakdownPage: {
    noBreakdown: 'no-usage-breakdown',
    backToHub: 'back-to-hub',
    elecTab: 'tab-electricity',
    gasTab: 'tab-gas',
  },
};
