import styled from 'styled-components';

import { mqPrint, mqLargeUp } from '@/src/utils/mediaQuery';

export const PageContainer = styled.div`
  ${({ theme: { breakpoints, mediaQueries } }) => `
    max-width: 100%;

    ${mediaQueries.mediumAndUp} {
      max-width: ${breakpoints.medium};
      margin-right: auto;
      margin-left: auto;
    }
  `}
`;

export const HeaderRow = styled.div`
  ${({ theme: { space } }) => `
    padding-top: ${space[4]};
  `}
`;

export const ContentRow = styled.div`
  margin-top: 0 !important;
`;

export const LinkCol = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  ${mqLargeUp(`
    margin-top: 0px;
  `)}

  ${mqPrint(`
    display: none;
  `)}
`;

export const ContentContainer = styled.main`
  margin-top: 50px;

  ${mqLargeUp(`
    margin-top: 0;
  `)}
`;
