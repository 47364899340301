import { Heading3, Heading4, P, Strong } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { ActionCentreBanner, Banner } from '../../Banner';
import { CollapsibleBlock } from '../../CollapsibleBlock';
import { LossBannerCTA } from '../LossBannerCTA';
import { useContractsQuery } from '@/src/api';
import { MeterSwitch, RouterLink } from '@/src/components';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { LOSS_MESSAGE_SHOWN } from '@/src/constants/analytics';
import {
  ROUTE_MAKE_CARD_PAYMENT,
  ROUTE_METER_READINGS,
} from '@/src/constants/routes';
import {
  findContractInLoss,
  getFirstAndLastContractsInLoss,
  getOtherFuelType,
} from '@/src/utils/contracts';

const bannerId = 'finalStatementOneReady';

export const FinalStatementOneReady: ActionCentreBanner = () => {
  const { accountId } = useAccountContext();
  const { data: contracts, isLoading } = useContractsQuery(accountId);
  if (isLoading) return <SkeletonCard />;
  if (!contracts) return null;

  const electricityContract = findContractInLoss(contracts.electricity);
  const gasContract = findContractInLoss(contracts.gas);
  const [first, last] = getFirstAndLastContractsInLoss(
    electricityContract,
    gasContract,
  );

  if (!first || !last) return null;

  const otherFuel = getOtherFuelType(first.fuel);
  const completionDay = last.status.inLoss && last.status.lossComplete;
  const lossEvent = `Dual fuel: ${otherFuel} Final Statement, ${
    last.fuel
  } Loss ${completionDay ? 'Completion Day' : 'Complete'}`;

  return (
    <AnalyticsWrapper name={LOSS_MESSAGE_SHOWN} properties={{ lossEvent }}>
      <Banner icon="finalStatement" data-testid="one-statement-ready">
        <Heading3 as="h2">You've switched to a new energy supplier</Heading3>
        <P>
          Your switch for {first.fuel} happened on{' '}
          <Strong data-testid="loss-final-statement-date">
            {format(new Date(first.lossDate as string), 'Do MMMM YYYY')}.
          </Strong>
        </P>
        <P>
          Your switch for {last.fuel} happened on{' '}
          <Strong>
            {format(new Date(last.lossDate as string), 'Do MMMM YYYY')}.
          </Strong>
        </P>

        <CollapsibleBlock bannerId={bannerId}>
          <P>
            We&rsquo;ll send your final statement for {last.fuel} within 6 weeks
            of your switch. This will include your {last.fuel} charges up until
            the date of your switch.
          </P>
          <MeterSwitch
            HasTraditional={() => (
              <>
                <Heading4 as="h3">Need to send a reading?</Heading4>
                <P>
                  Please send a reading if you haven't sent one recently to make
                  sure your final statement's accurate.{' '}
                  <RouterLink to={ROUTE_METER_READINGS}>
                    Send a reading
                  </RouterLink>
                  .
                </P>
              </>
            )}
          />
          <P>
            Please don't cancel your Direct Debit – we'll continue to collect it
            until your account's closed.
          </P>
          <P>
            If your final balance is in credit we'll refund this back to your
            bank account within 10 working days, then close your account.
          </P>
          <P>
            If your final balance is in debt, we'll continue collecting your
            Direct Debit until it's cleared. We'll refund any credit back to
            your bank account within 10 working days, then close your account.
          </P>
          <P>
            You can also make a top-up card payment on your online{' '}
            <RouterLink to={ROUTE_MAKE_CARD_PAYMENT}>account</RouterLink> to
            clear any outstanding debt and speed up the process.
          </P>
          <LossBannerCTA lossEvent={lossEvent} bannerId={bannerId} />
        </CollapsibleBlock>
      </Banner>
    </AnalyticsWrapper>
  );
};
