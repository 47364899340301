import theme from '@/src/core/theme';
import styled from 'styled-components';

export enum SpinnerSize {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}
export enum SpinnerColor {
  GRAY = 'GRAY',
  WHITE = 'WHITE',
}

const sizeConfig: Record<SpinnerSize, { width: string; borderWidth: string }> =
  {
    [SpinnerSize.SMALL]: {
      width: '24px',
      borderWidth: '2px',
    },
    [SpinnerSize.LARGE]: {
      width: '48px',
      borderWidth: '5px',
    },
  };

const colorConfig: Record<
  SpinnerColor,
  { borderColor: string; borderBottomColor: string }
> = {
  [SpinnerColor.GRAY]: {
    borderColor: theme.colors.shadeMidLight,
    borderBottomColor: theme.colors.shadeMid,
  },
  [SpinnerColor.WHITE]: {
    borderColor: theme.colors.brand.white,
    borderBottomColor: theme.colors.shadeMidLight,
  },
};

export const Spinner = styled.span<{
  size?: SpinnerSize;
  color?: SpinnerColor;
}>`
  ${({ size = SpinnerSize.LARGE, color = SpinnerColor.GRAY }) => {
    const { width, borderWidth } = sizeConfig[size];
    const { borderColor, borderBottomColor } = colorConfig[color];

    return `
        width: ${width};
        height: ${width};
        border: ${borderWidth} solid ${borderColor};
        border-bottom-color: ${borderBottomColor};
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;

        @keyframes rotation {
          0% {
              transform: rotate(0deg);
          }
          100% {
              transform: rotate(360deg);
          }
        }
    `;
  }};
`;
