import { Card, Heading2, Heading3, P, SecondaryCTALink } from '@ovotech/nebula';
import React from 'react';
import { DEBT_ASSISTANCE_URL } from '@/src/constants/links';

export const StruggleToPay = () => {
  return (
    <Card data-testid="struggle-to-pay">
      <Heading2>Need help with your bills?</Heading2>
      <Heading3>Create a personalised payment plan</Heading3>
      <P>
        We can offer you a payment plan based on your personal circumstances.
        This could be making a one-off payment, or spreading the cost over time
        to make it more manageable.
      </P>
      <SecondaryCTALink href={DEBT_ASSISTANCE_URL} opensInNewWindow>
        Get support
      </SecondaryCTALink>
    </Card>
  );
};
