import { Heading3 } from '@ovotech/nebula';
import React from 'react';
import TipSlideshow, {
  TipSlideshowProps,
} from '../../pages/Hub/Components/TipSlideshow/TipSlideshow';
import { StyledTopTips } from './EnergySavingTips.styled';

export const EnergySavingTips = ({
  tips,
  category,
  testId,
  tipInteractions,
}: TipSlideshowProps & { testId: string }) => (
  <StyledTopTips data-testid={testId}>
    <Heading3>Your energy-saving tips</Heading3>
    <TipSlideshow
      tipInteractions={tipInteractions}
      tips={tips}
      category={category}
    />
  </StyledTopTips>
);
