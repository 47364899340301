import { EnergyContract } from '@/src/types/Account';

export const hasActiveContract = (contracts: Array<EnergyContract>): boolean =>
  contracts.some(({ status: { active } }) => active);

export const hasActivePlan = (
  contracts: Array<EnergyContract>,
  filterPlanName: string,
): boolean =>
  contracts.some(
    ({ planName, status: { active } }) =>
      active && planName.includes(filterPlanName),
  );
