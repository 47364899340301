import { hubTestIDs } from '../../testIDs';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { energyInsightsStrings } from '@/src/pages/EnergyInsights/strings';

type StatusConfig = {
  title: string;
  buttonTitle: string;
  bodyText: string;
  bodyTestId: string;
  onClickEvent: string | null;
};

const defaultStatusConfig: StatusConfig = {
  title: `Make ${energyInsightsStrings.productDisplayName} work even harder`,
  buttonTitle: "Let's swap you over",
  bodyText:
    'If you want better energy saving tips, switch to half-hourly smart meter readings.' +
    '\n\n' +
    'Remember, you can switch back to daily or monthly readings whenever you like, just by emailing us: hello@ovoenergy.com',
  bodyTestId: hubTestIDs.halfHourlyOptInDefaultText,
  onClickEvent: null,
};

export const statusMap: {
  success: StatusConfig;
  error: StatusConfig;
  loading: StatusConfig;
  idle: StatusConfig;
} = {
  idle: defaultStatusConfig,

  success: {
    title:
      'Congrats, you’ve successfully opted in to half-hourly smart meter readings!',
    buttonTitle: 'Close',
    bodyText:
      'Your settings will change in the next 24 hours, at which point your meter will start sending readings every 30 mins. You’ll then get a breakdown of your electricity usage in 1-2 weeks.',
    bodyTestId: hubTestIDs.halfHourlyOptInSuccessText,
    onClickEvent: ENERGY_INSIGHTS.BUTTON_CLICK_HALF_HOURLY_OPT_IN_CLOSE,
  },

  error: {
    title: 'We’re really sorry - there was a bit of a problem.',
    buttonTitle: 'Try again',
    bodyText:
      'Please try again or contact us on hello@ovoenergy.com and let us know you’d like to opt in to half-hourly smart meter readings.',
    bodyTestId: hubTestIDs.halfHourlyOptInFailureText,
    onClickEvent: null,
  },

  loading: {
    ...defaultStatusConfig,
    buttonTitle: 'Swapping over...',
    onClickEvent: ENERGY_INSIGHTS.BUTTON_CLICK_HALF_HOURLY_OPT_IN,
  },
};
