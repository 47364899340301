import {
  CTAButton,
  Card,
  Heading1,
  Heading4,
  SecondaryCTAButton,
  Stack,
  TextField,
} from '@ovotech/nebula';
import React, { useState } from 'react';
import { Divider } from '../../components/Divider.styled';
import { StyledForm } from '../../components/Form.styled';
import { MoveInPage } from '../../components/MoveInPage';
import { FormErrors, MeterDetailsForm } from '../../types/form';
import { MoveInJourneyProps } from '../../types/moveIn';
import { formEventToObject } from '../../utils/formInput';
import { WhatsMpanModal } from './components/WhatsMpanModal';
import { WhatsMprnModal } from './components/WhatsMprnModal';
import { validateMeterDetails } from './validateMeter';
import { PAGE_METER_DETAILS } from '@/src/pages/MoveIn/analytics';

export function MeterDetails({
  moveInDetails,
  continueMoveIn,
  skipMoveInStep,
  updateMoveIn,
  progress,
}: MoveInJourneyProps) {
  const [shouldShowMpan, setShowMpan] = useState(false);
  const [shouldShowMprn, setShowMprn] = useState(false);

  const [formErrors, setFormErrors] = useState<FormErrors<MeterDetailsForm>>(
    {},
  );

  const onSubmit = (unknownForm: React.FormEvent<HTMLFormElement>) => {
    unknownForm.preventDefault();
    const formInputs = formEventToObject<MeterDetailsForm>(unknownForm);
    const validatedForm = validateMeterDetails(
      moveInDetails.getQuote!.fuel,
      formInputs,
    );

    if (validatedForm.validation === 'error') {
      setFormErrors(validatedForm.errors);
      window.scrollTo(0, 0);
      return false;
    } else {
      updateMoveIn({
        meterDetails: validatedForm.data,
      });
      continueMoveIn();
      return true;
    }
  };

  if (!moveInDetails.needMeterDetails) {
    skipMoveInStep();
  }

  return (
    <MoveInPage
      analyticsName={PAGE_METER_DETAILS}
      title="Meter Details"
      flowProgress={progress}
    >
      <WhatsMpanModal
        isOpen={shouldShowMpan}
        onClose={() => setShowMpan(false)}
      />
      <WhatsMprnModal
        isOpen={shouldShowMprn}
        onClose={() => setShowMprn(false)}
      />

      <Card>
        <Heading1>Meter Details</Heading1>

        <Divider />

        <StyledForm onSubmit={onSubmit}>
          <Stack spaceBetween={6}>
            <Stack spaceBetween={4}>
              <Heading4>Electricity meter number</Heading4>
              <TextField
                name="mpan"
                id="mpan"
                label="Enter your 13 digit Meter Point Administration Number (MPAN)"
                defaultValue={moveInDetails.meterDetails?.mpan ?? ''}
                error={formErrors.mpan}
                data-testid="mpan"
              />
              <SecondaryCTAButton
                type="button"
                onClick={() => setShowMpan(true)}
                data-testid="mpan-button"
              >
                What's my MPAN?
              </SecondaryCTAButton>
            </Stack>

            {moveInDetails.getQuote?.fuel === 'Dual' && (
              <Stack spaceBetween={4}>
                <Heading4>Gas meter number</Heading4>
                <TextField
                  name="mprn"
                  id="mprn"
                  label="Enter your 7-to-10 digit Meter Point Reference Number (MPRN)"
                  defaultValue={moveInDetails.meterDetails?.mprn ?? ''}
                  error={formErrors.mprn}
                  data-testid="mprn"
                />
                <SecondaryCTAButton
                  type="button"
                  onClick={() => setShowMprn(true)}
                  data-testid="mprn-button"
                >
                  What's my MPRN?
                </SecondaryCTAButton>
              </Stack>
            )}

            <CTAButton
              variant="missionPrimary"
              iconRight="chevron-right"
              data-testid="next-button"
            >
              Next
            </CTAButton>
          </Stack>
        </StyledForm>
      </Card>
    </MoveInPage>
  );
}
