import { Small } from '@ovotech/nebula';
import React from 'react';
import {
  NextActionContainer,
  StyledSmall,
  StyledTitle,
  WhatHappensNextContainer,
} from './WhatHappensNext.styled';
import { ReactComponent as EnergySupplyIcon } from '@/src/resources/tado/energy-supply-icon.svg';
import { ReactComponent as HouseIcon } from '@/src/resources/tado/house-icon.svg';
import { ReactComponent as MessageIcon } from '@/src/resources/tado/message-icon.svg';
import { ReactComponent as TadoIcon } from '@/src/resources/tado/tado-icon.svg';

type ActionProps = {
  icon: JSX.Element;
  title: string;
  description: string;
};

const actionItems: ActionProps[] = [
  {
    icon: <TadoIcon />,
    title: 'Get set up on tado°',
    description: 'You can now download the app and create your account',
  },
  {
    icon: <MessageIcon />,
    title: 'We’ll keep you up to date',
    description: 'You’ll receive an email when your tado° is on its way',
  },
  {
    icon: <HouseIcon />,
    title: 'Install your tado°',
    description:
      'There’s a handy guide to walk you through the install process',
  },
  {
    icon: <EnergySupplyIcon />,
    title: 'Connect',
    description: 'Connect your tadoº through My OVO and start saving!',
  },
];

const NextAction = ({ icon, title, description }: ActionProps) => (
  <NextActionContainer>
    {icon}
    <StyledSmall>{title}</StyledSmall>
    <Small>{description}</Small>
  </NextActionContainer>
);

// ts-unused-exports:disable-next-line
export const WhatHappensNext = () => (
  <WhatHappensNextContainer>
    <StyledTitle>What happens next</StyledTitle>
    {actionItems.map((actionItem, index) => (
      <NextAction {...actionItem} key={`action-item-${index}`} />
    ))}
  </WhatHappensNextContainer>
);
