import { ROUTE_OUR_PARTNERS } from '@/src/constants/routes';
import { Card, Heading2, TextLink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import styled from 'styled-components';
import { PARTNERS_EVENTS } from '@/src/constants/analytics';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHeading = styled.div`
  height: 100%;
`;

export const OurPartnersBanner = () => {
  return (
    <Card>
      <StyledContainer>
        <StyledHeading>
          <Heading2>Our partners</Heading2>
        </StyledHeading>
        <AnalyticsClick name={PARTNERS_EVENTS.OUR_PARTNERS}>
          <TextLink href={ROUTE_OUR_PARTNERS}>View all</TextLink>
        </AnalyticsClick>
      </StyledContainer>
    </Card>
  );
};
