import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

import { Portal } from '../Portal/Portal';
import {
  Container,
  Content,
  DismissButton,
  Heading,
  Image,
  Link,
  Text,
} from './SmartBanner.styled';

import AppIcon from '@/src/resources/ovo-energy-app-icon.png';
import analyticsService from '@/src/services/analyticsService';

export const COOKIE__DISMISSED_NAME = 'smart-banner__dismissed';
export const COOKIE__DISMISSED_EXP = 180;
export const ANALYTICS__CLICKED_VIEW = 'Orion Web - App smartbanner - View';
export const ANALYTICS__CLICKED_DISMISSED =
  'Orion Web - App smartbanner - Close';

type Config = {
  appId: string;
  appStoreText: string;
  getStoreLink: (id: string, language?: string) => string;
};

const settings: Record<string, Config> = {
  iOS: {
    appId: '626316021',
    appStoreText: 'iOS',
    getStoreLink: (id, language) =>
      `https://itunes.apple.com/${language}/app/id${id}`,
  },
  android: {
    appId: 'com.ovo.energy',
    appStoreText: 'Android',
    getStoreLink: id => `http://play.google.com/store/apps/details?id=${id}`,
  },
};

export const SmartBanner = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const cookie = Cookies.get(COOKIE__DISMISSED_NAME);
  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isDismissed, setIsDismissed] = useState<boolean>(Boolean(cookie));
  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [config, setConfig] = useState<Config | null>(null);

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const ua = new UAParser();
    const os = ua.getOS();

    // fallback to native banner for safari on iOS 6+
    if (
      os.name === 'iOS' &&
      os.version &&
      parseInt(os.version, 10) >= 6 &&
      ua.getBrowser().name === 'Mobile Safari'
    ) {
      return;
    }

    setConfig(settings[os.name || '']);
  }, []);

  const generateAppStoreLink = () => {
    if (!config) {
      return;
    }
    const { appId } = config;
    const appStoreLanguage = window.navigator.language.slice(-2) || 'us';
    return appId ? config.getStoreLink(appId, appStoreLanguage) : null;
  };

  const dismiss = () => {
    analyticsService.dispatch(ANALYTICS__CLICKED_DISMISSED);
    Cookies.set(COOKIE__DISMISSED_NAME, 'true', {
      expires: COOKIE__DISMISSED_EXP,
    });
    setIsDismissed(true);
  };

  const onClick = () => {
    analyticsService.dispatch(ANALYTICS__CLICKED_VIEW);
  };

  const appStoreLink = generateAppStoreLink();
  const showBanner = Boolean(config && appStoreLink && !isDismissed);

  if (!showBanner) {
    return null;
  }

  return (
    <Portal id="smart-banner">
      <Container data-testid="smart-banner">
        <DismissButton
          type="button"
          aria-label="close"
          onClick={dismiss}
          data-testid="smart-banner:dismiss"
        >
          &#10005;
        </DismissButton>

        <Image img={AppIcon} />

        <Content>
          <Heading>OVO Energy App</Heading>
          <Text>Available today on {config!.appStoreText}</Text>
        </Content>

        <Link
          href={appStoreLink!}
          onClick={onClick}
          data-testid="smart-banner:view"
        >
          View
        </Link>
      </Container>
    </Portal>
  );
};
