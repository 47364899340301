import { useQuery } from '@ovotech/ui-tools';
import { createRenewablePlansEndpoint } from '@/src/constants/endpoints';
import { RenewablePlansResponse } from '@/src/types/RenewablePlans';
import { apiService } from '@/src/services/api-service';

export function useRenewablePlansQuery(accountId: string | null) {
  const renewablePlansEndpoint = createRenewablePlansEndpoint(accountId ?? '');

  return useQuery(
    `orion-renewable-plans-${accountId}`,
    () =>
      apiService.get<RenewablePlansResponse>({
        url: renewablePlansEndpoint,
        headers: {
          'orion-endpoint-version': '2',
        },
      }),
    {
      enabled: !!accountId,
      cacheTime: 30000,
      staleTime: 30000,
    },
  );
}
