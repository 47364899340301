import styled from 'styled-components';
import { svgWidth } from '@/src/components/styled-utils';

export const Wrapper = styled.div`
  display: flex;
`;

export const SmallScreenImage = styled.div(
  ({ theme: { mediaQueries } }) => `
  text-align: center;

  svg {
    ${svgWidth('13rem', 1, 1)}
    margin: 0 auto;
  }

  ${mediaQueries.smallAndUp} {
    display: none;
  }
`,
);

export const LargeScreenImage = styled.div(
  ({ theme: { mediaQueries } }) => `
  display: none;
  ${mediaQueries.smallAndUp} {
    display: block;

    svg {
      width: 100%;
      height: auto;
    }
  }
`,
);
