import styled from 'styled-components';

export const StyledFeedback = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: ${({ theme }) => theme.space[3]};

  ${({ theme: { mediaQueries, space } }) => `
    ${mediaQueries.mediumAndUp} {
     flex-direction: column;
     display: flex;
     flex-direction: row;
     margin-top: ${space[4]};
     align-items: center;
     justify-content: space-between;
    }
  `};
`;
