import { ANALYTICS_ACCOUNT_ID } from '@/src/constants/analytics';
import { Action, SelectAccount } from '@/src/types/Action';
import { User } from '@/src/types/State';

export const selectAccount = (accountId: string): SelectAccount => ({
  type: 'SELECT_ACCOUNT',
  accountId,
  meta: { analytics: { register: { [ANALYTICS_ACCOUNT_ID]: accountId } } },
});

export const defaultUserState: User = {
  customerId: null,
  selectedAccountId: null,
  isFirstLogin: false,
  accountIds: [],
};

const user = (state: User = defaultUserState, action: Action): User => {
  switch (action.type) {
    case 'GET_BOOTSTRAP_SUCCESS': {
      const {
        data: { accountIds, customerId, isFirstLogin, selectedAccountId },
      } = action;

      return {
        ...state,
        accountIds,
        customerId,
        isFirstLogin,
        selectedAccountId,
      };
    }
    case 'SELECT_ACCOUNT': {
      const { accountId } = action;
      return {
        ...state,
        selectedAccountId: accountId,
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
