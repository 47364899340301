import {
  Card,
  Margin,
  SkeletonCTA,
  SkeletonHeading,
  SkeletonText,
} from '@ovotech/nebula';
import React from 'react';
import { StyledCardsWrapper } from '../PartnersTesco';

export const ContentLoader = () => (
  <>
    <Margin top={10} bottom={10}>
      <Card>
        <SkeletonHeading />
        <SkeletonCTA data-testid="eligibility-skeleton-loader" />
        <SkeletonCTA />
        <SkeletonCTA />
      </Card>
    </Margin>
    <Margin top={10} bottom={10}>
      <StyledCardsWrapper>
        <Card>
          <SkeletonHeading />
          <SkeletonText data-testid="reward-card-skeleton-loader" />
          <SkeletonCTA />
        </Card>
        <Card>
          <SkeletonHeading />
          <SkeletonText data-testid="get-offers-skeleton-loader" />
          <SkeletonCTA />
        </Card>
      </StyledCardsWrapper>
    </Margin>
  </>
);
