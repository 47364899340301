export const ANYTIME_ERROR = 'AnytimeError';
export const EV = {
  EVS_OVO_DRIVE_ANYTIME_PAGE_VIEWED: 'EVS - EVS OVO Drive Anytime Page Viewed',
  EVS_OVO_DRIVE_GO_TO_LEASING_PAGE_BUTTON_CLICKED:
    'EVS - EVS OVO Drive Navigate to Leasing page button clicked',
  EVS_OVO_DRIVE_GO_TO_NEWS_PAGE_BUTTON_CLICKED:
    'EVS - EVS OVO Drive Navigate to News page button clicked',
  INLIFE: {
    EVS_INLIFE_MONTHLY_ERROR: 'EVS - EVS Inlife Monthly Page Errored',
    EVS_INLIFE_MONTHLY_MISSING_DATA: (type: string) =>
      `EVS - EVS Inlife Monthly Page ${type} Missing`,
    EVS_INLIFE_MONTHLY_VIEWED: 'EVS - EVS Inlife Monthly Page Viewed',
    EVS_INLIFE_MONTHLY_VIEWED_NO_CONNECTION:
      'EVS - EVS Inlife Monthly Page Viewed - No connection',
    EVS_INLIFE_MONTHLY_CLICKED_CONNECT_CHARGER:
      'EVS - EVS Inlife Monthly Page Clicked Connect Charger',
    EVS_INLIFE_YEARLY_VIEWED: 'EVS - EVS Inlife Yearly Page Viewed',
    EVS_INLIFE_DISCONNECT_VIEWED: 'EVS - EVS Inlife Disconnect Page Viewed',
    EVS_INLIFE_DISCONNECT_LINK_CLICKED: 'EVS - EVS Inlife Disconnect Clicked',
    EVS_INLIFE_ANYTIME_VIEWED: 'EVS - EVS Inlife Anytime Page Viewed',
    EVS_INLIFE_NEWS: 'EvsInlifeNewsPage',
    EVS_INLIFE_COLLECTING_MONTHLY_DATA: 'EvsInlifeCollectingMonthlyData',
    EVS_INLIFE_SHOW_MORE_CHART: 'EvsInlifeShowMoreUsageGraph',
    EVS_INLIFE_DISCONNECT_CANCEL_LINK: 'EvsInlifeDisconnectCancelLink',
    EVS_INLIFE_DISCONNECT_CONFIRMED_LINK: 'EvsInlifeDisconnectConfirmedLink',
    EVS_INLIFE_DISCONNECT_CONFIRMED: 'EvsInlifeDisconnectConfirmed',
    EVS_INLIFE_MONTHLY_NAVIGATION_PREVIOUS:
      'EvsInlifeMonthlyNavigationPreviousLink',
    EVS_INLIFE_MONTHLY_NAVIGATION_NEXT: 'EvsInlifeMonthlyNavigationNextLink',
  },
  ELIGIBILITY: {
    EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_SMART_CHARGER_VIEWED:
      'EVS - OVO Charge Anytime smart charger viewed',
    EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_SMART_CHARGER_SUBMIT_CLICKED:
      'EVS - OVO Charge Anytime smart charger submit clicked',
    EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_VIEWED:
      'EVS - OVO Charge Anytime home set up viewed',
    EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_SUBMIT_CLICKED:
      'EVS - OVO Charge Anytime home set up submit clicked',
    EVS_OVO_CHARGE_ANYTIME_ELIGIBILITY_HOME_SET_UP_BACK_CLICKED:
      'EVS - OVO Charge Anytime home set up back clicked',
    EVS_OVO_CHARGE_ANYTIME_CONSENT_VIEWED:
      'EVS - OVO Charge Anytime consent viewed',
    EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_ELIGIBILITY_VIEWED:
      'EVS - OVO Charge Anytime confirmation eligibility viewed',
    EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_INELIGIBILITY_VIEWED:
      'EVS - OVO Charge Anytime confirmation ineligibility viewed',
    EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_SMART_CHARGER_CLICKED:
      'EVS - OVO Charge Anytime outcome ineligible get smart charger clicked',
    EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_SMART_METER_CLICKED:
      'EVS - OVO Charge Anytime outcome ineligible get smart meter clicked',
    EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_ELECTRICITY_CLICKED:
      'EVS - OVO Charge Anytime outcome ineligible get electricity clicked',
    EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_SWITCH_TO_HALF_HOURLY_READS_CLICKED:
      'EVS - OVO Charge Anytime outcome ineligible switch to half hourly reads clicked',
    EVS_OVO_CHARGE_ANYTIME_VW_PROMO_CLICKED:
      'EVS - OVO Charge Anytime VW promo clicked',
    EVS_OVO_CHARGE_ANYTIME_VOLVO_PROMO_CODE_CLICKED:
      'EVS - OVO Charge Anytime Volvo promo code clicked',
    EVS_OVO_CHARGE_ANYTIME_VOLVO_PROMO_FORM_CLICKED:
      'EVS - OVO Charge Anytime Volvo promo form clicked',
    EVS_OVO_CHARGE_ANYTIME_APP_DOWNLOAD_VIEWED:
      'EVS - OVO Charge Anytime app download viewed',
    EVS_OVO_CHARGE_ANYTIME_APP_DOWNLOAD_CTA_CLICKED:
      'EVS - OVO Charge Anytime app download CTA clicked',
    EVS_OVO_CHARGE_ANYTIME_VOLVO_APP_VIEWED:
      'EVS - OVO Charge Anytime Volvo app viewed',
    EVS_OVO_CHARGE_ANYTIME_VOLVO_APP_LINK_CLICKED:
      'EVS - OVO Charge Anytime Volvo app link clicked',
    EVS_OVO_CHARGE_ANYTIME_GO_TO_ELIGIBILITY_PAGE_BUTTON_CLICKED:
      'EVS - OVO Charge Anytime navigate to eligibility page button clicked',
    EVS_OVO_CHARGE_ANYTIME_HOMEPAGE_GO_TO_ELIGIBILITY_PAGE_BUTTON_CLICKED:
      'EVS - OVO Charge Anytime homepage navigate to eligibility page button clicked',
    EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_TECHNICAL_GLITCH_VIEWED:
      'EVS - OVO Charge Anytime confirmation technical glitch viewed',
    EVS_OVO_CHARGE_ANYTIME_ORDER_CREATED:
      'EVS - OVO Charge Anytime order created',
    EVS_OVO_CHARGE_ANYTIME_ORDER_FAILED:
      'EVS - OVO Charge Anytime order failed',
  },
  SIGNUP: {
    EVS_OVO_DRIVE_ANYTIME_HOMEPAGE_VIEWED:
      'EVS - EVS OVO Drive Anytime homepage viewed',
    EVS_OVO_CHARGE_ANYTIME_HOMEPAGE_ADD_CLICKED:
      'EVS - OVO Charge Anytime homepage add button clicked',
    EVS_OVO_DRIVE_ANYTIME_HOMEPAGE_BACK_CLICKED:
      'EVS - EVS OVO Drive Anytime homepage back button clicked',
    EVS_OVO_CHARGE_ANYTIME_CONSENT_CONFIRM_CLICKED:
      'EVS - OVO Charge Anytime consent confirm clicked',
    EVS_OVO_CHARGE_ANYTIME_CONSENT_BACK_CLICKED:
      'EVS - OVO Charge Anytime consent back clicked',
    EVS_OVO_CHARGE_ANYTIME_LOADING_VIEWED:
      'EVS - OVO Charge Anytime loading viewed',
    EVS_OVO_CHARGE_ANYTIME_KALUZA_ERROR_VIEWED:
      'EVS - OVO Charge Anytime Kaluza error viewed',
    EVS_OVO_CHARGE_ANYTIME_KALUZA_ERROR_BACK_CLICKED:
      'EVS - OVO Charge Anytime Kaluza back button clicked',
    EVS_OVO_CHARGE_ANYTIME_BOLTON_ERROR_VIEWED:
      'EVS - OVO Charge Anytime bolton error viewed',
    EVS_OVO_CHARGE_ANYTIME_BOLTON_ERROR_TRY_AGAIN_CLICKED:
      'EVS - OVO Charge Anytime bolton try again button clicked',
    EVS_OVO_CHARGE_ANYTIME_SUCCESS_VIEWED:
      'EVS - OVO Charge Anytime success viewed',
    EVS_OVO_CHARGE_ANYTIME_SUCCESS_SPIN_BUTTON_CLICKED:
      'EVS - OVO Charge Anytime success spin button clicked',
  },
  DISCONNECT: {
    EVS_OVO_CHARGE_ANYTIME_DISCONNECT_PERMISSION_PAGE_VIEWED:
      'EVS - OVO Charge Anytime disconnect permission page viewed',
    EVS_OVO_CHARGE_ANYTIME_DISCONNECT_PERMISSION_PAGE_YES_CLICKED:
      'EVS - OVO Charge Anytime disconnect permission yes clicked',
    EVS_OVO_CHARGE_ANYTIME_DISCONNECT_PERMISSION_PAGE_NO_CLICKED:
      'EVS - OVO Charge Anytime disconnect permission no clicked',
    EVS_OVO_CHARGE_ANYTIME_DISCONNECT_SUCCESS_PAGE_VIEWED:
      'EVS - OVO Charge Anytime disconnect success page viewed',
    EVS_OVO_CHARGE_ANYTIME_DISCONNECT_ERROR_PAGE_VIEWED:
      'EVS - OVO Charge Anytime disconnect error page viewed',
    EVS_OVO_CHARGE_ANYTIME_DISCONNECT_ERROR_PAGE_BACK_CLICKED:
      'EVS - OVO Charge Anytime disconnect error button clicked',
  },
};
