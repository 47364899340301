import { TimingCategory, VAT_RATE_MULTIPLIER } from '@monovo/energy-renewals';
import type { RenewablePlans } from '../types';
import { RenewalPlanUnitRate } from '@/src/types/RenewablePlans';
import type {
  RenewablePlanTariff,
  RenewablePlanTariffWithVAT,
} from '@/src/types/RenewablePlans';

// TODO RTN Enable mapping of renewablePlans similar to available plans
export function refineRenewablePlans(plans: RenewablePlans) {
  return plans.map(plan => ({
    ...plan,
    tariffs: plan.tariffs
      .sort((first: RenewablePlanTariff, second: RenewablePlanTariff) => {
        if (first.fuel === second.fuel) return 0;
        else if (first.fuel === 'electricity') return -1;
        else return 1;
      })
      .map(addRenewablePlanVATInfo),
  }));
}

export function addRenewablePlanVATInfo(
  renewablePlanTariff: RenewablePlanTariff,
): RenewablePlanTariffWithVAT {
  const toVAT = (amount: number | string) =>
    (Math.ceil(Number(amount) * VAT_RATE_MULTIPLIER * 100) / 100).toFixed(2);

  return {
    ...renewablePlanTariff,
    standingRate: {
      ...renewablePlanTariff.standingRate,
      amountWithVAT: toVAT(renewablePlanTariff.standingRate.amount),
    },
    unitRates: renewablePlanTariff.unitRates.map(unitRate => ({
      ...unitRate,
      label: 'Unit rate', // Not used by renewable plans. Day/Night are Hard coded.
      timingCategory: timingCategory(unitRate),
      unitRateWithVAT: toVAT(unitRate.unitRate),
      unitRateInPence: {
        ...unitRate.unitRateInPence,
        amountWithVAT: toVAT(unitRate.unitRateInPence.amount),
      },
    })),
  };
}

function timingCategory(unitRate: RenewalPlanUnitRate): TimingCategory {
  switch (unitRate.timeOfUseLabel) {
    case 'anytime':
      return 'anytime';
    case 'evening and weekend':
      return 'evening_and_weekend';
    case 'heating':
      return 'heating';
    case 'offpeak':
      return 'offpeak';
    case 'peak':
      return 'peak';
  }
}
