import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { CloseButton, HeadingWrapper, StyledCard } from './styles';
import { ErrorNotification, Heading3, Icon } from '@ovotech/nebula';

export type Status = 'success' | 'error' | null;

export const StatusCard = ({ status }: { status: Status }) => {
  const [isStatusCardDismissed, setIsStatusCardDismissed] =
    useState<boolean>(false);
  const { colors } = useTheme();

  if (!status || isStatusCardDismissed) {
    return null;
  }

  if (status === 'success') {
    return (
      <StyledCard>
        <HeadingWrapper>
          <Heading3>You've updated your marketing preferences!</Heading3>
        </HeadingWrapper>
        <CloseButton onClick={() => setIsStatusCardDismissed(true)}>
          <Icon name="cross" size={15} color={colors.roundalDark} />
        </CloseButton>
      </StyledCard>
    );
  }

  if (status === 'error') {
    return (
      <ErrorNotification id="email-marketing-activation-failure">
        There was a problem updating your marketing preferences.
      </ErrorNotification>
    );
  }

  return null;
};
