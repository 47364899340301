import { BillingHistoryView } from '@ovotech/energy-cx';
import { Margin } from '@ovotech/nebula';
import React from 'react';
import { Route } from 'react-router-dom-v5';
import { SwitchWithNotFound, PageWithNavigation } from '@/src/components';
import { ROUTE_BILLING_HISTORY } from '@/src/constants/routes';

const BillingHistoryRouter = () => (
  <SwitchWithNotFound>
    <Route
      exact
      path={ROUTE_BILLING_HISTORY}
      component={() => (
        <PageWithNavigation title="Billing history">
          <Margin top={[4, 8]}>
            <BillingHistoryView />
          </Margin>
        </PageWithNavigation>
      )}
    />
  </SwitchWithNotFound>
);

export default BillingHistoryRouter;
