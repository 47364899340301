import React from 'react';
import { Card, Heading3, P } from '@ovotech/nebula';
import { ReturnToHomePage } from '@/src/components/ReturnToHomePage/ReturnToHomePage';

export const TechnicalGlitch = () => {
  return (
    <Card>
      <Heading3>Sorry, there’s been a technical glitch.</Heading3>
      <P>
        Our team are aware of the problem, and are working to fix it as soon as
        possible.{' '}
      </P>
      <P>Please check back soon. </P>
      <ReturnToHomePage />
    </Card>
  );
};
