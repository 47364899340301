import React, { ComponentProps } from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span``;

const Pii = ({
  className = '',
  ...rest
}: ComponentProps<typeof StyledSpan>) => (
  <StyledSpan className={`${className}`} {...rest} />
);

export { Pii };
