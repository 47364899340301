import { Store } from 'redux';

import { EnergyContract } from '@/src/types/Account';
import { ContractsResponse, DirectDebitResponse } from '@/src/types/Response';
import {
  eligibleForThermostatBolton,
  hasSmartThermostatBolton,
} from '@/src/pages/SmartThermostats/utils/bolton';
import {
  State,
  Products,
  IneligibilityReasons,
  Eligibility,
  App,
  RecommendedDirectDebit,
} from '@/src/types/State';

import { getEpochDaysDate } from '@/src/utils/date';
import { ProfileNextV1Query } from '@/src/api/kapi/__generated__/graphql';

const AREA_CODES = [
  'YO',
  'DH',
  'DL',
  'BA',
  'BS',
  'OL',
  'EX',
  'BD',
  'PO',
  'SA',
  'M',
  'HU',
  'LS',
  'PL',
  'TA',
  'CF',
  'NP',
  'SO',
];

const EXCLUDED_POST_CODES = [
  'PO30',
  'PO31',
  'PO32',
  'PO33',
  'PO34',
  'PO35',
  'PO36',
  'PO37',
  'PO38',
  'PO39',
  'PO40',
  'PO41',
];

const NUM_MONTHS = 3;

type EligibleReturn = {
  eligible: true;
};
type IneligibleReturn = {
  eligible: false;
  reason: IneligibilityReasons;
};

export type EligiblityFuncReturn = EligibleReturn | IneligibleReturn;

export const doesntHaveSmartThermostatBundle = (
  productState: Products,
): EligiblityFuncReturn => {
  const bundles = productState.activated.data;
  let eligibility: EligiblityFuncReturn = {
    eligible: true,
  };

  if (bundles) {
    const eligible = !hasSmartThermostatBolton(bundles?.boltons);

    if (!eligible) {
      eligibility = {
        eligible: false,
        reason: 'AlreadyHas',
      };
    }
  }

  return eligibility;
};

export const eligibleForBolton = (
  productState: Products,
): EligiblityFuncReturn => {
  const activeBoltons = productState.activated.data?.boltons || [];
  const catalogueBoltons = productState.catalogue.data?.boltons || [];

  const eligibility = eligibleForThermostatBolton(
    catalogueBoltons,
    activeBoltons,
  );

  return eligibility;
};

export const hasDirectDebit = (
  debitState: DirectDebitResponse,
): EligiblityFuncReturn => {
  if (debitState?.amount?.amount != undefined) {
    return { eligible: true };
  }
  return { eligible: false, reason: 'NoDirectDebit' };
};

export const checkPredictedBalance = (
  balanceStr: string,
  balanceUnit: string | undefined,
  minimum: number,
) => {
  let balance = parseInt(balanceStr, 10);
  if (balanceUnit === 'GBX') {
    balance = balance / 100;
  }
  return balance >= minimum;
};

export const hasDebt = (
  recommendedDD: RecommendedDirectDebit,
): EligiblityFuncReturn => {
  const balanceUnit = recommendedDD.data?.predictedFinalBalance.currencyUnit;
  const balanceStr = recommendedDD.data?.predictedFinalBalance.amount;
  if (balanceStr && balanceUnit) {
    if (checkPredictedBalance(balanceStr, balanceUnit, 50)) {
      return { eligible: true };
    }
  }
  return { eligible: false, reason: 'InDebt' };
};

export const checkPostCode = (postCode: string) => {
  const outward = postCode.split(' ')[0];
  const areaMatch = outward.match(/[a-zA-Z]+/g);
  const area = areaMatch ? areaMatch[0] : '';
  if (EXCLUDED_POST_CODES.includes(outward)) {
    return false;
  }
  return AREA_CODES.includes(area);
};

export const inLocation = (
  profile: ProfileNextV1Query,
): EligiblityFuncReturn => {
  const postCode =
    profile.customer_nextV1.contactAddress?.postalAddress.postalCode;

  if (postCode) {
    if (checkPostCode(postCode)) {
      return { eligible: true };
    }
  }

  return { eligible: false, reason: 'NotInGeoLocation' };
};

export const getLongestLastingContract = (
  contracts: Array<EnergyContract> | undefined,
): EnergyContract | null => {
  if (!contracts) {
    return null;
  }
  const active = contracts.filter(({ status: { active }, expiryDate }) => {
    return active && expiryDate;
  });
  if (active.length === 0) {
    return null;
  }
  // after the sort, the contract with the expiryDate furthest in the future will be at index 0
  active.sort((a, b) => {
    // @ts-ignore we filtered out the null values above
    return b.expiryDate - a.expiryDate;
  });
  return active[0];
};

export const checkDate = (expiryDate: number, plusMonths: number): boolean => {
  if (plusMonths >= 12) {
    throw Error(
      'Trying to add a year using the months param. Create a year param.',
    );
  }
  const setDate = new Date(Date.now());
  setDate.setMonth(setDate.getMonth() + plusMonths);
  const expiryDateObj = getEpochDaysDate(expiryDate);
  return expiryDateObj >= setDate;
};

export const hasTimeLeftOnContract = (
  contracts: ContractsResponse,
): EligiblityFuncReturn => {
  const gasContract = getLongestLastingContract(contracts.gas);
  if (gasContract) {
    const { expiryDate } = gasContract;
    if (expiryDate) {
      const overThreeMonths = checkDate(expiryDate, NUM_MONTHS);
      if (overThreeMonths) {
        return { eligible: true };
      }
    }
    return { eligible: false, reason: 'ContractExpiring' };
  }
  return { eligible: false, reason: 'NoGas' };
};

export const hasSmartMeter = (appState: App): EligiblityFuncReturn => {
  const gasSupply = appState.gasSupply?.supplyPointInfo;
  if (gasSupply) {
    if (gasSupply?.isSmart) {
      return { eligible: true };
    }
  }
  return { eligible: false, reason: 'NoSmartMeter' };
};

export const getReasons = (factors: EligiblityFuncReturn[]) => {
  const ineligibility = factors.filter(
    factor => factor.eligible === false,
  ) as IneligibleReturn[];
  const reasons = ineligibility.map(factor => factor.reason);
  return reasons;
};

export const checkEligibility = (
  factors: EligiblityFuncReturn[],
  eligibilityState: Eligibility | null,
): Eligibility => {
  const eligibility = factors.map(factor => {
    return factor.eligible;
  });
  const eligible = eligibility.reduce((acc, factor) => {
    return acc && factor;
  });

  if (eligible) {
    return {
      eligible: true,
      reasons: eligibilityState?.reasons || [],
    };
  } else {
    const reasons = getReasons(factors);
    return { eligible: false, reasons: reasons };
  }
};

export const checkThermostatEligibility = (store: Store): Eligibility => {
  const state: State = store.getState();
  const productState = state.products;

  const eligibilityFactors = [
    doesntHaveSmartThermostatBundle(productState),
    eligibleForBolton(productState),
  ];

  return checkEligibility(
    eligibilityFactors,
    state.smartThermostats.eligibility.general,
  );
};

export const checkProfessionalInstallEligibility = (
  store: Store,
  noProInstalls = true,
  profile: ProfileNextV1Query,
): Eligibility => {
  if (noProInstalls) {
    return { eligible: false, reasons: [] };
  }

  const state: State = store.getState();

  const eligibilityFactors = [inLocation(profile)];

  return checkEligibility(
    eligibilityFactors,
    state.smartThermostats.eligibility.proInstall,
  );
};

export const checkPayMonthlyEligibility = (
  store: Store,
  directDebit: DirectDebitResponse,
  contracts: ContractsResponse,
) => {
  const state: State = store.getState();

  const accountState = state.recommendedDirectDebit;

  const eligibilityFactors = [
    hasDirectDebit(directDebit),
    hasDebt(accountState),
    hasTimeLeftOnContract(contracts),
  ];

  return checkEligibility(
    eligibilityFactors,
    state.smartThermostats.eligibility.payMonthly,
  );
};
