import { Col, Heading1, Margin, Row } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom-v5';
import styled from 'styled-components';
import {
  StyledPageContainer,
  StyledParagraph,
  SuccessHeadingWrapper,
} from '../SmartThermostatOffer.styled';
import { GetReadyForYourTado } from '../components/GetReadyForYourTado';
import { GetStartedWithTado } from '../components/GetStartedWithTado';
import { GotAQuestion } from '../components/GotAQuestion';
import { NavigationBreadCrumb } from '../components/NavigationBreadcrumb';
import { OrderDetails } from '../components/OrderDetails';
import { WhatHappensNext } from '../components/WhatHappensNext';
import { PageWithSimpleHeader } from '@/src/components';
import { SMART_THERMOSTATS_MAINTENANCE } from '@/src/constants/feature-flags';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';
import { breakpointVals, mqMediumUp } from '@/src/utils/mediaQuery';
import { useFeature } from '@/src/utils/optimizely';
import { PaymentMethod } from '../types';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';

export const Success = () => {
  const profile = useProfileQueryKapi();
  const { width } = useWindowDimensions();
  const isMobile = width < breakpointVals.medium;
  const [isUnderMaintenance] = useFeature(SMART_THERMOSTATS_MAINTENANCE);
  const location = useLocation<{ paymentMethod: PaymentMethod }>();

  const StyledContainerWithLongHeaderBg = styled.div`
    ${mqMediumUp(`
      .bg-ovo {
        height: 300px;
      }
    `)}
    .bg-ovo {
      height: 450px;
    }
  `;

  if (!isUnderMaintenance) {
    return (
      <StyledContainerWithLongHeaderBg>
        <PageWithSimpleHeader
          title="tado Checkout"
          analytics={{
            name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.VIEWED,
          }}
        >
          <StyledPageContainer>
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.CLICKED
                  .BACK_TO_ACCOUNT
              }
            >
              <NavigationBreadCrumb
                label="Back to account"
                href={ROUTE_SMART_THERMOSTATS_OFFER}
              />
            </AnalyticsClick>
            <SuccessHeadingWrapper className="checkout__heading-wrapper">
              <Heading1 style={{ color: '#ffffff' }}>
                Great, we’ve got your order
              </Heading1>
              <StyledParagraph className="checkout__subtitle">
                We’ll send you a confirmation email
                {profile.data?.customer_nextV1.contactEmail
                  ? ` to ${profile.data.customer_nextV1.contactEmail}`
                  : '.'}
              </StyledParagraph>
            </SuccessHeadingWrapper>
          </StyledPageContainer>
          <Col isNested as={Row}>
            <Col medium={9}>
              <Margin bottom={8}>
                <OrderDetails
                  paymentMethod={
                    location.state?.paymentMethod ?? PaymentMethod.CARD_PAYMENT
                  }
                />
              </Margin>
              {isMobile && (
                <Margin bottom={8}>
                  <WhatHappensNext />
                </Margin>
              )}
              <Margin bottom={8}>
                <GetReadyForYourTado />
              </Margin>
              <Margin bottom={8}>
                <GetStartedWithTado />
              </Margin>
              <Margin bottom={8}>
                <GotAQuestion />
              </Margin>
            </Col>

            {!isMobile && (
              <Col medium={3}>
                <WhatHappensNext />
              </Col>
            )}
          </Col>
        </PageWithSimpleHeader>
      </StyledContainerWithLongHeaderBg>
    );
  }
  return <Redirect to={ROUTE_SMART_THERMOSTATS_OFFER} />;
};
