import { useDecision } from '@optimizely/react-sdk';
import { BALANCE_COMPONENT_CHANGES } from '@/src/constants/feature-flags';
import {
  useAccountPaymentsQuery,
  useCollectableBalanceQuery,
  useDirectDebitQuery,
} from '@/src/api';
import { useAccountContext } from '@ovotech/ui-tools';
import { useOptimizely } from '@/src/utils/useOptimizely';

export const enum BalanceType {
  LiveBalance = 'live_balance',
  CollectableBalance = 'collectable_balance',
}

export const enum BalanceVariation {
  ControlGroup = 'control_group',
  TestGroup = 'test_group',
  NotInExperiment = 'not_in_experiment',
}

export type UseBalance = {
  balanceType: BalanceType;
  balanceVariation: BalanceVariation;
  liveBalance?: string;
  collectableBalance?: string;
  isLoading: boolean;
  isFetched?: boolean;
  isError: boolean;
};

export const useBalance = (): UseBalance => {
  const optimizely = useOptimizely();
  const { accountId } = useAccountContext();

  const directDebitQuery = useDirectDebitQuery(accountId);
  const isDirectDebitCustomer = directDebitQuery?.data?.amount !== undefined;

  const [balanceVariation] = useDecision(
    BALANCE_COMPONENT_CHANGES,
    {},
    {
      overrideAttributes: {
        ...optimizely?.user?.attributes,
        isDirectDebitCustomer,
      },
    },
  );

  const collectableBalanceQuery = useCollectableBalanceQuery({
    accountId,
  });

  const collectableBalance =
    collectableBalanceQuery?.data?.collectableBalance?.amount;

  const accountPaymentsQuery = useAccountPaymentsQuery(accountId);

  const liveBalance =
    accountPaymentsQuery.data?.billingSummary?.latestPeriod?.data
      ?.closingBalance?.pounds;

  const isLoading =
    directDebitQuery.isLoading ||
    collectableBalanceQuery.isLoading ||
    accountPaymentsQuery.isLoading;

  const isFetched =
    directDebitQuery.isFetched &&
    collectableBalanceQuery.isFetched &&
    accountPaymentsQuery.isFetched;

  const balanceType = (): BalanceType => {
    if (
      collectableBalanceQuery.isError ||
      directDebitQuery.isError ||
      balanceVariation.variationKey === BalanceVariation.NotInExperiment ||
      !collectableBalance
    ) {
      return BalanceType.LiveBalance;
    }

    const isControlGroupWithNegativeCollectableBalance =
      balanceVariation.variationKey === BalanceVariation.ControlGroup &&
      Number(collectableBalance) < 0;

    return balanceVariation.variationKey === BalanceVariation.TestGroup ||
      isControlGroupWithNegativeCollectableBalance
      ? BalanceType.CollectableBalance
      : BalanceType.LiveBalance;
  };

  return {
    balanceType: balanceType(),
    balanceVariation: balanceVariation.variationKey as BalanceVariation,
    liveBalance,
    collectableBalance,
    isLoading,
    isFetched,
    isError:
      collectableBalanceQuery.isError ||
      (balanceType() === BalanceType.LiveBalance &&
        accountPaymentsQuery.isError),
  };
};
