import { PrimaryCTAButton, TextField } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { ComponentProps } from 'react';
import {
  StyledPostcodeLookupField,
  StyledPostcodeLookupFieldButton,
  StyledPostcodeLookupFieldField,
} from './PostcodeLookupField.styled';
import { LoadingIcon } from '@/src/components/icons';
import { HOME_MOVES_FIND_ADDRESS } from '@/src/constants/analytics-subjects';

type Props = ComponentProps<typeof TextField> & {
  onFind: () => void;
  isFetching: boolean;
};

export const PostcodeLookupField = ({ onFind, isFetching, ...rest }: Props) => (
  <StyledPostcodeLookupField>
    <StyledPostcodeLookupFieldField>
      <TextField
        data-testid="postcode-textfield"
        characters={14}
        onKeyDown={event => {
          //stops form submission and only calls onFind
          if (event.key === 'Enter') {
            event.preventDefault();
            onFind();
          }
        }}
        {...rest}
      />
    </StyledPostcodeLookupFieldField>
    <StyledPostcodeLookupFieldButton>
      <AnalyticsClick name={HOME_MOVES_FIND_ADDRESS}>
        <PrimaryCTAButton
          type="button"
          onClick={onFind}
          disabled={isFetching}
          data-testid="address-lookup-find"
        >
          {isFetching ? <LoadingIcon /> : 'Find'}
        </PrimaryCTAButton>
      </AnalyticsClick>
    </StyledPostcodeLookupFieldButton>
  </StyledPostcodeLookupField>
);
