import React from 'react';
import NavLink from '../NavLink';
import { MENU } from '@/src/constants/analytics';
import { ROUTE_PROFILE, ROUTE_SELECT_ACCOUNT } from '@/src/constants/routes';

type LinkProps = {
  path: string;
  closeMobileMenu: () => void;
};

const SelectAccountLink = ({ path, closeMobileMenu }: LinkProps) => (
  <NavLink
    path={path}
    route={ROUTE_SELECT_ACCOUNT}
    label="Select account"
    onClick={closeMobileMenu}
    data-testid="select-account-link"
  />
);

const ProfileLink = ({ path, closeMobileMenu }: LinkProps) => (
  <NavLink
    path={path}
    route={ROUTE_PROFILE}
    label="Profile"
    onClick={closeMobileMenu}
    data-event-name={MENU.PROFILE}
  />
);

type AccountDropdownLinks = {
  path: string;
  hasMultipleAccounts: boolean;
  closeMobileMenu: () => void;
};

export default ({
  hasMultipleAccounts,
  path,
  closeMobileMenu,
}: AccountDropdownLinks) => {
  const accountDropdownLinks = [];

  if (hasMultipleAccounts) {
    accountDropdownLinks.push(
      <li>
        <SelectAccountLink path={path} closeMobileMenu={closeMobileMenu} />
      </li>,
    );
  }

  accountDropdownLinks.push(
    <li>
      <ProfileLink path={path} closeMobileMenu={closeMobileMenu} />
    </li>,
  );

  return accountDropdownLinks;
};
