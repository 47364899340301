import { ModalState } from '@/src/types/State';
import { Action, ShowModal, HideModal } from '@/src/types/Action';

export const showModal = (): ShowModal => ({
  type: 'SHOW_MODAL',
});

export const hideModal = (): HideModal => ({
  type: 'HIDE_MODAL',
});

const defaultModalState = {
  modalVisible: false,
};

const modal = (
  state: ModalState = defaultModalState,
  action: Action,
): ModalState => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        modalVisible: true,
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        modalVisible: false,
      };
    default:
      return state;
  }
};

export default modal;
