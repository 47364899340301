import styled from 'styled-components';

export const StyledSummaryCard = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledSummaryCardHeader = styled.div<{ condensed?: boolean }>(
  ({ theme: { borderWidths, colors, space, mediaQueries }, condensed }) => `
    padding-bottom: ${space[2]};

    ${mediaQueries.smallAndUp} {
      padding-bottom: ${space[3]};
    }

    ${
      !condensed
        ? `
          border-bottom: ${borderWidths.standard} solid ${colors.borderMuted};
          margin-bottom: ${space[4]};

          ${mediaQueries.smallAndUp} {
            margin-bottom: ${space[6]};
          }
        `
        : ``
    }
  `,
);

export const StyledSummaryCardFooter = styled.div(
  ({ theme: { space, mediaQueries } }) => `
    margin-top: ${space[4]};

    ${mediaQueries.smallAndUp} {
      margin-top: ${space[6]};
    }
  `,
);

export const StyledSummaryCardBody = styled.div`
  flex: 1;
`;
