import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { StyledNavHelpSupportLink } from '../../../Header/Header.styled';
import { logout } from '@/src/api/utils/logout';
import { MENU } from '@/src/constants/analytics';
import { doPreventDefault } from '@/src/utils';

const LogoutLink = () => {
  return (
    <StyledNavHelpSupportLink
      as={TextLink}
      href="#"
      onClick={doPreventDefault(() => {
        logout();
      })}
      data-event-name={MENU.LOGOUT}
    >
      Logout
    </StyledNavHelpSupportLink>
  );
};

export default LogoutLink;
