import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { FootnoteReferencedContent } from '@/src/components';
import {
  GREENER_ELECTRICITY_INFO_PAGE,
  RENEWABLE_GENERATION_LINK,
} from '@/src/constants/routes';

const Footnote100PercentGreenerElectricityContent = () => (
  <>
    With Greener Electricity, you&apos;ll receive 100% renewable electricity
    backed by Power Purchase Agreements (PPAs) that we have with UK-based
    generators (which are backed by REGOs to verify that the source is
    renewable). The electricity supplied to your home will still come from the
    National Grid and come from a mix of sources, but an amount of renewable
    electricity equal to the amount you&apos;ve used will be added to the grid,
    directly from a UK-based renewable generator.
    <br />
    <br /> The electricity you get is also carbon-neutral, as we offset all
    associated lifecycle carbon emissions involved in the production and
    consumption of your electricity by supporting carbon reduction projects that
    are certified to the Verified Carbon Standard or the Gold Standard. For more
    information please see our{' '}
    <TextLink opensInNewWindow href={GREENER_ELECTRICITY_INFO_PAGE}>
      Greener Electricity page
    </TextLink>{' '}
    and{' '}
    <TextLink opensInNewWindow href={RENEWABLE_GENERATION_LINK}>
      PPA page
    </TextLink>
    .
  </>
);
export const footnote100PercentGreenerElectricity: FootnoteReferencedContent = {
  id: 'GreenerElectricity',
  heading: '100% Renewable Electricity',
  body: [<Footnote100PercentGreenerElectricityContent key="content" />],
};
