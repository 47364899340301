import React from 'react';
import {
  StyledSkeletonCircleWrapper,
  StyledSkeletonCircle,
} from './SkeletonLoading.styled';

type Props = Omit<JSX.IntrinsicElements['div'], 'ref'> & {
  size?: string;
};
export const SkeletonCircle = ({ size = '12rem', ...rest }: Props) => {
  return (
    <StyledSkeletonCircleWrapper size={size} {...rest}>
      <StyledSkeletonCircle />
    </StyledSkeletonCircleWrapper>
  );
};
