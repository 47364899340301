import { P, SecondaryCTALink } from '@ovotech/nebula';
import React from 'react';

import { REFUND_PROCESSING_DAYS } from '../../constants';
import { NoWrap } from '@/src/components';
import { SummaryCard } from '@/src/components/SummaryCard';
import { PAYMENTS } from '@/src/constants/analytics';
import { ROUTE_REFUNDS_ONE_STOP } from '@/src/constants/routes';

const Refunds = () => (
  <SummaryCard
    heading="Refunds"
    icon="ovorefunds"
    footer={
      <SecondaryCTALink
        href={ROUTE_REFUNDS_ONE_STOP}
        data-event-name={PAYMENTS.FIND_OUT_HOW_TO_GET_REFUND}
      >
        Apply for a refund
      </SecondaryCTALink>
    }
  >
    <P>
      If your account balance is in credit you may be able to apply for a
      refund. If successful, we’ll pay your refund into your bank account within{' '}
      <NoWrap>{REFUND_PROCESSING_DAYS} working days</NoWrap>.
    </P>
  </SummaryCard>
);

export default Refunds;
