import { ChangeDirectDebitDateConfirm } from '@ovotech/energy-cx';
import React from 'react';
import { PageWithNavigationAndSectionPadding } from '@/src/components';

const ChangeDirectDebitDateConfirmPage = () => (
  <PageWithNavigationAndSectionPadding title="Change Direct Debit date - confirmation">
    <ChangeDirectDebitDateConfirm />
  </PageWithNavigationAndSectionPadding>
);

export default ChangeDirectDebitDateConfirmPage;
