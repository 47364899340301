import styled, { css } from 'styled-components';

/**
 * When importing SVGs here, as they are used as a `background-image: url(...)` format,
 * DO NOT import using `import { ReactComponent as MyIcon }`, as this will JSXify it.
 * Import as a default import, eg `import MyIcon from ...` so that it will remain an SVG
 * string for `background-image: url(...)`.
 */
import checkPersonalDetails from '@/src/resources/actionCentre/check-personal-details.svg';
import sendMeterReadings from '@/src/resources/actionCentre/send-meter-reading.svg';
import changeDate from '@/src/resources/change-date-icon.svg';
import confirmation from '@/src/resources/confirmation_icon.svg';
import confirmationSquare from '@/src/resources/confirmation_icon_square.svg';
import costCutting from '@/src/resources/cost_cutting_icon.svg';
import evAnytime from '@/src/resources/ev-anytime-icon.svg';
import finalStatement from '@/src/resources/final-statement-icon.svg';
import lossComplete from '@/src/resources/loss-complete-icon.svg';
import loss from '@/src/resources/loss-icon.svg';
import pound from '@/src/resources/pound-icon.svg';
import refund from '@/src/resources/refund.svg';
import renewal from '@/src/resources/renewal-icon.svg';
import shield from '@/src/resources/shield-icon.svg';
import smartMeter from '@/src/resources/smart-meter.svg';
import nextPlan from '@/src/resources/sunrise-icon.svg';
import warning from '@/src/resources/warning.svg';
import boilerGreen from '@/src/resources/boiler_green_icon.svg';
import highBalance from '@/src/resources/high-balance.svg';

import {
  styledOutline,
  svgHeight,
  svgWidth,
} from '@/src/components/styled-utils';
import { breakpoints } from '@/src/utils/mediaQuery';

const icons = {
  checkPersonalDetails,
  loss,
  lossComplete,
  finalStatement,
  pound,
  renewal,
  nextPlan,
  shield,
  changeDate,
  smartMeter,
  confirmation,
  sendMeterReadings,
  evAnytime,
  warning,
  confirmationSquare,
  costCutting,
  refund,
  boilerGreen,
  highBalance,
};

export type Icon = keyof typeof icons;

const greenPolygonStyles = css`
  &::before {
    content: '';
    height: 115px;
    width: 125px;
    background-color: ${({ theme: { colors } }) =>
      // TODO. OVO Specific.
      colors.brand && colors.brand.primary.lightest};
    transform: rotate(-15deg);
    position: absolute;
    top: -45px;
    right: -45px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    @media ${breakpoints.mediumUp} {
      height: 185px;
      width: 195px;
      top: -75px;
      right: -70px;
    }
  }
`;

const imageStyles = css<{ icon: Icon }>`
  &::after {
    content: '';
    position: absolute;
    right: 17px;
    top: 12px;
    ${svgWidth('50px', 1, 1)}
    ${svgHeight('50px', 1, 1)}
    background-image: ${({ icon }) => `url(${icons[icon]})`};
    background-size: cover;

    @media ${breakpoints.mediumUp} {
      ${svgWidth('80px', 1, 1)}
      ${svgHeight('80px', 1, 1)}
    }
  }
`;

const cornerImageStyle = css<{ icon: Icon }>`
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    ${svgWidth('75px', 1, 1)}
    ${svgHeight('70px', 1, 1)}
    background-image: ${({ icon }) => `url(${icons[icon]})`};
    background-size: cover;

    @media ${breakpoints.mediumUp} {
      ${svgWidth('149px', 1, 1)}
      ${svgHeight('141px', 1, 1)}
    }
  }
`;

export const BannerContent = styled.div<{
  icon: Icon;
  isCornerIcon?: boolean;
}>`
  padding: ${({ theme }) => theme.space[8]};
  position: relative;
  flex: auto;

  & > h2 {
    display: flex;
    align-items: center;
    min-height: 56px;
    margin-right: 70px;
  }

  @media ${breakpoints.mediumUp} {
    & > h2 {
      min-height: unset;
    }

    /* keep main content to the left of the icon */
    & > *,
    & > h2 {
      margin-right: 100px;
    }

    /* Allow collapsible block content to be full width */
    & > hr,
    & > hr ~ * {
      margin-right: unset;
    }
  }

  ${greenPolygonStyles}
  ${props => (props.isCornerIcon ? cornerImageStyle : imageStyles)}
`;

export const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.canvas};
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.space[4]};

  &:first-of-type {
    margin-top: ${({ theme }) => theme.space[6]};

    @media ${breakpoints.smallUp} {
      margin-top: ${({ theme }) => theme.space[8]};
    }
  }

  /* Keep close button on top of green polygon */

  & > div:nth-child(2) {
    flex: none;
    z-index: 1;
  }
`;

export const CloseButton = styled.button`
  padding: 0;
  margin: 8px 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 44px;
  width: 44px;

  ${({ theme: { colors } }) => `
    &:focus {
      ${styledOutline(colors.focus)};
    }
  `}
  svg {
    ${svgHeight('1.4em', 1, 1)}
  }

  @media ${breakpoints.mediumUp} {
    margin: 8px;
  }
`;
