import { Card, Heading2 } from '@ovotech/nebula';
import React from 'react';
import { PageHeader } from './PageHeader';

export const JourneyUnavailable = () => (
  <>
    <PageHeader title={'Charge Anytime'} showDescription={false} />
    <Card>
      <Heading2>The Charge Anytime bolt on is currently unavailable</Heading2>
    </Card>
  </>
);
