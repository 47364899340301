import React from 'react';
import { FeaturesWrapper } from './Features.styled';
import { RenewableGenerationCard } from './RenewableGeneration/RenewableGenerationCard';
import { TraceableElectricityCard } from './TraceableElectricity/TraceableElectricityCard';

export const Features = () => (
  <FeaturesWrapper>
    <RenewableGenerationCard />
    <TraceableElectricityCard />
  </FeaturesWrapper>
);
