import styled from 'styled-components';
import { svgWidth } from '@/src/components/styled-utils';
import { ReactComponent as LogoDark } from '@/src/resources/logo.svg';
import { ReactComponent as LogoLight } from '@/src/resources/logo-white.svg';
import {
  mqIE11,
  mqLargeUp,
  mqMediumUp,
  mqSmallUp,
} from '@/src/utils/mediaQuery';

export const StyledHeaderWrapper = styled.header`
  ${({ theme: { colors } }) => `
    position: absolute;
    width: 180px;
    height: 180px;
    background-color: ${colors.brand.primary.base};
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 77% 70%, 0 45%);
    display: flex;
    left: 0;
    top: 0;
  `}

  ${mqIE11(`
    height: 100px;
  `)}

  ${mqMediumUp(`
    width: 190px;
  `)}

  ${mqLargeUp(`
    background-color: transparent;
    clip-path: none;
    position: static;
    height: auto;
  `)}
`;

export const StyledLightLogo = styled(LogoLight)`
  width: 96px;
  display: block;
  line-height: 0;
  margin-top: 20px;

  ${({ theme: { space } }) => `
    margin-left: ${space[4]};

    ${mqSmallUp(`
      margin-left: ${space[6]};
    `)}

    ${mqMediumUp(`
      margin-left: ${space[8]};
    `)}
  `}

  ${svgWidth('50px', 48, 48)};

  ${mqLargeUp(`
    display: none;
  `)}
`;

export const StyledDarkLogo = styled(LogoDark)`
  ${({ theme: { space } }) => `
    display: none;

    ${svgWidth('50px', 48, 48)};
    ${mqLargeUp(`
      display: block;
      margin-left: 0px;
      margin-top: ${space[4]};
      margin-bottom: ${space[8]};
    `)}
  `}
`;
