import { Card } from '@ovotech/nebula';
import styled from 'styled-components';

export const PracticalStepsCustomCard = styled(Card)`
  ${() => `
    display: flex;
    flex-direction: column;
    text-align: center;
  `}
`;

export const Subheader = styled.span`
  ${() => `
    font-family: Open Sans, sans-serif;
    color: #555C6B;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    padding-left: 0.5rem;
  `}
`;

export const PracticalStepsCustomCardItems = styled.div`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    flex-direction: column;
    align-content: space-between;

    ${mediaQueries.mediumAndUp} {
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`;

export const GreyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme: { space, mediaQueries } }) => `
    background: #f3f3f3;
    padding: 40px;
    border-radius: ${space[1]};
    text-align: left;
    margin-bottom: 0;
    width: 100%;
    margin-top: ${space[4]};

    ${mediaQueries.mediumAndUp} {
      width: 48%;
    }
  `}
`;
