import { Heading4, Margin, P, Small, Stack, Sup } from '@ovotech/nebula';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StyledFootnoteHeading } from './Footnote.styled';

type FootnoteBody = Array<JSX.Element>;

export interface FootnoteReferencedContent {
  id: string;
  heading?: JSX.Element | string;
  body: FootnoteBody;
}

export interface FootnoteReferenced {
  content: FootnoteReferencedContent;
  ref: React.RefObject<HTMLElement>;
}

export interface FootnoteCommon {
  content: FootnoteBody;
  ref: React.RefObject<HTMLElement>;
}

export type FootnoteMarkerType = 'cross' | 'asterix' | 'number' | 'adaptive';

interface FootnoteContext {
  ctxKey?: string;
  markerType: FootnoteMarkerType;
  referenced: Array<FootnoteReferenced>;
  setReferenced: React.Dispatch<
    React.SetStateAction<Array<FootnoteReferenced>>
  >;
  common: Array<FootnoteCommon>;
  setCommon: React.Dispatch<React.SetStateAction<Array<FootnoteCommon>>>;
}

interface YieldProps {
  heading?: JSX.Element | string | false;
  customMarkerStyle?: React.CSSProperties;
  customFootnoteWrapperStyle?: React.CSSProperties;
  customTextStyle?: React.CSSProperties;
}

const FootnoteFactory = () => {
  const defaultContext: FootnoteContext = {
    markerType: 'adaptive',
    referenced: [],
    common: [],
    setReferenced: () => {
      console.error('No FootnoteProvider was found.');
    },
    setCommon: () => {
      console.error('No FootnoteProvider was found.');
    },
  };
  const Context = createContext(defaultContext);
  const Provider = ({
    children,
    markerType = 'adaptive',
    contextKey,
  }: {
    markerType?: FootnoteMarkerType;
    children: JSX.Element | null | false | Array<JSX.Element | null | false>;
    contextKey?: string;
  }) => {
    // For test purposes used ability to wrap providers to re-use parent's ctxKey
    const usedContext = React.useContext(Context);
    const [ctxKey] = React.useState(
      contextKey ||
        usedContext.ctxKey ||
        Math.random().toString(36).substring(7),
    );
    const [referenced, setReferenced] = useState<Array<FootnoteReferenced>>([]);
    const [common, setCommon] = useState<Array<FootnoteCommon>>([]);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const elements = Array.from(
        ref.current!.querySelectorAll(
          `sup[role="footnote-reference"][data-ctx="${ctxKey}"]`,
        ),
      );
      setReferenced(state => {
        const sortedState = elements.reduce<Array<FootnoteReferenced>>(
          (result, element) => {
            const referencedItem = state.find(
              item =>
                item!.ref!.current === element &&
                result.findIndex(
                  resultItem => item.content.id === resultItem.content.id,
                ) < 0,
            );
            if (referencedItem) {
              result.push(referencedItem);
            }
            return result;
          },
          [],
        );

        const isAlreadySorted = !sortedState.filter(
          (item, index) => state[index].ref.current !== item.ref.current,
        ).length;

        if (isAlreadySorted) {
          return state;
        }

        return sortedState;
      });
      // TODO: See https://github.com/ovotech/orion-ui/issues/2861
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referenced]);

    useEffect(() => {
      const elements = Array.from(
        ref.current!.querySelectorAll(
          `span[role="footnote-common-anchor"][data-ctx="${ctxKey}"]`,
        ),
      );
      setCommon(state => {
        const sortedState = elements.reduce<Array<FootnoteCommon>>(
          (result, element) => {
            const commonItem = state.find(item => item.ref.current === element);
            if (commonItem) {
              result.push(commonItem);
            }
            return result;
          },
          [],
        );

        const isAlreadySorted = !sortedState.filter(
          (item, index) => state[index].ref.current !== item.ref.current,
        ).length;

        if (isAlreadySorted) {
          return state;
        }
        return sortedState;
      });
      // TODO: See https://github.com/ovotech/orion-ui/issues/2861
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [common]);

    return (
      <Context.Provider
        value={{
          markerType,
          referenced,
          setReferenced,
          common,
          setCommon,
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/aria-role */}
        <div ref={ref} role="footnote-provider" data-ctx={ctxKey}>
          {children}
        </div>
      </Context.Provider>
    );
  };

  const YieldMaker = ({
    markerIndex,
    forwardedRef,
    className,
    children,
    style = {},
  }: React.PropsWithChildren<{
    markerIndex: number;
    forwardedRef?: React.Ref<HTMLElement>;
    className?: string;
    style?: React.CSSProperties;
  }>) => {
    const { ctxKey, markerType, referenced } = useContext(Context);
    const marker =
      (markerType === 'asterix' && '*'.repeat(markerIndex)) ||
      (markerType === 'cross' && '‡'.repeat(markerIndex)) ||
      (markerType === 'adaptive' &&
        ((referenced.length > 3 && markerIndex) || '*'.repeat(markerIndex))) ||
      markerIndex;

    return (
      <>
        <Sup
          role={(forwardedRef && 'footnote-reference') || ''}
          data-ctx={ctxKey}
          ref={forwardedRef}
          className={className}
          style={style}
        >
          {marker}
        </Sup>
        {children}
      </>
    );
  };

  const Referenced = ({
    content,
    style = {},
  }: {
    content: FootnoteReferencedContent;
    style?: React.CSSProperties;
  }) => {
    const { referenced, setReferenced } = useContext(Context);
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
      const referencedItem = { content, ref };

      setReferenced!(state => {
        const exists = state.some(item => item.content.id === content.id);

        if (exists) {
          return state;
        }
        return [...state, referencedItem];
      });

      return () => {
        setReferenced!(state => state.filter(item => item !== referencedItem));
      };
      // TODO: See https://github.com/ovotech/orion-ui/issues/2861
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const referencedIndex =
      referenced.findIndex(item => item.content.id === content.id) + 1;

    return (
      <YieldMaker
        markerIndex={referencedIndex}
        forwardedRef={ref}
        style={style}
      />
    );
  };

  const Common = ({ content }: { content: FootnoteBody }) => {
    const { ctxKey, setCommon } = useContext(Context);

    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
      const commonItem = { content, ref };
      setCommon!(state => [...state, commonItem]);

      return () => {
        setCommon!(state =>
          // TODO: See https://github.com/ovotech/orion-ui/issues/2861
          // eslint-disable-next-line react-hooks/exhaustive-deps
          state.filter(item => item.ref.current !== ref.current),
        );
      };
      // TODO: See https://github.com/ovotech/orion-ui/issues/2861
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <span
        // eslint-disable-next-line jsx-a11y/aria-role
        role="footnote-common-anchor"
        ref={ref}
        data-ctx={ctxKey}
        style={{ visibility: 'hidden' }}
      />
    );
  };

  const Yield = ({
    heading,
    customMarkerStyle = {},
    customFootnoteWrapperStyle = {},
    customTextStyle = {},
  }: YieldProps) => {
    const { ctxKey, referenced, common } = useContext(Context);
    const referencedItems = referenced.filter(
      ({ content: { id: id1 } }, index) =>
        referenced.findIndex(({ content: { id: id2 } }) => id1 === id2) ===
        index,
    );
    return (
      <Margin top={8}>
        {heading || <Heading4 as="h2">Terms &amp; Conditions</Heading4>}
        {/* eslint-disable-next-line jsx-a11y/aria-role */}
        <ul role="footnote-referenced-list" data-ctx={ctxKey}>
          {referencedItems.map(({ content: { heading, body } }, index) => (
            <li
              key={index}
              className="footnote-referenced-item"
              style={customFootnoteWrapperStyle}
              data-ctx={ctxKey}
            >
              {heading && (
                <StyledFootnoteHeading className="mb-2 font-bold text-base">
                  <YieldMaker
                    markerIndex={index + 1}
                    data-ctx={ctxKey}
                    style={customMarkerStyle}
                  />{' '}
                  {heading}
                </StyledFootnoteHeading>
              )}
              <ul>
                {body.map((item, key) => (
                  <Margin top={4} bottom={4} key={key}>
                    <li
                      data-testid={`footnote-yield-${index + 1}`}
                      data-ctx={ctxKey}
                    >
                      <P
                        key={key}
                        data-testid={`footnote-yield-${index + 1}`}
                        style={customTextStyle}
                      >
                        {!key && !heading && (
                          <YieldMaker
                            markerIndex={index + 1}
                            className="font-bold text-base"
                            style={customMarkerStyle}
                          >
                            {' '}
                          </YieldMaker>
                        )}
                        <Small>{item}</Small>
                      </P>
                    </li>
                  </Margin>
                ))}
              </ul>
            </li>
          ))}
        </ul>

        {(common.length && (
          <>
            {/* eslint-disable-next-line jsx-a11y/aria-role */}
            <ul role="footnote-common-list">
              <Stack spaceBetween={2}>
                {common.map(({ content }) =>
                  content.map((item, key) => (
                    <li key={key} data-ctx={ctxKey}>
                      <P key={key}>
                        <Small>{item}</Small>
                      </P>
                    </li>
                  )),
                )}
              </Stack>
            </ul>
          </>
        )) ||
          null}
      </Margin>
    );
  };

  const useFootnotes = () => {
    const context = useContext(Context);
    if (context !== undefined) return context;
    throw new Error('useFootnotes must be used within a FootnoteProvider');
  };

  return {
    FootnoteProvider: Provider,
    FootnoteReferenced: Referenced,
    FootnoteCommon: Common,
    FootnoteYield: Yield,
    useFootnotes,
  };
};

export const {
  FootnoteProvider,
  FootnoteReferenced,
  FootnoteCommon,
  FootnoteYield,
  useFootnotes,
} = FootnoteFactory();
