import { useAccountContext } from '@ovotech/ui-tools';
import { addMonths, isAfter } from 'date-fns';
import {
  TADO_PAY_MONTHLY_MINIMUM_EOC_BALANCE_POUNDS,
  TADO_PAY_MONTHLY_MINIMUM_FIXED_GAS_CONTRACT_MONTHS_LEFT,
} from '../constants';
import { TadoPayMonthlyIneligibleReason } from '../types';
import {
  useContractsQuery,
  useDirectDebitQuery,
  useProductCatalogueQuery,
  useRecommendedDirectDebitQuery,
} from '@/src/api';
import { PRODUCT_IDS } from '@/src/constants/products';
import {
  getActiveContracts,
  getContractsWithAnExpiryDate,
  getLastExpiringContract,
} from '@/src/utils/contracts';
import { getEpochDaysDate } from '@/src/utils/date';

interface UseTadoPayMonthlyEligibilityReturn {
  isLoading: boolean;
  isError: boolean;
  isEligible: boolean;
  ineligibleReasons: TadoPayMonthlyIneligibleReason[];
}

export const useTadoPayMonthlyEligibility =
  (): UseTadoPayMonthlyEligibilityReturn => {
    const { accountId } = useAccountContext();
    const products = useProductCatalogueQuery();
    const contracts = useContractsQuery(accountId);
    const directDebit = useDirectDebitQuery(accountId);
    const recommendedDirectDebit = useRecommendedDirectDebitQuery();

    const ineligibleReasons: TadoPayMonthlyIneligibleReason[] = [];

    /**
     * products API: are they eligible for pay monthly bolt on
     */
    const payMonthlyBoltonProductId = PRODUCT_IDS.smartThermostatSelfInstall;
    const bolton = products?.data?.boltons.find(
      bolton => bolton.productId === payMonthlyBoltonProductId,
    );
    if (!bolton?.eligibility.eligible) {
      ineligibleReasons.push(TadoPayMonthlyIneligibleReason.BOLTON_INELIGIBLE);
    }

    /**
     * contracts API: > 3 months left on their longest lasting gas contract
     * 1. filter down to active, gas, fixed term contracts only
     * 2. get the longest lasting contract
     * 3. checking it has >3 months left before the expiry date
     */
    const activeContracts = getActiveContracts(contracts?.data?.gas ?? []);
    const contractsWithExpiryDate =
      getContractsWithAnExpiryDate(activeContracts);
    const latestEndingContract = getLastExpiringContract(
      contractsWithExpiryDate,
    );
    if (!latestEndingContract?.expiryDate) {
      ineligibleReasons.push(
        TadoPayMonthlyIneligibleReason.NO_ACTIVE_GAS_CONTRACT_WITH_EXPIRY_DATE,
      );
    } else {
      const contractEndDate = getEpochDaysDate(
        latestEndingContract?.expiryDate,
      );
      const futureDateToCheck = addMonths(
        new Date(),
        TADO_PAY_MONTHLY_MINIMUM_FIXED_GAS_CONTRACT_MONTHS_LEFT,
      );
      if (!isAfter(contractEndDate, futureDateToCheck)) {
        ineligibleReasons.push(TadoPayMonthlyIneligibleReason.CONTRACT_ENDING);
      }
    }

    /**
     * direct debit API: do they have a DD setup
     */
    const hasDirectDebit = directDebit?.data?.amount?.amount;
    if (!hasDirectDebit) {
      ineligibleReasons.push(TadoPayMonthlyIneligibleReason.NO_DIRECT_DEBIT);
    }

    /**
     * rec dd API: predicted final eoc balance > £50
     */
    const eocBalanceAmount =
      recommendedDirectDebit?.data?.predictedFinalBalance.amount;
    const eocBalanceCurrencyUnit =
      recommendedDirectDebit?.data?.predictedFinalBalance.currencyUnit;
    if (eocBalanceAmount && eocBalanceCurrencyUnit) {
      let balance = parseInt(eocBalanceAmount, 10);
      if (eocBalanceCurrencyUnit === 'GBX') balance = balance / 100;
      if (balance < TADO_PAY_MONTHLY_MINIMUM_EOC_BALANCE_POUNDS) {
        ineligibleReasons.push(TadoPayMonthlyIneligibleReason.EOC_BALANCE_DEBT);
      }
    } else {
      ineligibleReasons.push(TadoPayMonthlyIneligibleReason.EOC_BALANCE_DEBT);
    }

    const isLoading =
      products?.isLoading ||
      contracts?.isLoading ||
      directDebit?.isLoading ||
      recommendedDirectDebit?.isLoading;

    const isError =
      products?.isError ||
      contracts?.isError ||
      directDebit?.isError ||
      recommendedDirectDebit?.isError;

    return {
      isLoading,
      isError,
      ineligibleReasons,
      isEligible: ineligibleReasons.length === 0,
    };
  };
