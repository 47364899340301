import styled from 'styled-components';

export const PriceLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  > p {
    margin: 0;
  }
`;
