import { Margin } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import {
  EnergyInsightsTermsLink,
  PageHeaderWithHubLink,
} from '../../components';
import { EnergyInsightsPageName } from '../../types';
import { Breakdown } from './Components/Breakdown';
import { HowWeCalculateItModal } from './Components/HowWeCalculateItModal/HowWeCalculateItModal';
import { useBreakdownPageQuery } from '@/src/api';
import { PageWithNavigation } from '@/src/components/Pages/Pages';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';

export const BillBreakdownPage = () => {
  return (
    <PageWithNavigation
      title="Bill Breakdown"
      analytics={{ name: EnergyInsightsPageName.BillBreakdown }}
    >
      <PageHeaderWithHubLink
        mainTitle="Your weekly energy use"
        subTitle="Learn where your energy goes and get some handy tips on cutting carbon."
        subTitleComponent={<HowWeCalculateItModal />}
      />
      <Content />
      <Margin bottom={12} />
      <EnergyInsightsTermsLink />
    </PageWithNavigation>
  );
};

function Content() {
  const { accountId } = useAccountContext();
  const { data, error } = useBreakdownPageQuery({ accountId });

  if (error) {
    return null;
  }

  if (!data) {
    return <SiteLoading />;
  }

  return (
    <Margin vertical={8}>
      <Breakdown {...data} />
    </Margin>
  );
}
