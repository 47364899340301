import { useQuery, UseQueryOptions } from 'react-query';
import { createProjectedCostsEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { ProjectedCostsResponse } from '@/src/types/Response';

type QueryCacheKey = `orion-projected-costs`;

export const useProjectedCostsQuery = (
  accountId: string | null | undefined,
  limitNextYear = false,
  options?: UseQueryOptions<
    ProjectedCostsResponse,
    unknown,
    ProjectedCostsResponse,
    QueryCacheKey
  >,
) => {
  const projectedCostsEndpoint = createProjectedCostsEndpoint(accountId ?? '');
  return useQuery(
    `orion-projected-costs` as QueryCacheKey,
    () =>
      apiService.get<ProjectedCostsResponse>({
        url: `${projectedCostsEndpoint}?limitNextYear=${limitNextYear}`,
      }),
    {
      enabled: !!accountId,
      ...options,
    },
  );
};
