import React from 'react';
import { Redirect, Route } from 'react-router-dom-v5';
import {
  ROUTE_METER_READINGS,
  ROUTE_OPENING_METER_READINGS,
  ROUTE_OPENING_METER_READINGS_NEW,
} from '../../constants/routes';

import { SwitchWithNotFound } from '@/src/components';

const OpeningMeterReadingsRoutes = () => (
  <SwitchWithNotFound>
    <Route
      exact
      path={ROUTE_OPENING_METER_READINGS} // @deprecated use meter readings routes instead.
      render={() => <Redirect to={ROUTE_METER_READINGS} />}
    />
    <Route
      path={ROUTE_OPENING_METER_READINGS_NEW} // @deprecated use meter readings routes instead.
      render={() => <Redirect to={ROUTE_METER_READINGS} />}
    />
    <Route
      exact
      path={`${ROUTE_OPENING_METER_READINGS}/confirm`} // @deprecated use meter readings routes instead.
      render={() => <Redirect to={ROUTE_METER_READINGS} />}
    />
  </SwitchWithNotFound>
);

export default OpeningMeterReadingsRoutes;
