import { Heading3, P, Ul, Li } from '@ovotech/nebula';
import { AnalyticsRender, useAnalytics } from '@ovotech/ui-tools';
import React from 'react';
import Offer from './Offer/Offer';

import { HOMEPLAN_DEFAULT_CARD } from '@/src/constants/analytics-subjects';
import { createHomePlanUrl } from '@/src/constants/endpoints';
import image from '@/src/resources/offers/boiler-cover.jpg';

type Props = {
  accountId: string;
};

export const HomePlanPromoOffer = ({ accountId }: Props) => {
  const analytics = useAnalytics();

  return (
    <AnalyticsRender name={HOMEPLAN_DEFAULT_CARD}>
      <Offer
        image={image}
        offerId="homePlanPromo"
        linkUrl={createHomePlanUrl('homePlanPromo', accountId)}
        linkText="Get cover"
        tracking={() => {
          analytics.dispatch({
            name: HOMEPLAN_DEFAULT_CARD,
            type: 'click',
          });
        }}
      >
        <Heading3 as="h2">Boiler cover from OVO.</Heading3>

        <P>
          We helped with over 120,000 repairs last year, so you know you can
          count on us. All our plans come with:
        </P>

        <Ul>
          <Li>An annual boiler service worth £84</Li>
          <Li>Unlimited callouts</Li>
          <Li>A 24/7 emergency helpline</Li>
          <Li>Access to a network of Gas Safe registered engineers</Li>
        </Ul>
      </Offer>
    </AnalyticsRender>
  );
};
