import { EstimatedAnnualConsumption } from '@monovo/energy-renewals';
import Big from 'big.js';
import { SyntheticEvent } from 'react';

import { marketingPrefs } from '@/src/constants/misc';

export const doPreventDefault =
  <E extends Event | SyntheticEvent>(callback: (e: E) => void) =>
  (e: E) => {
    e.preventDefault();
    callback(e);
  };

export const sortMarketingPreferences = (
  prefs?: null | Array<string>,
): null | Array<string> => {
  if (prefs == null || prefs.length === 0) {
    return null;
  }

  return marketingPrefs.filter(pref => prefs && prefs.includes(pref));
};

export const shallowObjectEquals = (
  a: { [key: string]: any },
  b: { [key: string]: any },
) => {
  const keys = new Set([...Object.keys(a), ...Object.keys(b)]);
  return [...keys].reduce((acc, key) => acc && a[key] === b[key], true);
};

export const sumAnnualConsumptions = (
  consumptions: Array<EstimatedAnnualConsumption>,
): string =>
  consumptions
    .reduce(
      (acc, { projectedConsumption }) =>
        acc.plus(new Big(projectedConsumption)),
      new Big(0),
    )
    .toFixed(0);

/**
 * Takes two arrays of objects and merges them based on a given key. If both arrays contain an object where the key has
 * the same value, then the object from the second array is used
 */
export function mergeCollectionRight<T extends { [key: string]: any }>(
  key: string,
  state: T[],
  updates: T[],
): T[] {
  const keyMap = state.reduce<{ [key: string]: T }>((acc, current) => {
    const index = `${current[key]}`;
    return {
      ...acc,
      [index]: current,
    };
  }, {});
  updates.forEach(obj => {
    const index = `${obj[key]}`;
    keyMap[index] = obj;
  });
  return Object.values(keyMap);
}

/**
 * Converts an object to a URL params string.
 * Values should be stringified before passing them in. Key-value pairs where the value is falsy will be discarded.
 */
export function objectToSearchParams(
  paramsObject: Record<string, string | null | undefined | false>,
) {
  return Object.entries(paramsObject).reduce((result, [key, value]) => {
    if (!value) return result;
    const pair = `${key}=${value}`;
    return !result.length ? `?${pair}` : `${result}&${pair}`;
  }, '');
}
