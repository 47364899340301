import { UpdateDirectDebitAmountResponse } from '@ovotech/energy-cx';
import React from 'react';
import { DirectDebitConfirm } from './DirectDebitConfirm';
import { PageWithNavigationAndSectionPadding } from '@/src/components';

const OneClickPaymentReductionRoute = () => {
  const response = decodeUpdateDirectDebitAmountResponseSearch(
    window.location.search,
  );

  return (
    <PageWithNavigationAndSectionPadding title="Confirmation - Change direct debit amount">
      <DirectDebitConfirm confirmation={response} />
    </PageWithNavigationAndSectionPadding>
  );
};

function decodeUpdateDirectDebitAmountResponseSearch(
  search: string,
): UpdateDirectDebitAmountResponse {
  const currencyUnit = getValueFromQueryParams('currencyUnit', search)!;
  const decodedResponse: UpdateDirectDebitAmountResponse = {
    newAmount: {
      amount: getValueFromQueryParams('futureAmount', search)!,
      currencyUnit,
    },
  };

  if (getValueFromQueryParams('nextAmount', search)) {
    decodedResponse.upcomingPayments = [
      {
        amount: {
          amount: getValueFromQueryParams('nextAmount', search)!,
          currencyUnit,
        },
        date: Number(getValueFromQueryParams('nextAmountDate', search)!),
      },
      {
        amount: { ...decodedResponse.newAmount },
        date: Number(getValueFromQueryParams('futureAmountDate', search)!),
      },
    ];
  }
  return decodedResponse;
}

function getValueFromQueryParams(name: string, search: string) {
  const urlParams = new URLSearchParams(search);
  return urlParams.get(name);
}

export default OneClickPaymentReductionRoute;
