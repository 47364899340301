import { BoltonInstance } from '@/src/types/Products';
import { PRODUCT_IDS } from '@/src/constants/products';

export const isChargeAnytimeBoltonCanceled = (
  bolton: BoltonInstance,
): boolean => {
  return (
    (bolton.productId === PRODUCT_IDS.payasyoudrive ||
      bolton.productId === PRODUCT_IDS.anytime) &&
    !!bolton.activeUntil
  );
};
