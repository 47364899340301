import { Card, Heading1, P } from '@ovotech/nebula';
import React from 'react';
import { PageWithNavigation, Section } from '@/src/components';

const Holding = () => {
  return (
    <PageWithNavigation title="Holding">
      <Section>
        <Card>
          <Heading1>
            Oops, this feature isn't available right now – we're busy improving
            it!
          </Heading1>
          <P>We hope to be up and running tomorrow, so pop back then.</P>
          <P>Thanks for bearing with us.</P>
        </Card>
      </Section>
    </PageWithNavigation>
  );
};

export default Holding;
