import styled from 'styled-components';
import { colours } from '../CarbonIntensityShared.assets';
import { mqSmallDown } from '@/src/utils/mediaQuery';
import { CarbonIntensityLevel } from '@/src/pages/EnergyInsights/types';

type StyledProps = {
  cardColour: CarbonIntensityLevel;
};

export const StyledCarbonIntensityCurrent = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 420px;
  width: 100%;
  position: relative;
  overflow: hidden;

  h4 {
    ${({ theme: { space } }) => `
    margin-bottom: ${space[2]}
  `}
  }
`;

export const StyledCarbonIntensityBanner = styled.div`
  ${({ intensity }: { intensity: CarbonIntensityLevel }) => `
    background: ${colours[intensity].banner};
    `}

  width: 100%;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h4 {
    margin: 0;
    color: white;
    padding: 0 12px;

    font-size: 16px;
  }
`;

export const StyledCarbonIntensityCurrentDiv = styled.div`
  ${({ cardColour }: StyledProps) => `
    background: ${colours[cardColour].muted};
    `}
  width: 220%;
  height: 105%;
  border-radius: 74%;
  position: absolute;
  top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 13%;
  padding-bottom: 5%;
  padding-left: 70%;
  padding-right: 70%;
  text-align: center;

  ${mqSmallDown(`
      padding-top: 30%;
      padding-bottom: 10%;
    `)}

  svg:first-of-type {
    height: 90px;
    width: 90px;

    ${mqSmallDown(`
      height: 70px;
      width: 70px;
    `)}
  }
`;

export const StyledCurrentIntensityFooter = styled.div`
  strong {
    width: 100%;
    display: block;
    ${({ theme: { space } }) => `
    margin-bottom: ${space[4]}
  `}
  }

  span {
    ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[4]}
  `}
  }
`;
