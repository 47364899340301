import {
  CTAButton,
  Card,
  ErrorNotification,
  Heading3,
  Margin,
  P,
  PrimaryCTAButton,
  SuccessNotification,
  TextLink,
} from '@ovotech/nebula';
import React, { useEffect, useState } from 'react';
import { useGreenerEnergySignup } from '@/src/pages/GreenerEnergy/GreenerEnergySignup/hooks/useGreenerEnergySignup';
import {
  StyledTextLinkContainer,
  StyledPrice,
  StyledStrongPrice,
} from './GreenerEnergyOffer.styled';
import { StyledOfferBody, StyledOfferPanel } from './Offer/Offer.styled';
import testIds from './testIds';
import { ContactUsLink } from '@/src/components';

import {
  CLICKED_OFFER_EVENT,
  NEW_OFFERS_PAGE,
  VIEWED_OFFER_EVENT,
} from '@/src/constants/analytics';
import {
  ROUTE_GREENER_ENERGY,
  ROUTE_GREENER_ENERGY_SIGNUP,
} from '@/src/constants/routes';
import analyticsService from '@/src/services/analyticsService';
import { extractGreenerEnergyPriceInfo } from '@/src/utils/products';
import GreenerEnergyAcceptTermsModal from '@/src/pages/GreenerEnergy/GreenerEnergySignup/components/GreenerEnergyAcceptTermsModal';
import image from '@/src/resources/greener-turbines.jpg';

export const GreenerEnergyOfferOneClick = () => {
  const { activateProduct, greenerEnergy, activation } =
    useGreenerEnergySignup();
  const [acceptPrompt, setAcceptPrompt] = useState<boolean>(false);

  useEffect(() => {
    analyticsService.dispatch(VIEWED_OFFER_EVENT, {
      offerType: NEW_OFFERS_PAGE.FUTURE_OF_GREEN_POWER_ID,
    });
  }, []);

  const [errorRetry, setErrorRetry] = useState(false);

  if (!greenerEnergy || !greenerEnergy?.eligibility?.eligible) return null;

  const { initialPrice } = extractGreenerEnergyPriceInfo(greenerEnergy);

  const clickHandler = () => {
    /*
      If the one click activation failed then set the errorRetry flag
      so that further retries do not jump back to the initial render state
    */
    if (activation.error) {
      setErrorRetry(true);
    }
    setAcceptPrompt(true);
    analyticsService.dispatch(CLICKED_OFFER_EVENT, {
      offerType: NEW_OFFERS_PAGE.FUTURE_OF_GREEN_POWER_ID,
    });
  };

  const InitialState = () => (
    <>
      <Heading3 as="h2">The future of green power in the UK</Heading3>
      <P>
        Upgrade to supercharge support for the UK’s renewable energy system. As
        well as 100% renewable electricity and 100% carbon-neutral gas.
      </P>
      <StyledPrice>
        <StyledStrongPrice>£{initialPrice}</StyledStrongPrice> a month.{' '}
      </StyledPrice>
      <Margin top={4}>
        <CTAButton
          variant="primary"
          iconLeft={activation.isUpdating ? null : 'plus'}
          fullWidth="always"
          onClick={clickHandler}
          disabled={activation.isUpdating}
          data-testid="greener-energy-one-click-add-to-plan-cta"
        >
          {activation.isUpdating ? '...updating' : 'Add to your plan'}
        </CTAButton>
      </Margin>
      <StyledTextLinkContainer>
        <TextLink href={ROUTE_GREENER_ENERGY_SIGNUP} opensInNewWindow>
          Find out more
        </TextLink>
      </StyledTextLinkContainer>
      {acceptPrompt && (
        <GreenerEnergyAcceptTermsModal
          setAcceptPrompt={setAcceptPrompt}
          acceptPrompt={acceptPrompt}
          activateProduct={activateProduct}
          greenerEnergy={greenerEnergy}
        />
      )}
    </>
  );

  const SuccessState = () => (
    <>
      <Heading3 as="h2">Congrats! You've supercharged your energy</Heading3>
      <SuccessNotification
        id="greener-energy-one-click-success"
        data-testid="rtn-greener-energy-one-click-response-ok"
      >
        <P>
          Greener Energy has been added to your plan. Cancel it anytime at ‘Plan
          details’ in your account.
        </P>
      </SuccessNotification>
      <StyledTextLinkContainer>
        <TextLink href={ROUTE_GREENER_ENERGY} opensInNewWindow>
          Take me to my Greener Energy benefits
        </TextLink>
      </StyledTextLinkContainer>
    </>
  );

  const ErrorState = () => (
    <>
      <Heading3 as="h2">Oops! Something went wrong...</Heading3>
      <ErrorNotification
        id="greener-energy-one-click-error"
        title={null}
        data-testid="rtn-greener-energy-one-click-response-error"
      >
        <P>
          We were unable to activate your upgrade. Please try again later. If
          this keeps happening please contact us{' '}
          <ContactUsLink>contact us</ContactUsLink>
        </P>
      </ErrorNotification>
      <Margin top={4}>
        <PrimaryCTAButton
          variant="primary"
          fullWidth="always"
          onClick={clickHandler}
        >
          {activation.isUpdating ? '...loading' : 'Try again'}
        </PrimaryCTAButton>
      </Margin>
    </>
  );

  return (
    <StyledOfferPanel data-testid={testIds.offers.greenerEnergyOffer} as={Card}>
      <img alt="" src={image} aria-hidden="true" />
      <StyledOfferBody>
        {activation.success ? (
          <SuccessState />
        ) : activation.error || errorRetry ? (
          <ErrorState />
        ) : (
          <InitialState />
        )}
      </StyledOfferBody>
    </StyledOfferPanel>
  );
};
