import { Heading4 } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Description = styled.h4`
  ${({ theme: { colors, space } }) => `
    color: ${colors.primary};
    padding: ${space[4]};
    max-width: 400px;
    text-align: center;
  `}
`;

export const FooterLogo = () => (
  <Wrapper aria-hidden="true">
    <Description
      as={Heading4}
    >{`Go further with our green upgrade`}</Description>
  </Wrapper>
);
