import { graphql } from '@/src/api/contentful/__generated__';
import { PackCtaFragmentFragment } from '@/src/api/contentful/__generated__/graphql';

export const PackCTAFragment = graphql(/* contentful-codegen-graphql */ `
  fragment PackCTAFragment on PackCta {
    label
    utmSource
    utmMedium
    utmContent
    utmCampaign
    queryStringExtras
    destination {
      __typename
      ... on WrapperUrl {
        url
      }
      ... on BuildWebpage {
        slug
      }
      ... on BuildCategoryWebpage {
        pathname
      }
      ... on PackUserAction {
        actionName
      }
      ... on WrapperDocument {
        document {
          url
        }
      }
    }
    destinationAnchor {
      __typename
      ... on ComponentContentBlock {
        anchor
      }
      ... on ComponentContactAndUsefulLinks {
        anchor
      }
    }
  }
`);

const OVO_PUBLIC_SITE = 'https://www.ovoenergy.com';

export const getOvoUrlFromPath = (path?: string | null): string | null => {
  if (!path) return null;
  try {
    const url = new URL(`${OVO_PUBLIC_SITE}${path}`);
    return url.href;
  } catch {
    return null;
  }
};

const getCtaUrlFromDestination = (
  destination: PackCtaFragmentFragment['destination'],
) => {
  if (destination?.__typename === 'WrapperUrl') {
    return destination?.url;
  }

  if (destination?.__typename === 'BuildWebpage') {
    return getOvoUrlFromPath(destination?.slug);
  }

  if (destination?.__typename === 'BuildCategoryWebpage') {
    return getOvoUrlFromPath(destination?.pathname);
  }

  if (destination?.__typename === 'WrapperDocument') {
    return destination?.document?.url;
  }
};

const getQueryParams = (cta: PackCtaFragmentFragment) => {
  const utmParam: Record<string, string | null | undefined> = {
    utm_source: cta?.utmSource,
    utm_medium: cta?.utmMedium,
    utm_content: cta?.utmContent,
    utm_campaign: cta?.utmCampaign,
  };

  const cleanedUtmParam = Object.keys(utmParam).map(key => {
    const value = utmParam?.[key];
    if (value) return `${key}=${value}`;
  });

  return [...cleanedUtmParam, cta?.queryStringExtras].filter(Boolean).join('&');
};

const getAnchorHash = (
  destinationAnchor: PackCtaFragmentFragment['destinationAnchor'],
): string => {
  if (
    (destinationAnchor?.__typename === 'ComponentContentBlock' ||
      destinationAnchor?.__typename === 'ComponentContactAndUsefulLinks') &&
    destinationAnchor?.anchor
  ) {
    return `#${destinationAnchor?.anchor}`;
  }

  return '';
};

const getCtaUrl = (cta: PackCtaFragmentFragment): string | null => {
  const url = getCtaUrlFromDestination(cta?.destination);
  if (!url) return null;
  const anchor = getAnchorHash(cta?.destinationAnchor);
  const queryParams = getQueryParams(cta);
  if (queryParams) return `${url}?${queryParams}${anchor}`;

  return `${url}${anchor}`;
};

export type CallToAction = {
  label?: string | null;
  url: string;
};

export const getCallToAction = (
  cta?: PackCtaFragmentFragment | null,
): CallToAction | null => {
  if (!cta) return null;
  const url = getCtaUrl(cta);
  if (!url) return null;
  return {
    url,
    label: cta?.label,
  };
};
