import styled from 'styled-components';

export const StyledLoadingContainer = styled.div(
  ({ theme: { space } }) => `
    width: 50%;
    flex: 1;
    margin-right: ${space[5]};
  `,
);

export const StyledLoadingRow = styled.div`
  display: flex;
  flex-direction: row;
`;
