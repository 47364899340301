import { Heading2, P } from '@ovotech/nebula';
import { AnalyticsRender } from '@ovotech/ui-tools';
import React, { FC } from 'react';
import {
  footnoteHomeRecoverQ1PromoDisclaimer,
  footnoteHomeRecoverQ1PromoSavings,
} from '../FooterTermsAndConditions';
import { FootnoteReferenced } from '@/src/components';
import { HOME_RECOVER_Q1_OFFER_CARD } from '@/src/constants/analytics-subjects';
import { createHomeRecoverUrl } from '@/src/constants/endpoints';
import Offer from '@/src/pages/Offers/components/cards/Offer/Offer';
import image from '@/src/resources/offers/home-recover.jpg';

interface HomeRecoverQ1OfferProp {
  accountId: string;
}

export const HomeRecoverQ1Offer: FC<HomeRecoverQ1OfferProp> = ({
  accountId,
}) => {
  return (
    <AnalyticsRender name={HOME_RECOVER_Q1_OFFER_CARD}>
      <Offer
        image={image}
        linkUrl={createHomeRecoverUrl('homeRecover', accountId)}
        linkText={'Get Home Recover'}
        offerId={'homeRecoverQ1'}
      >
        <Heading2>
          Something broken? No cover? No sweat (and up to £96 off).
          <FootnoteReferenced content={footnoteHomeRecoverQ1PromoSavings} />
        </Heading2>
        <P>
          With Home Recover, we’ll fix your boiler, electrics, drains, or
          heating for free when you get our Complete cover.
          <FootnoteReferenced content={footnoteHomeRecoverQ1PromoDisclaimer} />
        </P>
        <P>And for a limited time, save up to £96 on cover through the year.</P>
      </Offer>
    </AnalyticsRender>
  );
};
