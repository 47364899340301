import {
  BankAccount,
  CusomterDetails,
} from '@/src/pages/MoveIn/types/acquisitionRequest';
import { AddressWithSupplyPoint } from '@/src/pages/MoveIn/types/addressRequest';
import { QuoteRequest } from '@/src/pages/MoveIn/types/quoteRequest';

export type FormErrors<T> = Partial<Record<keyof T | string, ErrorMessages>>;
export type ValidatedForm<T> =
  | {
      validation: 'success';
      data: T;
    }
  | {
      validation: 'error';
      errors: FormErrors<T>;
    };

type ManualAddress = {
  addressLine1: string;
  addressLine2: string;
  town: string;
  postCode: string;
};

export type MeterDetailsForm = {
  mpan: string;
  mprn?: string;
};

export type AddressForm =
  | (ManualAddress & {
      manualAddress: true;
    })
  | (AddressWithSupplyPoint & {
      manualAddress: false;
    });

export type BankAccountForm = BankAccount & {
  accountIsOwn: boolean;
  permission: boolean;
};

export type NebulaDate = {
  day: string;
  month: string;
  year: string;
};

export type QuoteRequestForm = QuoteRequest & {
  moveInDate: number;
  'moveInDate-month': number;
  'moveInDate-year': number;
};

export type CustomerDetailsForm = Omit<CusomterDetails, 'dateOfBirth'> & {
  dateOfBirth: number;
  'dateOfBirth-month': number;
  'dateOfBirth-year': number;
  password: never;
  smartMeter?: string;
  serviceOptIn: string;
  specialRequirement: string;
  KeepInTouchEmail: boolean;
  KeepInTouchSMS: boolean;
  consent: boolean;
  useSupplyAsBillingAddress: boolean;
  referredFrom: string;
  campaign: string;
} & (
    | ({
        useSupplyAddress: false;
      } & AddressForm)
    | { useSupplyAddress: true }
  );

// type SmartMeterDealForm = {
//   acknowledgedSmartMeterRequirements: boolean;
//   hasSelectedBetterSmart: boolean;
// };
//
// type SupplyStartForm = {
//   coolingOffSwitch: boolean;
//   coolingOffPayment: boolean;
// };

export enum ErrorMessages {
  TECHNICAL_FAULT = 'Sorry. There is a technical issue.',
  REQUIRED = 'This field is required.',
  POSTCODE_MISSING = 'Postcode is required',
  INVALID_EMAIL = 'This email is not valid.',
  EMAIL_MISSING = 'Please supply a valid email address.',
  INVALID_POSTCODE = 'Invalid postcode.',
  INVALID_MOBILE_NUMBER = 'Invalid mobile number.',
  HAVING_TROUBLE = 'Sorry, we are having some trouble with your request. Please try again in a few minutes.',
  QUOTE_FAILURE = 'Sorry, we are unable to obtain a quote for your new property. Please try again later',
  NO_NUMBERS = 'No numbers allowed.',
  MAX_ACCOUNT_NUM = 'Account number must be 8 characters long and can only contain numbers.',
  MPAN = 'Please enter a valid MPAN',
  MPAN_LENGTH = 'This MPAN is not valid, please enter a 13 digit number',
  MPRN = 'Please enter a valid MPRN',
  MPRN_LENGTH = 'This MPRN is not valid, please enter a 7-to-10 digit number',
  MAX_SORT_CODE = 'Sort code must be 6 characters long and can only contain numbers.',
  AT_LEAST_18 = 'You must be at least 18 years old',
  ENTER_VALID_DATE = 'Please enter a valid date',
  AFTER_1901 = 'Year must be 1901 or later',
  NOT_IN_FUTURE = 'Date must not be in the future',
  ENTER_PAST_DATE = 'We can only accept dates in the last 365 days',
  ADDRESS_ERROR = 'Our address lookup service is currently experiencing issues, please input your address manually.',
  NUMERICAL_ONLY = 'This field can only contain numbers',
  MAX_42_DAYS_IN_FUTURE = 'We can only accept dates within the next 42 days.',
  MAX_365_DAYS_IN_PAST = 'We can only accept dates in the last 365 days, if you moved in before then contact our call centre.',
}
