export type HomeProfileAnswers = {
  propertyType: PropertyType;
  numBedrooms: NumBedrooms;
  numOccupants: NumOccupants;
  averageHoursAtHome: AverageHoursAtHome;
  homeHeat: HomeHeat;
  extraAppliances?: ExtraAppliance[];
  cleaningAppliances?: CleaningAppliance[];
  kitchenAppliances?: KitchenAppliance[];
  numFridgesOrFreezers: NumFridgesOrFreezers;
};

export enum PropertyType {
  Flat = 'Flat',
  Detached = 'Detached',
  SemiDetached = 'SemiDetached',
  MidTerrace = 'MidTerrace',
  EndTerrace = 'EndTerrace',
  Bungalow = 'Bungalow',
}

export enum NumBedrooms {
  One = 'OneBedroom',
  Two = 'TwoBedrooms',
  Three = 'ThreeBedrooms',
  Four = 'FourBedrooms',
  Five = 'FiveOrMoreBedrooms',
}

export enum NumOccupants {
  One = 'OnePerson',
  Two = 'TwoPeople',
  Three = 'ThreePeople',
  Four = 'FourPeople',
  FiveOrMore = 'FiveOrMorePeople',
}

export enum AverageHoursAtHome {
  ZeroToThree = 'ZeroToThree',
  ThreeToSix = 'ThreeToSix',
  SixToNine = 'SixToNine',
  NineToTwelve = 'NineToTwelve',
  TwelveOrMore = 'TwelveOrMore',
}

export enum HomeHeat {
  GasHeating = 'GasHeating',
  StorageHeating = 'StorageHeating',
  ElectricHeating = 'ElectricHeating',
  HeatPump = 'HeatPump',
  Other = 'Other',
}

export enum ExtraAppliance {
  WaterHeater = 'WaterHeater',
  PoolOrPondPump = 'PoolOrPondPump',
  ElectricVehicle = 'ElectricVehicle',
  SolarPanels = 'SolarPanels',
  ElectricUnderfloorHeating = 'ElectricUnderfloorHeating',
  GasFireplace = 'GasFireplace',
  HomeStorageBattery = 'HomeStorageBattery',
  SmartThermostat = 'SmartThermostat',
}

export enum CleaningAppliance {
  WashingMachine = 'WashingMachine',
  TumbleDryer = 'TumbleDryer',
  Dishwasher = 'Dishwasher',
}

export enum KitchenAppliance {
  ElectricHob = 'ElectricHob',
  GasHob = 'GasHob',
  ElectricOven = 'ElectricOven',
  GasOven = 'GasOven',
  Microwave = 'Microwave',
}

export enum NumFridgesOrFreezers {
  One = 'OneFridgeOrFreezer',
  Two = 'TwoFridgesOrFreezers',
  Three = 'ThreeFridgesOrFreezers',
  Four = 'FourFridgesOrFreezers',
  Five = 'FiveOrMoreFridgesOrFreezers',
}
