import { MeterReadsSummary, UsageSummary } from '@ovotech/energy-cx';
import { Col, Row } from '@ovotech/nebula';
import React from 'react';
import RowLoading from './RowLoading';
import { DisplayFlex, ErrorBoundary } from '@/src/components';
import {
  METER_READS_WIDGET_ERROR,
  USAGE_SUMMARY_WIDGET_ERROR,
} from '@/src/constants/error-section-ids';
import {
  areComplexSupplies,
  hasAtLeastOneActiveSupply,
} from '@/src/utils/supplyPoints';
import { useSupplyPointsQuery } from '@/src/api/kapi/use-supply-points-query';

export const UsageAndMeterReadWidgets = () => {
  const { data, isLoading, isError } = useSupplyPointsQuery();

  if (isLoading) return <RowLoading />;

  if (isError || !data) return null;

  const hasActiveSupplies = hasAtLeastOneActiveSupply(
    data?.account?.accountSupplyPoints,
  );

  // Hide these components for complex supplies for now.
  const hasComplexSupplies = areComplexSupplies(
    data?.account?.accountSupplyPoints,
  );

  if (hasActiveSupplies && !hasComplexSupplies) {
    return (
      <Row isNested>
        <Col medium={6} as={DisplayFlex}>
          <ErrorBoundary errorId={USAGE_SUMMARY_WIDGET_ERROR}>
            <UsageSummary />
          </ErrorBoundary>
        </Col>
        <Col medium={6} as={DisplayFlex}>
          <ErrorBoundary errorId={METER_READS_WIDGET_ERROR}>
            <MeterReadsSummary />
          </ErrorBoundary>
        </Col>
      </Row>
    );
  }

  return null;
};
