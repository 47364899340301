import { P, Small, Strong } from '@ovotech/nebula';
import React from 'react';

import { CardCol, CardRow } from '../../Card.styled';
import { RenewablePlanTariff } from '@/src/types/RenewablePlans';
import { sumAnnualConsumptions } from '@/src/utils';

const TariffEstimations = ({
  tariff1,
  tariff2,
}: {
  tariff1: RenewablePlanTariff;
  tariff2?: RenewablePlanTariff;
}) => {
  return (
    <>
      <CardRow>
        <CardCol>
          <P>
            <Small>Your yearly use*:</Small>
          </P>
        </CardCol>
        <CardCol>
          <P>
            <Small>
              <Strong>
                {sumAnnualConsumptions(tariff1.estimatedAnnualConsumptions)} kWh
              </Strong>
            </Small>
          </P>
        </CardCol>
        {tariff2 ? (
          <CardCol>
            <P>
              <Small>
                <Strong>
                  {sumAnnualConsumptions(tariff2.estimatedAnnualConsumptions)}{' '}
                  kWh
                </Strong>
              </Small>
            </P>
          </CardCol>
        ) : null}
      </CardRow>
      <CardRow>
        <CardCol>
          <P>
            <Small>Your yearly cost: </Small>
          </P>
        </CardCol>
        <CardCol>
          <P>
            <Small>
              <Strong>£{tariff1.estimatedAnnualCost.amount}</Strong>
            </Small>
          </P>
        </CardCol>
        {tariff2 ? (
          <CardCol>
            <P>
              <Small>
                <Strong>£{tariff2.estimatedAnnualCost.amount}</Strong>
              </Small>
            </P>
          </CardCol>
        ) : null}
      </CardRow>
    </>
  );
};

export default TariffEstimations;
