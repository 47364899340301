import { PlanFamily } from '@monovo/energy-renewals';
import { ContractsResponse } from '@/src/types/Response';
import { dateToEpochDays } from '@/src/utils/date';

export function homeplanBannerToUse(contracts: ContractsResponse) {
  const hasRecentFixedAndProtect =
    [...(contracts.electricity || []), ...(contracts.gas || [])].filter(
      contract =>
        dateToEpochDays(new Date()) - contract.startDate <= 30 &&
        contract.planName.includes(PlanFamily.twoYearFixAndProtect),
    ).length > 0;

  return hasRecentFixedAndProtect ? 'homeplanBundle' : 'homeplan';
}
