import { Heading4, Strong } from '@ovotech/nebula';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  StyledGreenCard,
  StyledVehicleInformation,
  StyledBatteryInformation,
  StyledWrapper,
  StyledDiv,
  StyledElectricVehicleSVG,
  StyledBatteryeSVG,
  StyledHeading4,
} from './GreenCard.styled';
import { useKaluzaChargerVehicleDataQuery } from '@/src/api';
import { State } from '@/src/types/State';
import { useAccountContext } from '@ovotech/ui-tools';

const GreenCard = () => {
  const { connection } = useSelector(({ evInlife }: State) => evInlife);
  const { accountId } = useAccountContext();

  const vehicleDataResponse = useKaluzaChargerVehicleDataQuery(
    connection,
    accountId,
  );
  if (vehicleDataResponse.status == 'data' && !vehicleDataResponse.isFetching) {
    const vehicleData = vehicleDataResponse.data;
    return (
      <StyledGreenCard>
        <StyledWrapper>
          <StyledDiv>
            <StyledElectricVehicleSVG />
            <StyledVehicleInformation>
              <StyledHeading4 as={Heading4}>Your car</StyledHeading4>
              <ul>
                <li>
                  Brand: <Strong>{vehicleData?.vehicleMake}</Strong>
                </li>
                <li>
                  Model: <Strong>{vehicleData?.vehicleModel}</Strong>
                </li>
              </ul>
            </StyledVehicleInformation>
          </StyledDiv>
          <StyledDiv>
            <StyledBatteryeSVG />
            <StyledBatteryInformation>
              <StyledHeading4 as={Heading4}>Your battery</StyledHeading4>
              <ul>
                <li>
                  Capacity:{' '}
                  <Strong>{vehicleData?.batteryCapacityKWH}kWh</Strong>
                </li>
              </ul>
            </StyledBatteryInformation>
          </StyledDiv>
        </StyledWrapper>
      </StyledGreenCard>
    );
  } else {
    return null;
  }
};

export default GreenCard;
