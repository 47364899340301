import { Heading4, Margin } from '@ovotech/nebula';
import React from 'react';

import { Divider, CardCol, CardRow } from '../../Card.styled';
import { RenewablePlanTariff } from '@/src/types/RenewablePlans';
import { capitalise } from '@/src/utils/typography';

const TariffHeading = ({
  tariff1,
  tariff2,
}: {
  tariff1: RenewablePlanTariff;
  tariff2?: RenewablePlanTariff;
}) => {
  return (
    <>
      <Margin top={8} bottom={2}>
        <CardRow>
          <CardCol />
          <CardCol>
            <Heading4>{capitalise(tariff1.fuel)}</Heading4>
          </CardCol>
          {tariff2 ? (
            <CardCol>
              <Heading4>{capitalise(tariff2.fuel)}</Heading4>
            </CardCol>
          ) : null}
        </CardRow>
      </Margin>
      <Margin bottom={2}>
        <CardRow>
          <CardCol />
          <CardCol>
            <Divider bold />
          </CardCol>
          {tariff2 ? (
            <CardCol>
              <Divider bold />
            </CardCol>
          ) : null}
        </CardRow>
      </Margin>
    </>
  );
};

export default TariffHeading;
