import styled from 'styled-components';
import { mqSmallUp } from '@/src/utils/mediaQuery';

export const BannerContainer = styled.div`
  p {
    margin: 5px 0;
  }
`;

export const Description = styled.div`
  font-size: 20px;

  ${mqSmallUp(`
    font-size: 24px; 
  `)}
`;
