import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';

import { makeActionError, makeActionStart, makeActionSuccess } from '../http';
import { createAccountInfoEndpoint } from '@/src/constants/endpoints';
import apiService from '@/src/services/apiService';
import {
  Action,
  FETCH_ACTION_KEY,
  GetAccountInfoError,
  GetAccountInfoStart,
  GetAccountInfoSuccess,
} from '@/src/types/Action';
import { AccountInfoResponse } from '@/src/types/Response';
import { AccountInfo } from '@/src/types/State';

/**
 * @deprecated use `useAccountGraphqlQuery or `useBillingQuery` instead
 */
export const getAccountInfoStart = makeActionStart<GetAccountInfoStart>(() => ({
  type: 'GET_ACCOUNT_INFO_START',
}));

// CAREFUL! This is used in analytics data
export const getAccountInfoSuccess = makeActionSuccess<
  GetAccountInfoSuccess,
  [AccountInfoResponse]
>(data => ({
  type: 'GET_ACCOUNT_INFO_SUCCESS',
  data,
}));

/**
 * @deprecated use `useAccountGraphqlQuery or `useBillingQuery` instead
 */
export const getAccountInfoError = makeActionError<GetAccountInfoError>(() => ({
  type: 'GET_ACCOUNT_INFO_ERROR',
}));

export const defaultAccountInfoState = {
  isFetching: false,
  data: null,
};

export const getAccountInfoEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  // @ts-ignore Typescript does not like action$.ofType
  action$
    .ofType('GET_ACCOUNT_INFO_START')
    .exhaustMap(({ [FETCH_ACTION_KEY]: id }: GetAccountInfoStart) => {
      const { selectedAccountId } = store.getState().user;
      if (!selectedAccountId) {
        return Observable.of(
          getAccountInfoError(
            id,
            // @ts-ignore
            { response: 'No account id set' },
          ),
        );
      }
      return apiService
        .get({
          responseType: 'json',
          url: createAccountInfoEndpoint(selectedAccountId),
        })
        .map(({ response }) => getAccountInfoSuccess(id, response))
        .catch(err => Observable.of(getAccountInfoError(id, err)));
    });

const accountInfo = (
  state: AccountInfo = defaultAccountInfoState,
  action: Action,
): AccountInfo => {
  switch (action.type) {
    case 'GET_ACCOUNT_INFO_START': {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case 'GET_ACCOUNT_INFO_SUCCESS': {
      const { isFetching, data } = action;
      const isDebit: boolean = data.balance.amount[0] === '-';

      return {
        ...state,
        isFetching,
        data: {
          ...data,
          isDebit,
        },
      };
    }
    case 'GET_ACCOUNT_INFO_ERROR': {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    default: {
      return state;
    }
  }
};

export default accountInfo;
