import styled from 'styled-components';

export const StyledRadioContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }
`;

export const DisabledAccountRow = styled.div`
  margin-top: 1.6rem;

  h2 {
    font-size: 1.6rem;
    margin-left: 1.6rem;
    margin-bottom: 0;
  }
  span {
    font-size: 1.6rem;
    margin-left: 1.6rem;
  }
`;
