import React from 'react';
import styled from 'styled-components';
import { styledOutline, svgWidth } from '../../../styled-utils';
import { mqDesktopNav, mqMobileNav } from '@/src/utils/mediaQuery';
import { ReactComponent as Svg } from '@/src/resources/down-arrow.svg';

export const StyledNavDropdown = styled.div`
  position: relative;
`;

export const StyledButton = styled.button`
  ${({ theme: { fontSizes, colors, space, fonts, fontWeights } }) => `
    .label {
      font-size: ${fontSizes[2]};
      font-family: ${fonts.display};
      font-weight: ${fontWeights.normal};
    }

    ${mqMobileNav(`
      width: 100%;
      text-align: center;
      padding: ${space[4]} 0px;
    `)}

    cursor: pointer;
    background: inherit;
    background-color: transparent;
    padding: 10px;
    border: none;
    display: block;

    &:focus {
      ${styledOutline(colors.focus)};
    }
  `}
`;

export const StyledButtonContent = styled.span`
  position: relative;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledDownArrow = styled(({ hasLightBackground: _, ...rest }) => (
  <Svg {...rest} />
))<{
  hasLightBackground?: boolean;
}>`
  pointer-events: none;
  ${({ theme: { colors }, hasLightBackground }) => `
    ${svgWidth('0.8em', 2, 1)}
    display: inline-block;
    margin: 0.2em;
    fill: ${
      hasLightBackground ? colors.headerPrimaryContrast : colors.brand.midnight
    };
  `};
`;

export const StyledUpArrow = styled(StyledDownArrow)<{
  hasLightBackground?: boolean;
}>`
  transform: rotate(180deg);
  ${({ theme: { colors }, hasLightBackground }) => `
    fill: ${
      hasLightBackground ? colors.headerPrimaryContrast : colors.brand.midnight
    };
  `};
`;

export const StyledDropdownUl = styled.ul<{
  isHidden?: boolean;
  dropdownAlignment: 'left' | 'right';
}>`
  border-radius: 3px;
  white-space: nowrap;
  display: none;

  ${({ theme: { colors, space }, isHidden, dropdownAlignment }) => `
    display: ${isHidden ? 'none' : 'block'};
    background-color: ${colors.canvas};
    padding: 0 ${space[8]};

    ${mqDesktopNav(`
      position: absolute;
      ${dropdownAlignment}: 0;
      padding: 10px;
      z-index: 3;
      box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
    `)}
  `}
`;

export const StyledAccordionUl = styled.ul<{
  isHidden?: boolean;
}>`
  ${({ isHidden }) => `display: ${isHidden ? 'none' : 'block'};`}
`;

export const StyledNavLabel = styled.span<{
  hasLightBackground?: boolean;
}>`
  ${({ theme: { colors }, hasLightBackground }) => `
        color: ${
          hasLightBackground
            ? colors.headerPrimaryContrast
            : colors.brand.midnight
        };
    `}
`;

export const StyledNavArrow = styled.span`
  vertical-align: baseline;
  ${mqDesktopNav(`
    margin-left: 0.2em;
  `)}
`;
