import { Card, IconName } from '@ovotech/nebula';
import React, { forwardRef, ReactNode } from 'react';
import { IconHeader } from '../../pages/Payments/components/IconHeader';
import {
  StyledSummaryCard,
  StyledSummaryCardHeader,
  StyledSummaryCardFooter,
  StyledSummaryCardBody,
} from './SummaryCard.styled';

type Props = JSX.IntrinsicElements['div'] & {
  heading: ReactNode;
  subtitle?: ReactNode;
  icon: IconName | string;
  footer?: ReactNode;
  // Removes border and margin under header.
  condensed?: boolean;
};

const SummaryCard = forwardRef<HTMLDivElement, Props>(
  ({ heading, subtitle, icon, footer, children, condensed, ...rest }, ref) => {
    return (
      <StyledSummaryCard {...rest} ref={ref} as={Card}>
        <IconHeader
          condensed={condensed}
          as={StyledSummaryCardHeader}
          heading={heading}
          subtitle={subtitle}
          icon={icon}
        />
        <StyledSummaryCardBody>{children}</StyledSummaryCardBody>
        {footer ? (
          <StyledSummaryCardFooter>{footer}</StyledSummaryCardFooter>
        ) : null}
      </StyledSummaryCard>
    );
  },
);

export { SummaryCard };
