import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { MoveInDetails } from '../types/moveIn';
import { acquisitionQuoteUrl } from '@/src/pages/MoveIn/api/urls';
import { QuoteResponse } from '@/src/pages/MoveIn/types/quoteRequest';
import { apiService } from '@/src/services/api-service';

type QuoteProps = Required<Pick<MoveInDetails, 'getQuote'>> &
  Pick<MoveInDetails, 'meterDetails'>;

export function useGetAcquisitionQuote({
  getQuote,
  meterDetails,
}: QuoteProps): UseQueryResult<QuoteResponse, AxiosError> {
  const usage: Record<string, string> =
    getQuote.quoteType === 'Estimated'
      ? {
          usage: getQuote.usage,
        }
      : getQuote.fuel === 'Electricity'
      ? {
          electricityUsage: getQuote.electricityUsage.toString(),
        }
      : {
          electricityUsage: getQuote.electricityUsage.toString(),
          gasUsage: getQuote.gasUsage.toString(),
        };

  // If meter details were needed, then don't send the uprn as the api will attempt to use it and fail
  const query = new URLSearchParams({
    uprn: getQuote.manualAddress || meterDetails ? '' : getQuote.uprn,
    fuel: getQuote.fuel,
    paymentMethod: getQuote.paymentMethod,
    postcode: getQuote.postCode,
    usage: 'Low', // Upstream API always requires a value, even with usage data. Will be overridden by spread operation
    ...usage,
  }).toString();

  const url = `${acquisitionQuoteUrl}?${query}`;

  return useQuery<QuoteResponse, AxiosError>(['move-in-quote', query], () =>
    apiService.get<QuoteResponse>({ url, withCredentials: false }),
  );
}
