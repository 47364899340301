import { AddressForm, ErrorMessages, FormErrors } from '../types/form';

const postcodeRegex =
  /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;

export function isPostcode(input: string) {
  return Boolean(input.match(postcodeRegex));
}

export function validatePostcode(postcode: string): ErrorMessages | undefined {
  if (!postcode) {
    return ErrorMessages.REQUIRED;
  } else if (!isPostcode(postcode)) {
    return ErrorMessages.INVALID_POSTCODE;
  }
}

export function validateAddress(
  form: Partial<AddressForm>,
): FormErrors<AddressForm> {
  const errors: FormErrors<AddressForm> = {};

  if (form.manualAddress) {
    if (!form.addressLine1) {
      errors.addressLine1 = ErrorMessages.REQUIRED;
    }
    if (!form.town) {
      errors.town = ErrorMessages.REQUIRED;
    }
    if (!form.postCode) {
      errors.postCode = ErrorMessages.POSTCODE_MISSING;
    } else if (!isPostcode(form.postCode)) {
      errors.postCode = ErrorMessages.INVALID_POSTCODE;
    }

    // Should always be true, but partial states it is potentially undefined
  } else if (form.manualAddress === false) {
    if (!form.postCode) {
      errors.postCode = ErrorMessages.POSTCODE_MISSING;
    } else if (!isPostcode(form.postCode)) {
      errors.postCode = ErrorMessages.INVALID_POSTCODE;
    } else if (!form.fullAddress) {
      errors.fullAddress = ErrorMessages.REQUIRED;
    }
  } else {
    // Should not appear, unless 'manualAddress' field cannot found.
    errors.postCode = ErrorMessages.TECHNICAL_FAULT;
  }

  return errors;
}
