import { useQuery } from '@ovotech/ui-tools';
import { EVsInlifeDataMonthlyResponse, MonthYear } from '@/src/types/State';
import { generateUrl } from '@/src/redux/ducks/evs/evInlife';
import { apiService } from '@/src/services/api-service';

export function useKaluzaMonthlyChargerDataQuery(
  connection: boolean | null,
  startDate: MonthYear,
  accountId: string | null | undefined,
  anytimeStartDate: string | undefined,
) {
  const url = generateUrl(
    accountId!,
    `${startDate.year}`,
    `${startDate.month}`,
    anytimeStartDate,
  );
  return useQuery(
    [accountId, startDate.year, startDate.month, anytimeStartDate],
    () => {
      return apiService.get<EVsInlifeDataMonthlyResponse>({
        responseType: 'json',
        url: url,
      });
    },

    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!connection,
      refetchOnWindowFocus: true,
    },
  );
}
