import { VAT_RATE_MULTIPLIER } from '@monovo/energy-renewals';
import React from 'react';
import { Til } from '../types/quoteRequest';
import { StyledTitle } from './FeaturesAndRates.styled';
import {
  Fuels,
  FuelTitle,
  FuelWrapper,
  RateRow,
  RateRowTitle,
  RateRowValue,
} from './Rates.styled';

function withVat(amount: number) {
  return (Math.ceil(amount * VAT_RATE_MULTIPLIER * 100) / 100).toFixed(2);
}

export function Rates({
  isColumnBased = true,
  electricity,
  gas,
}: {
  isColumnBased?: boolean;
  electricity: Til;
  gas?: Til;
}) {
  const tils = [
    { fuel: 'electric', til: electricity },
    ...(gas ? [{ fuel: 'gas', til: gas }] : []),
  ];

  return (
    <>
      <StyledTitle>Plan rates</StyledTitle>
      <Fuels isColumnBased={isColumnBased} data-testid="rates">
        {tils.map(
          ({ fuel, til: { unitRate, nightUnitRate, standingCharge } }) => (
            <FuelWrapper key={fuel} area={fuel}>
              <FuelTitle>{fuel}</FuelTitle>
              <RateRow key={unitRate} data-testid={'rate-' + fuel}>
                <RateRowTitle>
                  {nightUnitRate ? 'Day' : 'Unit'} Rate:
                </RateRowTitle>
                <RateRowValue>{withVat(unitRate)}p/kWh</RateRowValue>
              </RateRow>

              {!nightUnitRate ? null : (
                <RateRow key={nightUnitRate} data-testid={'night-rate-' + fuel}>
                  <RateRowTitle>Night Rate:</RateRowTitle>
                  <RateRowValue>{withVat(nightUnitRate)}p/kWh</RateRowValue>
                </RateRow>
              )}

              <RateRow data-testid={'standing-charge-' + fuel}>
                <RateRowTitle>Standing charge:</RateRowTitle>
                <RateRowValue>{withVat(standingCharge)}p/day</RateRowValue>
              </RateRow>
            </FuelWrapper>
          ),
        )}
      </Fuels>
    </>
  );
}
