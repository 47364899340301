import { Margin } from '@ovotech/nebula';
import React from 'react';
import Ghost from '../components/Ghost/Ghost';

export const Skeleton = () => {
  return (
    <div>
      <Margin bottom={4} top={8}>
        <Ghost width={'60%'} height={3} />
      </Margin>
      <Ghost width={'100%'} height={3} />
      <Ghost width={'100%'} height={17} />
      <Ghost width={'100%'} height={13} />
      <Ghost width={'100%'} height={13} />
      <Margin vertical={24}>
        <Ghost width={'100%'} height={40} />
      </Margin>
      <Ghost width={'50%'} height={3} />
      <Ghost width={'100%'} height={2} />
      <Margin bottom={12} top={4}>
        <Ghost width={'100%'} height={65} />
      </Margin>
      <Margin bottom={12} top={4}>
        <Ghost width={'100%'} height={65} />
      </Margin>
      <Margin bottom={12} top={4}>
        <Ghost width={'100%'} height={65} />
      </Margin>
      <Margin bottom={12} top={4}>
        <Ghost width={'100%'} height={65} />
      </Margin>
      <Ghost width={'30%'} height={3} />
      <Ghost width={'20%'} height={2} />
      <Ghost width={'100%'} height={3} />
      <Ghost width={'20%'} height={2} />
      <Ghost width={'100%'} height={3} />
      <Margin vertical={6}>
        <Ghost width={'25%'} height={3} />
      </Margin>
      <Ghost width={'20%'} height={2} />
      <Ghost width={'100%'} height={3} />
      <Ghost width={'10%'} height={2} />
      <Ghost width={'100%'} height={3} />
      <Ghost width={'30%'} height={2} />
      <Ghost width={'100%'} height={3} />
    </div>
  );
};
