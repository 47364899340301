import {
  PrimaryCTAButton,
  SecondaryCTAButton,
  Stack,
  CurrencyField,
  Heading3,
  ErrorSummaryNotification,
} from '@ovotech/nebula';
import React, { FormEvent, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switcher } from '@/src/components';
import {
  CLICKED_OVO_FOUNDATION_DONATE_AMOUNT,
  CLICKED_OVO_FOUNDATION_DONATE_SUBMIT,
} from '@/src/constants/analytics';
import { activateProductStart } from '@/src/redux/ducks/products/products';
import { CatalogueBolton } from '@/src/types/Products';
import { OptimizelyContext } from '@/src/utils/optimizely';
import { State } from '@/src/types/State';
import { createSelector } from 'reselect';
import { PRODUCT_IDS } from '@/src/constants/products';

type Props = {
  ovoFoundation: CatalogueBolton;
};

const ACTIVATE_ERROR = [
  {
    id: '1',
    message:
      'An error has occurred! We’ll take a look at this shortly, in the meantime please try refreshing the page.',
  },
];

export const VALUE_PARAMS = { min: 0.01, max: 20 };
export const DONATE_AMOUNTS = [2, 5, 10];

const isValidRange = (poundsToDonate: string) =>
  Number(poundsToDonate) >= VALUE_PARAMS.min &&
  Number(poundsToDonate) <= VALUE_PARAMS.max;

const productsSelector = ({ products }: State) => products;
export const isOvoFoundationProductActiveSelector = createSelector(
  productsSelector,
  ({ activate }) => activate && activate[PRODUCT_IDS.ovoFoundation],
);

export const DonateForm = ({ ovoFoundation }: Props) => {
  const dispatch = useDispatch();
  const activateProduct = useSelector(isOvoFoundationProductActiveSelector);
  const { optimizely } = useContext(OptimizelyContext);

  const [poundsToDonate, setPoundsToDonate] = useState('');
  const [loading, setLoading] = useState(false);
  const [rangeError, setRangeError] = useState(false);

  const activateError = activateProduct && activateProduct.error;

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setPoundsToDonate(e.currentTarget.value);
  };

  const handleSubmit = () => {
    setRangeError(false);

    if (isValidRange(poundsToDonate)) {
      const donationAmount = Number(poundsToDonate);

      setLoading(true);
      dispatch(
        activateProductStart(
          ovoFoundation.productId,
          ovoFoundation.versionId,
          undefined,
          undefined,
          { cost: donationAmount },
        ),
      );
      optimizely?.track('start_donating_today', {
        value: donationAmount,
        revenue: donationAmount * 100,
      });
    } else {
      setRangeError(true);
    }
  };

  return (
    <>
      <Heading3>Donate monthly</Heading3>
      <Stack spaceBetween={[4, 8]}>
        <Switcher threshold="1">
          {DONATE_AMOUNTS.map(amount => (
            <SecondaryCTAButton
              key={amount}
              data-testid={`foundation-donate-form-${amount}`}
              data-event-name={CLICKED_OVO_FOUNDATION_DONATE_AMOUNT}
              data-event-props={JSON.stringify({ amount: `£${amount}` })}
              onClick={() => setPoundsToDonate(`${amount}.00`)}
            >
              {`£${amount}.00`}
            </SecondaryCTAButton>
          ))}
        </Switcher>
        <CurrencyField
          id="amount"
          characters={6}
          label="Your donation"
          value={poundsToDonate}
          min={VALUE_PARAMS.min}
          max={VALUE_PARAMS.max}
          step={0.01}
          onChange={handleChange}
          error={
            rangeError
              ? `Invalid amount. Please enter an amount between £${VALUE_PARAMS.min} and £${VALUE_PARAMS.max}`
              : undefined
          }
        />
        <PrimaryCTAButton
          data-testid="foundation-donate-form-submit"
          data-event-name={CLICKED_OVO_FOUNDATION_DONATE_SUBMIT}
          data-event-props={JSON.stringify({
            amountSubmitted: `£${poundsToDonate} submitted`,
          })}
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <>Loading...</> : <>Start donating today</>}
        </PrimaryCTAButton>

        {activateError === true ? (
          <ErrorSummaryNotification
            id="activate-error"
            errors={ACTIVATE_ERROR}
          />
        ) : null}
      </Stack>
    </>
  );
};
