import { useQuery } from 'react-query';
import { KAPI_URL } from '@/src/constants/endpoints';
import { useAccountContext } from '@ovotech/ui-tools';
import { apiService } from '@/src/services/api-service';
import { graphql } from './__generated__';
import { SupplyPointByAccountQuery } from './__generated__/graphql';
import { buildGraphqlQueryBody } from '../utils/buildGraphqlQueryBody';

const SUPPLY_POINTS_QUERY = graphql(/* kapi-codegen-graphql */ `
  query SupplyPointByAccount($accountId: ID!) {
    account(id: $accountId) {
      accountSupplyPoints {
        ...AccountSupplyPointFragment
      }
    }
  }

  fragment AccountSupplyPointFragment on AccountSupplyPoint {
    startDate
    end {
      date
    }
    supplyPoint {
      status {
        current {
          status
        }
      }
      fuelType
      meterTechnicalDetails {
        meterSerialNumber
        mode
        type
      }
      address {
        addressLines
        postCode
      }
    }
  }
`);

export const useSupplyPointsQuery = () => {
  const { accountId } = useAccountContext();

  return useQuery(
    `supply-points-${accountId}`,
    () =>
      apiService.graphql<SupplyPointByAccountQuery>({
        url: KAPI_URL!,
        body: buildGraphqlQueryBody(SUPPLY_POINTS_QUERY, {
          accountId: accountId ?? '',
        }),
      }),
    {
      retry: 3,
      enabled: !!accountId,
    },
  );
};
