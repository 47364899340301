import React, { useContext } from 'react';

import { TadoOffer } from './TadoOffer';

import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { SmartThermostatsPricing } from '@/src/types/Response';

import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import { OptimizelyContext } from '@/src/utils/optimizely';
import {
  PaymentType,
  OrderType,
} from '@/src/pages/SmartThermostats/utils/types';
import {
  buildPromoCodeLink,
  getBoltonPrice,
} from '@/src/pages/SmartThermostats/utils/utils';

interface Props {
  pricing: SmartThermostatsPricing;
}

export const ThermostatsOffer = ({ pricing }: Props) => {
  const { optimizely } = useContext(OptimizelyContext);

  const upfrontTotal = getBoltonPrice(
    pricing,
    PaymentType.FullAmountPayment,
    OrderType.SelfInstall,
  );

  const promoCode = getQueryParamValue('promoCode');
  const route = buildPromoCodeLink(ROUTE_SMART_THERMOSTATS_OFFER, promoCode);

  return (
    <AnalyticsWrapper name={SMART_THERMOSTATS.OFFER_BANNER.VIEWED}>
      <TadoOffer
        price={upfrontTotal}
        linkUrl={route}
        tracking={() => {
          optimizely?.track('clicked_smart_thermostats_banner_button');
        }}
      ></TadoOffer>
    </AnalyticsWrapper>
  );
};
