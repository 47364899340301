import { Card, Heading2, P } from '@ovotech/nebula';
import React, { memo } from 'react';

export const LoadingPanel = memo(() => {
  return (
    <Card>
      <Heading2>Loading&hellip;</Heading2>
      <P>Please wait while we&rsquo;re loading your data&hellip;</P>
    </Card>
  );
});

LoadingPanel.displayName = 'LoadingPanel';
