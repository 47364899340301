import axios, { AxiosError, AxiosResponse } from 'axios';
import decode from 'jwt-decode';
import { DecodedToken } from './getCustomerId';
import { TOKEN_URL } from '@/src/constants/endpoints';

type CustomerToken = {
  accessToken: {
    value: string;
  };
  accessExpiry: number;
  refreshExpiry: number;
};

type TokenResponse = {
  accessToken: {
    value: string;
  };
  expiresIn: number;
  refreshExpiresIn: number;
};

// caching
let token: CustomerToken | null;

export async function handleLogout() {
  // redirect
  if (process.env.NX_LOGIN_REDIRECT) {
    window.location.href = `${
      process.env.NX_LOGIN_REDIRECT
    }?redirect=${encodeURIComponent(window.location.href)}`;
    // wait for a long time so the redirect has time to change page
    // before the generic error message is displayed on the page
    return sleep();
  } else {
    return Promise.reject('LOGIN_REDIRECT not set');
  }
}

// avoid showing error on a 401
const sleepTimeout = Number(process.env.SLEEP_TIMEOUT || 10_000);
async function sleep(): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => resolve(), sleepTimeout);
  });
}

let resolver: (value: AxiosResponse<TokenResponse>) => void;
let activePromise: Promise<AxiosResponse<TokenResponse>> | null;

async function requestToken() {
  // set up a new promise to return token on multiple reqs
  activePromise = new Promise(resolve => {
    resolver = resolve;
  });
  try {
    const token = await axios({
      url: TOKEN_URL || '/api/token',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    resolver && resolver(token);
    return token;
  } catch (err) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(`Error fetching token: ${err}`);
    }
    return Promise.reject(err);
  }
}

const LEEWAY_SECONDS = 10;

// Must always return a promise which resolves to a token (or error)
// so we can't cancel duplicate requests (calls will error)
// called by api service, observable api service and Apollo
export const getCustomerAccessToken = async () => {
  // if refresh token expired, log out and don't request an access token
  if (token?.refreshExpiry && new Date().getTime() >= token.refreshExpiry) {
    if (process.env.NODE_ENV !== 'test') {
      console.error('Refresh token expired');
    }

    token = null;
    await handleLogout();

    // shouldn't ever get here, but logout redirect may take some time
    return Promise.reject('Refresh expired');
  }

  if (token?.accessExpiry && new Date().getTime() < token.accessExpiry) {
    // return cached token
    return token;
  }

  try {
    // prevent multiple concurrent requests to token endpoint
    // if there is an active request, await the promise outcome which will contain token
    // otherwise start a new request which sets the activePromise
    const newToken = activePromise ? await activePromise : await requestToken();
    const decoded: DecodedToken = decode(newToken.data.accessToken.value);
    // cache token and expiry
    token = {
      accessToken: { value: newToken.data.accessToken.value },
      // convert to millis
      accessExpiry: (Number(decoded.exp) - LEEWAY_SECONDS) * 1000,
      refreshExpiry:
        new Date().getTime() + newToken.data.refreshExpiresIn * 1000,
    };
    return token;
  } catch (e) {
    // reset everything on error
    token = null;
    const { response } = e as AxiosError;
    if (response && response.status === 401) {
      await handleLogout();
    }
    // if not 401
    return Promise.reject(e);
  } finally {
    // remove the promise since there is no longer an active request
    activePromise = null;
  }
};
