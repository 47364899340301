import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Section } from '@/src/components';
import { PRODUCT_IDS } from '@/src/constants/products';
import { OVO_FOUNDATION_FORM_SECTION_ID } from '@/src/constants/sectionIds';
import { CatalogueBolton } from '@/src/types/Products';
import {
  getProductCatalogueStart,
  getProductsStart,
} from '@/src/redux/ducks/products/products';
import { State } from '@/src/types/State';
import {
  getEligibleProductFromCatalogue,
  hasBolton,
} from '@/src/utils/products';
import { OvoFoundationDonate } from './OvoFoundationDonate';

function OvoFoundationFeature() {
  const dispatch = useDispatch();
  const products = useSelector((state: State) => state.products);

  useEffect(() => {
    if (
      !products.activated.data &&
      !products.activated.isFetching &&
      !products.activated.errorResponse
    ) {
      dispatch(getProductsStart());
    }
    dispatch(getProductCatalogueStart());
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ovoFoundation =
    products.catalogue.data &&
    getEligibleProductFromCatalogue(
      products.catalogue.data,
      PRODUCT_IDS.ovoFoundation,
    );

  const hasOvoFoundation =
    products.activated.data &&
    hasBolton(products.activated.data.boltons, PRODUCT_IDS.ovoFoundation);

  return (
    <>
      {ovoFoundation && !hasOvoFoundation ? (
        <OvoFoundationForm ovoFoundation={ovoFoundation} />
      ) : null}
    </>
  );
}

function OvoFoundationForm({
  ovoFoundation,
}: {
  ovoFoundation: CatalogueBolton;
}) {
  const ref = createRef<HTMLElement>();
  useEffect(() => {
    if (window.location.href.includes('#ovo-foundation-form') && ref.current) {
      ref.current.scrollIntoView();
    }
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <Section id={OVO_FOUNDATION_FORM_SECTION_ID} ref={ref}>
      <OvoFoundationDonate ovoFoundation={ovoFoundation} />
    </Section>
  );
}

export { OvoFoundationFeature as OvoFoundationForm };
