export const transformAddress = (address: string | undefined) => {
  if (!address) return undefined;

  const words = address.split(', ');

  const capitalisedWords = words.map(w =>
    w
      .split(' ')
      .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
      .join(' '),
  );

  const formattedAddress = capitalisedWords.join(', ');

  return formattedAddress;
};

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
