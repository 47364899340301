const prefix = (text: string) => `TUWY-${text}`;
export const PAGE_GET_QUOTE = prefix('GetQuote');
export const PAGE_QUOTE = prefix(`Quote`);
export const PAGE_CONFIRMATION = prefix('PlanConfirmation');
export const PAGE_CUSTOMER_DETAILS = prefix('CustomerDetails');
export const PAGE_METER_DETAILS = prefix('MeterDetails');
export const PAGE_PAYMENT_DETAILS = prefix('BankDetails');
export const PAGE_SUCCESS = prefix('Success');

export const EVENT_ADDRESS_LOOKUP = prefix('UsedAddressLookup');
export const EVENT_CONFIRM = prefix('ConfirmedMoveIn');
export const EVENT_MANUAL_ADDRESS = prefix('UsedManualAddress');
export const EVENT_MOVE_IN_ERROR = prefix('MoveInError');
export const EVENT_SELECTED_TARIFF = prefix('SelectedTariff');
