import { Card } from '@ovotech/nebula';
import styled from 'styled-components';
import { ReactComponent as BatterySVG } from '@/src/resources/battery-icon-small.svg';
import { ReactComponent as ElectricVehicleSVG } from '@/src/resources/electric-vehicle-icon.svg';

export const StyledGreenCard = styled(Card)`
  ${({ theme: { colors, mediaQueries } }) => `
    display: none;
   ${mediaQueries.smallAndUp} {
    background: ${colors.primaryMuted};
    color: ${colors.heading};
    display: block;
  }
  `};
`;

export const StyledVehicleInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledBatteryInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    flex-direction: column;
    ${mediaQueries.smallAndUp} {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  `};
`;

export const StyledDiv = styled.div`
  ${({ theme: { mediaQueries } }) => `
   display: flex;
  flex-direction: row;
  justify-content: space-around;

  &:last-child {
    margin-top: 16px;
  }

    ${mediaQueries.smallAndUp} {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      &:last-child {
        margin-top: 0px;
      }
    }
  `};
`;

export const StyledElectricVehicleSVG = styled(ElectricVehicleSVG)`
  display: flex;
  margin-right: ${({ theme: { space } }) => space[6]};
`;

export const StyledBatteryeSVG = styled(BatterySVG)`
  display: flex;
  margin-right: ${({ theme: { space } }) => space[6]};
`;

export const StyledHeading4 = styled.h4`
  margin-bottom: 0px;
`;
