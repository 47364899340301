import { addMonths, format, subMonths } from 'date-fns';
import React from 'react';
import {
  NavChevronRight,
  NavChevronLeft,
  MonthNavSection,
  MonthlyNavHeading,
} from './MonthlyNavigator.styled';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { ROUTE_MY_THERMOSTAT } from '@/src/constants/routes';
import NavigationLink from '@/src/components/PreviousNextNavigation/NavigationLink';
import { MonthYear } from '@/src/types/State';

const getRoute = (date: Date) => ({
  pathname: `${ROUTE_MY_THERMOSTAT}/${format(date, 'YYYY/MM')}`,
});

const getPrevMonth = (date: Date) => subMonths(date, 1);

export const getNextMonth = (date: Date) => addMonths(date, 1);

const getPrevMonthRoute = (date: Date) => getRoute(getPrevMonth(date));

const getNextMonthRoute = (date: Date) => getRoute(getNextMonth(date));

const toDisplayFormat = (date: Date) => format(date, 'MMMM YYYY');

// When using date-fns, if given 1 as the month
// number, it will show Feburary.
export const FNS_MONTH_INDEX_CORRECTION = 1;
export const convertMonthYearToDateObj = (date: MonthYear) =>
  new Date(date.year, date.month - FNS_MONTH_INDEX_CORRECTION, 1);
export const convertDateObjToMonthYear = (date: Date) => {
  return {
    year: date.getUTCFullYear(),
    month: date.getMonth() + FNS_MONTH_INDEX_CORRECTION,
  };
};

type Props = {
  dateNavigatedTo: MonthYear;
};

const MonthlyNavigator = ({ dateNavigatedTo }: Props) => {
  const dateObj = convertMonthYearToDateObj(dateNavigatedTo);
  const currentDateObj = new Date();
  const isNavigatedToCurrentMonth =
    toDisplayFormat(dateObj) === toDisplayFormat(currentDateObj);
  return (
    <MonthNavSection data-testid="month-navigator">
      <NavigationLink
        data-testid="month-navigator-to-left"
        to={getPrevMonthRoute(dateObj)}
        disabled={false}
        aria-label={`Navigate to ${toDisplayFormat(getPrevMonth(dateObj))}`}
        data-event-name={
          SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.CONNECTED.CLICKED.PREV
        }
      >
        <NavChevronLeft />
      </NavigationLink>
      <MonthlyNavHeading> {toDisplayFormat(dateObj)} </MonthlyNavHeading>
      <NavigationLink
        data-testid="month-navigator-to-right"
        to={getNextMonthRoute(dateObj)}
        disabled={isNavigatedToCurrentMonth}
        aria-label={`Navigate to ${toDisplayFormat(getNextMonth(dateObj))}`}
        data-event-name={
          SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.CONNECTED.CLICKED.NEXT
        }
      >
        <NavChevronRight />
      </NavigationLink>
    </MonthNavSection>
  );
};

export default MonthlyNavigator;
