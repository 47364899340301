import React from 'react';
import styled from 'styled-components';
import { ActionCentreBanner } from '@/src/components/ActionCentre/Banners/Banner';
import { FIXED_PLAN_BANNER } from '@/src/constants/analytics-subjects';
import { RENEWAL_LINK } from '@/src/constants/links';
import { ClippedImageBanner } from '@/src/components/ActionCentre/Banners/ClippedImageBanner/ClippedImageBanner';
import handraisersBanner from '@/src/resources/HandraisersBanner.jpg';
import { mqMediumDown } from '@/src/utils/mediaQuery';

const ClippedImg = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url(${handraisersBanner});
  background-size: 430px auto;
  clip-path: polygon(15% 0%, 100% 0%, 100% 68%, 35% 92%);

  ${mqMediumDown(`
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    background-size: cover;
    background-position: top;
  `)}
`;

export const FixedPlanRenewalCard: ActionCentreBanner = ({
  onClick,
  dismiss,
}) => {
  return (
    <ClippedImageBanner
      analyticsName={FIXED_PLAN_BANNER}
      heading="Your current plan ends soon"
      body="Choose your next energy plan today from our new range -  including one which offers our cheapest energy prices."
      linkTo={RENEWAL_LINK}
      onClick={onClick}
      onDismiss={dismiss}
      ctaTitle="Pick a new fixed plan"
      cancelTitle="Close"
      iconRight="off"
      CroppedImage={ClippedImg}
    />
  );
};
