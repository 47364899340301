import { Margin, P } from '@ovotech/nebula';
import React from 'react';
import { useTheme } from 'styled-components';
import {
  UsageBreakdownBarWrapper,
  UsageBreakdownBar,
  UsageBreakdownBarFigure,
} from '../UsageBreakdownBar/UsageBreakdownBar.styled';
import { Fuel } from '@/src/types/Response';
import { BreakdownInfo } from '@/src/pages/EnergyInsights/constants/usage';
import { formatConsumption } from '@/src/pages/EnergyInsights/utils/usage';

type Props = {
  currentWeekConsumption: number;
  currentWeekPrice?: number;
  lastWeekConsumption: number;
  lastWeekPrice?: number;
  breakdownInfo: BreakdownInfo;
  fuel: Fuel;
  maxConsumption?: number;
  showLastWeek: boolean;
  showPrice: boolean;
};

const BAR_SCALE_FACTOR = 0.3;

const barWidth = (weekConsumption: number, maxConsumption: number) => {
  return ((weekConsumption / maxConsumption) * 100 * BAR_SCALE_FACTOR).toFixed(
    0,
  );
};

const UsageCollapsibleHeader = ({
  currentWeekConsumption,
  currentWeekPrice,
  breakdownInfo,
  lastWeekConsumption,
  lastWeekPrice,
  fuel,
  showLastWeek,
  maxConsumption,
  showPrice,
}: Props) => {
  const theme = useTheme();

  return (
    <>
      <Margin bottom={4}>
        <span data-testid={`${breakdownInfo.displayName} consumption`}>
          {breakdownInfo.displayName}
        </span>
      </Margin>
      <UsageBreakdownBarWrapper>
        <UsageBreakdownBar
          top="0"
          width={barWidth(currentWeekConsumption, maxConsumption!)}
          colour={
            fuel === Fuel.ELECTRICITY
              ? theme.colors.brand.electricity.base
              : theme.colors.brand.gas.base
          }
        >
          <UsageBreakdownBarFigure>
            <P>
              est.{' '}
              {showPrice && currentWeekPrice
                ? '£' + currentWeekPrice
                : formatConsumption(currentWeekConsumption) + ' kWh'}
            </P>
          </UsageBreakdownBarFigure>
        </UsageBreakdownBar>
        {showLastWeek ? (
          <UsageBreakdownBar
            width={barWidth(lastWeekConsumption, maxConsumption!)}
            top="24px"
            colour={
              fuel === Fuel.ELECTRICITY
                ? 'rgba(231, 162, 52, .35)'
                : 'rgba(38, 173, 228, 0.35)'
            }
          >
            <UsageBreakdownBarFigure>
              <P>
                est.{' '}
                {showPrice && lastWeekPrice
                  ? '£' + lastWeekPrice
                  : formatConsumption(lastWeekConsumption) + ' kWh'}
              </P>
            </UsageBreakdownBarFigure>
          </UsageBreakdownBar>
        ) : null}
      </UsageBreakdownBarWrapper>
    </>
  );
};

export default UsageCollapsibleHeader;
