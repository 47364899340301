import React from 'react';
import {
  StyledOvoBrandHeaderBg,
  StyledOvoBrandHeaderBgWrapper,
} from './OvoBrandHeader.styled';

type Props = {
  children: React.ReactElement;
};

const HeaderWrapper = ({ children }: Props) => {
  return (
    <>
      <StyledOvoBrandHeaderBgWrapper className="bg-ovo">
        <StyledOvoBrandHeaderBg />
      </StyledOvoBrandHeaderBgWrapper>
      {children}
    </>
  );
};

export { HeaderWrapper };
