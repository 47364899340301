import React from 'react';
import { StatusBlockContainer } from './styles';
import { Margin } from '@ovotech/nebula';
import { AnalyticsClick, useAccountContext } from '@ovotech/ui-tools';
import { StatusBlockProps } from './types';
import { StatusContent, StatusMessage } from './StatusComponents';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import { TESCO_EVENTS } from '@/src/constants/analytics';

export const SmartMeterStatus = ({ status }: StatusBlockProps) => {
  const { accountId } = useAccountContext();

  const onClickHandler = () => {
    window.location.assign(`${SMART_BOOKING_URL}?accountId=${accountId}`);
  };

  return (
    <>
      <StatusBlockContainer>
        <StatusMessage title="Install a smart meter" status={status} />
        {status !== 'eligible' && (
          <Margin top={2}>
            {status === 'ineligible' ? (
              <AnalyticsClick name={TESCO_EVENTS.CLICKED_SMB}>
                <StatusContent
                  onClick={onClickHandler}
                  buttonName="Book smart meter installation"
                  placeholderText="Once installed, it can take a few days to update your account."
                />
              </AnalyticsClick>
            ) : (
              <StatusContent
                onClick={onClickHandler}
                buttonName="View booking"
                placeholderText="Once installed, it can take a few days to update your account."
              />
            )}
          </Margin>
        )}
      </StatusBlockContainer>
    </>
  );
};
