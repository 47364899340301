import { Heading2, Margin, P } from '@ovotech/nebula';
import { AvailablePlan } from '@monovo/energy-renewals';
import React from 'react';
import { Section } from '../EOC1Compliance.styled';
import PlanInfoCard from './PlanCard/PlanInfoCard';

export function FixedLoyaltyPlan({
  fixedYearLoyaltyPlans,
}: {
  fixedYearLoyaltyPlans: AvailablePlan[];
}) {
  return fixedYearLoyaltyPlans.length > 0 ? (
    <Section>
      <Heading2 as="h3">Our exclusive plan at OVO</Heading2>
      <P>
        Exclusively for you as a loyal member, with fair prices fixed for one
        year.
      </P>
      {fixedYearLoyaltyPlans.map(plan => (
        <Margin bottom={6} key={plan.name}>
          <PlanInfoCard plan={plan} />
        </Margin>
      ))}
    </Section>
  ) : null;
}
