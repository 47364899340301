import { format } from 'date-fns';

import { ROUTE_USAGE } from '@/src/constants/routes';

type UsageLinkParams = {
  fuel?: string;
  date?: string;
  unit?: string;
  usageUnit?: string;
};

export const usageLinkWithParams = (params?: UsageLinkParams) => {
  if (!params) return ROUTE_USAGE;
  if (params.date) {
    params.date = format(params.date, 'YYYY-MM-DD');
  }
  const formattedParams = new URLSearchParams(params);
  return `${ROUTE_USAGE}?${formattedParams.toString()}`;
};
