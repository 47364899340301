import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { useTheme } from 'styled-components';
import { StyledSection, Title } from './UsageOvoGreenlightLink.styled';
import { CLICKED_OVO_GREENLIGHT_FROM_USAGE } from '@/src/constants/analytics';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { Link } from 'react-router-dom-v5';

export const UsageOvoGreenlightLink = () => {
  const theme = useTheme();

  // Class names to allow us to target these elements from the web Optimizely client
  return (
    <StyledSection>
      <Title className="usage-greenlight-title">Want to know</Title>
      <TextLink
        style={{ fontSize: theme.fontSizes[2] }}
        as={Link}
        to={ROUTE_ENERGY_INSIGHTS.HUB}
        data-event-name={CLICKED_OVO_GREENLIGHT_FROM_USAGE}
        className="usage-greenlight-link"
      >
        where your energy’s going?
      </TextLink>
    </StyledSection>
  );
};
