import { Card, Strong, Margin, CTALink } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom-v5';
import styled from 'styled-components';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { energyInsightsStrings } from '@/src/pages/EnergyInsights/strings';
import AnalyticsService from '@/src/services/analyticsService';
import { energyInsightsTestIDs } from '@/src/pages/EnergyInsights/testIDs';
import { AnyBreakdownStatus } from '@/src/pages/EnergyInsights/types/bill-breakdown.types';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 170px;
`;

type Props = {
  statusCode: AnyBreakdownStatus | string;
  message: string;
};

export const NoUsageBreakdown = ({ statusCode, message }: Props) => {
  useEffect(() => {
    AnalyticsService.dispatch(ENERGY_INSIGHTS.VIEWED_NO_BREAKDOWN_MESSAGE, {
      breakdownStatusCode: statusCode,
    });
  }, [statusCode]);
  return (
    <StyledCard>
      <Strong data-testid={energyInsightsTestIDs.breakdownPage.noBreakdown}>
        {message}
      </Strong>
      <Margin top={6} />
      <CTALink
        as={Link}
        to={ROUTE_ENERGY_INSIGHTS.HUB}
        iconLeft="chevron-left"
        iconRight="off"
        data-testid={energyInsightsTestIDs.breakdownPage.backToHub}
        onClick={() => {
          AnalyticsService.dispatch(
            ENERGY_INSIGHTS.CLICKED_NO_BREAKDOWN_BACK_TO_HUB,
          );
        }}
      >
        {energyInsightsStrings.backToHub}
      </CTALink>
    </StyledCard>
  );
};
