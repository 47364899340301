import React from 'react';
import { PRIVACY_POLICY_LINK } from '@/src/constants/routes';
import { P, TextLink } from '@ovotech/nebula';
import { homeProfileModalTestIDs } from '../testIDs';

export const PrivacyPolicyNotice = () => {
  return (
    <P data-testid={homeProfileModalTestIDs.form.privacyPolicyNotice}>
      This information helps personalise your insights and offers.{' '}
      <TextLink
        data-testid={homeProfileModalTestIDs.form.privacyPolicyLink}
        opensInNewWindow
        href={PRIVACY_POLICY_LINK}
      >
        See our privacy policy.
      </TextLink>
    </P>
  );
};
