import { Card, Heading4, Margin, P } from '@ovotech/nebula';
import React from 'react';
import { GreyCard, CenterContent, IconConnect } from './ConnectCard.styled';
import { ConnectButton } from '../ConnectButton';

export const ConnectCard = ({ authLink }: { authLink: string | null }) => {
  return (
    <Card as={GreyCard}>
      <CenterContent>
        <Margin>
          <IconConnect />
          <Margin top={2}>
            <Heading4>Learn more about your heating</Heading4>
          </Margin>
          <P style={{ marginTop: 0 }}>
            Connect your tado° to OVO and start learning how to save money,
            energy and carbon.
          </P>
          <Margin top={8}>
            <ConnectButton authLink={authLink} />
          </Margin>
        </Margin>
      </CenterContent>
    </Card>
  );
};
