import { Card, Heading1, P } from '@ovotech/nebula';
import { AnalyticsView } from '@ovotech/ui-tools';
import React from 'react';
import { StyledContent, StyledMaintenancePage } from './MaintenanceMode.styled';
import { MAINTENANCE_MODE_PAGE } from '@/src/constants/analytics-subjects';

export const MaintenanceMode = () => {
  return (
    <AnalyticsView name={MAINTENANCE_MODE_PAGE}>
      <StyledMaintenancePage id={'maintenanceMode'}>
        <StyledContent as={Card}>
          <Heading1>We're currently down for scheduled maintenance</Heading1>
          <P>Please check back soon.</P>
        </StyledContent>
      </StyledMaintenancePage>
    </AnalyticsView>
  );
};
