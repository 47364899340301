import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme: { space } }) => `
    padding-right: ${space[4]} ;
    margin-top: ${space[2]};
    margin-bottom: ${space[2]};
  `}
`;
