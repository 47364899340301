import { useQuery } from 'react-query';
import { createGetSmartMeterBookingEndpoint } from '@/src/constants/endpoints';
import { SmartMeterBookingResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `orion-smart-meter-marketing-${string}`;

export const useSmartMeterMarketingQuery = (
  accountId: string | null | undefined,
) =>
  useQuery(
    `orion-smart-meter-marketing-${accountId}` as QueryCacheKey,
    () =>
      apiService.get<SmartMeterBookingResponse>({
        url: createGetSmartMeterBookingEndpoint(accountId ?? ''),
      }),
    { enabled: !!accountId },
  );
