import { createGetNextBestActionEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { NbaChannel, NextBestActionResponse } from '@/src/types/NextBestAction';
import { useQuery } from 'react-query';

const timeInMinutes = 60 * 1000;

export function useNextBestActionQuery(
  accountId: string | null | undefined,
  channel: NbaChannel,
) {
  const nextBestActionEndpoint = createGetNextBestActionEndpoint(
    accountId ?? '',
    channel,
  );

  return useQuery<NextBestActionResponse>(
    ['orion-next-best-action', accountId, channel],
    () =>
      apiService.get<NextBestActionResponse>({
        url: nextBestActionEndpoint,
      }),
    {
      enabled: !!accountId,
      cacheTime: 30 * timeInMinutes,
      staleTime: 30 * timeInMinutes,
      retry: false,
      retryOnMount: false,
    },
  );
}
