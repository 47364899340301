import { addDays } from 'date-fns';
import { Sale } from '../types/acquisitionRequest';
import { MoveInDetails } from '../types/moveIn';
import { toShortISODate } from './date';

export function journeyToSale({
  bankDetails,
  billingAddress,
  property,
  meterDetails,
  moveInDate,
  quoteDetails,
  selectedTariff,
  isE7,
}: Required<MoveInDetails>): Sale {
  const monthlyCost = Math.ceil(selectedTariff.expectedAnnualSpend / 12);

  return {
    salesChannel: 'QS',
    saleValidationStatus: 'Confirmed',
    // Is actually about current method of supply - should be checked on the server instead of by the frontend.
    saleType: quoteDetails.cotCustomer ? 'MoveIn' : 'Switch',
    saleDate: new Date().toISOString().split('T')[0],
    moveInFlag: true, // Deprecated, always true
    broker: 'TUW',
    tracking: { medium: 'Take us with you' },
    plan: {
      quoteDate: toShortISODate(new Date()),
      chosenSupplyStartDate: quoteDetails.cotCustomer
        ? toShortISODate(addDays(new Date(), 3))
        : toShortISODate(moveInDate),
      annualQuotedAmount: selectedTariff.expectedAnnualSpend,
      selectedElectricityTariff: {
        tariffPlanName: selectedTariff.name,
        annualEstimatedUsage: selectedTariff.tils.Electricity.annualUsage,
        category: isE7 ? 'Economy7' : 'Anytime',
      },
      ...(selectedTariff?.tils.Gas
        ? {
            selectedGasTariff: {
              tariffPlanName: selectedTariff.name,
              annualEstimatedUsage: selectedTariff.tils.Gas.annualUsage,
              category: 'Anytime',
            },
          }
        : {}),
    },
    additionalProducts: [],
    paymentMethod: {
      bankAccount: {
        ...bankDetails,
        sortCode: bankDetails.sortCode.replaceAll('-', ''),
      },
      monthlyPayment: monthlyCost,
    },
    propertyToSupply:
      property.manualAddress || meterDetails
        ? { ...property, ...meterDetails }
        : property.uprn,
    billingAddress:
      billingAddress.manualAddress || meterDetails
        ? { ...billingAddress, ...meterDetails }
        : billingAddress.uprn,
  };
}
