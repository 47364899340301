import React from 'react';
import { StyledBlades } from './GreenerEnergyAnimation.styled';

interface TurbineAnimationProps {
  scale: number;
  style?: React.CSSProperties;
  turbineFill?: TurbineFill;
}

export enum TurbineFill {
  LIGHT = '#6CBD27',
  LIGHTEST = '#B4CE43',
  DARK = '#0D8426',
  GENERAL = '#70B03A',
}

const Turbine = ({ turbineFill, scale, style }: TurbineAnimationProps) => {
  return (
    <svg
      width="48"
      height="57"
      viewBox="0 0 48 57"
      fill={turbineFill || TurbineFill.GENERAL}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: 'block',
        bottom: 0,
        transform: `scale(${scale})`,
        transformOrigin: 'bottom center',
        ...style,
      }}
    >
      <path
        opacity="0.3"
        d="M26.6915 18.187H20.4111V50.1709H26.6915V18.187Z"
        fill={turbineFill || TurbineFill.GENERAL}
      />
      <path
        opacity="0.3"
        d="M39.2525 50.1709H7.85059V56.4422H39.2525V50.1709Z"
        fill={turbineFill || TurbineFill.GENERAL}
      />
      <StyledBlades
        className="blades"
        d="M28.5749 22.4201L23.5506 0L18.5263 22.4201L1.56934 37.9417L23.5506 31.0432L45.532 37.9417L28.5749 22.4201Z"
        fill={turbineFill || TurbineFill.GENERAL}
        scale={scale}
      />
      <path
        opacity="0.3"
        d="M6.28037 25.0851C6.28037 18.8138 9.73458 13.1696 14.9159 10.1907L16.643 2.66504C6.90841 5.64393 0 14.5806 0 25.0851C0 26.9665 0.157009 28.6911 0.628037 30.4158L6.28037 25.0851Z"
        fill={turbineFill || TurbineFill.GENERAL}
      />
      <path
        opacity="0.3"
        d="M40.8228 25.0851L46.4751 30.4158C46.9462 28.6911 47.1032 26.9665 47.1032 25.0851C47.1032 14.5806 40.1948 5.64393 30.6172 2.66504L32.3443 10.1907C37.3686 13.1696 40.8228 18.8138 40.8228 25.0851Z"
        fill={turbineFill || TurbineFill.GENERAL}
      />
    </svg>
  );
};

export function TurbineAnimation({
  scale,
  style,
  turbineFill,
}: TurbineAnimationProps) {
  return (
    <Turbine
      scale={scale}
      style={{ ...style, position: 'absolute', bottom: 0 }}
      turbineFill={turbineFill}
    />
  );
}
