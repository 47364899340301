import { Card } from '@ovotech/nebula';
import styled from 'styled-components';

export const FlexCard = styled(Card)`
  flex: 1 1 0;
`;

export const FlexCardContent = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  padding: ${({ theme }) => theme.space[4]};
`;
