import { Heading3, P } from '@ovotech/nebula';
import React from 'react';
import Offer from './Offer/Offer';
import { EV_ANYTIME_SMART_CHARGER_LINK } from '@/src/constants/links';
import image from '@/src/resources/offers/smartCharger.jpg';

export const SmartCharger = () => (
  <Offer
    image={image}
    offerId="smartCharger"
    linkUrl={EV_ANYTIME_SMART_CHARGER_LINK}
    linkText="Choose your charger"
    fullwidthCTA={true}
  >
    <Heading3 as="h2">Want to get a smart EV charger?</Heading3>
    <P>
      We install next generation smart chargers – the Indra Smart PRO or Ohme
      Home Pro – for cheaper, greener, more convenient charging.
    </P>
  </Offer>
);
