import { BootstrapResponse } from '@ovotech/energy-cx';
import {
  AnalyticsData,
  useAccountContext,
  useCustomerContext,
} from '@ovotech/ui-tools';
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom-v5';
import { Main } from './Main';
import SiteLoading from './SiteLoading';
import { useContractsQuery, useDirectDebitQuery } from '@/src/api';

import { GlobalErrorPage } from '@/src/components';
import { BOOTSTRAP_ERROR } from '@/src/constants/error-section-ids';
import {
  ROUTE_HELP,
  ROUTE_HOME,
  ROUTE_PAYG,
  ROUTE_SELECT_ACCOUNT,
} from '@/src/constants/routes';
import { optimizely } from '@/src/utils/optimizely';
import { useQualtricsDataEmbed } from '@/src/hooks/Qualtrics/useQualtricsDataEmbed';
import { getBootstrapSuccess } from '@/src/redux/ducks/bootstrap/bootstrap';
import { getRecommendedDirectDebitStart } from '@/src/redux/ducks/recommendedDirectDebit/recommendedDirectDebit';
import { EnergyContract } from '@/src/types/Account';
import { getActiveContracts } from '@/src/utils/contracts';
import { RecommendedDirectDebitResponse } from '@/src/types/Response';
import { SelectedAccountSupply, State } from '@/src/types/State';
import devAuth from '@/src/utils/devAuth';
import spaLoaded from '@/src/utils/spa-loaded';
import { setInitialUserContext as setInitialUserErrorTrackingContext } from '@/src/services/datadog';

import { useCustomerIdQuery } from '@/src/api/utils/useCustomerIdQuery';
import { useBootstrapQuery } from '@/src/api/kapi/use-bootstrap-query';

type MeterType = 'smart' | 'trad' | null;
const getMeterType = (supply: SelectedAccountSupply): MeterType => {
  if (!supply || !supply.supplyPointInfo) {
    return null;
  }
  return supply.supplyPointInfo.isSmart ? 'smart' : 'trad';
};

const getMeterConfig = (elec: MeterType, gas: MeterType) => {
  if (elec && gas) {
    return elec === gas ? elec : 'mixed';
  }
  return elec || gas;
};

export const getTariffType = (
  elec: EnergyContract[],
  gas: EnergyContract[],
) => {
  const activeElecContracts = getActiveContracts(elec);
  const activeGasContracts = getActiveContracts(gas);

  if (activeElecContracts.length === 0 && activeGasContracts.length === 0) {
    return null;
  }

  const elecType = activeElecContracts.reduce<
    'fixed' | 'variable' | 'half-hourly' | null
  >((_acc, { type }) => type, null);

  const gasType = activeGasContracts.reduce<
    'fixed' | 'variable' | 'half-hourly' | null
  >((_acc, { type }) => type, null);

  if (elecType && gasType) {
    return elecType === gasType ? elecType : 'mixed';
  }

  return elecType || gasType;
};

export const getRecommendedDirectDebitAmountToTrack = (
  friendlyAmount?: RecommendedDirectDebitResponse['friendlyAmount'],
) => {
  const amount = friendlyAmount?.amount;
  if (!amount) return `${amount}`;
  const amountAsNumber = Number(amount);
  return isNaN(amountAsNumber) ? `${amount}` : amountAsNumber;
};

interface Props {
  bootstrapData: BootstrapResponse;
}

const AnalyticsWrapper = ({
  accountId,
  customerId,
  isPayg,
  children,
}: {
  children?: ReactNode;
  accountId: string | null;
  customerId: string;
  isPayg: boolean;
}) => {
  const dispatch = useDispatch();
  const contracts = useContractsQuery(accountId);
  const directDebitQuery = useDirectDebitQuery(accountId);

  const { elecSupply, gasSupply, isOnboarding } = useSelector(
    (state: State) => state.app,
  );

  const meterCount = elecSupply && gasSupply ? 2 : 1;
  const elecMeterType = getMeterType(elecSupply);
  const gasMeterType = getMeterType(gasSupply);
  const meterConfig = getMeterConfig(elecMeterType, gasMeterType);

  useEffect(() => {
    if (accountId != null) {
      dispatch(
        getRecommendedDirectDebitStart({ selectedAccountId: accountId }),
      );
    }
  }, [dispatch, accountId]);
  const recommendedDirectDebit = useSelector(
    (state: State) => state.recommendedDirectDebit,
  );

  const hasDirectDebit = !!directDebitQuery?.data?.amount;

  const isDebtRisk = recommendedDirectDebit.data
    ? recommendedDirectDebit.data.isDebtRisk
    : false;

  const tariffType = contracts.data
    ? getTariffType(contracts.data.electricity, contracts.data.gas)
    : null;

  if (contracts.isLoading || directDebitQuery.isLoading) {
    return <SiteLoading />;
  }

  if (contracts.isError) return <GlobalErrorPage id={'contract-error'} />;

  return (
    <AnalyticsData
      data={{
        account: {
          isPayg,
          isDebtRisk,
          hasDirectDebit,
          meterCount,
          isOnboarding,
          elecMeterType,
          gasMeterType,
          meterConfig,
          tariffType,
          recommendedDirectDebitAmount: getRecommendedDirectDebitAmountToTrack(
            recommendedDirectDebit?.data?.friendlyAmount,
          ),
        },
      }}
    >
      <Main
        accountId={accountId || ''}
        customerId={customerId}
        hasDirectDebit={hasDirectDebit}
        isFetched={directDebitQuery.isFetched}
        isDebtRisk={isDebtRisk}
        tariffType={tariffType}
      >
        {children}
      </Main>
    </AnalyticsData>
  );
};

const WrapperWithData = ({
  bootstrapData,
  children,
}: PropsWithChildren<Props>) => {
  const { hash, pathname } = useLocation();
  const { accountId, setAccountId } = useAccountContext();
  const hasBootstrapDataSetInRedux = useSelector(
    (state: State) => state.user.customerId,
  );

  const { accountIds, customerId, selectedAccountId } = bootstrapData;

  const dispatch = useDispatch();

  // update Redux state since non-hook components will break otherwise
  useEffect(() => {
    // account context takes precedence over bootstrap/cookie data
    const bootstrapWithSelectedAccount = {
      ...bootstrapData,
      selectedAccountId: accountId ? accountId : selectedAccountId,
    };
    dispatch(getBootstrapSuccess('', bootstrapWithSelectedAccount));
  }, [dispatch, bootstrapData, accountId, selectedAccountId]);

  const hasMultipleAccountIds = accountIds.length > 1;
  const { accounts } = bootstrapData;

  const id = accountId ? accountId : selectedAccountId;
  const selectedAccount = accounts.find(
    account => String(account.accountId) === String(id),
  );
  const isPayg = selectedAccount?.isPayg ?? false;

  useEffect(() => {
    spaLoaded(pathname);
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAccountId != null && !accountId) {
      setAccountId(selectedAccountId);
    }
  }, [accountId, setAccountId, selectedAccountId]);

  useEffect(() => {
    if (hash === '') {
      return;
    }

    const element = document.getElementById(hash.substring(1));
    if (element) {
      element.focus();
      if (document.activeElement !== element) {
        element.tabIndex = -1;
        element.focus();
        element.removeAttribute('tabindex');
      }
    }
  });

  /*
    When this component loads, it gets bootstrap data passed into it as a prop.
    This bootstrap data needs to be set in Redux for all our legacy Redux fetching components to work (as they depend on info such as `accountId`).
    We need to ensure the app does not load BEFORE Redux has updated and re-rendered with its state that includes this info (such as `accountId`).

    The 'most reliable' indicator that Redux has updated its state is to use `customerId`, as every user who logs in has a valid `customerId`.
    Using `accountId` may be problematic as users can switch between different account IDs.
    `customerId` is set by the `dispatch(getBootstrapSuccess('', bootstrapWithSelectedAccount));` command in `useEffect` above.
    So when this is available in Redux state, we know the `useEffect` and Redux update has occurred, so we can render the app.

    This also ensures that `customerId` context has been set.

    This was a hidden race condition before that has surfaced with a dependency update.

    TODO: Remove the redux condition once everything has migrated from Redux to React Query
    TODO: Rather than `null`, show a nice loading screen (or continue the one in `index.ejs`)
  */
  if (!hasBootstrapDataSetInRedux || !customerId) {
    return null;
  }

  if (
    hasMultipleAccountIds &&
    !accountId &&
    selectedAccountId === null &&
    pathname !== ROUTE_SELECT_ACCOUNT
  ) {
    return (
      <Redirect
        to={{ pathname: ROUTE_SELECT_ACCOUNT, state: { prevUrl: pathname } }}
      />
    );
  }

  if (pathname === ROUTE_PAYG && !isPayg) {
    return <Redirect to={ROUTE_HOME} />;
  }

  if (
    isPayg &&
    pathname !== ROUTE_PAYG &&
    pathname !== ROUTE_SELECT_ACCOUNT &&
    pathname !== ROUTE_HELP
  ) {
    return <Redirect to={ROUTE_PAYG} />;
  }

  return (
    <AnalyticsWrapper
      data-testid="wrapper"
      accountId={accountId || selectedAccountId}
      customerId={customerId}
      isPayg={isPayg}
    >
      {children}
    </AnalyticsWrapper>
  );
};

const Wrapper = ({ children }: { children?: ReactNode }) => {
  // tmp for kapi bootstrap rollout
  // make sure Optimizely is ready so we can toggle between legacy and kapi
  // TODO CPCE-2346
  const [optimizelyReady, setOptimizelyReady] = useState(false);
  useEffect(() => {
    optimizely.onReady().then(() => {
      setOptimizelyReady(true);
    });
  }, []);
  // end tmp

  const { customerId } = useCustomerContext();

  // tmp - uncomment after `WithOptimizely` is removed CPCE-2346
  // useEffect(() => {
  //   async function fetchCustomerId() {
  //     try {
  //       const id = await getCustomerId();
  //       setCustomerId(id);
  //     } catch (err) {
  //       console.error(err);
  //       return <GlobalErrorPage id={'customer-id-error'} />;
  //     }
  //   }
  //   fetchCustomerId();
  // }, [setCustomerId]);

  const { loading, error, data } = useBootstrapQuery(
    customerId,
    optimizelyReady,
  );
  const { pathname } = useLocation();
  const { accountId } = useAccountContext();

  setInitialUserErrorTrackingContext(customerId, { accountId });

  useQualtricsDataEmbed({ accountId });

  useEffect(() => {
    async function uatLogin() {
      if (process.env.NODE_ENV === 'development') {
        await devAuth();
      }
    }
    uatLogin();
    spaLoaded(pathname);
  }, [pathname]);

  if (loading || !optimizelyReady) {
    return <SiteLoading />;
  }
  if (error || !data) {
    return <GlobalErrorPage id={BOOTSTRAP_ERROR} />;
  }

  return <WrapperWithData bootstrapData={data}>{children}</WrapperWithData>;
};

const WithKaluzaCustomerId = ({ children }: PropsWithChildren) => {
  const { setCustomerId } = useCustomerContext();
  const { data: customerId, error } = useCustomerIdQuery();
  useEffect(() => {
    if (customerId) {
      setCustomerId(customerId);
    }
  }, [setCustomerId, customerId]);
  if (error) {
    return <GlobalErrorPage id={'customer-id-error'} />;
  }
  return <Wrapper>{children}</Wrapper>;
};

export { WithKaluzaCustomerId as Wrapper };
