import styled from 'styled-components';

import { mqSmallDown } from '@/src/utils/mediaQuery';

export const RecommendedDDCalculationWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${mqSmallDown(`
    flex-direction: column;
    align-items: center;
  `)};

  ${({ theme: { space } }) => `
    margin-bottom: ${space[6]};
  `};
`;

export const RecommendedDDCalculationTitle = styled.strong`
  display: block;
`;
export const RecommendedDDCalculationSign = styled(
  RecommendedDDCalculationTitle,
)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[4]};
  `};
`;

export const RecommendedDDDescription = styled.div`
  ${({ theme: { colors, fontSizes } }) => `
    color: ${colors.shadeMid};
    font-size: ${fontSizes[1]};
    line-height: 1.4;
  `};
`;

export const RecommendedDDCalculationItem = styled.div`
  width: 180px;
  text-align: center;
`;
