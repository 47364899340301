import {
  currentIntensityBadgeMap,
  iconMapBig,
} from '../CarbonIntensityShared.assets';

export const carbonIntensityAssets = {
  low: {
    bannerText: 'There’s loads of green energy right now!',
    topText: 'Full power ahead',
    subText:
      'Charge your laptop, put the washing on and turn that to-do list to done.',
    indicatorBadge: currentIntensityBadgeMap.low,
    icon: iconMapBig.low,
  },
  moderate: {
    bannerText: 'There’s some carbon in your energy right now',
    topText: 'Let’s unplug for now',
    subText:
      'Perhaps you could set a timer if the washing can wait? Or charge your laptop when there’s more green power?',
    indicatorBadge: currentIntensityBadgeMap.moderate,
    icon: iconMapBig.moderate,
  },
  high: {
    bannerText: 'Uh oh! The grid is packed with carbon right now',
    topText: 'Unplug and unwind',
    subText:
      'If you can, the best thing to do right now is sit back and relax. We’ll let you know when things are green and good to go.',
    indicatorBadge: currentIntensityBadgeMap.high,
    icon: iconMapBig.high,
  },
};
