import { useMutation } from 'react-query';
import { useAccountContext } from '@ovotech/ui-tools';
import { createProductCancelEndpoint } from '@/src/constants/endpoints';
import { ProductId } from '@/src/types/Products';
import { apiService } from '@/src/services/api-service';

export const useProductCancellationMutation = () => {
  const { accountId } = useAccountContext();

  return useMutation<void, unknown, ProductId>({
    mutationFn: async productId => {
      if (!accountId) {
        return Promise.reject('No account id set');
      }
      await apiService.delete({
        responseType: 'json',
        withCredentials: true,
        url: createProductCancelEndpoint(accountId, productId),
      });
    },
  });
};
