import { Heading3, P, Strong } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { ActionCentreBanner, Banner } from './Banner';
import { useContractsQuery } from '@/src/api';
import { getEpochDaysDate } from '@/src/utils/date';

type PartialContracts = Array<{
  planName: string;
  startDate: number; // epochDays
  status: {
    active: boolean;
    hasFutureContracts: boolean;
  };
}>;

const findFutureContract = (contracts: PartialContracts) => {
  const activeContractIndex = contracts.findIndex(
    ({ status: { active, hasFutureContracts } }) =>
      active && hasFutureContracts,
  );

  return activeContractIndex === -1 ? null : contracts[activeContractIndex + 1];
};

export const NextPlan: ActionCentreBanner = () => {
  const { accountId } = useAccountContext();
  const contracts = useContractsQuery(accountId);

  if (!contracts?.data) return null;

  const nextContract =
    findFutureContract(contracts.data.electricity) ||
    findFutureContract(contracts.data.gas);

  if (!nextContract) {
    return null;
  }

  return (
    <Banner data-testid="nextplan-banner" icon="nextPlan">
      <Heading3 as="h2">Thanks for staying with us</Heading3>
      <P>
        Your new {nextContract.planName} plan will start on{' '}
        <Strong>
          {format(getEpochDaysDate(nextContract!.startDate), 'Do MMMM YYYY')}.
        </Strong>
      </P>
      <P>Let our journey to zero carbon continue!</P>
    </Banner>
  );
};
