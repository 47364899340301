import styled from 'styled-components';
import { Heading2, RadioField } from '@ovotech/nebula';

export const StyledHeading2 = styled(Heading2)(
  ({ theme: { colors } }) => `
    color: ${colors.primary};
    margin: 0.5rem 0;
  `,
);

export const StyledSubHeading = styled(Heading2)(
  ({ theme: { fontWeights } }) => `
    font-weight: ${fontWeights.semiBold};
    margin: 0.5rem 0;
  `,
);

export const StyledRadioField = styled(RadioField)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0 2rem 0;
`;

export const Text = styled.p`
  margin: 0 0 1rem 0;
`;
