import { Link } from 'react-router-dom-v5';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  ${({ theme: { space, mediaQueries } }) => `
    margin-bottom: ${space[7]};

    ${mediaQueries.smallAndUp} {
      margin-right: ${space[3]};
    }
  `}
`;

export const SecondaryButton = styled.button`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[7]};
  `}
`;
