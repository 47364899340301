import React from 'react';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { UsageOvoGreenlightLink } from './components/UsageOvoGreenlightLink/UsageOvoGreenlightLink';

const modify = {
  [sandwichIds.usagePage.topStats]: ({ children }: any) => (
    <>
      {children}
      <UsageOvoGreenlightLink />
    </>
  ),
  // OEP-UI version.
  'oep-usage-top-stats': ({ children }: any) => (
    <>
      {children}
      <UsageOvoGreenlightLink />
    </>
  ),
};

export { modify };
