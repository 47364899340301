import { P, Strong, TextLink } from '@ovotech/nebula';
import React from 'react';
import { FooterNote } from './Card.styled';
import { BannerContainer, Description } from './HomeplanBundleBanner.styled';
import { OVO_ENERGY_TERMS } from '@/src/constants/links';

export default function HomeplanBundleBanner() {
  return (
    <BannerContainer>
      <Description>
        Fix your energy prices for 2 whole years -
        <Strong> plus, get up to 22% off boiler cover.</Strong>*
      </Description>
      <P style={{ lineHeight: 2 }}>
        <TextLink
          target="_blank"
          href={
            'https://homeplan.ovoenergy.com/static/ipid-essentials-v2-82b79d34f044289d2eb8dbe75db30cf3.pdf'
          }
        >
          View boiler cover details
        </TextLink>
        <br />
        <TextLink
          target="_blank"
          href={
            'https://homeplan.ovoenergy.com/static/terms-79864e6009c5579a4266542965761d83.pdf'
          }
        >
          Terms and conditions
        </TextLink>{' '}
        and{' '}
        <TextLink
          target="_blank"
          href={`${OVO_ENERGY_TERMS}/2-year-fix-protect`}
        >
          eligibility criteria
        </TextLink>{' '}
        apply.
      </P>
      <FooterNote>
        * This offer is valid up to 30 days after the start of the new energy
        contract. Terms, conditions and eligibility criteria applies. No claims
        can be made within the first 30 days. For boilers aged 7 years and over
        £2 a month additional premium will be charged.
      </FooterNote>
    </BannerContainer>
  );
}
