import { Col, Row } from '@ovotech/nebula';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const BasicLayout = ({ children }: Props) => (
  <Row data-testid="basic-layout">
    <Col id="main">{children}</Col>
  </Row>
);
