import { Card, Dd, Dl, Dt, Heading3 } from '@ovotech/nebula';
import React from 'react';

import {
  CardWithRightIcon,
  NextCardTitleWrapper,
} from './WhatHappensNextCard.styled';
import { OrderType } from '../../utils/types';

export const WhatHappensNextCard = ({
  installType,
}: {
  installType: OrderType | null;
}) => {
  return (
    <CardWithRightIcon as={Card}>
      <NextCardTitleWrapper>
        <Heading3>Here’s what happens next...</Heading3>
      </NextCardTitleWrapper>
      <Dl>
        {installType === OrderType.SelfInstall && (
          <>
            <Dt>Get set up on tado°</Dt>
            <Dd>You can now download the app and create your account</Dd>
            <Dt>We’ll keep you up to date</Dt>
            <Dd>You’ll receive an email when your tado° is on its way</Dd>
            <Dt>Install your tado°</Dt>
            <Dd>
              There’s a handy guide to walk you through the install process
            </Dd>
          </>
        )}
        {installType === OrderType.ProfessionalInstall && (
          <>
            <Dt>We’ll call you</Dt>
            <Dd>To arrange the best time for an engineer to install</Dd>
            <Dt>Get set up on tado°</Dt>
            <Dd>You can now download the app and create your account</Dd>
            <Dt>On install day</Dt>
            <Dd>
              Our engineer will install your tado°, which usually takes just
              over an hour
            </Dd>
          </>
        )}
        <Dt>Connect</Dt>
        <Dd>Connect your tado° below and start saving!</Dd>
      </Dl>
    </CardWithRightIcon>
  );
};
