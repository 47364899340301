import {
  DirectDebitResponse,
  RecommendedDirectDebitResponse,
  UpdateDirectDebitAmountResponse,
  major2DP,
  majorToMinor,
} from '@ovotech/energy-cx';
import {
  Card,
  Heading2,
  Heading3,
  P,
  PrimaryCTAButton,
  SecondaryCTALink,
  TextLink,
} from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView, useAnalytics } from '@ovotech/ui-tools';
import React, { FormEvent, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom-v5';
import { useOneClickPaymentReductionLegacyMutation } from './useOneClickPaymentReductionMutation';
import { PAYMENTS } from '@/src/constants/analytics';
import {
  ONE_CLICK_PAYMENT_REDUCTION_BUTTON,
  ONE_CLICK_PAYMENT_REDUCTION_VIEW,
} from '@/src/constants/analytics-subjects';
import { ROUTE_ONE_CLICK_PAYMENT_REDUCTION_CONFIRMATION } from '@/src/constants/routes';
import {
  DD_REDUCTION_TERMS_AND_CONDITION_URL,
  DEBT_ASSISTANCE_URL,
} from '@/src/constants/links';

type OneClickPaymentReductionProps = {
  accountId: string;
  recommendedDirectDebit: RecommendedDirectDebitResponse;
  currentDirectDebit: DirectDebitResponse;
  setErrorState: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function OneClickPaymentReduction({
  accountId,
  setErrorState,
  recommendedDirectDebit,
  currentDirectDebit,
}: OneClickPaymentReductionProps) {
  const history = useHistory();
  const { dispatch } = useAnalytics();

  const oneClickPaymentReductionSubmitMutation =
    useOneClickPaymentReductionLegacyMutation(accountId, response => {
      history.push({
        pathname: `${ROUTE_ONE_CLICK_PAYMENT_REDUCTION_CONFIRMATION}`,
        search: encodeResponseURIQuery(response),
      });
    });
  useEffect(() => {
    if (oneClickPaymentReductionSubmitMutation.status === 'error') {
      setErrorState(true);
    }
  }, [oneClickPaymentReductionSubmitMutation.status, setErrorState]);

  useEffect(() => {
    dispatch({
      type: 'view',
      name: PAYMENTS.GET_SUPPORT_CTA_VIEWED,
    });
    // `dispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const amountToReduce = useMemo(
    () =>
      recommendedDirectDebit.friendlyAmount?.amount
        ? recommendedDirectDebit.friendlyAmount?.amount
        : currentDirectDebit.amount?.amount,
    [recommendedDirectDebit, currentDirectDebit],
  );

  const discountPercentage = 10;

  const paymentReductionDiscountAmount = useMemo(
    () =>
      calculatePaymentReductionDiscount(
        Number(amountToReduce),
        discountPercentage,
      ),
    [amountToReduce, discountPercentage],
  );

  const handlePaymentReductionSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (oneClickPaymentReductionSubmitMutation.status === 'in-progress') {
      return;
    }

    oneClickPaymentReductionSubmitMutation.mutate({
      amount: Number(paymentReductionDiscountAmount),
    });
  };

  return (
    <AnalyticsView name={ONE_CLICK_PAYMENT_REDUCTION_VIEW}>
      <Card data-testid="one-click-payment-reduction">
        <Heading2>Need help with your bills?</Heading2>
        <Heading3>Reduce your payments</Heading3>
        <P>
          We can reduce your next 3 payments by {discountPercentage}%. You’ll
          pay {major2DP(majorToMinor(paymentReductionDiscountAmount))} for the
          next 3 months, and then we’ll work out your payments again.{' '}
          <TextLink
            href={DD_REDUCTION_TERMS_AND_CONDITION_URL}
            opensInNewWindow
          >
            Please read the full terms and conditions first.
          </TextLink>
        </P>
        <AnalyticsClick name={ONE_CLICK_PAYMENT_REDUCTION_BUTTON}>
          <PrimaryCTAButton
            onClick={handlePaymentReductionSubmit}
            data-testid="one-click-payment-reduction-change"
          >
            Reduce payments now
          </PrimaryCTAButton>
        </AnalyticsClick>
        <Heading3>Create a personalised payment plan</Heading3>
        <P>
          We can offer you a payment plan based on your personal circumstances.
          This could be making a one-off payment, or spreading the cost over
          time to make it more manageable.
        </P>
        <SecondaryCTALink
          href={DEBT_ASSISTANCE_URL}
          opensInNewWindow
          onClick={() => {
            dispatch({
              type: 'click',
              name: PAYMENTS.GET_SUPPORT_CTA_CLICKED,
            });
          }}
        >
          Get support
        </SecondaryCTALink>
      </Card>
    </AnalyticsView>
  );
}

function calculatePaymentReductionDiscount(
  amount: number,
  discountPercentage: number,
) {
  return Math.ceil((amount * (100 - discountPercentage)) / 100);
}
function encodeResponseURIQuery(response: UpdateDirectDebitAmountResponse) {
  const NEXT_PAYMENT_INDEX = 0;
  const NEW_PAYMENT_INDEX = 1;
  const urlParams = new URLSearchParams();

  urlParams.set('currencyUnit', response.newAmount.currencyUnit);

  if (response.upcomingPayments && response.upcomingPayments.length === 2) {
    urlParams.set(
      'nextAmount',
      response.upcomingPayments[NEXT_PAYMENT_INDEX].amount.amount,
    );
    urlParams.set(
      'nextAmountDate',
      response.upcomingPayments[NEXT_PAYMENT_INDEX].date.toString(),
    );
    urlParams.set(
      'futureAmount',
      response.upcomingPayments[NEW_PAYMENT_INDEX].amount.amount,
    );
    urlParams.set(
      'futureAmountDate',
      response.upcomingPayments[NEW_PAYMENT_INDEX].date.toString(),
    );
  } else {
    urlParams.set('futureAmount', response.newAmount.amount);
  }

  return `?${urlParams.toString()}`;
}
