import { Card } from '@ovotech/nebula';
import styled from 'styled-components';

export const QuoteCard = styled(Card)`
  padding: 0;
`;

export const ColumnTitle = styled.h4`
  ${({ theme: { colors, fontWeights } }) => `
    font-size: 14px;
    color: ${colors.body};
    font-weight: ${fontWeights.normal};
  `}
`;

export const TotalPrice = styled.span`
  ${({ theme: { colors, fonts, fontWeights } }) => `
    font-family: ${fonts.display};
    font-size: 42px;
    font-weight: ${fontWeights.semiBold};
    color: ${colors.heading};
    align-items: baseline;
    white-space: break-spaces;
    display: flex;
    flex-wrap: wrap;
  `}
`;
