import { useEffect } from 'react';

declare const window: (typeof globalThis)['window'] & {
  QSI: object;
  qualtrics?: { start: () => void };
};

/**
 * This hook sets values from the provided object on the window so that Qualtrics can consume them.
 *
 * @returns void
 */
export const useQualtricsDataEmbed = (
  data: Record<string, string | null | undefined>,
) => {
  useEffect(() => {
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        const windowKey = `qualtricsEmbeddedData_${key}`;
        // @ts-expect-error
        window[windowKey] = value;
      }
    });
  }, [data]);
};
