import styled from 'styled-components';
import { mqFirefox, mqMobileNav } from '@/src/utils/mediaQuery';

export const StyledNewBadge = styled.div(
  ({ theme: { responsiveFontSizes } }) => `
  left: -55px;
  position: absolute;

  & > span {
    font-size: ${responsiveFontSizes.small[0]};
  }

  ${mqMobileNav(`
    top: -5px;
  `)}

  ${mqFirefox(`
    top: -5px;
  `)}
`,
);
