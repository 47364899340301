import React from 'react';
import { TileWithClippedImage } from '../../components/TileWithClippedImage/TileWithClippedImage';
import testIds from '../../testIds';

import { GREENER_ENERGY_CARBON_NEUTRAL_CARD } from '@/src/constants/analytics-subjects';
import { RENEWABLE_GENERATION_LINK } from '@/src/constants/routes';
import SolarFarm from '@/src/resources/small-solar-farm-2.png';

export const RenewableGenerationCard = () => {
  return (
    <TileWithClippedImage
      data-testid={testIds.features.renewableGeneration}
      title="Supporting new renewable generation"
      description="When you add Greener Energy to your account we’ll make a £20 contribution to support the creation of new renewable electricity generation in the UK."
      linkText="Find out more"
      linkIcon="chevron-right"
      linkUrl={RENEWABLE_GENERATION_LINK}
      imgUrl={SolarFarm}
      tracking={GREENER_ENERGY_CARBON_NEUTRAL_CARD}
    />
  );
};
