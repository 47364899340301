import { Middleware } from 'redux';
import AnalyticsService from '@/src/services/analyticsService';

function createAnalyticsMiddleware(): Middleware {
  return () => next => (action: any) => {
    // Mixpanel
    if (action.meta && action.meta.analytics) {
      const { event, identify, props, register } = action.meta.analytics;
      try {
        if (event) {
          AnalyticsService.dispatch(event, props);
        }

        if (identify) {
          AnalyticsService.identify(identify);
        }

        if (register) {
          AnalyticsService.register(register);
        }
      } catch (error) {
        console.error(error);
      }
    }

    return next(action);
  };
}

export { createAnalyticsMiddleware };
