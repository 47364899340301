import { EnergyContract } from '@/src/types/Account';
import { ContractsResponse } from '@/src/types/Response';

export function hasRenewingContracts(contracts: ContractsResponse) {
  return (
    contracts.electricity.some(
      ({ status }) =>
        status.inRenewal && status.active && !status.hasFutureContracts,
    ) ||
    contracts.gas.some(
      ({ status }) =>
        status.inRenewal && status.active && !status.hasFutureContracts,
    )
  );
}

export function hasAnyVariableContract(contracts: ContractsResponse) {
  if (!contracts) {
    return false;
  }
  const electricityContracts = contracts.electricity || [];
  const gasContracts = contracts.gas || [];

  return [...electricityContracts, ...gasContracts].some(
    contract => contract?.status?.active && contract?.type === 'variable',
  );
}

export function contractsInRenewal(contracts: ContractsResponse) {
  const getContractInRenewal = (
    contracts: Array<EnergyContract>,
  ): EnergyContract | undefined =>
    contracts.find(
      ({ status: { inRenewal, hasFutureContracts } }) =>
        inRenewal && !hasFutureContracts,
    );

  return {
    electricity: getContractInRenewal(contracts.electricity),
    gas: getContractInRenewal(contracts.gas),
  };
}

export const earliestContractInRenewal = (contracts: ContractsResponse) => {
  const { electricity, gas } = contractsInRenewal(
    contracts || { hasOnlineDiscount: false, gas: [], electricity: [] },
  );

  return !gas
    ? electricity!
    : !electricity
    ? gas
    : !gas.expiryDate
    ? electricity
    : !electricity.expiryDate || gas.expiryDate < electricity.expiryDate
    ? gas
    : electricity;
};

// E7 tariff with same day + night rate
export function isP1P2ConversionRenewal(contract: ContractsResponse) {
  const { electricity } = contractsInRenewal(contract);

  const isMultiRateWithSameValue = (
    unitRates: Array<{ unitRate: number }>,
  ): boolean =>
    unitRates.length > 1
      ? unitRates.every(({ unitRate }) => unitRate === unitRates[0].unitRate)
      : false;

  return (
    electricity?.profileClass === '02' &&
    isMultiRateWithSameValue(electricity.unitRates)
  );
}

export function hasFutureContracts(contracts: ContractsResponse) {
  return (
    contracts.electricity.some(({ status }) => status.hasFutureContracts) ||
    contracts.gas.some(({ status }) => status.hasFutureContracts)
  );
}
