import { Card, Heading2, Margin } from '@ovotech/nebula';
import React from 'react';

import {
  ThermostatInfoContainer,
  HeadingNoMargin,
  StyledSubtitle,
  ThermostatInfoLeftSection,
  ThermostatInfoRightSection,
} from './ThermostatInfoCard.styled';

export const ThermostatInfoCard = ({
  temperature,
  name,
}: {
  temperature: number;
  name: string;
}) => {
  return (
    <Margin bottom={1}>
      <ThermostatInfoContainer as={Card}>
        <ThermostatInfoLeftSection>
          <HeadingNoMargin as={Heading2}>My thermostat</HeadingNoMargin>
          <StyledSubtitle>A monthly overview of your heating</StyledSubtitle>
        </ThermostatInfoLeftSection>
        <ThermostatInfoRightSection>
          <HeadingNoMargin as={Heading2}>{temperature}°</HeadingNoMargin>
          <StyledSubtitle>{name}</StyledSubtitle>
        </ThermostatInfoRightSection>
      </ThermostatInfoContainer>
    </Margin>
  );
};
