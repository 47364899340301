import { useQuery } from 'react-query';
import { graphql } from './__generated__';
import { apiService } from '@/src/services/api-service';
import { ProfileNextV1Query } from './__generated__/graphql';
import { KAPI_URL } from '@/src/constants/endpoints';
import { buildGraphqlQueryBody } from '../utils/buildGraphqlQueryBody';
import { useCustomerContext } from '@ovotech/ui-tools';

const PROFILE_QUERY = graphql(/* kapi-codegen-graphql */ `
  query ProfileNextV1($customerId: ID!) {
    customer_nextV1(id: $customerId) {
      id
      givenName
      familyName
      title
      contactEmail
      contactPhoneNumbers
      contactAddress {
        addressee
        postalAddress {
          line1
          line2
          town
          region
          postalCode
          country
        }
      }
    }
  }
`);

export const useProfileQueryKapi = () => {
  const { customerId } = useCustomerContext();

  return useQuery(
    `profile-kapi-${customerId}`,
    () =>
      apiService.graphql<ProfileNextV1Query>({
        url: KAPI_URL!,
        body: buildGraphqlQueryBody(PROFILE_QUERY, {
          customerId: customerId ?? '',
        }),
        withCredentials: false,
      }),
    {
      enabled: !!customerId,
    },
  );
};
