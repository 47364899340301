import { isAfter } from 'date-fns';
import { EnergyContract } from '@/src/types/Account';

export const getLastExpiringContract = (
  contracts: Array<EnergyContract>,
): EnergyContract | null => {
  if (!contracts.length) return null;

  return contracts.reduce<EnergyContract | null>((acc, curr) => {
    const latestFoundExpiryDate = acc?.expiryDate;
    const currentExpiryDateToCheck = curr?.expiryDate;

    // No contract found with an expiry date yet, so return current contract
    if (!latestFoundExpiryDate && currentExpiryDateToCheck) return curr;

    // Current expiry date is after latest found expiry date
    if (
      latestFoundExpiryDate &&
      currentExpiryDateToCheck &&
      isAfter(currentExpiryDateToCheck, latestFoundExpiryDate)
    ) {
      return curr;
    }

    // Latest found expiry date is still longest lasting contract
    return acc;
  }, null);
};
