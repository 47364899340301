import { Heading3, P } from '@ovotech/nebula';
import React from 'react';
import { useSelector } from 'react-redux';
import Offer from './Offer/Offer';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import { State } from '@/src/types/State';
import image from '@/src/resources/offers/smartMeterBooking.jpg';

export const SmartMeterBooking = () => {
  const { selectedAccountId: accountId } = useSelector(
    (state: State) => state.user,
  );
  const route = `${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=upgradesSection&utm_medium=MyOVO&utm_campaign=smart-meter-booking`;

  return (
    <Offer
      image={image}
      offerId="smartMeterBooking"
      linkUrl={route}
      linkText="Get yours now"
    >
      <Heading3 as="h2">
        Smart meters can help save energy, money and carbon
      </Heading3>
      <P>Appointments are safely going ahead in line with government advice</P>
    </Offer>
  );
};
