import { P, Small, Strong } from '@ovotech/nebula';
import format from 'date-fns/format';
import React from 'react';

import { DATE_FORMAT } from '../../../shared';
import { CardCol, CardRow, Divider } from '../../Card.styled';

const PlanDates = ({
  plan1,
  plan2,
}: {
  plan1: {
    endDate: null | string;
    lengthInMonths: null | number;
  };
  plan2?: {
    endDate: null | string;
    lengthInMonths: null | number;
  };
}) => {
  const plan1Length = plan1.lengthInMonths
    ? `${plan1.lengthInMonths} months`
    : 'N/A';

  const plan2Length = plan2?.lengthInMonths
    ? `${plan2.lengthInMonths} months`
    : 'N/A';

  const plan1EndDate = plan1.endDate
    ? format(plan1.endDate, DATE_FORMAT)
    : 'N/A';

  const plan2EndDate = plan2?.endDate
    ? format(plan2.endDate, DATE_FORMAT)
    : 'N/A';

  return (
    <>
      <CardRow>
        <CardCol>
          <P>
            <Small>Plan length:</Small>
          </P>
        </CardCol>
        <CardCol>
          <P>
            <Strong>
              <Small>{plan1Length}</Small>
            </Strong>
          </P>
        </CardCol>
        {plan2 && (
          <CardCol>
            <P>
              <Strong>
                <Small>{plan2Length}</Small>
              </Strong>
            </P>
          </CardCol>
        )}
      </CardRow>
      <Divider secondary />
      <CardRow>
        <CardCol>
          <P>
            <Small>Plan end date:</Small>
          </P>
        </CardCol>
        <CardCol>
          <P>
            <Strong>
              <Small>{plan1EndDate}</Small>
            </Strong>
          </P>
        </CardCol>
        {plan2 && (
          <CardCol>
            <P>
              <Strong>
                <Small>{plan2EndDate}</Small>
              </Strong>
            </P>
          </CardCol>
        )}
      </CardRow>
    </>
  );
};

export default PlanDates;
