import React from 'react';
import styled from 'styled-components';
import { colours } from '../CarbonIntensityShared.assets';
import { StyledText, StyledBox } from './CarbonIntensityFooter.styled';
import { mqSmallDown } from '@/src/utils/mediaQuery';
import { CarbonIntensityLevel } from '@/src/pages/EnergyInsights/types';

type Props = {
  intensity: CarbonIntensityLevel;
  text?: string;
};

const StyledSquare = styled.div<{ colour: string }>`
  min-height: 22px;
  min-width: 22px;
  border-radius: 3px;
  background: ${props => props.colour};

  ${mqSmallDown(`
    min-height: 16px;
    min-width: 16px;
    border-radius: 2px;

  `)}
`;

export const IntensityLevel = ({ intensity, text }: Props) => {
  return (
    <StyledBox>
      <StyledSquare colour={colours[intensity].base} />
      <StyledText>{text}</StyledText>
    </StyledBox>
  );
};
