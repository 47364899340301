import React from 'react';
import { CARBON_ZERO, ENERGY_INSIGHTS } from '@/src/constants/analytics';

import { HomeProfileWidgetResponse } from '@/src/types/Response';
import {
  HomeProfileWidgetEvents,
  HomeProfileWidgetWithResponsiveContent,
} from '../../../components/HomeProfileWidget';

type Props = {
  homeProfileWidget: HomeProfileWidgetResponse;
};

const events: HomeProfileWidgetEvents = {
  viewed: CARBON_ZERO.HOME_PROFILE_WIDGET.VIEWED,
  ctaClicked: ENERGY_INSIGHTS.BUTTON_CLICK_UPDATE_HOME_PROFILE_BREAKDOWN,
};

export const HomeProfilePromptBreakdownContainer = ({
  homeProfileWidget,
}: Props) => {
  return (
    <HomeProfileWidgetWithResponsiveContent
      {...homeProfileWidget}
      events={events}
    />
  );
};
