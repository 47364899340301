import axios from 'axios';
import { handleLogout } from './getCustomerAccessToken';
import { LOGOUT_URL } from '@/src/constants/endpoints';

export const logout = async () => {
  // Don't use api-service - endpoint will reject most headers
  await axios.get(LOGOUT_URL, {
    withCredentials: true,
  });
  return handleLogout();
};
