import isFuture from 'date-fns/is_future';
import {
  formDateToDate,
  isAfter1901,
  isOver18,
  isValidDate,
} from '../../utils/date';
import {
  CustomerDetailsForm,
  ErrorMessages,
  FormErrors,
  ValidatedForm,
} from '@/src/pages/MoveIn/types/form';
import { validateAddress } from '@/src/pages/MoveIn/utils/address';

const regexMobile = /^(\(?44\)? ?|\(?\+\(?44\)? ?|\(?0)(\d\)? ?){09,10}$/;
const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type FormType = CustomerDetailsForm;
export function validatePersonalDetails(
  form: Partial<FormType>,
): ValidatedForm<FormType> {
  const errors: FormErrors<FormType> = {};

  if (!form.title) {
    errors.title = ErrorMessages.REQUIRED;
  }

  if (!form.firstName) {
    errors.firstName = ErrorMessages.REQUIRED;
  }

  if (!form.lastName) {
    errors.lastName = ErrorMessages.REQUIRED;
  }

  if (!form.phone) {
    errors.phone = ErrorMessages.REQUIRED;
  } else if (form.phone.toString().match(regexMobile) === null) {
    errors.phone = ErrorMessages.INVALID_MOBILE_NUMBER;
  }

  if (!form.emailAddress) {
    errors.emailAddress = ErrorMessages.EMAIL_MISSING;
  } else if (form.emailAddress.match(regexEmail) === null) {
    errors.emailAddress = ErrorMessages.INVALID_EMAIL;
  }

  if (!form.serviceOptIn) {
    errors.serviceOptIn = ErrorMessages.REQUIRED;
  }

  if (
    !form.dateOfBirth ||
    !form['dateOfBirth-month'] ||
    !form['dateOfBirth-year']
  ) {
    errors.dateOfBirth = ErrorMessages.REQUIRED;
  } else {
    const dateOfBirthDate = formDateToDate(
      form.dateOfBirth,
      form['dateOfBirth-month'],
      form['dateOfBirth-year'],
    );
    if (!isValidDate(dateOfBirthDate)) {
      errors.dateOfBirth = ErrorMessages.ENTER_VALID_DATE;
    } else if (isFuture(dateOfBirthDate)) {
      errors.dateOfBirth = ErrorMessages.NOT_IN_FUTURE;
    } else if (!isAfter1901(dateOfBirthDate)) {
      errors.dateOfBirth = ErrorMessages.AFTER_1901;
    } else if (!isOver18(dateOfBirthDate)) {
      errors.dateOfBirth = ErrorMessages.AT_LEAST_18;
    }
  }

  if (form.useSupplyAddress === false) {
    const addressErrors = validateAddress(form);
    Object.assign(errors, addressErrors);
  }

  return Object.keys(errors).length > 0
    ? {
        validation: 'error',
        errors,
      }
    : {
        validation: 'success',
        data: form as FormType,
      };
}
