import styled from 'styled-components';
import { mqMobileNav } from '@/src/utils/mediaQuery';

export const StyledGreenerEnergyListItem = styled.li`
  position: relative;

  ${mqMobileNav(`
      display: flex;
      justify-content: center;
      align-items: center;
  `)}
`;
