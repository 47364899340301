import { Heading3, P, Strong } from '@ovotech/nebula';
import React from 'react';
import Offer from './Offer/Offer';
import { useAnytimeBoltonPricingQuery } from '@/src/api';
import { ALLOW_ANYTIME_SIGNUP } from '@/src/constants/feature-flags';
import { useFeature } from '@/src/utils/optimizely';
import image from '@/src/resources/offers/ovoChargeAnytimeOffer.png';

export const Anytime = () => {
  const [allowAnytimeSignup] = useFeature(ALLOW_ANYTIME_SIGNUP);
  const anytimeBoltonPricing = useAnytimeBoltonPricingQuery();

  const route = '/anytime/eligibility';

  const getContent = () => {
    if (anytimeBoltonPricing.isError)
      return 'Smart charge and save with our super-low Charge Anytime rate';
    if (anytimeBoltonPricing.isLoading)
      return (
        <>
          Smart charge your EV from home, at <Strong>any time</Strong> for
          XXp/kWh.
        </>
      );

    if (anytimeBoltonPricing.data)
      return (
        <>
          Smart charge your EV from home, at <Strong>any time</Strong> for{' '}
          {anytimeBoltonPricing.data.formattedPence}.
        </>
      );
  };

  return (
    <Offer
      image={image}
      offerId="anytime"
      linkUrl={route}
      disableCTA={!allowAnytimeSignup}
      fullwidthCTA={true}
      linkText="Check compatibility"
    >
      <Heading3 as="h2">Charge Anytime</Heading3>
      <P>{getContent()}</P>
    </Offer>
  );
};
