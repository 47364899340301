export enum TadoPayMonthlyIneligibleReason {
  BOLTON_INELIGIBLE = 'BOLTON_INELIGIBLE',
  CONTRACT_ENDING = 'CONTRACT_ENDING',
  NO_ACTIVE_GAS_CONTRACT_WITH_EXPIRY_DATE = 'NO_ACTIVE_GAS_CONTRACT_WITH_EXPIRY_DATE',
  NO_DIRECT_DEBIT = 'NO_DIRECT_DEBIT',
  EOC_BALANCE_DEBT = 'EOC_BALANCE_DEBT',
}

export enum PaymentMethod {
  CARD_PAYMENT = 'Card',
  MONTHLY_CHARGE = 'Monthly charge to energy account',
}
