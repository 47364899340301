import React from 'react';
import { HomeProfileWidgetResponse } from '@/src/types/Response';
import { HomeProfileWidgetView } from './HomeProfileWidgetView';
import { HomeProfileWidgetContext } from './HomeProfileWidget.types';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';
import { getHomeProfileWidgetContent } from './HomeProfileWidget.content';
import { IMAGE_BREAKPOINT } from './HomeProfileWidget.styled';

export type HomeProfileWidgetPickerProps = HomeProfileWidgetResponse &
  HomeProfileWidgetContext;

export const HomeProfileWidgetWithResponsiveContent = (
  props: HomeProfileWidgetPickerProps,
) => {
  const { width } = useWindowDimensions();

  const isWideScreen = width > IMAGE_BREAKPOINT;

  const responsiveContent = getHomeProfileWidgetContent(
    props.variant,
    isWideScreen ? 'desktop' : 'mobile',
  );

  return <HomeProfileWidgetView {...props} {...responsiveContent} />;
};
