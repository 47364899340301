import { TextLink } from '@ovotech/nebula';
import React, { ComponentProps, forwardRef } from 'react';
import { RouterLink } from '../RouterLink/RouterLink';

type Props = ComponentProps<typeof RouterLink>;

const Link = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  return <TextLink {...props} as={RouterLink} ref={ref} />;
});

export default Link;
