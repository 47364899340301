import styled from 'styled-components';

export const StyledProjectedBalanceChart = styled.div`
  ${({ theme: { fontSizes, space, fontWeights } }) => `
    margin-bottom: ${space[4]};
    font-size: ${fontSizes[1]};

    .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
    .recharts-wrapper .recharts-cartesian-grid-vertical line:last-child,
    .recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(2),
    .recharts-wrapper .recharts-cartesian-grid-vertical line:nth-last-child(2) {
      stroke-opacity: 0;
    }

    .recharts-text.recharts-label {
      font-weight: ${fontWeights.semiBold};
    }

    .recharts-tooltip-cursor {
      opacity: 0;
    }
  `};
`;

export const StyledProjectedBalanceTooltip = styled.div`
  ${({ theme: { colors, space } }) => `
    padding: ${space[2]};
    background-color: ${colors.canvas};
    border: 1px solid ${colors.shadeMidLight};

    dd + dt {
      margin-top: ${space[4]};
    }
  `};
`;
