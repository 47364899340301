import { useQuery } from 'react-query';
import { graphql } from './__generated__';
import { fetchContentfulData } from '@/src/services/contentful/fetchContentfulData';

export const tescoQuestionAnswerQuery =
  graphql(/* contentful-codegen-graphql */ `
    query tescoQuestionAnswerQuery($containerId: String!, $preview: Boolean) {
      buildFaQs(id: $containerId, preview: $preview) {
        structuredTextCollection {
          items {
            ... on PackStructuredText {
              sys {
                id
              }
              header
              text {
                json
                links {
                  ...EntryHyperLinkFragment
                }
              }
            }
          }
        }
      }
    }
  `);

type QueryCacheKey = `tescoQuestionAnswer-${string}`;

export const useTescoQuestionAnswerQuery = () =>
  useQuery(
    `tescoQuestionAnswer-${process.env.NX_CONTENTFUL_TESCO_CONTAINER_ID}` as QueryCacheKey,
    () =>
      fetchContentfulData(tescoQuestionAnswerQuery, {
        containerId: process.env.NX_CONTENTFUL_TESCO_CONTAINER_ID!,
      }),
    {
      retry: 3,
      cacheTime: 500000,
    },
  );
