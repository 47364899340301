import styled from 'styled-components';

export const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const StyledRouteContent = styled.main`
  flex-grow: 1;
`;

export const DisplayFlex = styled.div`
  display: flex;
`;
export const NestedDisplayFlex = styled.div`
  display: flex;

  > *,
  [data-testid='kapi-switch'] > * {
    flex: 1;
    display: flex;
  }
`;

export const FlexChild = styled.div`
  flex: 1;
`;
