import {
  major2DP,
  majorToMinor,
  UpdateDirectDebitAmountResponse,
} from '@ovotech/energy-cx';
import {
  Card,
  Heading1,
  Li,
  Margin,
  P,
  PrimaryCTALink,
  Ul,
} from '@ovotech/nebula';
import { AnalyticsView } from '@ovotech/ui-tools';
import React from 'react';
import { ONE_CLICK_PAYMENT_CONFIRMATION_VIEW } from '@/src/constants/analytics-subjects';
import { ROUTE_HOME } from '@/src/constants/routes';
import { formatDateAndMonth } from '@/src/utils/date';

type Props = {
  confirmation: UpdateDirectDebitAmountResponse;
};

export const DirectDebitConfirm = ({ confirmation }: Props) => {
  const { newAmount, upcomingPayments } = confirmation;

  const formattedNewAmount = major2DP(
    majorToMinor(newAmount.amount, newAmount.currencyUnit),
  );

  return (
    <AnalyticsView name={ONE_CLICK_PAYMENT_CONFIRMATION_VIEW}>
      <section data-testid="one-click-payment-reduction-confirm">
        <Card>
          <Heading1>We&rsquo;ve reduced your next 3 payments</Heading1>
          <P>It&rsquo;s confirmed:</P>
          <Ul>
            <Li>
              We&rsquo;ll collect {formattedNewAmount} for your next 3 Direct
              Debit payments, which will be 10% lower than your recommended
              payment amount.
            </Li>
            <Li>
              We&rsquo;ll then get in touch with you to confirm your new
              recommended payment amount.
            </Li>
            {upcomingPayments ? (
              <Li>
                Your next payment is already being processed and is due on{' '}
                {formatDateAndMonth(upcomingPayments[0].date)}. So we'll still
                collect{' '}
                {major2DP(
                  majorToMinor(
                    upcomingPayments[0].amount.amount,
                    upcomingPayments[0].amount.currencyUnit,
                  ),
                )}{' '}
                on that date. Your new monthly amount of {formattedNewAmount}{' '}
                will be collected on{' '}
                {formatDateAndMonth(upcomingPayments[1].date)}, and for the next
                2 payments after that.
              </Li>
            ) : null}
          </Ul>
          <Margin top={[4, 8]}>
            <PrimaryCTALink
              href={ROUTE_HOME}
              iconLeft="chevron-left"
              iconRight="none"
            >
              Back to home screen
            </PrimaryCTALink>
          </Margin>
        </Card>
      </section>
    </AnalyticsView>
  );
};
