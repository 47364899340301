import {
  Heading3,
  Stack,
  Card,
  Heading4,
  P,
  SecondaryCTALink,
} from '@ovotech/nebula';
import React from 'react';
import {
  StyledNewsPanel,
  StyledImage,
  StyledNewsText,
  StyledNewsSecondText,
  StyledSecondImage,
  StyledImageMobile,
} from './EVNews.styled';
import ElectricCars from '@/src/resources/electric-cars.jpg';
import OVOCarChargingMobile from '@/src/resources/ev-car-charging-mobile.png';
import OVOCarCharging from '@/src/resources/ev-car-charging.jpg';
import EVChargingSpeedMobile from '@/src/resources/ev-charging-speed-mobile.png';
import EVChargingSpeed from '@/src/resources/ev-charging-speed.png';

export const EVNews = () => {
  return (
    <>
      <Heading3>All the latest about electric cars</Heading3>
      <Stack spaceBetween={4}>
        <StyledNewsPanel as={Card}>
          <StyledImage alt="" src={OVOCarCharging} aria-hidden="true" />
          <StyledImageMobile
            alt=""
            src={OVOCarChargingMobile}
            aria-hidden="true"
          />

          <StyledNewsText>
            <Heading4>
              Smart EV charging: How it works and why you need a smart EV
              charger
            </Heading4>
            <P>
              Have you heard of ‘smart’ EV chargers, but you’re not sure what’s
              so smart about them? Read our guide to how EV smart charging works
              and how to get it.
            </P>
            <P>
              <SecondaryCTALink
                opensInNewWindow
                href={
                  'https://www.ovoenergy.com/guides/energy-guides/ev-smart-charging-explained-benefits-and-types.html'
                }
              >
                Find out more
              </SecondaryCTALink>
            </P>
          </StyledNewsText>
        </StyledNewsPanel>
        <StyledNewsPanel as={Card}>
          <StyledNewsSecondText>
            <Heading4>What are the benefits of electric cars?</Heading4>
            <P>
              They’re just cars that run off batteries, right? Wrong! Electric
              cars promise to save you money, bless all of us with cleaner air,
              and (crucially) kickstart a transport and energy revolution.
            </P>
            <P>
              <SecondaryCTALink
                opensInNewWindow
                href={
                  'https://www.ovoenergy.com/guides/electric-cars/why-electric-cars.html'
                }
              >
                Find out more
              </SecondaryCTALink>
            </P>
          </StyledNewsSecondText>
          <StyledSecondImage alt="" src={ElectricCars} aria-hidden="true" />
          <StyledImageMobile alt="" src={ElectricCars} aria-hidden="true" />
        </StyledNewsPanel>
        <StyledNewsPanel as={Card}>
          <StyledImage alt="" src={EVChargingSpeed} aria-hidden="true" />
          <StyledImageMobile
            alt=""
            src={EVChargingSpeedMobile}
            aria-hidden="true"
          />
          <StyledNewsText>
            <Heading4>
              EV charging speeds explained: slow, fast and rapid
            </Heading4>
            <P>
              Slow, fast or rapid? Confused by the different options for
              charging your EV? Find out about the 3 main charging choices, and
              the timeframes for each.
            </P>
            <P>
              <SecondaryCTALink
                opensInNewWindow
                href={
                  'https://www.ovoenergy.com/guides/energy-guides/ev-charging-speeds-explained-slow-fast-and-rapid.html'
                }
              >
                Find out more
              </SecondaryCTALink>
            </P>
          </StyledNewsText>
        </StyledNewsPanel>
      </Stack>
    </>
  );
};
