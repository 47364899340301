import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSmartThermostatsTadoStatusStart } from '@/src/redux/ducks/smartThermostats/smart-thermostats-info';

import { State } from '@/src/types/State';

export function getTadoStatus() {
  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { tadoStatus, errorResponse, isFetching } = useSelector(
    ({ smartThermostats }: State) => smartThermostats,
  );

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (tadoStatus === null && !isFetching && !errorResponse) {
      dispatch(getSmartThermostatsTadoStatusStart());
    }
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tadoStatus, errorResponse };
}
