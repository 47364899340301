import { Heading3, Icon, Modal, P, TextLink } from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView } from '@ovotech/ui-tools';
import React, { useState } from 'react';
import styled from 'styled-components';
import carbonNeutral from '@/src/resources/carbon-neutral.png';
import { Card, SkinnyModal } from '../GreenerEnergySignup.styled';
import {
  GREENER_ENERGY_SIGNUP_CARBON_NEUTRAL_CARD,
  GREENER_ENERGY_SIGNUP_CARBON_NEUTRAL_CARD_MODAL,
} from '@/src/constants/analytics-subjects';
import { mqLargeDown } from '@/src/utils/mediaQuery';

const ClippedContent = styled.div`
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;

  clip-path: polygon(
    calc(50% - 20px) 0,
    calc(50% + 60px) 30px,
    calc(50% - 55px) 100%,
    0 100%,
    0 0
  );

  ${({ theme: { colors, space } }) => `
    padding: ${space[4]};
    background-color: ${colors.canvas};
  `}
`;

const CarbonNeutralBackgroundImg = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url(${carbonNeutral});
  background-position: center center;
  background-size: cover;
`;

const Wrapper = styled.div`
  max-width: 50%;
`;

const LinkTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }

  ${({ theme: { colors, responsiveFontSizes } }) => `
    color: ${colors.primary};
    text-decoration: underline;

    ${mqLargeDown(`
      font-size: ${responsiveFontSizes.body[0]};
    `)}
  `};
`;

const IconWrapper = styled.div`
  min-width: 20px;
`;

const CERTIFIED_CARBON_REDUCTION_URL =
  'https://verra.org/programs/verified-carbon-standard/';

const OVO_GREEN_GAS_URL = 'https://www.ovoenergy.com/green-gas';

export const CarbonNeutralCard = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <AnalyticsView name={GREENER_ENERGY_SIGNUP_CARBON_NEUTRAL_CARD}>
      <Card>
        <ClippedContent>
          <Wrapper>
            <Heading3>100% carbon&#8209;neutral energy</Heading3>
            <AnalyticsClick name={GREENER_ENERGY_SIGNUP_CARBON_NEUTRAL_CARD}>
              <LinkTextWrapper onClick={() => setShowModal(true)}>
                What difference does this make?
                <IconWrapper>
                  <Icon name="chevron-right" />
                </IconWrapper>
              </LinkTextWrapper>
            </AnalyticsClick>
          </Wrapper>
        </ClippedContent>
        <CarbonNeutralBackgroundImg />
        <SkinnyModal
          as={Modal}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title="100% carbon-neutral energy!"
        >
          <P>
            Greener Energy gives you 100% renewable electricity and 100%
            carbon-neutral gas. As well as supercharging support for the UK’s
            renewable energy system.
          </P>
          <P>
            It brings you 100% renewable electricity from traceable sources.
            While the electricity that powers your home comes from a mix of
            sources, we buy the same amount of renewable electricity to balance
            this all out. This is backed by PPAs (Power Purchase Agreements).
            PPAs are agreements to buy renewable electricity from the source
            (like a solar farm). This electricity can be traced back to the
            generator.
          </P>
          <P>
            You also get 100% carbon-neutral gas. That includes 10%{' '}
            <AnalyticsClick
              name={GREENER_ENERGY_SIGNUP_CARBON_NEUTRAL_CARD_MODAL}
            >
              <TextLink href={OVO_GREEN_GAS_URL}>green gas</TextLink>
            </AnalyticsClick>
            , the greener cousin of natural gas. It's cleaner and fossil
            fuel-free. Ours is backed by Renewable Gas Guarantees of Origin. We
            offset the rest of your gas, by supporting{' '}
            <AnalyticsClick
              name={GREENER_ENERGY_SIGNUP_CARBON_NEUTRAL_CARD_MODAL}
            >
              <TextLink href={CERTIFIED_CARBON_REDUCTION_URL}>
                certified carbon
              </TextLink>
            </AnalyticsClick>{' '}
            reduction projects – from protecting the rainforest to planting
            trees.
          </P>
        </SkinnyModal>
      </Card>
    </AnalyticsView>
  );
};
