import { AvailablePlan } from '@monovo/energy-renewals';
import { useMutation } from '@ovotech/ui-tools';
import { createRenewPlanEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';

export function useContractsRenewalMutation(accountId: string) {
  return useMutation((plan: AvailablePlan) => {
    const url = createRenewPlanEndpoint(accountId!);
    const body = plan.tariffs.map(({ contractId, tariffId }) => ({
      contractId,
      tariffId,
    }));

    return apiService
      .post({
        url,
        body,
        headers: {
          'orion-endpoint-version': '4',
        },
      })
      .then(() => plan);
  });
}
