import { useReactQuery } from '@ovotech/ui-tools';
import { createAddressLookupEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { AddressError, AddressResponse } from '@/src/types/Address';

function useAddressLookupQuery(postcode: string) {
  return useReactQuery<AddressResponse, AddressError>(
    `orion-address-lookup-${postcode}`,
    () =>
      apiService
        .get<AddressResponse>({
          url: createAddressLookupEndpoint(postcode),
        })
        .catch(err => {
          // 422 is no response which will return an empty array
          if (err?.response?.status === 422) {
            return [];
          }
          return Promise.reject(err);
        }),
    {
      enabled: !!postcode,
    },
  );
}

export { useAddressLookupQuery };
