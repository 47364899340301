import { VisuallyHidden } from '@ovotech/nebula';
import React, { PropsWithChildren } from 'react';
import { BasicLayout } from '../Layout/BasicLayout';
import { SideNavigationLayout } from '../Layout/SideNavigationLayout';
import { StyledRouteContent } from '../layout';
import { ErrorSection, Section } from '@/src/components';
import { GENERIC_PAGE_ERROR } from '@/src/constants/error-section-ids';

export type PageContentLayout = 'basic' | 'side-navigation';

export type PageContentProps = PropsWithChildren<{
  layout: PageContentLayout;
  hasError: boolean;
}>;

export function PageContent({ layout, children, hasError }: PageContentProps) {
  const Layout = layout === 'basic' ? BasicLayout : SideNavigationLayout;

  return (
    <>
      <StyledRouteContent>
        <Layout>
          {hasError ? (
            <Section>
              <ErrorSection
                alertLevel="critical"
                id={GENERIC_PAGE_ERROR}
                data-testid={GENERIC_PAGE_ERROR}
              />
            </Section>
          ) : (
            children
          )}
        </Layout>
      </StyledRouteContent>
      <VisuallyHidden>
        <div
          id="live-region"
          role="status"
          aria-live="polite"
          aria-atomic="true"
        />
      </VisuallyHidden>
    </>
  );
}
