import { DirectDebitSummary } from '@ovotech/energy-cx';
import {
  Card,
  Margin,
  Heading1,
  P,
  SecondaryCTALink,
  PrimaryCTALink,
  Heading3,
  Modal,
  VisuallyHidden,
} from '@ovotech/nebula';
import {
  AnalyticsRender,
  AnalyticsClick,
  useAccountContext,
} from '@ovotech/ui-tools';
import React, { useEffect, useMemo, useState } from 'react';
import AccountPaymentSummary from './components/AccountPaymentSummary';
import { BankDetails } from './components/BankDetails/BankDetails';
import ChangeDdDate from './components/ChangeDdDate';
import Refunds from './components/Refund/Refund';
import { useDirectDebitQuery } from '@/src/api';
import {
  PageWithNavigation,
  AnalyticsWrapper,
  Link,
  RouterLink,
  Section,
  Switcher,
  ErrorBoundary,
  ErrorSection,
  SVTCustomersPayingDDOneTimeBanner,
  ActionCard,
} from '@/src/components';
import { SummaryCard } from '@/src/components/SummaryCard';
import {
  PAYMENTS,
  VIEWED_PAYMENTS_SCREEN_ERROR,
} from '@/src/constants/analytics';
import { PAYMENTS_SCREEN } from '@/src/constants/analytics-subjects';
import {
  PAYMENTS_PAGE_ERROR,
  PAYMENT_ERROR,
} from '@/src/constants/error-section-ids';
import {
  ROUTE_BILLING_HISTORY,
  ROUTE_DD_CALCULATOR,
  ROUTE_MAKE_CARD_PAYMENT,
} from '@/src/constants/routes';
import { PAYMENT_INFO_SECTION_ID } from '@/src/constants/sectionIds';
import { useHistory } from 'react-router-dom-v5';
import { StyledModal } from '@/src/components/HighBalanceModal/HighBalanceModal';
import { useSupplyPointsQuery } from '@/src/api/kapi/use-supply-points-query';
import { HasDDModalContent } from '@/src/components/HighBalanceModal/HighBalanceModalContent/HasDDModalContent';
import { NoDDModalContent } from '@/src/components/HighBalanceModal/HighBalanceModalContent/NoDDModalContent';
import { isRelatedMPANCustomer } from '@/src/utils/supplyPoints';
import { AccountSupplyPointFragmentFragment } from '@/src/api/kapi/__generated__/graphql';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';

enum HighBalanceModal {
  None = '',
  HasDD = 'modal=balance_review_has_dd',
  NoDD = 'modal=balance_review_no_dd',
}

const PaymentError: React.FC = (): React.ReactElement => {
  return (
    <AnalyticsWrapper name={VIEWED_PAYMENTS_SCREEN_ERROR}>
      <Card>
        <ErrorSection headingComponent={Heading3} id={PAYMENT_ERROR} />
      </Card>
    </AnalyticsWrapper>
  );
};

const DirectDebitCalculatorCard = () => {
  return (
    <SummaryCard
      heading="Direct Debit calculator"
      icon="ovocalculator"
      footer={
        <SecondaryCTALink
          data-event-name={PAYMENTS.GO_TO_DD_CALCULATOR}
          to={ROUTE_DD_CALCULATOR}
          as={RouterLink}
        >
          Go to Direct Debit calculator
        </SecondaryCTALink>
      }
    >
      <P>
        Find out how changing your monthly payment amount will affect your
        balance over time.
      </P>
    </SummaryCard>
  );
};

const BalanceTopUp = ({ hasDD }: { hasDD: boolean }) => {
  const CTALink = hasDD ? PrimaryCTALink : SecondaryCTALink;

  return (
    <AnalyticsRender
      name={PAYMENTS.MAKE_PAYMENT}
      properties={{
        hadDirectDebit: `${hasDD}`,
      }}
    >
      <SummaryCard
        heading="Make a payment"
        icon="ovocomputer"
        footer={
          <AnalyticsClick
            name={PAYMENTS.MAKE_PAYMENT}
            properties={{ buttonName: 'make_payment_cta' }}
          >
            <CTALink to={ROUTE_MAKE_CARD_PAYMENT} as={RouterLink}>
              Make a card payment
            </CTALink>
          </AnalyticsClick>
        }
      >
        <P>
          Make a one-time debit card payment to keep your account in credit, or
          pay your bill.
        </P>
      </SummaryCard>
    </AnalyticsRender>
  );
};

export const PaymentsComponent = () => {
  const history = useHistory();
  const { accountId } = useAccountContext();

  const directDebit = useDirectDebitQuery(accountId);
  const supplyPointsQueryResult = useSupplyPointsQuery();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [showDDCalcLink, setShowDDCalcLink] = useState(true);
  const [modalContent, setModalContent] = useState<HighBalanceModal>(
    HighBalanceModal.None,
  );

  const openModal = () => {
    setIsOpen(true);
    document.documentElement.setAttribute('style', 'overflow-y: hidden;');
  };

  const closeModal = () => {
    setIsOpen(false);
    history.push({
      search: ``,
    });
    document.documentElement.setAttribute('style', 'overflow-y: scroll;');
  };

  const generateModalContent = () => {
    if (history.location.search.includes(HighBalanceModal.HasDD)) {
      openModal();
      setModalContent(HighBalanceModal.HasDD);
    } else if (history.location.search.includes(HighBalanceModal.NoDD)) {
      openModal();
      setModalContent(HighBalanceModal.NoDD);
    } else {
      closeModal();
      setModalContent(HighBalanceModal.None);
    }
  };

  useEffect(() => {
    generateModalContent();
  }, [history.location.search]);

  const supplies = useMemo<readonly AccountSupplyPointFragmentFragment[]>(
    () => supplyPointsQueryResult.data?.account?.accountSupplyPoints ?? [],
    [supplyPointsQueryResult.data?.account?.accountSupplyPoints],
  );
  const isRelatedMPAN = isRelatedMPANCustomer(supplies);

  useEffect(() => {
    if (showDDCalcLink && !!supplies?.length && isRelatedMPAN) {
      setShowDDCalcLink(false);
    }
  }, [supplies, showDDCalcLink, isRelatedMPAN]);

  if (!accountId) return null;

  if (supplyPointsQueryResult.isLoading || directDebit.isLoading) {
    return (
      <PageWithNavigation title="Home">
        <Section>
          <SiteLoading />
        </Section>
      </PageWithNavigation>
    );
  }

  if (supplyPointsQueryResult.isError || !supplyPointsQueryResult.data) {
    return (
      <PageWithNavigation title="Payments">
        <Section />
      </PageWithNavigation>
    );
  }

  const hasDirectDebit = directDebit?.data?.amount !== undefined;

  if (directDebit.isError) {
    return (
      <PageWithNavigation
        title="Payments"
        analytics={{ name: PAYMENTS_SCREEN }}
      >
        <Section>
          <PaymentError />
        </Section>
      </PageWithNavigation>
    );
  }

  return (
    <PageWithNavigation title="Payments" analytics={{ name: PAYMENTS_SCREEN }}>
      <SVTCustomersPayingDDOneTimeBanner containerStyle={{ marginBottom: 0 }} />
      <ErrorBoundary errorId={PAYMENTS_PAGE_ERROR}>
        <ActionCard
          cardsToShowByActionCode={[
            'high_billing_hold_ovo_001_has_dd',
            'high_billing_hold_ovo_001_no_dd',
          ]}
          utmSource="payments"
        />
      </ErrorBoundary>
      <Section id={PAYMENT_INFO_SECTION_ID}>
        <Card>
          <Heading1>Payments</Heading1>
          <AccountPaymentSummary />
        </Card>
        <Margin vertical={6}>
          <Switcher threshold="60rem">
            {hasDirectDebit && showDDCalcLink && !isRelatedMPAN && (
              <DirectDebitCalculatorCard />
            )}
            {!hasDirectDebit && <DirectDebitSummary />}
            {<BalanceTopUp hasDD={hasDirectDebit} />}
          </Switcher>
        </Margin>

        <Margin vertical={6}>
          <Switcher threshold="60rem">
            {hasDirectDebit && <BankDetails />}
            {hasDirectDebit && <DirectDebitSummary />}
            {!hasDirectDebit && <Refunds />}
          </Switcher>
        </Margin>

        <Margin vertical={6}>
          <Switcher threshold="60rem">
            {hasDirectDebit && (
              <>
                <ChangeDdDate />
                <Refunds />
              </>
            )}
          </Switcher>
        </Margin>

        <P>
          <Link
            data-event-name={PAYMENTS.KEEP_TRACK_YOUR_PAYMENTS}
            to={ROUTE_BILLING_HISTORY}
          >
            Keep track of your payments and charges
          </Link>
        </P>
      </Section>
      <div>
        <StyledModal
          as={Modal}
          isOpen={modalIsOpen}
          onClose={closeModal}
          title={<VisuallyHidden>Tariff Modal</VisuallyHidden>}
        >
          {modalContent === HighBalanceModal.HasDD ? (
            <HasDDModalContent />
          ) : (
            <NoDDModalContent />
          )}
        </StyledModal>
      </div>
    </PageWithNavigation>
  );
};
