export enum FilterType {
  Include = 'Include',
  Exclude = 'Exclude',
}

export const DEFAULT_FILTERS = {
  filterType: FilterType.Exclude,
  tariffs: [],
};

export const isPlanInTariffs = (tariffs: string[], planName: string): boolean =>
  tariffs.some((tariff: string) =>
    planName.toLowerCase().startsWith(tariff.toLowerCase()),
  );
