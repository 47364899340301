import { Card } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 12px;
  top: 12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const HeadingWrapper = styled.div`
  padding: 5px;
`;
