import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledFoundationDonation = styled.span`
  font-weight: bold;
  font-size: 1.2em;
`;

export const Flex = styled.div`
  flex: 1;
`;
