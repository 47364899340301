/*
 * TODO This is a bit of a pony solution. So maybe needs refactoring
 * to something more robust. Basic premise though is to provide a
 * simple interface for handling auth when working locally.
 *
 */
import 'url-search-params-polyfill';
import axios from 'axios';

const urlParams = new URLSearchParams(window.location.search);
const email = urlParams.has('email') && urlParams.get('email');
const password = urlParams.has('password') && urlParams.get('password');

const devAuth = async () => {
  if (email && password) {
    try {
      const authResponse = await axios({
        url: '/api/login',
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        data: JSON.stringify({
          email,
          password,
        }),
      });
      if (authResponse.status > 200) {
        alert('Auth failed');
        return;
      }

      window.location.href = window.location.origin;
    } catch (err) {
      // tslint:disable-next-line no-console
      console.log('Failed to authenticate', err);
    }
  }
};

export default devAuth;
