import { Margin, Heading2, Row, Col } from '@ovotech/nebula';
import React from 'react';

import { StyledProductHeading } from '../../Products.styled';
import ProductPrice from '../ProductPrice';
import { ProductsBundleBolton } from '../ProductsBolton';
import ProductsCancel from '../ProductsCancel';
import { CLICKED_CANCEL_ADDON } from '@/src/constants/analytics';
import { ProductIconAndLabel } from '../ProductIconAndLabel/ProductIconAndLabel';
import { BundleInstance } from '@/src/types/Products';
import { ProductUpdateStatus } from '@/src/types/State';

export type ProductsBundleProps = {
  bundle: BundleInstance;
  canCancel?: boolean;
  onCancel: () => void;
  cancellation: ProductUpdateStatus;
};

const ProductsBundle = ({
  canCancel,
  onCancel,
  cancellation,
  bundle,
}: ProductsBundleProps) => {
  return (
    <div data-testid="products-bundle">
      <Margin bottom={4}>
        <StyledProductHeading as={Heading2} data-testid="products-bundle-title">
          <ProductIconAndLabel id={bundle.productId} />
        </StyledProductHeading>
        <ProductPrice cost={bundle.cost} data-testid="products-bundle-price" />
      </Margin>
      <Row isNested>
        {bundle.boltons.map(bolton => (
          <Col medium={6} key={bolton.productId}>
            <ProductsBundleBolton product={bolton} />
          </Col>
        ))}
      </Row>
      {canCancel && (
        <Margin top={4}>
          <ProductsCancel
            onCancel={onCancel}
            cancellation={cancellation}
            title="Cancel upgrade"
            data-event-name={CLICKED_CANCEL_ADDON}
          />
        </Margin>
      )}
    </div>
  );
};

ProductsBundle.defaultProps = { canCancel: false };

export default ProductsBundle;
