import { ContractsResponse } from '@/src/types/Response';

export const areContractsVariable = (contracts: ContractsResponse): boolean => {
  return [
    ...(contracts.electricity.length > 0
      ? [contracts.electricity[contracts.electricity.length - 1]]
      : []),
    ...(contracts.gas.length > 0
      ? [contracts.gas[contracts.gas.length - 1]]
      : []),
  ].every(c => c.expiryDate == null);
};
