import {
  Card,
  Heading3,
  P,
  SkeletonCTA,
  SkeletonHeading,
  SkeletonText,
} from '@ovotech/nebula';
import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { VIEWED_OFFER_EVENT } from '@/src/constants/analytics';
import analyticsService from '@/src/services/analyticsService';
import { mqMediumUp } from '@/src/utils/mediaQuery';

interface PtzOfferProps {
  isLoading?: boolean;
  isError?: boolean;
  children: ReactNode;
  imagePath: string;
  imageAlt?: string;
  title: string;
  description: string;
  trackingId?: string;
}

const StyledHeading3 = styled(Heading3)`
  margin: 0;
`;

const StyledContainer = styled(Card)`
  padding: 0;

  ${({ theme }) => `
    &.error {
        background-color: ${theme.colors.errorMuted};
        border: 4px solid ${theme.colors.error};
    }
`}
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover;

  ${mqMediumUp(`
    height: 200px;
`)}
`;

export const PtzOffer = ({
  isLoading,
  isError,
  children,
  imagePath,
  imageAlt,
  title,
  description,
  trackingId,
}: PtzOfferProps) => {
  useEffect(() => {
    if (trackingId) {
      analyticsService.dispatch(VIEWED_OFFER_EVENT, { offerType: trackingId });
    }
  }, [trackingId]);

  if (isLoading) {
    return (
      <StyledContainer style={{ width: '100%' }}>
        <StyledContent>
          <SkeletonHeading />
          <SkeletonText lines={3} />
          <SkeletonCTA />
        </StyledContent>
      </StyledContainer>
    );
  }
  return (
    <StyledContainer className={isError ? 'error' : ''}>
      <StyledImg src={imagePath} alt={imageAlt ?? 'Path To Zero offer image'} />
      <StyledContent>
        <StyledHeading3>{title}</StyledHeading3>
        <P>{description}</P>
        {children}
      </StyledContent>
    </StyledContainer>
  );
};
