export const styledOutline = (color: string): string => `
  outline: 2px solid ${color};
  outline-offset: 2px;
`;

export const svgWidth = (
  widthWithUnit: string,
  svgViewBoxWidth: number,
  svgViewBoxHeight: number,
) => {
  const ratio = Number((svgViewBoxHeight / svgViewBoxWidth).toFixed(2)) + 0.01;

  return `
    min-width: ${widthWithUnit};
    width: ${widthWithUnit};
    max-height: calc(${ratio} * ${widthWithUnit});
  `;
};

export const svgHeight = (
  heightWithUnit: string,
  svgViewBoxWidth: number,
  svgViewBoxHeight: number,
) => {
  const ratio = Number((svgViewBoxWidth / svgViewBoxHeight).toFixed(2)) + 0.01;

  return `
    min-height: ${heightWithUnit};
    height: ${heightWithUnit};
    max-width: calc(${ratio} * ${heightWithUnit});
  `;
};
