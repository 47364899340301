export const isValidNumber = (n: any) => {
  const parsedNumber = parseFloat(n);
  return !isNaN(parsedNumber) && isFinite(parsedNumber) && parsedNumber !== 0;
};

export function roundTo1DP(n: string | number) {
  return Math.round(Number(n) * 10) / 10;
}

export function roundTo2DP(n: string | number) {
  return Math.round(Number(n) * 100) / 100;
}
