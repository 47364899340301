import {
  SecondaryCTAButton,
  Heading2,
  P,
  IconName,
  CTALink,
} from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView } from '@ovotech/ui-tools';
import React from 'react';
import {
  BoldText,
  ClippedImg,
  StyledImageBanner,
  StyledImageBannerBody,
  StyledImageBannerLockupWrap,
} from './ClippedImageBanner.styled';

import testIds from './testIds';
import { CTAWrapper } from '@/src/components/ActionCentre/Banners/banners.styled';

const offerTestIds = testIds;

interface ClippedImageBannerProps {
  analyticsName: string;
  imageUrl?: string;
  heading: string;
  body: React.ReactNode;
  linkTo: string;
  onClick: () => void;
  onDismiss: () => void;
  subtitle?: string;
  ctaTitle?: string;
  cancelTitle?: string;
  clipPath?: string;
  iconRight?: IconName | 'off';
  CroppedImage?: React.FC;
  target?: JSX.IntrinsicElements['a']['target'];
}

export const ClippedImageBanner = ({
  analyticsName,
  imageUrl,
  heading,
  body,
  subtitle,
  ctaTitle,
  cancelTitle = 'Close',
  linkTo,
  onClick,
  onDismiss,
  clipPath,
  iconRight,
  CroppedImage,
  target,
}: ClippedImageBannerProps) => {
  const renderImage = () => {
    if (CroppedImage) return <CroppedImage />;

    if (imageUrl)
      return <ClippedImg imgUrl={imageUrl} clipPath={clipPath}></ClippedImg>;

    return null;
  };

  return (
    <AnalyticsView name={analyticsName}>
      <StyledImageBanner data-testid={offerTestIds.offer}>
        <StyledImageBannerLockupWrap>
          {renderImage()}
        </StyledImageBannerLockupWrap>
        <StyledImageBannerBody>
          <Heading2 data-testid={testIds.title}>{heading}</Heading2>
          {typeof body === 'string' ? (
            <P data-testid={testIds.body}>{body}</P>
          ) : (
            <div data-testid={testIds.body}>{body}</div>
          )}
          {subtitle ? <BoldText>{subtitle}</BoldText> : null}
          <CTAWrapper>
            {ctaTitle ? (
              <AnalyticsClick name={analyticsName}>
                <CTALink
                  variant="missionPrimary"
                  href={linkTo}
                  target={target}
                  data-testid={offerTestIds.cta}
                  onClick={onClick}
                  defaultIconRight={iconRight}
                  iconRight="off"
                >
                  {ctaTitle}
                </CTALink>
              </AnalyticsClick>
            ) : null}
            <SecondaryCTAButton
              data-testid={offerTestIds.close}
              onClick={onDismiss}
            >
              {cancelTitle}
            </SecondaryCTAButton>
          </CTAWrapper>
        </StyledImageBannerBody>
      </StyledImageBanner>
    </AnalyticsView>
  );
};
