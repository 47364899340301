import styled from 'styled-components';

import { mqMediumDown } from '@/src/utils/mediaQuery';

export const FormProgressTrackerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormProgressTracker = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;

  ${mqMediumDown(`
    width: 125px;
  `)}
`;

export const FormProgressTrackerFilledRectangle = styled.div`
  width: 20px;
  height: 4px;
  background: black;

  ${mqMediumDown(`
    width: 10px;
  `)}
`;

export const FormProgressTrackerEmptyRectangle = styled.div`
  border: 1px solid #555c6b;

  width: 20px;
  height: 4px;

  ${mqMediumDown(`
    width: 10px;
  `)}
`;
