import {
  Card,
  P,
  TextLink,
  Icon,
  Heading1,
  SummaryList,
  SummaryItem,
  Heading3,
} from '@ovotech/nebula';
import { AnalyticsClick, useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { useSelector } from 'react-redux';
import { FlexSummaryList, LinksWrapper } from './PaygHoldingPage.styled';
import { PageWithoutNavigationAndSectionPadding } from '../Pages';
import {
  PAYG_SCREEN,
  PAYG_SELECT_ACCOUNT,
  PAYG_APP_STORE_LINK,
  PAYG_PLAY_STORE_LINK,
  PAYG_LINK,
} from '@/src/constants/analytics-subjects';
import { ROUTE_SELECT_ACCOUNT } from '@/src/constants/routes';
import { State } from '@/src/types/State';
import {
  HELP_OVO_ENERGY_METER_READINGS,
  HELP_OVO_ENERGY_TRADITIONAL_METER,
  OVO_ENERGY_PAYMENT_PORTAL,
} from '@/src/constants/links';
import theme from '@/src/core/theme';
import { ReactComponent as PlayStore } from '@/src/resources/google-play-badge.svg';
import { ReactComponent as IosStore } from '@/src/resources/ios-app-store-badge.svg';

const AppStoreLink = ({
  storeLink,
  analytics,
  children,
}: {
  storeLink: string;
  analytics: string;
  children: any;
}) => {
  return (
    <AnalyticsClick name={analytics}>
      <a href={storeLink} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </AnalyticsClick>
  );
};

const iosUrl = 'https://apps.apple.com/gb/app/ovo-top-up/id1604878627';
const googleUrl =
  'https://play.google.com/store/apps/details?id=com.paypoint.ovo';

export const PaygHoldingPage = () => {
  const { accountId } = useAccountContext();

  const hasMultipleAccounts = useSelector(
    ({ app }: State) => app.accounts.length > 1,
  );

  return (
    <PageWithoutNavigationAndSectionPadding
      title="This page doesn't support Pay As You Go"
      analytics={{ name: PAYG_SCREEN }}
    >
      <Card data-testid="payg-holding-page">
        {hasMultipleAccounts && (
          <P>
            <TextLink
              data-testid="select-account"
              href={ROUTE_SELECT_ACCOUNT}
              data-event-name={PAYG_SELECT_ACCOUNT}
            >
              <Icon name="chevron-left" /> Select another account
            </TextLink>
          </P>
        )}
        <Heading1 data-testid="payg-heading">
          This page doesn't support Pay As You Go
        </Heading1>
        {accountId ? (
          <FlexSummaryList as={SummaryList}>
            <SummaryItem label="Account number:">{accountId}</SummaryItem>
          </FlexSummaryList>
        ) : null}
        <P>
          What type of prepayment meter do you have? if you're not sure, see our{' '}
          <TextLink href={HELP_OVO_ENERGY_METER_READINGS}>
            guide on meter types
          </TextLink>
          .
        </P>
        <div style={{ display: 'flex', marginTop: theme.space[5] }}>
          <div
            style={{
              flex: 1,
              borderRight: `0.1rem solid ${theme.colors.borderMuted}`,
              paddingRight: theme.space[6],
            }}
          >
            <Heading3 data-testid="payg-smart-meter-heading">
              Smart meter
            </Heading3>
            <P>
              Download the OVO Energy Top-Up app to manage your smart meter from
              anywhere.
            </P>
            <LinksWrapper>
              <AppStoreLink analytics={PAYG_APP_STORE_LINK} storeLink={iosUrl}>
                <IosStore />
              </AppStoreLink>
              <AppStoreLink
                storeLink={googleUrl}
                analytics={PAYG_PLAY_STORE_LINK}
              >
                <PlayStore />
              </AppStoreLink>
            </LinksWrapper>
            <P>
              Or visit our online{' '}
              <AnalyticsClick
                name={PAYG_LINK}
                properties={{
                  linkId: 'help',
                }}
              >
                <TextLink href={OVO_ENERGY_PAYMENT_PORTAL} opensInNewWindow>
                  Payment Portal
                </TextLink>
              </AnalyticsClick>
              .
            </P>
          </div>

          <div
            style={{
              flex: 1,
              borderLeft: `0.1rem solid ${theme.colors.borderMuted}`,
              paddingLeft: theme.space[6],
            }}
          >
            <Heading3 data-testid="payg-traditional-meter-heading">
              Traditional meter
            </Heading3>
            <P>
              Top up using your electricity key or gas card at any Paypoint or
              Payzone store.
            </P>
            <P>
              For more support, see our{' '}
              <TextLink href={HELP_OVO_ENERGY_TRADITIONAL_METER}>
                guide on topping up
              </TextLink>
              .
            </P>
          </div>
        </div>
      </Card>
    </PageWithoutNavigationAndSectionPadding>
  );
};
