import styled from 'styled-components';
import { ButtonLooksLikeLink } from '@/src/components';
import { mqSmallUp } from '@/src/utils/mediaQuery';

export const Button = styled(ButtonLooksLikeLink)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[1]};

    ${mqSmallUp(`
      font-size: ${fontSizes[1]};
    `)}
  `}
`;

export const NoMarginHeading = styled.h2`
  margin: 0;
`;

// TODO. This is a bit of a workaround to make the PlanInfoCard be the modal content.
export const StyledModal = styled.div(
  () => `
  padding: 0;
  > button {
    z-index: 10;
    cursor:pointer;
    top:0;
    right:0;
  }
`,
);
