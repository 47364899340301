import { BalanceSummaryLoading } from '@/src/components/BalanceSummary/BalanceSummaryLoading';
import { BalanceType, useBalance } from '@/src/pages/Payments/hooks/useBalance';
import { AccountSummary } from '@ovotech/energy-cx';
import React from 'react';
import { CollectableBalanceSummary } from '@/src/components/BalanceSummary/CollectableBalanceSummary';

export const BalanceSummary = () => {
  const { balanceType, balanceVariation, isLoading, collectableBalance } =
    useBalance();

  if (isLoading) {
    return <BalanceSummaryLoading />;
  }

  if (balanceType === BalanceType.CollectableBalance && collectableBalance) {
    return (
      <CollectableBalanceSummary
        amount={collectableBalance}
        balanceVariation={balanceVariation}
      />
    );
  }

  return <AccountSummary />;
};
