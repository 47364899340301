import { Badge, VisuallyHidden } from '@ovotech/nebula';
import React from 'react';
import { CarbonIntensityLevel } from '../../../types/carbon-intensity.types';
import theme from '@/src/core/theme';
import { ReactComponent as RedIconBig } from '@/src/resources/carbon_intensity_high_icon.svg';
import { ReactComponent as GreenIconBig } from '@/src/resources/carbon_intensity_low_icon.svg';
import { ReactComponent as YellowIconBig } from '@/src/resources/carbon_intensity_moderate_icon.svg';

export const colours: Record<
  CarbonIntensityLevel,
  { base: string; muted: string; banner: string }
> = {
  [CarbonIntensityLevel.Low]: {
    banner: theme.colors.brand.primary.base,
    base: theme.colors.brand.primary.base,
    muted: theme.colors.primaryMuted,
  },
  [CarbonIntensityLevel.Moderate]: {
    banner: theme.colors.brand.electricity.base,
    base: theme.colors.brand.electricity.base,
    muted: theme.colors.warningMuted,
  },
  [CarbonIntensityLevel.High]: {
    banner: theme.colors.brand.error.dark,
    base: theme.colors.brand.error.base,
    muted: theme.colors.errorMuted,
  },
};

const styling = {
  small: {
    marginLeft: 3,
    marginBottom: 4,
  },
  big: {
    marginLeft: 10,
    marginBottom: 4,
  },
};

export const iconMapBig: Record<CarbonIntensityLevel, JSX.Element> = {
  low: <GreenIconBig style={styling.big} />,
  moderate: <YellowIconBig style={styling.big} />,
  high: <RedIconBig style={styling.big} />,
};

const CarbonIntensityBadge = ({
  intensity,
}: {
  intensity: CarbonIntensityLevel;
}) => (
  <Badge
    style={{
      borderColor: colours[intensity].base,
      color: colours[intensity].base,
      backgroundColor: theme.colors.shadeLightest,
    }}
  >
    {intensity.toUpperCase()}
    <VisuallyHidden> carbon intensity</VisuallyHidden>
  </Badge>
);

export const currentIntensityBadgeMap: Record<
  CarbonIntensityLevel,
  JSX.Element
> = {
  [CarbonIntensityLevel.Low]: (
    <CarbonIntensityBadge intensity={CarbonIntensityLevel.Low} />
  ),
  [CarbonIntensityLevel.Moderate]: (
    <CarbonIntensityBadge intensity={CarbonIntensityLevel.Moderate} />
  ),
  [CarbonIntensityLevel.High]: (
    <CarbonIntensityBadge intensity={CarbonIntensityLevel.High} />
  ),
};
