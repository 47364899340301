import { Checkbox } from '@ovotech/nebula';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { ButtonContainer } from './Form.styled';

type Props = {
  label: string;
  name: string;
  value: string;
  dataTestId?: string;
};

const CheckboxButton = ({ label, name, value, dataTestId }: Props) => {
  const id = uuidv4();
  const { register } = useFormContext();

  return (
    <ButtonContainer data-testid={dataTestId}>
      <Checkbox
        data-testid={`${name}${value}`}
        key={value}
        id={id}
        type="checkbox"
        value={value}
        label={label}
        {...register(name)}
      />
    </ButtonContainer>
  );
};

export default CheckboxButton;
