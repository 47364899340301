import { Card, Margin, P } from '@ovotech/nebula';
import { AnalyticsPage } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';
import { Features } from '../Features/Features';
import { SectionWrapper } from '../GreenerEnergySignup/GreenerEnergySignup.styled';
import GreenerEnergyHomeHeader from '../components/GreenerEnergyHomeHeader/GreenerEnergyHomeHeader';
import testIds from '../testIds';
import { FootnoteProvider, FootnoteYield, Section } from '@/src/components';

import { GREENER_ENERGY } from '@/src/constants/analytics-subjects';
import { ROUTE_GREENER_ENERGY_SIGNUP } from '@/src/constants/routes';

import { getProductsStart } from '@/src/redux/ducks/products/products';
import { getShowSmartMeterBookingBannerStart } from '@/src/redux/ducks/smartMeterBooking/smartMeterBooking';

import {
  isGreenerEnergyCustomer,
  isGreenerEnergyBundleCustomer,
} from '@/src/utils/products';
import { State } from '@/src/types/State';

const Loading = () => {
  return (
    <Section data-testid={testIds.zone.loadingSection}>
      <Card>
        <P>Loading...</P>
      </Card>
    </Section>
  );
};

export const GreenerEnergyContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const activatedProducts = useSelector(
    (state: State) => state.products.activated,
  );

  const activeBoltons = activatedProducts.data?.boltons || [];
  const isGreenerEnergyUser =
    isGreenerEnergyCustomer(activeBoltons) ||
    isGreenerEnergyBundleCustomer(activeBoltons);

  useEffect(() => {
    if (activatedProducts.data && !isGreenerEnergyUser) {
      history.push(ROUTE_GREENER_ENERGY_SIGNUP);
    }
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedProducts, isGreenerEnergyUser]);

  useEffect(() => {
    if (
      !activatedProducts.data &&
      !activatedProducts.errorResponse &&
      !activatedProducts.isFetching
    ) {
      dispatch(getProductsStart());
    }

    dispatch(getShowSmartMeterBookingBannerStart());
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    (activeBoltons.length === 0 && activatedProducts.isFetching) ||
    !activatedProducts.data ||
    !isGreenerEnergyUser
  ) {
    return (
      <>
        <SectionWrapper data-testid={testIds.zone.sectionWrapper}>
          <Loading />
        </SectionWrapper>
      </>
    );
  }

  return (
    <AnalyticsPage name={GREENER_ENERGY}>
      <GreenerEnergyHomeHeader />
      <FootnoteProvider>
        <Features />
        <Margin top={6}>
          <FootnoteYield heading=" " />
        </Margin>
      </FootnoteProvider>
    </AnalyticsPage>
  );
};
