import styled from 'styled-components';

export const StyledToolTip = styled.div`
  margin: 0px;
  padding: 10px;
  background-color: #f3f3f3;
  white-space: nowrap;
  display: block;
  ${({ theme: { space } }) => `
    padding: ${space[4]};
    p {
      margin: ${space[1]};
    }
  `}
  .value {
    text-align: right;
  }
`;

export const StyledToolTipDate = styled.p`
  text-align: center;
`;

export const StyledToolTipInfo = styled.span`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledToolTipInfoValue = styled.p`
  font-weight: bold;
`;

export const StyledToolTipInfoTitle = styled.p`
  margin-top: 0.4rem !important;
`;
