import {
  convertToPositiveNumber,
  formatCurrencyValueWithSeparator,
} from '@/src/utils/money';

export const formatCollectableBalanceAmount = (collectableBalance: string) => {
  const formattedAmount = formatCurrencyValueWithSeparator(
    convertToPositiveNumber(collectableBalance),
  );

  return Number(collectableBalance) > 0
    ? `+${formattedAmount}`
    : formattedAmount;
};

export const balanceTextVariations = (collectableBalance: string) => {
  if (Number(collectableBalance) >= 0) {
    return {
      headingText: 'Balance',
      balanceInfoText: 'Nothing to pay right now',
      paymentCTAText: 'Make extra payment',
    };
  }

  return {
    headingText: 'Amount due',
    balanceInfoText: 'Total to pay',
    paymentCTAText: 'Make payment',
  };
};
