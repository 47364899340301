import { Card, Heading2 } from '@ovotech/nebula';
import styled, { css } from 'styled-components';

export const IMAGE_BREAKPOINT = 800;

export const Styled = {
  Card: styled(Card)`
    ${({ theme }) => css`
      position: relative;
      display: flex;
      flex-direction: column;
      padding: ${theme.space[9]} 0px 0px 0px;

      @media (min-width: ${IMAGE_BREAKPOINT}px) {
        flex-direction: row;
      }
    `}
  `,

  LeftContent: styled.div`
    ${({ theme }) => css`
      flex: 1;
      padding: 0px 0px 0px ${theme.space[9]};

      @media (min-width: ${IMAGE_BREAKPOINT}px) {
        padding: 0px 0px ${theme.space[9]} ${theme.space[9]};
      }
    `}
  `,

  Heading: styled(Heading2)`
    ${({ theme }) => css`
      padding-right: ${theme.space[7]};
      margin-bottom: ${theme.space[2]};
      color: ${theme.colors.brand.midnight};

      @media (min-width: ${IMAGE_BREAKPOINT}px) {
        padding-right: 0px;
      }
    `}
  `,

  RightContent: styled.div`
    ${({ theme }) => css`
      align-self: flex-end;
      padding-left: ${theme.space[9]};

      @media (min-width: ${IMAGE_BREAKPOINT}px) {
        display: flex;
      }
    `}
  `,

  DismissButton: styled.button`
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  `,
};
