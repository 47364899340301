import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

const circleHeight = 200;
const circleWidth = 81;

const cardHeight = 153;

const circleTopPosition = (circleHeight - cardHeight) / 2;

export const StyledHeaderCircle = styled.div<{ colour: string }>`
  border-radius: 100% / 50%;
  width: ${circleWidth}px;
  height: ${circleHeight}px;
  position: absolute;
  z-index: 0;
  top: -${circleTopPosition}px;
  right: 110px;
  background-color: ${props => props.colour};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${mqMediumDown(`
    display: none;
  `)}
`;

export const StyledHeaderRectangle = styled.div<{ colour: string }>`
  width: 110px;
  height: 250px;
  position: absolute;
  z-index: 0;
  right: 0;
  top: 0;
  background-color: ${props => props.colour};
  /* opacity: 0.15; */

  ${mqMediumDown(`
    background-color: none;
    width: 1000px;
    height: 55px;
    bottom: 0;
    top: auto;
  `)}
`;

export const StyledCarbonIntensityHeaderWidget = styled.div<{ colour: string }>`
  display: flex;
  flex-direction: column;
  flex: 0;
  flex-basis: 120px;
  text-align: center;
  justify-content: space-around;
  margin: -20px 0;
  margin-right: -10px;
  position: relative;
  z-index: 2;
  font-size: 1.6rem;
  ${({ colour }) =>
    mqMediumDown(`
      background-color: ${colour}; 
      flex-direction: row;
      align-items: center;
      flex-basis: 70px;
      margin: -32px;
    `)}
`;

export const StyledIntensityBadge = styled.div`
  margin-bottom: 4px;
`;

export const StyledIntensityIcon = styled.div`
  > svg {
    height: 50px;
    position: relative;
    left: -3px;
    z-index: 2;

    ${mqMediumDown(`
      height: 40px;
    `)}
  }
`;
