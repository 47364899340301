import styled, { css, keyframes } from 'styled-components';

// The "vw" unit is used to keep animations consistent across skeletons of
// differing widths. The timing is adjusted with media queries to compensate.
const gleam = keyframes`
  from {
    background-position: -100vw 0;
  }
  to {
    background-position: 200vw 0;
  }
`;

export const StyledSkeleton = styled.span(
  ({ theme: { mediaQueries } }) => css`
    position: relative;
    overflow: hidden;
    display: block;

    &:after {
      content: '';
      position: absolute;
      opacity: 0.6;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      background-size: 100px;
      background-repeat: no-repeat;
      animation: ${gleam} 2s infinite;

      ${mediaQueries.smallAndUp} {
        animation-duration: 3s;
      }

      ${mediaQueries.mediumAndUp} {
        animation-duration: 4s;
      }

      @media (prefers-reduced-motion) {
        animation: none;
      }
    }
  `,
);

export const StyledSkeletonHeading = styled(StyledSkeleton)<{ size: string }>(
  ({ theme: { colors }, size }) => `
    background-color: ${colors.borderMuted};
    width: ${size};
  `,
);

export const StyledSkeletonCTA = styled(StyledSkeleton)<{ size: string }>(
  ({ theme: { colors, cta }, size }) => `
    background-color: ${colors.borderMuted};
    width: ${size};
    height: ${cta.minHeight};
  `,
);

export const StyledSkeletonCircleWrapper = styled(StyledSkeleton)<{
  size: string;
}>(({ size }) => `width: ${size}; max-width: 100%;`);

export const StyledSkeletonCircle = styled(StyledSkeleton)(
  ({ theme: { colors } }) => `
    background-color: ${colors.borderMuted};
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
  `,
);

export const StyledSkeletonText = styled(StyledSkeleton)(
  ({ theme: { colors, space } }) => `
    background-color: ${colors.borderMuted};
    height: 1em;
    margin-bottom: ${space[2]};

    &:last-child {
      width: 50%;
    }
  `,
);
