import { TabList, Tabs } from '@ovotech/nebula';
import React from 'react';
import { FuelTabContent } from './Components/FuelTabContent/FuelTabContent';
import { StyledFlexCard, StyledTab, StyledTabPanel } from './FuelTabs.styled';
import { BreakdownPageResponse, Fuel } from '@/src/types/Response';
import { SelectableIconLabel } from '@/src/pages/EnergyInsights/components';
import { energyInsightsTestIDs } from '@/src/pages/EnergyInsights/testIDs';

interface Props extends BreakdownPageResponse {
  isSingleFuel: boolean;
  fuel: Fuel;
  handleFuelChange: (f: Fuel) => void;
}

export const FuelTabs = ({
  handleFuelChange,
  isSingleFuel,
  ...rest
}: Props) => {
  const isElectricityTabActive = rest.fuel === Fuel.ELECTRICITY;
  const isGasTabActive = rest.fuel === Fuel.GAS;

  if (isSingleFuel) {
    return (
      <StyledFlexCard>
        <FuelTabContent {...rest} />
      </StyledFlexCard>
    );
  }

  return (
    <Tabs fullWidth="always">
      <TabList>
        <StyledTab
          active={isElectricityTabActive}
          onClick={() => handleFuelChange(Fuel.ELECTRICITY)}
        >
          <SelectableIconLabel
            data-testid={energyInsightsTestIDs.breakdownPage.elecTab}
            title={Fuel.ELECTRICITY}
            alignCentre
          />
        </StyledTab>
        <StyledTab
          active={isGasTabActive}
          onClick={() => handleFuelChange(Fuel.GAS)}
        >
          <SelectableIconLabel
            data-testid={energyInsightsTestIDs.breakdownPage.gasTab}
            title={Fuel.GAS}
            alignCentre
          />
        </StyledTab>
      </TabList>
      <StyledTabPanel data-testid="tab-panel-electricity">
        {isElectricityTabActive && <FuelTabContent {...rest} />}
      </StyledTabPanel>
      <StyledTabPanel data-testid="tab-panel-gas">
        {isGasTabActive && <FuelTabContent {...rest} />}
      </StyledTabPanel>
    </Tabs>
  );
};
