import isFuture from 'date-fns/is_future';
import {
  ErrorMessages,
  FormErrors,
  QuoteRequestForm,
  ValidatedForm,
} from '../../types/form';
import { validateAddress } from '../../utils/address';
import {
  formDateToDate,
  isUpTo365DaysAway,
  isValidDate,
} from '../../utils/date';

type FormType = QuoteRequestForm;
export function validateGetQuote(
  form: Partial<FormType>,
): ValidatedForm<FormType> {
  const errors: FormErrors<FormType> = {};

  if (form.quoteType && form.quoteType === 'UsageBased') {
    if (form.electricityUsage === undefined || form.electricityUsage === 0) {
      errors.electricityUsage = ErrorMessages.REQUIRED;
    } else if (typeof form.electricityUsage !== 'number') {
      errors.electricityUsage = ErrorMessages.NUMERICAL_ONLY;
    }

    // Should only be accepting gas with electricity
    if (form.fuel === 'Dual') {
      if (
        form.gasUsage === undefined ||
        (form.gasUsage as any as string).length === 0
      ) {
        errors.gasUsage = ErrorMessages.REQUIRED;
      } else if (typeof form.gasUsage !== 'number') {
        errors.gasUsage = ErrorMessages.NUMERICAL_ONLY;
      }
    }
  }

  if (
    !form.moveInDate ||
    !form['moveInDate-month'] ||
    !form['moveInDate-year']
  ) {
    errors.moveInDate = ErrorMessages.REQUIRED;
  } else {
    const moveInDate = formDateToDate(
      form.moveInDate,
      form['moveInDate-month'],
      form['moveInDate-year'],
    );
    if (!isValidDate(moveInDate)) {
      errors.moveInDate = ErrorMessages.ENTER_VALID_DATE;
    } else if (isFuture(moveInDate)) {
      errors.moveInDate = ErrorMessages.ENTER_PAST_DATE;
    } else if (!isFuture(moveInDate) && !isUpTo365DaysAway(moveInDate)) {
      errors.moveInDate = ErrorMessages.MAX_365_DAYS_IN_PAST;
    }
  }

  const addressErrors = validateAddress(form);
  Object.assign(errors, addressErrors);

  return Object.keys(errors).length > 0
    ? {
        validation: 'error',
        errors,
      }
    : {
        validation: 'success',
        data: form as FormType,
      };
}
