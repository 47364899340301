import {
  MeterReadingsConfirmation,
  MeterReadings,
  MeterReadingsHistory,
  SubmitMeterReading,
} from '@ovotech/energy-cx';
import React from 'react';
import { Redirect, Route } from 'react-router-dom-v5';
import {
  SwitchWithNotFound,
  PageWithNavigationAndSectionPadding,
} from '@/src/components';
import {
  ROUTE_METER_READINGS,
  ROUTE_METER_READINGS_HISTORY,
  ROUTE_METER_READINGS_NEW,
} from '@/src/constants/routes';

const MeterReadingsViewWithLayout = () => (
  <PageWithNavigationAndSectionPadding title="Meter readings">
    <MeterReadings />
  </PageWithNavigationAndSectionPadding>
);

const MeterReadingsConfirmViewWithLayout = () => (
  <PageWithNavigationAndSectionPadding title="Meter readings - Confirmation">
    <MeterReadingsConfirmation />
  </PageWithNavigationAndSectionPadding>
);

const MeterReadingsHistoryGasViewWithLayout = () => (
  <PageWithNavigationAndSectionPadding title="Meter readings history">
    <MeterReadingsHistory selectedFuel="gas" />
  </PageWithNavigationAndSectionPadding>
);

const MeterReadingsHistoryElecViewWithLayout = () => (
  <PageWithNavigationAndSectionPadding title="Meter readings history">
    <MeterReadingsHistory selectedFuel="electricity" />
  </PageWithNavigationAndSectionPadding>
);

const SubmitMeterRadingGasViewWithLayout = () => (
  <PageWithNavigationAndSectionPadding title="Meter Readings'">
    <SubmitMeterReading selectedFuel="gas" />
  </PageWithNavigationAndSectionPadding>
);

const SubmitMeterRadingElecViewWithLayout = () => (
  <PageWithNavigationAndSectionPadding title="Meter Readings'">
    <SubmitMeterReading selectedFuel="electricity" />
  </PageWithNavigationAndSectionPadding>
);

const MeterReadingsRoutes = () => (
  <SwitchWithNotFound>
    <Route
      exact
      path={ROUTE_METER_READINGS}
      component={MeterReadingsViewWithLayout}
    />
    <Route
      exact
      path={ROUTE_METER_READINGS_NEW}
      render={() => <Redirect to={ROUTE_METER_READINGS} />}
    />
    <Route
      exact
      path={`${ROUTE_METER_READINGS_NEW}/gas`}
      component={SubmitMeterRadingGasViewWithLayout}
    />
    <Route
      exact
      path={`${ROUTE_METER_READINGS_NEW}/electricity`}
      component={SubmitMeterRadingElecViewWithLayout}
    />
    <Route
      exact
      path={`${ROUTE_METER_READINGS_HISTORY}/gas`}
      component={MeterReadingsHistoryGasViewWithLayout}
    />
    <Route
      exact
      path={`${ROUTE_METER_READINGS_HISTORY}/electricity`}
      component={MeterReadingsHistoryElecViewWithLayout}
    />
    <Route
      exact
      path={`${ROUTE_METER_READINGS}/confirm`}
      component={MeterReadingsConfirmViewWithLayout}
    />
  </SwitchWithNotFound>
);

export default MeterReadingsRoutes;
