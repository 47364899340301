import React from 'react';

const lastSentence =
  'With our energy-saving tools and tips, you can green up your home and lower your bills even more.';

export const comparisonAssets = {
  // Screen readers seem to have trouble with spans (or fragments) and will pause before each one.
  // As a workaround we need to copy the plain text into the aria-label and set aria-hidden to "true" on the outer span.
  ariaLabel: `${lastSentence}`,
  text: <>{lastSentence}</>,
};
