import { Margin, P, Small } from '@ovotech/nebula';
import React from 'react';
import { useHistory } from 'react-router-dom-v5';
import { useGetAcquisitionQuote } from '../../api/useGetAcquisitionQuote';
import { QuoteErrorNotification } from '../../components/Error';
import { MoveInPage } from '../../components/MoveInPage';
import { MoveInDetails, MoveInJourneyProps } from '../../types/moveIn';
import { Tariff } from '../../types/quoteRequest';
import { CardsContainer, Terms } from './Quote.styled';
import { QuotePlan } from './components/QuotePlan';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';
import { MOVE_IN_TARIFFS_FILTERING } from '@/src/constants/feature-flags';
import { ROUTE_MOVE_IN_SUBPAGES } from '@/src/constants/routes';
import { PAGE_QUOTE } from '@/src/pages/MoveIn/analytics';
import {
  DEFAULT_FILTERS,
  FilterType,
  isPlanInTariffs,
} from '@/src/utils/filter-tariffs';
import { useFeature } from '@/src/utils/optimizely';

export function Quote({
  continueMoveIn,
  moveInDetails,
  progress,
  updateMoveIn,
}: MoveInJourneyProps) {
  const quoteQuery = useGetAcquisitionQuote(
    moveInDetails as Required<Pick<MoveInDetails, 'getQuote'>>,
  );

  const updatePlanMoveIn = (selectedTariff: Tariff) => {
    updateMoveIn({ selectedTariff, quoteDetails: quoteQuery.data });
    continueMoveIn();
  };

  const history = useHistory();

  const [filterTariffs, { filters }] = useFeature(MOVE_IN_TARIFFS_FILTERING);
  const { filterType, tariffs } = filters || DEFAULT_FILTERS;

  const quoteTariffs = Object.entries(quoteQuery?.data?.tariffs || {});

  const filteredTariffs =
    !filterTariffs || tariffs.length === 0
      ? quoteTariffs
      : filterType === FilterType.Include
      ? quoteTariffs.filter(([, tariff]) => {
          const { name = '' } = tariff || {};
          return isPlanInTariffs(tariffs, name);
        })
      : quoteTariffs.filter(([, tariff]) => {
          const { name = '' } = tariff || {};
          return !isPlanInTariffs(tariffs, name);
        });

  // If manual address, ensure we obtained mpan and (if needed) mprn for later
  if (
    (quoteQuery.isError &&
      [434, 435, 444].includes(quoteQuery.error.response?.status ?? 0)) ||
    (moveInDetails.property?.manualAddress &&
      !moveInDetails.meterDetails?.mpan &&
      !(
        moveInDetails.getQuote?.fuel === 'Dual' &&
        moveInDetails.meterDetails?.mprn
      ))
  ) {
    updateMoveIn({ needMeterDetails: true });
    history.replace(ROUTE_MOVE_IN_SUBPAGES.Meters);
    return <></>;
  }

  return quoteQuery.isError ? (
    <MoveInPage
      analyticsName={PAGE_QUOTE}
      title="Quote"
      flowProgress={progress}
      analyticsProps={{
        status: 'errored',
        manualAddress: moveInDetails.property?.manualAddress ?? false,
      }}
    >
      <QuoteErrorNotification />
    </MoveInPage>
  ) : quoteQuery.isSuccess ? (
    <MoveInPage
      analyticsName={PAGE_QUOTE}
      title="Quote"
      flowProgress={progress}
      analyticsProps={{
        status: 'success',
        manualAddress: moveInDetails.property?.manualAddress ?? false,
        tariffs: Object.entries(quoteQuery.data.tariffs)
          .filter(([, v]) => v !== undefined)
          .map(([, v]) => v!.name),
      }}
    >
      <Margin vertical={4} />

      <CardsContainer>
        {filteredTariffs.map(([name, tariff]) => (
          <QuotePlan
            key={name}
            tariff={tariff!}
            updateMoveIn={updatePlanMoveIn}
          />
        ))}
      </CardsContainer>
      <Margin top={2} bottom={6}>
        <Terms as={P}>
          <Small>
            All prices include VAT (currently 5% for domestic customers).
          </Small>
        </Terms>
      </Margin>
    </MoveInPage>
  ) : (
    <SiteLoading />
  );
}
