import { Heading3, P, Margin, PrimaryCTALink } from '@ovotech/nebula';
import React from 'react';
import strings from './strings';
import testIds from './testIds';
import { AnalyticsWrapper } from '@/src/components';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { ActionCentreBannerProps } from '@/src/components/ActionCentre/Banners/banners';
import {
  CLICKED_GREENLIGHT_FROM_BANNER,
  VIEWED_GREENLIGHT_BANNER,
} from '@/src/constants/analytics';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { Link } from 'react-router-dom-v5';

export const EnergyTrackerFirstTimeBanner: ActionCentreBanner = props => (
  <BannerBase
    {...props}
    variation="firstTime"
    buttonText={strings.buttonTextFirstTime}
  />
);

export const EnergyTrackerReturningBanner: ActionCentreBanner = props => (
  <BannerBase
    {...props}
    variation="returning"
    buttonText={strings.buttonTextReturning}
  />
);

interface BannerBaseProps extends ActionCentreBannerProps {
  buttonText: string;
  variation: 'firstTime' | 'returning';
}

function BannerBase({
  dismiss,
  onClick,
  variation,
  buttonText,
}: BannerBaseProps) {
  return (
    <AnalyticsWrapper
      name={VIEWED_GREENLIGHT_BANNER}
      properties={{ variation }}
    >
      <Banner
        icon="confirmation"
        data-testid={testIds.banner}
        dismiss={dismiss}
      >
        <Heading3 as="h2">{strings.title}</Heading3>
        <P>{strings.paragraph}</P>
        <Margin bottom={4} />
        <PrimaryCTALink
          variant="missionPrimary"
          onClick={onClick}
          as={Link}
          to={ROUTE_ENERGY_INSIGHTS.HUB}
          data-event-name={CLICKED_GREENLIGHT_FROM_BANNER}
          data-event-properties={{ variation }}
          fullWidth="never"
          iconRight="off"
        >
          {buttonText}
        </PrimaryCTALink>
      </Banner>
    </AnalyticsWrapper>
  );
}
