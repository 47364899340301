import { useQuery } from '@ovotech/ui-tools';
import { createDailyUsageEndpoint } from '@/src/constants/endpoints';
import { DailyUsageResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

function useDailyUsageQuery(accountId: string, date: string) {
  const dailyUsageEndpoint = createDailyUsageEndpoint(accountId, date);

  return useQuery(
    `orion-daily-${accountId}-${date}`,
    () =>
      apiService.get<DailyUsageResponse>({
        url: dailyUsageEndpoint,
        headers: { Accept: 'application/json;version=2' },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );
}

export { useDailyUsageQuery };
