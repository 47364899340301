import { useAccountContext } from '@ovotech/ui-tools';
import axios from 'axios';
import { useQuery } from 'react-query';
import { createAvailableProductsEndpoint } from '@/src/constants/endpoints';
import { AnytimeCreditingConfig, AvailableProduct } from '@/src/types/Products';
import { useFeature } from '@optimizely/react-sdk';
import { PAY_AS_YOU_DRIVE } from '../constants/feature-flags';
import { PRODUCT_IDS } from '../constants/products';

type QueryCacheKey = `anytime-bolton-pricing-${string}`;

export const useAnytimeBoltonPricingQuery = () => {
  const { accountId } = useAccountContext();
  const [payAsYouDriveEnabled] = useFeature(PAY_AS_YOU_DRIVE);
  const pricingBolton = payAsYouDriveEnabled
    ? PRODUCT_IDS.payasyoudrive
    : PRODUCT_IDS.anytime;

  return useQuery(
    `anytime-bolton-pricing-${accountId}` as QueryCacheKey,
    () =>
      axios
        .get<AvailableProduct[]>(createAvailableProductsEndpoint(pricingBolton))
        .then(response => response.data),
    {
      select: (data: AvailableProduct[]) => {
        const poundsPerkWh: number = (
          data?.[0]?.creditingConfig as AnytimeCreditingConfig
        )?.poundsPerkWh;

        if (poundsPerkWh !== undefined) {
          let pounds: string;
          let formattedPence: string;

          try {
            pounds = poundsPerkWh.toString();
            formattedPence = `${Number(poundsPerkWh * 100).toFixed(
              0,
            )}p per kWh`;

            if (isNaN(Number(poundsPerkWh))) {
              throw new Error('Error converting to Number');
            }
          } catch (error) {
            // TODO: send error metrics
            throw new Error('Conversion or formatting error occurred');
          }

          return { pounds, formattedPence };
        }

        // TODO: send error metrics
        throw new Error(`No pricing data found for ${pricingBolton} bolt-on`);
      },
    },
  );
};
