// TODO same as GlobalErrorPAge
import styled from 'styled-components';

export const FlexSummaryList = styled.dl(
  ({ theme: { space } }) => `
    display: flex;
    dt {
      margin-right: ${space[2]};
    }
  `,
);

export const LinksWrapper = styled.div(
  ({ theme: { mediaQueries } }) => `
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;

  ${mediaQueries.mediumAndUp} {
    flex-direction: row;
  }

  a {
    display: block;
    width: 100%;
    max-width: 230px;
    margin: 0.25rem;
  }
`,
);
