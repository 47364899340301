import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ReactQueryProvider } from '@ovotech/ui-tools';
import { ConnectedRouter } from 'connected-react-router';
import React, { ComponentType } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { Store } from 'redux';
import { campaignParams } from '../mixpanel-utils';
import { getCustomerAccessToken } from '@/src/api/utils/getCustomerAccessToken';
import { KAPI_URL } from '@/src/constants/endpoints';
import { history } from '@/src/redux/history';
import result from '@/src/api/kapi/__generated__/graphql';
import { WithOptimizely } from './WithOptimizely';

const createAuthLink = () => {
  const headers = setContext(async (_, { headers }) => {
    const token = await getCustomerAccessToken();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token.accessToken.value}`,
      },
    };
  });

  const httpLink = createHttpLink({
    uri: KAPI_URL,
    fetch,
  });

  return headers.concat(httpLink);
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`,
        graphQLErrors,
      ),
    );
  if (networkError)
    console.error(
      `[Network error]: Operation: ${operation.operationName}, ${networkError}`,
      networkError,
    );
});

const authLink = createAuthLink();

const link = from([errorLink, authLink]);
const client = new ApolloClient({
  cache: new InMemoryCache({ possibleTypes: result.possibleTypes }),
  link,
});

function createWrappedApp({
  store,
  App,
}: {
  store: Store;
  App: ComponentType;
}) {
  return () => {
    React.useEffect(() => {
      campaignParams();

      const unlisten = history.listen(() => {
        // Whenever the url changes, we run the `campaignParams` function to update the UTM last touch tags
        // https://help.mixpanel.com/hc/en-us/articles/360001337103-Last-Touch-UTM-Tags
        campaignParams();
      });
      return unlisten;
    }, []);

    return (
      <ReactQueryProvider>
        <ApolloProvider client={client}>
          {/* @ts-ignore react-18 children props */}
          <Provider store={store}>
            {/* @ts-ignore react-18 children props */}
            <ConnectedRouter history={history} context={ReactReduxContext}>
              <WithOptimizely>
                <App />
              </WithOptimizely>
            </ConnectedRouter>
          </Provider>
        </ApolloProvider>
      </ReactQueryProvider>
    );
  };
}

export default createWrappedApp;
