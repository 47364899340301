import { useMutation, UseMutationResult } from 'react-query';
import { acquisitionAcquireUrl } from '@/src/pages/MoveIn/api/urls';
import {
  AcquisitionRequest,
  AcquisitionResponse,
} from '@/src/pages/MoveIn/types/acquisitionRequest';
import { MoveInDetails } from '@/src/pages/MoveIn/types/moveIn';
import { journeyToSale } from '@/src/pages/MoveIn/utils/journeyToSale';
import { apiService } from '@/src/services/api-service';

export function usePostMoveIn(): UseMutationResult<
  AcquisitionResponse,
  unknown,
  Required<MoveInDetails>
> {
  const url = acquisitionAcquireUrl;

  return useMutation((moveInDetails: Required<MoveInDetails>) => {
    const sale = journeyToSale(moveInDetails);

    const body: AcquisitionRequest = {
      sale,
      customer: moveInDetails.customer,
    };

    return apiService.post<AcquisitionResponse>({
      url,
      body,
      withCredentials: false,
    });
  });
}
