import { P, Small, Margin } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { Spinner } from './Spinner.styled';

type Props = {
  text?: string;
};

const Container = styled.section`
  width: 248px;
  text-align: center;
  position: relative;
  margin: 0 auto;
`;

export const LoadingSpinner = ({ text }: Props) => (
  <Margin bottom={4} data-testid="loading-spinner">
    <Container>
      <Margin bottom={4}>
        <Spinner />
      </Margin>
      {text && (
        <P>
          <Small>{text}</Small>
        </P>
      )}
    </Container>
  </Margin>
);
