import { CTAButton } from '@ovotech/nebula/dist/components';
import { AnalyticsRender, AnalyticsClick } from '@ovotech/ui-tools';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { THERMOSTATS_WAITLIST_BUTTON } from '@/src/constants/analytics-subjects';
import { State } from '@/src/types/State';
import { getWaitlistInfo } from '../hooks/getWaitlistInfo';
import { addToWaitList } from '../hooks/addToWaitlist';

export const ConnectedWaitlistButton = ({
  variant = 'missionPrimary',
}: {
  variant?: string;
}) => {
  const [isAdding, setIsAdding] = useState(false);

  const { general } = useSelector(
    (state: State) => state.smartThermostats.eligibility,
  );

  const { onWaitlist, waitlistLoading } = getWaitlistInfo();

  addToWaitList(isAdding);

  const handleClick = () => {
    setIsAdding(true);
  };

  if (onWaitlist) {
    return <CTAButton variant={variant}>You're on the Waitlist!</CTAButton>;
  } else if (waitlistLoading) {
    return <CTAButton variant={variant}>Adding you to Waitlist!</CTAButton>;
  } else {
    return (
      <AnalyticsRender
        name={THERMOSTATS_WAITLIST_BUTTON}
        properties={{ ineligibilityReasons: general ? general.reasons : [] }}
      >
        <AnalyticsClick
          name={THERMOSTATS_WAITLIST_BUTTON}
          properties={{
            legacyName:
              SMART_THERMOSTATS.QUESTIONS_PAGE.COMING_SOON.CLICKED.WAITLIST,
            ineligibilityReasons: general ? general.reasons : [],
          }}
        >
          <CTAButton variant={variant} onClick={handleClick}>
            Keep me posted
          </CTAButton>
        </AnalyticsClick>
      </AnalyticsRender>
    );
  }
};
