import styled from 'styled-components';
import { mqSmallDown, mqMediumUp } from '@/src/utils/mediaQuery';

export const StyledGraph = styled.div<{
  isHidden?: boolean;
}>`
  ${({ isHidden }) => `display: ${isHidden ? 'none' : 'block'};`}

  font-size: 1.4rem;
  .recharts-label {
    font-weight: bold;
  }

  svg.recharts-surface tspan {
    font-size: 1.2rem;
  }

  ${({ theme: { space } }) => `
    .recharts-legend-wrapper {
      padding-top: ${space[5]};
    }
    .recharts-legend-item {
      padding-left: ${space[4]};
      &:first-of-type {
        padding-left: ${space[9]};
      }
    }
  `}
`;

export const StyledGraphCard = styled.div`
  border-radius: 0;
`;

export const StyledText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  ${({ theme: { space } }) => `
      margin-top: ${space[2]};
    }
  `}
`;

export const StyledToggleButton = styled.button`
  ${({ theme: { fontSizes } }) => `
    ${mqMediumUp(`
      margin: 0 auto;
    `)}
    ${mqSmallDown(`
      font-size: 1.4rem;
    `)}
    font-family: Open Sans;
    font-size: ${fontSizes[1]};
    color: #0D8426;
    text-decoration: underline;
    text-decoration-color: #0D8426;
    display: block;
    background: inherit;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  `}
`;

export const StyledToggleButtonContent = styled.span`
  position: relative;
  display: flex;
`;

export const StyledToggleButtonLabel = styled.span`
  ${({ theme: { space } }) => `
    padding-right: ${space[2]};
  `}
`;

export const ToggleContainer = styled.div<{
  singleUnit?: boolean;
}>`
  ${({ theme: { space, mediaQueries }, singleUnit }) => `
    margin: 0 auto;
    padding-bottom: 16px;
    ${mediaQueries.mediumAndUp} {
       display: flex;
       flex-direction: column;
       width: 25%;
       padding-left: ${singleUnit ? space[8] : '0px'};
       margin: 0;
      }
    }
  `};
`;

export const ToggleTextContainer = styled.div`
  ${({ theme: { mediaQueries } }) => `
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      justify-content: center;
    ${mediaQueries.mediumAndUp} {
       display: flex;
       flex-direction: row;
       margin-right: auto;
      }
    }
  `};
`;

export const ToggleTextItem = styled.div`
  ${({ theme: { space } }) => `
    padding-right: ${space[2]};
  `};
`;

export const UsageUnitText = styled.p<{
  isButton?: boolean;
}>`
  ${({ theme: { colors, fontSizes, space, fonts, fontWeights }, isButton }) => `
    font-size: ${fontSizes[4]};
    font-weight: ${fontWeights.bold};
    font-family: ${fonts.display};
    border-bottom: ${space[1]} solid ${colors.shadeMidLight};
    color: ${colors.shadeMidLight};
    cursor: ${isButton ? 'pointer' : 'default'};

    &[aria-pressed="true"] {
      border-bottom: ${space[1]} solid ${colors.primary};
      color: black;
    }

  `};
`;

export const VerticalSeparator = styled.div`
  ${({ theme: { space } }) => `
    padding-right: ${space[2]};
    border-left: 2px solid #D1D6DE;
  `};
`;
