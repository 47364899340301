import {
  SecondaryCTAButton,
  Margin,
  P,
  PrimaryCTAButton,
} from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { useState, Dispatch, SetStateAction } from 'react';
import GreenerEnergyCancellationModal from '@/src/pages/GreenerEnergy/GreenerEnergySignup/components/GreenerEnergyCancelModal';
import { StyledProductCancelError } from './ProductsCancel.styled';
import { ButtonGroup } from '@/src/components/ButtonGroup/ButtonGroup';
import ContactUsLink from '@/src/components/ContactUsLink';
import { CLICKED_CANCEL_ADDON } from '@/src/constants/analytics';
import {
  PROFILE_ADDON_CANCEL,
  PROFILE_ADDON_CANCEL_KEEP,
} from '@/src/constants/analytics-subjects';
import { PRODUCT_IDS } from '@/src/constants/products';
import { BoltonInstance } from '@/src/types/Products';
import { ProductUpdateStatus } from '@/src/types/State';
import GreenerElectricityCancellationModal from '@/src/pages/GreenerElectricity/GreenerElectricitySignup/components/GreenerElectricityCancelModal';

interface CancellationCTAProps {
  setCancelPrompt: Dispatch<SetStateAction<boolean>>;
  title: string;
  fullWidth?: string;
}

const CancellationCTA = (props: CancellationCTAProps) => {
  const { setCancelPrompt, title, fullWidth } = props;

  return (
    <AnalyticsClick name={PROFILE_ADDON_CANCEL}>
      <SecondaryCTAButton
        data-testid="cancel-link"
        type="button"
        variant="primary"
        onClick={() => {
          setCancelPrompt(true);
        }}
        fullWidth={fullWidth}
      >
        {title}
      </SecondaryCTAButton>
    </AnalyticsClick>
  );
};

const CancellationError = () => (
  <Margin top={4}>
    <StyledProductCancelError data-testid="cancel-error">
      Sorry, we were unable to cancel your upgrade. Please try again later. If
      this keeps happening please <ContactUsLink>contact us</ContactUsLink>
    </StyledProductCancelError>
  </Margin>
);

interface CancellationPromptProps {
  handleCancel: () => void;
  setCancelPrompt: Dispatch<SetStateAction<boolean>>;
  cancellation: ProductUpdateStatus;
  addon: string | undefined;
}

const CancellationPrompt = (props: CancellationPromptProps) => {
  const { handleCancel, setCancelPrompt, cancellation, addon } = props;

  return (
    <div data-testid="cancel-prompt">
      <P>Are you sure you want to remove this upgrade?</P>
      <ButtonGroup>
        <PrimaryCTAButton
          data-testid="cancel-button"
          type="button"
          onClick={handleCancel}
          disabled={cancellation.isUpdating}
          data-event-name={CLICKED_CANCEL_ADDON}
        >
          Yes
        </PrimaryCTAButton>
        <AnalyticsClick name={PROFILE_ADDON_CANCEL_KEEP} properties={{ addon }}>
          <SecondaryCTAButton
            data-testid="cancel-prompt-cancel"
            type="button"
            onClick={() => setCancelPrompt(false)}
            disabled={cancellation.isUpdating}
          >
            No
          </SecondaryCTAButton>
        </AnalyticsClick>
      </ButtonGroup>
    </div>
  );
};

type Props = {
  onCancel: () => void;
  cancellation: ProductUpdateStatus;
  title: string;
  bolton?: BoltonInstance;
  ctaFullWidth?: string;
};

const ProductsCancel = (props: Props) => {
  const { onCancel, cancellation, title, bolton, ctaFullWidth } = props;
  const [cancelPrompt, setCancelPrompt] = useState(false);

  const showGreenerEnergyModal =
    cancelPrompt && bolton?.productId === PRODUCT_IDS.greenerEnergy;

  const showGreenerElectricityModal =
    cancelPrompt && bolton?.productId === PRODUCT_IDS.greenerElectricity;

  const showCancellationPrompt =
    cancelPrompt && !(showGreenerEnergyModal || showGreenerElectricityModal);

  return (
    <div>
      {!cancelPrompt && (
        <CancellationCTA
          fullWidth={ctaFullWidth}
          setCancelPrompt={setCancelPrompt}
          title={title}
        />
      )}

      {showGreenerEnergyModal && (
        <GreenerEnergyCancellationModal
          handleCancel={onCancel}
          setCancelPrompt={setCancelPrompt}
          cancellation={cancellation}
          cancelPrompt={cancelPrompt}
          addon={bolton.name}
        />
      )}

      {showGreenerElectricityModal && (
        <GreenerElectricityCancellationModal
          handleCancel={onCancel}
          setCancelPrompt={setCancelPrompt}
          cancellation={cancellation}
          cancelPrompt={cancelPrompt}
          addon={bolton.name}
        />
      )}

      {showCancellationPrompt && (
        <CancellationPrompt
          handleCancel={onCancel}
          setCancelPrompt={setCancelPrompt}
          cancellation={cancellation}
          addon={bolton?.name}
        />
      )}

      {cancellation.error && <CancellationError />}
    </div>
  );
};

export default ProductsCancel;
