import styled from 'styled-components';

export const StyledTitle = styled.h3`
  ${({ theme: { fontSizes, colors, fonts, fontWeights } }) => `
    color: ${colors.heading};
    font-weight: ${fontWeights.bold};
    font-family: ${fonts.body};
    font-size: ${fontSizes[2]};
  `}
`;
