import {
  Heading1,
  P,
  SecondaryCTALink,
  Strong,
  TextLink,
} from '@ovotech/nebula';
import {
  AnalyticsClick,
  AnalyticsView,
  useAccountContext,
} from '@ovotech/ui-tools';
import React, { useState } from 'react';
import { RouterLink } from '@/src/components';
import { SummaryCard } from '@/src/components/SummaryCard';
import {
  COLLECTABLE_BALANCE_SUMMARY,
  MANAGE_YOUR_PAYMENTS_LINK,
  UNDERSTANDING_YOUR_BALANCE_LINK,
} from '@/src/constants/analytics-subjects';
import { ROUTE_MAKE_CARD_PAYMENT } from '@/src/constants/routes';
import { LEARN_ABOUT_BALANCE_LINK } from '@/src/constants/links';
import {
  balanceTextVariations,
  formatCollectableBalanceAmount,
} from '@/src/components/BalanceSummary/utils';
import { BalanceVariation } from '@/src/pages/Payments/hooks/useBalance';
import { LearnAboutBalanceModal } from '@/src/components/BalanceSummary/LearnAboutBalanceModal';
import styled from 'styled-components';

const LEARN_ABOUT_BALANCE = 'Learn about balance';

const StyledTextLink = styled(TextLink)`
  &:hover {
    cursor: pointer;
  }
`;

const LearnAboutBalanceLink = ({
  balanceVariation,
  onClick,
}: {
  balanceVariation: BalanceVariation;
  onClick?: () => void;
}) => {
  if (balanceVariation === BalanceVariation.TestGroup) {
    return (
      <P>
        <AnalyticsClick name={UNDERSTANDING_YOUR_BALANCE_LINK}>
          <StyledTextLink onClick={onClick}>
            {LEARN_ABOUT_BALANCE}
          </StyledTextLink>
        </AnalyticsClick>
      </P>
    );
  }

  return (
    <P>
      <AnalyticsClick name={UNDERSTANDING_YOUR_BALANCE_LINK}>
        <TextLink opensInNewWindow={true} href={LEARN_ABOUT_BALANCE_LINK}>
          {LEARN_ABOUT_BALANCE}
        </TextLink>
      </AnalyticsClick>
    </P>
  );
};

type Props = {
  amount: string;
  balanceVariation: BalanceVariation;
};

export const CollectableBalanceSummary = ({
  amount,
  balanceVariation,
}: Props) => {
  const { accountId } = useAccountContext();
  const balanceText = balanceTextVariations(amount);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <AnalyticsView name={COLLECTABLE_BALANCE_SUMMARY}>
      <>
        {modalOpen && (
          <LearnAboutBalanceModal
            isInDebit={Number(amount) < 0}
            onClose={closeModal}
          />
        )}
        <SummaryCard
          heading={balanceText.headingText}
          icon="accountSummary"
          footer={
            <AnalyticsClick name={MANAGE_YOUR_PAYMENTS_LINK}>
              <SecondaryCTALink to={ROUTE_MAKE_CARD_PAYMENT} as={RouterLink}>
                {balanceText.paymentCTAText}
              </SecondaryCTALink>
            </AnalyticsClick>
          }
        >
          <Heading1 as="h4" style={{ lineHeight: 1, margin: 0 }}>
            {formatCollectableBalanceAmount(amount)}
          </Heading1>
          <P style={{ margin: 0 }}>{balanceText.balanceInfoText}</P>
          <LearnAboutBalanceLink
            balanceVariation={balanceVariation}
            onClick={
              balanceVariation === BalanceVariation.TestGroup
                ? openModal
                : undefined
            }
          />
          <P>
            <Strong>Account number: </Strong>
            {accountId}
          </P>
        </SummaryCard>
      </>
    </AnalyticsView>
  );
};
