import { Disclosure } from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { ReactNode, useState } from 'react';

type Props = {
  title: string;
  titleWhenOpen?: string;
  children: ReactNode;
  'data-event-name'?: string;
  'data-event-props'?: Record<string, unknown>;
};

const Toggleable = ({
  title,
  titleWhenOpen,
  children,
  'data-event-name': name,
  'data-event-props': props,
  ...rest
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const { dispatch } = useAnalytics();
  return (
    <Disclosure
      title={titleWhenOpen && expanded ? titleWhenOpen : title}
      expanded={expanded}
      onToggle={() => {
        setExpanded(!expanded);
        if (name) {
          dispatch({
            name: name,
            type: 'click',
            properties: {
              ...props,
              toggleType: !expanded ? 'OPENED' : 'CLOSED',
            },
          });
        }
      }}
      {...rest}
    >
      {children}
    </Disclosure>
  );
};

export default Toggleable;
