import { SecondaryCTALink } from '@ovotech/nebula';
import styled from 'styled-components';
import ovoFoundationChild from '@/src/resources/ovo-foundation-child.png';
import ovoFoundationLogo from '@/src/resources/ovo-foundation-logo.png';
import { mqMediumDown, mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledMain = styled.div`
  overflow: hidden;
  position: relative;
`;

export const StyledDonatePicture = styled.div`
  order: 2;
  top: 0;
  right: 0;
  width: 45%;
  height: 435px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${ovoFoundationChild});
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 13% 100%);

  ${mqMediumDown(`
    width: 75%;
    height: 225px;
    order: 1;
    clip-path: polygon(0 0, 100% 0%, 100% 87%, 13% 99%);
    margin-left: 25%;
  `)}
`;

export const StyledDonateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${mqMediumDown(`
    margin-top: 225px;
  `)}
`;

export const StyledDonateInfo = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  order: 1;

  div h3 {
    font-size: 2rem;
    width: 70%;
  }

  div p {
    width: 75%;
  }

  div li {
    width: 97%;
  }

  ${({ theme: { space } }) => `
    padding-left: ${space[2]};

    ${mqMediumDown(`
      width: 100%;
      padding-right: ${space[6]};
      order: 2; 
    `)}
  `}
`;

export const StyledDonateLogo = styled.img.attrs({
  src: ovoFoundationLogo,
})`
  height: 66px;
  width: 290px;
`;

export const StyledFormContainer = styled.div`
  order: 3;
  width: 65%;

  ${({ theme: { space } }) => `
    padding-left: ${space[2]};
    padding-right: ${space[12]};
    margin-top: ${space[9]};
  
    ${mqMediumDown(`
      margin-top: ${space[4]};
      padding-right: ${space[6]};
      width: 100%;
    `)}
  `}
`;

export const StyledTitle = styled.h3`
  ${({ theme: { space } }) => `
    ${mqSmallDown(`
      margin-top: ${space[1]};
      margin-bottom: ${space[2]};
    `)}
   `}
`;

export const StyledParagraph = styled.p`
  strong {
    font-weight: bold;
  }

  ${({ theme: { space } }) => `
    ${mqSmallDown(`
      margin-top: 0;
      margin-bottom: ${space[2]};
    `)}
   `}
`;

export const StyledCTALink = styled(SecondaryCTALink)`
  width: 169px;
  margin-top: 6px;
`;
