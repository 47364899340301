import { NextBestActionResponse } from '@/src/types/NextBestAction';
import { getActionCodeName } from './getActionCodeName';
import { NbaActionCardsMap } from '../useHardcodedNbaActionCards';
import { ShowableCardsFragmentFragment } from '@/src/api/contentful/__generated__/graphql';
import {
  FragmentType,
  getFragmentData,
  graphql,
} from '@/src/api/contentful/__generated__';

type CardType = {
  actionCode: string;
  nbaInfo: NextBestActionResponse[number];
  fallbackComponent?: React.ComponentType<any>;
  featureFlag?: string | null;
  data?: NonNullable<ShowableCardsFragmentFragment['items']>[0];
  mixpanelId?: string | null;
};

type Props = {
  nbaData?: NextBestActionResponse;
  contentFragment?: FragmentType<typeof ShowableCardsFragment> | null;
  hardcodedNbaActionCards: NbaActionCardsMap;
  isFeatureEnabledQuery: (flag: string) => boolean;
};

const ShowableCardsFragment = graphql(/* contentful-codegen-graphql */ `
  fragment ShowableCardsFragment on BuildNextBestActionActionCardsCollection {
    items {
      actionCode
      text {
        header
        text {
          json
        }
      }
      nonRoundedEdges
      canBeDismissed
      image {
        ... on WrapperImage {
          altText
          desktopImage {
            url
            width
          }
        }
      }
      optimizelyFeatureFlag
      inverted
      ctaBehaviour
      cta {
        ...PackCTAFragment
      }
      mixpanelId
    }
  }
`);

export const getShowableCards = ({
  nbaData,
  contentFragment,
  hardcodedNbaActionCards,
  isFeatureEnabledQuery,
}: Props) => {
  if (!nbaData) return [];

  const prioritisedNbaCodes = [...nbaData].sort(
    (a, b) => a.Action_Priority_Order - b.Action_Priority_Order,
  );

  const cardContent = getFragmentData(ShowableCardsFragment, contentFragment);

  const showableCards = prioritisedNbaCodes.reduce<CardType[]>(
    (accumulator, nbaInfo) => {
      const actionCodeName = getActionCodeName(nbaInfo);

      let showableCard: CardType = {
        actionCode: actionCodeName,
        nbaInfo,
      };

      const contentfulCard = cardContent?.items?.find(
        item => item?.actionCode === actionCodeName,
      );

      const fallbackActionCard = hardcodedNbaActionCards[actionCodeName];

      if (fallbackActionCard) {
        showableCard = {
          ...showableCard,
          fallbackComponent: fallbackActionCard.component,
          featureFlag: fallbackActionCard.featureFlag,
          mixpanelId: fallbackActionCard.name,
        };
      }

      if (contentfulCard) {
        showableCard = {
          ...showableCard,
          data: contentfulCard,
          featureFlag: contentfulCard?.optimizelyFeatureFlag ?? null,
          mixpanelId: contentfulCard?.mixpanelId ?? null,
        };
      }

      const isCardEnabled =
        showableCard?.data || showableCard?.fallbackComponent;

      const isFlagEnabled =
        !showableCard?.featureFlag ||
        isFeatureEnabledQuery!(showableCard.featureFlag);

      if (isCardEnabled && isFlagEnabled) {
        return [...accumulator, showableCard];
      }

      return accumulator;
    },
    [],
  );

  return showableCards;
};
