import styled from 'styled-components';

/**
 * @deprecated use Nebula instead.
 */
export const Tr = styled.tr<{ noBorder?: boolean }>`
  ${({ theme: { colors }, noBorder }) =>
    noBorder
      ? null
      : `&:not(:last-of-type) {
      border-bottom: 1px solid ${colors.borderMuted};
    }`};
`;
