import { P, TextLink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { Section } from '@/src/components';
import { PLAN_PAGE_TERMS_LINK } from '@/src/constants/analytics-subjects';
import { OVO_ENERGY_TERMS } from '@/src/constants/links';

const PlanDisclaimer = () => (
  <Section>
    <P>
      All prices exclude VAT, which is currently charged at 5% for domestic
      customers.{' '}
      <AnalyticsClick name={PLAN_PAGE_TERMS_LINK}>
        <TextLink href={OVO_ENERGY_TERMS}>Terms and conditions</TextLink>
      </AnalyticsClick>{' '}
      apply.
    </P>
  </Section>
);

export { PlanDisclaimer };
