import styled from 'styled-components';
import { mqLargeDown, mqSmallUp } from '@/src/utils/mediaQuery';

export const SkinnyModal = styled.div`
  width: 500px;
`;
export const SectionWrapper = styled.div`
  ${({ theme: { space } }) => `
    margin-top: ${space[4]};
  `}
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
`;

export const Card = styled.article`
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
  position: relative;
  width: 49%;
  overflow: hidden;
  flex-direction: column;
  min-height: 300px;

  ${({ theme: { colors } }) => `
    background-color: ${colors.canvas};
  `}

  ${mqLargeDown(`
    width:100%;
  `)}
`;

export const TermsContainer = styled.div`
  ${({ theme: { colors, fonts } }) => `
    font-family: ${fonts.body};
    color: ${colors.heading};
  `}
`;

export const TermsHeader = styled.h3`
  ${({ theme: { space, fontSizes, fonts, fontWeights } }) => `
    font-size: ${fontSizes[1]};
    font-weight: ${fontWeights.bold};
    margin-bottom: ${space[2]};
    font-family: ${fonts.display};
    > span {
      font-weight: ${fontWeights.normal};
    }
    &:not(:first-of-type) {
      margin-top: ${space[6]}
    }
    ${mqSmallUp(`
      font-size: ${fontSizes[2]};
    `)}
  `}
`;

export const TermsContent = styled.p`
  ${({ theme: { space, fontSizes, colors } }) => `
    padding-bottom: ${space[4]};
    font-size: ${fontSizes[0]};
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    color: ${colors.body};
    > a {
      color: ${colors.primary};
    }
    ${mqSmallUp(`
      font-size: ${fontSizes[1]};
    `)}
  `}
`;

export const ConfirmationWrapper = styled.section`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  ${({ theme: { colors, space } }) => `
    margin-top: ${space[8]};
    padding: ${space[6]} ${space[6]} 9rem;
    background-color: ${colors.canvas};
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: ${space[1]};

    ${mqSmallUp(`
      padding-bottom: 10rem;
    `)}
  `}
`;

ConfirmationWrapper.displayName = 'ConfirmationWrapper';
