import styled from 'styled-components';

export const StyledSideNavigationPanel = styled.div(
  ({ theme: { space, mediaQueries } }) => `
    // Reduce padding to make more space on desktop.
    ${mediaQueries.smallAndUp} {
      padding: ${space[6]};
      padding-right: 0;
    }
  `,
);
