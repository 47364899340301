import { Margin } from '@ovotech/nebula';
import React, { ReactNode } from 'react';
import { BannerId } from './banners';
import { Toggleable } from '@/src/components';

type Props = {
  children: ReactNode;
  bannerId?: BannerId;
};

export const CollapsibleBlock = ({ children, bannerId }: Props) => (
  <Margin top={4}>
    <Toggleable
      title="Show full details"
      titleWhenOpen="Hide full details"
      data-testid="show-full-details"
      data-event-name="Action Centre: show more clicked"
      data-event-props={{
        id: bannerId,
      }}
    >
      {children}
    </Toggleable>
  </Margin>
);
