import { TextLink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';

import React, { FunctionComponent } from 'react';
import { CONTACT_US_LINK } from '@/src/constants/analytics-subjects';
import { HELP_CONTACT_US_LINK } from '@/src/constants/links';

const ContactUsLink: FunctionComponent<{ children?: Array<any> | string }> = ({
  children,
}) => {
  const linkText =
    children == null || (Array.isArray(children) && children.length === 0)
      ? 'contact us'
      : children;

  return (
    <AnalyticsClick
      name={CONTACT_US_LINK}
      properties={{
        linkText: JSON.stringify({
          linkText,
        }),
      }}
    >
      <TextLink href={HELP_CONTACT_US_LINK}>{linkText}</TextLink>
    </AnalyticsClick>
  );
};

export default ContactUsLink;
