export const getContentfulImageApiUrl = (
  url?: string | null,
  width?: number | null,
) => {
  if (!url) return;
  const MAX_IMAGE_WIDTH = 600;
  const calculatedWidth =
    !width || width > MAX_IMAGE_WIDTH ? MAX_IMAGE_WIDTH : width;
  return `${url}?w=${calculatedWidth}&q=85`;
};
