import { ConsentPolicyData } from '@/src/api/kapi/use-consent-query';
import { Radio } from '@ovotech/nebula';
import React from 'react';
import {
  StyledHeading2,
  StyledRadioField,
  StyledSubHeading,
  Text,
} from './MeterContent.styled';

/**
  Only show a frequency of monthly for settlement consent if
  the API returns a monthly frequency, otherwise only two options
  will be shown for settlement (half hourly and daily).

  The consent screen must display three frequency options for
  billing consent: half-hourly, daily, and monthly.

  If there is a future consent record (start date after today),
  it will be used to preselect the options.

  If there is no future consent record, but there is a current consent
  record (start date in the past with end date in the future or null),
  it will be used to preselect the options.

  If neither a future nor a current consent record is present,
  no options will be preselected.
*/

export const MeterContent = ({
  fuel,
  sprn,
  billingConsent,
  settlementConsent,
  onRadioChange,
  isMutationLoading,
}: {
  fuel: string;
  sprn: string;
  billingConsent: ConsentPolicyData;
  settlementConsent: ConsentPolicyData;
  onRadioChange: (
    sprn: string,
    consentLevel: string,
    policyName: string,
  ) => void;
  isMutationLoading: boolean;
}) => {
  const handleChange = (e: { target: { name: string; value: string } }) => {
    const selectedConsentLevel = e.target.value;
    const selectedPolicyName = e.target.name.startsWith('billing')
      ? 'billing'
      : 'settlement';
    onRadioChange(sprn, selectedConsentLevel, selectedPolicyName);
  };

  return (
    <>
      <StyledHeading2>MPAN: {sprn}</StyledHeading2>

      <StyledSubHeading>Data used for billing</StyledSubHeading>

      <StyledRadioField
        name={'billing_' + sprn}
        label=""
        defaultValue={billingConsent?.consentLevel}
        disabled={isMutationLoading}
      >
        <Radio
          label={
            <span>
              <span>Every half hour</span>
              <span style={{ fontWeight: 400 }}>
                <br />
                {/* not present in theme */}
                <p style={{ color: '666D78' }}>
                  {' '}
                  Get detailed insights about how your home uses{' '}
                  {fuel.toLowerCase()}
                </p>
              </span>
            </span>
          }
          value={'HALF_HOURLY'}
          name={'billing' + sprn}
          id={String(Math.random())}
          onChange={handleChange}
          data-testid="radio-billing-half-hourly"
        />
        <Radio
          label="Once a day"
          value={'DAILY'}
          name={'billing' + sprn}
          id={String(Math.random())}
          onChange={handleChange}
          data-testId="radio-billing-daily"
        />
        <Radio
          label="Once a month"
          value={'MONTHLY'}
          name={'billing' + sprn}
          onChange={handleChange}
          id={String(Math.random())}
          data-testid="radio-billing-monthly"
        />
      </StyledRadioField>

      <>
        <StyledSubHeading>Data used for settlement</StyledSubHeading>
        <Text style={{ color: '666D78' }}>
          We’ll use your data to help work out how much electricity to buy in
          advance.
        </Text>

        <StyledRadioField
          name={'settlement_' + sprn}
          label=""
          defaultValue={settlementConsent?.consentLevel}
        >
          <Radio
            label="Every half hour"
            value={'HALF_HOURLY'}
            name={'settlement' + sprn}
            id={String(Math.random())}
            onChange={handleChange}
            data-testid="radio-settlement-half-hourly"
          />
          <Radio
            label="Once a day"
            value={'DAILY'}
            name={'settlement' + sprn}
            id={String(Math.random())}
            onChange={handleChange}
            data-testid="radio-settlement-daily"
          />

          {settlementConsent.consentLevel === 'MONTHLY' && (
            <Radio
              label="Once a month"
              value={'MONTHLY'}
              name={'settlement' + sprn}
              id={String(Math.random())}
              onChange={handleChange}
              data-testid="radio-settlement-monthly"
            />
          )}
        </StyledRadioField>
      </>
    </>
  );
};
