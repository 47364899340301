import { useMutation, useQueryClient } from 'react-query';
import { apiService } from '@/src/services/api-service';
import { TescoRewardResponse } from '../types/Response';
import { TESCO_REWARDS_OFFER_URL } from '../constants/endpoints';
import { getTescoRewardsQueryCacheKey } from './use-tesco-rewards';

type QueryCacheKey = `tesco-rewards-create-application-${string}`;

export const useTescoRewardsCreateApplicationMutation = (
  accountId: string | null | undefined,
) => {
  const queryClient = useQueryClient();
  const tescoRewardQueryCacheKey = getTescoRewardsQueryCacheKey(accountId);

  return useMutation({
    mutationFn: (accountId: string | null | undefined) =>
      apiService.put<TescoRewardResponse>({
        url: TESCO_REWARDS_OFFER_URL,
        body: {
          accountNumber: accountId,
        },
      }),
    mutationKey:
      `tesco-rewards-create-application-${accountId}` as QueryCacheKey,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [tescoRewardQueryCacheKey],
      });
    },
  });
};
