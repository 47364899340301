import { BannerId } from '../../../ActionCentre/Banners/banners';
import { findContractInLoss } from '../../../../utils/contracts/findContractInLoss';
import { isDualFuelContract } from '../../../../utils/contracts/isDualFuelContract';
import { ContractsResponse } from '@/src/types/Response';

export const lossBannerToUse = (
  contracts: ContractsResponse | undefined,
): BannerId | null => {
  if (!contracts) return null;

  const isDualFuel = isDualFuelContract(contracts);
  const electricityContract = findContractInLoss(contracts.electricity);
  const gasContract = findContractInLoss(contracts.gas);

  if (!electricityContract && !gasContract) return null;

  const lossCompleteForBothFuels = Boolean(
    electricityContract?.status?.lossComplete &&
      gasContract?.status?.lossComplete,
  );

  const lossCompleteDifferentDates =
    gasContract?.lossDate !== electricityContract?.lossDate;

  const inLossForDualFuel = Boolean(
    electricityContract?.lossDate && gasContract?.lossDate,
  );

  if (!isDualFuel) return 'lossCompleteSingleFuel';
  if (!inLossForDualFuel) return 'lossCompleteSingleLoss';
  if (!lossCompleteForBothFuels) return 'lossCompletePending';
  if (lossCompleteDifferentDates) return 'lossCompleteDualLossDifferentDates';

  return 'lossCompleteDualLoss';
};
