import { Card, Heading2 } from '@ovotech/nebula';
import React from 'react';
import { SkeletonCTA } from './SkeletonCTA';
import { SkeletonCircle } from './SkeletonCircle';
import { SkeletonHeading } from './SkeletonHeading';
import { SkeletonText } from './SkeletonText';

interface SkeletonCardProps {
  'data-testid'?: string;
}
export const SkeletonCard = (props: SkeletonCardProps) => (
  <Card {...props}>
    <SkeletonHeading headingComponent={Heading2} />
    <SkeletonText lines={4} />
    <SkeletonCircle />
    <SkeletonCTA />
  </Card>
);
