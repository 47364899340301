// Bar width for breakdown widget
export const setBarWidth = (
  yourTotal: number,
  similarHomesTotal: number | null,
) => {
  const outputObject = { yourBarWidth: '0', averageBarWidth: '0' };
  if (!similarHomesTotal) {
    return { ...outputObject, yourBarWidth: '100' };
  }
  const yourTotalToNum = yourTotal;
  const averageTotalToNum = similarHomesTotal;
  if (yourTotalToNum > averageTotalToNum) {
    outputObject.yourBarWidth = '100';
    outputObject.averageBarWidth = ((averageTotalToNum / yourTotalToNum) * 100)
      .toFixed()
      .toString();
  } else {
    outputObject.yourBarWidth = ((yourTotalToNum / averageTotalToNum) * 100)
      .toFixed()
      .toString();
    outputObject.averageBarWidth = '100';
  }
  return outputObject;
};
export const formatConsumption = (n: number) => n.toFixed(1).toString();
