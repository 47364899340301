import { Col, Row, VisuallyHidden } from '@ovotech/nebula';
import React from 'react';
import Link from '../Link/Link';
import {
  StyledSimpleHeaderContents,
  StyledLogoAndMenuButton,
  StyledNavHeader,
  StyledSimpleHeaderLink,
} from './Header.styled';
import { HeaderWrapper } from './HeaderWrapper';

import { StyledSkipLink } from '@/src/components/Navigation/Links/SkipLink.styled';

import { ROUTE_HOME } from '@/src/constants/routes';
import { StyledLightLogo } from '@/src/core/theme';

const SimpleHeader = ({ headerLink }: { headerLink?: JSX.Element }) => {
  return (
    <HeaderWrapper>
      <StyledNavHeader>
        <Row>
          <Col>
            <StyledSkipLink to="#main">Skip to main content</StyledSkipLink>
            <StyledSimpleHeaderContents>
              <StyledLogoAndMenuButton>
                <Link aria-hidden="true" tabIndex={-1} to={ROUTE_HOME}>
                  <StyledLightLogo />
                </Link>
                <VisuallyHidden>OVO Energy</VisuallyHidden>
              </StyledLogoAndMenuButton>
              <StyledSimpleHeaderLink>
                {headerLink ? headerLink : null}
              </StyledSimpleHeaderLink>
            </StyledSimpleHeaderContents>
          </Col>
        </Row>
      </StyledNavHeader>
    </HeaderWrapper>
  );
};

export default SimpleHeader;
