import { useAccountContext } from '@ovotech/ui-tools';
import { differenceInMonths, startOfToday } from 'date-fns';
import React from 'react';

import {
  useContractsQuery,
  useDirectDebitQuery,
  useRenewablePlansQuery,
} from '@/src/api';
import FixedContractRenewal from '@/src/components/ActionCentre/Banners/Renewal/FixedContractRenewal';
import VariableContractEarlyRenewal from '@/src/components/ActionCentre/Banners/Renewal/VariableContractEarlyRenewal';
import VariableContractRenewal from '@/src/components/ActionCentre/Banners/Renewal/VariableContractRenewal';
import {
  ActionCentreBanner,
  ActionCentreBannerProps,
} from '@/src/components/ActionCentre/Banners/banners';
import { earliestContractInRenewal } from '@/src/pages/Renewal/selectors';
import { getEpochDaysDate } from '@/src/utils/date';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';

export const Renewal: ActionCentreBanner = (props: ActionCentreBannerProps) => {
  const { utmSource = 'other' } = props;
  const { accountId } = useAccountContext();

  const profile = useProfileQueryKapi();
  const contracts = useContractsQuery(accountId);
  const directDebit = useDirectDebitQuery(accountId);
  const renewablePlans = useRenewablePlansQuery(accountId ?? null);

  if (
    contracts.status !== 'success' ||
    directDebit.isFetching ||
    renewablePlans.status !== 'data' ||
    profile.isFetching ||
    profile.isError
  ) {
    return null;
  }

  const earliestContract = earliestContractInRenewal(contracts.data);
  if (!earliestContract) return null;

  if (!earliestContract.expiryDate) {
    const isLongVariable =
      differenceInMonths(
        startOfToday(),
        getEpochDaysDate(earliestContract.startDate),
      ) >= 3;

    return isLongVariable ? (
      <VariableContractRenewal
        id="renewalVariableLong"
        {...props}
        utmSource={utmSource}
      />
    ) : (
      <VariableContractEarlyRenewal id="renewalVariableShort" {...props} />
    );
  }

  return (
    <FixedContractRenewal
      id="renewalFixed"
      firstName={profile?.data?.customer_nextV1?.givenName || ''}
      earliestContract={earliestContract}
      {...props}
      utmSource={utmSource}
    />
  );
};
