type BreakpointVals = {
  small: number;
  medium: number;
  large: number;
  navigation: number;
};

type Breakpoints = {
  smallDown: string;
  smallUp: string;
  smallToMedium: string;
  smallToLarge: string;
  mediumDown: string;
  mediumUp: string;
  mediumToLarge: string;
  largeUp: string;
  largeDown: string;
  mobileNav: string;
  desktopNav: string;
};

export const breakpointVals: BreakpointVals = {
  small: 480,
  medium: 768,
  large: 1108,
  navigation: 1024,
};

export const breakpoints: Breakpoints = {
  smallDown: `(max-width: ${breakpointVals.small}px)`,
  smallUp: `(min-width: ${breakpointVals.small + 1}px)`,
  smallToMedium: `(min-width: ${breakpointVals.small + 1}px) and (max-width: ${
    breakpointVals.medium
  }px)`,
  smallToLarge: `(min-width: ${breakpointVals.small + 1}px) and (max-width: ${
    breakpointVals.large
  }px)`,
  mediumDown: `(max-width: ${breakpointVals.medium}px)`,
  mediumUp: `(min-width: ${breakpointVals.medium + 1}px)`,
  mediumToLarge: `(min-width: ${
    breakpointVals.medium + 1
  }px) and  (max-width: ${breakpointVals.large}px)`,
  largeUp: `(min-width: ${breakpointVals.large + 1}px)`,
  largeDown: `(max-width: ${breakpointVals.large}px)`,
  mobileNav: `(max-width: ${breakpointVals.navigation}px)`,
  desktopNav: `(min-width: ${breakpointVals.navigation + 1}px)`,
};

const mq =
  (breakpoint: string) =>
  (content: string): string =>
    `
  @media ${breakpoint} {
    ${content}
  }
`;

export const mqSmallDown = mq(breakpoints.smallDown);
export const mqSmallUp = mq(breakpoints.smallUp);
export const mqSmallToMedium = mq(breakpoints.smallToMedium);
export const mqSmallToLarge = mq(breakpoints.smallToLarge);
export const mqMediumDown = mq(breakpoints.mediumDown);
export const mqMediumUp = mq(breakpoints.mediumUp);
export const mqMediumToLarge = mq(breakpoints.mediumToLarge);
export const mqLargeDown = mq(breakpoints.largeDown);
export const mqLargeUp = mq(breakpoints.largeUp);
export const mqMobileNav = mq(breakpoints.mobileNav);
export const mqDesktopNav = mq(breakpoints.desktopNav);

export const mqPrint = mq('print');
export const mqIE11 = mq(
  'screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)',
);

export const mqFirefox = mq('all and (min--moz-device-pixel-ratio:0)');

export const safariOnly = (content: string): string => `
  @media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
    ${content}
    }
  }
`;

export default mq;
