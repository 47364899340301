import { useAccountContext } from '@ovotech/ui-tools';
import { isAfter, subMonths } from 'date-fns';
import { useLocalStorage } from '@/src/utils/useLocalStorage';
import { HomeProfileWidgetPosition } from '../../components/HomeProfileWidget/HomeProfileWidget.types';
import { HomeProfileWidgetResponse } from '@/src/types/Response';

export const useHubHomeProfileWidget = (
  homeProfileWidget: HomeProfileWidgetResponse | null,
) => {
  const { accountId } = useAccountContext();
  const [dismissedDate, setDismissedDate] = useLocalStorage(
    `hp_widget_dismissed_hub_${accountId}`,
    '',
  );

  const isDismissed =
    !!dismissedDate &&
    isAfter(new Date(dismissedDate), subMonths(new Date(), 6));

  const widgetPosition: HomeProfileWidgetPosition =
    !homeProfileWidget || !accountId
      ? 'hidden'
      : homeProfileWidget.recentlyUpdated || isDismissed
      ? 'bottom'
      : 'top';

  return {
    widgetPosition,
    onClose:
      widgetPosition === 'top'
        ? () => setDismissedDate(new Date().toISOString())
        : undefined,
  };
};
