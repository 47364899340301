import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import {
  createProductActivateEndpoint,
  createProductCancelEndpoint,
} from '@/src/constants/endpoints';
import { AjaxError } from '@/src/types/Ajax';
import apiService from '@/src/services/apiService';
import {
  Action,
  ActivateAnytimeProductError,
  ActivateAnytimeProductStart,
  ActivateAnytimeProductSuccess,
  CancelAnytimeProductError,
  CancelAnytimeProductStart,
  CancelAnytimeProductSuccess,
  ChangeSmartChargerSelection,
  UpdateCarMake,
  UpdateCarModel,
  UpdateCarYear,
  UpdateInternetAccess,
  UpdateSolarPanels,
} from '@/src/types/Action';
import { ProductId, SignupSource } from '@/src/types/Products';
import { SmartChargers, EVAnytimeState } from '@/src/types/State';

/**
 * @deprecated only used for the deprecated chargeanytime bolton
 */
export const activateAnytimeProductStart = (
  promoCode?: string,
  displayName?: string,
  options?: Partial<{ cost: number }>,
  source?: SignupSource,
  additionalData?: {
    promo: string;
  },
): ActivateAnytimeProductStart => ({
  type: 'ACTIVATE_ANYTIME_PRODUCT_START',
  isFetching: true,
  productId: 'e02623e2-512f-4787-a8da-c3719c1a275a', // anytime productid
  versionId: '4487c24c-6a8a-4e82-9a94-ee1169fbd635', // anytime versionid
  promoCode,
  displayName,
  options,
  source,
  additionalData,
});

/**
 * @deprecated only used for the deprecated chargeanytime bolton
 */
export const activateAnytimeProductSuccess = (
  displayName: string,
): ActivateAnytimeProductSuccess => ({
  type: 'ACTIVATE_ANYTIME_PRODUCT_SUCCESS',
  isFetching: false,
  productId: 'e02623e2-512f-4787-a8da-c3719c1a275a',
  displayName,
  meta: {
    analytics: {
      event: `PAYM - Completed upgrade to a ${displayName}`,
      props: {
        productId: 'e02623e2-512f-4787-a8da-c3719c1a275a',
      },
    },
  },
});

/**
 * @deprecated only used for the deprecated chargeanytime bolton
 */
export const activateAnytimeProductError = (
  { response }: AjaxError,
  displayName: string,
): ActivateAnytimeProductError => ({
  type: 'ACTIVATE_ANYTIME_PRODUCT_ERROR',
  isFetching: false,
  errorResponse: response,
  productId: 'e02623e2-512f-4787-a8da-c3719c1a275a',
  displayName,
});

export const cancelAnytimeProductStart = (
  productId: ProductId,
): CancelAnytimeProductStart => ({
  type: 'CANCEL_ANYTIME_PRODUCT_START',
  isFetching: true,
  productId,
});

export const cancelAnytimeProductSuccess = (
  productId: ProductId,
): CancelAnytimeProductSuccess => ({
  type: 'CANCEL_ANYTIME_PRODUCT_SUCCESS',
  isFetching: false,
  productId,
});

export const cancelAnytimeProductError = (
  productId: ProductId,
  { response }: AjaxError,
): CancelAnytimeProductError => ({
  type: 'CANCEL_ANYTIME_PRODUCT_ERROR',
  isFetching: false,
  errorResponse: response,
  productId,
});

export const activateAnytimeProductEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  action$.ofType('ACTIVATE_ANYTIME_PRODUCT_START').switchMap(
    (
      // We cannot ts-ignore a block so we need to prevent prettier from wrapping the object
      // prettier-ignore
      // @ts-ignore not able to follow narrowing down of type through ofType
      { promoCode, displayName, options, source, additionalData, },
    ) => {
      const { selectedAccountId } = store.getState().user;
      if (!selectedAccountId) {
        return Observable.of(
          activateAnytimeProductError(
            // @ts-ignore
            { response: 'No account id set' },
            displayName,
          ),
        );
      }

      return apiService
        .post({
          responseType: 'json',
          withCredentials: true,
          url: createProductActivateEndpoint(selectedAccountId),
          body: {
            ...(promoCode ? { promoCode } : {}),
            ...(source ? { source } : {}),
            products: [
              {
                versionId: '4487c24c-6a8a-4e82-9a94-ee1169fbd635',
                ...options,
                additionalData,
              },
            ],
          },
        })
        .map(() => {
          return activateAnytimeProductSuccess(displayName);
        })
        .catch(err =>
          Observable.of(activateAnytimeProductError(err, displayName)),
        );
    },
  );

export const cancelAnytimeProductEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  action$
    .ofType('CANCEL_ANYTIME_PRODUCT_START')
    // @ts-ignore not able to follow narrowing down of type through ofType
    .switchMap(({ productId }) => {
      const { selectedAccountId } = store.getState().user;
      if (!selectedAccountId) {
        return Observable.of(
          cancelAnytimeProductError(
            productId,
            // @ts-ignore
            { response: 'No account id set' },
          ),
        );
      }
      return apiService
        .delete({
          responseType: 'json',
          withCredentials: true,
          url: createProductCancelEndpoint(selectedAccountId, productId),
        })
        .map(() => {
          return cancelAnytimeProductSuccess(productId);
        })
        .catch(err => Observable.of(cancelAnytimeProductError(productId, err)));
    });

// smart charger selection
export const changeSmartChargerSelection = (
  smartChargerSelection: SmartChargers,
): ChangeSmartChargerSelection => ({
  type: 'CHANGE_SMART_CHARGER_SELECTION',
  smartChargerSelection,
});

export const updateCarMake = (carMake: string | undefined): UpdateCarMake => ({
  type: 'UPDATE_CAR_MAKE',
  carMake,
});

export const updateCarModel = (
  carModel: string | undefined,
): UpdateCarModel => ({
  type: 'UPDATE_CAR_MODEL',
  carModel,
});

export const updateCarYear = (carYear: string | undefined): UpdateCarYear => ({
  type: 'UPDATE_CAR_YEAR',
  carYear,
});

export const updateInternetAccess = (
  internetAccess: boolean,
): UpdateInternetAccess => ({
  type: 'UPDATE_INTERNET_ACCESS',
  internetAccess,
});

export const updateSolarPanels = (solarPanels: boolean): UpdateSolarPanels => ({
  type: 'UPDATE_SOLAR_PANELS',
  solarPanels,
});

// reducer
export const defaultEvAnytimeState: EVAnytimeState = {
  smartChargerSelection: SmartChargers.Indra,
  carMake: undefined,
  carModel: undefined,
  carYear: undefined,
  internetAccess: true,
  solarPanels: false,
  activated: { isFetching: false },
  cancel: null,
  activate: null,
  isFetching: false,
};

const evAnytime = (
  state: EVAnytimeState = defaultEvAnytimeState,
  action: Action,
): EVAnytimeState => {
  switch (action.type) {
    case 'CHANGE_SMART_CHARGER_SELECTION':
      return {
        ...state,
        smartChargerSelection: action.smartChargerSelection,
      };
    case 'UPDATE_CAR_MAKE':
      return {
        ...state,
        carMake: action.carMake,
      };
    case 'UPDATE_CAR_MODEL':
      return {
        ...state,
        carModel: action.carModel,
      };
    case 'UPDATE_CAR_YEAR':
      return {
        ...state,
        carYear: action.carYear,
      };
    case 'UPDATE_INTERNET_ACCESS':
      return {
        ...state,
        internetAccess: action.internetAccess,
      };
    case 'UPDATE_SOLAR_PANELS':
      return {
        ...state,
        solarPanels: action.solarPanels,
      };
    case 'ACTIVATE_ANYTIME_PRODUCT_START': {
      const { isFetching, productId } = action;
      return {
        ...state,
        isFetching: true,
        activate: {
          [productId]: {
            isUpdating: isFetching,
            error: false,
            success: false,
          },
        },
      };
    }
    case 'ACTIVATE_ANYTIME_PRODUCT_SUCCESS': {
      const { isFetching, productId } = action;
      return {
        ...state,
        isFetching: false,
        activate: {
          [productId]: {
            isUpdating: isFetching,
            error: false,
            success: true,
          },
        },
      };
    }
    case 'ACTIVATE_ANYTIME_PRODUCT_ERROR': {
      const { isFetching, productId } = action;
      return {
        ...state,
        isFetching: false,
        activate: {
          [productId]: {
            isUpdating: isFetching,
            error: true,
            success: false,
          },
        },
      };
    }
    case 'CANCEL_ANYTIME_PRODUCT_START': {
      const { isFetching, productId } = action;
      return {
        ...state,
        cancel: {
          [productId]: {
            isUpdating: isFetching,
            error: false,
            success: false,
          },
        },
      };
    }
    case 'CANCEL_ANYTIME_PRODUCT_SUCCESS': {
      const { isFetching, productId } = action;
      return {
        ...state,
        cancel: {
          [productId]: {
            isUpdating: isFetching,
            error: false,
            success: true,
          },
        },
        activated: {
          ...state.activated,
          data: {
            boltons: state.activated.data
              ? state.activated.data.boltons.filter(
                  b => b.productId !== productId,
                )
              : [],
            bundle:
              state.activated.data &&
              state.activated.data.bundle &&
              state.activated.data.bundle.productId !== productId
                ? state.activated.data.bundle
                : undefined,
          },
        },
      };
    }
    case 'CANCEL_ANYTIME_PRODUCT_ERROR': {
      const { isFetching, productId } = action;
      return {
        ...state,
        cancel: {
          [productId]: {
            isUpdating: isFetching,
            error: true,
            success: false,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default evAnytime;
