export const homeProfileModalTestIDs = {
  form: {
    container: 'home-profile-form',
    step: (stepNumber: number) => `home-profile-form-step-${stepNumber}`,
    next: (stepNumber: number) => `home-profile-form-next-${stepNumber}`,
    previous: (stepNumber: number) =>
      `home-profile-form-previous-${stepNumber}`,
    submit: 'home-profile-form-submit',
    dismiss: 'nebula-modal-dismiss', // from design system
    privacyPolicyNotice: 'home-profile-form-privacy-policy-notice',
    privacyPolicyLink: 'home-profile-form-privacy-policy-link',
  },
};
