import React from 'react';
import NavDropdown from '../NavDropdown';
import NavLink from '../NavLink';
import generateAccountDropdownLinks from './generateAccountDropdownLinks';
import { MENU } from '@/src/constants/analytics';
import { ROUTE_PROFILE } from '@/src/constants/routes';

type Props = {
  path: string;
  closeMobileMenu: () => void;
  hasMultipleAccounts: boolean;
  hasLightBackground?: boolean;
};

export const AccountLinks = ({
  path,
  closeMobileMenu,
  hasMultipleAccounts,
  hasLightBackground,
}: Props) => {
  const links = generateAccountDropdownLinks({
    path,
    closeMobileMenu,
    hasMultipleAccounts,
  });

  return links.length > 1 ? (
    <NavDropdown
      hasLightBackground={hasLightBackground}
      dropdownAlignment="right"
      label="Account"
      listItems={links}
    />
  ) : (
    <NavLink
      data-event-name={MENU.PROFILE}
      hasLightBackground={hasLightBackground}
      path={path}
      route={ROUTE_PROFILE}
      label="Profile"
      onClick={closeMobileMenu}
    />
  );
};
