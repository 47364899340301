import {
  ErrorMessages,
  FormErrors,
  MeterDetailsForm,
  ValidatedForm,
} from '../../types/form';

const regexMpan = /^(\d{13})?$/;
const regexMprn = /\b(\d{7,10})\b/;

function isValidMPAN(mpan: string) {
  const primes = [3, 5, 7, 13, 17, 19, 23, 29, 31, 37, 41, 43];

  const sum = primes.reduce((sum, primeNumber, index) => {
    return sum + parseInt(mpan[index]) * primeNumber;
  }, 0);

  return (sum % 11) % 10 === parseInt(mpan[12]);
}

function isValidMPRN(mprn: string) {
  const numbersToCheckList = mprn.slice(0, -2).split('');
  const checkDigits = mprn.slice(-2);

  const sum = numbersToCheckList.reduce((sum, num, index) => {
    return sum + parseInt(num) * (numbersToCheckList.length - index);
  }, 0);

  return parseInt(checkDigits) === sum % 11;
}

type FormType = MeterDetailsForm;
export function validateMeterDetails(
  fuelType: 'Dual' | 'Electricity',
  form: Partial<FormType>,
): ValidatedForm<FormType> {
  const errors: FormErrors<FormType> = {};

  if (!form.mpan) {
    errors.mpan = ErrorMessages.REQUIRED;
  } else if (form.mpan.match(regexMpan) === null) {
    errors.mpan = ErrorMessages.MPAN_LENGTH;
  } else if (!isValidMPAN(form.mpan)) {
    errors.mpan = ErrorMessages.MPAN;
  }

  if (fuelType === 'Dual') {
    if (!form.mprn) {
      errors.mprn = ErrorMessages.REQUIRED;
    } else if (form.mprn.match(regexMprn) === null) {
      errors.mprn = ErrorMessages.MPRN_LENGTH;
    } else if (!isValidMPRN(form.mprn)) {
      errors.mprn = ErrorMessages.MPRN;
    }
  }

  return Object.keys(errors).length > 0
    ? {
        validation: 'error',
        errors,
      }
    : {
        validation: 'success',
        data: form as FormType,
      };
}
