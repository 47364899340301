import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 1fr min-content;
  justify-content: center;
  align-items: center;

  ${({ theme }) => `
    padding: ${theme.space[4]};
    grid-gap ${theme.space[4]};
    color: ${theme.colors.primaryContrast};
  `}
  ${mqMediumDown(`
    display: block
  `)}
`;

export const Title = styled.div`
  grid-column: 1/2;
  grid-row: 1/2;
`;

export const SubTitle = styled.div`
  grid-column: 1/2;
  grid-row: 2/2;
  ${({ theme: { colors } }) => `
    color: ${colors.primaryContrast};
  `};
  ${mqMediumDown(`
    grid-column: 1/3;
  `)}
`;

export const StyledSimplePageHeaderTitle = styled.h1`
  margin-bottom: 0;
  white-space: pre-wrap;
  ${({ theme: { colors, responsiveFontSizes } }) => `
    color: ${colors.primaryContrast};
    font-size: ${responsiveFontSizes.display[0]};
  `};
`;

export const StyledSimpleHeaderSubTitle = styled.p`
  white-space: pre-wrap;
  ${({ theme: { colors } }) => `
    color: ${colors.primaryContrast};
  `};
`;
