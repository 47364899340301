import styled from 'styled-components';
import rateUsOnTrustpilot from '@/src/resources/rate_us_on_trustpilot.jpg';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const ClippedImg = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url(${rateUsOnTrustpilot});
  background-size: 370px auto;
  clip-path: polygon(0% 0%, 23% 93%, 100% 66%, 100% 0%);

  ${mqMediumDown(`
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    background-size: cover;
    background-position: top;
  `)}
`;
