import { useQuery, UseQueryResult } from 'react-query';
import { createCollectableBalanceEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { CollectableBalanceResponse } from '@/src/types/Response';

type QueryCacheKey = `collectable-balance-${string}`;

interface Params {
  accountId: string | null | undefined;
}

export const useCollectableBalanceQuery = ({
  accountId,
}: Params): UseQueryResult<CollectableBalanceResponse> => {
  return useQuery(
    `collectable-balance-${accountId}` as QueryCacheKey,
    () =>
      apiService.get<CollectableBalanceResponse>({
        url: createCollectableBalanceEndpoint(accountId as string),
      }),
    {
      enabled: !!accountId,
    },
  );
};
