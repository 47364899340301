import styled from 'styled-components';
import {
  mqLargeUp,
  mqMediumDown,
  mqMediumToLarge,
  mqMediumUp,
  mqSmallUp,
} from '@/src/utils/mediaQuery';

const imageLargeHeight = 650;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${mqMediumUp(`
    flex-direction: row-reverse;
  `)}

  ${mqLargeUp(`
    clip-path: polygon(0 0, 100% 0, 100% 58%, 0 100%);
  `)}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  ${mqMediumToLarge(`
    width: 120%;
  `)}
`;

export const Content = styled.div`
  ${({ theme: { space } }) => `
    flex: 1;
    padding-left: ${space[4]};
    padding-right: ${space[4]};
    padding-bottom: 50px;

    ${mqSmallUp(`
      padding-left: ${space[6]};
      padding-right: ${space[6]};
    `)}

    ${mqMediumUp(`
      padding-left: ${space[8]};
      padding-right: 0;
      clip-path: none;
    `)}

    ${mqMediumDown(`
      -ms-flex: 1 1 auto;
    `)}

    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    ${mqLargeUp(`
      height: ${imageLargeHeight}px;
      padding-bottom: 100px;
    `)}
  `}
`;

export const LargeAndUpContentContainer = styled.div`
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  width: 100%;

  ${mqLargeUp(`
    display: block;
  `)}
`;
