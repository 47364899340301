import {
  Heading2,
  Margin,
  P,
  Strong,
  SecondaryCTALink,
  TextLink,
} from '@ovotech/nebula';
import React from 'react';

import { FootnoteReferenced } from '@/src/components';

import {
  GreyCard,
  PracticalStepsCustomCard,
  PracticalStepsCustomCardItems,
  Subheader,
} from './PracticalStepsCard.styled';
import { SmartThermostatsPricing } from '@/src/types/Response';
import {
  OrderType,
  PaymentType,
} from '@/src/pages/SmartThermostats/utils/types';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { getBoltonPrice } from '../../utils/utils';
import { TADO_GUIDE_LINK } from '@/src/constants/routes';
import { BuyNowButton } from '../BuyNowButton/BuyNowButton';
import { tadoRRP } from '@/src/pages/SmartThermostats/constants';

interface Props {
  pricing: SmartThermostatsPricing;
}

function InstallingYourselfFootnote() {
  return (
    <FootnoteReferenced
      content={{
        id: 'InstallingYourself',
        body: [
          <>
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED
                  .HOW_TO_INSTALL
              }
            >
              <TextLink href={TADO_GUIDE_LINK}>
                How to install the Wireless Smart Thermostat yourself.
              </TextLink>
            </AnalyticsClick>
          </>,
        ],
      }}
    />
  );
}

export const PracticalStepsCard = ({ pricing }: Props) => {
  const upfrontTotal = getBoltonPrice(
    pricing,
    PaymentType.FullAmountPayment,
    OrderType.SelfInstall,
  );
  const monthlyPaymentAmount =
    getBoltonPrice(pricing, PaymentType.MonthlyPayment, OrderType.SelfInstall) /
    12;

  return (
    <PracticalStepsCustomCard>
      <Heading2>The practical stuff</Heading2>
      <PracticalStepsCustomCardItems>
        <GreyCard>
          <div>
            <Heading2>
              £{upfrontTotal}
              <Subheader>(RRP £{tadoRRP})</Subheader>
            </Heading2>
            <Margin>
              <P>
                <Strong>Pay in full or monthly</Strong>
              </P>
            </Margin>
            <Margin>
              <P>
                Pay £{upfrontTotal} now or £{monthlyPaymentAmount} a month over
                12 months, we'll just add this to your monthly bill.
              </P>
            </Margin>
          </div>
          <Margin top={7}>
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED
                  .BUY_NOW
              }
              properties={{ buttonPlacement: 'practical-stuff' }}
            >
              <BuyNowButton />
            </AnalyticsClick>
          </Margin>
        </GreyCard>
        <GreyCard>
          <Heading2>Set it up yourself</Heading2>
          <Margin>
            <P>
              <Strong>
                95% of people fit their own tado°
                <InstallingYourselfFootnote />
              </Strong>
            </P>
          </Margin>
          <Margin>
            <P>
              If you’re unsure and would like an engineer to do it for you, get
              in touch with your local heating engineer.
            </P>
          </Margin>
          <Margin top={7}>
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED
                  .INSTALL_GUIDE
              }
            >
              <SecondaryCTALink
                opensInNewWindow
                href="https://www.ovoenergy.com/guides/energy-guides/how-to-install-a-tado-wireless-smart-thermostat.html"
              >
                Install guide
              </SecondaryCTALink>
            </AnalyticsClick>
          </Margin>
        </GreyCard>
      </PracticalStepsCustomCardItems>
    </PracticalStepsCustomCard>
  );
};
