import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

const ToggleContainer = styled.div`
  width: 150px;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2px;
  margin-top: 12px;

  div:first-child {
    ${({ theme: { colors } }) => css`
      border-right: 1px solid ${colors.brand.primary.base};
    `}
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ToggleButton = styled.div<{ selected: boolean; greyedOut?: boolean }>`
  width: 50%;
  height: 100%;
  border: 1px solid
    ${props =>
      props.greyedOut ? 'grey' : props.theme.colors.brand.primary.base};
  background: ${props =>
    props.selected ? props.theme.colors.brand.primary.base : null};
  color: ${props =>
    props.greyedOut
      ? 'grey'
      : props.selected
      ? '#fff'
      : props.theme.colors.brand.primary.base};
`;

type Props = {
  showPrice: boolean;
  setShowPrice: React.Dispatch<React.SetStateAction<boolean>>;
  lastWeekHasCost: boolean;
  showLastWeek: boolean;
};

const TogglePrice = ({
  showPrice,
  setShowPrice,
  lastWeekHasCost,
  showLastWeek,
}: Props) => {
  const isCostInactive = showLastWeek && !lastWeekHasCost;
  const isCostSelected = showPrice && !isCostInactive;

  useEffect(() => {
    if (isCostInactive) {
      setShowPrice(false);
    }
  }, [isCostInactive, setShowPrice]);

  return (
    <ToggleContainer role="radiogroup" data-testid="cost-toggle">
      <ToggleButton
        role="radio"
        aria-label="kilowatt hours"
        aria-checked={!isCostSelected}
        onClick={() => setShowPrice(false)}
        selected={!isCostSelected}
        data-testid="cost-toggle-button-kwh"
      >
        kWh
      </ToggleButton>
      <ToggleButton
        role="radio"
        aria-label="cost"
        aria-checked={isCostSelected}
        onClick={() => setShowPrice(!isCostInactive)}
        selected={isCostSelected}
        greyedOut={isCostInactive}
        data-testid="cost-toggle-button-cost"
      >
        Cost
      </ToggleButton>
    </ToggleContainer>
  );
};

export default TogglePrice;
