import { homeProfileModalTestIDs as testIDs } from '../testIDs';

enum FieldSetname {
  propertyType = 'propertyType',
  numBedrooms = 'numBedrooms',
  numOccupants = 'numOccupants',
  averageHoursAtHome = 'averageHoursAtHome',
  homeHeat = 'homeHeat',
  appliances = 'appliances',
  extraAppliances = 'extraAppliances',
  numFridgesOrFreezers = 'numFridgesOrFreezers',
}

type StepConfigBase = {
  step: number;
  question: string;
  description?: string;
  fieldSetName: FieldSetname;
  optional: boolean;
};

const questionBaseConfig: StepConfigBase[] = [
  {
    step: 1,
    question: 'What kind of home do you have?',
    description:
      'This gives us an idea of how much energy your home uses. We can then compare that energy use with other homes like yours.',
    fieldSetName: FieldSetname.propertyType,
    optional: false,
  },
  {
    step: 2,
    question: 'How many bedrooms do you have in your home?',
    fieldSetName: FieldSetname.numBedrooms,
    optional: false,
  },
  {
    step: 3,
    question: 'How many people live in your home?',
    fieldSetName: FieldSetname.numOccupants,
    optional: false,
  },
  {
    step: 4,
    question: 'On an average working day, how many hours is someone home?',
    fieldSetName: FieldSetname.averageHoursAtHome,
    optional: false,
  },
  {
    step: 5,
    question: 'Which of these do you use the most to heat your home?',
    fieldSetName: FieldSetname.homeHeat,
    optional: false,
  },
  {
    step: 6,
    question: 'Which of these appliances do you have in your home?',
    description:
      'You can select more than one option. So if you have a washer dryer, please select washing machine AND tumble dryer.',
    fieldSetName: FieldSetname.appliances,
    optional: true,
  },
  {
    step: 7,
    question: 'Do you use any of these?',
    description: 'You can select more than one option.',
    fieldSetName: FieldSetname.extraAppliances,
    optional: true,
  },
  {
    step: 8,
    question: 'How many fridges and freezers do you have?',
    description: 'A fridge-freezer counts as one.',
    fieldSetName: FieldSetname.numFridgesOrFreezers,
    optional: false,
  },
];

const questionSteps = questionBaseConfig.map(stepConfig => ({
  ...stepConfig,
  label: `${stepConfig.step}. ${stepConfig.question}`,
  testId: testIDs.form.step(stepConfig.step),
}));

export const homeProfileConfig = {
  getStepTestId: testIDs.form.step,
  getQuestionStepConfig: (step: number) => questionSteps[step - 1],
  numberOfQuestions: questionSteps.length,
  allQuestionSteps: questionSteps,
};
