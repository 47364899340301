import { Card, Heading1, Heading3, Margin, P, Strong } from '@ovotech/nebula';
import Big from 'big.js';
import { format } from 'date-fns';
import React, { Fragment } from 'react';
import { SelectorData } from '../selector';
import {
  RecommendedDDCalculationItem,
  RecommendedDDCalculationSign,
  RecommendedDDCalculationTitle,
  RecommendedDDCalculationWrapper,
  RecommendedDDDescription,
} from './RecommendedDirectDebitInfo.styled';
import { FormattedAmount } from '@/src/components';
import { RecommendedDirectDebitResponse } from '@/src/types/Response';
import { getEpochDaysDate } from '@/src/utils/date';
import {
  formatCurrencyValue,
  getCurrencyValueAsNumber,
} from '@/src/utils/money';

export type Props = SelectorData;

const recommendationCalculationValues = (
  recommendedDirectDebit: RecommendedDirectDebitResponse,
) => {
  const { balance, predictedEnergyCost, predictedBoltOnCost } =
    recommendedDirectDebit;

  const numBalance = getCurrencyValueAsNumber({ unit: 'GBP' }, balance);
  const numPredictedEnergyCost = getCurrencyValueAsNumber(
    { unit: 'GBP' },
    predictedEnergyCost,
  );
  const numPredictedBoltOnCost = getCurrencyValueAsNumber(
    { unit: 'GBP' },
    predictedBoltOnCost,
  );

  const costIncBoltOnsDisplay = formatCurrencyValue(
    { unit: 'GBP', dp: 2 },
    {
      amount: Big(numPredictedEnergyCost + numPredictedBoltOnCost).toFixed(2),
      currencyUnit: 'GBP',
    },
  );

  const totalAmountIncBoltOns = formatCurrencyValue(
    { unit: 'GBP', dp: 2 },
    {
      amount: Big(numPredictedEnergyCost + numPredictedBoltOnCost)
        .minus(numBalance)
        .toFixed(2),
      currencyUnit: 'GBP',
    },
  );
  return { costIncBoltOnsDisplay, totalAmountIncBoltOns, numBalance };
};

export const RecommendedDirectDebitInfo = ({
  planExpiryDate,
  recommendedDirectDebit,
}: Props) => {
  if (recommendedDirectDebit == null || planExpiryDate == null) {
    return null;
  }

  const { amount, friendlyAmount, balance, remainingDirectDebits, updatedAt } =
    recommendedDirectDebit;

  const { totalAmountIncBoltOns, costIncBoltOnsDisplay, numBalance } =
    recommendationCalculationValues(recommendedDirectDebit);

  return (
    <Fragment>
      <Margin bottom={6}>
        <Card>
          <Heading1>
            How we work out your recommended Direct&nbsp;Debit
          </Heading1>
          <P>
            We estimate the cost of your energy use between{' '}
            {format(updatedAt, 'Do MMMM YYYY')} and your plan anniversary (when
            you started your plan) – including any changes you made to your plan
            (like upgrades). We take into account any positive or negative
            balance you might have, and divide everything by your remaining
            months.
          </P>
        </Card>
      </Margin>
      <Card>
        <Heading3 as="h2">The sums made simpler</Heading3>
        <RecommendedDDCalculationWrapper>
          <RecommendedDDCalculationItem data-testid="recommended-dd-predicted-balance-info">
            <FormattedAmount amount={costIncBoltOnsDisplay} />

            <RecommendedDDCalculationTitle as={Strong}>
              Estimated costs + changes to your plan
            </RecommendedDDCalculationTitle>

            <RecommendedDDDescription>
              between {format(updatedAt, 'Do MMMM YYYY')} and the end of your
              contract
            </RecommendedDDDescription>
          </RecommendedDDCalculationItem>
          {numBalance > 0 ? (
            <RecommendedDDCalculationSign aria-label="minus">
              &minus;
            </RecommendedDDCalculationSign>
          ) : (
            <RecommendedDDCalculationSign aria-label="plus">
              &#43;
            </RecommendedDDCalculationSign>
          )}
          <RecommendedDDCalculationItem>
            <FormattedAmount
              amount={formatCurrencyValue({ unit: 'GBP', abs: true }, balance)}
            />
            <div data-testid="recommended-dd-balance-description-text">
              in {numBalance > 0 ? 'credit' : 'debit'}
            </div>
            <RecommendedDDCalculationTitle as={Strong}>
              Account balance
            </RecommendedDDCalculationTitle>
            <RecommendedDDDescription>
              when you last made a payment
            </RecommendedDDDescription>
          </RecommendedDDCalculationItem>
          <RecommendedDDCalculationSign aria-label="equals">
            =
          </RecommendedDDCalculationSign>
          <RecommendedDDCalculationItem>
            <FormattedAmount amount={totalAmountIncBoltOns} />
            <RecommendedDDCalculationTitle as={Strong}>
              Total amount
            </RecommendedDDCalculationTitle>
            <RecommendedDDDescription>
              to be covered by your remaining payments
            </RecommendedDDDescription>
          </RecommendedDDCalculationItem>
        </RecommendedDDCalculationWrapper>
      </Card>
      <Margin top={6}>
        <P>
          We divide this new amount by the number of payments remaining until
          your end of contract or plan anniversary to set a recommended Direct
          Debit. We’re aiming for your balance to be as close to zero as
          possible by {format(getEpochDaysDate(planExpiryDate), 'Do MMMM YYYY')}
          .
        </P>
      </Margin>
      <Card>
        <Heading3 as="h2">Recommended Direct Debit</Heading3>
        <RecommendedDDCalculationWrapper>
          <RecommendedDDCalculationItem>
            <FormattedAmount amount={totalAmountIncBoltOns} />
            <RecommendedDDCalculationTitle as={Strong}>
              Total amount
            </RecommendedDDCalculationTitle>
            <RecommendedDDDescription>
              to be covered by your remaining payments
            </RecommendedDDDescription>
          </RecommendedDDCalculationItem>
          <RecommendedDDCalculationSign aria-label="divided by">
            &divide;
          </RecommendedDDCalculationSign>
          <RecommendedDDCalculationItem>
            <FormattedAmount
              data-testid="remaining-months"
              amount={String(remainingDirectDebits.count)}
            />
            <RecommendedDDCalculationTitle as={Strong}>
              Monthly payments
            </RecommendedDDCalculationTitle>
            <RecommendedDDDescription>
              remaining on your contract
            </RecommendedDDDescription>
          </RecommendedDDCalculationItem>
          <RecommendedDDCalculationSign aria-label="equals">
            =
          </RecommendedDDCalculationSign>
          <RecommendedDDCalculationItem>
            <FormattedAmount
              amount={formatCurrencyValue(
                { unit: 'GBP', dp: 0 },
                friendlyAmount || amount,
              )}
            />
            <RecommendedDDCalculationTitle as={Strong}>
              Recommended Direct Debit
            </RecommendedDDCalculationTitle>
          </RecommendedDDCalculationItem>
        </RecommendedDDCalculationWrapper>
      </Card>
      <Margin top={6}>
        <P>
          This is just an estimation – based on your energy use and costs so
          far. Your final balance will depend on how much you’ll actually use by
          the end of your year with us.
        </P>
      </Margin>
    </Fragment>
  );
};

export default RecommendedDirectDebitInfo;
