import Big from 'big.js';
import format from 'date-fns/format';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Text,
  TextProps,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { withTheme } from 'styled-components';

import ResponsiveChartWrapper, {
  ResponsiveChartRenderProps,
} from '../ResponsiveChartWrapper/ResponsiveChartWrapper';
import {
  StyledProjectedBalanceChart,
  StyledProjectedBalanceTooltip,
} from './ProjectedBalanceChart.styled';
import { Theme } from '@/src/types/Theme';

import { breakpoints } from '@/src/utils/mediaQuery';
import { formatBalance, formatCurrencyValue } from '@/src/utils/money';
import setSvgAttributes from '@/src/utils/set-svg-attributes';

type ProjectedBalanceChartData = {
  balance: Big;
  date: string;
};

type Props = {
  data: Array<ProjectedBalanceChartData>;
  theme: Theme;
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: Array<{ payload?: ProjectedBalanceChartData }>;
}) => {
  if (!active || !payload || !payload[0]?.payload) {
    return null;
  }

  const { date, balance } = payload[0].payload;

  return (
    <StyledProjectedBalanceTooltip>
      <dl>
        <dt>{format(date, 'MMMM YYYY')}</dt>
        <br />
        <dd>
          Balance at the end of the month:
          <br />
          {formatBalance({ amount: balance.toString(), currencyUnit: 'GBP' })}
        </dd>
      </dl>
    </StyledProjectedBalanceTooltip>
  );
};

type MonthTickProps = TextProps & {
  payload: {
    value: string;
  };
};

const MonthTick = (isFlipped: boolean) => (props: MonthTickProps) =>
  (
    // @ts-ignore react-18 children props
    <Text {...props} verticalAnchor="middle" angle={!isFlipped ? -60 : 0}>
      {format(props.payload.value, 'MMM')}
    </Text>
  );

class ProjectedBalanceChart extends React.Component<Props> {
  // eslint-disable-next-line react/sort-comp
  wrapper: null | HTMLElement = null;

  componentDidMount() {
    setTimeout(() => {
      setSvgAttributes(
        this.wrapper,
        'Bar graph showing monthly estimated end of month balance',
      );
    }, 1000);
  }

  componentDidUpdate() {
    setSvgAttributes(
      this.wrapper,
      'Bar graph showing monthly estimated end of month balance',
    );
  }

  getChartProps = (
    isFlipped: boolean,
    data: Array<ProjectedBalanceChartData>,
  ) => {
    const isSmallToMedium = window.matchMedia(
      breakpoints.smallToMedium,
    ).matches;

    const commonAxisProps = {
      tickLine: false,
      axisLine: false,
    };

    const timeAxisProps: XAxisProps | YAxisProps = {
      ...commonAxisProps,
      dataKey: 'date',
      interval: isSmallToMedium ? 1 : 0,
      tickMargin: isFlipped ? undefined : 12,
      type: 'category',
      tick: MonthTick(isFlipped),
    };

    const maxBalanceLength = [...data].reduce(
      (p, c) => Math.max(p, c.balance.toFixed(0).length),
      0,
    );

    const balanceAxisProps: XAxisProps | YAxisProps = {
      ...commonAxisProps,
      tickFormatter: (value: any) =>
        formatCurrencyValue(
          { unit: 'GBP', dp: 0 },
          { amount: value, currencyUnit: 'GBP' },
        ),
      domain: [
        (dataMin: number) => {
          return Math.floor(dataMin) - Math.abs(dataMin * 0.1);
        },
        (dataMax: number) => {
          return Math.ceil(dataMax) + Math.abs(dataMax * 0.1);
        },
      ],
      interval: 0,
      scale: 'linear',
      type: 'number',
      width: (maxBalanceLength + 1) * 10,
    };

    return { balanceAxisProps, timeAxisProps };
  };

  setWrapper = (wrapper: HTMLElement) => {
    this.wrapper = wrapper;
  };

  getChart({
    isFlipped,
    keyAxisProps,
    valueAxisProps,
  }: ResponsiveChartRenderProps) {
    const {
      data,
      theme: { colors },
    } = this.props;

    const { balanceAxisProps, timeAxisProps } = this.getChartProps(
      isFlipped,
      data,
    );

    const balanceProps = {
      ...valueAxisProps,
      ...balanceAxisProps,
    };

    const timeProps = {
      ...keyAxisProps,
      ...timeAxisProps,
    };

    // tslint:disable-next-line no-object-literal-type-assertion
    const xAxisProps: XAxisProps = {
      ...(isFlipped ? balanceProps : timeProps),
      padding: {
        right: isFlipped ? 8 : 0,
      },
    } as XAxisProps;

    // tslint:disable-next-line no-object-literal-type-assertion
    const yAxisProps: YAxisProps = {
      ...(isFlipped ? timeProps : balanceProps),
      width: isFlipped ? 40 : balanceProps.width,
    } as YAxisProps;

    const normalizedData = data.map(d => ({
      date: d.date,
      balance: Number(d.balance.toFixed(2)),
    }));

    return (
      <BarChart
        layout={isFlipped ? 'vertical' : 'horizontal'}
        data={normalizedData}
      >
        <CartesianGrid
          horizontal={!isFlipped}
          vertical={isFlipped}
          stroke={colors.chartCartesianGrid}
        />
        <Bar
          layout={isFlipped ? 'horizontal' : 'vertical'}
          dataKey="balance"
          fill={colors.primary}
          isAnimationActive={false}
        />
        <Tooltip content={CustomTooltip} />
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />
      </BarChart>
    );
  }

  render() {
    return (
      <StyledProjectedBalanceChart aria-hidden="true">
        <ResponsiveChartWrapper flipBreakpoint={breakpoints.smallDown}>
          {renderProps => (
            <ResponsiveContainer
              width="100%"
              height={renderProps.isFlipped ? 500 : 300}
            >
              {this.getChart(renderProps)}
            </ResponsiveContainer>
          )}
        </ResponsiveChartWrapper>
      </StyledProjectedBalanceChart>
    );
  }
}

export default withTheme(ProjectedBalanceChart);
