import { Heading4 } from '@ovotech/nebula';
import styled from 'styled-components';
import { styledOutline } from '@/src/components/styled-utils';

export const StyledCollapsibleContentHeader = styled(Heading4)`
  ${({ theme: { space } }) => `
    line-height: 1;
    margin: 0;
    padding: ${space[2]} 0 ${space[8]} 0
  `}
`;

export const StyledCollapsibleContentButton = styled.button<{
  arrowPosition?: 'left' | 'right';
}>`
  ${({ arrowPosition = 'right', theme: { colors, space } }) => `
    font-size: inherit;
    display: block;
    width: 100%;
    text-align: inherit;
    background: transparent;
    border: none;
    padding-left: ${arrowPosition === 'left' ? space[8] : 0};
    font-weight: inherit;
    color: inherit;
    position: relative;

    &:focus {
      ${styledOutline(colors.primary)}
    }
  `}
`;

export const StyledCollapsibleContentIconWrapper = styled.div<{
  arrowPosition?: 'left' | 'right';
}>`
  ${({ arrowPosition = 'right', theme: { space } }) => `
    width: ${space[6]};
    position: absolute;
    ${arrowPosition}: ${arrowPosition === 'left' ? 0 : space[4]};
    top: 50%;
    transform: translateY(-50%);
  `}
`;

export const StyledCollapsibleContentPanel = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[4]} 0;

    > *:last-child {
      margin-bottom: 0;
    }
  `}
`;

export const StyledCollapsibleContentSection = styled.div<{
  isNested?: boolean;
  noPadding?: boolean;
}>`
  ${({ isNested, noPadding, theme: { space } }) => `
    background: white;
    padding: ${noPadding ? '0' : space[6]} ${
    isNested || noPadding ? '0' : space[8]
  };
  `}
`;
