import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSmartThermostatsTadoInfoStart } from '@/src/redux/ducks/smartThermostats/smart-thermostats-info';

import { State } from '@/src/types/State';

export function getTadoInfo() {
  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { tadoInfo, tadoStatus, errorResponse, isFetching } = useSelector(
    ({ smartThermostats }: State) => smartThermostats,
  );

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (tadoInfo === null && tadoStatus && !isFetching && !errorResponse) {
      if (tadoStatus.connected) {
        dispatch(getSmartThermostatsTadoInfoStart());
      }
    }
  }, [dispatch, errorResponse, isFetching, tadoInfo, tadoStatus]);

  return { tadoInfo, errorResponse };
}
