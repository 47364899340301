import { Heading1, Heading4, Li, Margin, TextLink, Ul } from '@ovotech/nebula';
import React, { Fragment } from 'react';

import { tadoRRP, TADO_STUDY } from '../constants';
import { OrderType, PaymentType } from '../utils/types';
import { getBoltonPrice, hasBlackFridayDiscount } from '../utils/utils';
import { ActionSegment } from './ActionSegment';
import {
  FlexWrapper,
  StyledHeroImage,
  StyledHeroImageWrapper,
  StyledHeroTextWrapper,
  StyledOfferThermostatsHeaderBg,
  StyledOfferThermostatsHeaderBgWrapper,
  WhiteHeading,
  WhiteText,
} from './ProductContent.styled';

import { FootnoteReferenced } from '@/src/components';
import { SmartThermostatsPricing } from '@/src/types/Response';
import TadoImage from '@/src/resources/tado-hero-image.png';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';

export const ProductHero = ({
  eligible,
  goToCompatibilityStep,
  pricing,
  hasDiscount,
  promoCode,
}: {
  eligible: boolean | undefined;
  goToCompatibilityStep: () => void;
  pricing: SmartThermostatsPricing;
  hasDiscount: boolean;
  promoCode?: string;
}) => {
  const upfrontTotal = getBoltonPrice(
    pricing,
    PaymentType.FullAmountPayment,
    OrderType.SelfInstall,
  );

  const rrpSaving = (tadoRRP - upfrontTotal).toFixed(2);

  const blackFridayDiscountApplied = hasBlackFridayDiscount(
    hasDiscount,
    promoCode,
  );

  const getHeading = () => {
    if (blackFridayDiscountApplied)
      return (
        <>
          Nab a wireless tadoº for less than half price{' '}
          <FootnoteReferenced
            content={{
              id: 'Price',
              body: [
                <Fragment key={'RRP'}>
                  Saving based on the manufacturer's recommended retail price of
                  £${tadoRRP}.
                </Fragment>,
              ],
            }}
          />
        </>
      );
    return <>Stay comfy. Save energy. Simple.</>;
  };

  return (
    <>
      <StyledOfferThermostatsHeaderBgWrapper>
        <StyledOfferThermostatsHeaderBg />
      </StyledOfferThermostatsHeaderBgWrapper>
      <FlexWrapper>
        <StyledHeroImageWrapper>
          <StyledHeroImage src={TadoImage} />
        </StyledHeroImageWrapper>
        <StyledHeroTextWrapper>
          <WhiteHeading as={Heading1}>{getHeading()}</WhiteHeading>
          <WhiteText as={Heading4}>
            {blackFridayDiscountApplied
              ? 'Treat yourself to carbon-cutting tech this Green Friday'
              : 'Meet the Wireless tadoº Smart Thermostat'}
          </WhiteText>
          <Margin bottom={6}>
            <Ul>
              <WhiteText as={Li}>
                Control your heating and hot water wherever you are
              </WhiteText>
              <WhiteText as={Li}>
                Reduce the energy your heating uses - by up to 31%
                <FootnoteReferenced
                  content={{
                    id: 'IndependentStudy',
                    body: [
                      <Fragment key="IndependentStudy">
                        An{' '}
                        <AnalyticsClick
                          name={
                            SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE
                              .CLICKED.INDEPENDENT_STUDY
                          }
                        >
                          <TextLink href={TADO_STUDY}>
                            independent study
                          </TextLink>
                        </AnalyticsClick>{' '}
                        has shown that installing and correctly using a tado°
                        smart thermostat can reduce your energy use from heating
                        by up to 28%. The study found that the tado° device
                        could save you up to 23% by turning the temperature down
                        when nobody is at home. You could also save another 6%
                        by turning down the heating based on the weather
                        forecast, depending on the window sizes. If the window
                        surfaces are relatively large, the energy saving will
                        increase.
                      </Fragment>,
                    ],
                  }}
                />
              </WhiteText>
              <WhiteText as={Li}>
                Exclusive price for OVO members of £{upfrontTotal} (saving of £
                {rrpSaving}){' '}
                <FootnoteReferenced
                  content={{
                    id: 'Price2',
                    body: [
                      <>
                        Saving based on the manufacturer's recommended retail
                        price of £{tadoRRP}."
                      </>,
                    ],
                  }}
                />
              </WhiteText>
            </Ul>
          </Margin>
          <ActionSegment
            eligible={eligible}
            goToCompatibilityStep={goToCompatibilityStep}
            pricing={pricing}
          />
        </StyledHeroTextWrapper>
      </FlexWrapper>
    </>
  );
};
