import { mqMediumUp, mqSmallDown } from '@/src/utils/mediaQuery';
import styled from 'styled-components';

export const StyledGraph = styled.div<{
  isHidden?: boolean;
}>`
  ${({ isHidden }) => `display: ${isHidden ? 'none' : 'block'};`}
  font-size: 1.4rem;
  .recharts-label {
    font-weight: bold;
  }
  svg.recharts-surface tspan {
    font-size: 1.2rem;
  }
  ${({ theme: { space } }) => `
      .recharts-legend-wrapper {
        padding-top: ${space[5]};
      }
      .recharts-legend-item {
        padding-left: ${space[4]};
        &:first-of-type {
          padding-left: ${space[9]};
        }
      }
    `}
`;

export const StyledGraphCard = styled.div`
  border-radius: 0;
`;

export const StyledToggleButton = styled.button`
  ${({ theme: { fontSizes } }) => `
    ${mqMediumUp(`
      margin: 0 auto;
    `)}
    ${mqSmallDown(`
      font-size: 1.4rem;
    `)}
    font-size: ${fontSizes[2]};
    color: #0D8426;
    text-decoration: underline;
    text-decoration-color: #0D8426;
    display: block;
    background: inherit;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  `}
`;

export const StyledToggleButtonContent = styled.span`
  position: relative;
  display: flex;
`;

export const StyledToggleButtonLabel = styled.span`
  ${({ theme: { space } }) => `
    padding-right: ${space[2]};
  `}
`;
