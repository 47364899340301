import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import {
  makeActionError,
  makeActionStart,
  makeActionSuccess,
} from '../../../redux/ducks/http';
import {
  checkProfessionalInstallEligibility,
  checkPayMonthlyEligibility,
  checkThermostatEligibility,
} from './check-eligibility';
import {
  extendDateObj,
  getSmartThermostatsData,
  getSmartThermostatsSavings,
  getSmartThermostatsTadoInfo,
  getSmartThermostatsTadoLink,
  getSmartThermostatsTadoStatus,
  updateData,
  getSmartThermostatsOrderInfo,
  getSmartThermostatsPricing,
} from './get-data';
import {
  callOrdersAPI,
  callWaitlistAPI,
  callWaitlistStatusAPI,
} from './sales-journey';
import {
  OrderType,
  PaymentType,
} from '@/src/pages/SmartThermostats/utils/types';
import { selectThermostatBolton } from '@/src/pages/SmartThermostats/utils/bolton';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import {
  Action,
  AddAccountToThermostatsWaitlistError,
  AddAccountToThermostatsWaitlistStart,
  AddAccountToThermostatsWaitlistSuccess,
  AddSmartThermostatsIneligibilityReason,
  CheckAccountWaitlistStatusError,
  CheckAccountWaitlistStatusStart,
  CheckAccountWaitlistStatusSuccess,
  CheckEligibilityForSmartThermostatError,
  CheckEligibilityForSmartThermostatProfessionalInstallError,
  CheckEligibilityForSmartThermostatProfessionalInstallStart,
  CheckEligibilityForSmartThermostatProfessionalInstallSuccess,
  CheckEligibilityForSmartThermostatStart,
  CheckEligibilityForSmartThermostatSuccess,
  FETCH_ACTION_KEY,
  GetSmartThermostatsInfoError,
  GetSmartThermostatsInfoStart,
  GetSmartThermostatsInfoSuccess,
  GetSmartThermostatsSavingsError,
  GetSmartThermostatsSavingsStart,
  GetSmartThermostatsSavingsSuccess,
  GetSmartThermostatsTadoInfoError,
  GetSmartThermostatsTadoInfoStart,
  GetSmartThermostatsPricingStart,
  GetSmartThermostatsTadoInfoSuccess,
  GetSmartThermostatsPricingSuccess,
  GetSmartThermostatsTadoLinkError,
  GetSmartThermostatsTadoLinkStart,
  GetSmartThermostatsTadoLinkSuccess,
  GetSmartThermostatsTadoStatusError,
  GetSmartThermostatsTadoStatusStart,
  GetSmartThermostatsTadoStatusSuccess,
  StoreOrderType,
  RegisterSmartThermostatsOrderError,
  RegisterSmartThermostatsOrderStart,
  RegisterSmartThermostatsOrderSuccess,
  RegisterSmartThermostatsOrderRequiresAction,
  StoreEligibilityQuestionsAnswer,
  SetEligibilityForSmartThermostatProfessionalInstall,
  StorePaymentType,
  CheckEligibilityForSmartThermostatPayMonthlyStart,
  CheckEligibilityForSmartThermostatPayMonthlySuccess,
  CheckEligibilityForSmartThermostatPayMonthlyError,
  UnsetPaymentStatusAndSecret,
  GetSmartThermostatsOrderInfoStart,
  GetSmartThermostatsOrderInfoSuccess,
  GetSmartThermostatsOrderInfoError,
  GetSmartThermostatsPricingError,
} from '@/src/types/Action';
import {
  SmartThermostatsTadoInfo,
  SmartThermostatsTadoStatus,
  SmartThermostatsOrderInfo,
  SmartThermostatsPricing,
  DirectDebitResponse,
  ProfileResponse,
  ContractsResponse,
} from '@/src/types/Response';
import {
  Eligibility,
  IneligibilityReasons,
  MonthYear,
  SmartThermostatsChartDataSeriesObj,
  SmartThermostatsInfoState,
  State,
  ToolTipDates,
} from '@/src/types/State';
import { ProfileNextV1Query } from '@/src/api/kapi/__generated__/graphql';

export const getSmartThermostatsInfoStart = (
  startDate: MonthYear,
  endDate: MonthYear,
  granularity: 'month' | 'day',
): GetSmartThermostatsInfoStart => ({
  type: 'GET_SMART_THERMOSTATS_INFO_START',
  isFetching: true,
  startDate,
  endDate,
  granularity: granularity,
});

const getSmartThermostatsDataSuccess = makeActionSuccess<
  GetSmartThermostatsInfoSuccess,
  [SmartThermostatsChartDataSeriesObj, ToolTipDates]
>((data, toolTips) => {
  return {
    type: 'GET_SMART_THERMOSTATS_INFO_SUCCESS',
    data,
    toolTips,
  };
});

const getSmartThermostatsDataError =
  makeActionError<GetSmartThermostatsInfoError>(() => ({
    type: 'GET_SMART_THERMOSTATS_INFO_ERROR',
  }));

const getSmartThermostatsSavingsSuccess = makeActionSuccess<
  GetSmartThermostatsSavingsSuccess,
  [string]
>(savings => {
  return {
    type: 'GET_SMART_THERMOSTATS_SAVINGS_SUCCESS',
    savings,
  };
});

const getSmartThermostatsSavingsError =
  makeActionError<GetSmartThermostatsSavingsError>(() => ({
    type: 'GET_SMART_THERMOSTATS_SAVINGS_ERROR',
  }));

const getSmartThermostatsTadoLinkSuccess = makeActionSuccess<
  GetSmartThermostatsTadoLinkSuccess,
  [string]
>(link => {
  return {
    type: 'GET_SMART_THERMOSTATS_TADO_LINK_SUCCESS',
    link,
  };
});

const getSmartThermostatsTadoLinkError =
  makeActionError<GetSmartThermostatsTadoLinkError>(() => ({
    type: 'GET_SMART_THERMOSTATS_TADO_LINK_ERROR',
  }));

export const checkEligibilityForSmartThermostatStart =
  makeActionStart<CheckEligibilityForSmartThermostatStart>(() => ({
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_START',
  }));

const checkEligibilityForSmartThermostatSuccess = makeActionSuccess<
  CheckEligibilityForSmartThermostatSuccess,
  [Eligibility]
>(eligibility => {
  return {
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_SUCCESS',
    eligibility,
  };
});

const checkEligibilityForSmartThermostatError =
  makeActionError<CheckEligibilityForSmartThermostatError>(() => ({
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_ERROR',
  }));

export const checkEligibilityForSmartThermostatProfessionalInstallStart =
  makeActionStart<
    CheckEligibilityForSmartThermostatProfessionalInstallStart,
    [ProfileNextV1Query]
  >(profile => ({
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_START',
    profile,
  }));

export const checkEligibilityForSmartThermostatProfessionalInstallSuccess =
  makeActionSuccess<
    CheckEligibilityForSmartThermostatProfessionalInstallSuccess,
    [Eligibility]
  >(eligibility => {
    return {
      type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_SUCCESS',
      eligibility,
    };
  });

const checkEligibilityForSmartThermostatProfessionalInstallError =
  makeActionError<CheckEligibilityForSmartThermostatProfessionalInstallError>(
    () => ({
      type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_ERROR',
    }),
  );

export const checkEligibilityForSmartThermostatPayMonthlyStart =
  makeActionStart<
    CheckEligibilityForSmartThermostatPayMonthlyStart,
    [DirectDebitResponse, ContractsResponse]
  >((directDebit, contracts) => ({
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_START',
    directDebit,
    contracts,
  }));

const checkEligibilityForSmartThermostatPayMonthlySuccess = makeActionSuccess<
  CheckEligibilityForSmartThermostatPayMonthlySuccess,
  [Eligibility]
>(eligibility => {
  return {
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_SUCCESS',
    eligibility,
  };
});

const checkEligibilityForSmartThermostatPayMonthlyError =
  makeActionError<CheckEligibilityForSmartThermostatPayMonthlyError>(() => ({
    type: 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_ERROR',
  }));

export const storeEligibilityQuestionsAnswer = (
  answer: boolean,
): StoreEligibilityQuestionsAnswer => ({
  type: 'STORE_ELIGIBILITY_QUESTIONS_ANSWER',
  answer,
});

export const setEligibilityForSmartThermostatProfessionalInstall = (
  eligibility: Eligibility,
): SetEligibilityForSmartThermostatProfessionalInstall => ({
  type: 'SET_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL',
  eligibility,
});

export const storeOrderType = (orderType: OrderType): StoreOrderType => ({
  type: 'STORE_ORDER_TYPE',
  orderType,
});

export const storePaymentType = (
  paymentType: PaymentType,
): StorePaymentType => ({
  type: 'STORE_PAYMENT_TYPE',
  paymentType,
});

export const addSmartThermostatsIneligibilityReason = (
  reason: IneligibilityReasons,
): AddSmartThermostatsIneligibilityReason => ({
  type: 'ADD_SMART_THERMOSTATS_INELIGIBILITY_REASON',
  reason,
});

export const addAccountToThermostatsWaitlistStart =
  makeActionStart<AddAccountToThermostatsWaitlistStart>(() => ({
    type: 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_START',
  }));

const addAccountToThermostatsWaitlistSuccess = makeActionSuccess<
  AddAccountToThermostatsWaitlistSuccess,
  [boolean]
>((onWaitlist: boolean) => ({
  type: 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_SUCCESS',
  onWaitlist,
}));

const addAccountToThermostatsWaitlistError =
  makeActionError<AddAccountToThermostatsWaitlistError>(() => ({
    type: 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_ERROR',
  }));

export const checkAccountWaitlistStatusStart =
  makeActionStart<CheckAccountWaitlistStatusStart>(() => ({
    type: 'CHECK_ACCOUNT_WAITLIST_STATUS_START',
  }));

const checkAccountWaitlistStatusSuccess = makeActionSuccess<
  CheckAccountWaitlistStatusSuccess,
  [boolean]
>((onWaitlist: boolean) => ({
  type: 'CHECK_ACCOUNT_WAITLIST_STATUS_SUCCESS',
  onWaitlist,
}));

const checkAccountWaitlistStatusError =
  makeActionError<CheckAccountWaitlistStatusError>(() => ({
    type: 'CHECK_ACCOUNT_WAITLIST_STATUS_ERROR',
  }));

export const registerSmartThermostatsOrderStart = makeActionStart<
  RegisterSmartThermostatsOrderStart,
  [
    {
      paymentToken?: string;
      paymentIntentId?: string;
      profile: ProfileResponse;
    },
  ]
>(({ paymentToken, paymentIntentId, profile }) => ({
  type: 'REGISTER_SMART_THERMOSTATS_ORDER_START',
  paymentToken,
  paymentIntentId,
  profile,
}));

const registerSmartThermostatsOrderSuccess = makeActionSuccess<
  RegisterSmartThermostatsOrderSuccess,
  [string, string, string | undefined]
>((orderNo: string, paymentStatus: string, promoCode: string | undefined) => ({
  type: 'REGISTER_SMART_THERMOSTATS_ORDER_SUCCESS',
  orderNo,
  paymentStatus,
  promoCode,
}));

const registerSmartThermostatsOrderRequiresAction = makeActionSuccess<
  RegisterSmartThermostatsOrderRequiresAction,
  [string, string]
>((paymentStatus: string, clientSecret: string) => ({
  type: 'REGISTER_SMART_THERMOSTATS_ORDER_REQUIRES_ACTION',
  paymentStatus,
  clientSecret,
}));

const registerSmartThermostatsOrderError =
  makeActionError<RegisterSmartThermostatsOrderError>(() => ({
    type: 'REGISTER_SMART_THERMOSTATS_ORDER_ERROR',
  }));

export const unsetPaymentStatusAndSecret =
  makeActionStart<UnsetPaymentStatusAndSecret>(() => ({
    type: 'UNSET_PAYMENT_STATUS_AND_SECRET',
  }));

export const getSmartThermostatsTadoInfoStart =
  makeActionStart<GetSmartThermostatsTadoInfoStart>(() => ({
    type: 'GET_SMART_THERMOSTATS_TADO_INFO_START',
  }));

const getSmartThermostatsTadoInfoSuccess = makeActionSuccess<
  GetSmartThermostatsTadoInfoSuccess,
  [SmartThermostatsTadoInfo]
>(info => {
  return {
    type: 'GET_SMART_THERMOSTATS_TADO_INFO_SUCCESS',
    info,
  };
});

const getSmartThermostatsTadoInfoError =
  makeActionError<GetSmartThermostatsTadoInfoError>(() => ({
    type: 'GET_SMART_THERMOSTATS_TADO_INFO_ERROR',
  }));

export const getSmartThermostatsPricingStart = makeActionStart<
  GetSmartThermostatsPricingStart,
  [promoCode?: string]
>((promoCode?: string) => ({
  type: 'GET_SMART_THERMOSTATS_PRICING_START',
  promoCode,
}));

const getSmartThermostatsPricingSuccess = makeActionSuccess<
  GetSmartThermostatsPricingSuccess,
  [SmartThermostatsPricing]
>(pricing => {
  return {
    type: 'GET_SMART_THERMOSTATS_PRICING_SUCCESS',
    pricing,
  };
});

const getSmartThermostatsPricingError =
  makeActionError<GetSmartThermostatsPricingError>(() => ({
    type: 'GET_SMART_THERMOSTATS_PRICING_ERROR',
  }));

export const getSmartThermostatsTadoStatusStart =
  makeActionStart<GetSmartThermostatsTadoStatusStart>(() => ({
    type: 'GET_SMART_THERMOSTATS_TADO_STATUS_START',
  }));

const getSmartThermostatsTadoStatusSuccess = makeActionSuccess<
  GetSmartThermostatsTadoStatusSuccess,
  [SmartThermostatsTadoStatus]
>(status => {
  return {
    type: 'GET_SMART_THERMOSTATS_TADO_STATUS_SUCCESS',
    status,
  };
});

const getSmartThermostatsTadoStatusError =
  makeActionError<GetSmartThermostatsTadoStatusError>(() => ({
    type: 'GET_SMART_THERMOSTATS_TADO_STATUS_ERROR',
  }));

export const getSmartThermostatsOrderInfoStart =
  makeActionStart<GetSmartThermostatsOrderInfoStart>(() => ({
    type: 'GET_SMART_THERMOSTATS_ORDER_INFO_START',
  }));

const getSmartThermostatsOrderInfoSuccess = makeActionSuccess<
  GetSmartThermostatsOrderInfoSuccess,
  [SmartThermostatsOrderInfo]
>(orderType => {
  return {
    type: 'GET_SMART_THERMOSTATS_ORDER_INFO_SUCCESS',
    orderType,
  };
});

const getSmartThermostatsOrderInfoError =
  makeActionError<GetSmartThermostatsOrderInfoError>(() => ({
    type: 'GET_SMART_THERMOSTATS_ORDER_INFO_ERROR',
  }));

export const getSmartThermostatsDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_SMART_THERMOSTATS_INFO_START')
    .mergeMap(
      ({
        [FETCH_ACTION_KEY]: id,
        startDate,
        endDate,
        granularity,
      }: GetSmartThermostatsInfoStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getSmartThermostatsDataError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return getSmartThermostatsData(
          startDate,
          endDate,
          accountId,
          granularity,
        )
          .map(series => {
            const state = store.getState().smartThermostats.data;
            return updateData(state, series, startDate, endDate, granularity);
          })
          .map(data => {
            const state = store.getState().smartThermostats.toolTips;
            const toolTips = extendDateObj(state, startDate);
            return getSmartThermostatsDataSuccess(id, data, toolTips);
          })
          .catch(err => {
            return Observable.of(getSmartThermostatsDataError(id, err));
          });
      },
    );
};

export const getSmartThermostatsSavingsEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_SMART_THERMOSTATS_SAVINGS_START')
    .switchMap(
      ({ [FETCH_ACTION_KEY]: id }: GetSmartThermostatsSavingsStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getSmartThermostatsSavingsError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return getSmartThermostatsSavings(accountId)
          .map(linkResp => {
            const link = linkResp.savings;
            return getSmartThermostatsSavingsSuccess(id, link);
          })
          .catch(err => {
            return Observable.of(getSmartThermostatsSavingsError(id, err));
          });
      },
    );
};

export const getSmartThermostatsTadoLinkEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_SMART_THERMOSTATS_TADO_LINK_START')
    .switchMap(
      ({ [FETCH_ACTION_KEY]: id }: GetSmartThermostatsTadoLinkStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getSmartThermostatsTadoLinkError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }

        return getSmartThermostatsTadoLink(accountId)
          .map(linkResp => {
            const link = linkResp;
            return getSmartThermostatsTadoLinkSuccess(id, link);
          })
          .catch(err => {
            return Observable.of(getSmartThermostatsTadoLinkError(id, err));
          });
      },
    );
};

export const getSmartThermostatsTadoInfoEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_SMART_THERMOSTATS_TADO_INFO_START')
    .switchMap(
      ({ [FETCH_ACTION_KEY]: id }: GetSmartThermostatsTadoInfoStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getSmartThermostatsTadoInfoError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return getSmartThermostatsTadoInfo(accountId)
          .map(resp => {
            const info = resp;
            return getSmartThermostatsTadoInfoSuccess(id, info);
          })
          .catch(err => {
            return Observable.of(getSmartThermostatsTadoInfoError(id, err));
          });
      },
    );
};

export const getSmartThermostatsPricingEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_SMART_THERMOSTATS_PRICING_START')
    .switchMap(
      ({
        [FETCH_ACTION_KEY]: id,
        promoCode,
      }: GetSmartThermostatsPricingStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getSmartThermostatsPricingError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }

        const customerId = store.getState().user.customerId;
        return getSmartThermostatsPricing(accountId, customerId, promoCode)
          .map(pricing => {
            return getSmartThermostatsPricingSuccess(id, pricing);
          })
          .catch(err => {
            return Observable.of(getSmartThermostatsPricingError(id, err));
          });
      },
    );
};

export const getSmartThermostatsTadoStatusEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_SMART_THERMOSTATS_TADO_STATUS_START')
    .switchMap(
      ({ [FETCH_ACTION_KEY]: id }: GetSmartThermostatsTadoStatusStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getSmartThermostatsTadoStatusError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return getSmartThermostatsTadoStatus(accountId)
          .map(resp => {
            const status = resp;
            return getSmartThermostatsTadoStatusSuccess(id, status);
          })
          .catch(err => {
            return Observable.of(getSmartThermostatsTadoStatusError(id, err));
          });
      },
    );
};

export const getSmartThermostatsOrderInfoEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_SMART_THERMOSTATS_ORDER_INFO_START')
    .switchMap(
      ({ [FETCH_ACTION_KEY]: id }: GetSmartThermostatsOrderInfoStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getSmartThermostatsOrderInfoError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return getSmartThermostatsOrderInfo(accountId)
          .map(resp => {
            const orderType = resp;
            return getSmartThermostatsOrderInfoSuccess(id, orderType);
          })
          .catch(err => {
            return Observable.of(getSmartThermostatsOrderInfoError(id, err));
          });
      },
    );
};

export const checkGeneralEligibilityEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_START')
    .switchMap(
      ({ [FETCH_ACTION_KEY]: id }: CheckEligibilityForSmartThermostatStart) => {
        return Observable.of(checkThermostatEligibility(store))
          .map(eligibility => {
            return checkEligibilityForSmartThermostatSuccess(id, eligibility);
          })
          .catch(err => {
            return Observable.of(
              checkEligibilityForSmartThermostatError(id, err),
            );
          });
      },
    );
};

export const checkProfessionalInstallEligibilityEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_START')
    .switchMap(
      ({
        [FETCH_ACTION_KEY]: id,
        profile,
      }: CheckEligibilityForSmartThermostatProfessionalInstallStart) => {
        return Observable.of(
          checkProfessionalInstallEligibility(store, undefined, profile),
        )
          .map(eligibility => {
            return checkEligibilityForSmartThermostatProfessionalInstallSuccess(
              id,
              eligibility,
            );
          })
          .catch(err => {
            return Observable.of(
              checkEligibilityForSmartThermostatProfessionalInstallError(
                id,
                err,
              ),
            );
          });
      },
    );
};

export const checkPayMonthlyEligibilityEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_START')
    .switchMap(
      ({
        [FETCH_ACTION_KEY]: id,
        directDebit,
        contracts,
      }: CheckEligibilityForSmartThermostatPayMonthlyStart) => {
        return Observable.of(
          checkPayMonthlyEligibility(store, directDebit, contracts),
        )
          .map(eligibility => {
            return checkEligibilityForSmartThermostatPayMonthlySuccess(
              id,
              eligibility,
            );
          })
          .catch(err => {
            return Observable.of(
              checkEligibilityForSmartThermostatPayMonthlyError(id, err),
            );
          });
      },
    );
};

export const addAccountToWaitlistEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_START')
    .switchMap(
      ({ [FETCH_ACTION_KEY]: id }: AddAccountToThermostatsWaitlistStart) => {
        const state: State = store.getState();
        const eligibility = state.smartThermostats.eligibility.general;
        const reasons: string[] = eligibility?.reasons || [];
        return callWaitlistAPI(reasons)
          .map(() => {
            return addAccountToThermostatsWaitlistSuccess(id, true);
          })
          .catch(err => {
            return Observable.of(addAccountToThermostatsWaitlistError(id, err));
          });
      },
    );
};

export const checkAccountWaitlistStatusEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('CHECK_ACCOUNT_WAITLIST_STATUS_START')
    .switchMap(
      ({ [FETCH_ACTION_KEY]: id }: AddAccountToThermostatsWaitlistStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            checkAccountWaitlistStatusError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return callWaitlistStatusAPI(accountId)
          .map(response => {
            return checkAccountWaitlistStatusSuccess(id, response.inWaitlist);
          })
          .catch(err => {
            return Observable.of(checkAccountWaitlistStatusError(id, err));
          });
      },
    );
};

export const registerSmartThermostatsOrderEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('REGISTER_SMART_THERMOSTATS_ORDER_START')
    .switchMap(
      ({
        [FETCH_ACTION_KEY]: id,
        paymentToken,
        paymentIntentId,
        profile,
      }: RegisterSmartThermostatsOrderStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            registerSmartThermostatsOrderError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        const thermostats: SmartThermostatsInfoState =
          store.getState().smartThermostats;
        const promoCode = getQueryParamValue('promoCode');

        if (!profile) {
          throw Error('No Profile information');
        }

        if (!thermostats.orderType) {
          throw Error('No install type set');
        }

        if (!thermostats.paymentType) {
          throw Error('No payment type set');
        }

        const phone: string = profile.phoneNumbers[0];
        const address: string[] = profile.address;
        const { orderType, paymentType } = thermostats;
        const productId = selectThermostatBolton(paymentType, orderType);

        return callOrdersAPI(
          accountId,
          phone,
          address,
          orderType,
          paymentType,
          productId,
          paymentToken,
          paymentIntentId,
          promoCode,
        )
          .map(resp => {
            if (resp.status === 201) {
              const { orderId, paymentStatus, clientSecret } = resp.response;
              if (orderId) {
                return registerSmartThermostatsOrderSuccess(
                  id,
                  orderId,
                  paymentStatus,
                  promoCode,
                );
              } else if (!orderId && paymentStatus === 'requires_action') {
                return registerSmartThermostatsOrderRequiresAction(
                  id,
                  paymentStatus,
                  clientSecret,
                );
              } else {
                throw Error('No order number');
              }
            } else if (resp.response.error) {
              throw Error(resp.response.error);
            } else {
              throw Error('Something went wrong');
            }
          })
          .catch(err => {
            return Observable.of(registerSmartThermostatsOrderError(id, err));
          });
      },
    );
};

export const defaultSmartThermostatsInfoState: SmartThermostatsInfoState = {
  data: {},
  toolTips: {},
  tadoInfo: null,
  tadoLink: null,
  tadoStatus: null,
  savings: null,
  isFetching: false,
  eligibility: {
    general: null,
    proInstall: null,
    payMonthly: null,
  },
  eligibilityAnswer: null,
  error: false,
  onWaitList: null,
  waitlistLoading: false,
  orderNo: null,
  orderType: null,
  orderDetails: {
    orderType: null,
  },
  paymentType: null,
  paymentIntentStatus: null,
  paymentIntentClientSecret: null,
  pricing: null,
  promoCode: null,
};

const smartThermostats = (
  state: SmartThermostatsInfoState = defaultSmartThermostatsInfoState,
  action: Action,
): SmartThermostatsInfoState => {
  switch (action.type) {
    case 'GET_SMART_THERMOSTATS_ORDER_INFO_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'GET_SMART_THERMOSTATS_ORDER_INFO_SUCCESS':
      return {
        ...state,
        isFetching: false,
        orderDetails: {
          ...action.orderType,
        },
      };
    case 'GET_SMART_THERMOSTATS_ORDER_INFO_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'GET_SMART_THERMOSTATS_INFO_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'GET_SMART_THERMOSTATS_INFO_SUCCESS':
      return {
        ...state,
        isFetching: false,
        data: action.data,
        toolTips: action.toolTips,
      };
    case 'GET_SMART_THERMOSTATS_INFO_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'GET_SMART_THERMOSTATS_SAVINGS_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'GET_SMART_THERMOSTATS_SAVINGS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        savings: action.savings,
      };
    case 'GET_SMART_THERMOSTATS_SAVINGS_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'GET_SMART_THERMOSTATS_TADO_LINK_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'GET_SMART_THERMOSTATS_TADO_LINK_SUCCESS':
      return {
        ...state,
        isFetching: false,
        tadoLink: action.link,
      };
    case 'GET_SMART_THERMOSTATS_TADO_LINK_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'GET_SMART_THERMOSTATS_TADO_INFO_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'GET_SMART_THERMOSTATS_TADO_INFO_SUCCESS':
      return {
        ...state,
        isFetching: false,
        tadoInfo: action.info,
      };
    case 'GET_SMART_THERMOSTATS_TADO_INFO_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'GET_SMART_THERMOSTATS_PRICING_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'GET_SMART_THERMOSTATS_PRICING_SUCCESS':
      return {
        ...state,
        isFetching: false,
        pricing: action.pricing,
      };
    case 'GET_SMART_THERMOSTATS_PRICING_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'GET_SMART_THERMOSTATS_TADO_STATUS_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'GET_SMART_THERMOSTATS_TADO_STATUS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        tadoStatus: action.status,
      };
    case 'GET_SMART_THERMOSTATS_TADO_STATUS_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_SUCCESS':
      return {
        ...state,
        isFetching: false,
        eligibility: {
          ...state.eligibility,
          general: action.eligibility,
        },
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_SUCCESS':
      return {
        ...state,
        isFetching: false,
        eligibility: {
          ...state.eligibility,
          proInstall: action.eligibility,
        },
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_SUCCESS':
      return {
        ...state,
        isFetching: false,
        eligibility: {
          ...state.eligibility,
          payMonthly: action.eligibility,
        },
      };
    case 'CHECK_ELIGIBILITY_FOR_SMART_THERMOSTAT_PAY_MONTHLY_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'STORE_ELIGIBILITY_QUESTIONS_ANSWER':
      return {
        ...state,
        eligibilityAnswer: action.answer,
      };
    case 'SET_ELIGIBILITY_FOR_SMART_THERMOSTAT_PROFESSIONAL_INSTALL':
      return {
        ...state,
        isFetching: false,
        eligibility: {
          ...state.eligibility,
          proInstall: action.eligibility,
        },
      };
    case 'STORE_ORDER_TYPE':
      return {
        ...state,
        orderType: action.orderType,
      };
    case 'STORE_PAYMENT_TYPE':
      return {
        ...state,
        paymentType: action.paymentType,
      };
    case 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_START':
      return {
        ...state,
        isFetching: true,
        waitlistLoading: true,
      };
    case 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_SUCCESS':
      return {
        ...state,
        isFetching: false,
        onWaitList: action.onWaitlist,
        waitlistLoading: false,
      };
    case 'ADD_ACCOUNT_TO_THERMOSTATS_WAITLIST_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        waitlistLoading: false,
        error: true,
      };
    case 'CHECK_ACCOUNT_WAITLIST_STATUS_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'CHECK_ACCOUNT_WAITLIST_STATUS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        onWaitList: action.onWaitlist,
      };
    case 'CHECK_ACCOUNT_WAITLIST_STATUS_ERROR':
      return {
        ...state,
        errorResponse: action.errorResponse,
        isFetching: false,
        error: true,
      };
    case 'REGISTER_SMART_THERMOSTATS_ORDER_START':
      return {
        ...state,
        isFetching: true,
        errorResponse: null,
      };
    case 'REGISTER_SMART_THERMOSTATS_ORDER_SUCCESS':
      return {
        ...state,
        isFetching: false,
        orderNo: action.orderNo,
        paymentIntentStatus: action.paymentStatus,
        paymentIntentClientSecret: null,
        promoCode: action.promoCode || null,
      };
    case 'REGISTER_SMART_THERMOSTATS_ORDER_REQUIRES_ACTION':
      return {
        ...state,
        isFetching: false,
        paymentIntentStatus: action.paymentStatus,
        paymentIntentClientSecret: action.clientSecret,
      };
    case 'REGISTER_SMART_THERMOSTATS_ORDER_ERROR': {
      const { error } = action;
      return {
        ...state,
        errorResponse: {
          statusCode: error.status,
          message: error.response?.error || error.message,
        },
        paymentIntentStatus: null,
        paymentIntentClientSecret: null,
        isFetching: false,
        error: true,
      };
    }
    case 'ADD_SMART_THERMOSTATS_INELIGIBILITY_REASON': {
      const reasons =
        state.eligibility?.general?.eligible === false
          ? state.eligibility.general.reasons
          : [];
      reasons.push(action.reason);
      const eligibility: Eligibility = {
        eligible: state.eligibility?.general?.eligible,
        reasons: reasons,
      };
      return {
        ...state,
        eligibility: {
          ...state.eligibility,
          general: eligibility,
        },
      };
    }
    case 'UNSET_PAYMENT_STATUS_AND_SECRET':
      return {
        ...state,
        paymentIntentStatus: null,
        paymentIntentClientSecret: null,
      };
    default: {
      return state;
    }
  }
};

export default smartThermostats;
