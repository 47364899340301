import { CTALink, Heading3, P, Strong } from '@ovotech/nebula';
import React from 'react';
import { ActionCentreBanner, Banner } from '../Banner';

export const HomePlanBundle: ActionCentreBanner = ({ dismiss, onClick }) => (
  <Banner data-testid="homeplan" icon="pound" dismiss={dismiss}>
    <Heading3 as="h2">
      Remember to complete your bundle - apply for boiler cover now
    </Heading3>
    <P>
      You only have <Strong>30 days</Strong> from the start of your new energy
      contract to apply, so why wait? It only takes a few minutes.
    </P>
    <CTALink
      variant="missionPrimary"
      href={'https://homeplan.ovoenergy.com?promoCode=PROTECT22'}
      onClick={onClick}
      opensInNewWindow="true"
    >
      Apply now
    </CTALink>
  </Banner>
);
