import React, { FunctionComponent, PropsWithChildren } from 'react';

import { StyledNavigationLink } from './PreviousNextNavigation.styled';
import { LinkTo } from './types';

type Props = {
  to: LinkTo;
  disabled?: boolean;
  [key: string]: any;
};

const NavigationLink: FunctionComponent<Props> = ({
  to,
  disabled,
  children,
  ...rest
}: PropsWithChildren<Props>) => (
  <StyledNavigationLink
    {...rest}
    to={disabled ? '#' : to}
    aria-disabled={disabled}
  >
    {children}
  </StyledNavigationLink>
);

NavigationLink.defaultProps = {
  disabled: undefined,
};

export default NavigationLink;
