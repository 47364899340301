import styled from 'styled-components';

import { Theme } from '@/src/types/Theme';

type SpaceKey = keyof Theme['space'];

export const StyledSwitcher = styled.div<{
  threshold: string;
  space: SpaceKey;
}>`
  ${({ theme, threshold, space }) => `
    > * {
      display: flex;
      flex-wrap: wrap;
      margin: calc((${theme.space[space]} / 2) * -1);
    }

    > * > * {
      flex-grow: 1;
      flex-basis:
        calc((${threshold} - (100% - ${theme.space[space]})) * 999);
      margin: calc(${theme.space[space]} / 2);
    }
  `}
`;
