import { Heading3, Heading4, P, Strong } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { LossBannerCTA } from '../LossBannerCTA';
import { useContractsQuery } from '@/src/api';
import { MeterSwitch, RouterLink } from '@/src/components';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { CollapsibleBlock } from '@/src/components/ActionCentre/Banners/CollapsibleBlock';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { LOSS_MESSAGE_SHOWN } from '@/src/constants/analytics';
import {
  ROUTE_MAKE_CARD_PAYMENT,
  ROUTE_METER_READINGS,
} from '@/src/constants/routes';
import {
  findContractInLoss,
  getFirstAndLastContractsInLoss,
} from '@/src/utils/contracts';

const bannerId = 'lossPendingSingleFuel';

export const LossPendingSingleFuel: ActionCentreBanner = () => {
  const { accountId } = useAccountContext();
  const { data: contracts, isLoading } = useContractsQuery(accountId);
  if (isLoading) return <SkeletonCard />;
  if (!contracts) return null;

  const electricityContract = findContractInLoss(contracts.electricity);
  const gasContract = findContractInLoss(contracts.gas);
  const [contractInLoss] = getFirstAndLastContractsInLoss(
    electricityContract,
    gasContract,
  );

  if (!contractInLoss) return null;

  const { fuel, lossDate } = contractInLoss;
  const lossEvent = `Single fuel: ${fuel} In Loss`;

  return (
    <AnalyticsWrapper name={LOSS_MESSAGE_SHOWN} properties={{ lossEvent }}>
      <Banner icon="loss">
        <Heading3 as="h2">We hear you're leaving...</Heading3>
        <P>
          We're sorry to see you go but we're doing all we can to make your
          switch as smooth as possible. There's no need to get in touch.
        </P>
        <P>
          <Strong data-testid="loss-pending-date">
            We’ll stop supplying your energy on{' '}
            {format(new Date(lossDate as string), 'Do MMMM YYYY')}.
          </Strong>
        </P>
        <CollapsibleBlock bannerId={bannerId}>
          <P>
            We'll send separate final statements for your electricity and gas
            within 6 weeks. These will cover your energy up to the date of your
            switch.
          </P>
          <MeterSwitch
            HasTraditional={() => (
              <>
                <Heading4 as="h3">Need to send a reading?</Heading4>
                <P>
                  Please send a reading if you haven't sent one recently to make
                  sure your final statement's accurate.{' '}
                  <RouterLink to={ROUTE_METER_READINGS}>
                    Send a reading
                  </RouterLink>
                  .
                </P>
              </>
            )}
          />
          <P>
            Please don't cancel your Direct Debit – we'll continue to collect it
            until your account's closed.
          </P>
          <P>
            If your final balance is in credit we'll refund this back to your
            bank account within 10 working days, then close your account.
          </P>
          <P>
            If your final balance is in debt, we'll continue collecting your
            Direct Debit until it's cleared. We'll refund any credit back to
            your bank account within 10 working days, then close your account.
          </P>
          <P>
            You can also make a top-up card payment on your online{' '}
            <RouterLink to={ROUTE_MAKE_CARD_PAYMENT}>account</RouterLink> to
            clear any outstanding debt and speed up the process.
          </P>
          <LossBannerCTA lossEvent={lossEvent} bannerId={bannerId} />
        </CollapsibleBlock>
      </Banner>
    </AnalyticsWrapper>
  );
};
