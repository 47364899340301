import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkAccountWaitlistStatusStart } from '@/src/redux/ducks/smartThermostats/smart-thermostats-info';

import { State } from '@/src/types/State';

// TODO: move to react-query
export function getWaitlistInfo() {
  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const smartThermostats = useSelector(
    (state: State) => state.smartThermostats,
  );

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      smartThermostats.onWaitList === null &&
      !smartThermostats.isFetching &&
      !smartThermostats.errorResponse
    ) {
      dispatch(checkAccountWaitlistStatusStart());
    }
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onWaitlist: smartThermostats.onWaitList,
    waitlistLoading: smartThermostats.waitlistLoading,
  };
}
