import { Heading3, P, Ul, Li } from '@ovotech/nebula';
import { AnalyticsRender, useAnalytics } from '@ovotech/ui-tools';
import React from 'react';
import Offer from './Offer/Offer';

import { HOMEPLAN_SVT_PLUS_BC_CARD_Q1_PROMO_OFFER } from '@/src/constants/analytics-subjects';

import { FootnoteReferenced } from '@/src/components';
import { footnoteOHSQ1PromoOfferNoClaims } from '../FooterTermsAndConditions';

import { createHomePlanUrl } from '@/src/constants/endpoints';

import image from '@/src/resources/offers/boiler-cover2.png';

type Props = {
  accountId: string;
};

export const HomePlanSvtPlusBCQ1PromoOffer = ({ accountId }: Props) => {
  const analytics = useAnalytics();

  return (
    <AnalyticsRender name={HOMEPLAN_SVT_PLUS_BC_CARD_Q1_PROMO_OFFER}>
      <Offer
        image={image}
        offerId="q1PromoOffer"
        linkUrl={createHomePlanUrl(
          'offers-and-upgrades',
          accountId,
          '&utm_medium=banner&utm_campaign=svt_addon_q1_offer',
        )}
        linkText="Apply now"
        tracking={() => {
          analytics.dispatch({
            name: HOMEPLAN_SVT_PLUS_BC_CARD_Q1_PROMO_OFFER,
            type: 'click',
          });
        }}
      >
        <Heading3 as="h2">
          Get 50% off boiler cover for 6 months.
          <FootnoteReferenced content={footnoteOHSQ1PromoOfferNoClaims} />
        </Heading3>

        <P>Plus, get £100 energy credit.</P>

        <P>
          Protect your home with OVO boiler cover – on offer until 21 March.
        </P>

        <Ul>
          <Li>Make claims online, or call our 24/7 helpline</Li>
          <Li>Over 195,000 boilers serviced last year</Li>
          <Li>
            Choose from 3 plans – get your electrics, drains, taps and toilets
            covered too
          </Li>
        </Ul>
      </Offer>
    </AnalyticsRender>
  );
};
