import React from 'react';
import { TurbineAnimation, TurbineFill } from './TurbineAnimation';

export const AnimatedIconsDesktop = () => (
  <>
    <TurbineAnimation
      style={{ left: '36%', fill: '#B4CE43' }}
      turbineFill={TurbineFill.LIGHT}
      scale={2}
    />
    <TurbineAnimation
      style={{ left: '50%' }}
      turbineFill={TurbineFill.LIGHTEST}
      scale={2.5}
    />
    <TurbineAnimation style={{ left: '68%' }} scale={3.5} />
    <TurbineAnimation
      style={{ left: '87%' }}
      turbineFill={TurbineFill.DARK}
      scale={2.8}
    />
  </>
);
