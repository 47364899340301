import { Small, Heading2, Margin, P, SecondaryCTALink } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { FootnoteProvider } from '@/src/components';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';

export const SmartMeterBookingRenter: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const { accountId } = useAccountContext();

  return (
    <Banner icon="smartMeter" dismiss={dismiss} data-testid="smb-renter">
      <Heading2>Renting and ready to go smart?</Heading2>
      <P>
        If your name's on the energy bills, you don't need your landlord's
        permission to get a smart meter.* And we always tidy up after the
        installation.
      </P>
      <SecondaryCTALink
        href={`${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=banner&utm_medium=MyOVO&utm_campaign=smart-meter-booking`}
        data-testid="sm-booking-link"
        onClick={onClick}
      >
        Book my free appointment
      </SecondaryCTALink>
      <Margin top={2}>
        <FootnoteProvider>
          <P>
            {' '}
            <Small>
              {' '}
              * But it's worth letting them know and checking your tenancy
              agreement for any rules around changing the meter.{' '}
            </Small>{' '}
          </P>
        </FootnoteProvider>
      </Margin>
    </Banner>
  );
};
