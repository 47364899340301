import { CTALink, Heading3, P } from '@ovotech/nebula';
import React from 'react';
import { testIds } from './testIds';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { ROUTE_SETUP_DD } from '@/src/constants/routes';

export const DirectDebitSetup: ActionCentreBanner = ({ dismiss, onClick }) => {
  return (
    <Banner data-testid={testIds.ddSetup.banner} icon="pound" dismiss={dismiss}>
      <Heading3 as="h2">Set up a Direct Debit</Heading3>
      <P>It’s the easiest way to pay for your energy every month.</P>
      <CTALink
        data-testid={testIds.ddSetup.button}
        href={ROUTE_SETUP_DD}
        onClick={onClick}
      >
        Set up now
      </CTALink>
    </Banner>
  );
};
