import {
  CTAButton,
  Disclosure,
  Field,
  Heading2,
  Li,
  Margin,
  P,
  SecondaryCTAButton,
  SkeletonHeading,
  TextField,
  TextLink,
  Ul,
} from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom-v5';
import { PageHeader } from '../../components/PageHeader';
import {
  LoadingStyledCard,
  PromoContainer,
  StyledTermsCheckbox,
  StyledUl,
  ButtonGroupWrapper,
  PromoCutout,
  Divider,
} from './DataSharingAndTCConsent.styled';
import { PageWithNavigation } from '@/src/components';
import { ButtonGroup } from '@/src/components/ButtonGroup/ButtonGroup';
import {
  CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK,
  KALUZA_PRIVACY_POLICY_WEB_LINK,
  OVO_PRIVACY_POLICY_LINK,
  VOLVO_FREE_MILES_OFFER_LINK,
  VWG_FREE_MILES_OFFER_LINK,
} from '@/src/constants/links';
import { usePayAsYouDriveProduct } from '@/src/pages/AnytimeEligibility/hooks/usePayAsYouDriveProduct';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { TechnicalGlitch } from '@/src/pages/AnytimeEligibility/components/TechnicalGlitch';
import { useAvailableProductsQuery } from '@/src/api';
import {
  badRequestErrors,
  isServerError,
  getVolvoTrackingEventName,
  isVWGOrderNumberValid,
  PromoType,
  VOLVO_PROMO_FORM,
  VWG_PROMO_FORM,
  ChargeAnytimeErrorResponse,
  useChargeAnytimeContext,
  EV,
  VWG_PROMO_HEADING,
  VWG_PROMO_COPY,
} from '@monovo/ev-anytime';
import { PRODUCT_IDS } from '@/src/constants/products';
import { useFeature, OptimizelyContext } from '@/src/utils/optimizely';
import {
  CHARGE_ANYTIME_API,
  VWG_ORDER_NUMBER_FIELD,
  VWG_PROMO_BOX_UPDATE,
} from '@/src/constants/feature-flags';
import { useChargeAnytimeAcquisitionMutation } from '@/src/api/use-charge-anytime-acquisition-mutation';
import { AxiosError } from 'axios';
import { ROUTE_ANYTIME_ELIGIBILITY } from '@/src/constants/routes';

export const DataSharingAndTCConsent = () => {
  const { dispatch: analyticsDispatch } = useAnalytics();
  const { optimizely } = useContext(OptimizelyContext);
  const caDataConsentTitleTestEnabled = optimizely?.isFeatureEnabled(
    'ca_data_consent_title_test',
  );
  const [consentShareEVData, setConsentShareEVData] = useState(false);
  const [consentCATerms, setConsentCATerms] = useState(false);
  const [consentErrors, setConsentErrors] = useState(false);
  const { activateProduct, isReady, productActivationMutation } =
    usePayAsYouDriveProduct();
  const acquisitionMutation = useChargeAnytimeAcquisitionMutation();
  const { isError, error } = acquisitionMutation;

  const [isVWGOrderNumberFieldEnabled] = useFeature(VWG_ORDER_NUMBER_FIELD);
  const [isChargeAnytimeApiEnabled] = useFeature(CHARGE_ANYTIME_API);
  const [isVWGPromoBoxUpdateEnabled] = useFeature(VWG_PROMO_BOX_UPDATE);
  const history = useHistory();

  const isChargeAnytimeGlitch =
    isChargeAnytimeApiEnabled && isError && isServerError(error);

  const {
    smartCharger,
    make,
    model,
    year,
    hasSolarPanels,
    hasInternetAccess,
    isEligibleVolvo,
    vwgOrderNumber,
    setVwgOrderNumber,
    vwgOrderNumberError,
    setVwgOrderNumberError,
    validatePromoCode,
    promoCode,
    setPromoCode,
    validPromoCode,
    isPromoCodeError,
    integrationType,
    isEligibleVWG,
  } = useChargeAnytimeContext();

  const { refetch, isLoading } = useAvailableProductsQuery(
    PRODUCT_IDS.payasyoudrive,
    null,
    promoCode,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_CONSENT_VIEWED,
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: 'EVS - EVS OVO Charge Anytime D2V Confirmation Eligibility Consent Viewed',
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !smartCharger ||
    !make ||
    productActivationMutation.isError ||
    isChargeAnytimeGlitch
  ) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <TechnicalGlitch />
      </PageWithNavigation>
    );
  }

  const changePromoCode: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPromoCode(e.target.value);
  };

  const changeConsentShareEVData = () => {
    setConsentShareEVData(!consentShareEVData);
  };

  const changeConsentCATerms = () => {
    setConsentCATerms(!consentCATerms);
  };

  const shouldSendVwgOrderNumber =
    isVWGOrderNumberFieldEnabled &&
    isEligibleVWG === PromoType.CAN_LINK_TO_VW_PROMO;

  const validateVwgOrderNumber = () => {
    if (vwgOrderNumber && shouldSendVwgOrderNumber) {
      return isVWGOrderNumberValid(vwgOrderNumber);
    }
    return true;
  };

  const sendVolvoTracking = () => {
    const eventName = getVolvoTrackingEventName(
      isEligibleVolvo,
      !!hasSolarPanels,
      smartCharger,
      validPromoCode,
    );
    if (eventName) {
      analyticsDispatch({
        type: 'view',
        name: eventName,
      });
    }
  };

  const confirmConsent = async () => {
    optimizely?.track('charge_anytime_data_consent_confirm_clicked');
    const isVWGOrderNumberValid = validateVwgOrderNumber();
    if (!isVWGOrderNumberValid) {
      setVwgOrderNumberError(
        'That’s not a valid order number. Please check and try again.',
      );
    }
    if (consentShareEVData && consentCATerms && isVWGOrderNumberValid) {
      sendVolvoTracking();
      const vwgOrderNumberProps = shouldSendVwgOrderNumber
        ? { vwgOrderNumber }
        : {};
      analyticsDispatch({
        type: 'click',
        name: EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_CONFIRM_CLICKED,
        properties: {
          chargerName: smartCharger,
          carMake: make,
          carModel: model,
          carYear: year,
          internetAccess: hasInternetAccess,
          solarPanels: hasSolarPanels,
          isEligibleVolvo: isEligibleVolvo,
          validPromoCode: validPromoCode,
          DataConsentTitleTest: caDataConsentTitleTestEnabled,
          ...vwgOrderNumberProps,
        },
      });
      analyticsDispatch({
        type: 'click',
        name: 'EVS - EVS OVO Drive Anytime consent yes clicked',
        properties: {
          chargerName: smartCharger,
          carMake: make,
          carModel: model,
          carYear: year,
          internetAccess: hasInternetAccess,
          solarPanels: hasSolarPanels,
          isEligibleVolvo: isEligibleVolvo,
          validPromoCode: validPromoCode,
          DataConsentTitleTest: caDataConsentTitleTestEnabled,
          ...vwgOrderNumberProps,
        },
      });

      if (isChargeAnytimeApiEnabled) {
        try {
          await acquisitionMutation.mutateAsync({
            charger: smartCharger,
            make,
            model,
            year,
            hasInternetAccess: !!hasInternetAccess,
            hasSolarPanels: !!hasSolarPanels,
            ...vwgOrderNumberProps,
          });
          analyticsDispatch({
            type: 'view',
            name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_ORDER_CREATED,
          });
        } catch (error: any) {
          const { status, response } =
            error as AxiosError<ChargeAnytimeErrorResponse>;
          if (status === 400) {
            const errors = badRequestErrors(response?.data ?? []) as {
              vwgOrderNumber: string;
            };
            analyticsDispatch({
              type: 'view',
              name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_ORDER_FAILED,
              properties: { errors: errors },
            });
            setVwgOrderNumberError(errors.vwgOrderNumber);
          } else {
            analyticsDispatch({
              type: 'view',
              name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_ORDER_FAILED,
              properties: { error: error.message },
            });
          }
          return;
        }
      }
      if (integrationType === 'Volvo') {
        try {
          await activateProduct(validPromoCode);
          setConsentErrors(false);
          history.push(ROUTE_ANYTIME_ELIGIBILITY.VOLVO_APP);
        } catch {
          // Handled by productActivationMutation.isError below
          analyticsDispatch({
            type: 'view',
            name: EV.ELIGIBILITY
              .EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_TECHNICAL_GLITCH_VIEWED,
          });
        }
      } else if (integrationType === 'Ohme') {
        window.location.assign(
          process.env.NX_EV_ANYTIME_OHME_IDENTITY_URL as string,
        );
      } else if (integrationType === 'Indra') {
        window.location.assign(
          process.env.NX_EV_ANYTIME_KALUZA_IDENTITY_URL as string,
        );
      } /* KALUZA APP JOURNEY */ else {
        setConsentErrors(false);
        history.push(ROUTE_ANYTIME_ELIGIBILITY.CHARGE_ANYTIME_APP);
      }
    } else {
      setConsentShareEVData(consentShareEVData);
      setConsentCATerms(consentCATerms);
      setConsentErrors(true);
    }
  };

  if (
    !isReady ||
    productActivationMutation.isLoading ||
    acquisitionMutation.isLoading
  ) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <SkeletonCard />
      </PageWithNavigation>
    );
  }

  return (
    <PageWithNavigation title="Charge Anytime">
      <PageHeader
        title={
          caDataConsentTitleTestEnabled
            ? 'Good news. You can get Charge Anytime.'
            : 'Success! You can get Charge Anytime.'
        }
        showDescription={false}
      />
      <LoadingStyledCard data-testid="anytime-consent-page">
        <Heading2>Data sharing</Heading2>
        <P>
          To bring you Charge Anytime, we need to share the following data with
          our trusted tech partner, Kaluza, and your EV charging partner:
        </P>
        <Ul>
          <Li>Your EV charger ID</Li>
          <Li>Your EV details</Li>
          <Li>Your EV energy use</Li>
        </Ul>
        <Margin top={4}>
          <StyledTermsCheckbox
            id="dataSharingEVData"
            label="I agree for my EV data to be shared with OVO, Kaluza and my EV charging partner. "
            checked={consentShareEVData}
            onChange={changeConsentShareEVData}
            error={
              !consentShareEVData &&
              consentErrors &&
              'Please tick ok to continue'
            }
            data-testid="dataSharingEVData"
          />
        </Margin>
        <Margin top={4}>
          <Disclosure name="more-about-data" title="More about data sharing">
            <P>
              We share your data with Kaluza and your charging partner (this is
              your EV charger manufacturer or your EV manufacturer). We do this
              to work out the most accurate charging costs for you. Find out
              exactly what information will be shared using the links below.
            </P>
            <StyledUl>
              <Li>
                <TextLink
                  href={KALUZA_PRIVACY_POLICY_WEB_LINK}
                  opensInNewWindow
                >
                  See Kaluza Privacy Policy
                </TextLink>
              </Li>
              <Li>
                <TextLink href={OVO_PRIVACY_POLICY_LINK} opensInNewWindow>
                  See OVO Privacy Policy
                </TextLink>
              </Li>
            </StyledUl>
          </Disclosure>
        </Margin>
        {isVWGPromoBoxUpdateEnabled
          ? isEligibleVWG === PromoType.CAN_LINK_TO_VW_PROMO && (
              <PromoCutout>
                <Heading2>Our free miles offers</Heading2>
                <P>
                  We’re offering some fantastic free miles promotions through
                  our partnership with the Volkswagen Group.{' '}
                  <TextLink href={VWG_FREE_MILES_OFFER_LINK} opensInNewWindow>
                    Eligibility criteria apply.
                  </TextLink>{' '}
                </P>
                <P>
                  Enter your order number, and within 2 weeks we’ll let you know
                  if you’re eligible for one of our free miles offers.
                </P>
                <Margin top={4}>
                  <Disclosure
                    name="where-can-i-find-my-order-number"
                    title="Where can I find my order number?"
                  >
                    <P>
                      Your order number is an 8-digit number, usually starting
                      with 3. You can find it in the purchase agreement or order
                      confirmation from your dealership. If your EV is a company
                      car, please contact your fleet manager.
                    </P>
                  </Disclosure>
                </Margin>
                <StyledUl>
                  <Li>
                    <AnalyticsClick
                      name={
                        EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VW_PROMO_CLICKED
                      }
                      properties={{
                        smartChargerSelection: smartCharger,
                        validPromoCode: validPromoCode,
                        isEligibleVWG: isEligibleVWG,
                        carMake: make,
                        carModel: model,
                        carYear: year,
                        solarPanels: hasSolarPanels,
                        internetAccess: hasInternetAccess,
                      }}
                    >
                      {!isVWGOrderNumberFieldEnabled ? (
                        <TextLink
                          data-event-name={
                            'EVS - EVS OVO Drive Anytime VW promo clicked'
                          }
                          data-event-props={JSON.stringify({
                            smartChargerSelection: smartCharger,
                            validPromoCode: validPromoCode,
                            isEligibleVWG: isEligibleVWG,
                            carMake: make,
                            carModel: model,
                            carYear: year,
                            solarPanels: hasSolarPanels,
                            internetAccess: hasInternetAccess,
                          })}
                          href={VWG_PROMO_FORM}
                          opensInNewWindow
                        >
                          Click here to send us your order number
                        </TextLink>
                      ) : (
                        <Margin top={4}>
                          <TextField
                            id="chargeAnytimeVWGOrderNUmber"
                            placeholder="3"
                            data-testid="chargeAnytimeVWGOrderNumber"
                            label="Enter your 8-digit order number. This usually starts with a 3."
                            error={!!vwgOrderNumberError}
                            hint={vwgOrderNumberError}
                            value={vwgOrderNumber}
                            onChange={e => {
                              setVwgOrderNumberError('');
                              setVwgOrderNumber(e.target.value);
                            }}
                          />
                        </Margin>
                      )}
                    </AnalyticsClick>
                  </Li>
                </StyledUl>
              </PromoCutout>
            )
          : null}
        <Heading2>Our terms and conditions</Heading2>
        {!isEligibleVolvo && !isEligibleVWG && (
          <>
            <P>
              To add Charge Anytime you’ll need to agree to our terms and
              conditions.
            </P>
            <StyledUl>
              <Li>
                <TextLink
                  href={CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK}
                  opensInNewWindow
                >
                  Charge Anytime terms and conditions
                </TextLink>
              </Li>
            </StyledUl>
            <Margin top={4} bottom={8}>
              <StyledTermsCheckbox
                id="chargeAnytimeTerms"
                label="I agree to the Charge Anytime terms and conditions."
                checked={consentCATerms}
                onChange={changeConsentCATerms}
                error={
                  !consentCATerms &&
                  consentErrors &&
                  'Please tick ok to continue'
                }
                data-testid="chargeAnytimeTerms"
              />
            </Margin>
          </>
        )}
        {isEligibleVolvo && (
          <>
            <P>
              To add Charge Anytime and claim your free miles, you’ll need to
              agree to the terms and conditions for{' '}
              <TextLink
                href={CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK}
                opensInNewWindow
              >
                Charge Anytime
              </TextLink>{' '}
              and the{' '}
              <TextLink href={VOLVO_FREE_MILES_OFFER_LINK} opensInNewWindow>
                Volvo free miles offer
              </TextLink>
              .
            </P>
            <Margin top={4} bottom={8}>
              <StyledTermsCheckbox
                id="chargeAnytimeTerms"
                label="I agree to the Charge Anytime and Volvo free miles offer terms."
                checked={consentCATerms}
                onChange={changeConsentCATerms}
                error={
                  !consentCATerms &&
                  consentErrors &&
                  'Please tick ok to continue'
                }
                data-testid="chargeAnytimeTerms"
              />
            </Margin>
          </>
        )}
        {isEligibleVWG && (
          <>
            <P>
              To add Charge Anytime and claim your free miles, you’ll need to
              agree to the terms and conditions for{' '}
              <TextLink
                href={CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK}
                opensInNewWindow
              >
                Charge Anytime
              </TextLink>{' '}
              and the{' '}
              <TextLink href={VWG_FREE_MILES_OFFER_LINK} opensInNewWindow>
                Volkswagen Group free miles offer
              </TextLink>
              .
            </P>
            <Margin top={4} bottom={8}>
              <StyledTermsCheckbox
                id="chargeAnytimeTerms"
                label="I agree to the Charge Anytime and Volkswagen Group free miles offer terms and conditions."
                checked={consentCATerms}
                onChange={changeConsentCATerms}
                error={
                  !consentCATerms &&
                  consentErrors &&
                  'Please tick ok to continue'
                }
                data-testid="chargeAnytimeTerms"
              />
            </Margin>
          </>
        )}
        {isEligibleVolvo === PromoType.CAN_ADD_VOLVO_PROMO &&
        !validPromoCode &&
        !isLoading ? (
          <Margin top={4} bottom={8}>
            <Heading2>Do you have a promo code?</Heading2>
            <Field
              id="chargeAnytimePromoCodeContainer"
              label=""
              error={
                isPromoCodeError &&
                'We don’t recognise that promo code. Please check and try again.'
              }
            >
              <PromoContainer>
                <TextField
                  id="chargeAnytimePromoCode"
                  placeholder="Enter your code here"
                  data-testid="chargeAnytimePromoCodeInput"
                  label=""
                  value={promoCode}
                  onChange={changePromoCode}
                />
                <AnalyticsClick
                  name={
                    EV.ELIGIBILITY
                      .EVS_OVO_CHARGE_ANYTIME_VOLVO_PROMO_CODE_CLICKED
                  }
                >
                  <CTAButton
                    onClick={async () =>
                      validatePromoCode(
                        !!promoCode && (await refetch()).isError,
                      )
                    }
                    data-testid="changePromoCodeButton"
                  >
                    Apply
                  </CTAButton>
                </AnalyticsClick>
              </PromoContainer>
            </Field>
          </Margin>
        ) : isEligibleVolvo === PromoType.CAN_LINK_TO_VOLVO_PROMO &&
          !validPromoCode &&
          !isLoading ? (
          <Margin top={4} bottom={8}>
            <Heading2>Do you have a promo code?</Heading2>
            <StyledUl>
              <Li>
                <AnalyticsClick
                  name={
                    EV.ELIGIBILITY
                      .EVS_OVO_CHARGE_ANYTIME_VOLVO_PROMO_FORM_CLICKED
                  }
                >
                  <TextLink href={VOLVO_PROMO_FORM} opensInNewWindow>
                    Click here to send us your code
                  </TextLink>
                </AnalyticsClick>
              </Li>
            </StyledUl>
          </Margin>
        ) : null}
        {isLoading ? (
          <SkeletonHeading headingComponent={Heading2} size="90%" />
        ) : validPromoCode ? (
          <Margin top={4} bottom={8}>
            <Heading2>Thanks!</Heading2>
            <P>
              After you finish signing up for Charge Anytime in the app, we'll
              activate the promotion for you.
            </P>
          </Margin>
        ) : null}
        {!isVWGPromoBoxUpdateEnabled
          ? isEligibleVWG === PromoType.CAN_LINK_TO_VW_PROMO && (
              <>
                <Divider />
                <Margin bottom={8}>
                  <Heading2>{VWG_PROMO_HEADING}</Heading2>
                  <P>{VWG_PROMO_COPY}</P>
                  <StyledUl>
                    <Li>
                      <AnalyticsClick
                        name={
                          EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VW_PROMO_CLICKED
                        }
                        properties={{
                          smartChargerSelection: smartCharger,
                          validPromoCode: validPromoCode,
                          isEligibleVWG: isEligibleVWG,
                          carMake: make,
                          carModel: model,
                          carYear: year,
                          solarPanels: hasSolarPanels,
                          internetAccess: hasInternetAccess,
                        }}
                      >
                        {!isVWGOrderNumberFieldEnabled ? (
                          <TextLink
                            data-event-name={
                              'EVS - EVS OVO Drive Anytime VW promo clicked'
                            }
                            data-event-props={JSON.stringify({
                              smartChargerSelection: smartCharger,
                              validPromoCode: validPromoCode,
                              isEligibleVWG: isEligibleVWG,
                              carMake: make,
                              carModel: model,
                              carYear: year,
                              solarPanels: hasSolarPanels,
                              internetAccess: hasInternetAccess,
                            })}
                            href={VWG_PROMO_FORM}
                            opensInNewWindow
                          >
                            Click here to send us your order number
                          </TextLink>
                        ) : (
                          <TextField
                            id="chargeAnytimeVWGOrderNUmber"
                            placeholder="Enter your order number here"
                            data-testid="chargeAnytimeVWGOrderNumber"
                            label=""
                            error={!!vwgOrderNumberError}
                            hint={vwgOrderNumberError}
                            value={vwgOrderNumber}
                            onChange={e => {
                              setVwgOrderNumberError('');
                              setVwgOrderNumber(e.target.value);
                            }}
                          />
                        )}
                      </AnalyticsClick>
                    </Li>
                  </StyledUl>
                </Margin>
                <Divider />
              </>
            )
          : null}
        <ButtonGroupWrapper>
          <ButtonGroup>
            <AnalyticsClick
              name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_BACK_CLICKED}
            >
              <SecondaryCTAButton
                iconLeft="chevron-left"
                iconRight="off"
                onClick={history.goBack}
                data-testid="backButton"
              >
                Back
              </SecondaryCTAButton>
            </AnalyticsClick>
            <AnalyticsClick
              name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_CONFIRM_CLICKED}
            >
              <CTAButton
                data-event-name={
                  'EVS - EVS OVO Drive Anytime consent yes clicked'
                }
                onClick={confirmConsent}
                data-testid="confirmConsentButton"
              >
                Confirm
              </CTAButton>
            </AnalyticsClick>
          </ButtonGroup>
        </ButtonGroupWrapper>
      </LoadingStyledCard>
    </PageWithNavigation>
  );
};
