import styled from 'styled-components';

export const StyledNewsPanel = styled.div`
  ${({ theme: { mediaQueries } }) => `
    padding: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    > :last-child {
      margin-bottom: 0;
    }

    ${mediaQueries.mediumAndUp} {
      padding: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      overflow: hidden;

      > :last-child {
        margin-bottom: 0;
      }
    }
  `};
`;

export const StyledNewsText = styled.div`
  ${({ theme: { card, mediaQueries } }) => `
    padding: ${card.padding[0]};
    display: flex;
    flex-direction: column;
    flex: 2;
    flex: 1 1 50%;
    width: 100%;
    ${mediaQueries.mediumAndUp} {
      padding: ${card.padding[1]};
      display: flex;
      flex-direction: column;
      flex: 2;
      flex: 1 1 50%;
      width: 50%;
    }
  `};
`;

export const StyledNewsSecondText = styled.div`
  ${({ theme: { card, mediaQueries } }) => `
    padding: ${card.padding[0]};
    order: 2;
    display: flex;
    flex-direction: column;
    flex: 2;
    order: 2;
    width: 100%;
    flex: 1 1 50%;
    ${mediaQueries.mediumAndUp} {
      padding: ${card.padding[1]};
      display: flex;
      flex-direction: column;
      flex: 2;
      order: 2;
      width: 50%;
      flex: 1 1 50%;
    }
  `};
`;

export const StyledImage = styled.img`
  ${({ theme: { mediaQueries } }) => `
    display: none;

    ${mediaQueries.mediumAndUp} {
      display: flex;
      clip-path: polygon(0 0,95% 0,60% 15%,80% 100%,0 100%,0% 50%);
      width: 50%;
      flex: 1 1 50%;
    }
  `};
`;

export const StyledImageMobile = styled.img`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    flex: 1;
    ${mediaQueries.mediumAndUp} {
      display: none;
    }
  `};
`;

export const StyledSecondImage = styled.img`
  ${({ theme: { mediaQueries } }) => `
    display: none;

    ${mediaQueries.mediumAndUp} {
      display: flex;
      flex: 1;
      clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
      order: 2;
      width: 50%;
      flex: 1 1 50%;
    }
  `};
`;

export const StyledNewsImage = styled.img`
  ${({ theme: { mediaQueries } }) => `
    display: none;

    ${mediaQueries.mediumAndUp} {
      display: flex;
    }
  `};
`;
