import React, { ComponentProps, ComponentType, ElementType } from 'react';
import { StyledSwitcher } from './Switcher.styled';

type Props = ComponentProps<typeof StyledSwitcher> & {
  element?: ElementType | ComponentType;
};

const Switcher = ({
  children,
  threshold,
  space = 4,
  element: Element = 'div',
}: Props) => (
  <StyledSwitcher threshold={threshold} space={space}>
    <Element>{children}</Element>
  </StyledSwitcher>
);

export default Switcher;
