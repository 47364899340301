import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const UsageBreakdownBarWrapper = styled.div`
  position: relative;
  height: 4rem;
  width: 75%;
`;

export const UsageBreakdownBar = styled.div<{
  width: string;
  colour: string;
  top: string;
}>`
  position: absolute;
  display: inline-block;

  bottom: 0;
  height: 2rem;

  ${({ width, colour, top }) => `
    width: ${width}%;
    min-width: 1px;
    max-width: 100%;
    background: ${colour};
    top: ${top};
  `};
`;

export const UsageBreakdownBarFigure = styled.span`
  ${({ theme: { fontSizes } }) => `
    position: absolute;
    left: 100%;
    top: 50%;
    padding-left: 10px;
    transform: translateY(-50%);
    font-size: ${fontSizes[2]};
    ${mqSmallDown(`
      font-size: ${fontSizes}
    `)}
  `}

  width: 150px;

  ${mqSmallDown(`
      width: 135px;
    `)}
`;

export const UsageBreakdownSectionContent = styled.div`
  &:not(:last-child) {
    ${({ theme: { colors } }) => `
    border-bottom: 3px solid ${colors.borderMuted};
  `};
  }
`;
