import styled from 'styled-components';
import { mqMediumUp } from '@/src/utils/mediaQuery';

export const Roundel = styled.div`
  ${({ theme: { colors, fontWeights, fontSizes } }) => `
  width: 110px;
  height: 110px;
  position: absolute;
  left: 65%;
  top: -22%;
  border-radius: 50%;
  background-color: ${colors.green.midnight};
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.green.energised};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes[3]};
  line-height: normal;
    ${mqMediumUp(`
      top: -16%;
      width: 122px;
      height: 122px;
      font-size:${fontSizes[4]}
    `)}
  `}
`;
