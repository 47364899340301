import styled from 'styled-components';
import { safariOnly } from '@/src/utils/mediaQuery';

export const StyledKey = styled.div`
  display: flex;
  padding: 8px;

  ${safariOnly(`
    margin-top: 8px;
  `)}
`;

export const StyledKeyBox = styled.div<{ colour: string }>`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  background-color: ${({ colour }) => ` ${colour};`};
`;

export const StyledKeyItem = styled.span`
  display: flex;
  align-items: center;
  margin: 0 8px;
  font-size: 16px;
`;
