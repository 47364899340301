import { Margin, P } from '@ovotech/nebula';
import React from 'react';

import { FooterNote } from '../../Card.styled';

const Footer = () => {
  return (
    <Margin top={8} bottom={6}>
      <P as={FooterNote}>
        All prices include VAT (currently 5% for domestic customers).
      </P>
      <P as={FooterNote}>
        * We worked this out based on your previous energy use.
      </P>
    </Margin>
  );
};

export default Footer;
