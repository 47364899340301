import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from 'recharts';
import {
  yAxisTickFormatter,
  getColor,
} from '@/src/pages/EnergyInsights/utils/carbonIntensity';
import { CarbonIntensityThreshold } from '@/src/pages/EnergyInsights/utils/conversions';

type Props = {
  graphShapeData: Array<Array<{ from: string; intensity: number }>>;
  positionInData: number;
  isMobile: boolean;
};

const ForecastGraph = ({ graphShapeData, positionInData, isMobile }: Props) => {
  return (
    <ResponsiveContainer width="90%" height={300}>
      <BarChart
        data={graphShapeData[positionInData]}
        margin={{
          top: 0,
          left: isMobile ? 2 : 32,
          right: isMobile ? 20 : 48,
          bottom: 0,
        }}
        barSize={isMobile ? 20 : 30}
      >
        <ReferenceLine
          y={CarbonIntensityThreshold.low}
          stroke="#E5E5E5"
          isFront={false}
        />
        <ReferenceLine
          y={CarbonIntensityThreshold.moderate}
          stroke="#E5E5E5"
          isFront={false}
        />
        <XAxis dataKey="from" />
        <YAxis
          tickFormatter={yAxisTickFormatter}
          tickCount={4}
          domain={[0, 350]}
        />
        <Bar dataKey="intensity">
          {graphShapeData[positionInData].map((oneHour: any, index: number) => (
            <Cell fill={getColor(oneHour.intensity)} key={index} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ForecastGraph;
