import { useAccountContext } from '@ovotech/ui-tools';
import { useQuery } from 'react-query';

import { createProductCatalogueEndpoint } from '@/src/constants/endpoints';
import { ProductCatalogue } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

export const useProductCatalogueQuery = (promo?: string) => {
  const { accountId } = useAccountContext();
  const catalogueEndpoint = createProductCatalogueEndpoint(
    accountId || '',
    promo,
  );

  return useQuery(
    `orion-product-catalogue-${accountId}`,
    () => apiService.get<ProductCatalogue>({ url: catalogueEndpoint }),
    {
      enabled: !!accountId,
    },
  );
};
