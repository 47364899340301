import { useState } from 'react';
import { BreakdownWeek } from '../../types/bill-breakdown.types';

export const useWeeklyBreakdown = (usageBreakdown?: BreakdownWeek[]) => {
  const [weekIndex, setWeekIndex] = useState(0);
  const lastWeek = weekIndex + 1;
  const numWeeks = usageBreakdown?.length || 0;

  return {
    backDisabled: weekIndex === numWeeks - 1,
    forwardDisabled: weekIndex === 0,
    currentBreakdown: usageBreakdown && usageBreakdown[weekIndex],
    lastWeekBreakdown: usageBreakdown && usageBreakdown[lastWeek],
    backWeek: () => setWeekIndex(lastWeek),
    forwardWeek: () => setWeekIndex(weekIndex - 1),
  };
};
