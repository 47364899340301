import styled from 'styled-components';

export const StyledIconHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledIconHeaderText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin: 0;
  }
`;

export const StyledIconHeaderIcon = styled.div(
  ({ theme: { mediaQueries, space, colors } }) => `
    color: ${colors.primary};
    font-size: 4rem;
    line-height: 1;
    margin-top: ${space[1]};
    margin-right: ${space[2]};
    svg[data-icon="gas"] {
      color: ${colors.fuelGas};
    }    
    svg[data-icon="electricity"] {
      color: ${colors.fuelElec};
    }
    ${mediaQueries.smallAndUp} {
      font-size: 4.8rem;
      margin-right: ${space[3]};
    }
  `,
);
