import { Modal } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledGreenerEnergyModalContent = styled(Modal)`
  overflow: hidden;
  max-width: 60rem;
  width: 100%;

  ${({ theme: { mediaQueries, space } }) => `
    padding: ${space[6]};

    ${mediaQueries.smallAndUp} {
      padding-top: ${space[11]};
      padding-bottom: ${space[8]};
      padding-left: ${space[12]};
      padding-right: ${space[12]};
    };
    
    ${mediaQueries.mediumAndUp} {
      padding-top: ${space[8]};
    }
  `}
`;

export const StyledCircle = styled.svg`
  @keyframes animateInCircle {
    from {
      transform: translateY(-3rem);
    }
    to {
      transform: translateY(0);
    }
  }

  position: absolute;
  top: -2rem;
  right: 20%;
  animation: animateInCircle 1s ease-out forwards;

  ${({ theme: { mediaQueries } }) => `
    ${mediaQueries.mediumAndUp} {
      top: 0;
    };
  `}
`;

export const StyledDoughnut = styled.svg`
  @keyframes animateInDoughnut {
    from {
      transform: translateY(3rem) translateX(3rem);
    }
    to {
      transform: translateY(0) translateX(0);
    }
  }

  position: absolute;
  bottom: 0;
  right: 0;
  animation: animateInDoughnut 1s ease-out forwards;
`;

export const StyledTriangle = styled.svg`
  @keyframes animateInTriangle {
    from {
      transform: translateX(-5rem);
    }
    to {
      transform: translateX(0);
    }
  }

  position: absolute;
  top: 40%;
  left: -3rem;
  animation: animateInTriangle 1s ease-out forwards;

  ${({ theme: { mediaQueries } }) => `
    ${mediaQueries.smallAndUp} {
      left: 0;
    };
  `}
`;

export const StyledContent = styled.div`
  position: relative;
  z-index: 10;
`;
