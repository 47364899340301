import { Card, PrimaryCTALink, P } from '@ovotech/nebula';
import styled, { keyframes, css } from 'styled-components';

export const StyledSuccessCard = styled(Card)`
  ${({ theme: { mediaQueries } }) => `
display: flex;
margin 0 auto;
padding: 0 !important;
flex-direction: column-reverse;
${mediaQueries.mediumAndUp} {
display: flex;
margin 0 auto;
flex-direction: row;
position: relative;
};
`}
`;
export const StyledSuccessTextWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `
  display: flex;
  flex-direction: column;
  padding: 3.2rem;
  ${mediaQueries.mediumAndUp} {
      flex: 0 0 50%;
      padding-right: 0;
    };

  `}
`;

export const StyledHeadstartCard = styled(Card)`
  ${({ theme: { mediaQueries } }) => `

display: flex;
margin 0 auto;
flex-direction: column;
position: relative;;
padding: 0 !important;
margin-top: 4rem;
${mediaQueries.mediumAndUp} {
display: flex;
margin: 0 auto;
flex-direction: row;
margin-top: 4rem;
};
`}
`;

export const StyledHeadstartImage = styled.img`
  ${({ theme: { mediaQueries } }) => `
max-width: 100%;
object-fit: contain;
${mediaQueries.mediumAndUp} {
flex: 0 0 50%;
max-width: 50%;
width: 50%;
object-fit: cover;
};
`}
`;

export const StyledHeadstartTextWrapper = styled(StyledSuccessTextWrapper)`
  margin-top: 1rem;
  padding-left: 60px;
`;

export const StyledEVSpinLink = styled(PrimaryCTALink)`
  margin-top: 48px;
`;

export const StyledP = styled(P)`
  width: 100%;
  text-align: center;
  box-sizing: border-box;
`;

const circleLoader = keyframes`
    0% { background-color: #d1d6de; }
    50% { background-color: #0A9828; }
    90% { background-color: #d1d6de; }
    100% { background-color: #d1d6de; }
`;

function setAnimations(duration: number, delay: number) {
  const animationRule = css`
    ${circleLoader} ${duration}s linear ${delay}s infinite
  `;
  return animationRule;
}

export const LoadingAnimationWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  @media only screen and (max-width: 550px) {
    margin-left: 4px;
    margin-right: 4px;
  }
  .circle1 {
    animation: ${setAnimations(1.9, 0)};
  }
  .circle2 {
    animation: ${setAnimations(1.9, 0.15)};
  }
  .circle3 {
    animation: ${setAnimations(1.9, 0.3)};
  }
  .circle4 {
    animation: ${setAnimations(1.9, 0.45)};
  }
  .circle5 {
    animation: ${setAnimations(1.9, 0.6)};
  }
  .circle6 {
    animation: ${setAnimations(1.9, 0.75)};
  }
  .circle7 {
    animation: ${setAnimations(1.9, 0.9)};
  }
  .circle8 {
    animation: ${setAnimations(1.9, 1.05)};
  }
  .circle9 {
    animation: ${setAnimations(1.9, 1.2)};
  }
  .circle10 {
    animation: ${setAnimations(1.9, 1.35)};
  }
  .circle11 {
    animation: ${setAnimations(1.9, 1.5)};
  }
  .circle12 {
    animation: ${setAnimations(1.9, 1.75)};
  }

  div {
    width: 7px;
    height: 7px;
    background-color: #d1d6de;
    border-radius: 50%;
    margin-right: 3px;
    @media only screen and (min-width: 430px) {
      margin-right: 7px;
    }
    &.active {
      background-color: #0a9828;
    }
  }
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 10px;
`;

export const StyledFeaturesImage = styled.img<{ image: any; imageMobile: any }>`
  ${({ theme: { mediaQueries }, image, imageMobile }) => `
      max-width: 100%;
      object-fit: contain;
      width: 100%;
      content: url(${imageMobile});

    ${mediaQueries.mediumAndUp} {
      flex: 0 0 50%;
      object-fit: unset;
      margin-bottom: 0;
      content: url(${image});
    };
  `}
`;

export const StyledFeaturesImageWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `

    ${mediaQueries.mediumAndUp} {
      max-width: 50%;


    };
  `}
`;
