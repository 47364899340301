import { svgWidth } from '@/src/components/styled-utils';
import { mqSmallDown } from '@/src/utils/mediaQuery';
import styled from 'styled-components';
import { ReactComponent as connect } from '@/src/resources/connect_tado_icon.svg';

export const GreyCard = styled.div`
  background: #f3f3f3;
`;

export const CenterContent = styled.div`
  margin: auto;
  width: 80%;
  text-align: center;
  ${mqSmallDown(`
  width: 90%;
  `)}
`;

export const IconConnect = styled(connect)`
  ${svgWidth('10rem', 280, 400)}
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`;
