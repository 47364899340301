import { P, Checkbox } from '@ovotech/nebula';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Fuel } from '@/src/types/Response';
import { mqSmallDown } from '@/src/utils/mediaQuery';
import AnalyticsService from '@/src/services/analyticsService';

const StyledToggleKeyContainer = styled.div<{ showingCostToggle: boolean }>`
  ${({ theme: { space }, showingCostToggle }) => css`
    margin-left: ${space[8]};

    display: flex;
    flex-direction: ${showingCostToggle ? 'row' : 'column'};
    align-items: ${showingCostToggle ? 'center' : 'flex-start'};
    justify-content: space-between;

    ${mqSmallDown(`
      margin-left: 0;
      margin-top: ${space[2]};
      flex-direction: column;
    `)}
  `}
`;

const StyledToggleContainer = styled.div<{ showingCostToggle: boolean }>`
  width: 100%;
  height: 4rem;

  ${({ theme: { space }, showingCostToggle }) => css`
    margin-top: ${showingCostToggle ? 0 : space[4]};
  `}

  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    padding-right: 12px;
    margin-bottom: 0;
  }
`;

const StyledKeyContainer = styled.div<{ showingCostToggle: boolean }>`
  ${({ showingCostToggle }) => css`
    margin-top: ${showingCostToggle ? 0 : '1rem'};
    margin-left: ${showingCostToggle ? '1.2rem' : 0};
  `}

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledSpecificKey = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
`;

const StyledKeyCircle = styled.div<{
  backgroundColour: string;
}>`
  ${({ backgroundColour }) => css`
    background: ${backgroundColour};
  `};

  width: 20px;
  height: 20px;

  border-radius: 50%;
`;

const StyledKeyText = styled(P)`
  margin-top: 0;
  padding-left: 10%;

  width: 200px;
`;

type Props = {
  fuel: Fuel;
  toggleLastWeek: (showLastWeek: boolean) => void;
  showLastWeek: boolean;
  showingCostToggle: boolean;
};

const CompareBreakdownToggle = ({
  fuel,
  toggleLastWeek,
  showLastWeek,
  showingCostToggle,
}: Props) => {
  const theme = useTheme();
  return (
    <StyledToggleKeyContainer
      showingCostToggle={showingCostToggle}
      data-testid="compare-breakdown-toggle"
    >
      <StyledToggleContainer showingCostToggle={showingCostToggle}>
        <Checkbox
          label="Compare to last week"
          id="compare-checkbox"
          onChange={() => {
            toggleLastWeek(!showLastWeek);
            AnalyticsService.dispatch(
              `Energy Spotlights - ${
                showLastWeek ? 'hide' : 'show'
              } last weeks breakdown button clicked`,
            );
          }}
        />
      </StyledToggleContainer>
      {showLastWeek ? (
        <StyledKeyContainer showingCostToggle={showingCostToggle}>
          <StyledSpecificKey>
            <StyledKeyCircle
              backgroundColour={
                fuel === Fuel.ELECTRICITY
                  ? theme.colors.brand.electricity.base
                  : theme.colors.brand.gas.base
              }
            />
            <StyledKeyText>This week usage</StyledKeyText>
          </StyledSpecificKey>
          <StyledSpecificKey>
            <StyledKeyCircle
              backgroundColour={
                fuel === Fuel.ELECTRICITY
                  ? 'rgba(231, 162, 52, .35)'
                  : 'rgba(38, 173, 228, 0.35)'
              }
            />
            <StyledKeyText>Last week usage</StyledKeyText>
          </StyledSpecificKey>
        </StyledKeyContainer>
      ) : null}
    </StyledToggleKeyContainer>
  );
};

export default CompareBreakdownToggle;
