import { OrderType, PaymentType } from './types';
import { PRODUCT_IDS } from '@/src/constants/products';
import {
  CatalogueBolton,
  BoltonInstance,
  ProductId,
} from '@/src/types/Products';
import { EligiblityFuncReturn } from '@/src/redux/ducks/smartThermostats/check-eligibility';

export const hasSmartThermostatBolton = (boltons: BoltonInstance[]) => {
  const smartThermostatBoltons = [
    PRODUCT_IDS.smartThermostatSelfInstall,
    PRODUCT_IDS.smartThermostats,
    PRODUCT_IDS.smartThermostatsPayUpfrontSelfInstall,
    PRODUCT_IDS.smartThermostatsPayUpfrontProInstall,
    PRODUCT_IDS.heatingControlTwoYrFix,
  ];

  return boltons
    .filter(item => smartThermostatBoltons.includes(item.productId))
    .some(smartThermostatBolton => smartThermostatBolton.status === 'Active');
};

const isSmartThermostatBolton = (bolton: CatalogueBolton | BoltonInstance) =>
  bolton.productId === PRODUCT_IDS.smartThermostats ||
  bolton.productId === PRODUCT_IDS.smartThermostatSelfInstall ||
  bolton.productId === PRODUCT_IDS.smartThermostatsPayUpfrontSelfInstall ||
  bolton.productId === PRODUCT_IDS.smartThermostatsPayUpfrontProInstall ||
  bolton.productId === PRODUCT_IDS.heatingControlTwoYrFix;

export const eligibleForThermostatBolton = (
  catalogueBoltons: CatalogueBolton[],
  activeBoltons: BoltonInstance[],
): EligiblityFuncReturn => {
  let eligibility: EligiblityFuncReturn = {
    eligible: false,
    reason: 'BoltonNotFound',
  };

  if (activeBoltons) {
    const alreadyHas = activeBoltons.some(bolton =>
      isSmartThermostatBolton(bolton),
    );

    if (alreadyHas) {
      return {
        eligible: false,
        reason: 'AlreadyHas',
      };
    }
  }

  if (catalogueBoltons) {
    catalogueBoltons.some(bolton => {
      if (isSmartThermostatBolton(bolton)) {
        if (bolton.eligibility.eligible) {
          eligibility = { eligible: true };
          return true;
        } else {
          eligibility = {
            eligible: false,
            reason: bolton.eligibility.reason ?? 'Unknown',
          };
          return false;
        }
      } else {
        return false;
      }
    });
  }

  return eligibility;
};

export const selectThermostatBolton = (
  paymentType: PaymentType,
  orderType: OrderType,
) => {
  let boltonId: ProductId;
  if (paymentType === PaymentType.FullAmountPayment) {
    boltonId =
      orderType === OrderType.ProfessionalInstall
        ? PRODUCT_IDS.smartThermostatsPayUpfrontProInstall
        : PRODUCT_IDS.smartThermostatsPayUpfrontSelfInstall;
  } else {
    boltonId =
      orderType === OrderType.ProfessionalInstall
        ? PRODUCT_IDS.smartThermostats
        : PRODUCT_IDS.smartThermostatSelfInstall;
  }

  return boltonId;
};
