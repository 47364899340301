import { Heading3, P, Ul, Li } from '@ovotech/nebula';
import { AnalyticsRender, useAnalytics } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { DISCOUNT_END_DATE } from '../../constants/constants';
import Offer from './Offer/Offer';
import {
  FootnoteReferenced,
  FootnoteReferencedContent,
} from '@/src/components';

import { HOMEPLAN_PROMO_OFFER_CARD } from '@/src/constants/analytics-subjects';
import { createHomePlanUrl } from '@/src/constants/endpoints';
import image from '@/src/resources/offers/boiler-cover.jpg';
import roundel from '@/src/resources/offers/home-service-6-month-roundel.png';

type Props = {
  accountId: string;
};

const noClaimsFooter = (
  <>
    No claims can be made in the first 30 days. Terms, conditions, eligibility
    criteria, exclusions and cancellation charges apply.
  </>
);

const homeServicePromoFooter = (
  <>
    * Offer available to new customers only, and not available for existing
    CORGI HomePlan, OVO HomePlan and OVO Energy boiler cover customers. Offer
    expires {format(DISCOUNT_END_DATE, 'DD/MM/YYYY')}
  </>
);

const ovoTradingName = (
  <>
    OVO Energy is a trading name of CORGI HomePlan Ltd, a firm authorised and
    regulated by the Financial Conduct Authority under firm reference number
    824122 to carry on insurance distribution. CORGI HomePlan Ltd is registered
    in Scotland (Company No. SC358475). Registered Office: Cadworks, 41 West
    Campbell Street, Glasgow, G2 6SE. CORGI HomePlan Ltd is part of OVO Group
    Ltd.
  </>
);

const footnoteOHPPromo: FootnoteReferencedContent = {
  id: 'OHPPromo',
  body: [noClaimsFooter, homeServicePromoFooter, ovoTradingName],
};

export const HomePlanDiscountPromoOffer = ({ accountId }: Props) => {
  const analytics = useAnalytics();

  return (
    <AnalyticsRender name={HOMEPLAN_PROMO_OFFER_CARD}>
      <Offer
        image={image}
        offerId="homePlanDiscountPromo"
        linkUrl={createHomePlanUrl('homePlanDiscountPromo', accountId)}
        linkText="Get covered"
        promoImage={roundel}
        tracking={() => {
          analytics.dispatch({
            name: HOMEPLAN_PROMO_OFFER_CARD,
            type: 'click',
          });
        }}
      >
        <Heading3 as="h2">
          Boiler and central heating cover you can count on
        </Heading3>
        <P>
          Keep chills and big repair bills at bay with our boiler cover.
          <FootnoteReferenced content={footnoteOHPPromo} />
        </P>

        <Ul>
          <Li>Unlimited call-outs</Li>
          <Li>An annual home boiler service</Li>
          <Li>Access to a nationwide network of Gas Safe engineers</Li>
          <Li>A 24/7 emergency helpline</Li>
        </Ul>
        <P>From £7.50 for the first 6 months (£15 a month thereafter)*</P>
      </Offer>
    </AnalyticsRender>
  );
};
