import { useQuery } from 'react-query';
import { carbonZeroBffEndpoints } from '@/src/constants/endpoints';
import { BreakdownPageResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

export const getBreakdownPageQueryKey = (accountId?: string | null) =>
  `breakdown-page-${accountId}`;

type Params = {
  accountId?: string | null;
  enabled?: boolean;
  onSuccess?: (data: BreakdownPageResponse) => void;
  onError?: (error: Error) => void;
};

export const useBreakdownPageQuery = ({
  accountId,
  enabled = true,
  onSuccess,
  onError,
}: Params) => {
  return useQuery(
    getBreakdownPageQueryKey(accountId),
    () =>
      !accountId
        ? null
        : apiService.get<BreakdownPageResponse>({
            url: carbonZeroBffEndpoints.getBreakdownPage(accountId),
          }),
    {
      enabled: enabled && !!accountId,
      onSuccess,
      onError,
    },
  );
};
