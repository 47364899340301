import { P, Strong, Heading3, Margin } from '@ovotech/nebula';
import React from 'react';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';

export const HighRiskDDCancellation: ActionCentreBanner = ({ dismiss }) => {
  return (
    <Banner data-testid="high-risk-dd-cancel" icon="pound" dismiss={dismiss}>
      <Margin all={2}>
        <Heading3 as="h2">You're paying by Direct Debit</Heading3>
        <P>
          It's the cheapest way to pay for your energy, saving up to{' '}
          <Strong>£123</Strong> a year
        </P>
      </Margin>
    </Banner>
  );
};
