import { graphql } from '@/src/api/contentful/__generated__';
import { EntryHyperLinkFragmentFragment } from '@/src/api/contentful/__generated__/graphql';
import {
  getCallToAction,
  getOvoUrlFromPath,
} from '@/src/utils/contentful/getCallToAction';
import { Block, Inline } from '@contentful/rich-text-types';
import { TextLink } from '@ovotech/nebula';
import React, { ReactNode } from 'react';

export const EntryHyperLinkFragment = graphql(/* contentful-codegen-graphql */ `
  fragment EntryHyperLinkFragment on PackStructuredTextTextLinks {
    entries {
      hyperlink {
        sys {
          id
        }
        __typename
        ... on WrapperUrl {
          url
        }
        ... on BuildWebpage {
          slug
        }
        ... on BuildCategoryWebpage {
          pathname
        }
        ... on WrapperDocument {
          document {
            url
          }
        }
        ... on PackCta {
          ...PackCTAFragment
        }
      }
    }
  }
`);

export type HyperLinkEntry = NonNullable<
  EntryHyperLinkFragmentFragment['entries']['hyperlink'][number]
>;

interface EntryHyperLinkProps {
  entryMap: Map<string, HyperLinkEntry>;
  node: Block | Inline;
  children: ReactNode;
}

export const EntryHyperlink = ({
  entryMap,
  node,
  children,
}: EntryHyperLinkProps) => {
  const entry = entryMap.get(node.data.target.sys.id);

  if (!entry) return null;

  if (entry.__typename === 'WrapperUrl') {
    if (!entry.url || !('url' in entry)) return;
    return <TextLink href={entry.url}>{children}</TextLink>;
  }

  if (entry.__typename === 'BuildWebpage') {
    if (!entry.slug || !('slug' in entry)) return;
    const url = getOvoUrlFromPath(entry.slug);
    return <TextLink href={url}>{children}</TextLink>;
  }

  if (entry.__typename === 'BuildCategoryWebpage') {
    if (!entry.pathname || !('pathname' in entry)) return;
    const url = getOvoUrlFromPath(entry.pathname);
    return <TextLink href={url}>{children}</TextLink>;
  }

  if (entry.__typename === 'WrapperDocument') {
    return <TextLink href={entry?.document?.url}>{children}</TextLink>;
  }

  if (entry.__typename === 'PackCta') {
    const cta = getCallToAction(entry);
    if (!cta?.url) return <>{children}</>;
    return <TextLink href={cta.url}>{children}</TextLink>;
  }
};
