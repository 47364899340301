export enum CarbonIntensityLevel {
  Low = 'low',
  Moderate = 'moderate',
  High = 'high',
}

export type CarbonIntensityForecast = {
  from: string;
  intensity: number;
  colour: string;
};

export type NextGreenTimes = {
  start: string | null;
  end: string | null;
  day: string | null;
};
