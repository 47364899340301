import { useMutation } from 'react-query';
import { ENDPOINT_CARD_PAYMENT } from '@/src/constants/endpoints';
import { CreateCardPaymentSessionResponse } from '@/src/types/Response';
import AnalyticsService from '@/src/services/analyticsService';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `card-payment-${string}`;
export type CreateCardPaymentSessionBody = {
  AccountId: string;
  AccountBalance: string;
  CustomerAddressLine1?: string;
  CustomerAddressLine2?: string;
  CustomerAddressCity?: string;
  CustomerAddressCounty?: string;
  CustomerAddressPostCode?: string;
  CustomerAddressCountry: 'GB';
  CustomerId: string;
};

export function useCreateCardPaymentSessionMutation(accountId?: string | null) {
  return useMutation({
    mutationFn: (body: CreateCardPaymentSessionBody) =>
      apiService.post<CreateCardPaymentSessionResponse | null>({
        url: ENDPOINT_CARD_PAYMENT,
        body,
        headers: {
          platform: 'web',
        },
      }),
    mutationKey: `card-payment-${accountId}` as QueryCacheKey,
    onMutate: () => {
      AnalyticsService.dispatch(
        'Card Payment Session - Generating session url - Start',
      );
    },
    onSuccess: (data: CreateCardPaymentSessionResponse | null) => {
      AnalyticsService.dispatch(
        'Card Payment Session - Generating session url - Success',
        {
          url: data?.SessionUrl,
        },
      );
    },
    onError: (error: any) => {
      AnalyticsService.dispatch(
        'Card Payment Session - Generating session url - Failure',
        {
          status: error?.response?.status,
        },
      );
    },
  });
}
