import { configService } from '@ovotech/energy-cx';
import React, { ReactNode, useEffect, useState } from 'react';
import { LoadingSpinner } from '@/src/components';
import { IS_KAPI_SUPPLY_POINTS_ENABLED } from '@/src/constants/feature-flags';
import { optimizely } from '@/src/utils/optimizely';

type Props = {
  children: ReactNode;
};

export const KapiFeature = ({ children }: Props) => {
  const config = configService.getConfig();
  const [loaded, setLoaded] = useState(false);

  const isKapiSupplyPointsEnabled = optimizely.isFeatureEnabled(
    IS_KAPI_SUPPLY_POINTS_ENABLED,
  );

  useEffect(() => {
    if (!loaded) {
      optimizely
        .onReady()
        .then(({ success }) => {
          if (success) {
            const kapiConfig = {
              accountSummary: false,
              plan: false,

              supplyPoints: isKapiSupplyPointsEnabled,
            };

            // Don't set config if it's already the value we want.
            if (JSON.stringify(config.kapi) !== JSON.stringify(kapiConfig)) {
              configService.setConfig({
                ...config,
                kapi: kapiConfig,
              });
            }

            setLoaded(true);
          } else {
            throw new Error('Optimizely init failed');
          }
        })
        .catch(() => {
          // render as normal if optimizely fails.
          setLoaded(true);
        });
    }
  }, [config, loaded, isKapiSupplyPointsEnabled]);

  return (
    <div data-testid="kapi-feature">
      {loaded ? children : <LoadingSpinner />}
    </div>
  );
};
