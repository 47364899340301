import React from 'react';
import NavLink from './NavLink';
import { MENU } from '@/src/constants/analytics';
import { ROUTE_MOVING_HOME } from '@/src/constants/routes';

type LinkProps = {
  path: string;
  closeMobileMenu?: () => void;
};

const MovingHomeLink = ({ path, closeMobileMenu }: LinkProps) => (
  <NavLink
    path={path}
    route={ROUTE_MOVING_HOME}
    label="Moving home"
    onClick={closeMobileMenu}
    data-event-name={MENU.MOVING_HOME}
  />
);

export default MovingHomeLink;
