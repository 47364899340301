import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';

import { USAGE_ROUTES } from '@/src/constants/endpoints';
import apiService from '@/src/services/apiService';
import {
  Action,
  GetDateRangeMonthlyUsageError,
  GetDateRangeMonthlyUsageStart,
  GetDateRangeMonthlyUsageSuccess,
} from '@/src/types/Action';
import { AjaxError } from '@/src/types/Ajax';
import {
  MonthlyUsageGroupedByYear,
  MonthlyUsageResponse,
} from '@/src/types/Response';
import { MonthlyUsage } from '@/src/types/State';

/**
 * @deprecated use api tooling.
 */
export const getDateRangeMonthlyUsageStart = (
  years: string[],
): GetDateRangeMonthlyUsageStart => ({
  type: 'GET_DATE_RANGE_MONTHLY_USAGE_START',
  isFetchingUsageDataByYear: true,
  years,
});

/**
 * @deprecated use api tooling.
 */
export const getDateRangeMonthlyUsageSuccess = (data: {
  [key: string]: MonthlyUsageResponse;
}): GetDateRangeMonthlyUsageSuccess => ({
  type: 'GET_DATE_RANGE_MONTHLY_USAGE_SUCCESS',
  isFetchingUsageDataByYear: false,
  data,
});

/**
 * @deprecated use api tooling.
 */
export const getDateRangeMonthlyUsageError = (
  err: AjaxError,
): GetDateRangeMonthlyUsageError => ({
  type: 'GET_DATE_RANGE_MONTHLY_USAGE_ERROR',
  isFetchingUsageDataByYear: false,
  errorResponse: err.response,
});

export const getDateRangeMonthlyUsageEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  // @ts-ignore
  Observable.combineLatest(
    action$.ofType('GET_DATE_RANGE_MONTHLY_USAGE_START'),
  ).switchMap((actions: Array<GetDateRangeMonthlyUsageStart>) => {
    const { years } = actions.find(
      ({ type }) => type === 'GET_DATE_RANGE_MONTHLY_USAGE_START',
    ) as GetDateRangeMonthlyUsageStart;
    const { selectedAccountId } = store.getState().user;
    if (!selectedAccountId) {
      return Observable.of(
        // @ts-ignore
        getDateRangeMonthlyUsageError({ response: 'No account id set' }),
      );
    }

    return Observable.forkJoin(
      years.map((year: string) =>
        apiService
          .get({
            responseType: 'json',
            url: `${
              USAGE_ROUTES.MONTHLY_USAGE
            }/${selectedAccountId}?date=${encodeURIComponent(year)}`,
            headers: { Accept: 'application/json;version=2' },
          })
          .map(({ response }: { response: MonthlyUsageResponse }) => ({
            response,
            year,
          })),
      ),
    )
      .map((data: Array<{ response: MonthlyUsageResponse; year: string }>) => {
        const grouppedByYear: MonthlyUsageGroupedByYear = data.reduce(
          (acc, curr) => {
            return {
              ...acc,
              [curr.year]: curr.response,
            };
          },
          {},
        );
        return getDateRangeMonthlyUsageSuccess(grouppedByYear);
      })
      .catch(err => {
        return Observable.of(getDateRangeMonthlyUsageError(err));
      });
  });

export const defaultMonthlyUsageState: MonthlyUsage = {
  isFetching: false,
  isFetchingUsageDataByYear: false,
  data: {
    electricity: {
      data: [],
      next: false,
      prev: false,
    },
    gas: {
      data: [],
      next: false,
      prev: false,
    },
  },
  usageDataByYear: {},
};

const monthlyUsage = (
  state: MonthlyUsage = defaultMonthlyUsageState,
  action: Action,
): MonthlyUsage => {
  switch (action.type) {
    case 'GET_DATE_RANGE_MONTHLY_USAGE_START': {
      return {
        ...state,
        isFetchingUsageDataByYear: action.isFetchingUsageDataByYear,
      };
    }
    case 'GET_DATE_RANGE_MONTHLY_USAGE_SUCCESS': {
      return {
        ...state,
        isFetchingUsageDataByYear: false,
        usageDataByYear: action.data,
      };
    }
    case 'GET_DATE_RANGE_MONTHLY_USAGE_ERROR': {
      return {
        ...state,
        isFetchingUsageDataByYear: false,
        errorResponse: action.errorResponse,
      };
    }
    default: {
      return state;
    }
  }
};

export default monthlyUsage;
