import { Heading2, Heading4, P } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExclamationIcon } from '@/src/resources/exclamation-icon.svg';

export const HasDDModalContent = () => {
  const StyledModalWrapper = styled.div`
    padding: 70px 22px;
  `;

  const StyledHeading2 = styled(Heading2)`
    color: ${({ theme }) => theme.colors.ovo};
  `;

  const StyledHeading4 = styled(Heading4)`
    color: ${({ theme }) => theme.colors.ovo};
  `;

  return (
    <StyledModalWrapper>
      <ExclamationIcon />
      <StyledHeading2>We’re checking your balance </StyledHeading2>
      <P>
        Your account balance may look higher than usual. We’ve delayed sending
        you a bill to check the information on our systems and protect you from
        being overcharged.
      </P>
      <P>Once we’ve checked everything’s correct, we’ll send your next bill.</P>
      <StyledHeading4>Please send a recent meter reading</StyledHeading4>
      <P>
        If you haven’t sent a meter reading recently, please send one when you
        can, even if you have a smart meter. This helps speed up our checks.
      </P>

      <StyledHeading4>Please keep your Direct Debit set up</StyledHeading4>
      <P>
        Once we’ve checked your balance is correct, we’ll send you a new bill.
        In the meantime, please keep paying your Direct Debit as normal.
      </P>
      <StyledHeading4>We’re here to help </StyledHeading4>
      <P>
        If you have any questions, call us on 0330 303 5063. We're open Monday
        to Friday 9am - 5pm.
      </P>
    </StyledModalWrapper>
  );
};
