import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore as createReduxStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import createRootReducer from './create-root-reducer';
import orionEpics from './epics';
import { history } from './history';
import { createAnalyticsMiddleware } from './middleware/analytics/analyticsMiddleware';

import orionReducers from './reducers';

type Options = {
  reducers?: Record<string, any>;
  epics?: Array<any>;
  middlewares?: Array<any>;
};

function createStore({
  reducers = {},
  epics = [],
  middlewares = [],
}: Options = {}) {
  const combinedEpics = [...orionEpics, ...epics];
  const rootReducer = createRootReducer({ ...reducers, ...orionReducers });
  const rootEpic = combineEpics(...combinedEpics);

  const epicMiddleware = createEpicMiddleware(rootEpic);
  const routingMiddleware = routerMiddleware(history);
  const analyticsMiddleware = createAnalyticsMiddleware();

  const orionMiddlewares = [
    epicMiddleware,
    routingMiddleware,
    analyticsMiddleware,
  ];
  const combinedMiddlewares = [...orionMiddlewares, ...middlewares];

  return createReduxStore(
    // @ts-ignore ts not happy with receiving partial state in root reducer
    rootReducer,
    composeWithDevTools(applyMiddleware(...combinedMiddlewares)),
  );
}

export default createStore;
