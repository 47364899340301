import { CTALink, Heading2, P } from '@ovotech/nebula';
import { AnalyticsRender, useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { ActionCentreBanner, Banner } from '../Banner';
import {
  HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED,
  HOME_PLAN_SVT_PLAN_ADDON_Q1_OFFER,
} from '@/src/constants/analytics-subjects';
import { HOME_PLAN_ENERGY_CREDITS_PROMO_SVT } from '@/src/constants/routes';
import { isAfter, isBefore } from 'date-fns';
import {
  OHS_Q1_OFFER_END_DATE,
  OHS_Q1_OFFER_START_DATE,
} from '@/src/pages/Offers/constants';
import { useDecision, useFeature } from '@/src/utils/optimizely';
import {
  ENERGY_CREDITS_PROMO,
  HOMEPLAN_Q1_PROMO_OFFER,
} from '@/src/constants/feature-flags';
import { HomePlan } from '@/src/components/ActionCentre/Banners/HomePlan/HomePlan';

export const HomePlanEnergyCreditsPromoSVT: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const { accountId } = useAccountContext();

  const homeplanUrl =
    process.env.NX_HOMEPLAN_URL || `https://homeplan.ovoenergy.com`;

  const redirectUrl = `${homeplanUrl}?accountId=${accountId}&${HOME_PLAN_ENERGY_CREDITS_PROMO_SVT}`;

  const currentDate = new Date();
  const isWithinQ1PromoOfferPeriod =
    isAfter(currentDate, OHS_Q1_OFFER_START_DATE) &&
    isBefore(currentDate, OHS_Q1_OFFER_END_DATE);

  const [{ enabled: isHomePlanQ1Offer }] = useDecision(HOMEPLAN_Q1_PROMO_OFFER);
  const [isEnergyCreditsPromo] = useFeature(ENERGY_CREDITS_PROMO);

  if (isHomePlanQ1Offer && isWithinQ1PromoOfferPeriod) {
    return (
      <AnalyticsRender name={HOME_PLAN_SVT_PLAN_ADDON_Q1_OFFER}>
        <Banner icon="boilerGreen" dismiss={dismiss}>
          <Heading2 data-testid="banner-title">
            6 months half price boiler cover
          </Heading2>
          <P data-testid="banner-body">
            Unlimited callouts, annual boiler service, 24/7 helpline. Terms and
            cancellation fees apply. Offer ends 21/03/24.
          </P>
          <CTALink href={redirectUrl} onClick={onClick}>
            See plans
          </CTALink>
        </Banner>
      </AnalyticsRender>
    );
  }

  if (isEnergyCreditsPromo) {
    return (
      <AnalyticsRender name={HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED}>
        <Banner icon="boilerGreen" dismiss={dismiss}>
          <Heading2 data-testid="banner-title">
            Expert boiler cover + £100 credit
          </Heading2>
          <P data-testid="banner-body">
            Get £100 energy credit when you take one of our plans. Terms and
            cancellation fees apply.
          </P>
          <CTALink href={redirectUrl} onClick={onClick}>
            See plans
          </CTALink>
        </Banner>
      </AnalyticsRender>
    );
  }

  return <HomePlan onClick={onClick} dismiss={dismiss} />;
};
