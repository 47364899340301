import { Col, Row } from '@ovotech/nebula';
import React from 'react';
import { SkeletonCardWithCTA } from '../SkeletonCard';
import { DisplayFlex } from '../../../../components/layout';

function RowLoading() {
  return (
    <Row isNested data-testid="row-loading">
      <Col medium={6} as={DisplayFlex}>
        <SkeletonCardWithCTA />
      </Col>

      <Col medium={6} as={DisplayFlex}>
        <SkeletonCardWithCTA />
      </Col>
    </Row>
  );
}

export default RowLoading;
