import axios, { AxiosResponse } from 'axios';
import { reportError } from '@/src/services/datadog';
import { TypedDocumentString } from '@/src/api/contentful/__generated__/graphql';

type Variables = Record<string, unknown>;

type ErrorResponse = {
  message?: string;
  [key: string]: unknown;
};

type JSONResponse<T> = {
  data?: T;
  errors?: ErrorResponse[];
};

const ERROR_TYPE = 'contentful-fetch';

export async function fetchContentfulData<T, V extends Variables>(
  document: TypedDocumentString<T, V>,
  variables?: V,
): Promise<T> {
  let response: AxiosResponse<JSONResponse<T>>;

  const body = JSON.stringify({
    query: document.toString(),
    variables: {
      ...variables,
      preview: process.env.NX_CONTENTFUL_FETCH_MODE === 'preview',
    },
  });

  try {
    response = await axios.post(
      process.env.NX_CONTENTFUL_GRAPHQL_ENDPOINT!,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            process.env.NX_CONTENTFUL_FETCH_MODE === 'preview'
              ? process.env.NX_CONTENTFUL_SPACE_PREVIEW_TOKEN
              : process.env.NX_CONTENTFUL_SPACE_CDA_TOKEN
          }`,
        },
        adapter: 'fetch',
      },
    );
  } catch (error) {
    reportError(error, {
      type: ERROR_TYPE,
    });
    throw error;
  }

  if (!response?.data?.data) {
    const error = new Error('Contentful Graphql API fetch returned no data');
    reportError(error, {
      type: ERROR_TYPE,
      cause: JSON.stringify(response?.data?.errors),
    });
    throw error;
  }

  if (response?.data?.errors) {
    reportError(new Error('Contentful json errors'), {
      type: ERROR_TYPE,
      cause: JSON.stringify(response.data.errors),
    });
  }

  return response.data.data;
}
