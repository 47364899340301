import { useAccountContext } from '@ovotech/ui-tools';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useContractsQuery,
  useDirectDebitQuery,
  useProductsQuery,
} from '@/src/api';

import { ContractsResponse, DirectDebitResponse } from '@/src/types/Response';
import { State } from '@/src/types/State';
import {
  checkEligibilityForSmartThermostatPayMonthlyStart,
  checkEligibilityForSmartThermostatProfessionalInstallStart,
  checkEligibilityForSmartThermostatStart,
} from '@/src/redux/ducks/smartThermostats/smart-thermostats-info';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import { getProductCatalogueStart } from '@/src/redux/ducks/products/products';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';

export interface GetEligibilityInfoResult {
  isFetching: boolean;
  isError: boolean;
  eligible: boolean | undefined;
  professionalInstallEligibility: boolean | undefined;
  payMonthlyEligibility: boolean | undefined;
}

// TODO - Rewrite function to only use useQueries
export function useEligibilityInfo(): GetEligibilityInfoResult {
  const dispatch = useDispatch();
  const { selectedAccountId } = useSelector((state: State) => state.user);

  const recommendedDD = useSelector(
    (state: State) => state.recommendedDirectDebit,
  );

  const products = useSelector((state: State) => state.products);

  const { accountId } = useAccountContext();
  const activatedProducts = useProductsQuery(accountId);
  const contracts = useContractsQuery(accountId);
  const directDebit = useDirectDebitQuery(accountId);
  const profile = useProfileQueryKapi();

  const smartThermostats = useSelector(
    (state: State) => state.smartThermostats,
  );

  const promoCode = getQueryParamValue('promoCode');

  const isAllDataDefined = [
    activatedProducts?.data,
    contracts.data,
    directDebit.data,
    products.catalogue.data,
    profile.data,
    recommendedDD.data,
  ].every(x => x != undefined);

  useEffect(() => {
    if (selectedAccountId) {
      if (
        !products.catalogue.isFetching &&
        !products.catalogue.data &&
        !products.catalogue.errorResponse
      ) {
        dispatch(getProductCatalogueStart(promoCode));
      }

      if (
        !smartThermostats.isFetching &&
        isAllDataDefined &&
        profile.data &&
        profile.data.customer_nextV1
      ) {
        dispatch(checkEligibilityForSmartThermostatStart());
        dispatch(
          checkEligibilityForSmartThermostatProfessionalInstallStart(
            profile.data,
          ),
        );
        dispatch(
          checkEligibilityForSmartThermostatPayMonthlyStart(
            directDebit.data as DirectDebitResponse, // != undefined check ensures it is populated
            contracts.data as ContractsResponse,
          ),
        );
      }
    }
  }, [
    contracts.data,
    directDebit.data,
    dispatch,
    isAllDataDefined,
    products.catalogue.data,
    products.catalogue.errorResponse,
    products.catalogue.isFetching,
    profile.data,
    promoCode,
    selectedAccountId,
    smartThermostats.isFetching,
  ]);

  const { general, proInstall, payMonthly } =
    smartThermostats?.eligibility ?? {};

  const isFetching = [
    recommendedDD,
    products.catalogue,
    activatedProducts,
    contracts,
    directDebit,
    profile,
    smartThermostats,
  ].some(data => data.isFetching);

  const isError =
    [recommendedDD, products.catalogue].some(data => data.errorResponse) ||
    [activatedProducts, contracts, directDebit, profile].some(
      data => data.isError,
    ) ||
    smartThermostats.error ||
    (!recommendedDD.isFetching && !recommendedDD.data?.amount);

  return {
    isFetching,
    isError,
    eligible: general?.eligible,
    professionalInstallEligibility: proInstall?.eligible,
    payMonthlyEligibility: payMonthly?.eligible,
  };
}

export const useSmartThermostatEligibility = () => {
  return useSelector(
    (state: State) => state.smartThermostats.eligibilityAnswer,
  );
};
