import {
  useProductActivationMutation,
  useProductCatalogueQueryV2,
} from '@/src/api';
import { PRODUCT_IDS } from '@/src/constants/products';
import { UseMutationResult } from 'react-query';
import { ProductActivationMutationProps } from '@/src/api/use-product-activation-mutation';

export type PayAsYouDriveProduct = {
  activateProduct(promoCode?: string): Promise<void>;
  isReady: boolean;
  productActivationMutation: UseMutationResult<
    unknown,
    unknown,
    ProductActivationMutationProps,
    unknown
  >;
};

export const usePayAsYouDriveProduct = (): PayAsYouDriveProduct => {
  const productActivationMutation = useProductActivationMutation();
  const productsCatalogue = useProductCatalogueQueryV2();
  const catalogueBoltons = productsCatalogue?.data?.boltons ?? [];
  const payAsYouDriveProduct = catalogueBoltons.find(
    bolton => bolton.productId === PRODUCT_IDS.payasyoudrive,
  );

  const isReady = productsCatalogue?.isFetched;

  const canActivate =
    productActivationMutation.isIdle &&
    payAsYouDriveProduct !== undefined &&
    payAsYouDriveProduct.eligibility.eligible;

  return {
    activateProduct: async (promoCode?: string) => {
      if (canActivate) {
        await productActivationMutation.mutateAsync({
          product: payAsYouDriveProduct,
          promo: promoCode,
        });
      }
    },
    isReady,
    productActivationMutation,
  };
};
