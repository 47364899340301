import {
  CTAButton,
  PrimaryCTAButton,
  Heading2,
  Heading3,
} from '@ovotech/nebula';
import * as React from 'react';
import {
  StyledCard,
  StyledImageContainer,
  ClippedImg,
  CTAWrapper,
  StyledContentContainer,
  StyledBody,
} from './styles';
import theme from '@/src/core/theme';

type Props = {
  title: string;
  body?: string | JSX.Element;
  onClickCta?: () => void;
  onClickClose?: () => void;
  ctaTitle?: string;
  ctaVariant?: 'primary' | 'secondary';
  inverted?: boolean;
  imgSrc?: string;
  inline?: boolean;
};

export const ActionCardBase = ({
  onClickCta,
  onClickClose,
  body,
  title,
  ctaTitle,
  ctaVariant = 'primary',
  inverted,
  imgSrc,
  inline,
}: Props) => {
  const Heading = inline ? Heading3 : Heading2;

  return (
    <StyledCard inverted={inverted} inline={inline}>
      {imgSrc ? (
        <StyledImageContainer>
          <ClippedImg url={imgSrc} />
        </StyledImageContainer>
      ) : null}
      <StyledContentContainer hasImage={!!imgSrc}>
        <Heading
          style={{
            color: inverted ? theme.colors.green.lighter : theme.colors.ovo,
          }}
        >
          {title}
        </Heading>
        <>
          {typeof body === 'string' ? (
            <StyledBody inverted={inverted}>{body}</StyledBody>
          ) : (
            body
          )}
        </>
      </StyledContentContainer>
      <CTAWrapper>
        {ctaTitle && onClickCta && (
          <PrimaryCTAButton
            data-testid="cta-button"
            variant={ctaVariant}
            iconRight="chevron-right"
            onClick={onClickCta}
          >
            {ctaTitle}
          </PrimaryCTAButton>
        )}
        {onClickClose ? (
          <CTAButton variant="secondary" onClick={onClickClose}>
            Close
          </CTAButton>
        ) : null}
      </CTAWrapper>
    </StyledCard>
  );
};
