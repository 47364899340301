import React from 'react';

export const ClockArrowImage = () => (
  <svg
    width="119"
    height="121"
    viewBox="0 0 119 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#B6D727"
      d="M17.695 0l100.352 17.695-17.695 100.352L0 100.352z"
    />
    <circle
      cx="98.597"
      cy="100.598"
      r="18.598"
      transform="rotate(-180 98.597 100.598)"
      fill="#0C7494"
    />
    <path
      d="M98.695 109.195v-15.5M92.195 100.195l6.5-6.5 6.5 6.5"
      stroke="#fff"
      strokeWidth="2"
    />
    <path
      opacity=".3"
      d="M75.33 27H59.22v6.444H75.33V27zM70.497 33.444h-6.445v4.834h6.445v-4.834z"
      fill="#0A9928"
    />
    <path
      opacity=".3"
      d="M41.336 76.945H30.219v6.444h16.433c-2.094-1.933-3.866-4.028-5.316-6.444zM20.552 64.055V70.5h17.723a41.641 41.641 0 01-1.45-6.445H20.552z"
      fill="#0D8426"
    />
    <path
      opacity=".3"
      d="M26.997 51.167v6.444h9.828c.16-2.255.805-4.35 1.45-6.444H26.996z"
      fill="#0A9928"
    />
    <path
      d="M67.275 36.667c-13.373 0-24.167 10.794-24.167 24.166C43.108 74.206 53.902 85 67.275 85 80.647 85 91.44 74.206 91.44 60.833c0-13.372-10.794-24.166-24.166-24.166zm3.222 24.166h-6.445v-16.11h6.445v16.11z"
      fill="#0A9928"
    />
    <path
      opacity=".3"
      d="M88.235 36.597l-13.952 8.056 3.222 5.58 13.952-8.055-3.222-5.58z"
      fill="#0A9928"
    />
  </svg>
);
