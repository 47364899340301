import { format } from 'date-fns';
import {
  EVsInlifeDataSeries,
  MonthYear,
  EVsInlifeDataMonthlyResponse,
} from '@/src/types/State';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getDateFromParams = (month: string, year: string): MonthYear => {
  const date = { year: parseInt(year, 10), month: parseInt(month, 10) };
  return date;
};

export const dateSuffix = (date: number): string => {
  if (date > 3 && date < 21) {
    return 'th';
  }
  switch (date % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const mapToMonths = (number: number): string => {
  return months[number - 1];
};

export const defaultDateRedirectPath = (path: string, date: Date): string => {
  const defaultDatePath = `${path}/${format(date, 'YYYY/MM')}`;
  return defaultDatePath;
};

export const checkMissingBreakdownData = (data: EVsInlifeDataSeries) => {
  return data.some(
    ({ home, electricVehicle }) =>
      home.cost === null ||
      home.usageInKWH === null ||
      electricVehicle.cost === null ||
      electricVehicle.usageInKWH === null,
  );
};

export const getAverageBreakdownValue = (
  arr: EVsInlifeDataSeries,
  field: 'cost' | 'usageInKWH',
  type: 'electricVehicle' | 'home',
) => {
  const costs = arr
    .map(day => day[type][field])
    .filter((el): el is number => typeof el === 'number');

  const nonZeroCosts = costs.filter(el => el !== 0);

  return costs.reduce((el, total) => el + total, 0) / nonZeroCosts.length;
};

export const isMissingAnyRequestedMonthAggregateData = (
  requestedMonthSummary: EVsInlifeDataMonthlyResponse['monthlySummary']['requestedMonth'],
  usageUnit: 'kwh' | 'pounds',
) => {
  if (usageUnit === 'pounds') {
    const isMissingAnyCostValues =
      requestedMonthSummary.electricVehicle.cost === null ||
      requestedMonthSummary.home.cost === null ||
      requestedMonthSummary.totalCost === null;
    return isMissingAnyCostValues;
  }

  const isMissingAnyKWHValues =
    requestedMonthSummary.electricVehicle.usageInKWH === null ||
    requestedMonthSummary.home.usageInKWH === null ||
    requestedMonthSummary.totalUsage === null;

  return isMissingAnyKWHValues;
};
