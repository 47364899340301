import { PRODUCT_IDS } from '@/src/constants/products';
import {
  BoltonInstance,
  BundleInstance,
  CatalogueBolton,
  ProductId,
} from '@/src/types/Products';
import { ProductCatalogue, ProductsResponse } from '@/src/types/Response';

export const hasBolton = (
  boltons: Array<BoltonInstance>,
  boltonId: ProductId,
) => !!boltons.find(b => b.productId === boltonId);

export const hasActiveBolton = (
  boltons: Array<BoltonInstance> | undefined,
  boltonId: ProductId,
) => !!boltons?.find(b => b.status === 'Active' && b.productId === boltonId);

interface ProductDateComparisonConfig {
  boltons: Array<BoltonInstance>;
  boltonId: ProductId;
  beforeDate?: string;
  afterDate?: string;
}

/*
 Accepts a config with optional before or after dates
 Depending on the value passed, will compare whether the product was activated before or after the passed in date
 Also returns true if dates match
 */
export const activatedProductDateComparison = ({
  boltons,
  boltonId,
  beforeDate,
  afterDate,
}: ProductDateComparisonConfig) => {
  // Find bolton within provided array using provided id
  const selectedBolton = boltons
    .filter(b => b.status === 'Active')
    .find(b => b.productId === boltonId);

  // Return false if there is no selected bolton or neither date is provided
  if (!selectedBolton || (!beforeDate && !afterDate)) return false;

  // Get Boltons active from date
  const activeFromDateTime = new Date(selectedBolton.activeFrom).getTime();

  // If both date ranges are provided, check that active date is in between these two values
  if (beforeDate && afterDate) {
    const beforeCutoffDate = new Date(beforeDate).getTime();
    const afterCutoffDate = new Date(afterDate).getTime();
    return (
      activeFromDateTime <= beforeCutoffDate &&
      activeFromDateTime >= afterCutoffDate
    );
  }

  // Otherwise check for a singular date cutoff
  const date = beforeDate ? beforeDate : afterDate;
  if (!date) return false;

  const cutoffDate = new Date(date).getTime();

  return beforeDate
    ? cutoffDate >= activeFromDateTime
    : cutoffDate <= activeFromDateTime;
};

const hasBundle = (bundleId: ProductId, bundle?: BundleInstance) =>
  Boolean(bundle && bundle.productId === bundleId);

export const getEligibleProductFromCatalogue = (
  catalogue: ProductCatalogue,
  productId: ProductId,
) =>
  [...catalogue.boltons, ...catalogue.bundles].find(
    product => product.productId === productId && product.eligibility.eligible,
  );

export const getProductFromCatalogue = (
  catalogue: ProductCatalogue,
  productId: ProductId,
) =>
  [...catalogue.boltons, ...catalogue.bundles].find(
    product => product.productId === productId,
  );

export const isGreenElectricityCustomer = (boltons: Array<BoltonInstance>) =>
  hasBolton(boltons, PRODUCT_IDS.greenElectricity);

export const isGreenerEnergyCustomer = (boltons: Array<BoltonInstance>) =>
  hasBolton(boltons, PRODUCT_IDS.greenerEnergy);

export const isGreenerEnergyBundleCustomer = (boltons: Array<BoltonInstance>) =>
  hasBolton(boltons, PRODUCT_IDS.greenerEnergyOneYearFixed);

/**
 * Does the user have the Greener Electricity add-on as a standalone item
 */
export const isGreenerElectricityCustomer = (boltons: Array<BoltonInstance>) =>
  hasBolton(boltons, PRODUCT_IDS.greenerElectricity);

/**
 * Does the user have the Greener Electricity add-on as a result
 * of it being bundled with a tariff
 * (currently just 1 Year Fixed + Greener Energy)
 */
export const isGreenerElectricityBundleCustomer = (
  boltons: Array<BoltonInstance>,
) => hasBolton(boltons, PRODUCT_IDS.greenerElectricityOneYearFixed);

export const isGreenGasCustomer = (boltons: Array<BoltonInstance>) =>
  hasBolton(boltons, PRODUCT_IDS.greenGas);

export const isGreenBundleCustomer = (bundle?: BundleInstance) =>
  hasBundle(PRODUCT_IDS.greenBundle, bundle);

export const isGreenCustomer = (
  boltons: Array<BoltonInstance>,
  bundle?: BundleInstance,
) =>
  isGreenBundleCustomer(bundle) ||
  isGreenElectricityCustomer(boltons) ||
  isGreenGasCustomer(boltons);

export const getGreenProducts = (activated: ProductsResponse) =>
  [
    ...activated.boltons,
    ...(activated.bundle ? [activated.bundle] : []),
  ].filter(
    product =>
      product.productId === PRODUCT_IDS.greenElectricity ||
      product.productId === PRODUCT_IDS.greenGas ||
      product.productId === PRODUCT_IDS.greenBundle ||
      product.productId === PRODUCT_IDS.greenerEnergy ||
      product.productId === PRODUCT_IDS.greenerElectricity,
  );

const zeroCarbonHeatingRestrictedAreasRegex =
  /(BH(21|23|24|25|31)|BN(88|99|5[0-2]|4[1-5]|2[0-7]|1[0-8]|[1-9])|BR(6|8)|CR(3|[5-6])|CT(21|50|1[0-9]|[1-9])|DA(9|17|1[0-3]|[1-5])|GL(56|7)|GU(46|47|5[1-2]|3[0-5]|2[0-9]|1[0-9]|[1-9])|HP(27|2[0-3]|1[0-9]|[5-9])|KT(4|[6-9]|2[0-4]|1[0-9])|LU([6-7])|ME(20|99|1[0-9]|[1-9])|MK(46|77|98|1[0-9]|[1-9])|NN(12|13|[7-9])|OX(20|25|26|27|28|29|33|39|44|49|1[0-8]|[1-9])|PO(4[0-1]|3[0-9]|2[0-2]|1[0-9]|[1-9])|RG(45|4[0-2]|3[0-1]|2[0-9]|1[0-9]|[1-9])|RH(20|77|1[0-9]|[1-9])|SL(60|95|[0-9])|SM([2-3]|7)|SN([6-7])|SO(14|15|16|17|18|19|20|21|22|23|24|25|97|9|5[0-3]|4[0-5]|3[0-2]|[1-5])|SP([4-6]|9|1[0-1])|TN(40|3[0-9]|2[0-9]|1[0-9]|[1-9])|TW(6|15|16|17|18|19|20)|UB([8-9])|WD3[ ]?9ZL)/i;

export const getZeroCarbonHeatingOffer = (postCode: string | null) =>
  postCode ? zeroCarbonHeatingRestrictedAreasRegex.test(postCode) : false;

export const extractGreenerEnergyPriceInfo = (
  greenerEnergyCatalogueBolton: CatalogueBolton,
) => {
  let initialPrice: number;
  let currentPrice: number;
  let discountedLength: number | undefined;
  let hasPromo: boolean;

  if (greenerEnergyCatalogueBolton.cost.current.type === 'Fixed') {
    currentPrice = greenerEnergyCatalogueBolton.cost.current.value;
  } else if (greenerEnergyCatalogueBolton.cost.current.type === 'Variable') {
    currentPrice = greenerEnergyCatalogueBolton.cost.current.min;
  } else {
    throw new Error('Invalid Cost Type from the Catalogue Bolton');
  }

  if (greenerEnergyCatalogueBolton.cost.initial.type === 'Fixed') {
    initialPrice = greenerEnergyCatalogueBolton.cost.initial.value;
  } else if (greenerEnergyCatalogueBolton.cost.initial.type === 'Variable') {
    initialPrice = greenerEnergyCatalogueBolton.cost.initial.min;
  } else {
    throw new Error('Invalid Promo Discount from the Catalogue Bolton');
  }

  if (greenerEnergyCatalogueBolton.promoDiscount) {
    hasPromo = true;
    discountedLength =
      greenerEnergyCatalogueBolton.promoDiscount?.lengthInMonths ?? 0;
  } else {
    hasPromo = false;
    discountedLength = undefined;
  }

  return {
    currentPrice,
    initialPrice,
    discountedLength,
    hasPromo,
  };
};

export const extractGreenerElectricityPriceInfo = (
  greenerElectricityCatalogueBolton: CatalogueBolton,
) => {
  let initialPrice: number;
  let currentPrice: number;
  let discountedLength: number | undefined;
  let hasPromo: boolean;

  if (greenerElectricityCatalogueBolton.cost.current.type === 'Fixed') {
    currentPrice = greenerElectricityCatalogueBolton.cost.current.value;
  } else if (
    greenerElectricityCatalogueBolton.cost.current.type === 'Variable'
  ) {
    currentPrice = greenerElectricityCatalogueBolton.cost.current.min;
  } else {
    throw new Error('Invalid Cost Type from the Catalogue Bolton');
  }

  if (greenerElectricityCatalogueBolton.cost.initial.type === 'Fixed') {
    initialPrice = greenerElectricityCatalogueBolton.cost.initial.value;
  } else if (
    greenerElectricityCatalogueBolton.cost.initial.type === 'Variable'
  ) {
    initialPrice = greenerElectricityCatalogueBolton.cost.initial.min;
  } else {
    throw new Error('Invalid Promo Discount from the Catalogue Bolton');
  }

  if (greenerElectricityCatalogueBolton.promoDiscount) {
    hasPromo = true;
    discountedLength =
      greenerElectricityCatalogueBolton.promoDiscount?.lengthInMonths ?? 0;
  } else {
    hasPromo = false;
    discountedLength = undefined;
  }

  return {
    currentPrice,
    initialPrice,
    discountedLength,
    hasPromo,
  };
};
