import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { createMoveOutEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { MoveOutDateResponse } from '@/src/types/MoveOut';

type QueryCacheKey = `orion-move-out-${string}`;

export const useMoveOutDateQuery = (
  accountId: string | null | undefined,
  options?: UseQueryOptions<
    MoveOutDateResponse,
    unknown,
    MoveOutDateResponse,
    QueryCacheKey
  >,
): UseQueryResult<MoveOutDateResponse> =>
  useQuery(
    `orion-move-out-${accountId}` as QueryCacheKey,
    () =>
      apiService.get<MoveOutDateResponse>({
        url: createMoveOutEndpoint(accountId ?? ''),
      }),
    {
      enabled: !!accountId,
      ...options,
    },
  );
