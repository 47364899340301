import styled from 'styled-components';
import { mqLargeUp } from '@/src/utils/mediaQuery';

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 10px;

  ${mqLargeUp(`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  `)}
`;

export const Terms = styled.p`
  ${({ theme: { space, breakpoints } }) => `
    margin-top: ${space[1]};
    margin-bottom: 0;
    max-width: ${breakpoints.medium};
  `}
`;
