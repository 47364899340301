import { SelectField, TextField } from '@ovotech/nebula';
import React from 'react';
import { FormProps } from '../CustomerDetails';

const titles = [
  'Mr',
  'Mrs',
  'Miss',
  'Ms',
  'Dr',
  'Mx',
  'Prof',
  'Rev',
  'Sir',
  'Lord',
  'Lady',
].map(v => ({ value: v, label: v }));

type FilledNameProps = Partial<{
  title: string;
  firstName: string;
  lastName: string;
}>;

export function CustomerName({
  existingDetails,
  formErrors,
  title,
  firstName,
  lastName,
}: FormProps & FilledNameProps) {
  return (
    <>
      <SelectField
        name="title"
        id="title"
        label="Title"
        characters={5}
        options={titles}
        error={formErrors.title}
        defaultValue={
          title ??
          (existingDetails.status === 'success'
            ? existingDetails.data.customer_nextV1.title ?? ''
            : '')
        }
      />

      <TextField
        autoComplete="given-name"
        id="firstName"
        name="firstName"
        label="First name"
        error={formErrors.firstName}
        defaultValue={
          firstName ??
          (existingDetails.status === 'success'
            ? existingDetails.data.customer_nextV1.givenName
            : '')
        }
      />
      <TextField
        autoComplete="family-name"
        id="lastName"
        name="lastName"
        label="Surname"
        error={formErrors.lastName}
        defaultValue={
          lastName ??
          (existingDetails.status === 'success'
            ? existingDetails.data.customer_nextV1.familyName
            : '')
        }
      />
    </>
  );
}
