import { format } from 'date-fns';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom-v5';

import { UsagePageView } from './UsagePageView';
import { SwitchWithNotFound } from '@/src/components';
import {
  ROUTE_USAGE,
  ROUTE_USAGE_DAILY,
  ROUTE_USAGE_MONTHLY,
  ROUTE_USAGE_YEARLY,
} from '@/src/constants/routes';

type UsageDatePeriod = 'yearly' | 'monthly' | 'daily';

const getDatePrams = (path: string, datePeriod: UsageDatePeriod) => {
  const dateBit = path.replace(ROUTE_USAGE, '');
  return dateBit
    ? `?date=${format(dateBit, 'YYYY-MM-DD')}&datePeriod=${datePeriod}`
    : `?datePeriod=${datePeriod}`;
};

const Usage = () => {
  const { pathname } = useLocation();

  return (
    <SwitchWithNotFound>
      <Route exact path={ROUTE_USAGE} component={UsagePageView} />
      <Redirect
        exact
        from={ROUTE_USAGE_DAILY}
        to={{ pathname: ROUTE_USAGE, search: '?datePeriod=daily' }}
      />
      <Redirect
        exact
        from={ROUTE_USAGE_MONTHLY}
        to={{ pathname: ROUTE_USAGE, search: '?datePeriod=monthly' }}
      />
      <Redirect
        exact
        from={ROUTE_USAGE_YEARLY}
        to={{ pathname: ROUTE_USAGE, search: '?datePeriod=yearly' }}
      />

      {/* Legacy route redirects */}
      <Redirect
        exact
        from={`${ROUTE_USAGE}/:year(\\d{4})`}
        to={{
          pathname: ROUTE_USAGE,
          search: getDatePrams(pathname, 'yearly'),
        }}
      />
      <Redirect
        exact
        from={`${ROUTE_USAGE}/:year(\\d{4})/:month(\\d{2})`}
        to={{
          pathname: ROUTE_USAGE,
          search: getDatePrams(pathname, 'monthly'),
        }}
      />
      <Redirect
        exact
        from={`${ROUTE_USAGE}/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})`}
        to={{
          pathname: ROUTE_USAGE,
          search: getDatePrams(pathname, 'daily'),
        }}
      />
    </SwitchWithNotFound>
  );
};

export default Usage;
