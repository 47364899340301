import { useQuery } from '@ovotech/ui-tools';
import { createMonthlyUsageEndpoint } from '@/src/constants/endpoints';
import { MonthlyUsageResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

function useMonthlyUsageQuery(accountId: string, date: string) {
  const monthlyUsageEndpoint = createMonthlyUsageEndpoint(accountId, date);

  return useQuery(
    `orion-monthly-${accountId}-${date}`,
    () =>
      apiService.get<MonthlyUsageResponse>({
        url: monthlyUsageEndpoint,
        headers: { Accept: 'application/json;version=2' },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );
}

// ts-unused-exports:disable-next-line
export { useMonthlyUsageQuery };
