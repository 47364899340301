import { FunctionComponent, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

const LiveRegion: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const liveRegion: HTMLElement = document.getElementById('live-region')!;

  return createPortal(children, liveRegion);
};

export default LiveRegion;
