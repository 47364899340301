import {
  Ul,
  Li,
  P,
  Small,
  TextLink,
  Card,
  Heading2,
  Margin,
  SecondaryCTAButton,
  Heading3,
} from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsData } from '@ovotech/ui-tools';
import React from 'react';
import { Link } from 'react-router-dom-v5';
import { StyledProductHeading } from '../../Products.styled';
import ProductPrice from '../ProductPrice';
import ProductsCancel from '../ProductsCancel';
import { Flex, StyledFoundationDonation } from './ProductsBolton.styled';
import { useAnytimeBoltonPricingQuery } from '@/src/api';
import { BOLT_ONS } from '@/src/constants/analytics';
import { PRODUCT_IDS } from '@/src/constants/products';
import {
  ROUTE_GREENER_ELECTRICITY,
  ROUTE_GREENER_ENERGY,
} from '@/src/constants/routes';
import { ProductIconAndLabel } from '../ProductIconAndLabel/ProductIconAndLabel';
import { BoltonInstance } from '@/src/types/Products';
import { ProductUpdateStatus } from '@/src/types/State';
import { isChargeAnytimeProduct } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

export type ProductsBoltonProps = {
  bolton: BoltonInstance;
  canCancel?: boolean;
  onCancel: () => void;
  cancellation: ProductUpdateStatus;
};

type ProductsBundleBoltonProps = {
  product: BoltonInstance;
};

type ProductsBoltonBodyProps = {
  product: BoltonInstance;
};

function ProductsBoltonBody({ product }: ProductsBoltonBodyProps) {
  const anytimeBoltonPricing = useAnytimeBoltonPricingQuery();

  const getContent = () => {
    if (anytimeBoltonPricing.isError)
      return 'Smart charge and save with our super-low Charge Anytime rate';
    if (anytimeBoltonPricing.isLoading)
      return (
        <>
          You only pay XX/kWh when smart charging your EV with Charge Anytime.
        </>
      );

    if (anytimeBoltonPricing.data)
      return (
        <>
          You only pay {anytimeBoltonPricing.data.formattedPence} when smart
          charging your EV with Charge Anytime.
        </>
      );
  };

  return (
    <div data-testid="products-bolton-body">
      {product.productId === PRODUCT_IDS.greenElectricity ? (
        <Ul data-testid="upgrade-info-green-electricity">
          <Li>
            100% renewable electricity from green sources like wind and solar.
          </Li>
          <Li>
            5 trees planted on your behalf to offset harmful carbon emissions.
          </Li>
        </Ul>
      ) : product.productId === PRODUCT_IDS.greenGas ? (
        <Ul data-testid="upgrade-info-green-gas">
          <Li>
            15% from biogas, landfill and synthetic gas, and we offset the rest.
          </Li>
          <Li>We offset by funding worldwide projects that reduce CO2.</Li>
        </Ul>
      ) : product.productId === PRODUCT_IDS.polarPlus ? (
        <div data-testid="upgrade-info-bp-pulse">
          <P>
            Use thousands of UK charge points from the Cornish Coast to the
            Scottish Highlands - and every place in between.
          </P>
          <Small>
            Discover more about your subscription at{' '}
            <TextLink href="https://network.bppulse.co.uk/" opensInNewWindow>
              network.bppulse.co.uk/
            </TextLink>
            .
          </Small>
        </div>
      ) : product.productId === PRODUCT_IDS.ovoFoundation ? (
        <div data-testid="upgrade-info-ovo-foundation">
          <P>
            From planting forests to helping schools become more
            energy-efficient, your donation is having a huge impact.
          </P>
          <P>
            100% of your donation goes to our projects, and OVO matches every
            penny donated. Together, we're doing double the good! Thank you for
            helping us create a greener, brighter future for the next
            generation.
          </P>
        </div>
      ) : product.productId === PRODUCT_IDS.greenerEnergy ? (
        <Ul data-testid="upgrade-info-greener-energy">
          <Li>100% renewable electricity</Li>
          <Li>100% carbon-neutral gas</Li>
          <Li>OVO Annual contribution to support new renewables</Li>
        </Ul>
      ) : product.productId === PRODUCT_IDS.greenerElectricity ? (
        <Ul data-testid="upgrade-info-greener-electricity">
          <Li>100% renewable electricity</Li>
          <Li>OVO Annual contribution to support new renewables</Li>
        </Ul>
      ) : isChargeAnytimeProduct(product.productId) ? (
        <div data-testid="upgrade-info-ovo-anytime">
          <P>{getContent()}</P>
        </div>
      ) : null}
    </div>
  );
}

export const ProductsBundleBolton = ({
  product,
}: ProductsBundleBoltonProps) => (
  <Card as={Flex}>
    <div>
      <Heading3 data-testid="products-bundle-bolton-title">
        <ProductIconAndLabel id={product.productId} />
      </Heading3>
      <ProductsBoltonBody product={product} />
    </div>
  </Card>
);

const ProductsBolton = ({
  canCancel,
  onCancel,
  cancellation,
  bolton,
}: ProductsBoltonProps) => {
  return (
    <div data-testid="products-bolton">
      <Margin bottom={4}>
        <StyledProductHeading as={Heading2} data-testid="products-bolton-title">
          <ProductIconAndLabel id={bolton.productId} />
        </StyledProductHeading>
      </Margin>
      <Card>
        <Margin bottom={4}>
          {bolton.productId === PRODUCT_IDS.ovoFoundation ? (
            <StyledFoundationDonation data-testid="products-bolton-foundation-title">
              You're making a big difference all over the UK. Thank you!
            </StyledFoundationDonation>
          ) : (
            <ProductPrice
              cost={bolton.cost}
              data-testid="products-bolton-price"
            />
          )}
        </Margin>
        <Margin top={4}>
          <ProductsBoltonBody product={bolton} />
        </Margin>
        <Margin top={4}>
          {bolton.productId === PRODUCT_IDS.greenerEnergy ? (
            <SecondaryCTAButton
              type="button"
              as={Link}
              to={ROUTE_GREENER_ENERGY}
              fullWidth="always"
            >
              {' '}
              Learn More{' '}
            </SecondaryCTAButton>
          ) : null}

          {bolton.productId === PRODUCT_IDS.greenerElectricity ? (
            <SecondaryCTAButton
              type="button"
              as={Link}
              to={ROUTE_GREENER_ELECTRICITY}
              fullWidth="always"
            >
              {' '}
              Learn More{' '}
            </SecondaryCTAButton>
          ) : null}
        </Margin>
        {canCancel && (
          <Margin top={4}>
            <AnalyticsData
              data={{
                name: bolton.displayName,
                id: bolton.id,
              }}
            >
              <AnalyticsClick
                name={BOLT_ONS.DISCONNECT_BOLTON_CLICKED}
                properties={{
                  name: bolton.displayName,
                  id: bolton.id,
                }}
              >
                <AnalyticsClick
                  name="EVS - EVS OVO Drive Anytime tile cancel button clicked"
                  properties={{
                    name: bolton.displayName,
                    id: bolton.id,
                  }}
                >
                  <ProductsCancel
                    bolton={bolton}
                    onCancel={onCancel}
                    cancellation={cancellation}
                    title={
                      bolton.productId === PRODUCT_IDS.ovoFoundation
                        ? 'Cancel donation'
                        : 'Cancel upgrade'
                    }
                    ctaFullWidth={
                      [
                        PRODUCT_IDS.greenerElectricity,
                        PRODUCT_IDS.greenerEnergy,
                      ].includes(bolton.productId)
                        ? 'always'
                        : 'never'
                    }
                  />
                </AnalyticsClick>
              </AnalyticsClick>
            </AnalyticsData>
          </Margin>
        )}
      </Card>
    </div>
  );
};

ProductsBolton.defaultProps = { canCancel: false };

export default ProductsBolton;
