import React from 'react';

// FormData object does not convert data in a desirable manner (all values are strings)
export function formEventToObject<T>(
  unknownForm: React.FormEvent<HTMLFormElement>,
): Partial<T> {
  const formData = (
    Object.values(unknownForm.currentTarget) as HTMLInputElement[]
  ).reduce((acc, input) => {
    switch (input.type) {
      case 'text':
      case 'tel':
        acc[input.name] =
          input.inputMode === 'numeric' && input.value.match('^\\d+$') !== null
            ? Number(input.value)
            : input.value;
        break;
      case 'number':
        acc[input.name] = Number(input.value);
        break;
      case 'checkbox':
        acc[input.name] = input.checked;
        break;
      case 'radio':
        if (input.checked) {
          acc[input.name] = input.value;
        }
        break;
      case 'select-one':
        try {
          // Allows multiple values in single form input
          const trueObject = JSON.parse(input.value.toString());
          Object.assign(acc, trueObject);
        } catch {
          acc[input.name] = input.value;
        }
        break;
    }

    return acc;
  }, {} as Record<string, unknown>);

  return formData as Partial<T>;
}
