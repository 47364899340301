import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';

import { createRecommendedDirectDebitEndpoint } from '@/src/constants/endpoints';
import {
  makeActionError,
  makeActionStart,
  makeActionSuccess,
} from '@/src/redux/ducks/http';
import apiService from '@/src/services/apiService';
import {
  Action,
  FETCH_ACTION_KEY,
  GetRecommendedDirectDebitError,
  GetRecommendedDirectDebitStart,
  GetRecommendedDirectDebitSuccess,
} from '@/src/types/Action';
import { RecommendedDirectDebitResponse } from '@/src/types/Response';
import {
  RecommendedDirectDebitData,
  RecommendedDirectDebit,
} from '@/src/types/State';

export const getRecommendedDirectDebitStart = makeActionStart<
  GetRecommendedDirectDebitStart,
  [{ selectedAccountId: string | null }]
>(({ selectedAccountId }) => ({
  type: 'GET_RECOMMENDED_DIRECT_DEBIT_START',
  selectedAccountId,
}));

export const getRecommendedDirectDebitSuccess = makeActionSuccess<
  GetRecommendedDirectDebitSuccess,
  [RecommendedDirectDebitData | null]
>(data => ({
  type: 'GET_RECOMMENDED_DIRECT_DEBIT_SUCCESS',
  data,
}));

export const getRecommendedDirectDebitError =
  makeActionError<GetRecommendedDirectDebitError>(() => ({
    type: 'GET_RECOMMENDED_DIRECT_DEBIT_ERROR',
  }));

const getRecommendedDirectDebitNoAccountError =
  //@ts-ignore error prevents retry so not included here
  (): GetRecommendedDirectDebitError => ({
    type: 'GET_RECOMMENDED_DIRECT_DEBIT_ERROR',
    isFetching: false,
  });

const debtRiskLimit = -50;
export const transformResponse = (
  response: RecommendedDirectDebitResponse,
): RecommendedDirectDebitData => ({
  ...response,
  isDebtRisk: debtRiskLimit > Number(response.predictedFinalBalance.amount),
});

export const getRecommendedDirectDebitEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  // @ts-ignore Typescript does not like action$.ofType

  action$
    .ofType('GET_RECOMMENDED_DIRECT_DEBIT_START')
    .exhaustMap(
      ({
        [FETCH_ACTION_KEY]: id,
        selectedAccountId,
      }: GetRecommendedDirectDebitStart) => {
        const { user } = store.getState();

        if (user.selectedAccountId || selectedAccountId) {
          const recDdEndpoint = createRecommendedDirectDebitEndpoint(
            user.selectedAccountId || selectedAccountId,
          );

          return apiService
            .get({
              responseType: 'json',
              url: recDdEndpoint,
            })
            .map(({ response }) =>
              getRecommendedDirectDebitSuccess(id, transformResponse(response)),
            )
            .catch(err =>
              err.status === 404
                ? Observable.of(getRecommendedDirectDebitSuccess(id, null))
                : Observable.throw(err),
            )
            .catch(err =>
              Observable.of(getRecommendedDirectDebitError(id, err)),
            );
        } else {
          return Observable.of(getRecommendedDirectDebitNoAccountError());
        }
      },
    );

export const defaultRecommendedDirectDebit: RecommendedDirectDebit = {
  isFetching: false,
  data: null,
  errorResponse: null,
};

const recommendedDirectDebit = (
  state: RecommendedDirectDebit = defaultRecommendedDirectDebit,
  action: Action,
): RecommendedDirectDebit => {
  switch (action.type) {
    case 'GET_RECOMMENDED_DIRECT_DEBIT_START': {
      const { isFetching } = action;
      return {
        ...state,
        isFetching,
        errorResponse: undefined,
      };
    }
    case 'GET_RECOMMENDED_DIRECT_DEBIT_SUCCESS': {
      const { isFetching, data } = action;

      return {
        ...state,
        isFetching,
        isFetched: true,
        data,
        errorResponse: null,
      };
    }
    case 'GET_RECOMMENDED_DIRECT_DEBIT_ERROR': {
      const { isFetching, errorResponse } = action;
      return {
        ...state,
        isFetching,
        errorResponse: errorResponse || true,
      };
    }
    default: {
      return state;
    }
  }
};

export default recommendedDirectDebit;
