import { CombinedState, combineReducers } from 'redux';

import { Action } from '@/src/types/Action';
import { State } from '@/src/types/State';

function createRootReducer(reducers: Record<string, any>) {
  const allReducers = combineReducers(reducers);

  const rootReducer = (
    state: CombinedState<State> | Partial<State>,
    action: Action,
  ) => {
    if (action.type === 'SELECT_ACCOUNT') {
      const { app, bootstrap, user, router } = state;

      state = { app, bootstrap, user, router };
    }

    // @ts-ignore
    return allReducers(state, action);
  };

  return rootReducer;
}

export default createRootReducer;
