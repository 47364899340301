import { Checkbox, CTAButton, Heading4, TextLink } from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { PROFILE_ADDON_GREENER_ELECTRICITY_MODAL_CLOSE } from '@/src/constants/analytics-subjects';
import { GREENER_ELECTRICITY_TERMS } from '@/src/constants/routes';
import { CatalogueBolton, SignupSource } from '@/src/types/Products';
import { getGreenerElectricitySourceFromURL } from '@/src/pages/GreenerElectricity/GreenerElectricitySignup/utils';

import { GreenerElectricityModal } from '@/src/pages/GreenerElectricity/components/GreenerElectricityModal/GreenerElectricityModal';

import { OptimizelyContext } from '@/src/utils/optimizely';
import testIds from '../../testIds';

interface Props {
  activateProduct: (product: CatalogueBolton, source?: SignupSource) => void;
  greenerElectricity: CatalogueBolton;
  setAcceptPrompt: Dispatch<SetStateAction<boolean>>;
  acceptPrompt: boolean;
}

const GreenerElectricityAcceptTermsModal = (props: Props) => {
  const { dispatch } = useAnalytics();
  const { activateProduct, greenerElectricity, setAcceptPrompt, acceptPrompt } =
    props;

  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [mustAgreeMessage, setMustAgreeMessage] = useState<boolean>(false);

  const { optimizely } = useContext(OptimizelyContext);

  const onSignupClick = () => {
    if (acceptedTerms) {
      activateProduct(greenerElectricity, getGreenerElectricitySourceFromURL());
      optimizely?.track('Greener Electricity Signup Click');
    } else {
      setMustAgreeMessage(true);
    }
  };

  const onPress = () => {
    setAcceptedTerms(!acceptedTerms);
    setMustAgreeMessage(false);
  };

  const onClose = () => {
    dispatch({
      name: PROFILE_ADDON_GREENER_ELECTRICITY_MODAL_CLOSE,
      type: 'click',
    });
    setAcceptPrompt(false);
  };

  return (
    <GreenerElectricityModal
      testId="greener-electricity-accept-terms-modal"
      title={'Add Greener Electricity to your account'}
      showGreenerElectricityModal={acceptPrompt}
      onClose={onClose}
    >
      <Heading4>{`£8 monthly subscription.`}</Heading4>
      <Heading4>{`Cancel any time.`}</Heading4>
      <div data-testid={testIds.signUp.agreement}>
        <Checkbox
          id="greener-electricity-terms-and-conditions-accept"
          label={
            <>
              By continuing, you are agreeing to the Greener Electricity terms
              and conditions.{' '}
              <TextLink target="_blank" href={GREENER_ELECTRICITY_TERMS}>
                Read the Greener electricity full terms and conditions.{' '}
              </TextLink>
            </>
          }
          onChange={onPress}
          error={
            mustAgreeMessage &&
            !acceptedTerms &&
            'You must agree to terms and conditions before continuing.'
          }
        />

        <CTAButton
          variant="primary"
          type="button"
          onClick={onSignupClick}
          style={{ marginTop: '2rem' }}
          fullWidth="always"
        >
          {`Sign up  now`}
        </CTAButton>
      </div>
    </GreenerElectricityModal>
  );
};

export default GreenerElectricityAcceptTermsModal;
