import { Row, Theme } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqLargeDown, mqSmallDown, mqSmallUp } from '@/src/utils/mediaQuery';

export const Divider = styled.div<{
  margin: keyof Theme['space'];
}>`
  ${({ margin, theme: { space } }) => `
      margin-bottom: ${space[margin]};
      margin-top: ${space[margin]};
    `}

  ${({ theme: { colors } }) => `
    ${mqSmallUp(`
      background-color: ${colors.borderMuted}`)}
      height: 1px;
      border: 0;
    ${mqSmallDown(`
      background-image: linear-gradient(
        to right,
        ${colors.borderMuted} 33%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 9px 1px;
      background-repeat: repeat-x;
      height: 10px;
    `)};
  `}
`;

export const OrderSummaryWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-shrink: 2;
  margin-top: 50px;
  ${mqSmallDown(`
      margin: 0;
      display: block;
  `)}
  ${mqLargeDown(`
    display: block;
`)}
`;

export const StyledProductImageCard = styled.div`
  ${({ theme: { space } }) => `
    margin: ${space[2]};
  `}
  ${mqSmallDown(`
      display: none;
  `)}
`;

export const StyledProductImageWrapper = styled.img`
  max-width: 160px;
  max-height: 80px;
`;

export const StyledProductTitleWrapper = styled.div`
  ${({ theme: { space } }) => `
      margin: ${space[2]};
    `}
  font-weight: bold;
  max-width: 325px;
  ${mqSmallDown(`
      font-weight: normal;
      margin: 0;
  `)}
`;

export const StyledProductTextWrapper = styled.div`
  ${({ theme: { space } }) => `
      margin: ${space[2]};
      ${mqSmallDown(`
      margin-left: 0;
      margin-top: ${space[5]};
  `)}
    `}
  display: inline-flex;
  gap: 30px;
`;

export const StyledRow = styled(Row)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 0;
  padding: 0;
  ${({ theme: { colors } }) => `
    color: ${colors.borderMuted};
  `}
  ${mqSmallDown(`
      display: block;
  `)};
`;

export const StyledCard = styled.div`
  ${({ theme: { colors, space } }) => `
        background-color: ${colors.primaryContrast};
        padding: ${space[8]}
    `}
`;

export const StyledDetails = styled.div`
  ${({ theme: { space } }) => `
      ${mqSmallDown(`
      margin-top: ${space[3]}
  `)}
  `}
`;
