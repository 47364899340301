import { Margin, TextLink } from '@ovotech/nebula';
import { AnalyticsPage } from '@ovotech/ui-tools';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom-v5';
import { footnote100PercentGreenerElectricity } from '../components/Footnotes/GreenerElectricityFootnotes';
import GreenerElectricityHomeHeader from '../components/GreenerElectricityHomeHeader/GreenerElectricityHomeHeader';
import {
  FeaturesWrapper,
  TermsHeader,
  TermsContent,
  TermsContainer,
} from './GreenerElectricitySignup.styled';
import { CarbonNeutralCard } from './components/CarbonNeutralCard';
import { FooterLogo } from './components/FooterLogo';
import { GreenerElectricityHeader } from './components/GreenerElectricityHeader';
import {
  LoadError,
  Loading,
  FormError,
} from './components/GreenerElectricitySignupComponents';
import { RenewablePower } from './components/RenewablePower';
import { SignupBanner } from './components/SignupBanner';
import { WhatIsIncluded } from './components/WhatIsIncluded';
import { useGreenerElectricitySignup } from './hooks/useGreenerElectricitySignup';

import { StyledFootnoteHeading } from '@/src/components/Footnote/Footnote.styled';
import { GREENER_ELECTRICITY_SIGNUP } from '@/src/constants/analytics-subjects';

import {
  ROUTE_GREENER_ELECTRICITY_SIGNUP_SUCCESS,
  ROUTE_GREENER_ELECTRICITY,
  GREENER_ELECTRICITY_TERMS,
} from '@/src/constants/routes';

import { getProductCatalogueStart } from '@/src/redux/ducks/products/products';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';

export const GreenerElectricitySignup = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    isFetching,
    hasStandaloneGreenerElectricityAddOn,
    activateProduct,
    greenerElectricity,
    activation,
  } = useGreenerElectricitySignup();

  const promoCode = getQueryParamValue('promoCode');
  const [acceptPrompt, setAcceptPrompt] = useState<boolean>(false);

  useEffect(() => {
    // Redirect to Greener Electricity
    if (hasStandaloneGreenerElectricityAddOn) {
      history.push(ROUTE_GREENER_ELECTRICITY);
    } else {
      // If a user is not a Greener Electricity member then fetch an up to date status for Greener Electricity eligibility
      dispatch(getProductCatalogueStart());
    }
  }, [hasStandaloneGreenerElectricityAddOn, history, dispatch]);

  if (activation.success) {
    return <Redirect to={ROUTE_GREENER_ELECTRICITY_SIGNUP_SUCCESS} />;
  }
  if (isFetching) {
    return <Loading />;
  }
  if (activation.error) {
    return <FormError />;
  }

  if (greenerElectricity?.eligibility?.reason === 'Conflict') {
    return (
      <AnalyticsPage
        name={GREENER_ELECTRICITY_SIGNUP}
        properties={{ promoCode }}
      >
        <GreenerElectricityHomeHeader headingContent="Greener Electricity is included with your tariff" />
      </AnalyticsPage>
    );
  }

  if (!greenerElectricity || greenerElectricity?.eligibility?.reason) {
    return <LoadError />;
  }

  return (
    <AnalyticsPage name={GREENER_ELECTRICITY_SIGNUP} properties={{ promoCode }}>
      <GreenerElectricityHeader />
      <SignupBanner
        setAcceptPrompt={setAcceptPrompt}
        acceptPrompt={acceptPrompt}
        activateProduct={activateProduct}
        activation={activation}
        greenerElectricity={greenerElectricity}
      ></SignupBanner>
      <WhatIsIncluded></WhatIsIncluded>
      <FeaturesWrapper>
        <CarbonNeutralCard />
        <RenewablePower />
      </FeaturesWrapper>
      <Margin top={2}>
        <FooterLogo />
      </Margin>
      <StyledFootnoteHeading>More information</StyledFootnoteHeading>
      <TermsContainer>
        <ul>
          <li>
            <TermsHeader>
              {footnote100PercentGreenerElectricity.heading}
            </TermsHeader>
            <TermsContent>
              {footnote100PercentGreenerElectricity.body[0]}
            </TermsContent>
          </li>
        </ul>
        <Margin bottom={6}>
          <TextLink opensInNewWindow href={GREENER_ELECTRICITY_TERMS}>
            Greener Electricity terms and conditions
          </TextLink>
        </Margin>
      </TermsContainer>
    </AnalyticsPage>
  );
};
