import { Heading3 } from '@ovotech/nebula';
import React, { ComponentType } from 'react';
import { StyledSkeletonHeading } from './SkeletonLoading.styled';

type Props = JSX.IntrinsicElements['div'] & {
  size?: string;
  headingComponent?: ComponentType<any>;
};

export const SkeletonHeading = ({
  size = '70%',
  headingComponent: Heading,
  ...rest
}: Props) => (
  <StyledSkeletonHeading
    aria-hidden="true"
    size={size}
    as={Heading || Heading3}
    {...rest}
  >
    &nbsp;
  </StyledSkeletonHeading>
);
