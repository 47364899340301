import { P, Accordion, Heading4, Li, TextLink, Ul } from '@ovotech/nebula';
import React from 'react';
import { StyledAccordionGroup } from '../EVsInlifePage.styled';
import { CHARGE_ANYTIME_EMAIL_ADDRESS } from '@/src/constants/routes';

export const EVAnytimeFAQ = () => {
  return (
    <StyledAccordionGroup>
      <Accordion
        headingComponent={Heading4}
        title="How can I add Charge Anytime?"
      >
        <P>
          All you have to do is choose Charge Anytime in the offers section,
          connect your smart charger to your OVO account and opt in to smart
          charging with Kaluza.
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="If I’m in a hurry, can I override EV smart charging?"
      >
        <P>
          Things don’t always go to plan. You might have an emergency or you may
          have forgotten you need to pop out.
        </P>
        <P>
          You can override your smart charging schedule at any time. This will
          let you top up quickly when you need to, at your standard electricity
          rate.
        </P>
        <P>
          When you’re done you’ll simply revert back to your smart charging
          schedule, like before.
        </P>
      </Accordion>
      <Accordion headingComponent={Heading4} title="What is boost charging?">
        <P>
          If your plans change and you need to charge your EV unexpectedly,
          boost charging lets you temporarily override the smart charging
          schedule that you’ve set in your EV charger app, and get your car
          fully charged as quickly as possible.
        </P>
        <P>
          If you need to boost charge your EV, you’ll be charged your standard
          energy rate.
        </P>{' '}
        <P>You can activate boost mode in your EV charger app.</P>
      </Accordion>
      <Accordion headingComponent={Heading4} title="What is Kaluza?">
        <P>
          Kaluza is the intelligent software platform that powers the Charge
          Anytime add-on. Using AI and real-time data from a multitude of
          sources, it makes sure your electric vehicle is charged when energy
          demand, or carbon intensity, is lower.
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="Which EV chargers are compatible with Charge Anytime?"
      >
        <P>
          The good news is there are several models which work with Charge
          Anytime. These are:
        </P>
        <Ul>
          <Li>Indra Smart PRO</Li>
          <Li>Indra Smart Charger V3</Li>
          <Li>Ohme Home</Li>
          <Li>Ohme Home Pro</Li>
        </Ul>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="How do I know if I’m eligible for the Charge Anytime add-on?"
      >
        <P>
          You can add Charge Anytime to your existing OVO Energy plan, if you
          have a compatible EV charger that can be connected to Kaluza for smart
          charging. You’ll also need a compatible smart meter.
        </P>
        <P>
          You’ll also need to be OK with your smart meter sharing half-hourly
          readings with us.
        </P>
        <P>
          If your smart meter isn’t set up to share half-hourly data, we’ll ask
          you to opt in to doing so when you add Charge Anytime.
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="How does the Charge Anytime add-on work?"
      >
        <P>
          At the end of the month, we’ll work out how much energy you've used to
          smart charge your EV.
        </P>
        <P>
          We'll use this information to credit your account in the first week of
          the following month.
        </P>
        <P>
          Check out how it all works in this{' '}
          <TextLink
            href="https://www.ovoenergy.com/electric-cars/anytime-credit-explained"
            opensInNewWindow
          >
            handy example
          </TextLink>
          .
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="Will my Direct Debit be affected?"
      >
        <P>
          Your Charge Anytime charging credit won’t impact your Direct debit at
          all. In fact, you can even ask for this credit to be refunded to you
          once you’ve got enough of them. Find out more{' '}
          <TextLink href="https://www.ovoenergy.com/help/refunds">
            here
          </TextLink>
          .
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="What if I don’t agree with the calculation charges?"
      >
        <P>
          If you believe the calculation of your Charge Anytime charging credit
          is incorrect you can query this by emailing{' '}
          <TextLink href={`mailto:${CHARGE_ANYTIME_EMAIL_ADDRESS}`}>
            {CHARGE_ANYTIME_EMAIL_ADDRESS}
          </TextLink>{' '}
          and providing any evidence. Please note that OVO cannot take
          responsibility for the quality of the data supplied by your home
          charger, and any accuracy issues relating to the charger will need to
          be taken up with its manufacturer.
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="What happens if I choose to leave Charge Anytime?"
      >
        <P>
          If you want to leave Charge Anytime for any reason, you can cancel the
          add-on whenever you like and at no extra cost. Just head to the Plan
          tab in your OVO account and click on the cancel add-on button.
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="Am I eligible if I have solar PV?"
      >
        <P>
          Yes, customers with a chargepoint that measures solar consumption are
          eligible for this add-on. Customers who have solar but do not have a
          chargepoint that measures it are not currently eligible for Charge
          Anytime. Any charging from solar energy will not contribute to your
          Charge Anytime charging credit (as the credit is only paid on energy
          you have purchased from OVO to smart charge your EV).
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="What will the credit look like on my bill?"
      >
        <P>
          We apply credit from EV smart charging to your account in the first
          week of every month. It will also appear on your monthly OVO Energy
          bill under the heading ‘Charge Anytime Credit’. The credit shown on
          your bill will be for the electricity you’ve used for EV smart
          charging your EV the previous month. So in the first week of
          September, we’ll apply your credit for August.
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="What happens if there is a problem?"
      >
        <P>
          If you have any problems while using the Charge Anytime add-on, we’ll
          be happy to help. Just get in touch with us at{' '}
          <TextLink href={`mailto:${CHARGE_ANYTIME_EMAIL_ADDRESS}`}>
            {CHARGE_ANYTIME_EMAIL_ADDRESS}
          </TextLink>
          .
        </P>
      </Accordion>
      <Accordion
        headingComponent={Heading4}
        title="Why do I need to share half-hourly meter readings?"
      >
        <P>
          By sharing half-hourly meter information with us, it gives us a much
          more precise and detailed breakdown of what energy you’re using and
          when. This information, coupled with Kaluza’s data and tech, gives us
          extra purchasing power when we’re buying energy on your behalf.
          Meaning we can buy the energy that you consume cheaper – which is why
          we’re able to offer you Charge Anytime’s super-low smart charging
          rate.
        </P>
      </Accordion>
    </StyledAccordionGroup>
  );
};
