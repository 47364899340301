import { Card, Col, Heading1, Li, Ol, P, Row, TextLink } from '@ovotech/nebula';
import { AnalyticsClick, Sandwich, useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import LogoutLink from '../Navigation/Links/LogoutLink';
import DeleteCookies from './DeleteCookies';
import {
  LargeScreenImage,
  SmallScreenImage,
  Wrapper,
} from './GlobalErrorPage.styled';
import { PageWithSimpleHeader, Section } from '@/src/components';
import {
  GLOBAL_ERROR_LINK,
  GLOBAL_ERROR_PAGE,
} from '@/src/constants/analytics-subjects';
import { EXTERNAL_ROUTE_HELP_GLOBAL_ERROR } from '@/src/constants/routes';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { MajorIncidentGeneralBanner } from '@/src/pages/Home/components/mi-banners';
import { ReactComponent as ErrorImage } from '@/src/resources/global-error.svg';
import { createInstance, OptimizelyProvider } from '@/src/utils/optimizely';
import { reportError } from '@/src/services/datadog';

type Props = {
  id: string;
  errorCodes?: number[];
};

export const GlobalErrorPage = ({ errorCodes, id }: Props) => {
  const is431 = errorCodes ? errorCodes.includes(431) : false;

  const { dispatch } = useAnalytics();
  dispatch({
    name: GLOBAL_ERROR_PAGE,
    type: 'view',
    properties: { id, errorCodes },
  });

  useEffect(() => {
    reportError('GlobalErrorPageRendered', {
      ...(errorCodes ? { errorCodes } : {}),
    });
  }, [errorCodes]);

  return (
    <PageWithSimpleHeader
      title="Oops! It looks like we need to fix something..."
      headerLink={<LogoutLink key="logoutLink" />}
    >
      <MajorIncidentGeneralBanner />
      <Section>
        <Card data-testid="global-error-page">
          <Row isNested>
            <Col small={9} as={Wrapper}>
              <div>
                <Heading1>
                  {is431
                    ? "Oops! There's a problem with your cookies"
                    : 'Oops! It looks like we need to fix something...'}
                </Heading1>
                <SmallScreenImage>
                  <Sandwich id={sandwichIds.globalErrorPage.image}>
                    <ErrorImage />
                  </Sandwich>
                </SmallScreenImage>
                {is431 ? (
                  <>
                    <P>
                      Delete your cookies for this site to fix the problem. This
                      will log you out, but don't worry - you just need to log
                      back in again.
                    </P>
                    <P>
                      <DeleteCookies>Delete your OVO cookies</DeleteCookies>
                    </P>
                  </>
                ) : (
                  <>
                    <Ol>
                      <Li>Please try refreshing the page</Li>
                      <Li>If that doesn't work, log out, then log in again</Li>
                      <Li>
                        If it's still not loading,{' '}
                        <AnalyticsClick
                          name={GLOBAL_ERROR_LINK}
                          properties={{ linkId: 'help' }}
                        >
                          <TextLink
                            href={EXTERNAL_ROUTE_HELP_GLOBAL_ERROR}
                            opensInNewWindow
                          >
                            find more help and advice here
                          </TextLink>
                        </AnalyticsClick>
                      </Li>
                    </Ol>
                    <P>
                      Just so you know, we've told our web experts about this
                      problem
                    </P>
                  </>
                )}
              </div>
            </Col>
            <Col small={3} as={LargeScreenImage}>
              <Sandwich id={sandwichIds.globalErrorPage.image}>
                <ErrorImage />
              </Sandwich>
            </Col>
          </Row>
        </Card>
      </Section>
    </PageWithSimpleHeader>
  );
};

const GlobalErrorWithOptimizely = ({ id, errorCodes }: Props) => {
  const optimizely = createInstance({
    sdkKey: process.env.NX_OPTIMIZELY_SDK_KEY,
  });

  return (
    // @ts-ignore react-18 children props
    <OptimizelyProvider optimizely={optimizely} timeout={500}>
      <GlobalErrorPage id={id} errorCodes={errorCodes} />
    </OptimizelyProvider>
  );
};

export default GlobalErrorWithOptimizely;
