import { AddressFormatted, OrderType, PaymentType } from './types';
import { PROMO_CODES } from '@/src/constants/products';
import { SmartThermostatsPricing } from '@/src/types/Response';

export const hasBlackFridayDiscount = (
  hasDiscount: boolean,
  promoCode?: string,
) => {
  return hasDiscount && promoCode === PROMO_CODES.smartThermostatBlackFriday;
};

export const hasBeyondDiscount = (hasDiscount: boolean, promoCode?: string) => {
  return hasDiscount && promoCode === PROMO_CODES.beyond0524;
};

export const getBoltonPrice = (
  pricing: SmartThermostatsPricing,
  paymentType: PaymentType,
  orderType: OrderType,
) => {
  const boltonPricing = pricing[paymentType][orderType];
  return boltonPricing.discountedTotal ?? boltonPricing.total;
};

export const formatAddress = (address: string[]): AddressFormatted => {
  if (address.length === 3) {
    const [addressLine1, city, postalCode] = address;

    return {
      addressLine1,
      city,
      postalCode,
    };
  }

  const [addressLine1, addressLine2, city, postalCode] = address;

  return {
    addressLine1,
    addressLine2,
    city,
    postalCode,
  };
};

export const buildPromoCodeLink = (path: string, promoCode?: string) => {
  const queryParams = promoCode ? `?promoCode=${promoCode}` : '';
  return path + queryParams;
};
