import { overrideableComponents } from '@ovotech/energy-cx';
import React, { ComponentProps } from 'react';
import { useError } from '@/src/components';
import { ErrorSectionId } from '@/src/types/ErrorSectionIds';

const OepErrorSection = overrideableComponents.ErrorSection;

type Props = Omit<ComponentProps<typeof OepErrorSection>, 'id'> & {
  id: ErrorSectionId;
};

const ErrorSection = ({ id, alertLevel, errorMetadata, ...rest }: Props) => {
  useError(true, alertLevel, id, errorMetadata || {});

  return (
    <OepErrorSection
      id={id}
      alertLevel={alertLevel}
      errorMetadata={errorMetadata}
      {...rest}
    />
  );
};

export default ErrorSection;
