import { P } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React, { useEffect, useState } from 'react';

import { hubTestIDs as testIDs } from '../../testIDs';
import { ActionCard } from '../ActionCard/ActionCard';
import { statusMap } from './HalfHourlyOptIn.data';
import { useHalfHourlyOptInMutation } from '@/src/api/use-half-hourly-opt-in-mutation';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';

import { GridContainer } from '@/src/pages/EnergyInsights/components';
import AnalyticsService from '@/src/services/analyticsService';
import { useLocalStorage } from '@/src/utils/useLocalStorage';

type Props = {
  hasSmartMeter: boolean | null;
  halfHourlyReads: boolean | null;
};

export const HalfHourlyOptIn = ({ halfHourlyReads, hasSmartMeter }: Props) => {
  const { accountId } = useAccountContext();
  const putHalfHourlyOptIn = useHalfHourlyOptInMutation();

  const [closedBanner, setClosedBanner] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [optedInThisSession, setOptedInThisSession] = useState(false);
  const [alreadyOptedIn, setAlreadyOptedIn] = useLocalStorage(
    `half-hourly-opted-in-${accountId}`,
    false,
  );

  const maxRetriesReached = retryCount === 3;

  useEffect(() => {
    switch (putHalfHourlyOptIn.status) {
      case 'success':
        AnalyticsService.dispatch(ENERGY_INSIGHTS.HALF_HOURLY_OPT_IN_SUCCESS);
        setOptedInThisSession(true);
        setAlreadyOptedIn(true);
        break;
      case 'error':
        AnalyticsService.dispatch(ENERGY_INSIGHTS.HALF_HOURLY_OPT_IN_ERROR);
        break;
      default:
        break;
    }
  }, [putHalfHourlyOptIn.status, setAlreadyOptedIn]);

  const halfHourlyReadsStatusUnknown = halfHourlyReads === null;
  const statusConfig = statusMap[putHalfHourlyOptIn.status];

  if (
    !hasSmartMeter ||
    halfHourlyReads ||
    halfHourlyReadsStatusUnknown ||
    (alreadyOptedIn && !optedInThisSession)
  ) {
    return null;
  }

  return closedBanner ? null : (
    <GridContainer>
      <ActionCard
        testId={testIDs.halfHourlyOptIn}
        title={statusConfig.title}
        buttonTitle={
          maxRetriesReached
            ? 'Please try again later'
            : statusConfig.buttonTitle
        }
        buttonStyle="secondary"
        body={
          <span>
            <P data-testid={statusConfig.bodyTestId}>{statusConfig.bodyText}</P>
          </span>
        }
        handleOnClickActionButton={() => {
          if (maxRetriesReached) {
            return null;
          }

          if (statusConfig.onClickEvent) {
            AnalyticsService.dispatch(statusConfig.onClickEvent);
          }

          if (putHalfHourlyOptIn.status !== 'loading') {
            putHalfHourlyOptIn.mutate();
          }
          if (putHalfHourlyOptIn.status === 'success') {
            setClosedBanner(true);
          }
          if (putHalfHourlyOptIn.status === 'error') {
            setRetryCount(1 + retryCount);
          }
        }}
      />
    </GridContainer>
  );
};
