import { KAPI_URL } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { useCustomerContext } from '@ovotech/ui-tools';
import { useMutation, useQueryClient } from 'react-query';
import { graphql } from './__generated__';
import { print } from 'graphql';
import { UpdateCustomerMutationVariables } from './__generated__/graphql';
import { CommunicationChannel } from '@monovo/kapi';
import { getMarketingPreferencesQueryCacheKey } from './use-marketing-preferences-query';

export const MARKETING_PREFERENCES_MUTATION =
  graphql(/* kapi-codegen-graphql */ `
    mutation UpdateCustomer(
      $customerId: String!
      $realm: String!
      $operations: [JSONPatchOperation!]!
    ) {
      updateCustomer(operations: $operations, realm: $realm, id: $customerId)
    }
  `);

export const useMarketingPreferencesMutation = (
  onSuccess?: () => void,
  onError?: () => void,
) => {
  const { customerId } = useCustomerContext();
  const queryClient = useQueryClient();

  return useMutation(
    (updatedCommunicationChannels: readonly CommunicationChannel[]) => {
      return apiService.graphql<UpdateCustomerMutationVariables>({
        responseType: 'json',
        url: KAPI_URL!,
        body: {
          query: print(MARKETING_PREFERENCES_MUTATION),
          variables: {
            customerId,
            realm: 'ovo-energy',
            /** Standard JSON Patch (See http://jsonpatch.com/). */
            operations: [
              {
                op: 'replace',
                path: '/marketingPreferences',
                value: updatedCommunicationChannels,
              },
            ],
          },
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getMarketingPreferencesQueryCacheKey(customerId),
        );
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        if (onError) {
          onError();
        }
      },
    },
  );
};
