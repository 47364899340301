import React from 'react';
import { StyledArrowButton, Chevron } from './ArrowButton.styled';

type Props = {
  direction: 'right' | 'left';
  size?: number;
  onClick?: () => any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  dataTestId?: string;
  ariaLabel: string;
  margin?: string;
};

export const ArrowButton = ({
  direction,
  size = 42,
  onClick = undefined,
  disabled = false,
  type = 'button',
  dataTestId,
  ariaLabel,
  margin,
}: Props) => {
  return (
    <StyledArrowButton
      data-testid={dataTestId}
      direction={direction}
      size={size}
      onClick={onClick}
      disabled={disabled}
      type={type}
      aria-label={ariaLabel}
      margin={margin}
    >
      <Chevron viewBox="0 0 40 40" disabled={disabled}>
        <path d="M30.014 21L18.732 32.281l-2.003-2.003L26.007 21l-9.278-9.278 2.003-2.003L30.014 21z" />
      </Chevron>
    </StyledArrowButton>
  );
};
