import {
  Callout,
  Card,
  Margin,
  P,
  PrimaryCTAButton,
  Radio,
  RadioField,
  SkeletonCircle,
  SkeletonCTA,
  SkeletonHeading,
  SkeletonText,
  Small,
  Strong,
  TextLink,
} from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom-v5';
import { PRIVACY_POLICY_LINK, SMART_THERMOSTAT_LINK } from '../constants';
import { useTadoPayMonthlyEligibility } from '../hooks/use-tado-pay-monthly-eligibility';
import { useTadoPricingQuery } from '../hooks/use-tado-pricing';
import { OrderType, PaymentType } from '../utils/types';
import {
  Divider,
  StyledHint,
  StyledPayNowCard,
  StyledRadio,
} from './PayNow.styled';
import {
  useProductCatalogueQuery,
  useTadoPayMonthlyMutation,
  useTadoPayUpfrontMutation,
} from '@/src/api';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { PRODUCT_IDS } from '@/src/constants/products';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { TadoPayUpfrontMutationResponse } from '@/src/types/Response';
import {
  Spinner,
  SpinnerColor,
  SpinnerSize,
} from '@/src/components/LoadingSpinner/Spinner.styled';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import { getBoltonPrice } from '../utils/utils';

const isRedirectResponse = (
  response: TadoPayUpfrontMutationResponse,
): response is { redirectUrl: string } => {
  return typeof response === 'object' && 'redirectUrl' in response;
};

export const PayNow = () => {
  const promoCode = getQueryParamValue('promoCode');
  const tadoPricing = useTadoPricingQuery(promoCode);
  const tadoPayMonthlyEligibility = useTadoPayMonthlyEligibility();
  const products = useProductCatalogueQuery();
  const payUpfrontMutation = useTadoPayUpfrontMutation();
  const payMonthlyMutation = useTadoPayMonthlyMutation();
  const [paymentType, setPaymentType] = useState<'monthly' | 'upfront' | null>(
    null,
  );
  const profile = useProfileQueryKapi();

  const history = useHistory();
  const [errorCount, setErrorCount] = useState(0);
  const [missingPhoneError, setMissingPhoneError] = useState(false);

  const { dispatch } = useAnalytics();

  useEffect(() => {
    if (errorCount === 3) {
      history.push({
        pathname: `${ROUTE_SMART_THERMOSTATS_OFFER}/checkout/error`,
        state: { reason: 'Failed after 3 retries.' },
      });
    }
  }, [errorCount, history]);

  useEffect(() => {
    if (tadoPayMonthlyEligibility.isError) {
      dispatch({
        type: 'view',
        name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO.ERROR
          .PAY_NOW_BUTTON_HIDDEN,
        properties: {
          ineligibleReasons:
            tadoPayMonthlyEligibility.ineligibleReasons.join(', '),
        },
      });
    }
  }, [dispatch, tadoPayMonthlyEligibility]);

  const payMonthlyBoltonVersionId =
    products.data?.boltons.find(
      bolton => bolton.productId === PRODUCT_IDS.smartThermostatSelfInstall,
    )?.versionId ?? '';

  const payUpfrontBoltonVersionId =
    products.data?.boltons.find(
      bolton =>
        bolton.productId === PRODUCT_IDS.smartThermostatsPayUpfrontSelfInstall,
    )?.versionId ?? '';

  if (
    tadoPricing.isLoading ||
    tadoPayMonthlyEligibility.isLoading ||
    profile.isFetching
  ) {
    return (
      <Card>
        <SkeletonHeading />
        <SkeletonText lines={3} />
        <SkeletonCircle />
        <SkeletonCTA />
      </Card>
    );
  }

  if (
    tadoPricing.isError ||
    tadoPricing.data === undefined ||
    tadoPayMonthlyEligibility.isError
  ) {
    return null;
  }

  const upfrontBoltonPricing = getBoltonPrice(
    tadoPricing.data,
    PaymentType.FullAmountPayment,
    OrderType.SelfInstall,
  );

  const monthlyBoltonPricing = getBoltonPrice(
    tadoPricing.data,
    PaymentType.MonthlyPayment,
    OrderType.SelfInstall,
  );

  const totalMonthlyPrice = Number(monthlyBoltonPricing).toFixed(2);
  const upfrontPrice = Number(upfrontBoltonPricing).toFixed(2);
  const monthlyPrice = Number(monthlyBoltonPricing / 12).toFixed(2);

  const {
    contactEmail,
    givenName,
    familyName,
    contactAddress,
    contactPhoneNumbers,
  } = profile.data?.customer_nextV1 || {};

  const paymentPayload = {
    email: contactEmail ?? '',
    firstName: givenName ?? '',
    lastName: familyName ?? '',
    addressLine1: contactAddress?.postalAddress.line1 ?? '',
    addressLine2: contactAddress?.postalAddress.line2 ?? '',
    city: contactAddress?.postalAddress.town ?? '',
    postalCode: contactAddress?.postalAddress.postalCode ?? '',
    phone: contactPhoneNumbers?.[0] ?? '',
    promoCode: promoCode,
  };

  const handlePayment = () => {
    if (!profile.data?.customer_nextV1.contactPhoneNumbers[0]) {
      setMissingPhoneError(true);
      return;
    }

    if (paymentType === 'monthly') {
      !payMonthlyMutation.isLoading &&
        payMonthlyMutation.mutate(
          { versionId: payMonthlyBoltonVersionId, ...paymentPayload },
          {
            onSuccess: () =>
              history.push({
                pathname: `${ROUTE_SMART_THERMOSTATS_OFFER}/checkout/success`,
                state: { paymentMethod: 'Monthly charge to energy account' },
              }),
            onError: () => {
              setErrorCount(prevCount => prevCount + 1);
            },
          },
        );
      return;
    }

    // if they are not eligible for pay monthly, fall back to upfront
    // or if they have not selected paymentType as monthly
    !payUpfrontMutation.isLoading &&
      payUpfrontMutation.mutate(
        { versionId: payUpfrontBoltonVersionId, ...paymentPayload },
        {
          onSuccess: data => {
            if (isRedirectResponse(data)) {
              window.location.href = data.redirectUrl;
            }
          },
          onError: () => {
            setErrorCount(prevCount => prevCount + 1);
          },
        },
      );
  };

  return (
    <StyledPayNowCard>
      <P>
        <Strong>tado° Wireless Smart Thermostat Starter Kit V3+</Strong>
      </P>
      <P style={{ marginBottom: 0 }}>
        <Strong>
          Total (1 Item): £
          {paymentType === 'monthly' ? totalMonthlyPrice : upfrontPrice}
        </Strong>
      </P>
      {tadoPayMonthlyEligibility.isEligible ? (
        <>
          <Divider margin={4} />
          <StyledRadio>
            <RadioField
              name="sport"
              label="Payment options"
              defaultValue="reduceEm"
            >
              <Radio
                label={`£${monthlyPrice} per month for 12 months`}
                hint={<Small>Charged to your energy account</Small>}
                id="monthly"
                value="12x12"
                onChange={() => {
                  setPaymentType('monthly');
                  setErrorCount(0);

                  dispatch({
                    type: 'click',
                    name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO
                      .CLICKED.PAYMENT_OPTION,
                    properties: {
                      paymentTypeClicked: 'monthly',
                    },
                  });
                }}
              />
              <Radio
                label={`£${upfrontPrice} to pay now`}
                hint={<Small>By debit / credit card</Small>}
                id="fullPrice"
                value="144"
                onChange={() => {
                  setPaymentType('upfront');
                  setErrorCount(0);

                  dispatch({
                    type: 'click',
                    name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO
                      .CLICKED.PAYMENT_OPTION,
                    properties: {
                      paymentTypeClicked: 'upfront',
                    },
                  });
                }}
              />
            </RadioField>
          </StyledRadio>
        </>
      ) : (
        <StyledHint>By debit / credit card</StyledHint>
      )}

      <Margin top={8} bottom={8}>
        <AnalyticsClick
          name={
            SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO.CLICKED
              .CONFIRM_MY_ORDER
          }
          properties={{ paymentOptionSelected: paymentType }}
        >
          <PrimaryCTAButton
            fullWidth="always"
            type="button"
            variant="secondary"
            disabled={tadoPayMonthlyEligibility.isEligible && !paymentType}
            onClick={handlePayment}
          >
            {payMonthlyMutation.isLoading ? (
              <Spinner
                data-testid="checkout-button-loader"
                size={SpinnerSize.SMALL}
                color={SpinnerColor.WHITE}
                style={{ verticalAlign: 'middle' }}
              />
            ) : paymentType === 'monthly' ? (
              'Confirm my order'
            ) : (
              'Enter payment details'
            )}
          </PrimaryCTAButton>
        </AnalyticsClick>
      </Margin>
      {(payMonthlyMutation.isError || payUpfrontMutation.isError) && (
        <Callout variant="error">
          <P>Sorry, something went wrong. Please try again.</P>
        </Callout>
      )}

      {missingPhoneError && (
        <Callout variant="error">
          <P>Please enter your phone number before you check out.</P>
        </Callout>
      )}

      <Margin top={6}>
        <P>
          By confirming your purchase, you agree to our{' '}
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO.CLICKED
                .TERMS_AND_CONDITIONS
            }
          >
            <TextLink
              href={SMART_THERMOSTAT_LINK}
              opensInNewWindow
              data-event-name={
                SMART_THERMOSTATS.CHECKOUT_PAGE.CLICKED.UPDATE_DETAILS
              }
            >
              terms and conditions
            </TextLink>
          </AnalyticsClick>{' '}
          and{' '}
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO.CLICKED
                .PRIVACY_POLICY
            }
          >
            <TextLink
              href={PRIVACY_POLICY_LINK}
              opensInNewWindow
              data-event-name={
                SMART_THERMOSTATS.CHECKOUT_PAGE.CLICKED.UPDATE_DETAILS
              }
            >
              privacy policy
            </TextLink>
          </AnalyticsClick>
        </P>
      </Margin>
    </StyledPayNowCard>
  );
};
