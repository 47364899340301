import mixpanel from 'mixpanel-browser';
import { hasOptedIntoTrackingCookies } from './onetrust-utils';

type TrackWithEventQueue = (name: string, data: { [key: string]: any }) => void;

type MixpanelEventsQueue = {
  track: TrackWithEventQueue;
};

function getQueryParam(url: string, param: string) {
  // Expects a raw URL
  // eslint-disable-next-line no-empty-character-class
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  const regexS = '[?&]' + param + '=([^&#]*)',
    regex = new RegExp(regexS),
    results = regex.exec(url);
  if (
    results === null ||
    // @ts-ignore
    (results && typeof results[1] !== 'string' && results[1].length)
  ) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
}

// Last Touch Mixpanel UTM tags - https://help.mixpanel.com/hc/en-us/articles/360001337103-Last-Touch-UTM-Tags
export function campaignParams() {
  const campaign_keywords =
    'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');

  const params = {};

  for (let index = 0; index < campaign_keywords.length; ++index) {
    const kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      // @ts-ignore
      params[campaign_keywords[index] + ' [last touch]'] = kw;
    }
  }

  mixpanel.register(params);
}

const trackWithEventQueue = (): TrackWithEventQueue => {
  let eventsQueue: Array<{
    name: string;
    data?: Record<string, any>;
  }> = [];

  const EVENT_QUEUE_LIMIT = 30;

  return (name: string, data: { [key: string]: any }) => {
    if (hasOptedIntoTrackingCookies() && !mixpanel.has_opted_in_tracking())
      mixpanel.opt_in_tracking();

    if (mixpanel.has_opted_in_tracking()) {
      eventsQueue.forEach(event => {
        mixpanel.track(event.name, event.data);
      });
      eventsQueue = [];
      mixpanel.track(name, data);
      return;
    }

    if (eventsQueue.length < EVENT_QUEUE_LIMIT) {
      eventsQueue.push({ name, data });
    }
  };
};

export const MixpanelEventsQueue: MixpanelEventsQueue = {
  track: trackWithEventQueue(),
};
