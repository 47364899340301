import { VisuallyHidden } from '@ovotech/nebula';
import React from 'react';

import {
  StyledProductOriginalPrice,
  StyledProductPrice,
} from './ProductPrice.styled';
import { formatPoundsAmount } from '@/src/utils/money';

type Props = {
  // eslint-disable-next-line react/require-default-props
  'data-testid'?: string;
  cost: {
    initial: number;
    current: number;
  };
};
const ProductPrice = ({ cost, 'data-testid': dataTestId }: Props) => {
  return (
    <StyledProductPrice data-testid={dataTestId}>
      {cost.initial !== cost.current ? (
        <StyledProductOriginalPrice>
          <VisuallyHidden>Was </VisuallyHidden>
          {formatPoundsAmount(cost.initial)}
          <VisuallyHidden>, now</VisuallyHidden>
        </StyledProductOriginalPrice>
      ) : null}{' '}
      {formatPoundsAmount(cost.current)} a month
    </StyledProductPrice>
  );
};

export default ProductPrice;
