import { P, PrimaryCTALink } from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import { Media } from '@/src/components/Media';
import {
  DesktopContainer,
  VolvoLeftContent,
  VolvoMobileContainer,
  VolvoRightContent,
} from '../ChargeAnytimeAppLink/ChargeAnytimeAppLink.styled';
import { breakpoints } from '@/src/utils/mediaQuery';
import VOLVO_APP from '@/src/resources/volvo-app.png';
import { VOLVO_APP_LINK, EV } from '@monovo/ev-anytime';
import { PageHeader } from '@/src/pages/AnytimeEligibility/components/PageHeader';
import { PageWithNavigation } from '@/src/components';

export const VolvoAnytimeAppLink = () => {
  const { dispatch } = useAnalytics();

  useEffect(() => {
    dispatch({
      type: 'view',
      name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VOLVO_APP_VIEWED,
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'view',
      name: 'EVS - EVS OVO Charge Anytime Volvo app link viewed',
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWithNavigation title="Charge Anytime">
      <PageHeader
        data-testid="success-message"
        title={'Activate Charge Anytime'}
        showDescription={false}
      />
      <Media query={breakpoints.mediumUp}>
        <DesktopContainer>
          <VolvoLeftContent>
            <img src={VOLVO_APP} alt="EV Anytime Volvo App" />
          </VolvoLeftContent>
          <VolvoRightContent>
            <P>
              Now, just go back to your Volvo app, link your account, and start
              charging!
            </P>
            <AnalyticsClick
              name={
                EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VOLVO_APP_LINK_CLICKED
              }
            >
              <PrimaryCTALink
                data-event-name={
                  'EVS - EVS OVO Drive Anytime volvo link clicked'
                }
                opensInNewWindow
                href={VOLVO_APP_LINK}
              >
                Go to your Volvo app
              </PrimaryCTALink>
            </AnalyticsClick>
          </VolvoRightContent>
        </DesktopContainer>
      </Media>

      <Media query={breakpoints.mediumDown}>
        <VolvoMobileContainer>
          <P>
            Now, just go back to your Volvo app, link your account, and start
            charging!
          </P>
          <img src={VOLVO_APP} alt="EV Anytime Volvo App" />
          <AnalyticsClick
            name={EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VOLVO_APP_LINK_CLICKED}
          >
            <PrimaryCTALink
              data-event-name={'EVS - EVS OVO Drive Anytime volvo link clicked'}
              opensInNewWindow
              href={VOLVO_APP_LINK}
            >
              Go to your Volvo app
            </PrimaryCTALink>
          </AnalyticsClick>
        </VolvoMobileContainer>
      </Media>
    </PageWithNavigation>
  );
};
