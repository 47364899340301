import { themeConfig } from '@ovotech/ovoenergy-theme';
import React from 'react';
import {
  StyledKey,
  StyledKeyBox,
  StyledKeyItem,
} from './ComparisonGraphKey.styled';

type KeyItemProps = {
  colour: string;
  label: string;
};

const KeyItem = ({ colour, label }: KeyItemProps) => (
  <StyledKeyItem>
    <StyledKeyBox colour={colour} />
    {label}
  </StyledKeyItem>
);

export const ComparisonGraphKey = () => {
  return (
    <StyledKey>
      <KeyItem
        colour={themeConfig.colors.brand.electricity.base}
        label="Electricity"
      />
      <KeyItem colour={themeConfig.colors.brand.gas.base} label="Gas" />
    </StyledKey>
  );
};
