import {
  Card,
  Col,
  Heading4,
  Margin,
  P,
  Row,
  Skeleton,
  SummaryItem,
  SummaryList,
  TextLink,
} from '@ovotech/nebula';
import React from 'react';

import styled from 'styled-components';
import { usePersonalInfo } from '../hooks/usePersonalInfo';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { ROUTE_PROFILE } from '@/src/constants/routes';
import { AnalyticsClick } from '@ovotech/ui-tools';

const StyledDetailsCard = styled(Card)`
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  & dd {
    word-break: break-all;
  }

  & .details__edit {
    &:first-of-type {
      margin: 1.25rem 0 0 0;
    }
  }
`;

export const DetailsCard = () => {
  const userDetails = usePersonalInfo();

  if (userDetails.isFetching) {
    return (
      <Card>
        <Skeleton />
      </Card>
    );
  }

  return (
    <StyledDetailsCard>
      <Heading4>Your details</Heading4>
      <Margin bottom={6}>
        <Row isNested>
          <Col span={12} small={4} medium={6} large={4}>
            <SummaryList>
              <SummaryItem label="Delivery address" data-testid="address">
                {userDetails.userAddress?.line1 && (
                  <div>{userDetails.userAddress.line1}</div>
                )}
                {userDetails.userAddress?.line2 && (
                  <div>{userDetails.userAddress.line2}</div>
                )}
              </SummaryItem>
            </SummaryList>
          </Col>
          <Col span={12} small={4} medium={6} large={4}>
            <SummaryList>
              <SummaryItem label="Phone" data-testid="phone">
                {userDetails.userPhone}
              </SummaryItem>
              <P className="details__edit">
                <AnalyticsClick
                  name={
                    SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO
                      .CLICKED.EDIT_PHONE
                  }
                >
                  <TextLink
                    href={ROUTE_PROFILE}
                    data-event-name={
                      SMART_THERMOSTATS.CHECKOUT_PAGE.CLICKED.UPDATE_DETAILS
                    }
                  >
                    Edit phone
                  </TextLink>
                </AnalyticsClick>
              </P>
            </SummaryList>
          </Col>
          <Col span={12} small={4} medium={12} large={4}>
            <SummaryList>
              <SummaryItem label="Email address" data-testid="phone">
                {userDetails.userEmail}
              </SummaryItem>
              <P className="details__edit">
                <AnalyticsClick
                  name={
                    SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO
                      .CLICKED.EDIT_EMAIL
                  }
                >
                  <TextLink
                    href={ROUTE_PROFILE}
                    data-event-name={
                      SMART_THERMOSTATS.CHECKOUT_PAGE.CLICKED.UPDATE_DETAILS
                    }
                  >
                    Edit email
                  </TextLink>
                </AnalyticsClick>
              </P>
            </SummaryList>
          </Col>
        </Row>
      </Margin>
    </StyledDetailsCard>
  );
};
