import { EnergyContract } from '@/src/types/Account';

/**
 * Given an array of contracts, it will find which is the first contract in loss.
 *
 * @param contracts the array to search
 * @returns the contract, or null
 */
export const findContractInLoss = (
  contracts: EnergyContract[],
): EnergyContract | null => {
  if (contracts.length === 0) return null;

  const foundContract = contracts.find(contract => {
    if (
      contract.lossDate !== null &&
      (contract.status.inLoss || contract.status.lossComplete)
    ) {
      return true;
    }

    return false;
  });

  return foundContract ?? null;
};
