import {
  Card,
  ErrorNotification,
  Heading4,
  Margin,
  P,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView } from '@ovotech/ui-tools';
import React from 'react';
import { Link, useHistory } from 'react-router-dom-v5';
import { AnytimeNotEligible } from '../../../AnytimeEligibility/components/AnytimeNotEligible';
import { PageWithNavigation } from '@/src/components';
import { ButtonGroup } from '@/src/components/ButtonGroup/ButtonGroup';
import { EV } from '@monovo/ev-anytime';
import {
  CHARGE_ANYTIME_EMAIL_ADDRESS,
  ROUTE_HOME,
  ROUTE_PLAN,
} from '@/src/constants/routes';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';
import { useProductCancellationMutation } from '@/src/api/use-product-cancellation-mutation';

type ConfirmCancellationType = {
  cancelAnytime: () => void;
};

const ConfirmCancellation = ({ cancelAnytime }: ConfirmCancellationType) => {
  const history = useHistory();
  const goToPlanPage = () => {
    history.push(ROUTE_PLAN);
  };

  return (
    <AnalyticsView
      name={
        EV.DISCONNECT.EVS_OVO_CHARGE_ANYTIME_DISCONNECT_PERMISSION_PAGE_VIEWED
      }
    >
      <AnalyticsView name="EVS - EVS OVO Drive Anytime disconnect permission page viewed">
        <Card>
          <Heading4 data-testId="cancellation-header">
            If you cancel your Charge Anytime add-on...
          </Heading4>
          <Margin bottom={9}>
            <P data-testId="cancellation-text-body">
              You’ll stop receiving charging credit and will no longer have
              access to the Your EV hub.
            </P>
          </Margin>
          <ButtonGroup>
            <AnalyticsClick
              name={
                EV.DISCONNECT
                  .EVS_OVO_CHARGE_ANYTIME_DISCONNECT_PERMISSION_PAGE_YES_CLICKED
              }
            >
              <PrimaryCTAButton
                data-event-name={
                  'EVS - EVS OVO Drive Anytime disconnect permission page yes button clicked'
                }
                data-testid="confirm-button"
                onClick={cancelAnytime}
              >
                Yes, I want to cancel
              </PrimaryCTAButton>
            </AnalyticsClick>
            <AnalyticsClick
              name={
                EV.DISCONNECT
                  .EVS_OVO_CHARGE_ANYTIME_DISCONNECT_PERMISSION_PAGE_NO_CLICKED
              }
            >
              <SecondaryCTAButton
                data-event-name={
                  'EVS - EVS OVO Drive Anytime disconnect permission page no button clicked'
                }
                onClick={goToPlanPage}
                data-testid="reject-button"
              >
                No, I've changed my mind
              </SecondaryCTAButton>
            </AnalyticsClick>
          </ButtonGroup>
        </Card>
      </AnalyticsView>
    </AnalyticsView>
  );
};

const CancelSuccess = () => {
  const history = useHistory();
  const goToHomepage = () => {
    history.push(ROUTE_HOME);
  };
  return (
    <AnalyticsView
      name={EV.DISCONNECT.EVS_OVO_CHARGE_ANYTIME_DISCONNECT_SUCCESS_PAGE_VIEWED}
    >
      <AnalyticsView name="EVS - EVS OVO Drive Anytime disconnect success page viewed">
        <Card>
          <Heading4 data-testid="cancellation-confirmation-header">
            You’re no longer signed up to Charge Anytime
          </Heading4>
          <P>
            We’ve removed Charge Anytime from your OVO tariff. After your final
            charging credit is applied to your account, your charging data will
            no longer be shared with OVO, or visible in your OVO account.
          </P>
          <Margin bottom={12}>
            <P>
              It’ll take 24 hours to unlink your account. If you’ve removed
              Charge Anytime by mistake, or you’ve changed your mind, you can
              sign up again after the 24 hour period.
            </P>
          </Margin>
          <PrimaryCTAButton onClick={goToHomepage} data-testid="close-button">
            Close
          </PrimaryCTAButton>
        </Card>
      </AnalyticsView>
    </AnalyticsView>
  );
};

const CancelError = () => {
  return (
    <AnalyticsView
      name={EV.DISCONNECT.EVS_OVO_CHARGE_ANYTIME_DISCONNECT_ERROR_PAGE_VIEWED}
    >
      <AnalyticsView name="EVS - EVS OVO Drive Anytime disconnect error page viewed">
        <Card>
          <Margin bottom={6}>
            <ErrorNotification
              id="AnytimeCancelError"
              title="Sorry, there’s a bit of a problem"
            >
              <P>
                We couldn’t cancel your Charge Anytime add-on. Please try again,
                or email us at {CHARGE_ANYTIME_EMAIL_ADDRESS} and we’ll be happy
                to help.
              </P>
            </ErrorNotification>
          </Margin>
          <AnalyticsClick
            name={
              EV.DISCONNECT
                .EVS_OVO_CHARGE_ANYTIME_DISCONNECT_ERROR_PAGE_BACK_CLICKED
            }
          >
            <SecondaryCTAButton
              data-testid="back-button"
              data-event-name={
                'EVS - EVS OVO Drive Anytime tile cancel button clicked'
              }
              as={Link}
              to={ROUTE_PLAN}
            >
              Back
            </SecondaryCTAButton>
          </AnalyticsClick>
        </Card>
      </AnalyticsView>
    </AnalyticsView>
  );
};

export const EVAnytimeDisconnect = () => {
  const { chargeAnytimeBolton, isFetching } = useChargeAnytimeBoltonState();
  const chargeAnytimeProductId = chargeAnytimeBolton?.productId;
  const productCancellationMutation = useProductCancellationMutation();

  if (!chargeAnytimeProductId) {
    return !isFetching ? <AnytimeNotEligible /> : null;
  }

  const cancelAnytime = () => {
    productCancellationMutation.mutate(chargeAnytimeProductId);
  };

  const renderContent = (cancelAnytime: { (): void; (): void }) => {
    if (productCancellationMutation.isSuccess) {
      return <CancelSuccess />;
    } else if (productCancellationMutation.isError) {
      return <CancelError />;
    } else {
      return <ConfirmCancellation cancelAnytime={cancelAnytime} />;
    }
  };

  return (
    <PageWithNavigation title="Charge Anytime">
      <Margin top={8}>{renderContent(cancelAnytime)}</Margin>
    </PageWithNavigation>
  );
};
