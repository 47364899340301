import { Card } from '@ovotech/nebula';
import { getDaysInMonth } from 'date-fns';
import React, { useMemo, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  StyledGraph,
  StyledGraphCard,
  StyledToggleButton,
  StyledToggleButtonContent,
  StyledToggleButtonLabel,
} from './GasUsageChart.styled';
import {
  convertDateObjToMonthYear,
  convertMonthYearToDateObj,
  getNextMonth,
} from '../MonthlyNavigator/MonthlyNavigator';
import { DailyUsageData } from '@/src/types/Response';
import { SmartThermostatsChartDataPoint, MonthYear } from '@/src/types/State';
import { ReactComponent as ChevronDown } from '@/src/resources/chevron-down.svg';
import { ReactComponent as ChevronUp } from '@/src/resources/chevron-up.svg';
import { getThermostatData } from '../../hooks/getThermostatData';
import { ChartCustomTooltip } from '../ChartCustomTooltip';

const NOT_FOUND_INDEX = -1;

export function daysInMonth(monthYear: MonthYear) {
  const { year, month } = monthYear;
  return new Date(year, month, 0).getDate();
}

export function isCurrentMonth(monthYear: MonthYear) {
  const { year, month } = monthYear;
  return (
    new Date().getFullYear() === year && new Date().getMonth() + 1 === month
  );
}

export function generateMonthUsageDays(monthYear: MonthYear) {
  const countDays = isCurrentMonth(monthYear)
    ? new Date().getDate()
    : daysInMonth(monthYear);
  const usageDays = [];
  for (let i = 1; i <= countDays; i++) {
    usageDays.push({
      day: i,
      ind: undefined,
      out: undefined,
      gas: 0,
      cost: undefined,
    });
  }
  return usageDays;
}

export function mergeTempAndGasData(
  temperatureSeries: SmartThermostatsChartDataPoint[],
  gasUsageSeries: SmartThermostatsChartDataPoint[],
  monthYear: MonthYear,
) {
  const monthUsageDays = generateMonthUsageDays(monthYear);
  const countDays = monthUsageDays.length;
  const filteredInputData = isCurrentMonth(monthYear)
    ? [...temperatureSeries, ...gasUsageSeries].filter(d => d.day < countDays)
    : [...temperatureSeries, ...gasUsageSeries];
  return [...monthUsageDays, ...filteredInputData].reduce(
    (
      acc: SmartThermostatsChartDataPoint[],
      current: SmartThermostatsChartDataPoint,
    ) => {
      const existingIndex = acc.findIndex(d => d.day === current.day);
      if (existingIndex > NOT_FOUND_INDEX) {
        const updated = { ...acc[existingIndex], ...current };
        acc[existingIndex] = updated;
        return acc;
      }
      return [...acc, current];
    },
    [],
  );
}

export function roundToNearestUpperHundred(
  gasUsageSeries: SmartThermostatsChartDataPoint[],
) {
  const maxGasConsumption: number = gasUsageSeries.reduce(
    (max: number, current: SmartThermostatsChartDataPoint) =>
      current.gas && current.gas > max ? current.gas : max,
    0,
  );
  const dividedValue = maxGasConsumption / 100;
  const roundedValue = Math.round(dividedValue);
  if (roundedValue < dividedValue) {
    return Math.round(dividedValue + 1) * 100;
  }
  return roundedValue * 100;
}

// maxGasRounded assumed to always be divisible by
// 50, because of roundToNearestUpperHundred logic
export function calculateTickListForGas(maxGasRounded: number): number[] {
  const tickList: number[] = [];
  let currentGasTickValue = 0;
  while (currentGasTickValue <= maxGasRounded) {
    tickList.push(currentGasTickValue);
    currentGasTickValue = currentGasTickValue + 50;
  }
  return tickList;
}

type Props = {
  gasDailyUsageData?: DailyUsageData[];
  monthYear: MonthYear;
};

const axisLine = { stroke: '#F3F3F3' };

const convertUsageDataToChartPoints = (
  gasDailyUsageData?: DailyUsageData[],
): SmartThermostatsChartDataPoint[] => {
  const toChartDataPoint = (day: DailyUsageData) => ({
    cost: Number(day.cost?.amount),
    day: new Date(day.interval.start).getDate(),
    gas: day.consumption,
  });

  return !gasDailyUsageData ? [] : gasDailyUsageData.map(toChartDataPoint);
};

export const GasUsageChart = ({ monthYear, gasDailyUsageData }: Props) => {
  const [showGraph, setShowGraph] = useState(true);
  const dateObj = convertMonthYearToDateObj(monthYear);
  const dailyResponseForMonth = getThermostatData(
    monthYear,
    convertDateObjToMonthYear(getNextMonth(dateObj)),
    'day',
  );

  const gasChartPointData = convertUsageDataToChartPoints(gasDailyUsageData);
  const maxGasRounded = roundToNearestUpperHundred(gasChartPointData);
  const gasUsageTicks = useMemo(
    () =>
      maxGasRounded === 0
        ? [0, 50, 100, 150, 200]
        : calculateTickListForGas(maxGasRounded),
    [maxGasRounded],
  );

  const xAxisTickCount = getDaysInMonth(
    new Date(monthYear.year, monthYear.month - 1),
  );

  return (
    <Card as={StyledGraphCard}>
      <StyledToggleButton onClick={() => setShowGraph(!showGraph)}>
        <StyledToggleButtonContent>
          <StyledToggleButtonLabel>
            {showGraph ? 'Hide daily usage' : 'Show daily usage'}
          </StyledToggleButtonLabel>
          <span aria-hidden="true">
            {showGraph ? <ChevronUp /> : <ChevronDown />}
          </span>
        </StyledToggleButtonContent>
      </StyledToggleButton>
      <StyledGraph isHidden={!showGraph}>
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart
            height={500}
            data={mergeTempAndGasData(
              dailyResponseForMonth.data,
              gasChartPointData,
              monthYear,
            )}
            margin={{ top: 70, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              dataKey="day"
              type="number"
              axisLine={axisLine}
              padding={{ left: 15, right: 15 }}
              height={30}
              interval={0}
              tickLine={false}
              tickCount={xAxisTickCount}
              domain={[1, xAxisTickCount]}
            />
            {/* @ts-ignore react-18 children props */}
            <YAxis
              width={40}
              orientation="left"
              axisLine={axisLine}
              tickLine={false}
              ticks={gasUsageTicks}
              domain={[0, maxGasRounded]}
            >
              <Label value="kWh" position="top" offset={40} />
            </YAxis>
            {/* @ts-ignore react-18 children props */}
            <YAxis
              width={30}
              yAxisId="right"
              orientation="right"
              axisLine={axisLine}
              tickLine={false}
              ticks={[5, 10, 15, 20, 25]}
              domain={[0, 25]}
            >
              <Label value="°C" position="top" offset={40} />
            </YAxis>
            <Legend align="left" verticalAlign="bottom" iconType="circle" />
            <Bar
              dataKey="gas"
              name="Gas"
              fill="#26ADE4"
              fillOpacity="10"
              shape={<Rectangle radius={[3, 3, 0, 0]} />}
              barSize={10}
              isAnimationActive={false}
            />
            <Scatter
              dataKey="ind"
              name="Indoor Temperature"
              shape="square"
              fill="#DE1D8D"
              yAxisId="right"
              isAnimationActive={false}
            />
            <Scatter
              dataKey="out"
              name="Outside Temperature"
              yAxisId="right"
              shape="square"
              fill="#0D8426"
              isAnimationActive={false}
            />
            <Tooltip content={<ChartCustomTooltip />} cursor={false} />
          </ComposedChart>
        </ResponsiveContainer>
      </StyledGraph>
    </Card>
  );
};
