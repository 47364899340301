import { Card } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { ActionCentreBanner } from '../../Banner';
import { FinalStatementReadyBalanceInCredit } from './FinalStatementReadyBalanceInCredit';
import { FinalStatementReadyBalanceInDebit } from './FinalStatementReadyBalanceInDebit';
import { FinalStatementReadyBalanceZero } from './FinalStatementReadyBalanceZero';
import { useBillingQuery, useContractsQuery } from '@/src/api';
import { ErrorSection } from '@/src/components';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import {
  findContractInLoss,
  getFirstAndLastContractsInLoss,
  isDualFuelContract,
} from '@/src/utils/contracts';

const bannerId = 'finalStatementReady';

export const FinalStatementReady: ActionCentreBanner = () => {
  const { accountId } = useAccountContext();
  const latestBill = useBillingQuery(accountId ?? '', 'latest'); // TODO: move `useBillingQuery` into orion ui codebase and migrate to React Query
  const contracts = useContractsQuery(accountId);

  if (latestBill.isFetching || contracts.isLoading) return <SkeletonCard />;
  if (latestBill.status === 'error' || contracts.isError) {
    return (
      <Card>
        <ErrorSection alertLevel="error" id="final-statement-ready-error" />
      </Card>
    );
  }

  if (!contracts.data || latestBill.status !== 'data') return null;

  const isDualFuel = isDualFuelContract(contracts.data);
  const electricityContract = findContractInLoss(contracts.data.electricity);
  const gasContract = findContractInLoss(contracts.data.gas);
  const [first, last] = getFirstAndLastContractsInLoss(
    electricityContract,
    gasContract,
  );

  if (!first) return null;

  const lossDate = last?.lossDate ?? (first.lossDate as string);
  const lossEventSummary = isDualFuel
    ? `Dual fuel: electricity and gas Final Statement`
    : `Single fuel: ${first.fuel} Final statement`;

  const closingBalance: string = (() => {
    const { billingSummary } = latestBill.data;

    if (
      !billingSummary ||
      !('latestPeriod' in billingSummary) ||
      typeof billingSummary?.latestPeriod?.data.closingBalance?.pounds !==
        'string'
    ) {
      return '0';
    }

    return billingSummary?.latestPeriod?.data.closingBalance?.pounds;
  })();
  const isDebit = closingBalance.charAt(0) === '-';

  if (closingBalance === '0') {
    return (
      <FinalStatementReadyBalanceZero
        bannerId={bannerId}
        lossDate={lossDate}
        lossEvent={`${lossEventSummary}: Balance in zero`}
      />
    );
  }

  if (isDebit) {
    return (
      <FinalStatementReadyBalanceInDebit
        bannerId={bannerId}
        lossDate={lossDate}
        lossEvent={`${lossEventSummary}: In Debit`}
        balance={closingBalance}
      />
    );
  }

  return (
    <FinalStatementReadyBalanceInCredit
      bannerId={bannerId}
      lossDate={lossDate}
      lossEvent={`${lossEventSummary}: In Credit`}
      balance={closingBalance}
    />
  );
};
