import { Card, Heading1, P, Small } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import {
  HeadingWrapper,
  StyledPageContainer,
  StyledTadoThermostatImage,
  StyledTadoThermostatImageWrapper,
} from '../SmartThermostatOffer.styled';
import { NavigationBreadCrumb } from '../components/NavigationBreadcrumb';
import { PageWithSimpleHeader } from '@/src/components';
import { ROUTE_HOME } from '@/src/constants/routes';
import TadoThermostatImage from '@/src/resources/tado-thermostat.png';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';

const StyledBanner = styled(Card)`
  ${({ theme: { borderWidths } }) => `
    border: ${borderWidths.heavy} solid #157d88;
    border-radius: 4px;`}
  ${({ theme: { colors } }) => `
    background-color: ${colors.primaryMuted}`}
`;

const ServiceUnavailableTitle = styled(P)`
  font-size: 28px;
`;

export const MaintenancePage = () => {
  return (
    <PageWithSimpleHeader
      title="tado Maintenance"
      analytics={{
        name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO.ERROR
          .MAINTENANCE,
      }}
    >
      <StyledPageContainer>
        <NavigationBreadCrumb href={ROUTE_HOME} label="Home" />
        <HeadingWrapper className="checkout__heading-wrapper">
          <Heading1 style={{ color: '#ffffff' }}>Order your tado°</Heading1>
          <StyledTadoThermostatImageWrapper className="checkout__image-wrapper">
            <StyledTadoThermostatImage src={TadoThermostatImage} />
          </StyledTadoThermostatImageWrapper>
        </HeadingWrapper>
        <StyledBanner>
          <ServiceUnavailableTitle>
            This service is unavailable right now
          </ServiceUnavailableTitle>
          <Small>Please try again later.</Small>
        </StyledBanner>
      </StyledPageContainer>
    </PageWithSimpleHeader>
  );
};
