import { useFeature } from '@optimizely/react-sdk';
import { Col, Heading1, Heading2, Margin, Row } from '@ovotech/nebula';
import React from 'react';
import { Redirect } from 'react-router-dom-v5';
import {
  HeadingWrapper,
  StyledTadoThermostatImage,
  StyledTadoThermostatImageWrapper,
  StyledPageContainer,
} from '../SmartThermostatOffer.styled';
import { DetailsCard } from '../components/DetailsCard';
import { NavigationBreadCrumb } from '../components/NavigationBreadcrumb';
import { PayNow } from '../components/PayNow';
import { TadoOrderSummary } from '../components/TadoOrderSummary';
import { useTadoEligible } from '../hooks/use-tado-eligible';
import {
  ErrorSection,
  PageWithNavigation,
  PageWithSimpleHeader,
} from '@/src/components';
import { SMART_THERMOSTATS_STATUS_ERROR } from '@/src/constants/error-section-ids';
import { SMART_THERMOSTATS_MAINTENANCE } from '@/src/constants/feature-flags';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { LoadingPanel } from '@/src/pages/Payments/DirectDebitCalculator/LoadingPanel';
import TadoThermostatImage from '@/src/resources/tado-thermostat.png';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';

export const Checkout = () => {
  const eligibility = useTadoEligible();
  const [isUnderMaintenance] = useFeature(SMART_THERMOSTATS_MAINTENANCE);

  if (eligibility.isError) {
    return (
      <PageWithNavigation title="Smart Thermostats">
        <ErrorSection
          headingComponent={Heading2}
          id={SMART_THERMOSTATS_STATUS_ERROR}
        />
      </PageWithNavigation>
    );
  }

  if (eligibility.isLoading) {
    return (
      <PageWithNavigation title="Smart Thermostats">
        <LoadingPanel />
      </PageWithNavigation>
    );
  }

  if (eligibility.isEligible && !isUnderMaintenance) {
    return (
      <PageWithSimpleHeader
        title="tado Checkout"
        analytics={{
          name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO.VIEWED,
        }}
      >
        <StyledPageContainer>
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO.CLICKED.BACK
            }
          >
            <NavigationBreadCrumb
              href={ROUTE_SMART_THERMOSTATS_OFFER + '/compatibility'}
            />
          </AnalyticsClick>
          <HeadingWrapper className="checkout__heading-wrapper">
            <Heading1 style={{ color: '#ffffff' }}>Order your tado°</Heading1>
            <StyledTadoThermostatImageWrapper className="checkout__image-wrapper">
              <StyledTadoThermostatImage src={TadoThermostatImage} />
            </StyledTadoThermostatImageWrapper>
          </HeadingWrapper>

          <Col isNested as={Row}>
            <Col medium={8}>
              <Margin bottom={8}>
                <DetailsCard />
              </Margin>

              <TadoOrderSummary />
            </Col>

            <Col medium={4}>
              <PayNow />
            </Col>
          </Col>
        </StyledPageContainer>
      </PageWithSimpleHeader>
    );
  }
  return <Redirect to={ROUTE_SMART_THERMOSTATS_OFFER} />;
};
