export function kgToTonne(kg: number): number {
  return kg / 1000;
}

export enum CarbonIntensityThreshold {
  low = 129,
  moderate = 209,
}

export const chunkArr = <T>(
  arrData: any,
  chunkSize: number,
): Array<Array<T>> => {
  return [].concat(
    ...arrData.map((_elem: any, i: number) => {
      return i % chunkSize ? [] : [arrData.slice(i, i + chunkSize)];
    }),
  );
};
