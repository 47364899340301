import { TextLink } from '@ovotech/nebula';
import { PlanFamily } from '@monovo/energy-renewals';
import React from 'react';
import { Terms } from '@/src/pages/MoveIn/pages/Quote/components/TermLinks.styled';
import {
  OVO_ENERGY_TERMS,
  OVO_PRIVACY_POLICY_LINK,
} from '@/src/constants/links';

export function TermsLinks({ tariffName }: { tariffName: string }) {
  const additionalTC = AdditionalTCs(tariffName);
  return (
    <Terms>
      By renewing your plan you agree to our{' '}
      <TextLink
        data-testid="terms-link"
        href={`${OVO_ENERGY_TERMS}/2022`}
        target="_blank"
      >
        terms and conditions
      </TextLink>
      {additionalTC ? ', ' : ' and '}
      <TextLink
        data-testid="privacy-policy-link"
        href={OVO_PRIVACY_POLICY_LINK}
        target="_blank"
      >
        privacy policy
      </TextLink>
      {!!additionalTC && (
        <>
          {' and '}
          {additionalTC}
        </>
      )}
    </Terms>
  );
}

// Taken from renewal, may not be required now, but useful just in case
function AdditionalTCs(tariffName: string) {
  switch (tariffName.replace(/(E7)|(Economy 7)/, '').trim()) {
    case PlanFamily.threeYearFixed:
      return (
        <TextLink
          data-testid="3-year-tariff-terms"
          href={`${OVO_ENERGY_TERMS}/3-year-fixed`}
          target="_blank"
        >
          3 year fixed terms
        </TextLink>
      );
    case PlanFamily.memberSpecial:
      return (
        <TextLink
          data-testid="ret-tariff-renewal-terms-link"
          href={`${OVO_ENERGY_TERMS}/ovo-member-special-terms`}
          target="_blank"
        >
          OVO Member Special’s terms and conditions
        </TextLink>
      );
    case PlanFamily.stayAndSave:
      return (
        <TextLink
          data-testid="ret-tariff-renewal-terms-link"
          href={`${OVO_ENERGY_TERMS}/1-year-fixed-stay-and-save-terms`}
          target="_blank"
        >
          1 Year Fixed Stay & Save’s terms and conditions.
        </TextLink>
      );
    case PlanFamily.drive:
      return (
        <TextLink
          data-testid="drive-renewal-terms-link"
          href={`${OVO_ENERGY_TERMS}/ovo-drive`}
          target="_blank"
        >
          OVO Drive terms
        </TextLink>
      );
    case PlanFamily.twoYearFixAndProtect:
      return (
        <TextLink
          data-testid="fix-and-protect-renewal-terms-link"
          href={`${OVO_ENERGY_TERMS}/2-year-fix-protect`}
          target="_blank"
        >
          2 Year Fix and Protect terms
        </TextLink>
      );
    default:
      return null;
  }
}
