import { TextLink } from '@ovotech/nebula';
import { Sandwich, useAccountContext } from '@ovotech/ui-tools';
import React, { useContext } from 'react';
import {
  StyledDesktopNavUl,
  StyledMobileNavUl,
  StyledNavLink,
} from '../../Header/Header.styled';
import { AccountLinks } from '../Links/AccountLinks/AccountLinks';
import HelpAndSupportLink from '../Links/HelpAndSupportLink/HelpAndSupportLink';
import LogoutLink from '../Links/LogoutLink';
import MovingHomeLink from '../Links/MovingHomeLink';
import NavLink from '../Links/NavLink';
import { useContractsQuery } from '@/src/api/use-contracts-query';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';

import {
  SMB_NAV_BOOKING_CLICKED,
  SMB_NAV_BOOKING_VIEWED,
  MENU,
} from '@/src/constants/analytics';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import {
  ROUTE_BILLING_HISTORY,
  ROUTE_HOME,
  ROUTE_METER_READINGS,
  ROUTE_OFFERS,
  ROUTE_POWER_MOVE,
  ROUTE_PAYMENTS,
  ROUTE_PLAN,
  ROUTE_RENEWAL,
  ROUTE_USAGE,
} from '@/src/constants/routes';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { hasRenewingContracts } from '@/src/pages/Renewal/selectors';
import { haveAllSuppliesBeenActive } from '@/src/utils/supplyPoints';
import { NewNavItemBadge } from '@/src/components/Badges/NewNavItemBadge';
import {
  OptimizelyContext,
  OptimizelyExperiment,
  OptimizelyVariation,
} from '@/src/utils/optimizely';
import { AccountSupplyPointFragmentFragment } from '@/src/api/kapi/__generated__/graphql';

type Props = {
  closeMobileMenu: () => void;
  hasMultipleAccounts: boolean;
  isMobileMenuOpen: boolean;
  path: string;
};

type HeaderLinksProps = {
  closeMobileMenu: () => void;
  hasMultipleAccounts: boolean;
  path: string;
};

type MobileHeaderLinksProps = HeaderLinksProps & {
  smartEligible: boolean;
  hasSmartMeterBooking: boolean | undefined;
  supplies?: readonly AccountSupplyPointFragmentFragment[];
};

const InLifeMobileHeaderLinks = ({
  closeMobileMenu,
  path,
  smartEligible,
  hasSmartMeterBooking,
}: MobileHeaderLinksProps) => {
  const { accountId } = useAccountContext();
  const contractQuery = useContractsQuery(accountId);
  const { optimizely } = useContext(OptimizelyContext);

  const isInRenewal =
    contractQuery.status === 'success' &&
    hasRenewingContracts(contractQuery.data);

  return (
    <StyledMobileNavUl>
      <Sandwich id={sandwichIds.mobileNavigation.home}>
        <li>
          <NavLink
            path={path}
            route={ROUTE_HOME}
            label="Home"
            onClick={closeMobileMenu}
            data-event-name={MENU.USAGE}
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.mobileNavigation.usage}>
        <li>
          <NavLink
            data-testid="usage-link"
            path={path}
            route={ROUTE_USAGE}
            label="Usage"
            onClick={closeMobileMenu}
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.mobileNavigation.getSmartMeter}>
        {/* @ts-ignore react-18 children props */}
        {smartEligible && !hasSmartMeterBooking && (
          <OptimizelyExperiment experiment="smart_book_menu">
            <OptimizelyVariation variation="original"></OptimizelyVariation>
            <OptimizelyVariation variation="booking_in_side_nav">
              <li>
                <AnalyticsWrapper name={SMB_NAV_BOOKING_VIEWED}>
                  <StyledNavLink
                    as={TextLink}
                    href={`${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=menu&utm_medium=MyOVO&utm_campaign=smart-meter-booking`}
                    opensInNewWindow={false}
                    data-testid="smb-side-nav"
                    data-event-name={SMB_NAV_BOOKING_CLICKED}
                  >
                    Get a smart meter
                  </StyledNavLink>
                </AnalyticsWrapper>
              </li>
            </OptimizelyVariation>
          </OptimizelyExperiment>
        )}
      </Sandwich>
      <Sandwich
        id={sandwichIds.mobileNavigation.meterReadings}
        props={{ closeMobileMenu, path }}
      >
        <li>
          <NavLink
            path={path}
            route={`${ROUTE_METER_READINGS}`}
            label="Meter readings"
            onClick={closeMobileMenu}
            data-testid="meter-readings-link"
            data-event-name={MENU.METER_READINGS}
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.mobileNavigation.billing}>
        <li>
          <NavLink
            path={path}
            route={`${ROUTE_BILLING_HISTORY}`}
            label="Billing history"
            onClick={closeMobileMenu}
            data-testid="billing-history-link"
            data-event-name={MENU.BILLING_HISTORY}
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.mobileNavigation.payments}>
        <li>
          <NavLink
            path={path}
            route={ROUTE_PAYMENTS}
            label="Payments"
            onClick={closeMobileMenu}
            data-event-name={MENU.PAYMENTS}
          />
        </li>
      </Sandwich>
      <Sandwich
        id={sandwichIds.mobileNavigation.plan}
        props={{ path, closeMobileMenu, accountId }}
      >
        <li>
          <NavLink
            path={path}
            route={ROUTE_PLAN}
            label="Plan"
            data-testid="plan-link"
            onClick={closeMobileMenu}
          />
        </li>
      </Sandwich>

      <Sandwich id={sandwichIds.mobileNavigation.powerMove}>
        <li>
          <NavLink
            data-event-name={MENU.POWER_MOVE}
            path={path}
            route={ROUTE_POWER_MOVE}
            label="Power Move"
            onClick={() => {
              optimizely?.track('navigate_to_power_move_page_clicked');
            }}
            badge={<NewNavItemBadge />}
          />
        </li>
      </Sandwich>

      <Sandwich id={sandwichIds.mobileNavigation.offers}>
        <li>
          <NavLink
            path={path}
            route={ROUTE_OFFERS}
            label="Offers"
            onClick={closeMobileMenu}
            data-event-name={MENU.OFFERS}
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.mobileNavigation.movingHome}>
        <li>
          <MovingHomeLink path={path} />
        </li>
      </Sandwich>
      {isInRenewal && (
        <Sandwich id={sandwichIds.sideNavigation.renewPlan}>
          <li>
            <NavLink
              path={path}
              route={`${ROUTE_RENEWAL}?utm_source=renew-your-plan&utm_medium=MyOVO`}
              label="Renew your plan"
            />
          </li>
        </Sandwich>
      )}
    </StyledMobileNavUl>
  );
};

const onboardingMobileHeaderLinks = ({
  closeMobileMenu,
  path,
  supplies,
  hasMultipleAccounts,
  smartEligible,
  hasSmartMeterBooking,
}: MobileHeaderLinksProps) => {
  if (supplies && haveAllSuppliesBeenActive(supplies)) {
    return (
      <InLifeMobileHeaderLinks
        closeMobileMenu={closeMobileMenu}
        path={path}
        hasMultipleAccounts={hasMultipleAccounts}
        smartEligible={smartEligible}
        hasSmartMeterBooking={hasSmartMeterBooking}
      />
    );
  }

  return null;
};

export const MobileHeaderLinks = ({
  path,
  supplies,
  smartEligible,
  hasMultipleAccounts,
  hasSmartMeterBooking,
  closeMobileMenu,
}: MobileHeaderLinksProps) => {
  return onboardingMobileHeaderLinks({
    path,
    supplies,
    smartEligible,
    hasMultipleAccounts,
    hasSmartMeterBooking,
    closeMobileMenu,
  });
};

export const MobileDiminishedHeaderLinks = ({
  path,
  hasMultipleAccounts,
  closeMobileMenu,
}: HeaderLinksProps) => (
  <StyledMobileNavUl diminished={true}>
    <Sandwich id={sandwichIds.mobileNavigation.helpAndSupport}>
      <li>
        <HelpAndSupportLink />
      </li>
    </Sandwich>
    <Sandwich id={sandwichIds.mobileNavigation.accountLinks}>
      <li>
        <AccountLinks
          path={path}
          closeMobileMenu={closeMobileMenu}
          hasMultipleAccounts={hasMultipleAccounts}
        />
      </li>
    </Sandwich>
    <Sandwich id={sandwichIds.mobileNavigation.logout}>
      <li>
        <LogoutLink />
      </li>
    </Sandwich>
  </StyledMobileNavUl>
);

const DesktopHeaderLinks = ({
  path,
  hasMultipleAccounts,
  closeMobileMenu,
}: HeaderLinksProps) => (
  <StyledDesktopNavUl>
    <Sandwich id={sandwichIds.headerNavigation.helpAndSupport}>
      <li>
        <HelpAndSupportLink />
      </li>
    </Sandwich>
    <Sandwich id={sandwichIds.headerNavigation.accountLinks}>
      <li>
        <AccountLinks
          path={path}
          closeMobileMenu={closeMobileMenu}
          hasMultipleAccounts={hasMultipleAccounts}
          hasLightBackground={true}
        />
      </li>
    </Sandwich>
    <Sandwich id={sandwichIds.headerNavigation.logout}>
      <li>
        <LogoutLink />
      </li>
    </Sandwich>
  </StyledDesktopNavUl>
);

export const HeaderLinks = ({ ...rest }: Props) => (
  <nav data-testid="desktop-header-nav">
    <DesktopHeaderLinks {...rest} />
  </nav>
);
