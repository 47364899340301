import React from 'react';
import { TextLink } from '@ovotech/nebula';
import { useAnalytics, useAccountContext } from '@ovotech/ui-tools';
import { useSmartMeterMarketingQuery } from '@/src/api';
import { useLocalStorage } from '@/src/utils/useLocalStorage';
import { StyledNavLink } from '@/src/components/Header/Header.styled';
import { StyledLinkContent } from '@/src/components/Navigation/Links/NavLink.styled';
import { NewNavItemBadge } from '../../Badges/NewNavItemBadge';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import { CLICKED_SMB_IN_NAV } from '@/src/constants/analytics';

interface Props {
  closeMobileMenu?: () => void;
}

const BookSmartMeterLink = ({ closeMobileMenu }: Props) => {
  const { dispatch } = useAnalytics();
  const { accountId } = useAccountContext();
  const { data } = useSmartMeterMarketingQuery(accountId);
  const isEligibleForSmartMeter =
    data?.eligibility?.isEligible &&
    data?.booking?.outstandingBooking === false;
  const [hasNewBadge, updateHasNewBadge] = useLocalStorage(
    'new_smb_link_badge_dismissed',
    false,
  );

  return (
    isEligibleForSmartMeter && (
      <li>
        <StyledNavLink
          as={TextLink}
          href={SMART_BOOKING_URL}
          data-testid="book-smart-meter-link"
          onClick={() => {
            dispatch({ name: CLICKED_SMB_IN_NAV, type: 'click' });
            closeMobileMenu && closeMobileMenu();
            !hasNewBadge && updateHasNewBadge(true);
          }}
        >
          <StyledLinkContent>
            {!hasNewBadge && <NewNavItemBadge />}
            Book a free smart meter
          </StyledLinkContent>
        </StyledNavLink>
      </li>
    )
  );
};

export default BookSmartMeterLink;
