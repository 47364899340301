import { Margin } from '@ovotech/nebula';
import React from 'react';
import {
  EnergyInsightsTermsLink,
  GridContainer,
  PageHeaderWithHubLink,
} from '../../components';
import { EnergyInsightsPageName } from '../../types';
import CarbonIntensity from './Components/CarbonIntensity';
import { useGetCarbonIntensity } from '@/src/api/use-get-carbon-intensity';
import { PageWithNavigation } from '@/src/components';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';

export const CarbonIntensityPage = () => {
  return (
    <PageWithNavigation
      title="Carbon Intensity"
      analytics={{ name: EnergyInsightsPageName.CarbonIntensity }}
    >
      <PageHeaderWithHubLink
        mainTitle="Power up at greener times"
        subTitle="Find out when the energy grid is producing the most planet-friendly power."
      />
      <GridContainer>
        <Content />
        <Margin bottom={4} />
        <EnergyInsightsTermsLink />
      </GridContainer>
    </PageWithNavigation>
  );
};

function Content() {
  const { data, error } = useGetCarbonIntensity();

  if (error) {
    return null;
  }

  if (!data) {
    return <SiteLoading />;
  }

  return <CarbonIntensity {...data} />;
}
