import { ErrorNotification, Strong, P } from '@ovotech/nebula';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useAnalytics } from '@ovotech/ui-tools';
import { TESCO_EVENTS } from '@/src/constants/analytics';

type Props = {
  error?: unknown;
};

const ERROR_MESSAGE = "We're sorry, something went wrong.";
const AccountHolderErrorMessage = () => (
  <>
    <P>
      <Strong>We couldn't link your account</Strong>
    </P>
    <P>Try using the primary OVO account to link your Tesco Clubcard.</P>
  </>
);
("We're sorry, only the primary account holder can access this offer.");

export const TescoError = ({ error }: Props) => {
  const { dispatch } = useAnalytics();

  const isNotPrimaryAccountHolderError =
    error instanceof AxiosError &&
    error?.response?.data?.error?.code === 'NotPrimaryAccountHolder';

  useEffect(() => {
    if (isNotPrimaryAccountHolderError) {
      dispatch({
        name: TESCO_EVENTS.VIEWED_ACC_HOLDER_ERROR,
        type: 'view',
      });
    }
    // dispatch is not stable across renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotPrimaryAccountHolderError]);

  return (
    <ErrorNotification id={'tesco-reward-error'}>
      {isNotPrimaryAccountHolderError ? (
        <AccountHolderErrorMessage />
      ) : (
        ERROR_MESSAGE
      )}
    </ErrorNotification>
  );
};
