import { Badge } from '@ovotech/nebula';
import React from 'react';
import { StyledNewBadge } from './Badges.styled';

export const NewNavItemBadge = () => {
  return (
    <StyledNewBadge data-testid="StyledNewBadge">
      <Badge variant="newNavItem">New</Badge>
    </StyledNewBadge>
  );
};
