import {
  Em,
  Heading2,
  Li,
  Margin,
  P,
  Strong,
  TextLink,
  Ul,
} from '@ovotech/nebula';
import { AvailablePlan } from '@monovo/energy-renewals';
import React from 'react';

import { Link } from 'react-router-dom-v5';
import PlanInfoCard from './PlanCard/PlanInfoCard';
import { ROUTE_SETUP_DD } from '@/src/constants/routes';

interface Props {
  fixedPlans: Array<AvailablePlan>;
  variablePlans: Array<AvailablePlan>;
  hasDirectDebitSetUp: boolean;
  canRefix: boolean;
}

const PlansList = ({
  fixedPlans,
  variablePlans,
  hasDirectDebitSetUp,
  canRefix,
}: Props) => {
  return (
    <>
      {fixedPlans.length > 0 && (
        <>
          <Margin bottom={6}>
            <Heading2 as="h3">Our fixed-rate plans at OVO</Heading2>
            <P>Fixed-rate plans available for all members.</P>
          </Margin>

          {fixedPlans.map((plan, index) => (
            <Margin key={index} bottom={8}>
              <PlanInfoCard plan={plan} />
            </Margin>
          ))}
        </>
      )}
      {variablePlans.length > 0 ? (
        <>
          <Margin bottom={6}>
            <Heading2 as="h3">Our variable plan at OVO</Heading2>

            {canRefix ? (
              <P>
                If you take no action, you’ll move to Simpler Energy – our
                variable plan, where prices can go up and down based on the
                price of wholesale energy, but protected by the price cap.
              </P>
            ) : (
              <P>
                You're moving to Simpler Energy – our variable plan, where
                prices can go up and down based on the price of wholesale
                energy, but protected by the price cap.
              </P>
            )}
            <P>There are 2 ways you can pay:</P>
            <Ul>
              <Li>
                Pay by Direct Debit –{' '}
                <Strong>this is the cheapest way to pay</Strong>, because it
                costs us less to manage your account.
              </Li>
              <Li>
                Pay after your bill arrives – it costs slightly more to pay this
                way. We call it paying On Demand.
              </Li>
            </Ul>
            {!hasDirectDebitSetUp ? (
              <>
                <P>
                  <Strong>
                    At the moment, you pay for your energy On Demand – which
                    means you’ll pay the higher rates.
                  </Strong>
                </P>
                <P>
                  To get the lower rates,{' '}
                  <TextLink as={Link} to={ROUTE_SETUP_DD}>
                    you can set up a Direct Debit
                  </TextLink>{' '}
                  quickly and easily: do it via your online account or by giving
                  us a call. If you do set one up and then cancel it while on
                  Simpler Energy, you’ll start paying the higher On Demand rates
                  instead – but we’ll let you know before this happens.
                </P>
              </>
            ) : (
              <>
                <P>
                  <Strong>
                    At the moment, you pay for your energy by Direct Debit –
                    which means you’ll pay lower rates.
                  </Strong>
                </P>
                <P>
                  If your Direct Debit payments fail, or if they’re cancelled by
                  you or your bank, you’ll start paying the higher On Demand
                  rates instead. We’ll let you know before this happens, to give
                  you the chance to set up the Direct Debit again and avoid
                  paying more.
                </P>
              </>
            )}
            <P>
              <Em>
                If you qualified for our <Strong>Warm Home Discount</Strong>{' '}
                before the 1 August 2019, you’ll still get discounted prices –
                and it doesn’t matter how you pay.
              </Em>
            </P>
          </Margin>
          {variablePlans.map((plan, index) => (
            <Margin
              key={index}
              bottom={variablePlans.length - 1 === index ? undefined : 8}
            >
              <PlanInfoCard plan={plan} />
            </Margin>
          ))}
        </>
      ) : null}
    </>
  );
};

export default PlansList;
