import { CTALink, Heading1, P } from '@ovotech/nebula';
import { AnalyticsPage } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom-v5';
import testIds from '../../testIds';
import { ConfirmationWrapper } from '../GreenerEnergySignup.styled';
import { useGreenerEnergySignup } from '../hooks/useGreenerEnergySignup';

import { GreenerEnergyAnimation } from './GreenerEnergyAnimation/GreenerEnergyAnimation';
import { GREENER_ENERGY_SIGNUP_SUCCESS } from '@/src/constants/analytics-subjects';
import { PRODUCT_DISPLAY_NAMES, PRODUCT_IDS } from '@/src/constants/products';
import { ROUTE_GREENER_ENERGY } from '@/src/constants/routes';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';

const signUpTestIds = testIds.signUp;

export const SignupSuccess = () => {
  const history = useHistory();
  const promoCode = getQueryParamValue('promoCode');

  const { hasStandaloneGreenerEnergyAddOn, activation } =
    useGreenerEnergySignup();

  useEffect(() => {
    // Redirect to Greener Energy
    if (hasStandaloneGreenerEnergyAddOn && !activation.success) {
      history.push(ROUTE_GREENER_ENERGY);
    }
  }, [hasStandaloneGreenerEnergyAddOn, activation, history]);

  return (
    <AnalyticsPage
      name={GREENER_ENERGY_SIGNUP_SUCCESS}
      properties={{ promoCode }}
    >
      <ConfirmationWrapper
        data-testid={signUpTestIds.confirmation.wrapper}
        role="status"
      >
        <Heading1>Nice one!</Heading1>
        <P>
          You’ve upgraded to {PRODUCT_DISPLAY_NAMES[PRODUCT_IDS.greenerEnergy]}
        </P>
        <CTALink
          href={ROUTE_GREENER_ENERGY}
          variant="primary"
          data-testid={signUpTestIds.confirmation.cta}
        >
          Start your journey now
        </CTALink>
        <GreenerEnergyAnimation />
      </ConfirmationWrapper>
    </AnalyticsPage>
  );
};
