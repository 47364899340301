import { useQuery, UseQueryResult } from 'react-query';
import { BASS_API_URL } from '@/src/constants/endpoints';
import { BeyondAccountStatusGraphqlResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';
import { useAccountContext } from '@ovotech/ui-tools';

const query = `
  query BeyondAccountStatus($accountId: String!) {
      beyondAccountStatus(accountId: $accountId) {
          status
      }
  }
`;

const graphqlBody = ({ accountId }: { accountId: string }) => {
  return {
    query,
    operationName: 'BeyondAccountStatus',
    variables: { accountId },
  };
};

export const useBeyondAccountStatusQuery =
  (): UseQueryResult<BeyondAccountStatusGraphqlResponse> => {
    const { accountId } = useAccountContext();

    return useQuery(
      [`beyond-account-status-graphql-${accountId}`],
      () =>
        apiService.graphql<BeyondAccountStatusGraphqlResponse>({
          url: BASS_API_URL,
          body: graphqlBody({ accountId: accountId ?? '' }),
        }),
      {
        enabled: !!accountId,
      },
    );
  };
