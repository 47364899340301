import React from 'react';
import { Redirect } from 'react-router-dom-v5';
import { DirectDebitCalculator } from '../DirectDebitCalculator';
import { ROUTE_ADJUST_DD_AMOUNT_CALCULATION } from '@/src/constants/routes';
import { useSupplyPointsQuery } from '@/src/api/kapi/use-supply-points-query';
import { isRelatedMPANCustomer } from '@/src/utils/supplyPoints';

export const AdjustDirectDebit = () => {
  const supplyPointsQueryResult = useSupplyPointsQuery();

  if (
    isRelatedMPANCustomer(
      supplyPointsQueryResult.data?.account?.accountSupplyPoints ?? [],
    )
  ) {
    return <Redirect to={ROUTE_ADJUST_DD_AMOUNT_CALCULATION} />;
  }
  return <DirectDebitCalculator />;
};
