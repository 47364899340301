import styled from 'styled-components';

export const StyledGreenerEnergyAnimation = styled.div`
  position: absolute;

  ${({ theme: { space } }) => `
    left: ${space[2]};
    right: ${space[2]};
    bottom: ${space[2]};
  `}
`;

export const StyledBlades = styled.path<{ scale: number }>`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  transform-origin: center 44%;
  ${props => `
    animation: rotate ${2 * props.scale * props.scale}s linear infinite;
  `};
`;
