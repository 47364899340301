import { useQuery } from 'react-query';

import { fetchContentfulData } from '@/src/services/contentful/fetchContentfulData';
import { graphql } from './__generated__';

export const nextBestActionsQuery = graphql(/* contentful-codegen-graphql */ `
  query nextBestActionsQuery($nbaContainerId: String!, $preview: Boolean) {
    buildNextBestAction(id: $nbaContainerId, preview: $preview) {
      actionCardsCollection {
        ...ShowableCardsFragment
      }
    }
  }
`);

type QueryCacheKey = `contentfulNextBestActions-${string}`;

export const useContentfulNextBestActionsQuery = (nbaContainerId: string) =>
  useQuery(
    `contentfulNextBestActions-${nbaContainerId}` as QueryCacheKey,
    () =>
      fetchContentfulData(nextBestActionsQuery, {
        nbaContainerId: nbaContainerId,
      }),
    {
      retry: 3,
      cacheTime: 500000,
    },
  );
