import { Strong } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledPrice = styled.div`
  ${({ theme: { fontSizes, space } }) => `
    margin-bottom: ${space[2]};
    margin-right: ${space[1]};
    font-size: ${fontSizes[2]};
    display: inline-block;
  `}
`;

StyledPrice.displayName = 'StyledPrice';

export const StyledStrongPrice = styled(Strong)`
  ${({ theme: { fontSizes, fontWeights } }) => `
    font-size: ${fontSizes[4]};
    font-weight: ${fontWeights.bold};
  `}
`;

StyledStrongPrice.displayName = 'StyledStrongPrice';

// ts-unused-exports:disable-next-line
export const StyledPromotion = styled(Strong)`
  display: inline-block;

  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
  `}
`;

StyledPromotion.displayName = 'StyledPromotion';

export const StyledTextLinkContainer = styled.div`
  margin-top: 1.4rem;
  text-align: center;
`;

StyledTextLinkContainer.displayName = 'StyledTextLinkContainer';
