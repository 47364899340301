import { Heading2, P, SecondaryCTALink } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';

export const SmartMeterBookingRecerts: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const { accountId } = useAccountContext();

  return (
    <Banner
      icon="smartMeter"
      dismiss={dismiss}
      data-testid="smb-booking-recerts"
    >
      <Heading2>Your meter may need replacing</Heading2>
      <P>
        Meters get old and less accurate over time. Yours could be measuring
        your energy use incorrectly. We’ll replace it with a free smart meter.
        So you’ll be able to see how much energy your home uses in real time
        from your phone.
      </P>
      <SecondaryCTALink
        href={`${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=banner&utm_medium=MyOVO&utm_campaign=smart-meter-booking`}
        data-testid="sm-booking-link"
        onClick={onClick}
      >
        Book your free appointment
      </SecondaryCTALink>
    </Banner>
  );
};
