import { IconName, Icon, Heading3, P } from '@ovotech/nebula';
import React, { ComponentProps, forwardRef, ReactNode } from 'react';
import {
  StyledIconHeader,
  StyledIconHeaderText,
  StyledIconHeaderIcon,
} from './IconHeader.styled';

type Props = ComponentProps<typeof StyledIconHeader> & {
  heading: ReactNode;
  subtitle?: ReactNode;
  icon: IconName | string;
};

const IconHeader = forwardRef<HTMLDivElement, Props>(
  ({ heading, subtitle, icon, ...rest }, ref) => {
    return (
      <StyledIconHeader {...rest} ref={ref}>
        <StyledIconHeaderIcon>
          <Icon name={icon} />
        </StyledIconHeaderIcon>
        <StyledIconHeaderText>
          <Heading3 as="h2">{heading}</Heading3>
          {subtitle ? (
            <P data-testid="icon-header-subtitle">{subtitle}</P>
          ) : null}
        </StyledIconHeaderText>
      </StyledIconHeader>
    );
  },
);

export { IconHeader };
