import { P, WarningNotification } from '@ovotech/nebula';
import React from 'react';
import { RETENTION_BANNER_WARNING } from '@/src/constants/feature-flags';
import { useFeature } from '@/src/utils/optimizely';

export function RenewalBannerWarning() {
  const [showBannerWarning] = useFeature(RETENTION_BANNER_WARNING);
  return showBannerWarning ? (
    <WarningNotification id={RETENTION_BANNER_WARNING} title={null}>
      <P>
        We're currently experiencing issues when renewing some customer
        contracts. If you experience an issue please try again later.
      </P>
    </WarningNotification>
  ) : null;
}
