import { AnalyticsData } from '@ovotech/ui-tools';
import React, { useState, useMemo } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom-v5';
import { Bank } from './pages/Bank/Bank';
import { CustomerDetails } from './pages/CustomerDetails/CustomerDetails';
import { GetQuote } from './pages/GetQuote/GetQuote';
import { Quote } from './pages/Quote/Quote';
import { QuoteConfirmation } from './pages/QuoteConfirmation/QuoteConfirmation';
import { Success } from './pages/Success/Success';
import { MoveInDetails, MoveInJourneyProps } from './types/moveIn';
import { ROUTE_MOVE_IN_SUBPAGES } from '@/src/constants/routes';
import { MeterDetails } from '@/src/pages/MoveIn/pages/MeterDetails/MeterDetails';

type PageOrder = ReadonlyArray<string>;

const pageOrder: PageOrder = [
  ROUTE_MOVE_IN_SUBPAGES.GetQuote,
  ROUTE_MOVE_IN_SUBPAGES.Meters,
  ROUTE_MOVE_IN_SUBPAGES.Quote,
  ROUTE_MOVE_IN_SUBPAGES.Plan,
  ROUTE_MOVE_IN_SUBPAGES.Details,
  ROUTE_MOVE_IN_SUBPAGES.Bank,
  ROUTE_MOVE_IN_SUBPAGES.Success,
];

// TODO - RTN-1599: Add tests for flow
// Designed such that, if need stripping out, can be done with relative ease.
export function MoveIn() {
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const [moveInDetails, setMoveInDetails] = useState<MoveInDetails>({
    movedOut: window.location.hash === 'movedOut', // Only want to run this once on page load and store
    needMeterDetails: false,
    useSupplyAddress: true,
  });

  const history = useHistory();
  const { updateMoveIn, continueMoveIn, skipMoveInStep } = useMemo(() => {
    const updateMoveIn = (moveInDetail: Partial<MoveInDetails>) => {
      setMoveInDetails(state => ({ ...state, ...moveInDetail }));
    };

    const continueMoveIn = () => {
      const currentPageIndex = pageOrder.findIndex(
        url => url === history.location.pathname,
      );

      const nextPageUrl = pageOrder[1 + currentPageIndex];
      history.push(nextPageUrl);
    };

    const skipMoveInStep = () => {
      const currentPageIndex = pageOrder.findIndex(
        url => url === history.location.pathname,
      );

      const nextPageUrl = pageOrder[1 + currentPageIndex];
      history.replace(nextPageUrl);
    };

    return { updateMoveIn, continueMoveIn, skipMoveInStep };
  }, [history]);

  const journeyProps: MoveInJourneyProps = {
    moveInDetails,
    updateMoveIn,
    continueMoveIn,
    skipMoveInStep,
    progress: {
      numberOfPages: pageOrder.length,
      currentPage: pageOrder.findIndex(
        path => history.location.pathname === path,
      ),
    },
  };

  // Force redirect on first load
  // useEffect does not take place until after render causing problems
  if (isFirstLoad) {
    setIsFirstLoad(false);
    history.replace(pageOrder[0]);
    return <></>;
  }

  return (
    <AnalyticsData
      data={{
        movedOut: moveInDetails.movedOut,
      }}
    >
      <Switch>
        <Route path={ROUTE_MOVE_IN_SUBPAGES.Bank}>
          <Bank {...journeyProps} />
        </Route>

        <Route path={ROUTE_MOVE_IN_SUBPAGES.Details}>
          <CustomerDetails {...journeyProps} />
        </Route>

        <Route path={ROUTE_MOVE_IN_SUBPAGES.GetQuote}>
          <GetQuote {...journeyProps} />
        </Route>

        <Route path={ROUTE_MOVE_IN_SUBPAGES.Quote}>
          <Quote {...journeyProps} />
        </Route>

        <Route path={ROUTE_MOVE_IN_SUBPAGES.Meters}>
          <MeterDetails {...journeyProps} />
        </Route>

        <Route path={ROUTE_MOVE_IN_SUBPAGES.Plan}>
          <QuoteConfirmation {...journeyProps} />
        </Route>

        <Route path={ROUTE_MOVE_IN_SUBPAGES.Success}>
          <Success {...journeyProps} />
        </Route>
      </Switch>
    </AnalyticsData>
  );
}
