import { CarbonIntensityThreshold } from './conversions';

export const yAxisTickFormatter = (value: any) => {
  if (value < CarbonIntensityThreshold.low) {
    return 'Low';
  }
  if (
    value >= CarbonIntensityThreshold.low &&
    value < CarbonIntensityThreshold.moderate
  ) {
    return 'Med';
  }
  if (value >= CarbonIntensityThreshold.moderate) {
    return 'High';
  }
  return value;
};

export const getColor = (value: number) => {
  if (value < CarbonIntensityThreshold.low) {
    return '#0A9928';
  }
  if (
    value >= CarbonIntensityThreshold.low &&
    value < CarbonIntensityThreshold.moderate
  ) {
    return '#E7A234';
  }
  if (value >= CarbonIntensityThreshold.moderate) {
    return '#AE0A30';
  }
};
