import styled from 'styled-components';

import { mqSmallUp } from '@/src/utils/mediaQuery';

export const StyledSection = styled.section`
  ${({ theme: { space } }) => `
    padding-top: ${space[6]};
    padding-bottom: ${space[6]};

    ${mqSmallUp(`
      padding-top: ${space[8]};
      padding-bottom: ${space[8]};
    `)}
  `};
`;
