import { Plan } from '@ovotech/energy-cx';
import React from 'react';
import { Route } from 'react-router-dom-v5';
import {
  SwitchWithNotFound,
  PageWithNavigationAndSectionPadding,
} from '@/src/components';
import { ROUTE_PLAN } from '@/src/constants/routes';

const PlanPageRouter = () => (
  <SwitchWithNotFound>
    <Route
      exact
      path={ROUTE_PLAN}
      component={() => (
        <PageWithNavigationAndSectionPadding title="Plan">
          <Plan />
        </PageWithNavigationAndSectionPadding>
      )}
    />
  </SwitchWithNotFound>
);

export default PlanPageRouter;
