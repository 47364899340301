import styled from 'styled-components';
import { mqLargeUp } from '@/src/utils/mediaQuery';

enum CartGridArea {
  Cost = 'cost',
  Header = 'header',
  Body = 'body',
}
const CGA = CartGridArea;

export const CostHeader = styled.div`
  width: 145px;

  ${mqLargeUp(`
    width: 205px;
  `)}
`;

export const Cart = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas:
    '${CGA.Header}'
    '${CGA.Body}'
    '${CGA.Cost}';
  ${mqLargeUp(`
    grid-template-areas: 
      '${CGA.Header} ${CGA.Header}'
      '${CGA.Body} ${CGA.Cost}';
    grid-template-columns: auto 325px;
  `)}
`;

export const HeaderGrid = styled.div`
  grid-area: ${CGA.Header};
`;

export const BodyGrid = styled.div`
  grid-area: ${CGA.Body};
`;

export const CostGrid = styled.div`
  grid-area: ${CGA.Cost};
`;
