import { Badge, CTALink, Heading3, P } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { ReactElement } from 'react';
import {
  TileWithClippedImageStyled,
  TileContent,
  TileButton,
  TileImage,
  TileNewBadge,
} from './TileWithClippedImage.styled';
import testIds from '../../testIds';

interface Props {
  title: string;
  description: string | ReactElement;
  imgUrl: string;
  linkText: string | Element;
  linkIcon?: string;
  newTab?: boolean;
  linkUrl: string;
  tracking?: any;
  onClick?: () => void;
  newBadge?: boolean;
}

export const TileWithClippedImage = ({
  title,
  description,
  imgUrl,
  linkText,
  linkIcon,
  newTab = false,
  linkUrl,
  tracking,
  onClick,
  newBadge = false,
  ...props
}: Props) => (
  <TileWithClippedImageStyled {...props}>
    <TileImage src={imgUrl} />
    <TileContent>
      <Heading3>{title}</Heading3>
      <P>{description}</P>
      {newBadge && (
        <TileNewBadge>
          <Badge variant="newNavItem">New</Badge>
        </TileNewBadge>
      )}
      <AnalyticsClick name={tracking} properties={{ badgeShown: newBadge }}>
        <TileButton
          data-testid={testIds.features.button}
          as={CTALink}
          variant="secondary"
          href={linkUrl}
          rightIcon={linkIcon}
          opensInNewWindow={newTab}
          onClick={() => {
            onClick && onClick();
          }}
        >
          {linkText}
        </TileButton>
      </AnalyticsClick>
    </TileContent>
  </TileWithClippedImageStyled>
);
