import { Heading2, P } from '@ovotech/nebula';
import styled from 'styled-components';
import {
  mqMediumDown,
  mqMediumUp,
  mqSmallDown,
  mqSmallToMedium,
  mqSmallUp,
} from '@/src/utils/mediaQuery';

export const StyledContainer = styled.div`
  ${({ theme: { colors, space } }) => `
    position: relative;
    background-color: ${colors.canvas};
    box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
    margin-top: ${space[4]};
    overflow: hidden;

    ${mqMediumUp(`
      padding-right: 30%;
    `)}
    
    &:first-of-type {
      margin-top: ${space[6]};

      ${mqSmallUp(`
      margin-top: ${space[8]};
    `)}
    }
    
  `}
`;

export const StyledContainerBody = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[6]};
  `}

  ${mqMediumDown(`
  width: 100%;
  padding-top: 0;
`)}
`;

export const StyledDefaultImageContainer = styled.div`
  width: 100%;
  height: 300px;

  ${mqMediumUp(`
      position: absolute;
      margin: 0;
      top: 0%;
      right:0%;
      width: 40%;
      height: 110%;
    `)}

  ${mqSmallToMedium(`
    height: 300px;
    width: 100%;
    position: relative;
  `)}

  ${mqSmallDown(`
    height: 300px;
    width: 100%;
    position: relative;
  `)}
`;

export const StyledHeading2 = styled(Heading2)`
  color: #20993e;
  width: 80%;

  ${mqMediumDown(`
  width: 100%;
`)}
`;

export const StyledParagraph = styled(P)`
  color: #1c1c1c;
  max-width: 80%;

  ${mqMediumDown(`
  width: 100%;
`)}
`;

export const ClippedImg = styled.img`
  display: block;
  height: 90%;
  width: 100%;
  object-fit: cover;
  object-position: 0% 25%;
  clip-path: polygon(17% 0%, 100% 0%, 100% 72%, 32% 92%);

  ${mqMediumDown(`
    height:100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    object-position: 0% 25%;
  `)}
`;

ClippedImg.displayName = 'ClippedImg';
