import { Heading3, Heading4, Ul, Li } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqMediumUp, mqSmallUp } from '@/src/utils/mediaQuery';

export const StyledHeading3 = styled(Heading3)`
  ${({ theme: { colors, space } }) => `
    color: ${colors.primary};
    margin-bottom: ${space[2]};
  `}
`;

export const Roundel = styled.div`
  width: 110px;
  height: 110px;
  position: absolute;
  top: 4%;
  left: 8%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.primaryAlt};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  ${mqMediumUp(`
  top: 6%;
  left: 45%;
  width: 122px;
  height: 122px;
`)}
`;

export const StyledH4 = styled(Heading4)`
  color: ${({ theme: { colors } }) => colors.canvas};
  text-align: center;
  magin: auto;
  padding: 0 5px 0 5px;
`;

export const StyledHomePlanContainer = styled.div`
  ${({ theme: { colors, space } }) => `
    position: relative;
    background-color: ${colors.canvas};
    box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
    margin-top: ${space[4]};
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    padding-top: ${space[6]};

    ${mqMediumUp(`
      padding-right: 30%;
      padding-top: 0;
    `)}
    
    &:first-of-type {
      margin-top: ${space[6]};

      ${mqSmallUp(`
      margin-top: ${space[8]};
    `)}
    }
    
  `}
`;

export const StyledUl = styled(Ul)`
  list-style-type: none;
  margin: 0 0 0 12px;
`;

export const StyledLi = styled(Li)`
  position: relative;
  padding-left: 25px;
  margin-bottom: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 17px;
    height: 17px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg clip-path='url(%23clip0_9745_4623)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.51853 14.6446L16.4687 3.59493L15.0423 2.18136L5.50569 11.8046L1.90953 8.24083L0.495966 9.66724L5.51853 14.6446Z' fill='%230D8426'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9745_4623'%3E%3Crect width='16' height='16' fill='white' transform='translate(0.454346 0.457336) rotate(-0.259173)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const StyledImg = styled.div<{ imgUrl: string }>`
  width: 97%;
  height: 97%;
  background-image: ${({ imgUrl }) => `
  url(${imgUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: absolute;
  right: 0px;
  bottom: 0px;

  ${mqMediumUp(`
  background-position: bottom;
  background-size: contain;
`)}
`;

export const StyledHomeRecoverCardLockupWrap = styled.div`
  width: 100%;
  height: 250px;
  position: relative;

  ${mqMediumUp(`
      position: absolute;
      margin: 0;
      top: 0;
      right: 0%;
      width: 40%;
      height: 100%;
    `)}
`;
