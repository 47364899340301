import { Card, Margin, P } from '@ovotech/nebula';
import { AnalyticsPage, useAccountContext } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';
import { Features } from '../Features/Features';
import { SectionWrapper } from '../GreenerElectricitySignup/GreenerElectricitySignup.styled';
import GreenerElectricityHomeHeader from '../components/GreenerElectricityHomeHeader/GreenerElectricityHomeHeader';
import { FootnoteProvider, FootnoteYield, Section } from '@/src/components';

import { GREENER_ELECTRICITY } from '@/src/constants/analytics-subjects';
import { ROUTE_GREENER_ELECTRICITY_SIGNUP } from '@/src/constants/routes';

import { getShowSmartMeterBookingBannerStart } from '@/src/redux/ducks/smartMeterBooking/smartMeterBooking';

import {
  isGreenerElectricityBundleCustomer,
  isGreenerElectricityCustomer,
} from '@/src/utils/products';
import { useProductsQuery } from '@/src/api';

const Loading = () => {
  return (
    <Section>
      <Card>
        <P>Loading...</P>
      </Card>
    </Section>
  );
};

export const GreenerElectricityContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { accountId } = useAccountContext();
  const productsQuery = useProductsQuery(accountId);

  const activeBoltons =
    productsQuery.data?.boltons.filter(b => b.status === 'Active') || [];

  const isGreenerElectricityUser =
    isGreenerElectricityCustomer(activeBoltons) ||
    isGreenerElectricityBundleCustomer(activeBoltons);

  useEffect(() => {
    if (productsQuery.data && !isGreenerElectricityUser) {
      history.push(ROUTE_GREENER_ELECTRICITY_SIGNUP);
    }
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsQuery, isGreenerElectricityUser]);

  useEffect(() => {
    dispatch(getShowSmartMeterBookingBannerStart());
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (productsQuery.isLoading) {
    return (
      <>
        <SectionWrapper>
          <Loading />
        </SectionWrapper>
      </>
    );
  }

  return (
    <AnalyticsPage name={GREENER_ELECTRICITY}>
      <GreenerElectricityHomeHeader />
      <FootnoteProvider>
        <Features />
        <Margin top={6}>
          <FootnoteYield heading=" " />
        </Margin>
      </FootnoteProvider>
    </AnalyticsPage>
  );
};
