import styled from 'styled-components';

import {
  mqLargeUp,
  mqSmallDown,
  mqSmallUp,
  mqMediumDown,
  mqMediumUp,
} from '@/src/utils/mediaQuery';

export const StyledOfferThermostatsHeaderBgWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  position: absolute;
`;

export const StyledOfferThermostatsHeaderBg = styled.div`
  ${({ theme: { colors, zIndexes } }) => `
  background-color: ${colors.brand.primary.dark};
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: 0% 100%;
  transform: rotate(-5deg);
  width: 125%;
  height: 650px;
  margin-left: -60vw;
  overflow: hidden;
  z-index: ${zIndexes.ovoBeyondBackground};

  ${mqLargeUp(`
    width: 110%;
    margin-left: -50%;
  `)}

  &:before {
    content: '';
    font-size: 18px;
    position: absolute;
    bottom: 0;
    left: -4%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6em 12em 0;
    border-color: transparent transparent ${colors.primary} transparent;
    background: ${colors.brand.primary.lighter};
    transform: rotate(33deg);
  }

  &:after {
    content: '';
    font-size: 50px;
    position: absolute;
    top: -33%;
    right: 0;
    width: 10em;
    height: 10em;
    margin-right: -5em;
    border-radius: 50%;
    background: ${colors.brand.primary.darker};
  }

  @media screen and (max-width: 1300px) {
     &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
`};
`;

export const WhiteText = styled.p`
  color: white;
`;

export const WhiteHeading = styled(WhiteText)`
  color: white;
  font-size: 48px;
  line-height: 48px;
  ${mqMediumDown(`
    font-size: 32px;
    line-height: 32px;
  `)};
  ${({ theme: { space } }) => `
    margin-top: ${space[4]};
  `}
`;

export const SmallText = styled.p`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[1]};
    ${mqSmallDown(`
      font-size: ${fontSizes[0]};
    `)};
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mqMediumUp(`
    flex-direction: row-reverse;
  `)};
`;

export const FlexWrapperRow = styled.div`
  display: flex;
  flex-direction: column;

  text-align: left;
  justify-content: flex-start;

  margin-right: 8px;

  ${mqSmallDown(`
    text-align: center;
    margin-bottom: 8px;
  `)};
`;

export const NoMarginText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;

export const StyledHeroTextWrapper = styled.div`
  flex-basis: auto;
`;

export const StyledHeroImageWrapper = styled.div`
  position: relative;
  width: 40%;
  width: 300px;
  flex-shrink: 0;

  ${mqMediumDown(`
    width: 18rem;
  `)};
`;

export const StyledHeroImage = styled.img`
  z-index: 1;
  width: 300px;
  height: 300px;
  ${mqMediumDown(`
    width: 180px;
    height: 180px;
  `)};
`;

export const StyledWhiteSegment = styled.div`
  background-color: white;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  text-align: center;

  padding: 8px 8px 8px 12px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  ${mqSmallUp(`
    flex-direction: row;
    text-align: left;
  `)};
`;

export const SegmentButton = styled.div`
  width: 160px;
  ${mqSmallDown(`
    width: 100%;
  `)};
`;

export const ButtonRightArrow = styled.i`
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
`;
