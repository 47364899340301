import React, { ReactElement } from 'react';

export type ResponsiveChartRenderProps = {
  isFlipped: boolean;
  keyAxisProps: Record<string, unknown>;
  valueAxisProps: Record<string, unknown>;
};

type Props = {
  flipBreakpoint: string;
  children: (renderProps: ResponsiveChartRenderProps) => ReactElement;
};

class ResponsiveChartWrapper extends React.Component<
  Props,
  ResponsiveChartRenderProps
> {
  // eslint-disable-next-line react/sort-comp
  resizeListener: null | EventListener = null;

  constructor(props: Props) {
    super(props);
    this.state = this.calculateResponsiveChartProps(props);
  }

  componentDidMount() {
    this.resizeListener = debounce(
      () => this.updateResponsiveChartProps(this.props),
      100,
    );
    window.addEventListener('resize', this.resizeListener);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.updateResponsiveChartProps(nextProps);
  }

  componentWillUnmount() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  calculateResponsiveChartProps = (props: Props) => {
    const { flipBreakpoint } = props;

    const isFlipped = window.matchMedia(flipBreakpoint).matches;

    const keyAxisProps = {
      orientation: isFlipped ? 'left' : 'bottom',
    };

    const valueAxisProps = {
      orientation: isFlipped ? 'top' : 'left',
    };

    return {
      isFlipped,
      keyAxisProps,
      valueAxisProps,
    };
  };

  updateResponsiveChartProps(props: Props) {
    this.setState(this.calculateResponsiveChartProps(props));
  }

  render() {
    const { children } = this.props;

    return children(this.state);
  }
}

type AnyFunction = (...args: any[]) => any;

function debounce<Fn extends AnyFunction>(fn: Fn, waitInMs: number) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), waitInMs);
  };
}

export default ResponsiveChartWrapper;
