import { Card, Margin, Heading4 } from '@ovotech/nebula';
import React from 'react';
import { StyledDiv, StyledHeading4 } from './EVOnboarding.styled';
import { ReactComponent as DirectDebitDate } from '@/src/resources/direct-debit-date-icon.svg';

export const CollectingSmartChargerData = () => {
  return (
    <Card data-testid="connecting-to-kaluza">
      <Margin bottom={2}>
        <StyledDiv>
          <DirectDebitDate />
        </StyledDiv>
      </Margin>
      <StyledHeading4 as={Heading4}>
        Please wait while we collect the info from your smart charger...
        <br />
        Hold tight, this can take up to 10 seconds
      </StyledHeading4>
    </Card>
  );
};
