import {
  Margin,
  Card,
  ErrorNotification,
  P,
  SecondaryCTAButton,
  Heading3,
  Heading4,
  Sup,
  Small,
} from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom-v5';
import { roundTo2DP } from '@/src/utils/number';
import { EVInsights } from '../EVInsights/EVInsights';
import {
  StyledEVSavingsPanel,
  StyledContent,
  StyledMiniCardWrapper,
  StyledMiniCard,
  StyledMiniCardImage,
  StyledTextUnderImage,
  StyledEqualsImage,
  StyledContainer,
  StyledSmall,
  StyledCardHeader,
  StyledSup,
} from './EVSavings.styled';
import CO2Tonnes from '@/src/resources/co2Tonnes.svg';
import Equals from '@/src/resources/equalsSign.svg';
import Trees from '@/src/resources/trees.svg';
import { useSavingsAndInsightDataFromKaluza } from '@/src/api';
import { FootnoteProvider } from '@/src/components';
import { CHARGE_ANYTIME_EMAIL_ADDRESS, ROUTE_EV } from '@/src/constants/routes';
import { State } from '@/src/types/State';
import { useAccountContext } from '@ovotech/ui-tools';

export const EVSavings = () => {
  const history = useHistory();
  const { connection } = useSelector(({ evInlife }: State) => evInlife);
  const { accountId } = useAccountContext();
  const savingsAndInsightDataResponse = useSavingsAndInsightDataFromKaluza(
    connection,
    accountId,
  );

  const currentMonth =
    savingsAndInsightDataResponse.status === 'data' &&
    savingsAndInsightDataResponse.data.savings.currentMonth;

  const showEvInsights = Boolean(
    savingsAndInsightDataResponse.status === 'data' &&
      savingsAndInsightDataResponse.data.insights,
  );

  useEffect(() => {
    if (!currentMonth && savingsAndInsightDataResponse.status !== 'error') {
      history.push(ROUTE_EV);
    }
  }, [currentMonth, history, savingsAndInsightDataResponse.status]);

  if (savingsAndInsightDataResponse.status === 'error') {
    return (
      <Margin top={8} data-testid="ev-saving-section">
        <StyledEVSavingsPanel as={Card}>
          <Margin bottom={6}>
            <ErrorNotification
              id="SavingsError"
              title="Sorry, there’s a bit of a problem"
            >
              <P>
                Please try again. If this still doesn't work, please get in
                touch with us at {CHARGE_ANYTIME_EMAIL_ADDRESS}
              </P>
            </ErrorNotification>
          </Margin>
          <SecondaryCTAButton
            data-testid="retry-savings"
            as={Link}
            to={ROUTE_EV}
          >
            Try again
          </SecondaryCTAButton>
        </StyledEVSavingsPanel>
      </Margin>
    );
  }

  return (
    <>
      {currentMonth && (
        <Margin top={8} data-testid="ev-saving-section">
          <FootnoteProvider markerType="number">
            <StyledEVSavingsPanel as={Card}>
              <StyledCardHeader>
                <Heading3>You’ve cut this much carbon</Heading3>
              </StyledCardHeader>
              <Margin all={8}>
                <Heading4>
                  This is how much carbon you’ve cut driving an EV compared to a
                  standard car this month:
                </Heading4>
              </Margin>
              <StyledContent>
                <div>
                  <StyledMiniCardWrapper>
                    <StyledMiniCard>
                      <StyledContainer>
                        <div>
                          <StyledMiniCardImage
                            src={CO2Tonnes}
                            alt="co2 icon"
                            center
                          />
                          <StyledTextUnderImage>
                            <Heading4>
                              {roundTo2DP(currentMonth.carbonInKg)} kg of carbon
                              <Sup>1</Sup>
                            </Heading4>
                          </StyledTextUnderImage>
                        </div>
                        <div>
                          <StyledEqualsImage
                            src={Equals}
                            alt="equals icon"
                            center
                          />
                        </div>
                        <div>
                          <StyledMiniCardImage
                            src={Trees}
                            alt="trees icon"
                            center
                          />
                          <StyledTextUnderImage>
                            <Heading4>
                              planting {roundTo2DP(currentMonth.numberOfTrees)}{' '}
                              trees <Sup>2</Sup>
                            </Heading4>
                          </StyledTextUnderImage>
                        </div>
                      </StyledContainer>
                    </StyledMiniCard>
                  </StyledMiniCardWrapper>
                </div>
              </StyledContent>
              <Margin horizontal={8}>
                <Margin top={2}>
                  <Small>
                    <StyledSup>1</StyledSup>
                    To calculate this, we estimate your mileage based on the
                    energy used to charge your EV and its stated efficiency. We
                    then compare this to the CO2 produced by a Ford Fiesta (the
                    UK's most popular petrol car) covering the same distance.
                  </Small>
                </Margin>
                <Margin top={3}>
                  <Small>
                    <StyledSup>2</StyledSup>
                    One glorious tree absorbs 60kg of carbon emissions over a
                    period of 10 years.
                  </Small>
                </Margin>
                <Margin top={3} bottom={8}>
                  <StyledSmall as={P}>
                    Please know, these are estimates only.
                  </StyledSmall>
                </Margin>
              </Margin>
            </StyledEVSavingsPanel>
          </FootnoteProvider>
        </Margin>
      )}
      {showEvInsights && (
        <EVInsights
          insightData={
            savingsAndInsightDataResponse.status === 'data'
              ? savingsAndInsightDataResponse.data.insights
              : undefined
          }
        />
      )}
    </>
  );
};
