import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { energyInsightsTestIDs } from '../../testIDs';
import { Link } from '@/src/components';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';

export const EnergyInsightsTermsLink = () => (
  <TextLink
    data-testid={energyInsightsTestIDs.common.termsLink}
    to={ROUTE_ENERGY_INSIGHTS.TERMS}
    as={Link}
  >
    View the Energy Insights terms and conditions
  </TextLink>
);
