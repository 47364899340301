import {
  Heading2,
  Li,
  P,
  Radio,
  RadioField,
  TextLink,
  Ul,
} from '@ovotech/nebula';
import React from 'react';

// TODO Don't modify current page, go to another
export function PaymentMethod({
  paymentMethod,
  setPaymentMethod,
}: {
  paymentMethod: string;
  setPaymentMethod: (arg: string) => void;
}) {
  return (
    <RadioField
      name="paymentMethod"
      label="How do you pay for your energy?"
      defaultValue={paymentMethod}
      onValueChange={value => setPaymentMethod(value!)}
      data-testid="payment-method-question"
    >
      <Radio
        label="Pay monthly"
        id="paym"
        value="paym"
        hint="Got a standard meter and pay a monthly bill? This one is for you."
        data-testid="payment-method-paym"
      />
      <Radio
        label="Pay as you go"
        id="payg"
        value="payg"
        hint="Got a prepayment meter? This is the way to go."
        data-testid="payment-method-payg"
      />
      <Radio
        label="Pay on demand without a Direct Debit"
        id="paymOD"
        value="paymOD"
        hint={
          <>
            Want a variable plan and pay by cash or cheque? This is for you.
            <br />
            You’ll see more plans if you choose the Monthly Direct Debit option.
          </>
        }
        data-testid="payment-method-payod"
      />
    </RadioField>
  );
}

export function Payg() {
  return (
    <div>
      <Heading2>Give us a call to join us on a pay as you go plan</Heading2>
      <P>
        If you want to pay for your energy without a Direct Debit (by cash or
        cheque) we’ve got a variable plan which might suit you. You’ll need to
        give us a call to join.
      </P>
      <P>
        But don’t forget - a Direct Debit plan can give you lots of benefits,
        including:
      </P>
      <Ul>
        <Li>
          Keeping on top of your energy spending which we can adjust if you’re
          paying too much
        </Li>
        <Li>Fixed energy rates with our 12 or 24 month plans </Li>
        <Li>A worry-free way to pay that saves you time and hassle </Li>
      </Ul>
    </div>
  );
}

export function PayOD() {
  return (
    <div>
      <Heading2>Give us a call to join us on a non-Direct Debit plan</Heading2>
      <P>
        Sorry, we are unable to complete your pay as you go quote online. Please
        give us a quick call on{' '}
        <TextLink href="tel:0330 305063">0330 303 5063</TextLink> and we'll be
        happy to help you finish your quote over the phone.
      </P>
    </div>
  );
}
