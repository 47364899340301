import { Margin, SkeletonText } from '@ovotech/nebula';
import React from 'react';
import { testIds } from './constants';

export const LoadingCard = () => (
  <>
    <Margin top={10} data-testid={testIds.mhhsLoadingCard}>
      <SkeletonText lines={3} />
    </Margin>
    <Margin top={10}>
      <SkeletonText lines={3} />
    </Margin>
  </>
);
