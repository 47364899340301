import { Card, Heading4, Icon, P, Strong } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import GetTesco from '@/src/resources/tescoPeacefulPerson.png';
import { ROUTE_EDIT_PROFILE_MARKETING } from '@/src/constants/routes';
import { mqMediumDown } from '@/src/utils/mediaQuery';
import { Link } from '@/src/components';

const OptIn = styled(Link)`
  span {
    text-decoration: none;
  }
  color: ${({ theme }) => theme.colors.brand.black};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.brand.black};
  }
`;

const StyledOfferCard = styled(Card)`
  display: flex;
  padding: 0;
  justify-content: space-between;
`;

const StyledOfferCardDetails = styled(Card)`
  width: 250px;
  heigh: 200px;

  ${mqMediumDown(`
    width: fit-content;
  `)}
`;

const StyledOfferCardImageWrapper = styled.div`
  width: 200px;
  height: fit-content;
`;

export const GetOffersCard = () => {
  return (
    <StyledOfferCard>
      <StyledOfferCardDetails>
        <Heading4>Get offers to your inbox</Heading4>
        <P>
          Hear about our exclusive partner offers with Tesco Clubcard, and more.
        </P>
        <OptIn to={ROUTE_EDIT_PROFILE_MARKETING}>
          <Strong>
            Opt in <Icon name="chevron-right" size={10} />
          </Strong>
        </OptIn>
      </StyledOfferCardDetails>
      <StyledOfferCardImageWrapper>
        <img alt="Get Tesco" src={GetTesco} />
      </StyledOfferCardImageWrapper>
    </StyledOfferCard>
  );
};
