import {
  Card,
  Heading3,
  Margin,
  P,
  SummaryItem,
  SummaryList,
  TextLink,
  VisuallyHidden,
} from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { useDirectDebitQuery } from '@/src/api';

import { ContactUsLink, ErrorSection } from '@/src/components';
import { SummaryCard } from '@/src/components/SummaryCard';
import { PAYMENTS } from '@/src/constants/analytics';
import { BANK_DETAILS_ERROR } from '@/src/constants/error-section-ids';
import { DD_GUARANTEE } from '@/src/constants/links';

export const BankDetails = () => {
  const { accountId } = useAccountContext();
  const directDebit = useDirectDebitQuery(accountId);

  if (directDebit.isError)
    return (
      <Card>
        <ErrorSection headingComponent={Heading3} id={BANK_DETAILS_ERROR} />
      </Card>
    );

  if (directDebit.isLoading || !directDebit.data) return null;

  const hasDirectDebit = directDebit.data?.amount !== undefined;

  if (!hasDirectDebit)
    return (
      <SummaryCard heading="Your bank details" icon="wallet">
        <div>
          <Margin top={4} bottom={4}>
            <P>
              Please send us your bank details so we can set up your Direct
              Debit.
            </P>
          </Margin>
        </div>
        <ContactUsLink>Contact us</ContactUsLink>
      </SummaryCard>
    );

  const { bankName, obfuscatedAccountNo } = directDebit.data;

  return (
    <SummaryCard heading="Your bank details" icon="wallet">
      <SummaryList>
        {!!obfuscatedAccountNo && (
          <SummaryItem
            label={
              <>
                Bank account number{' '}
                <VisuallyHidden>(last two digits)</VisuallyHidden>
              </>
            }
          >
            {obfuscatedAccountNo.padStart(8, '\u2219')}
          </SummaryItem>
        )}
        {!!bankName && <SummaryItem label="Bank">{bankName}</SummaryItem>}
      </SummaryList>
      <P>
        <TextLink
          href={DD_GUARANTEE}
          data-testid="dd-guarantee"
          opensInNewWindow
          data-event-name={PAYMENTS.DD_GUARANTEE}
        >
          The Direct Debit Guarantee
        </TextLink>
      </P>
    </SummaryCard>
  );
};
