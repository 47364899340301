import { Icon, P } from '@ovotech/nebula';
import React, { PropsWithChildren } from 'react';
import styled, { css, useTheme } from 'styled-components';

type ThemeSpace = 1 | 2 | 3 | 4 | 5;

const Container = styled.div<{ marginBottom: ThemeSpace }>(
  ({ theme, marginBottom }) => css`
    display: flex;
    align-items: flex-start;
    margin-bottom: ${theme.space[marginBottom]};
  `,
);

const IconWrapper = styled.div`
  padding-right: ${props => props.theme.space[2]};
  flex-shrink: 0;
`;

const Text = styled.p`
  margin: 0;
`;

type Props = PropsWithChildren<{
  iconName: string;
  iconThemeSize: number;
  iconColor: string;
  marginBottom?: ThemeSpace;
}>;

export const BulletWithIcon: React.FC<Props> = ({
  children,
  iconName,
  iconThemeSize,
  iconColor,
  marginBottom = 2,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Container marginBottom={marginBottom} {...rest}>
      <IconWrapper>
        <Icon
          size={theme.fontSizes[iconThemeSize]}
          name={iconName}
          color={iconColor}
        />
      </IconWrapper>
      <P as={Text}>{children}</P>
    </Container>
  );
};
