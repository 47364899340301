import styled from 'styled-components';
import { styledOutline } from '../styled-utils';
import { ReactComponent as CrossIcon } from '@/src/resources/menu-cross.svg';
import { ReactComponent as HamburgerIcon } from '@/src/resources/menu-hamburger.svg';
import { mqPrint, mqMobileNav, mqDesktopNav } from '@/src/utils/mediaQuery';

export const StyledMobileNav = styled.nav<{ isMobileMenuOpen?: boolean }>`
  display: none;
  ${({ isMobileMenuOpen }) => `
    ${mqMobileNav(`
      display: ${isMobileMenuOpen ? 'block' : 'none'};
    `)}
  `}
`;

export const StyledNavHeader = styled.header<{ isMobileMenuOpen?: boolean }>`
  ${({ theme: { colors }, isMobileMenuOpen }) => `
   ${mqMobileNav(`
      background-color: ${isMobileMenuOpen ? colors.canvas : 'transparent'};
      color: ${colors.canvasMuted}
      `)}

    ${mqPrint(`
      display: none;
    `)};

    `}
`;

export const StyledHeaderContents = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding-top: ${theme.space[4]};
    padding-bottom: ${theme.space[4]};

    ${mqDesktopNav(`
      flex-direction: row;
      align-items: center;
    `)}
  `}
`;

const StyledNavUl = styled.ul<{ diminished?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ theme: { space, colors }, diminished }) => `
    background-color: ${diminished === true ? colors.canvasMuted : 'none'};

    ${mqDesktopNav(`
      > li:last-child {
        margin-left: auto;
      }

      flex-direction: row;
      margin-left: ${space[6]};
      align-items: center;
    `)}

    ${mqMobileNav(
      diminished !== true
        ? `
      > li:not(:first-child) {
          border-top: 1px solid ${colors.shadeMidLight};
        };

        &:first-child {
          margin-top: ${space[4]};
        }
      }
    `
        : '',
    )}
  `}
`;

export const StyledMobileNavUl = styled(StyledNavUl)`
  ${({ theme: { colors, space } }) => `
    padding: 0 ${space[5]};
    background-color: ${colors.canvas}
  `}
`;

export const StyledDesktopNavUl = styled(StyledNavUl)`
  ${({ theme: { space } }) => `
    ${mqDesktopNav(`
      > li, > li:last-child {
        margin-left: ${space[6]};
      }
      margin-left: 0;
    `)}

    ${mqMobileNav(`
      display: none;
    `)}
  `}
`;

export const StyledSimpleHeaderContents = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;


    padding-top: ${theme.space[4]};
    padding-bottom: ${theme.space[4]};
  `}
`;

export const StyledSimpleHeaderLink = styled.div`
  ${({ theme: { colors, space } }) => `
    ${mqMobileNav(`
      > a {
        color: ${colors.headerPrimaryContrast};
        padding-bottom: ${space[3]};
      }
    `)}
  `}
`;

export const StyledLogoAndMenuButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledMenuToggleButton = styled.button<{
  isMobileMenuOpen?: boolean;
}>`
  background-color: transparent;
  border: none;
  cursor: pointer;

  ${mqDesktopNav(`
    display: none;
  `)}

  ${({
    theme: { colors, fontSizes, fonts, fontWeights },
    isMobileMenuOpen,
  }) => `
    color: ${
      isMobileMenuOpen ? colors.headerPrimary : colors.headerPrimaryContrast
    }};
    font-size: ${fontSizes[1]};
    font-family: ${fonts.display};
    font-weight: ${fontWeights.semiBold};

    &:focus {
      ${styledOutline(colors.focus)};
    }
  `}
`;

export const StyledHamburgerIcon = styled(HamburgerIcon)`
  width: 12px;
  vertical-align: baseline;

  ${({ theme: { colors } }) => `
    fill: ${colors.headerPrimaryContrast}
  `}
`;

export const StyledCrossIcon = styled(CrossIcon)`
  width: 12px;
  vertical-align: baseline;
  margin-bottom: -1px;

  ${({ theme: { colors } }) => `
    fill: ${colors.headerPrimary}
  `}
`;

export const StyledNavLink = styled.a`
  ${({ theme: { fontSizes, fonts, colors, fontWeights } }) => `
    position: relative;
    text-decoration: none;
    color: ${colors.brand.midnight};
    font-size: ${fontSizes[2]};
    font-family: ${fonts.display};
    font-weight: ${fontWeights.normal};

    span {
      text-decoration: none;
    }


    ${mqMobileNav(`
      display: flex;
      height: 51px;
      align-items: center;
      justify-content: center;
    `)}

    ${mqDesktopNav(`
      padding: 10px;
      display: block;
    `)}
  `}
`;

export const StyledNavHelpSupportLink = styled.a`
  ${({ theme: { fontSizes, fonts, colors, fontWeights } }) => `
    text-decoration: none;
    font-size: ${fontSizes[2]};
    font-family: ${fonts.display};
    font-weight: ${fontWeights.normal};

    span {
      text-decoration: none;
    }

    &:focus {
      color: ${colors.headerPrimaryContrast};
    }

    ${mqMobileNav(`
      display:flex;
      height: 51px;
      align-items: center;
      justify-content: center;
      color: ${colors.headerPrimary};
    `)}

    ${mqDesktopNav(`
      padding: 10px;
      display: block;
      color: ${colors.headerPrimaryContrast};
      &:hover {
        color: ${colors.headerPrimaryContrast};
      }
    `)}
  `}
`;

export const StyledNavLinkLabel = styled.span<{
  hasLightBackground: boolean | undefined;
}>`
  position: relative;
  ${({ theme: { colors }, hasLightBackground }) => `
      color: ${
        hasLightBackground
          ? colors.headerPrimaryContrast
          : colors.brand.midnight
      };
  `}

  ${StyledNavLink}.active & {
    ${({ theme: { colors } }) => `
      &::after {
        content: '';
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: -5px;
        height: 2px;
        background-color: ${
          // TODO. OVO Specific.
          colors.primary
        };
      }
    `}
  }
`;
