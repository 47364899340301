import styled from 'styled-components';

import Link from '../Link/Link';

export const StyledNavigationLink = styled(Link)`
  ${({ theme: { colors, space } }) => `
    padding: ${space[2]} ${space[4]};

    &[aria-disabled="true"] {
      color: ${colors.shadeMidLight};
    }
  `}
`;
