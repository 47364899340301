import styled from 'styled-components';
import {
  UsageBreakdownBar,
  UsageBreakdownBarFigure,
  UsageBreakdownBarWrapper,
} from '../../../../BillBreakdown/Components/UsageBreakdownBar/UsageBreakdownBar.styled';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledUsageBreakdownBarWrapper = styled(UsageBreakdownBarWrapper)`
  display: flex;
  flex-direction: column;
`;

export const StyledUsageBreakdownBar = styled(UsageBreakdownBar)`
  position: static;

  display: flex;
  align-items: center;

  p {
    padding-left: 8px;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const StyledUsageBreakdownBarFigure = styled(UsageBreakdownBarFigure)`
  position: static;
  transform: none;
  padding-left: 0;
  width: 100%;

  strong {
    ${mqSmallDown(`
      font-size: 14px;
    `)}
  }
`;
export const StyledBarText = styled.p`
  width: 175px;
  position: absolute;
`;
