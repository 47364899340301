import { Heading4, Li, P, Strong, TextLink, Ul } from '@ovotech/nebula';
import React from 'react';
import {
  CHECK_A_TRADE_URL,
  INSTALLATION_GUIDE,
  KANTAN_BOOKING_FLOW_URL,
} from '../constants';
import {
  ContentWrapper,
  FindAnInstallerCard,
  FindAnInstallerContent,
  ImageWrapper,
  InfoWrapper,
  TextWrapper,
} from './GetReadyForYourTado.styled';
import image from '@/src/resources/get-ready-for-your-tado.png';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { useFeature } from '@/src/utils/optimizely';

export const GetReadyForYourTado = () => {
  const [useKantanBookingFlow] = useFeature('use_kantan_booking_flow');

  return (
    <ContentWrapper>
      <InfoWrapper>
        <TextWrapper>
          <Heading4>Get ready for your tado°</Heading4>
          <P>
            95% of all tado° customers install their devices by themselves. If
            you want to be ready to install your tado°, you can:
          </P>
          <Ul style={{ marginTop: 30 }}>
            <Li>Locate your internet router</Li>
            <Li>Download the tado° app</Li>
            <Li>
              Check out{' '}
              <AnalyticsClick
                name={
                  SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.CLICKED
                    .INSTALLATION_GUIDE
                }
              >
                <TextLink
                  href={INSTALLATION_GUIDE}
                  style={{ color: '#000000' }}
                >
                  our installation guide
                </TextLink>
              </AnalyticsClick>{' '}
              – 5 simple steps to heat smart
            </Li>
          </Ul>
        </TextWrapper>
        <FindAnInstallerCard data-testid="find-an-installer">
          <FindAnInstallerContent backgroundColor="#ecf0fd">
            <Strong style={{ color: '#004ca1' }}>
              Prefer a professional to do it for you?
            </Strong>
          </FindAnInstallerContent>
          <FindAnInstallerContent backgroundColor="#ffffff">
            {' '}
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.CLICKED
                  .FIND_AN_INSTALLER
              }
            >
              <TextLink
                href={
                  useKantanBookingFlow
                    ? KANTAN_BOOKING_FLOW_URL
                    : CHECK_A_TRADE_URL
                }
                opensInNewWindow
                data-testid="find-an-installer-link"
              >
                Find an installer
              </TextLink>
            </AnalyticsClick>
          </FindAnInstallerContent>
        </FindAnInstallerCard>
      </InfoWrapper>
      <ImageWrapper backgroundImage={image}></ImageWrapper>
    </ContentWrapper>
  );
};
