import React from 'react';
import {
  FormProgressTracker,
  FormProgressTrackerEmptyRectangle,
  FormProgressTrackerFilledRectangle,
} from './FormProgressTracker.styled';

type FormProgressProps = {
  questionLength: number;
  step: number;
};

const FormProgress = ({ questionLength, step }: FormProgressProps) => {
  const questionArray = new Array(questionLength);
  questionArray.fill('questionFiller', 0, questionLength);

  return (
    <FormProgressTracker>
      {questionArray.map((_, i) => {
        const ProgressComponent =
          step >= i + 1
            ? FormProgressTrackerFilledRectangle
            : FormProgressTrackerEmptyRectangle;

        return <ProgressComponent key={`progressTracker ${i}`} />;
      })}
    </FormProgressTracker>
  );
};

export default FormProgress;
