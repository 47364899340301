import { CTALink, Heading2, P } from '@ovotech/nebula';
import { AnalyticsRender, useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { ActionCentreBanner, Banner } from '../Banner';
import { HOME_PLAN_FIXED_PLAN_ADDON_Q1_OFFER } from '@/src/constants/analytics-subjects';
import { HOMEPLAN_Q1_PROMO_OFFER } from '@/src/constants/feature-flags';
import { HOME_PLAN_FIXED_ADDON_Q1_OFFER } from '@/src/constants/routes';
import { HomePlan } from '@/src/components/ActionCentre/Banners/HomePlan/HomePlan';
import { useDecision } from '@/src/utils/optimizely';
import { isAfter, isBefore } from 'date-fns';
import {
  OHS_Q1_OFFER_END_DATE,
  OHS_Q1_OFFER_START_DATE,
} from '@/src/pages/Offers/constants';

export const HomePlanFixedAddonQ1Offer: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const { accountId } = useAccountContext();

  const homeplanUrl =
    process.env.NX_HOMEPLAN_URL || `https://homeplan.ovoenergy.com`;

  const redirectUrl = `${homeplanUrl}?accountId=${accountId}&${HOME_PLAN_FIXED_ADDON_Q1_OFFER}`;

  const currentDate = new Date();
  const isWithinQ1PromoOfferPeriod =
    isAfter(currentDate, OHS_Q1_OFFER_START_DATE) &&
    isBefore(currentDate, OHS_Q1_OFFER_END_DATE);

  const [{ enabled: isHomeplanQ1Offer }] = useDecision(HOMEPLAN_Q1_PROMO_OFFER);

  if (isHomeplanQ1Offer && isWithinQ1PromoOfferPeriod) {
    return (
      <AnalyticsRender name={HOME_PLAN_FIXED_PLAN_ADDON_Q1_OFFER}>
        <Banner icon="boilerGreen" dismiss={dismiss}>
          <Heading2 data-testid="banner-title">
            6 months half price boiler cover
          </Heading2>
          <P data-testid="banner-body">
            Plus £100 energy credit. Terms and cancellation fees apply. Half
            price offer ends 21/03/24.
          </P>
          <CTALink href={redirectUrl} onClick={onClick}>
            See plans
          </CTALink>
        </Banner>
      </AnalyticsRender>
    );
  }

  return <HomePlan dismiss={dismiss} onClick={onClick} />;
};
