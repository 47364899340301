import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSmartThermostatsInfoStart } from '@/src/redux/ducks/smartThermostats/smart-thermostats-info';
import { MonthYear, State } from '@/src/types/State';
import { buildDateKey } from '@/src/redux/ducks/smartThermostats/get-data';

export function getThermostatData(
  startDate: MonthYear,
  endDate: MonthYear,
  granularity: 'month' | 'day',
) {
  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, errorResponse } = useSelector(
    ({ smartThermostats }: State) => smartThermostats,
  );
  const dateKey = buildDateKey(startDate, endDate);
  const dataSeries =
    data[granularity] && data[granularity][dateKey]
      ? data[granularity][dateKey]
      : [];

  const shouldFetchData = !data?.[granularity]?.[dateKey];

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (shouldFetchData && !errorResponse) {
      dispatch(getSmartThermostatsInfoStart(startDate, endDate, granularity));
    }
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate.year, startDate.month, shouldFetchData]);

  return { data: dataSeries, errorResponse };
}
