// MSStream detects if on IE so probably not needed
declare global {
  interface Window {
    MSStream: unknown;
  }
}

export enum MobileOS {
  ANDROID = 'android',
  IOS = 'iOS',
}

export const getDeviceOS = (): MobileOS | null => {
  const userAgent = navigator?.userAgent || navigator?.vendor;

  if (/android/i.test(userAgent)) {
    return MobileOS.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window?.MSStream) {
    return MobileOS.IOS;
  }

  return null;
};
