import { isSMETS2MeterType, isSmartMeterType } from '@/src/utils/meters';
import { Action, GetBootstrapSuccess } from '@/src/types/Action';
import { BootstrapAccountSupply } from '@/src/types/Response';
import { App, AppAccount, SelectedAccountSupply } from '@/src/types/State';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { concat } from 'rxjs/observable/concat';
import { getSmartMeterBookingStart } from '@/src/redux/ducks/smartMeterBooking/smartMeterBooking';

export const appStartEpic = (action$: ActionsObservable<Action>) =>
  action$.ofType('GET_BOOTSTRAP_SUCCESS').switchMap(() => {
    return concat(Observable.of(getSmartMeterBookingStart()));
  });

export const defaultAppState: App = {
  accounts: [],
  isOnboarding: false,
  gasSupply: {
    isOnboarding: false,
    supplyPointInfo: { isSmart: true, isNonComms: false, address: [] },
  },
  elecSupply: {
    isOnboarding: false,
    supplyPointInfo: { isSmart: true, isNonComms: false, address: [] },
  },
};

const findAccount =
  (id: null | string) =>
  ({ accountId }: { accountId: string }) =>
    accountId === id;

function supplyToSelectedSupply(
  src: undefined | BootstrapAccountSupply,
): SelectedAccountSupply {
  if (typeof src === 'undefined') {
    return null;
  }

  if (src.supplyPointInfo === null) {
    return { supplyPointInfo: null, isOnboarding: src.isOnboarding };
  }

  return {
    isOnboarding: src.isOnboarding,
    supplyPointInfo: {
      isSmart: isSmartMeterType(src.supplyPointInfo.meterType),
      isNonComms: false,
      hasSmets2Meter: isSMETS2MeterType(src.supplyPointInfo.meterType),
      meterNotFound: src.supplyPointInfo.meterNotFound,
      address: src.supplyPointInfo.address,
    },
  };
}

const DEFAULT_SUPPLY = {
  isOnboarding: false,
  supplyPointInfo: { isSmart: true, isNonComms: false, address: [] },
};

const app = (state: App = defaultAppState, action: Action): App => {
  switch (action.type) {
    case 'GET_BOOTSTRAP_SUCCESS': {
      return parseAccounts(action);
    }
    case 'SELECT_ACCOUNT': {
      const { accountId } = action;
      const { accounts } = state;
      const { isOnboarding, gasSupply, elecSupply } = accounts.find(
        findAccount(accountId),
      )!; // user should never be able to select account not in list
      return {
        ...state,
        isOnboarding,
        elecSupply,
        gasSupply,
      };
    }
    default: {
      return state;
    }
  }
};

function parseAccounts(action: GetBootstrapSuccess) {
  const {
    data: { accounts, selectedAccountId },
  } = action;

  const parsedAccounts = accounts.map(
    ({ accountId, supplies, isPayg }): AppAccount => {
      const gSupply = supplyToSelectedSupply(
        supplies.find(({ fuel }) => fuel === 'gas'),
      );
      const eSupply = supplyToSelectedSupply(
        supplies.find(({ fuel }) => fuel === 'electricity'),
      );

      const isAnySupplyOnboarding = () =>
        supplies.some(
          supply =>
            supply.isOnboarding ||
            !supply.supplyPointInfo ||
            supply.supplyPointInfo?.meterNotFound,
        );

      return {
        accountId,
        isPayg,
        isOnboarding: isAnySupplyOnboarding(),
        gasSupply: gSupply,
        elecSupply: eSupply,
      };
    },
  );

  const { gasSupply, elecSupply, isOnboarding } = parsedAccounts.find(
    findAccount(selectedAccountId),
  ) || {
    gasSupply: DEFAULT_SUPPLY,
    elecSupply: DEFAULT_SUPPLY,
    isOnboarding: false,
  };

  return {
    accounts: parsedAccounts,
    isOnboarding, //Forces check in UI to see if in-life experience can be shown
    gasSupply,
    elecSupply,
  };
}

export default app;
