import {
  disconnectKaluzaSmartChargerDataEpic,
  getKaluzaSmartChargerMonthlyDataEpic,
  sendKaluzaTokenDataEpic,
  sendKaluzaAuthTokenDataEpic,
  connectionStatusToKaluzaSmartChargerDataEpic,
  getVehicleDataFromKaluzaSmartChargerDataEpic,
  getSavingsAndInsightDataFromKaluzaSmartChargerDataEpic,
  getAnytimeSavingsDataFromKaluzaSmartChargerDataEpic,
} from './ducks/evs/evInlife';
import {
  getSmartThermostatsDataEpic,
  getSmartThermostatsSavingsEpic,
  getSmartThermostatsTadoLinkEpic,
  checkGeneralEligibilityEpic,
  checkProfessionalInstallEligibilityEpic,
  checkPayMonthlyEligibilityEpic,
  getSmartThermostatsTadoInfoEpic,
  getSmartThermostatsPricingEpic,
  getSmartThermostatsTadoStatusEpic,
  addAccountToWaitlistEpic,
  checkAccountWaitlistStatusEpic,
  registerSmartThermostatsOrderEpic,
  getSmartThermostatsOrderInfoEpic,
} from './ducks/smartThermostats/smart-thermostats-info';
import { getAccountInfoEpic } from './ducks/account-info';
import { apiErrorEpic, resetApiErrorEpic } from './ducks/apiError/apiError';
import {
  activateProductEpic,
  activateProductSuccessEpic,
  cancelProductEpic,
  getProductCatalogueEpic,
  getProductsEpic,
} from './ducks/products/products';
import { getRecommendedDirectDebitEpic } from './ducks/recommendedDirectDebit/recommendedDirectDebit';
import {
  getShowSmartMeterBookingBannerEpic,
  getSmartMeterBookingEpic,
} from './ducks/smartMeterBooking/smartMeterBooking';
import { fetchTariffCostsEpic } from './ducks/tariffCosts/tariffCosts';
import { getDateRangeMonthlyUsageEpic } from './ducks/usage/monthly-usage';
import {
  activateAnytimeProductEpic,
  cancelAnytimeProductEpic,
} from '@/src/redux/ducks/evs/evAnytime';

const epics = [
  getAccountInfoEpic,
  getDateRangeMonthlyUsageEpic,
  apiErrorEpic,
  resetApiErrorEpic,
  getProductsEpic,
  getProductCatalogueEpic,
  activateProductEpic,
  activateProductSuccessEpic,
  cancelProductEpic,
  getRecommendedDirectDebitEpic,
  getShowSmartMeterBookingBannerEpic,
  getSmartMeterBookingEpic,
  fetchTariffCostsEpic,
  getSmartThermostatsDataEpic,
  getSmartThermostatsTadoLinkEpic,
  getSmartThermostatsSavingsEpic,
  checkGeneralEligibilityEpic,
  checkProfessionalInstallEligibilityEpic,
  checkPayMonthlyEligibilityEpic,
  addAccountToWaitlistEpic,
  checkAccountWaitlistStatusEpic,
  registerSmartThermostatsOrderEpic,
  getSmartThermostatsTadoInfoEpic,
  getSmartThermostatsPricingEpic,
  getSmartThermostatsTadoStatusEpic,
  disconnectKaluzaSmartChargerDataEpic,
  getKaluzaSmartChargerMonthlyDataEpic,
  sendKaluzaTokenDataEpic,
  sendKaluzaAuthTokenDataEpic,
  connectionStatusToKaluzaSmartChargerDataEpic,
  getVehicleDataFromKaluzaSmartChargerDataEpic,
  getSavingsAndInsightDataFromKaluzaSmartChargerDataEpic,
  getAnytimeSavingsDataFromKaluzaSmartChargerDataEpic,
  getSmartThermostatsOrderInfoEpic,
  activateAnytimeProductEpic,
  cancelAnytimeProductEpic,
];

export default epics;
