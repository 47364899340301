import { Radio } from '@ovotech/nebula';
import React, { Dispatch } from 'react';
import styled from 'styled-components';
import NoAccountWarning from './NoAccountWarning';
import { BootstrapAccountSupply } from '@/src/types/Response';
import { transformAddress } from '@/src/utils/strings';

const StyledRadioRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const formatAddress = (supplyPoints?: BootstrapAccountSupply[]) => {
  if (!supplyPoints || supplyPoints.length === 0) {
    return null;
  }

  const address =
    (supplyPoints[0] && supplyPoints[0].supplyPointInfo?.address) ||
    (supplyPoints[1] && supplyPoints[1].supplyPointInfo?.address);

  if (!address) {
    return null;
  }

  return `${address[0]},  ${address[1]}, ${address[2]}`;
};

type SetSelectedAccount = Dispatch<string>;

const SelectAccountWithData = ({
  accountId,
  selectedAccount,
  setSelectedAccount,
  supplyPoints,
}: {
  accountId: string;
  selectedAccount: string;
  setSelectedAccount: SetSelectedAccount;
  supplyPoints: BootstrapAccountSupply[];
}) => {
  const address = formatAddress(supplyPoints);
  const isChecked = accountId === selectedAccount;

  return address ? (
    <StyledRadioRow>
      <Radio
        key={accountId}
        id={accountId}
        hint={accountId}
        label={transformAddress(address)}
        name={'select-account'}
        onChange={e => setSelectedAccount(e.currentTarget.value)}
        value={accountId}
        checked={isChecked}
        data-testid={`account-${accountId}`}
      />
    </StyledRadioRow>
  ) : (
    <NoAccountWarning accountId={accountId} />
  );
};

export default SelectAccountWithData;
