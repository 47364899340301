import { RecommendedDirectDebitResponse } from '@ovotech/energy-cx';

export const isValidRecommendedDirectDebit = (
  recommendedDirectDebit?: RecommendedDirectDebitResponse,
) => {
  const statusList =
    (recommendedDirectDebit && recommendedDirectDebit.state) || [];

  if (
    statusList.includes('BillingOnHold') ||
    statusList.includes('AmountOverSensibleUpperLimit') ||
    !recommendedDirectDebit ||
    recommendedDirectDebit.friendlyAmount === null
  ) {
    return false;
  } else {
    return true;
  }
};
