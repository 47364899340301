import { Li, Modal, P, Strong, TextLink, Ul } from '@ovotech/nebula';
import React from 'react';
import { DEBT_ASSISTANCE_URL } from '@/src/constants/links';
import { DEBT_SENSE_LINK } from '@/src/constants/analytics-subjects';
import { AnalyticsClick } from '@ovotech/ui-tools';

type Props = {
  onClose: () => void;
  isInDebit: boolean;
};

const InDebitModalContent = () => (
  <>
    <Strong>The amount due shows how much you need to pay now</Strong>
    <P>It includes:</P>
    <Ul>
      <Li>
        the cost of your energy use, standing charges and VAT since your last
        bill
      </Li>
      <Li>
        any money still due from previous bills, or any credit in your account
        since your last bill
      </Li>
      <Li>any upgrades, rewards or discounts on your plan, if you have them</Li>
    </Ul>
    <P>After paying the full amount due, you’ll see your balance again.</P>
    <Strong>Worried about paying?</Strong>
    <P>
      If you need some support paying your energy bills, we’re here to help.{' '}
      <AnalyticsClick name={DEBT_SENSE_LINK}>
        <TextLink href={DEBT_ASSISTANCE_URL} opensInNewWindow>
          {'Find out how we can support you.'}
        </TextLink>
      </AnalyticsClick>
    </P>
  </>
);

const InCreditModalContent = () => (
  <>
    <Strong>Your balance shows how much money is in your account</Strong>
    <P>It updates automatically around 3 hours after:</P>
    <Ul>
      <Li>you make a payment</Li>
      <Li>we credit or refund you</Li>
    </Ul>
    <Strong>There’s nothing to pay right now</Strong>
    <P>
      You’re all paid up and we’ll let you know when your next bill is ready.
      You can make extra payments into your balance any time, if you want to.
    </P>
  </>
);

export const LearnAboutBalanceModal = ({ onClose, isInDebit }: Props) => {
  return (
    <Modal
      style={{ top: 100 }}
      data-testid="learn-about-balance-modal"
      title="Learn about balance"
      isOpen
      onClose={onClose}
    >
      {isInDebit ? <InDebitModalContent /> : <InCreditModalContent />}
    </Modal>
  );
};
