import { P, Strong } from '@ovotech/nebula';
import format from 'date-fns/format';
import React from 'react';
import { getEpochDaysDate } from '@/src/utils/date';

export function RenewalMessage({ expiryDate }: { expiryDate: number }) {
  return (
    <P>
      Now that your plan ends on <Strong>{formatEndDate(expiryDate)}</Strong>,
      choose a new fixed rate plan and benefit from locked-in rates until your
      contract ends. Ready?
      <br />
      Remember, with OVO you will get:
    </P>
  );
}

function formatEndDate(date: number) {
  return format(getEpochDaysDate(date), 'Do MMMM YYYY');
}
