import React from 'react';
import {
  StyledGreenerElectricityModalContent,
  StyledContent,
} from './GreenerElectricityModal.styled';

interface Props extends React.HTMLProps<HTMLDivElement> {
  onClose: any;
  title: string;
  showGreenerElectricityModal: boolean;
  testId?: string;
}

export const GreenerElectricityModal = ({
  children,
  onClose,
  title,
  showGreenerElectricityModal,
  testId,
}: Props) => {
  return (
    <StyledGreenerElectricityModalContent
      title={title}
      isOpen={showGreenerElectricityModal}
      onClose={onClose}
      data-testid={testId}
    >
      <StyledContent>{children}</StyledContent>
    </StyledGreenerElectricityModalContent>
  );
};
