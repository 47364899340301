import { Card, DateField } from '@ovotech/nebula';
import React, { useState } from 'react';
import { PAGE_GET_QUOTE } from '../../analytics';
import { Address } from '../../components/Address';
import { Divider } from '../../components/Divider.styled';
import { StyledForm } from '../../components/Form.styled';
import { MoveInPage } from '../../components/MoveInPage';
import { FormErrors, NebulaDate, QuoteRequestForm } from '../../types/form';
import { MoveInJourneyProps } from '../../types/moveIn';
import { formDateToDate } from '../../utils/date';
import { formEventToObject } from '../../utils/formInput';
import { Payg, PaymentMethod, PayOD } from './components/PaymentMethod';
import { Usage } from './components/Usage';
import { validateGetQuote } from './validateQuote';

export function GetQuote({
  continueMoveIn,
  moveInDetails,
  progress,
  updateMoveIn,
}: MoveInJourneyProps) {
  const [formErrors, setFormErrors] = useState<FormErrors<QuoteRequestForm>>(
    {},
  );
  const [paymentMethod, setPaymentMethod] = useState<string>('paym');

  // Required by DateField :/
  const [supplyDate, setSupplyDate] = useState<NebulaDate>({
    day: moveInDetails.moveInDate?.getUTCDate().toString() ?? '',
    month: moveInDetails.moveInDate
      ? (moveInDetails.moveInDate.getUTCMonth() + 1).toString()
      : '',
    year: moveInDetails.moveInDate?.getUTCFullYear().toString() ?? '',
  });

  const onSubmit = (unknownForm: React.FormEvent<HTMLFormElement>) => {
    unknownForm.preventDefault();
    const formInputs = formEventToObject<QuoteRequestForm>(unknownForm);
    const validatedForm = validateGetQuote(formInputs);

    if (validatedForm.validation === 'error') {
      setFormErrors(validatedForm.errors);
      window.scrollTo(0, 0);
      return false;
    } else {
      updateMoveIn({
        getQuote: validatedForm.data,
        property: validatedForm.data,
        moveInDate: formDateToDate(
          validatedForm.data.moveInDate!,
          validatedForm.data['moveInDate-month']!,
          validatedForm.data['moveInDate-year']!,
        ),
        // Meter details not obtained automatically if manual address entered
        needMeterDetails: validatedForm.data.manualAddress,
      });
    }

    continueMoveIn();
    return true;
  };

  const today = new Date();

  return (
    <MoveInPage
      analyticsName={PAGE_GET_QUOTE}
      title="Move In Quote"
      flowProgress={progress}
    >
      <Card>
        <StyledForm onSubmit={onSubmit}>
          <Address
            errors={formErrors}
            setFormErrors={setFormErrors}
            filledValues={moveInDetails.property ?? {}}
          />

          <Divider />

          <DateField
            id="moveInDate"
            name="moveInDate"
            label="When did you move in to your new property?"
            hint={
              `For example, ${today.getUTCDate()} ` +
              `${1 + today.getUTCMonth()} ${today.getUTCFullYear()}`
            }
            onChange={setSupplyDate}
            value={supplyDate}
            error={formErrors.moveInDate}
            data-testid="move-in-date"
          />

          <Divider />

          <PaymentMethod
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />

          <Divider />

          {paymentMethod === 'paym' ? (
            <Usage formErrors={formErrors} {...moveInDetails.getQuote} />
          ) : paymentMethod === 'payg' ? (
            <Payg />
          ) : (
            <PayOD />
          )}
        </StyledForm>
      </Card>
    </MoveInPage>
  );
}
