import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
  ReferenceLine,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import { CarbonComparisonCustomLabel } from './CarbonComparisonCustomLabel';
import { ComparisonGraphKey } from './ComparisonGraphKey';

import {
  AVERAGE_HOME_CARBON_KG_FROM_ELECTRICITY,
  AVERAGE_HOME_CARBON_KG_FROM_GAS,
} from '@/src/constants/stats';
import { roundTo1DP } from '@/src/utils/number';
import { kgToTonne } from '@/src/pages/EnergyInsights/utils/conversions';

type Props = {
  electricityCarbonTonnes: number;
  gasCarbonTonnes: number;
};

export const CARBON_COMPARISON_GRAPH_BAR_WIDTH = 125;

type CarbonComparisonChartData = {
  name: string;
  electricity: number;
  gas: number;
  offset?: number;
};

const StyledCarbonComparisonGraph = styled.div`
  padding-top: 0px;
  padding-bottom: 20px;
  height: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const CarbonComparisonGraph: React.FC<Props> = ({
  electricityCarbonTonnes,
  gasCarbonTonnes,
}) => {
  const theme = useTheme();
  const chartData: CarbonComparisonChartData[] = [
    {
      name: 'Your impact',
      electricity: roundTo1DP(electricityCarbonTonnes),
      gas: roundTo1DP(gasCarbonTonnes),
    },
    {
      name: "Average OVO home's impact",
      electricity: roundTo1DP(
        kgToTonne(AVERAGE_HOME_CARBON_KG_FROM_ELECTRICITY),
      ),
      gas: roundTo1DP(kgToTonne(AVERAGE_HOME_CARBON_KG_FROM_GAS)),
    },
  ];

  return (
    <StyledCarbonComparisonGraph>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={400}
          barSize={CARBON_COMPARISON_GRAPH_BAR_WIDTH}
          barCategoryGap={4}
          data={chartData}
          stackOffset="sign"
          margin={{
            top: 100,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <Bar dataKey="gas" stackId="a" fill={theme.colors.brand.gas.base} />
          <Bar
            dataKey="electricity"
            isAnimationActive={false}
            stackId="a"
            fill={theme.colors.brand.electricity.base}
          >
            <LabelList
              content={
                <CarbonComparisonCustomLabel
                  carbonTonnes={electricityCarbonTonnes + gasCarbonTonnes}
                />
              }
              offset={0}
              position="top"
            />
          </Bar>
          <Bar
            dataKey="offset"
            stackId="a"
            fill={theme.colors.brand.primary.base}
          />
          <ReferenceLine
            y={0}
            stroke={theme.colors.brand.grey.darkest}
            strokeWidth={5}
            label={{
              position: 'left',
              value: '',
              fontWeight: 'bold',
              fontFamily: theme.fonts.display,
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <ComparisonGraphKey />
    </StyledCarbonComparisonGraph>
  );
};

export default CarbonComparisonGraph;
