import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const Card = styled.div`
  ${({ theme: { colors, space } }) => `
    background-color: ${colors.canvas};
    box-shadow: 0px 1px 8px ${colors.brand.grey.base};
    display: flex;
    overflow: hidden;
    ${mqSmallDown(`
      flex-direction: column;
      padding: ${space[6]}
    `)}
  `}
`;

export const CardBody = styled.div`
  ${({ theme: { space } }) => `
    flex: 1;
    padding-top: ${space[6]};
    padding-bottom: ${space[8]};
    padding-right: ${space[12]};
    ${mqSmallDown(`
      flex-direction: column;
      padding-right: 0;
      padding-bottom: 0;
    `)}
  `}
`;

export const CardNumberWrapper = styled.div`
  ${({ theme: { colors } }) => `
    width: 40px;
    height: 40px;
    margin: 20px;
    border: 4px solid ${colors.brand.primary.base};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mqSmallDown(`
      margin: 0;
    `)}
  `}
`;

export const CardNumber = styled.h3`
  ${({ theme: { colors } }) => `
    color: ${colors.brand.primary.base};
    text-align: center;
    margin: 0;
  `}
`;
