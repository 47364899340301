import { useQuery, UseQueryResult } from 'react-query';
import { KAPI_URL } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { useAccountContext } from '@ovotech/ui-tools';
import { useFeature } from '@/src/utils/optimizely';
import { ENABLE_MHHS_READ_FREQUENCY_SETTINGS } from '../../constants/feature-flags';
import { graphql } from './__generated__';
import { ConsentLevel, ConsentType, SupplyFuel } from '@monovo/kapi';
import { AccountMhhsConsentQuery } from './__generated__/graphql';
import { buildGraphqlQueryBody } from '../utils/buildGraphqlQueryBody';
import { isAfter, isEqual } from 'date-fns';

const MHHS_CONSENT_QUERY = graphql(/* kapi-codegen-graphql */ `
  query AccountMHHSConsent($accountId: ID!) {
    account(id: $accountId) {
      accountSupplyPoints {
        supplyPoint {
          sprn
          fuelType
          status {
            current {
              status
            }
          }
          meterTechnicalDetails {
            type
          }
        }
        consent {
          sprn
          billingConsent {
            consentLevel
            consentType
            from
            to
          }
          settlementConsent {
            consentLevel
            consentType
            from
            to
          }
        }
      }
    }
  }
`);

type QueryCacheKey = `consent-${string}`;
/**
 * `consentLevel`, `consentType`, `from` can be undefined because of optional chaining when mapping Account -> ConsentData
 */
export type ConsentPolicyData = {
  consentLevel?: ConsentLevel;
  consentType?: ConsentType;
  from?: string;
  to?: string;
};

type ConsentData = {
  fuel: SupplyFuel;
  sprn: string;
  consent: { billing: ConsentPolicyData; settlement: ConsentPolicyData };
};

type Consent = {
  hasValidSupplyPointsAndMeters: boolean;
  consentData: ConsentData[];
};

// TODO: use generated types when available
type ConsentPolicy = {
  readonly __typename?: 'ConsentPolicy' | undefined;
  readonly consentLevel: ConsentLevel;
  readonly consentType: ConsentType;
  readonly from: string; // Date in ISO 8601 format
  readonly to?: string; // Date ISO 8601 format
};

/**
 * input type inferred from codegen
 * `from` and `to` fields typed as `any` - not ideal
 */
const mapToNearestPolicyConsentLevelBasedOnDate = (
  consentPolicies?: ReadonlyArray<ConsentPolicy> | undefined,
): ConsentLevel | undefined => {
  if (!consentPolicies) return undefined;

  const filteredPolicies = consentPolicies.filter(policy => !policy.to);

  // if there are policies without an end date, return the one with the highest precedence
  if (filteredPolicies.length > 0) {
    return filteredPolicies[0].consentLevel;
  }

  // sort policies by end date in ascending order
  const sortedPolicies = consentPolicies
    .slice()
    .sort((a, b) => new Date(a.to!).getTime() - new Date(b.to!).getTime());

  const currentDate = new Date();

  for (const policy of sortedPolicies) {
    // if the policy in the sorted policies is after or equal to the current date we found it!
    if (isAfter(currentDate, policy.to!) || isEqual(currentDate, policy.to!)) {
      return policy.consentLevel;
    }
  }

  return undefined;
};

const mapToConsent = (account: AccountMhhsConsentQuery): Consent => {
  if (!account.account?.accountSupplyPoints) {
    return {
      hasValidSupplyPointsAndMeters: false,
      consentData: [],
    };
  }

  const meterTypePrefixes = ['S1', 'S2', '2']; // Smart Meter Prefixes

  const isValidMeterType = (meterType?: string | null) => {
    if (!meterType) {
      return false;
    }

    if (
      meterTypePrefixes.some(meterTypePrefix =>
        meterType?.startsWith(meterTypePrefix),
      )
    ) {
      return true;
    }

    if (meterType === 'RCAMR' || meterType === 'RCAMY' || meterType === 'H') {
      return true;
    }

    return false;
  };

  const hasValidSupplyPointsAndMeters =
    account.account?.accountSupplyPoints.some(supplyPoint => {
      const isElectricity = supplyPoint.supplyPoint.fuelType === 'ELECTRICITY';
      const isLive = supplyPoint.supplyPoint.status?.current?.status === 'Live'; // https://developer.kaluza.com/energy-retail/api-reference/v1/objects/supply-point-status-history-detail
      const hasValidMeterType =
        supplyPoint.supplyPoint.meterTechnicalDetails.some(meter =>
          isValidMeterType(meter.type),
        );

      return isElectricity && isLive && hasValidMeterType;
    });

  const consentData = account.account?.accountSupplyPoints.reduce(
    (accumulator: ConsentData[], accountSupplyPoint) => {
      // the consent screen displays options for billing and settlement related to *electricity only*
      if (accountSupplyPoint.supplyPoint.fuelType === 'ELECTRICITY') {
        const supplyPointData = {
          fuel: accountSupplyPoint.supplyPoint.fuelType,
          sprn: accountSupplyPoint.supplyPoint.sprn,
          consent: {
            billing: {
              consentLevel: mapToNearestPolicyConsentLevelBasedOnDate(
                accountSupplyPoint.consent?.billingConsent,
              ),
            },
            settlement: {
              consentLevel: mapToNearestPolicyConsentLevelBasedOnDate(
                accountSupplyPoint.consent?.settlementConsent,
              ),
            },
          },
        };
        accumulator.push(supplyPointData);
      }
      return accumulator;
    },
    [],
  );

  return {
    hasValidSupplyPointsAndMeters,
    consentData,
  };
};

export const useConsentQuery = (): UseQueryResult<Consent> => {
  const { accountId } = useAccountContext();
  const [enabled] = useFeature(ENABLE_MHHS_READ_FREQUENCY_SETTINGS);

  return useQuery(
    `consent-${accountId}` as QueryCacheKey,
    () =>
      apiService
        .graphql<AccountMhhsConsentQuery>({
          url: KAPI_URL!,
          body: buildGraphqlQueryBody(MHHS_CONSENT_QUERY, {
            accountId: accountId as string,
          }),
        })
        .then(
          /* select is only executed if the query successfully returns data
           if the API happens to return 'undefined', 'mapToConsent' will not be called at all
           added this tweak to ensure that 'mapToConsent' is consistently executed */
          response => response || {},
        ),
    {
      select: account => {
        return mapToConsent(account);
      },
      enabled: !!accountId && enabled,
      retry: 3,
    },
  );
};
