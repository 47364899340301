import {
  Card,
  Checkbox,
  CTAButton,
  DateField,
  Margin,
  P,
  Radio,
  RadioField,
} from '@ovotech/nebula';
import React, { useState } from 'react';
import { UseQueryResult } from 'react-query';
import { PAGE_CUSTOMER_DETAILS } from '../../analytics';
import { StyledForm } from '../../components/Form.styled';
import { MoveInPage } from '../../components/MoveInPage';
import { CusomterDetails } from '../../types/acquisitionRequest';
import { FormErrors, NebulaDate, CustomerDetailsForm } from '../../types/form';
import { MoveInJourneyProps } from '../../types/moveIn';
import { formDateToDate, toShortISODate } from '../../utils/date';
import { formEventToObject } from '../../utils/formInput';
import { FormContactDetails } from './components/Contact';
import { CustomerName } from './components/CustomerName';
import { validatePersonalDetails } from './validateDetails';
import { Address } from '@/src/pages/MoveIn/components/Address';
import { DisabledTextField } from '@/src/pages/MoveIn/pages/CustomerDetails/CustomerDetails.styled';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { ProfileNextV1Query } from '@/src/api/kapi/__generated__/graphql';

export type FormProps = {
  existingDetails: UseQueryResult<ProfileNextV1Query, unknown>;
  formErrors: FormErrors<CustomerDetailsForm>;
};

export function CustomerDetails({
  continueMoveIn,
  moveInDetails,
  progress,
  updateMoveIn,
}: MoveInJourneyProps) {
  const profile = useProfileQueryKapi();
  const [useSameAddress, setUseSameAddress] = useState<boolean>(
    moveInDetails.useSupplyAddress,
  );

  // Required by DateField :/
  const [dateOfBirth, setDateOfBirth] = useState<NebulaDate>({
    day: moveInDetails.customer?.dateOfBirth?.slice(-2) ?? '',
    month: moveInDetails.customer?.dateOfBirth?.slice(5, 7) ?? '',
    year: moveInDetails.customer?.dateOfBirth?.slice(0, 4) ?? '',
  });

  const [formErrors, setFormErrors] = useState<FormErrors<CustomerDetailsForm>>(
    {},
  );

  const onSubmit = (unknownForm: React.FormEvent<HTMLFormElement>) => {
    unknownForm.preventDefault();
    const formInputs = formEventToObject<CustomerDetailsForm>(unknownForm);
    const validatedForm = validatePersonalDetails(formInputs);

    if (validatedForm.validation === 'error') {
      setFormErrors(validatedForm.errors);
      window.scrollTo(0, 0);
      return false;
    } else {
      const customer: CusomterDetails = {
        ...validatedForm.data,
        isFinanciallyLiable: true,
        dateOfBirth: toShortISODate(
          formDateToDate(
            validatedForm.data.dateOfBirth!,
            validatedForm.data['dateOfBirth-month']!,
            validatedForm.data['dateOfBirth-year']!,
          ),
        ),
        ...(validatedForm.data.specialRequirement === 'yes'
          ? { specialRequirement: 'yes', criticalCode: ['Other'] }
          : { specialRequirement: 'no' }),
      };

      updateMoveIn({
        customer,
        billingAddress: moveInDetails.property,
      });
      continueMoveIn();

      return true;
    }
  };

  return (
    <MoveInPage
      analyticsName={PAGE_CUSTOMER_DETAILS}
      analyticsProps={{ autofilled: profile.status === 'success' }}
      title="Your Details"
      flowProgress={progress}
    >
      <Card>
        <StyledForm onSubmit={onSubmit}>
          <CustomerName
            existingDetails={profile}
            formErrors={formErrors}
            {...moveInDetails.customer}
          />

          <DateField
            id="dateOfBirth"
            name="dateOfBirth"
            label="Date of birth"
            hint="For example, 31 07 1980"
            onChange={setDateOfBirth}
            value={dateOfBirth}
            error={formErrors.dateOfBirth}
            data-testid="dob-field"
          />

          <FormContactDetails
            existingDetails={profile}
            formErrors={formErrors}
          />

          <DisabledTextField
            id="supply"
            disabled
            label="Your Supply Address"
            value={
              moveInDetails.property
                ? moveInDetails.property.manualAddress
                  ? moveInDetails.property.addressLine1
                  : moveInDetails.property.fullAddress
                : ''
            }
            data-testid="supply-address"
          />

          <Checkbox
            id="useSupplyAddress"
            name="useSupplyAddress"
            label="Use this address as my billing address"
            checked={useSameAddress}
            onChange={() => setUseSameAddress(!useSameAddress)}
            data-testid="use-supply-address-checkbox"
          />

          {!useSameAddress && (
            <>
              <Address
                errors={formErrors}
                setFormErrors={setFormErrors}
                filledValues={moveInDetails.billingAddress ?? {}}
                data-testid="customer-address"
              />
              <Margin bottom={4} />
            </>
          )}

          <RadioField
            name="specialRequirement"
            label="Do you have any special requirements that might require additional services?"
            defaultValue={moveInDetails.customer?.specialRequirement ?? 'no'}
            error={formErrors.specialRequirement}
            hint={
              <P>
                Need a hand with your energy? From notice about planned power
                cuts, to large-print bills and braille - there's lots we can do
                to help.
              </P>
            }
            data-testid="special-requirements-question"
          >
            <Radio
              name="specialRequirement"
              label="Yes"
              value="yes"
              id="specialrequirement-yes"
              data-testid="special-requirements-yes"
            />

            <Radio
              name="specialRequirement"
              label="No"
              value="no"
              id="specialrequirement-no"
              data-testid="special-requirements-no"
            />
          </RadioField>

          <RadioField
            name="serviceOptIn"
            label="How would you like to receive account information?"
            defaultValue={moveInDetails.customer?.serviceOptIn ?? 'Email'}
            error={formErrors.serviceOptIn}
            data-testid="opt-in-question"
          >
            <Radio
              name="serviceOptIn"
              id="serviceOptIn-Email"
              label="Email"
              value="Email"
              data-testid="opt-in-email"
            />

            <Radio
              name="serviceOptIn"
              id="serviceOptIn-Post"
              label="Post"
              value="Post"
              data-testid="opt-in-post"
            />
          </RadioField>

          <CTAButton
            variant="missionPrimary"
            iconRight="chevron-right"
            data-testid="next-button"
          >
            Next
          </CTAButton>
        </StyledForm>
      </Card>
    </MoveInPage>
  );
}
