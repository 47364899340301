import React, { FC } from 'react';
import { HomeRecoverPromoOffer } from './HomeRecoverPromoOffer';
import { HomeRecoverQ1Offer } from './HomeRecoverQ1Offer';

interface HomeRecoverOfferProp {
  showQ1Offer: boolean;
  accountId: string;
}

export const HomeRecoverOffer: FC<HomeRecoverOfferProp> = ({
  showQ1Offer,
  accountId,
}) => {
  if (showQ1Offer) {
    return <HomeRecoverQ1Offer accountId={accountId} />;
  }
  return <HomeRecoverPromoOffer accountId={accountId} />;
};
