import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { FootnoteReferencedContent } from '@/src/components';

const GREENER_ENERGY_PAGE_URL = 'https://www.ovoenergy.com/greener-energy';
const PFA_PAGE_URL = 'https://www.ovoenergy.com/renewable-ppas';

const Footnote100PercentGreenEnergyContent = () => (
  <>
    With Greener Energy, you’ll receive 100% renewable electricity backed by
    Power Purchase Agreements (PPAs) that we have with UK-based generators
    (which are backed by REGOs to verify that the source is renewable). The
    electricity supplied to your home will still come from the National Grid and
    come from a mix of sources, but an amount of renewable electricity equal to
    the amount you’ve used will be added to the grid, directly from a UK-based
    renewable generator.
    <br />
    <br /> If you’re a dual fuel customer, Greener Energy also provides 100%
    carbon-neutral gas (10% green gas backed via renewable certificates
    (Renewable Gas Guarantees of Origin (RGGOs) and 90% offset)).
    <br />
    <br /> The energy you get is also carbon-neutral, as we offset all
    associated lifecycle carbon emissions involved in the production and
    consumption of your gas and electricity by supporting carbon reduction
    projects that are certified to the Verified Carbon Standard or the Gold
    Standard. For more information please see our{' '}
    <TextLink opensInNewWindow href={GREENER_ENERGY_PAGE_URL}>
      Greener Energy page
    </TextLink>{' '}
    and{' '}
    <TextLink opensInNewWindow href={PFA_PAGE_URL}>
      PPA page
    </TextLink>
    .
  </>
);
export const footnote100PercentGreenEnergy: FootnoteReferencedContent = {
  id: 'GreenEnergy',
  heading: '100% Carbon Neutral Energy',
  body: [<Footnote100PercentGreenEnergyContent key="content" />],
};
