import { Heading3, P, Strong } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { ActionCentreBanner, Banner } from '../../Banner';
import { useContractsQuery } from '@/src/api';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { LOSS_MESSAGE_SHOWN } from '@/src/constants/analytics';
import {
  findContractInLoss,
  getFirstAndLastContractsInLoss,
  getOtherFuelType,
} from '@/src/utils/contracts';

export const FinalStatementOneStaying: ActionCentreBanner = () => {
  const { accountId } = useAccountContext();
  const { data: contracts, isLoading } = useContractsQuery(accountId);
  if (isLoading) return <SkeletonCard />;
  if (!contracts) return null;

  const electricityContract = findContractInLoss(contracts.electricity);
  const gasContract = findContractInLoss(contracts.gas);
  const [contractInLoss] = getFirstAndLastContractsInLoss(
    electricityContract,
    gasContract,
  );

  if (!contractInLoss) return null;

  const { fuel, lossDate } = contractInLoss;
  const otherFuel = getOtherFuelType(fuel);
  const lossEvent = `Dual fuel: ${fuel} Final Statement, ${otherFuel} Staying`;

  return (
    <AnalyticsWrapper name={LOSS_MESSAGE_SHOWN} properties={{ lossEvent }}>
      <Banner
        icon="finalStatement"
        data-testid="duel-fuel-final-statement-one-staying"
      >
        <Heading3 as="h2">You've switched to a new energy supplier</Heading3>
        <P data-testid="loss-final-statement-date">
          Your switch happened on{' '}
          <Strong>
            {format(new Date(lossDate as string), 'Do MMMM YYYY')}.
          </Strong>
        </P>
        <P data-testid="loss-final-statement-other-fuel">
          We&rsquo;ll keep supplying your {otherFuel} as usual.
        </P>
      </Banner>
    </AnalyticsWrapper>
  );
};
