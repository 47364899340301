import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  overflow: hidden;
  align-items: center;
  background: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
`;

export const Image = styled.div<{ img: string }>`
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  height: 57px;
  width: 57px;
  margin: 0 12px;
  border-radius: 10px;
`;

export const DismissButton = styled.button`
  padding: 0;
  margin-left: 12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  color: #888;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Heading = styled.p`
  font-size: 0.9em;
  font-weight: 600;
`;

export const Text = styled.p`
  font-size: 0.7em;
  margin-top: 4px;
`;

export const Link = styled.a`
  color: #2e6cf3;
  margin: 12px;
  text-decoration: none;

  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: #2e6cf3;
  }
`;
