import { P } from '@ovotech/nebula';
import React from 'react';
import {
  StyledLearnMoreHeading,
  StyledLearnMoreContent,
} from './UsageBreakdownContent.styled';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import {
  Blur,
  CollapsibleSection,
  CollapsibleHeader,
  CollapsiblePanel,
} from '@/src/pages/EnergyInsights/components';
import { BreakdownInfo } from '@/src/pages/EnergyInsights/constants/usage';
import { AnyBreakdownCatID } from '@/src/pages/EnergyInsights/types';
import AnalyticsService from '@/src/services/analyticsService';

type Props = {
  breakdownInfo: BreakdownInfo;
  category: AnyBreakdownCatID;
};

export const CollapsibleLearnMore = ({ breakdownInfo, category }: Props) => (
  <CollapsibleSection isNested>
    <CollapsibleHeader
      headerContent={
        <StyledLearnMoreHeading data-testid="learn-more">
          Learn more
        </StyledLearnMoreHeading>
      }
      arrowPosition="left"
      onClick={() =>
        AnalyticsService.dispatch(
          ENERGY_INSIGHTS.usageBreakdownLearnMore(category),
        )
      }
    />
    <CollapsiblePanel>
      <StyledLearnMoreContent data-testid="learn-more-content">
        <P>
          <Blur>{breakdownInfo.learnMore}</Blur>
        </P>
        {breakdownInfo.appliances.length > 0 && (
          <>
            <P>Examples of appliances might include:</P>

            <ul>
              {breakdownInfo.appliances.map((appliance, i) => (
                <Blur key={i}>
                  <li>
                    <P>- {appliance}</P>
                  </li>
                </Blur>
              ))}
            </ul>
          </>
        )}
      </StyledLearnMoreContent>
    </CollapsiblePanel>
  </CollapsibleSection>
);
