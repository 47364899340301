import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledLearnMoreHeading = styled.span`
  ${({ theme: { colors, fontSizes } }) => `
    color: ${colors.brand.primary.base};
    font-size: ${fontSizes[4]};
  `}
`;

export const StyledLearnMoreContent = styled.div`
  ${({ theme: { colors } }) => `
    border-left: 1px solid ${colors.brand.grey.light};
    margin-left: 12px;
    padding-left: 20px;

    ${mqSmallDown(`
      margin-left: 0;
      padding-left: 0;
    `)}
  `}
`;
