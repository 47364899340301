import { makeActionSuccess } from '../http';
import {
  ANALYTICS_ACCOUNT_ID,
  ANALYTICS_CUSTOMER_ID,
} from '@/src/constants/analytics';
import { Action, GetBootstrapSuccess } from '@/src/types/Action';
import { BootstrapResponse } from '@/src/types/Response';
import { Bootstrap } from '@/src/types/State';

// CAREFUL! This is used in a lot of places
export const getBootstrapSuccess = makeActionSuccess<
  GetBootstrapSuccess,
  [BootstrapResponse]
>(data => ({
  type: 'GET_BOOTSTRAP_SUCCESS',
  data,
  meta: {
    analytics: {
      identify: data.customerId,
      register: {
        [ANALYTICS_ACCOUNT_ID]: data.selectedAccountId,
        [ANALYTICS_CUSTOMER_ID]: data.customerId,
      },
    },
  },
}));

export const defaultBootstrapState = {
  isFetching: false,
};

const bootstrap = (
  state: Bootstrap = defaultBootstrapState,
  action: Action,
): Bootstrap => {
  switch (action.type) {
    case 'GET_BOOTSTRAP_SUCCESS': {
      const { isFetching } = action;
      return {
        ...state,
        isFetching,
      };
    }
    default: {
      return state;
    }
  }
};

export default bootstrap;
1;
