import { CurrencyValueResponse } from '@/src/types/Response';
import { FuelType, PaymentMethod } from '@/src/types/Account';

export enum TariffCostsStatus {
  Idle = 'Idle',
  Started = 'Started',
  Resolved = 'Resolved',
  Rejected = 'Rejected',
}

type IdleState = {
  data: null;
  status: TariffCostsStatus.Idle;
};

type StartedState = {
  data: FetchTariffCostsResponse | null;
  status: TariffCostsStatus.Started;
};

type ResolvedState = {
  data: FetchTariffCostsResponse;
  status: TariffCostsStatus.Resolved;
};

type RejectedState = {
  data: null;
  status: TariffCostsStatus.Rejected;
};

export type TariffCostsState =
  | IdleState
  | StartedState
  | ResolvedState
  | RejectedState;

export type FetchTariffCostsResponse = Array<{
  mpxn: string;
  fuel: FuelType;
  currentTariffLabel: TariffLabel;
  defaultVariableTariffLabel: null | TariffLabel;
  allDefaultVariableTariffLabels: TariffLabel[];
  estimatedAnnualConsumption: string;
  cheapestOverallTariff: CheaperTariff;
  cheapestSimilarTariff: CheaperTariff;
}>;

type TimeOfUseLabel = 'peak' | 'offpeak' | 'anytime';
type ClockingTimeLabel = 'day' | 'night' | 'anytime';

export type TariffLabelUnitRate = {
  label: ClockingTimeLabel;
  timeOfUseLabel: TimeOfUseLabel;
  value: CurrencyValueResponse;
};

export type TariffLabel = {
  planName: string;
  type: string;
  paymentMethod: PaymentMethod;
  unitRates: Array<TariffLabelUnitRate>;
  standingRate: CurrencyValueResponse;
  startDate: null | string;
  endDate: null | string;
  exitFee: CurrencyValueResponse;
  estimatedAnnualCostIncVat: null | CurrencyValueResponse;
};

export type CheaperTariff = {
  planName: string;
  current: boolean;
  costSavingIncVat: CurrencyValueResponse;
  paymentMethod: PaymentMethod;
};
