import { Card, Heading3, Margin, P } from '@ovotech/nebula';
import React from 'react';
import { MI_BANNER } from '@/src/constants/feature-flags';
import { OptimizelyFeature } from '@/src/utils/optimizely';

type BannerOptions = {
  description: string;
  heading: string;
};

// PLEASE DON'T CHANGE THIS COPY!
// create a specific banner and flag so this one can be toggled quickly
const FALLBACK_HEADING = `We're experiencing some issues`;
const FALLBACK_DESCRIPTION = `We're currently experiencing some technical issues, and we're working to fix them. Please check back later.`;

export const MajorIncidentGeneralBanner = () => {
  return (
    <OptimizelyFeature feature={MI_BANNER}>
      {(isEnabled, variables: BannerOptions) =>
        isEnabled ? (
          <Margin top={8} bottom={1} data-testid="mi-banner">
            <Card>
              <Heading3 data-testid="mi-banner-heading">
                {(variables && variables.heading) || FALLBACK_HEADING}
              </Heading3>
              <P data-testid="mi-banner-description">
                {(variables && variables.description) || FALLBACK_DESCRIPTION}
              </P>
            </Card>
          </Margin>
        ) : null
      }
    </OptimizelyFeature>
  );
};
