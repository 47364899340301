import {
  Card,
  ErrorNotification,
  Heading4,
  Margin,
  P,
  SkeletonCircle,
  SkeletonHeading,
  SkeletonText,
  Strong,
  SummaryItem,
  SummaryList,
} from '@ovotech/nebula';
import React from 'react';
import { useTadoPricingQuery } from '../hooks/use-tado-pricing';
import { PaymentMethod } from '../types';
import { OrderType, PaymentType } from '../utils/types';
import {
  Divider,
  OrderSummaryWrapper,
  StyledCard,
  StyledDetails,
  StyledProductImageCard,
  StyledProductImageWrapper,
  StyledProductTextWrapper,
  StyledProductTitleWrapper,
  StyledRow,
} from './OrderDetails.styled';
import TadoStarterKitImage from '@/src/resources/tado-starter-kit.png';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { getBoltonPrice } from '../utils/utils';

interface OrderDetailsProps {
  paymentMethod: PaymentMethod.CARD_PAYMENT | PaymentMethod.MONTHLY_CHARGE;
}

export const OrderDetails = (props: OrderDetailsProps) => {
  const tadoPricing = useTadoPricingQuery();
  const profile = useProfileQueryKapi();
  const { paymentMethod } = props;

  if (tadoPricing.isLoading || profile.isLoading) {
    return (
      <Card>
        <SkeletonHeading />
        <SkeletonCircle />
        <SkeletonText lines={3} />
      </Card>
    );
  }

  if (
    tadoPricing.isError ||
    tadoPricing.data === undefined ||
    profile.error ||
    profile.data === undefined ||
    !profile.data.customer_nextV1
  ) {
    return (
      <ErrorNotification id="order-details-error">
        <P>Something went wrong. Please try again</P>
      </ErrorNotification>
    );
  }

  const upfrontBoltonPricing = getBoltonPrice(
    tadoPricing.data,
    PaymentType.FullAmountPayment,
    OrderType.SelfInstall,
  );

  const {
    customer_nextV1: { givenName, familyName, contactAddress },
  } = profile.data;

  return (
    <StyledCard>
      <Heading4>Order details</Heading4>
      <Margin bottom={6}>
        <StyledRow>
          <StyledDetails>
            <SummaryList>
              <SummaryItem
                label={'Delivery address'}
                data-testid="delivery-address"
              >
                <div data-testid="profile-name">{`${givenName} ${familyName}`}</div>
                {contactAddress?.postalAddress.line1 && (
                  <div>{contactAddress?.postalAddress.line1}</div>
                )}
                {contactAddress?.postalAddress.line2 && (
                  <div>{contactAddress?.postalAddress.line2}</div>
                )}
              </SummaryItem>
            </SummaryList>
          </StyledDetails>
          <StyledDetails>
            <SummaryList>
              <SummaryItem label={'Payment method'} data-testid="tado-payment">
                {paymentMethod}
              </SummaryItem>
            </SummaryList>
          </StyledDetails>
          <StyledDetails>
            <SummaryList>
              <SummaryItem
                label={'Estimated delivery'}
                data-testid="tado-delivery"
              >
                3-5 working days
              </SummaryItem>
            </SummaryList>
          </StyledDetails>
        </StyledRow>
      </Margin>
      <Divider margin={5} />
      <Heading4>Your tado° order summary</Heading4>
      <OrderSummaryWrapper>
        <StyledProductImageCard>
          <StyledProductImageWrapper src={TadoStarterKitImage} />
        </StyledProductImageCard>
        <StyledProductTitleWrapper>
          tado° Wireless Smart Thermostat Starter Kit V3+
        </StyledProductTitleWrapper>
        <StyledProductTextWrapper>
          <Strong>Qty: 1</Strong>
          {/* TODO: Check what to render for monthly pricing */}
          <Strong>£{Number(upfrontBoltonPricing).toFixed(2)}</Strong>
        </StyledProductTextWrapper>
      </OrderSummaryWrapper>
    </StyledCard>
  );
};
