import { parse } from 'date-fns';
import React from 'react';
import { useWeeklyBreakdown } from '../../../../useWeeklyBreakdown';
import WeekNavigation from '../../../WeeklyNavigation';
import { FuelBreakdown } from '../FuelBreakdown/FuelBreakdown';
import { BreakdownPageResponse, Fuel } from '@/src/types/Response';

interface Props extends BreakdownPageResponse {
  fuel: Fuel;
}

export const FuelTabContent = (props: Props) => {
  const {
    currentBreakdown,
    lastWeekBreakdown,
    backDisabled,
    forwardDisabled,
    backWeek,
    forwardWeek,
  } = useWeeklyBreakdown(props.breakdown);

  if (!currentBreakdown) {
    return null;
  }

  return (
    <>
      <WeekNavigation
        fromDate={parse(currentBreakdown.fromDate)}
        backDisabled={backDisabled}
        forwardDisabled={forwardDisabled}
        forward={forwardWeek}
        backward={backWeek}
      />
      <FuelBreakdown
        {...props}
        breakdownWeek={currentBreakdown}
        lastWeekBreakdown={lastWeekBreakdown}
      />
    </>
  );
};
