import styled from 'styled-components';

import { CostType } from '@/src/types/Account';

export const StyledPredictedCostChart = styled.div`
  ${({ theme: { fontSizes, space, fontWeights } }) => `
    margin-bottom: ${space[4]};
    font-size: ${fontSizes[1]};

    .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
    .recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
      stroke-opacity: 0;
    }

    .recharts-text.recharts-label {
      font-weight: ${fontWeights.semiBold};
    }

    .recharts-tooltip-cursor {
      opacity: 0;
    }
  `};
`;

export const StyledPredictedCostTooltip = styled.div`
  ${({ theme: { colors, space } }) => `
    padding: ${space[2]};
    background-color: ${colors.canvas};
    border: 1px solid ${colors.shadeMidLight};

    dd + dt {
      margin-top: ${space[4]};
    }
  `};
`;

type CostIndicatorProps = {
  cost: CostType;
};

export const StyledCostIndicator = styled.div<CostIndicatorProps>`
  display: inline-block;

  width: 10px;
  height: 10px;

  background: ${({ theme: { colors }, cost }) => {
    switch (cost) {
      case 'electricity':
        return colors.elec;
      case 'gas':
        return colors.gas;
      default:
        return colors.upgradesDark;
    }
  }};
`;
