import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const TileWithClippedImageStyled = styled.div`
  ${({ theme: { space, colors } }) => `
        flex: 1;
        align-items: center;
        height: auto; 
        margin-bottom: 18px;
        break-inside: avoid;

        display: inline-flex;
        flex-direction: column;
        border-radius: ${space[1]};
        overflow: hidden;
        
        // Safari Support
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
        background-color: ${colors.primaryContrast};

        // Fix for overflowing box-shadow
        transform: translateZ(0);

        // Used to adjust the order of tile items
        ${mqMediumDown(`
            &:nth-of-type(1) { order: 1}
            &:nth-of-type(2) { order: 3}
            &:nth-of-type(3) { order: 2}
            &:nth-of-type(4) { order: 4}
        `)}
    `}
`;

export const TileContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  position: relative;
  z-index: 2;
  height: 100%;

  &::before {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    background-color: white;
    width: 110%;
    height: 100px;
    top: -20px;
    left: -10px;
    transform: rotate(-2deg);
  }
`;

export const TileImage = styled.img`
  width: 100%;
  height: 190px;
  object-fit: cover;
`;

export const TileButton = styled.button`
  width: 100%;
  margin-top: auto;
`;

export const TileNewBadge = styled.div`
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  top: -24px;

  span {
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }
`;
