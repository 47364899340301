interface OneTrustWindow extends Window {
  OnetrustActiveGroups: string | undefined;
  OptanonWrapper: () => void;
}

declare let window: OneTrustWindow;

export function hasOptedIntoTrackingCookies() {
  // This is the default targeted cookie category ID unless changed in the OneTrust UI
  const targetedCookiesGroupId = 'C0004';
  return window.OnetrustActiveGroups?.includes(targetedCookiesGroupId) ?? false;
}
