import { print } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

interface QueryBodyReturn<TVar> {
  query: string;
  operationName?: string;
  variables?: TVar;
}

export function buildGraphqlQueryBody<TDoc, TVar>(
  document: TypedDocumentNode<TDoc, TVar>,
  variables?: TVar,
): QueryBodyReturn<TVar> {
  const body = {
    query: print(document),
    variables,
  };

  return body;
}
