import {
  OptimizelyFeature as OptimizelyFeatureOriginal,
  OptimizelyExperiment as OptimizelyExperimentOriginal,
  useFeature as useFeatureOriginal,
  useExperiment as useExperimentOriginal,
  useDecision as useDecisionOriginal,
  createInstance,
  setLogger,
} from '@optimizely/react-sdk';
import cookie from 'js-cookie';
import React, { Children, ReactNode } from 'react';
import { useOptimizely } from '@/src/utils/useOptimizely';

if (process.env.NODE_ENV === 'production') {
  setLogger(null);
}

export const optimizely = createInstance({
  sdkKey: process.env.NX_OPTIMIZELY_SDK_KEY,
});

function OptimizelyFeature({
  children,
  feature,
}: {
  children: (isEnabled: boolean, variables?: any) => ReactNode;
  feature: string;
}) {
  if (featureMatches(feature)) {
    return children(true);
  }

  return (
    <OptimizelyFeatureOriginal feature={feature}>
      {children}
    </OptimizelyFeatureOriginal>
  ) as any;
}

function OptimizelyExperiment({
  experiment,
  children,
}: {
  experiment: string;
  children: React.ReactNode;
}) {
  if (experimentMatches(experiment)) {
    const variation = getVariation();
    const childList = Children.toArray(children) as any;

    if (variation != null) {
      const childToRender = childList.find(
        ({ props }: any) => props.variation === variation,
      );

      if (childToRender) {
        return childToRender;
      }
    }

    const defaultChildToRender = childList.find(
      ({ props }: any) => props.default,
    );

    if (defaultChildToRender) {
      return defaultChildToRender;
    }

    return null;
  }

  return (
    <OptimizelyExperimentOriginal experiment={experiment}>
      {children}
    </OptimizelyExperimentOriginal>
  );
}

type DefaultProps = {
  enabled: boolean;
  variables?: {
    [key: string]: any;
  };
};

function useFeature(feature: string, defaultProps?: DefaultProps) {
  const result = useFeatureOriginal(feature);
  const optimizely = useOptimizely();
  const [, ...tail] = result;

  if (featureMatches(feature)) {
    const { enabled, variables } = getFeatureObject(feature);

    const isDisabled = getFeatureDisabled();

    const finalEnabledValue = !isDisabled && enabled;
    return [finalEnabledValue, ...(variables ? [variables] : tail)] as const;
  }

  if (defaultProps) {
    const isFeatureExists = Object.keys(
      optimizely?.getOptimizelyConfig()?.featuresMap ?? {},
    ).includes(feature);

    if (!isFeatureExists) {
      const { enabled, variables } = defaultProps;
      return [enabled, ...(variables ? [variables] : tail)] as const;
    }
  }

  return result;
}

function useDecision(decision: string) {
  const result = useDecisionOriginal(decision);

  if (decisionMatches(decision)) {
    const [decisionProperties, ...tail] = result;
    const { enabled, variables } = getDecisionObject(decision);

    return [
      {
        ...decisionProperties,
        enabled,
        variables,
      },
      ...tail,
    ] as const;
  }

  return result;
}

function useExperiment(experiment: string) {
  const result = useExperimentOriginal(experiment);

  if (experimentMatches(experiment)) {
    const [, ...tail] = result;

    return [getVariation(), ...tail] as const;
  }

  return result;
}

function featureMatches(feature: string) {
  const value = getValue('optimizelyFeature');
  const { enabled, variables } = getFeatureObject(feature);
  const hasFeatureKey = Boolean(enabled && variables);

  return (
    value === '*' ||
    value?.split(',').includes(feature) ||
    hasFeatureKey ||
    getFeatureDisabled() ||
    value?.includes(feature)
  );
}

function getFeatureDisabled(): boolean {
  return getValue('optimizelyFeatureDisabled') === 'true';
}

function experimentMatches(experiment: string) {
  return getValue('optimizelyExperiment') === experiment;
}

function decisionMatches(decision: string) {
  const value = getValue('optimizelyDecision');

  try {
    const decisionObj = value ? JSON.parse(value) : {};
    return !!decisionObj?.[decision] ?? false;
  } catch {
    return false;
  }
}

function getVariation() {
  return getValue('optimizelyVariation');
}

function getValue(key: string) {
  const urlParams = new URLSearchParams(window.location.search);
  // Cookie used for e2e tests mainly
  return urlParams.get(key) || cookie.get(key);
}

function getDecisionObject(decision: string) {
  const value = getValue('optimizelyDecision');
  const defaultObj = { enabled: false };

  try {
    const decisionObj = value ? JSON.parse(value) : defaultObj;
    return decisionObj?.[decision] || defaultObj;
  } catch {
    return defaultObj;
  }
}

function getFeatureObject(feature: string) {
  const value = getValue('optimizelyFeature');
  const defaultObj = { enabled: false, variables: {} };
  try {
    const featureObj = value ? JSON.parse(value) : defaultObj;
    return featureObj[feature] || defaultObj;
  } catch {
    return defaultObj;
  }
}

// Export everything first and then override with our own implementations
export * from '@optimizely/react-sdk';
// Ignore exports when no active tests are in progress
// ts-unused-exports:disable-next-line
export {
  OptimizelyFeature,
  OptimizelyExperiment,
  useFeature,
  useExperiment,
  useDecision,
};
