import { Modal, P, Strong, TextLink, Ul } from '@ovotech/nebula';
import React from 'react';
import { StyledLi } from '@/src/pages/MoveIn/pages/MeterDetails/components/modal.styled';
import MPANImage from '@/src/resources/MPAN.png';

export function WhatsMpanModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      title={`What's my MPAN`}
      isOpen={isOpen}
      onClose={onClose}
      data-testid="mpan-modal"
    >
      <P>
        MPAN (Meter Point Administration Number) is a 13-digit number that
        identifies your <Strong>electricity supply</Strong>.
      </P>
      <Ul>
        <Strong>You can find your MPAN:</Strong>
        <StyledLi>in a recent bill</StyledLi>
        <StyledLi>
          or you can request it from your{' '}
          <TextLink
            opensInNewWindow
            href="https://www.ovoenergy.com/guides/energy-guides/dno"
          >
            District Network Operator
          </TextLink>
        </StyledLi>
      </Ul>
      <P>
        On your bill, your MPAN is the 13-digit number which should be shown on
        the page with the details about your electricity use. It will be either
        shown as the 13-digit string we need, or in a grid - in this case, it's
        all of the numbers found along the bottom row.
      </P>
      <img
        src={MPANImage}
        alt="location of MPAN on bill - top left under supply address"
      />
      <P>
        Your MPAN confirms the location and structure of the supply you are on.
        It helps us to switch the correct supply and prevent switching a wrong
        one.
      </P>
    </Modal>
  );
}
