import styled from 'styled-components';

export const Square = styled.li`
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: -28px;
    margin-top: 10px;
    background: ${({ theme }) => theme.colors.brand.primary.lightest};
    transform: matrix(0.96, -0.24, 0.28, 0.97, 0, 0);
  }
`;

export const Circle = styled.li`
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    margin-left: -28px;
    margin-top: 10px;
    background: ${({ theme }) => theme.colors.brand.primary.lightest};
    border-radius: 50%;
  }
`;

export const StyledFeaturesList = styled.ul`
  ${({ theme: { space, colors } }) => `
    margin-top: ${space[2]};
    margin-bottom: ${space[2]};
    font-size: 16px;
    list-style: none;

    > li {
      margin-left: 30px;
      color: ${colors.body};
      margin-top: ${space[1]};
      margin-bottom: ${space[1]};
      line-height: 32px;
    }
  `}
`;
