import styled from 'styled-components';

export const StyledPostcodeLookupField = styled.div`
  display: flex;
`;

export const StyledPostcodeLookupFieldField = styled.div(
  ({ theme: { mediaQueries } }) => `
    flex: 1;

    ${mediaQueries.smallAndUp} {
        flex: 0;
    }
`,
);

export const StyledPostcodeLookupFieldButton = styled.div(
  ({ theme: { space } }) => `
    margin-left: ${space[2]};
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
  `,
);
