import {
  Heading1,
  P,
  PrimaryCTALink,
  SecondaryCTAButton,
  Sup,
} from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { ActionCentreBanner } from '../Banner';
import { CTAWrapper } from '../banners.styled';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';
import { HOME_RECOVER_JOURNEY_LINK } from '@/src/constants/links';
import homeRecoverImage from '@/src/resources/home-recover-image.jpg';

import { mqMediumDown, mqMediumUp, mqSmallUp } from '@/src/utils/mediaQuery';

const StyledHomeRecoverHeading = styled(Heading1)`
  ${mqMediumUp(`
  width: 40%;
  font-size: 3rem;
  line-height: 3rem;
    `)}
`;

const StyledHomeRecoverCardLockupWrap = styled.div`
  width: 100%;
  height: 100%;

  ${mqMediumUp(`
      position: absolute;
      margin: 0;
      top: 0;
      right: -1px;
      width: 55%;
      height: 100%;
    `)}
`;

const StyledHomeRecoverCardBody = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[4]} ${space[6]} ${space[4]};
    margin-top: -20%;
    ${mqMediumUp(`
    padding: ${space[8]} ${space[10]} ${space[4]};
    padding-right: 20%;
    margin-top: 0;
`)}
  `}
`;

const StyledHomeRecoverP = styled(P)`
  ${mqMediumUp(`
    font-size: 1.2rem;
    width: 50%
`)}
`;

const StyledHomeRecoverFootnote = styled(P)`
  ${mqMediumUp(`
    font-size: 1rem;
    width:60%
`)}
`;

const StyledHomeRecoverCard = styled.div`
  ${({ theme: { colors, space } }) => `
    position: relative;
    background-color: ${colors.canvas};
    box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
    margin-top: ${space[4]};

    &:first-of-type {
      margin-top: ${space[6]};

      ${mqSmallUp(`
      margin-top: ${space[8]};
    `)}
    }
  `}
`;

StyledHomeRecoverCard.displayName = 'SolarPanels';

type ImageProps = {
  desktopImage?: string;
  mobileImage?: string;
};

const ClippedImg = styled.img<ImageProps>`
  display: block;
  height: 95%;
  width: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 60%, 10% 100%);

  ${({ desktopImage, mobileImage }) => `
    content: url(${desktopImage});

    ${mqMediumDown(`
      ${mobileImage && `content: url(${mobileImage})`};
      height:30%;
      clip-path: polygon(4% 0, 100% 0, 100% 35%,15% 70%);
    `)}
  `}
`;

ClippedImg.displayName = 'ClippedImg';

export const HomeRecoverBanner: ActionCentreBanner = ({ dismiss, onClick }) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 800;

  const termsAndConditions = isMobile
    ? "OVO Energy Customers only, T&C's apply"
    : 'Exclusions, eligibility criteria, cancellation fees and Ts + Cs apply. Existing OVO Energy customers only.';

  return (
    <StyledHomeRecoverCard>
      <StyledHomeRecoverCardLockupWrap>
        <ClippedImg desktopImage={homeRecoverImage}></ClippedImg>
      </StyledHomeRecoverCardLockupWrap>
      <StyledHomeRecoverCardBody>
        <StyledHomeRecoverHeading data-testid="solar-panels-title" as="h1">
          Boiler, plumbing, heating, or electrics fixed for free.
          <Sup style={{ fontSize: 16 }}>1</Sup>
        </StyledHomeRecoverHeading>
        <StyledHomeRecoverP>
          Get a free repair when you take out our Complete plan
          <Sup style={{ fontSize: 7 }}>1</Sup>. Another perk of being an OVO
          customer.
        </StyledHomeRecoverP>

        <CTAWrapper>
          <PrimaryCTALink
            data-testid="home-recover-action-button"
            variant="primary"
            iconRight="off"
            href={HOME_RECOVER_JOURNEY_LINK}
            onClick={onClick}
          >
            Learn more
          </PrimaryCTALink>

          <SecondaryCTAButton onClick={dismiss}>Close</SecondaryCTAButton>
        </CTAWrapper>

        <StyledHomeRecoverFootnote>
          <Sup style={{ fontSize: 7 }}>1</Sup> {termsAndConditions}
        </StyledHomeRecoverFootnote>
      </StyledHomeRecoverCardBody>
    </StyledHomeRecoverCard>
  );
};
