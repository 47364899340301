import { useAccountContext } from '@ovotech/ui-tools';
import { useQuery } from 'react-query';
import { carbonZeroBffEndpoints } from '@/src/constants/endpoints';
import { EnergyInsightsHubResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

export const getHubQueryKey = (accountId?: string | null) =>
  `energy-insights-hub-${accountId}`;

export function useEnergyInsightsHubQuery() {
  const { accountId } = useAccountContext();

  return useQuery(
    getHubQueryKey(accountId),
    () =>
      !accountId
        ? null
        : apiService.get<EnergyInsightsHubResponse>({
            responseType: 'json',
            url: carbonZeroBffEndpoints.getHub(accountId),
          }),
    { enabled: !!accountId },
  );
}
