import { SMART_THERM_EMAIL_ADDRESS } from '@/src/constants/routes';

export const TADO_PAY_MONTHLY_MINIMUM_EOC_BALANCE_POUNDS = 50;
export const TADO_PAY_MONTHLY_MINIMUM_FIXED_GAS_CONTRACT_MONTHS_LEFT = 3;
export const TADO_APPLE_STORE_URL =
  'https://apps.apple.com/app/tado/id574418486';
export const TADO_GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.tado';
export const CHECK_A_TRADE_URL = 'https://www.checkatrade.com/';
export const KANTAN_BOOKING_FLOW_URL =
  'https://ondemand.ovoenergy.com/tadoinstallation/postcode';
export const INSTALLATION_GUIDE =
  'https://www.ovoenergy.com/guides/energy-guides/how-to-install-a-tado-wireless-smart-thermostat';
export const tadoRRP = 199.99;
export const TADO_STUDY = 'https://www.tado.com/gb-en/fraunhofer-study';
export const SMART_THERM_TERMS_AND_CONDITIONS_LINK =
  'https://www.ovoenergy.com/terms/smart-thermostat';
export const GREEN_FRIDAY_LINK =
  'https://www.ovoenergy.com/campaigns/smart-thermostat/green-friday-2021/terms';
export const PRIVACY_POLICY_LINK = 'https://www.ovoenergy.com/privacy-policy';
export const SMART_THERMOSTAT_LINK =
  'https://www.ovoenergy.com/terms/smart-thermostat';
export const TADO_FAQ = 'https://www.ovoenergy.com/smart-home/smart-thermostat';
export const TADO_EMAIL = `mailto:${SMART_THERM_EMAIL_ADDRESS}`;
export const TADO_TEL = 'tel:+443301759691';
