import axios from 'axios';
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { createAvailableProductsEndpoint } from '@/src/constants/endpoints';
import { AvailableProductsResponse } from '@/src/types/Response';

type QueryCacheKey = `orion-available-products`;

export const useAvailableProductsQuery = (
  productId: string,
  versionId?: string | null,
  promoCredit?: string | null,
  options?: UseQueryOptions<
    AvailableProductsResponse,
    unknown,
    AvailableProductsResponse,
    QueryCacheKey
  >,
): UseQueryResult<AvailableProductsResponse> =>
  useQuery(
    'orion-available-products',
    () =>
      axios
        .get<AvailableProductsResponse>(
          createAvailableProductsEndpoint(productId, versionId, promoCredit),
        )
        .then(response => response.data),
    {
      enabled: !!productId,
      ...options,
    },
  );
