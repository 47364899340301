import { Card, Heading2, Heading4, P, Small } from '@ovotech/nebula';
import React from 'react';
import { Divider } from '../../../components/Divider.styled';
import { Features } from '../../../components/Features';
import { Rates } from '../../../components/Rates';
import { Tariff } from '../../../types/quoteRequest';
import { getPlanDetails } from '../../../utils/planDetails';
import { PlanCardHeaderWrapper } from './PlanCard.styled';
import { CostHeader } from '@/src/pages/MoveIn/pages/QuoteConfirmation/QuoteConfirmation.styled';

type PlanProps = {
  tariff: Tariff;
};

function PlanDetails({ tariff }: PlanProps) {
  const { exitFees, monthlyCost, planDetails } = getPlanDetails(tariff);

  return (
    <>
      <PlanCardHeaderWrapper>
        <div>
          <Heading2 style={{ marginBottom: 0 }}>{planDetails.title}</Heading2>
          <Small>({planDetails.subtitle})</Small>
          <P>{planDetails.description}</P>
        </div>
        <CostHeader>
          <Heading4>£{monthlyCost} a month</Heading4>
        </CostHeader>
      </PlanCardHeaderWrapper>

      <Divider />
      <Rates
        isColumnBased={false}
        electricity={tariff.tils.Electricity}
        gas={tariff.tils.Gas}
      />

      <Divider />
      <Features
        exitFees={exitFees}
        partialFeatures={planDetails.features}
        contractLength={tariff.contractLength}
      />
    </>
  );
}

export function PlanCard(props: PlanProps) {
  return (
    <Card>
      <PlanDetails {...props} />
    </Card>
  );
}
