import { EnergyContract, TariffType } from '@/src/types/Account';
import {
  ContractsResponse,
  RecommendedDirectDebitResponse,
} from '@/src/types/Response';
import { getCurrentDate, getEpochDaysDate } from '@/src/utils/date';

export type SelectorData = {
  planExpiryDate?: null | number;
  recommendedDirectDebit?: RecommendedDirectDebitResponse;
};

type PlanData = { expiryDate: null | number; planType: TariffType };

export const getLatestContractEndDateAndType = (
  contracts: Array<EnergyContract>,
): null | PlanData => {
  const activeOrFutureContracts = contracts.filter(
    ({ status, expiryDate }: EnergyContract) =>
      status.active ||
      expiryDate == null ||
      getEpochDaysDate(expiryDate).getTime() > getCurrentDate().getTime(),
  );

  if (activeOrFutureContracts.length === 0) {
    return null;
  }

  const latestContract = activeOrFutureContracts.reduce((prev, curr) => {
    if (
      curr.expiryDate == null ||
      (prev.expiryDate != null && curr.expiryDate > prev.expiryDate)
    ) {
      return curr;
    }

    return prev;
  });
  if (latestContract.type === 'half-hourly') {
    return null;
  }
  return {
    expiryDate: latestContract.expiryDate,
    planType: latestContract.type,
  };
};

const selector = ({
  contracts,
  recommendedDirectDebit,
}: {
  contracts: ContractsResponse;
  recommendedDirectDebit: RecommendedDirectDebitResponse;
}): null | SelectorData => {
  if (
    recommendedDirectDebit.amount == null ||
    recommendedDirectDebit.balance == null
  ) {
    return null;
  }

  const planData = getLatestContractEndDateAndType([
    ...contracts.electricity,
    ...contracts.gas,
  ]);

  if (!planData || planData.planType === 'variable') {
    return null;
  }

  return {
    planExpiryDate: planData.expiryDate,
    recommendedDirectDebit: recommendedDirectDebit,
  };
};

export default selector;
