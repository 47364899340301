import { MoveOutConfirm, MoveOutView } from '@ovotech/energy-cx';
import { useAccountContext, useCustomerContext } from '@ovotech/ui-tools';
import React from 'react';
import { Route, useLocation } from 'react-router-dom-v5';
import {
  PageWithNavigationAndSectionPadding,
  SwitchWithNotFound,
} from '@/src/components';
import { GEMINI_URL } from '@/src/constants/endpoints';

import {
  ROUTE_MOVING_HOME,
  ROUTE_MOVING_HOME_CONFIRM,
  ROUTE_MOVING_HOME_MOVE_IN,
} from '@/src/constants/routes';

const MovingHomePage = () => {
  const location = useLocation();
  const { accountId } = useAccountContext();
  const { customerId } = useCustomerContext();

  if (location.pathname === ROUTE_MOVING_HOME_MOVE_IN) {
    const moveInUrl = `${GEMINI_URL}/?bundle=tuwy&utm_source=ovo-account-web&utm_medium=web&utm_campaign=Take us with you&accountId=${accountId}&customerId=${customerId}`;

    window.location.replace(moveInUrl);
    return <></>;
  }

  // Hooks cause a re-render when value changes for any reason
  // Using children instead of Route `component` to prevent re-renders of the route when not needed
  // `() => JSX.Element` !== `() => JSX.Element` due to different memory addresses for each new arrow function
  return (
    <SwitchWithNotFound>
      <Route exact path={ROUTE_MOVING_HOME}>
        <PageWithNavigationAndSectionPadding title="Moving home">
          <MoveOutView />
        </PageWithNavigationAndSectionPadding>
      </Route>

      <Route exact path={ROUTE_MOVING_HOME_CONFIRM}>
        <PageWithNavigationAndSectionPadding title="Confirmation - Moving home">
          <MoveOutConfirm />
        </PageWithNavigationAndSectionPadding>
      </Route>
    </SwitchWithNotFound>
  );
};

export default MovingHomePage;
