import { SetupDirectDebit, SetupDirectDebitConfirm } from '@ovotech/energy-cx';
import React from 'react';
import { Route } from 'react-router-dom-v5';
import {
  SwitchWithNotFound,
  PageWithNavigationAndSectionPadding,
} from '@/src/components';
import { ROUTE_SETUP_DD, ROUTE_SETUP_DD_CONFIRM } from '@/src/constants/routes';

const SetupDD = () => (
  <PageWithNavigationAndSectionPadding title="Set up Direct Debit">
    <SetupDirectDebit />
  </PageWithNavigationAndSectionPadding>
);

const SetupDDConfirm = () => (
  <PageWithNavigationAndSectionPadding title="Confirmation - Set up Direct Debit">
    <SetupDirectDebitConfirm />
  </PageWithNavigationAndSectionPadding>
);

const SetupDirectDebitRoutes = () => (
  <SwitchWithNotFound>
    <Route exact path={ROUTE_SETUP_DD} component={SetupDD} />
    <Route exact path={ROUTE_SETUP_DD_CONFIRM} component={SetupDDConfirm} />
  </SwitchWithNotFound>
);

export default SetupDirectDebitRoutes;
