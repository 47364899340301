import { ErrorNotification } from '@ovotech/nebula';
import React from 'react';
import { PageWithNavigation, useError } from '@/src/components';
import { RENEWAL_ERROR } from '@/src/constants/analytics-subjects';
import {
  GENERIC_PAGE_ERROR,
  INSUFFICIENT_RENEWAL_DATA,
} from '@/src/constants/error-section-ids';
import { ContractsResponse } from '@/src/types/Response';
import { getActiveContracts } from '@/src/utils/contracts';

function Error({
  errorMessage,
  errorTitle,
  id,
}: {
  errorMessage: string | JSX.Element;
  errorTitle: string;
  id: string;
}) {
  return (
    <PageWithNavigation
      title="Renewal - Error"
      analytics={{ name: RENEWAL_ERROR, properties: { id } }}
    >
      <div style={{ paddingTop: 32 }} data-testid={GENERIC_PAGE_ERROR}>
        <ErrorNotification title={errorTitle} id={id}>
          {errorMessage}
        </ErrorNotification>
      </div>
    </PageWithNavigation>
  );
}

export function ApiError() {
  return (
    <Error
      id="renewal-api-error"
      errorTitle="We’re sorry, something went wrong"
      errorMessage="Please try again later – or call us on 0330 303 5063 to renew your plan."
    />
  );
}

type Props = {
  contracts: ContractsResponse;
  availablePlansCount: number;
};

export function NotInRenewalError({ contracts, availablePlansCount }: Props) {
  const activeGas = getActiveContracts(contracts.gas);
  const activeElectric = getActiveContracts(contracts.electricity);

  const activeContracts = [...activeGas, ...activeElectric];
  const contractsInRenewalCount = activeContracts.filter(
    ({ status: { inRenewal } }) => inRenewal,
  ).length;

  useError(true, 'warn', INSUFFICIENT_RENEWAL_DATA, {
    availablePlansCount,
    contractsInRenewalCount,
    renewalDates: activeContracts.map(e => e.expiryDate),
  });

  return (
    <Error
      id="not-in-renewal-error"
      errorTitle="Sorry, there aren’t any plans available for you to renew at the moment."
      errorMessage="You’ve either renewed a plan recently, or your current plan isn’t ending within 53 days. Please call us on 0330 303 5063 to find out more."
    />
  );
}
