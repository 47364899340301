import { CTAButton, CTALink, Heading3, P } from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsRender } from '@ovotech/ui-tools';
import React from 'react';
import { RenewalBannerWarning } from './RenewalBannerWarning';
import { SecondaryButton, StyledLink } from './styled';
import { Banner } from '@/src/components/ActionCentre/Banners/Banner';
import { ACTION_CENTRE } from '@/src/constants/analytics-subjects';
import { ROUTE_RENEWAL } from '@/src/constants/routes';

function VariableContractRenewal({
  utmSource,
  id,
  dismiss,
  onClick,
}: {
  utmSource: string;
  id: string;
  dismiss?: (daysToExpiration?: number) => void;
  onClick: () => void;
}) {
  return (
    <AnalyticsRender name={ACTION_CENTRE} properties={{ id }}>
      <Banner icon="renewal" data-testid="renewal-notification-variable">
        <Heading3 as="h2">Protect yourself from price rises</Heading3>
        <P>
          You’re on our variable plan, so your rates could go up or down. Fancy
          something more worry-free? Our fixed rate energy plans lock your
          prices until your contract ends, offering you peace of mind in a
          changing market.
        </P>
        <RenewalBannerWarning />
        <AnalyticsClick name={ACTION_CENTRE} properties={{ id }}>
          <CTALink
            as={StyledLink}
            data-testid="renew-button"
            to={`${ROUTE_RENEWAL}?utm_source=${utmSource}`}
            variant="missionPrimary"
            onClick={onClick}
          >
            View fixed-rate plans
          </CTALink>
        </AnalyticsClick>
        <CTAButton
          as={SecondaryButton}
          variant="missionSecondary"
          onClick={() => {
            dismiss?.(14);
          }}
        >
          Remind me later
        </CTAButton>
      </Banner>
    </AnalyticsRender>
  );
}

export default VariableContractRenewal;
