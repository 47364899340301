import { useAccountContext, useCustomerContext } from '@ovotech/ui-tools';
import { useMutation, UseMutationResult } from 'react-query';
import { SMART_THERMOSTATS_ENDPOINT_V2 } from '@/src/constants/endpoints';
import { PRODUCT_IDS } from '@/src/constants/products';
import { apiService } from '@/src/services/api-service';
import { TadoPayUpfrontMutationResponse } from '@/src/types/Response';

interface TadoPayUpfrontOrderData {
  email: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  phone: string;
  promoCode?: string;
  versionId: string;
}

interface TadoPayUpfrontPayload extends TadoPayUpfrontOrderData {
  accountId: string;
  customerId: string;
  orderType: 'TadoProInstall' | 'TadoSelfInstall';
  paymentType: 'Monthly Bolt On' | 'Upfront Payment';
  productId: string;
}

type MutationKey = `orion-tado-pay-upfront-${string}`;

export const useTadoPayUpfrontMutation = (): UseMutationResult<
  TadoPayUpfrontMutationResponse,
  unknown,
  TadoPayUpfrontOrderData
> => {
  const { accountId } = useAccountContext();
  const { customerId } = useCustomerContext();

  return useMutation({
    mutationFn: (data: TadoPayUpfrontOrderData) => {
      const body: TadoPayUpfrontPayload = {
        ...data,
        accountId: accountId ?? '',
        customerId: customerId ?? '',
        orderType: 'TadoSelfInstall', // not currently offering pro install option
        paymentType: 'Upfront Payment', // this hook is for upfront payment, not pay monthly
        productId: PRODUCT_IDS.smartThermostatsPayUpfrontSelfInstall,
      };

      return apiService.post<TadoPayUpfrontMutationResponse>({
        url: `${SMART_THERMOSTATS_ENDPOINT_V2}/orders/intent`,
        body,
      });
    },
    mutationKey: `orion-tado-pay-upfront-${accountId}` as MutationKey,
  });
};
