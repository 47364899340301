import styled from 'styled-components';

export const Terms = styled.div`
  ${({ theme: { space } }) => `
    font-size: 12px;
    margin-top: ${space[3]};
    min-width: 150px;
    min-height: 5rem;
    margin-right: ${space[4]};
  `}
`;
