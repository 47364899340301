import { VisuallyHidden } from '@ovotech/nebula';
import React from 'react';
import {
  StyledDarkLogo,
  StyledHeaderWrapper,
  StyledLightLogo,
} from './RenewalHeader.styled';
import { Link } from '@/src/components';
import { StyledSkipLink } from '@/src/components/Navigation/Links/SkipLink.styled';
import { ROUTE_HOME } from '@/src/constants/routes';

function RenewalHeader() {
  return (
    <StyledHeaderWrapper>
      <StyledSkipLink to="#main">Skip to main content</StyledSkipLink>
      <Link aria-hidden="true" tabIndex={-1} to={ROUTE_HOME}>
        <StyledLightLogo />
        <StyledDarkLogo />
      </Link>
      <VisuallyHidden>OVO Energy</VisuallyHidden>
    </StyledHeaderWrapper>
  );
}

export default RenewalHeader;
