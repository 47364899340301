import { push } from 'connected-react-router';
import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable, AjaxResponse } from 'rxjs';
import routeManager from 'screen-reader-route-manager';
import {
  makeActionError,
  makeActionSuccess,
  makeActionStart,
} from '@/src/redux/ducks/http';
import { EVS_INLIFE_ENDPOINT } from '@/src/constants/endpoints';
import { PRODUCT_IDS } from '@/src/constants/products';
import { ROUTE_EV_DISCONNECT_CONFIRMATION } from '@/src/constants/routes';
import apiService from '@/src/services/apiService';
import {
  Action,
  GetKaluzaSmartChargerMonthlyDataStart,
  GetKaluzaSmartChargerMonthlyDataSuccess,
  GetKaluzaSmartChargerMonthlyDataError,
  FETCH_ACTION_KEY,
  DisconnectKaluzaSmartChargerDataStart,
  DisconnectKaluzaSmartChargerDataSuccess,
  DisconnectKaluzaSmartChargerDataError,
  SendKaluzaAuthTokenStart,
  SendKaluzaAuthTokenSuccess,
  SendKaluzaAuthTokenError,
  SendKaluzaTokenStart,
  SendKaluzaTokenSuccess,
  SendKaluzaTokenError,
  ConnectionStatusToKaluzaSmartChargerStart,
  ConnectionStatusToKaluzaSmartChargerSuccess,
  ConnectionStatusToKaluzaSmartChargerError,
  GetVehicleDataFromKaluzaSmartChargerStart,
  GetVehicleDataFromKaluzaSmartChargerSuccess,
  GetVehicleDataFromKaluzaSmartChargerError,
  GetSavingsAndInsightDataFromKaluzaSmartChargerStart,
  GetSavingsAndInsightDataFromKaluzaSmartChargerSuccess,
  GetSavingsAndInsightDataFromKaluzaSmartChargerError,
  GetAnytimeSavingsDataFromKaluzaSmartChargerError,
  GetAnytimeSavingsDataFromKaluzaSmartChargerStart,
  GetAnytimeSavingsDataFromKaluzaSmartChargerSuccess,
} from '@/src/types/Action';
import {
  EVInlifeInfoState,
  EVsInlifeDataMonthlyResponse,
  EVsInlifeVehicleDataResponse,
  EVsInlifeSavingsAndInsightDataResponse,
  State,
  EVsInlifeAnytimeSavingsDataResponse,
} from '@/src/types/State';

/* send Kaluza authcode and refreshToken to EV Backend */

export const sendKaluzaAuthTokenStart = (refreshToken: string) => ({
  type: 'SEND_KALUZA_AUTH_TOKEN_START',
  refreshToken,
});

export const sendKaluzaAuthTokenSuccess =
  makeActionSuccess<SendKaluzaAuthTokenSuccess>(() => ({
    type: 'SEND_KALUZA_AUTH_TOKEN_SUCCESS',
  }));

export const sendKaluzaAuthTokenError =
  makeActionError<SendKaluzaAuthTokenError>(() => ({
    type: 'SEND_KALUZA_AUTH_TOKEN_ERROR',
  }));

const sendKaluzaAuthTokenAPIData = (token: string, accountId: string) => {
  const url = `${EVS_INLIFE_ENDPOINT}/account/${accountId}/kaluza/token`;
  const body = {
    refresh_token: token,
  };
  return apiService
    .post({
      responseType: 'json',
      url,
      body,
    })
    .map((resp: AjaxResponse) => {
      return resp;
    });
};

const sendKaluzaAuthTokenData = (token: string, accountId: string) => {
  return sendKaluzaAuthTokenAPIData(token, accountId).map(res => res);
};

export const sendKaluzaAuthTokenDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('SEND_KALUZA_AUTH_TOKEN_START')
    .mergeMap(
      ({ [FETCH_ACTION_KEY]: id, refreshToken }: SendKaluzaAuthTokenStart) => {
        const accountId = store.getState().user.selectedAccountId;
        if (!accountId) {
          return Observable.of(
            sendKaluzaAuthTokenError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return sendKaluzaAuthTokenData(refreshToken, accountId)
          .map(() => {
            return sendKaluzaAuthTokenSuccess(id);
          })
          .catch(err => {
            return Observable.of(sendKaluzaAuthTokenError(id, err));
          });
      },
    );
};

/* send Kaluza token to EV Backend */

export const sendKaluzaTokenStart = (token: string) => ({
  type: 'SEND_KALUZA_TOKEN_START',
  token,
});

export const sendKaluzaTokenSuccess = makeActionSuccess<SendKaluzaTokenSuccess>(
  () => ({
    type: 'SEND_KALUZA_TOKEN_SUCCESS',
    connection: true,
  }),
);

export const sendKaluzaTokenError = makeActionError<SendKaluzaTokenError>(
  () => ({
    type: 'SEND_KALUZA_TOKEN_ERROR',
  }),
);

export const sendKaluzaTokenDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('SEND_KALUZA_TOKEN_START')
    .switchMap(({ [FETCH_ACTION_KEY]: id, token }: SendKaluzaTokenStart) => {
      const accountId = store.getState().user.selectedAccountId;
      if (!accountId) {
        return Observable.of(
          sendKaluzaTokenError(
            id,
            // @ts-ignore
            { response: 'No account id set' },
          ),
        );
      }
      const url = `${EVS_INLIFE_ENDPOINT}/account/${accountId}/kaluza/token`;

      return apiService
        .post({
          responseType: 'json',
          url: url,
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            auth_code: token,
            oauth_version: 'V3',
          },
        })
        .map(resp => {
          if (resp.status === 204) {
            return sendKaluzaTokenSuccess(id);
          } else {
            throw Error('Not successfully stored in DB');
          }
        })
        .catch(err => {
          return Observable.of(sendKaluzaTokenError(id, err));
        });
    });
};

/* get Kaluza Smart charger monthly data */
export const generateUrl = (
  accountId: string,
  year: string,
  month: string,
  anytimeStartDate: string | undefined,
): string => {
  let url = EVS_INLIFE_ENDPOINT;
  url += '/account';
  url += `/${accountId}`;
  url += '/usage';
  url += '/year';
  url += `/${year}`;
  url += '/month';
  url += `/${month}`;
  url += `${
    anytimeStartDate
      ? `?anytimeStartDate=${anytimeStartDate.split('T')[0]}`
      : ''
  }`;
  return url;
};

const getKaluzaSmartChargerAPIMonthlyData = (
  accountId: string,
  year: string,
  month: string,
  anytimeStartDate: string | undefined,
): Observable<EVsInlifeDataMonthlyResponse> => {
  const url = generateUrl(accountId, year, month, anytimeStartDate);
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

const getKaluzaSmartChargerMonthlyData = (
  accountId: string,
  year: string,
  month: string,
  anytimeStartDate: string | undefined,
): Observable<EVsInlifeDataMonthlyResponse> => {
  return getKaluzaSmartChargerAPIMonthlyData(
    accountId,
    year,
    month,
    anytimeStartDate,
  ).map(res => res);
};

export const getKaluzaSmartChargerMonthlyDataStart = (
  year: string,
  month: string,
): GetKaluzaSmartChargerMonthlyDataStart => ({
  type: 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_START',
  isFetching: true,
  year,
  month,
});

export const getKaluzaSmartChargerMonthlyDataSuccess = makeActionSuccess<
  GetKaluzaSmartChargerMonthlyDataSuccess,
  [EVsInlifeDataMonthlyResponse | null]
>((monthlyData: EVsInlifeDataMonthlyResponse | null) => {
  return {
    type: 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_SUCCESS',
    monthlyData,
  };
});

export const getKaluzaSmartChargerMonthlyDataError =
  makeActionError<GetKaluzaSmartChargerMonthlyDataError>(() => ({
    type: 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_ERROR',
  }));

export const getKaluzaSmartChargerMonthlyDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store<State>,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_START')
    .mergeMap(
      ({
        [FETCH_ACTION_KEY]: id,
        year,
        month,
      }: GetKaluzaSmartChargerMonthlyDataStart) => {
        const accountId = store.getState().user.selectedAccountId;
        if (!accountId) {
          return Observable.of(
            getKaluzaSmartChargerMonthlyDataError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        const products = store.getState().products;
        const anytimeBolton = products.activated.data
          ? products.activated.data.boltons.find(bolton => {
              return bolton.productId === PRODUCT_IDS.anytime;
            })
          : undefined;

        const anytimeStartDate = anytimeBolton?.activeFrom;

        return getKaluzaSmartChargerMonthlyData(
          accountId,
          year,
          month,
          anytimeStartDate,
        )
          .map(data => {
            return getKaluzaSmartChargerMonthlyDataSuccess(id, data);
          })
          .catch(err => {
            return Observable.of(
              getKaluzaSmartChargerMonthlyDataError(id, err?.response),
            );
          });
      },
    );
};

// disconnect to Kaluza Smart charger

export const disconnectKaluzaSmartChargerDataStart =
  makeActionStart<DisconnectKaluzaSmartChargerDataStart>(() => ({
    type: 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_START',
  }));

export const disconnectKaluzaSmartChargerDataSuccess =
  (): DisconnectKaluzaSmartChargerDataSuccess => ({
    type: 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_SUCCESS',
  });

export const disconnectKaluzaSmartChargerDataError =
  makeActionError<DisconnectKaluzaSmartChargerDataError>(() => ({
    type: 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_ERROR',
  }));

const disconnectKaluzaSmartChargerAPIData = (accountId: string) => {
  const url = `${EVS_INLIFE_ENDPOINT}/account/${accountId}/kaluza/token`;
  return apiService
    .delete({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

const disconnectKaluzaSmartChargerData = (accountId: string) => {
  return disconnectKaluzaSmartChargerAPIData(accountId).map(res => res);
};

export const disconnectKaluzaSmartChargerDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store<State>,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('DISCONNECT_KALUZA_SMART_CHARGER_DATA_START')
    .mergeMap(
      ({ [FETCH_ACTION_KEY]: id }: DisconnectKaluzaSmartChargerDataStart) => {
        const accountId = store.getState().user.selectedAccountId;
        if (!accountId) {
          return Observable.of(
            disconnectKaluzaSmartChargerDataError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        const products = store.getState().products;

        const anyTimeBundle = products.catalogue.data
          ? products.catalogue.data.boltons.find(bundle => {
              return bundle.productId === PRODUCT_IDS.anytime;
            })
          : undefined;

        const anytimeBundleVersion = anyTimeBundle?.versionId;

        return disconnectKaluzaSmartChargerData(accountId)
          .mergeMap(() => {
            routeManager.beforeRouteChange();
            if (anytimeBundleVersion) {
              return Observable.of(disconnectKaluzaSmartChargerDataSuccess());
            } else {
              return Observable.of(
                disconnectKaluzaSmartChargerDataSuccess(),
                // @ts-ignore Observable.of typedef forces all items to be of the same type, they're not
                push(ROUTE_EV_DISCONNECT_CONFIRMATION),
              );
            }
          })
          .catch(err => {
            return Observable.of(
              disconnectKaluzaSmartChargerDataError(id, err),
            );
          });
      },
    );
};

// check if connected to Kaluza Smart charger

export const connectionStatusToKaluzaSmartChargerStart =
  makeActionStart<ConnectionStatusToKaluzaSmartChargerStart>(() => ({
    type: 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_START',
  }));

export const connectionStatusToKaluzaSmartChargerSuccess = makeActionSuccess<
  ConnectionStatusToKaluzaSmartChargerSuccess,
  [boolean]
>((connection: boolean) => {
  return {
    type: 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_SUCCESS',
    connection,
  };
});

export const connectionStatusToKaluzaSmartChargerError =
  makeActionError<ConnectionStatusToKaluzaSmartChargerError>(() => ({
    type: 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_ERROR',
    connection: false,
  }));

const connectionStatusToKaluzaSmartChargerAPIData = (accountId: string) => {
  const url = `${EVS_INLIFE_ENDPOINT}/account/${accountId}/kaluza/status`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp;
    });
};

export const connectionStatusToKaluzaSmartChargerDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_START')
    .mergeMap(
      ({
        [FETCH_ACTION_KEY]: id,
      }: ConnectionStatusToKaluzaSmartChargerStart) => {
        const accountId = store.getState().user.selectedAccountId;
        if (!accountId) {
          return Observable.of(
            connectionStatusToKaluzaSmartChargerError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return connectionStatusToKaluzaSmartChargerAPIData(accountId)
          .map(resp => {
            return connectionStatusToKaluzaSmartChargerSuccess(
              id,
              resp.response.connected,
            );
          })
          .catch(err =>
            Observable.of(connectionStatusToKaluzaSmartChargerError(id, err)),
          );
      },
    );
};

// get vehicle data from Kaluza

export const getVehicleDataFromKaluzaSmartChargerStart =
  makeActionStart<GetVehicleDataFromKaluzaSmartChargerStart>(() => ({
    type: 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_START',
  }));

export const getVehicleDataFromKaluzaSmartChargerSuccess = makeActionSuccess<
  GetVehicleDataFromKaluzaSmartChargerSuccess,
  [EVsInlifeVehicleDataResponse | null]
>((vehicleData: EVsInlifeVehicleDataResponse | null) => {
  return {
    type: 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS',
    vehicleData,
  };
});

export const getVehicleDataFromKaluzaSmartChargerError =
  makeActionError<GetVehicleDataFromKaluzaSmartChargerError>(() => ({
    type: 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_ERROR',
  }));

const getVehicleDataFromKaluzaSmartChargerAPIData = (accountId: string) => {
  const url = `${EVS_INLIFE_ENDPOINT}/account/${accountId}/vehicle`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

const getVehicleDataFromKaluzaSmartChargerData = (accountId: string) => {
  return getVehicleDataFromKaluzaSmartChargerAPIData(accountId).map(res => res);
};

export const getVehicleDataFromKaluzaSmartChargerDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_START')
    .mergeMap(
      ({
        [FETCH_ACTION_KEY]: id,
      }: GetVehicleDataFromKaluzaSmartChargerStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getVehicleDataFromKaluzaSmartChargerError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return getVehicleDataFromKaluzaSmartChargerData(accountId)
          .map(data => {
            return getVehicleDataFromKaluzaSmartChargerSuccess(id, data);
          })
          .catch(err => {
            return Observable.of(
              getVehicleDataFromKaluzaSmartChargerError(id, err),
            );
          });
      },
    );
};

// get savings and insight data from Kaluza

export const getSavingsAndInsightDataFromKaluzaSmartChargerStart =
  (): GetSavingsAndInsightDataFromKaluzaSmartChargerStart => ({
    type: 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_START',
    isFetching: true,
  });

export const getSavingsAndInsightDataFromKaluzaSmartChargerSuccess =
  makeActionSuccess<
    GetSavingsAndInsightDataFromKaluzaSmartChargerSuccess,
    [EVsInlifeSavingsAndInsightDataResponse | null]
  >((savingsAndInsight: EVsInlifeSavingsAndInsightDataResponse | null) => {
    return {
      type: 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS',
      savingsAndInsight,
    };
  });

export const getSavingsAndInsightDataFromKaluzaSmartChargerError =
  makeActionError<GetSavingsAndInsightDataFromKaluzaSmartChargerError>(() => ({
    type: 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_ERROR',
  }));

const getSavingsAndInsightDataFromKaluzaSmartChargerAPIData = (
  accountId: string,
) => {
  const url = `${EVS_INLIFE_ENDPOINT}/account/${accountId}/savingsandinsights`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

const getSavingsAndInsightDataFromKaluzaSmartChargerData = (
  accountId: string,
) => {
  return getSavingsAndInsightDataFromKaluzaSmartChargerAPIData(accountId).map(
    res => res,
  );
};

export const getSavingsAndInsightDataFromKaluzaSmartChargerDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_START')
    .mergeMap(
      ({
        [FETCH_ACTION_KEY]: id,
      }: GetSavingsAndInsightDataFromKaluzaSmartChargerStart) => {
        const accountId = store.getState().user.selectedAccountId;
        if (!accountId) {
          return Observable.of(
            getSavingsAndInsightDataFromKaluzaSmartChargerError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        return getSavingsAndInsightDataFromKaluzaSmartChargerData(accountId)
          .map(data => {
            return getSavingsAndInsightDataFromKaluzaSmartChargerSuccess(
              id,
              data,
            );
          })
          .catch(err => {
            return Observable.of(
              getSavingsAndInsightDataFromKaluzaSmartChargerError(id, err),
            );
          });
      },
    );
};

// get anytime savings data from Kaluza

export const generateAnytimeUrl = (
  accountId: string,
  year: string,
  month: string,
  anytimeStartDate: string,
): string => {
  let url = EVS_INLIFE_ENDPOINT;
  url += '/account';
  url += `/${accountId}`;
  url += '/anytime';
  url += '/year';
  url += `/${year}`;
  url += '/month';
  url += `/${month}`;
  url += `?anytimeStartDate=${anytimeStartDate.split('T')[0]}`;
  return url;
};

export const getAnytimeSavingsDataFromKaluzaSmartChargerStart = (
  year: string,
  month: string,
): GetAnytimeSavingsDataFromKaluzaSmartChargerStart => ({
  type: 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_START',
  isFetching: true,
  year,
  month,
});

export const getAnytimeSavingsDataFromKaluzaSmartChargerSuccess =
  makeActionSuccess<
    GetAnytimeSavingsDataFromKaluzaSmartChargerSuccess,
    [EVsInlifeAnytimeSavingsDataResponse | null]
  >((anytimeSavings: EVsInlifeAnytimeSavingsDataResponse | null) => {
    return {
      type: 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS',
      anytimeSavings,
    };
  });

export const getAnytimeSavingsDataFromKaluzaSmartChargerError =
  makeActionError<GetAnytimeSavingsDataFromKaluzaSmartChargerError>(() => ({
    type: 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_ERROR',
  }));

const getAnytimeSavingsDataFromKaluzaSmartChargerAPIData = (
  accountId: string,
  year: string,
  month: string,
  anytimeStartDate: string,
) => {
  const url = generateAnytimeUrl(accountId, year, month, anytimeStartDate);
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map((resp: AjaxResponse) => {
      return resp.response;
    });
};

const getAnytimeSavingsDataFromKaluzaSmartChargerData = (
  accountId: string,
  year: string,
  month: string,
  anytimeStartDate: string,
) => {
  return getAnytimeSavingsDataFromKaluzaSmartChargerAPIData(
    accountId,
    year,
    month,
    anytimeStartDate,
  ).map(res => res);
};

export const getAnytimeSavingsDataFromKaluzaSmartChargerDataEpic = (
  action$: ActionsObservable<Action>,
  store: Store<State>,
) => {
  // @ts-ignore Typescript does not like action$.ofType
  return action$
    .ofType('GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_START')
    .mergeMap(
      ({
        [FETCH_ACTION_KEY]: id,
        year,
        month,
      }: GetAnytimeSavingsDataFromKaluzaSmartChargerStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (!accountId) {
          return Observable.of(
            getAnytimeSavingsDataFromKaluzaSmartChargerError(
              id,
              // @ts-ignore
              { response: 'No account id set' },
            ),
          );
        }
        const products = store.getState().products;
        const anytimeBolton = products.activated.data
          ? products.activated.data.boltons.find(bolton => {
              return bolton.productId === PRODUCT_IDS.anytime;
            })
          : undefined;

        const anytimeStartDate = anytimeBolton?.activeFrom;

        return getAnytimeSavingsDataFromKaluzaSmartChargerData(
          accountId!,
          year,
          month,
          anytimeStartDate!,
        )
          .map(data => {
            return getAnytimeSavingsDataFromKaluzaSmartChargerSuccess(id, data);
          })
          .catch(err => {
            return Observable.of(
              getAnytimeSavingsDataFromKaluzaSmartChargerError(id, err),
            );
          });
      },
    );
};

// reducer
export const defaultEVInlifeState: EVInlifeInfoState = {
  monthlyData: null,
  token: null,
  isFetching: false,
  isFetchingToken: false,
  isFetchingMonthly: false,
  isFetchingStatus: false,
  isFetchingVehicle: false,
  isFetchingSavingAndInsights: false,
  isFetchingAnytimeSavings: false,
  isFetchingDisconnect: false,
  isFetchingYearly: false,
  connection: null,
  vehicleData: null,
  savingsAndInsight: null,
  anytimeSavings: null,
  error: false,
  tokenError: false,
  monthlyDataError: false,
  statusError: false,
  yearlyDataError: false,
  vehicleDataError: false,
  savingAndInsightsDataError: false,
  anytimeSavingsDataError: false,
  disconnectError: false,
};

export const isAnyEvStateFetching = (state: EVInlifeInfoState) => {
  if (
    state.isFetchingToken ||
    state.isFetchingMonthly ||
    state.isFetchingStatus ||
    state.isFetchingVehicle ||
    state.isFetchingSavingAndInsights ||
    state.isFetchingAnytimeSavings ||
    state.isFetchingDisconnect ||
    state.isFetchingYearly
  ) {
    return true;
  } else {
    return false;
  }
};

const evInlife = (
  state: EVInlifeInfoState = defaultEVInlifeState,
  action: Action,
): EVInlifeInfoState => {
  switch (action.type) {
    case 'SEND_KALUZA_TOKEN_START':
      return {
        ...state,
        isFetchingToken: true,
        isFetching: isAnyEvStateFetching(state),
      };
    case 'SEND_KALUZA_TOKEN_SUCCESS':
      return {
        ...state,
        isFetchingToken: false,
        connection: true,
      };
    case 'SEND_KALUZA_TOKEN_ERROR':
      return {
        ...state,
        isFetchingToken: false,
        errorResponse: action.errorResponse,
        tokenError: true,
      };
    case 'SEND_KALUZA_AUTH_TOKEN_START':
      return {
        ...state,
        isFetchingToken: true,
        isFetching: isAnyEvStateFetching(state),
      };
    case 'SEND_KALUZA_AUTH_TOKEN_SUCCESS':
      return {
        ...state,
        isFetchingToken: false,
        connection: true,
      };
    case 'SEND_KALUZA_AUTH_TOKEN_ERROR':
      return {
        ...state,
        isFetchingToken: false,
        errorResponse: action.errorResponse,
        tokenError: true,
      };
    case 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_START':
      return {
        ...state,
        isFetchingMonthly: true,
      };
    case 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_SUCCESS':
      return {
        ...state,
        isFetchingMonthly: false,
        monthlyData: action.monthlyData,
      };
    case 'GET_KALUZA_SMART_CHARGER_MONTHLY_DATA_ERROR':
      return {
        ...state,
        isFetchingMonthly: false,
        errorResponse: action.errorResponse,
        monthlyDataError: true,
      };
    case 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_START':
      return {
        ...state,
        isFetchingStatus: true,
      };
    case 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_SUCCESS':
      return {
        ...state,
        isFetchingStatus: false,
        connection: action.connection,
      };
    case 'CONNECTION_STATUS_TO_KALUZA_SMART_CHARGER_ERROR':
      return {
        ...state,
        isFetchingStatus: false,
        errorResponse: action.errorResponse,
        statusError: true,
      };
    case 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_START':
      return {
        ...state,
        isFetchingVehicle: true,
      };
    case 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS':
      return {
        ...state,
        isFetchingVehicle: false,
        vehicleData: action.vehicleData,
      };
    case 'GET_VEHICLE_DATA_FROM_KALUZA_SMART_CHARGER_ERROR':
      return {
        ...state,
        isFetchingVehicle: false,
        errorResponse: action.errorResponse,
        vehicleDataError: true,
      };
    case 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_START':
      return {
        ...state,
        isFetchingSavingAndInsights: true,
      };
    case 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS':
      return {
        ...state,
        isFetchingSavingAndInsights: false,
        savingsAndInsight: action.savingsAndInsight,
      };
    case 'GET_SAVINGS_AND_INSIGHT_DATA_FROM_KALUZA_SMART_CHARGER_ERROR':
      return {
        ...state,
        isFetchingSavingAndInsights: false,
        errorResponse: action.errorResponse,
        savingAndInsightsDataError: true,
      };
    case 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_START':
      return {
        ...state,
        isFetchingAnytimeSavings: true,
      };
    case 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_SUCCESS':
      return {
        ...state,
        isFetchingAnytimeSavings: false,
        anytimeSavings: action.anytimeSavings,
      };
    case 'GET_ANYTIME_SAVINGS_DATA_FROM_KALUZA_SMART_CHARGER_ERROR':
      return {
        ...state,
        isFetchingAnytimeSavings: false,
        errorResponse: action.errorResponse,
        anytimeSavingsDataError: true,
      };
    case 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_START':
      return {
        ...state,
        isFetchingDisconnect: true,
      };
    case 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_SUCCESS':
      return {
        ...state,
        isFetchingDisconnect: false,
        connection: false,
      };
    case 'DISCONNECT_KALUZA_SMART_CHARGER_DATA_ERROR':
      return {
        ...state,
        isFetchingDisconnect: false,
        errorResponse: action.errorResponse,
        disconnectError: true,
      };
    default: {
      return state;
    }
  }
};

export default evInlife;
