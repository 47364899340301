import React, { useEffect } from 'react';
import { useContentfulActionCards } from './hooks/useContentfulActionCards';
import LoadingBanner from '../ActionCentre/Banners/Loading/LoadingBanner';
import { ActionCardBase } from '@/src/components/ActionCardBase/ActionCardBase';
import { getContentfulImageApiUrl } from './utils/getContentfulImageApiUrl';
import { useAnalytics } from '@ovotech/ui-tools';
import { CUSTOMER_ACTION_CARD_EVENTS } from '@/src/constants/analytics';
import { ServiceActionCodes } from './hooks/utils/rulesetForServiceBanners';

type Props = {
  cardsToShowByActionCode?: Array<string | ServiceActionCodes>;
  utmSource?: string;
};

export const ActionCard = ({ cardsToShowByActionCode, utmSource }: Props) => {
  const { isLoading, activeCard, reject, accept, present } =
    useContentfulActionCards(cardsToShowByActionCode);
  const { dispatch } = useAnalytics();

  const actionCode = activeCard?.actionCode;
  const contentfulCard = activeCard?.data;
  const FallbackComponent = activeCard?.fallbackComponent;
  const hasData = actionCode && (!!contentfulCard?.title || FallbackComponent);
  const isContentfulActionCard = !!contentfulCard?.title;

  useEffect(() => {
    if (!isLoading && hasData) {
      dispatch({
        name: CUSTOMER_ACTION_CARD_EVENTS.cardShown,
        type: 'view',
        properties: {
          cardName: activeCard.mixpanelId,
          contentfulActionCard: `${isContentfulActionCard}`,
        },
      });
      present(actionCode);
    }
    // dispatch is not stable across renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionCode,
    present,
    hasData,
    activeCard?.mixpanelId,
    isContentfulActionCard,
    isLoading,
  ]);

  const handleOnClickClose = () => {
    dispatch({
      name: CUSTOMER_ACTION_CARD_EVENTS.cardDismissed,
      type: 'click',
      properties: {
        cardName: activeCard?.mixpanelId,
        contentfulActionCard: `${isContentfulActionCard}`,
      },
    });
  };

  const handleOnClickCta = () => {
    dispatch({
      name: CUSTOMER_ACTION_CARD_EVENTS.cardTapped,
      type: 'click',
      properties: {
        cardName: activeCard?.mixpanelId,
        contentfulActionCard: `${isContentfulActionCard}`,
      },
    });
  };

  if (isLoading) return <LoadingBanner />;

  if (!hasData) return null;

  // Try render card from Contentful data first
  if (contentfulCard?.title) {
    const imgSrc = getContentfulImageApiUrl(
      activeCard?.data?.image?.url,
      activeCard?.data?.image?.width,
    );

    return (
      <ActionCardBase
        title={contentfulCard?.title}
        body={contentfulCard?.body ? <>{contentfulCard?.body}</> : undefined}
        inverted={!!contentfulCard?.inverted}
        inline={!contentfulCard?.nonRoundedEdges}
        imgSrc={imgSrc}
        onClickClose={
          contentfulCard?.canBeDismissed
            ? () => {
                reject(actionCode);
                handleOnClickClose();
              }
            : undefined
        }
        onClickCta={
          contentfulCard?.cta?.action
            ? () => {
                accept(actionCode);
                handleOnClickCta();
                contentfulCard?.cta?.action();
              }
            : undefined
        }
        ctaTitle={contentfulCard?.cta?.label || undefined}
      />
    );
  }

  // Fallback to hardcoded cards if no Contentful card
  if (FallbackComponent) {
    return (
      <FallbackComponent
        dismiss={() => {
          reject(actionCode, activeCard?.onDismiss);
          handleOnClickClose();
        }}
        onClick={() => {
          accept(actionCode, activeCard?.onDismiss);
          handleOnClickCta();
        }}
        utmSource={utmSource}
      />
    );
  }
};
