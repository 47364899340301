import {
  BankAccountForm,
  ErrorMessages,
  FormErrors,
  ValidatedForm,
} from '@/src/pages/MoveIn/types/form';

type FormType = BankAccountForm;
export function validateBankDetails(
  form: Partial<FormType>,
): ValidatedForm<FormType> {
  const errors: FormErrors<FormType> = {};

  if (!form.accountName) {
    errors.accountName = ErrorMessages.REQUIRED;
  } else if (!form.accountName.match(/^([^0-9]*)$/)) {
    errors.accountName = ErrorMessages.NO_NUMBERS;
  }

  if (!form.accountNumber) {
    errors.accountNumber = ErrorMessages.REQUIRED;
  } else if (!form.accountNumber.match(/^[0-9]*$/)) {
    errors.accountNumber = ErrorMessages.MAX_ACCOUNT_NUM;
  } else if (form.accountNumber.length !== 8) {
    errors.accountNumber = ErrorMessages.MAX_ACCOUNT_NUM;
  }

  if (!form.sortCode) {
    errors.sortCode = ErrorMessages.REQUIRED;
  } else if (!form.sortCode.match(/^(\d{2}-?\d{2}-?\d{2})$/)) {
    errors.sortCode = ErrorMessages.MAX_SORT_CODE;
  }

  if (!form.permission) {
    errors.permission = ErrorMessages.REQUIRED;
  }

  if (!form.accountIsOwn) {
    errors.accountIsOwn = ErrorMessages.REQUIRED;
  }

  return Object.keys(errors).length > 0
    ? {
        validation: 'error',
        errors,
      }
    : {
        validation: 'success',
        data: form as FormType,
      };
}
