import React, { PropsWithChildren } from 'react';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { optimizely } from '@/src/utils/optimizely';

import SiteLoading from '@/src/components/Wrapper/SiteLoading';
import { useCustomerIdQuery } from '@/src/api/utils/useCustomerIdQuery';
import { GlobalErrorPage } from '@/src/components';
import { BOOTSTRAP_ERROR } from '@/src/constants/error-section-ids';

export const WithOptimizely = ({ children }: PropsWithChildren) => {
  const { data: customerId, error, isLoading } = useCustomerIdQuery();

  if (isLoading) {
    return <SiteLoading data-testid="site-loading" />;
  }
  if (error || !customerId) {
    return <GlobalErrorPage id={BOOTSTRAP_ERROR} />;
  }
  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{ id: customerId ?? null, attributes: { customerId } }}
      timeout={500}
    >
      {children}
    </OptimizelyProvider>
  );
};
