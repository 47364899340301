/* ######################################
DEPRECATION WARNING
The use of RXJS is legacy at this point.
The default experiences have been moved over to using a combination of useQuery and QueryResultRenderer.
###################################### */

import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';

import {
  createProductActivateEndpoint,
  createProductCancelEndpoint,
  createProductCatalogueEndpoint,
  createProductsEndpoint,
} from '@/src/constants/endpoints';
import apiService from '@/src/services/apiService';
import {
  Action,
  ActivateProductError,
  ActivateProductStart,
  ActivateProductSuccess,
  CancelProductError,
  CancelProductStart,
  CancelProductSuccess,
  GetProductCatalogueError,
  GetProductCatalogueStart,
  GetProductCatalogueSuccess,
  GetProductsError,
  GetProductsStart,
  GetProductsSuccess,
  NoAction,
} from '@/src/types/Action';
import { AjaxError } from '@/src/types/Ajax';
import { ProductId, SignupSource } from '@/src/types/Products';
import { ProductCatalogue, ProductsResponse } from '@/src/types/Response';
import { Products } from '@/src/types/State';

/**
 * @deprecated use useProductsQuery instead
 * DO NOT USE THIS IN A HOOK UNDER ANY CIRCUMSTANCES
 */
export const getProductsStart = (): GetProductsStart => ({
  type: 'GET_PRODUCTS_START',
  isFetching: true,
});

export const getProductsSuccess = (
  response: ProductsResponse,
): GetProductsSuccess => ({
  type: 'GET_PRODUCTS_SUCCESS',
  isFetching: false,
  data: response,
});

export const getProductsError = ({
  response,
}: AjaxError | NoAction): GetProductsError => ({
  type: 'GET_PRODUCTS_ERROR',
  isFetching: false,
  errorResponse: response,
});

export const getProductCatalogueStart = (
  promoCode?: string,
): GetProductCatalogueStart => ({
  type: 'GET_PRODUCT_CATALOGUE_START',
  isFetching: true,
  promoCode,
});

export const getProductCatalogueSuccess = (
  response: ProductCatalogue,
): GetProductCatalogueSuccess => ({
  type: 'GET_PRODUCT_CATALOGUE_SUCCESS',
  isFetching: false,
  data: response,
});

export const getProductCatalogueError = ({
  response,
}: AjaxError | NoAction): GetProductCatalogueError => ({
  type: 'GET_PRODUCT_CATALOGUE_ERROR',
  isFetching: false,
  errorResponse: response,
});

export const activateProductStart = (
  productId: ProductId,
  versionId: string,
  promoCode?: string,
  displayName?: string,
  options?: Partial<{ cost: number }>,
  source?: SignupSource,
  additionalData?: {
    promo: string;
  },
): ActivateProductStart => ({
  type: 'ACTIVATE_PRODUCT_START',
  isFetching: true,
  productId,
  versionId,
  promoCode,
  displayName,
  options,
  source,
  additionalData,
});

export const activateProductSuccess = (
  productId: ProductId,
  displayName: string,
): ActivateProductSuccess => ({
  type: 'ACTIVATE_PRODUCT_SUCCESS',
  isFetching: false,
  productId,
  displayName,
  meta: {
    analytics: {
      event: `PAYM - Completed upgrade to a ${displayName}`,
      props: {
        productId,
      },
    },
  },
});

export const activateProductError = (
  { response }: AjaxError,
  productId: ProductId,
  displayName: string,
): ActivateProductError => ({
  type: 'ACTIVATE_PRODUCT_ERROR',
  isFetching: false,
  errorResponse: response,
  productId,
  displayName,
});

export const cancelProductStart = (
  productId: ProductId,
): CancelProductStart => ({
  type: 'CANCEL_PRODUCT_START',
  isFetching: true,
  productId,
});

export const cancelProductSuccess = (
  productId: ProductId,
): CancelProductSuccess => ({
  type: 'CANCEL_PRODUCT_SUCCESS',
  isFetching: false,
  productId,
});

export const cancelProductError = (
  { response }: AjaxError,
  productId: ProductId,
): CancelProductError => ({
  type: 'CANCEL_PRODUCT_ERROR',
  isFetching: false,
  errorResponse: response,
  productId,
});

export const defaultProductsState: Products = {
  activated: { isFetching: false },
  catalogue: { isFetching: false },
  cancel: null,
  activate: null,
};

export const getProductsEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  return action$.ofType('GET_PRODUCTS_START').exhaustMap(() => {
    const accountId = store.getState().user.selectedAccountId;
    // accountId must be set otherwise we get stuck in a refresh loop
    // (endpoint returns 401, causes error with multiple accounts)
    // check inside this action otherwise race condition occurs
    if (accountId) {
      return apiService
        .get({
          responseType: 'json',
          withCredentials: true,
          url: createProductsEndpoint(accountId),
        })
        .map(({ response }) => getProductsSuccess(response))
        .catch(err => Observable.of(getProductsError(err)));
    } else {
      return Observable.of(
        getProductsError({ response: 'No account selected' }),
      );
    }
  });
};

export const getProductCatalogueEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  action$
    .ofType('GET_PRODUCT_CATALOGUE_START')
    // @ts-ignore not able to follow narrowing down of type through ofType
    .concatMap(({ promoCode }) => {
      const accountId = store.getState().user.selectedAccountId;
      if (accountId) {
        return apiService
          .get({
            responseType: 'json',
            withCredentials: true,
            url: createProductCatalogueEndpoint(
              store.getState().user.selectedAccountId!,
              promoCode,
            ),
          })
          .map(({ response }) => getProductCatalogueSuccess(response))
          .catch(err => {
            const error = {
              ...err,
              response: err.response
                ? err.response
                : {
                    response: {
                      statusCode: err.status,
                      message: err.message,
                    },
                  },
            };
            return Observable.of(getProductCatalogueError(error));
          });
      } else {
        return Observable.of(
          getProductCatalogueError({ response: 'No account selected' }),
        );
      }
    });

export const activateProductEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  action$.ofType('ACTIVATE_PRODUCT_START').switchMap(
    (
      // We cannot ts-ignore a block so we need to prevent prettier from wrapping the object
      // prettier-ignore
      // @ts-ignore not able to follow narrowing down of type through ofType
      { productId, versionId, promoCode, displayName, options, source, additionalData },
    ) => {
      const {
        user: { selectedAccountId },
      } = store.getState();
      if (!selectedAccountId) {
        return Observable.of(
          activateProductError(
            // @ts-ignore
            { response: 'No account id set' },
            productId,
            displayName,
          ),
        );
      }
      return apiService
        .post({
          responseType: 'json',
          withCredentials: true,
          url: createProductActivateEndpoint(selectedAccountId),
          body: {
            ...(promoCode ? { promoCode } : {}),
            ...(source ? { source } : {}),
            products: [
              {
                versionId,
                ...options,
                additionalData,
              },
            ],
          },
        })
        .map(() => {
          return activateProductSuccess(productId, displayName);
        })
        .catch(err =>
          Observable.of(activateProductError(err, productId, displayName)),
        );
    },
  );

export const activateProductSuccessEpic = (
  action$: ActionsObservable<Action>,
) =>
  action$
    .ofType('ACTIVATE_PRODUCT_SUCCESS')
    .switchMap(() => Observable.of(getProductsStart()));

export const cancelProductEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  action$
    .ofType('CANCEL_PRODUCT_START')
    // @ts-ignore not able to follow narrowing down of type through ofType
    .switchMap(({ productId }) => {
      const {
        user: { selectedAccountId },
      } = store.getState();
      if (!selectedAccountId) {
        return Observable.of(
          // @ts-ignore
          cancelProductError({ response: 'No account id set' }, productId),
        );
      }
      return apiService
        .delete({
          responseType: 'json',
          withCredentials: true,
          url: createProductCancelEndpoint(selectedAccountId, productId),
        })
        .map(() => {
          return cancelProductSuccess(productId);
        })
        .catch(err => Observable.of(cancelProductError(err, productId)));
    });

const products = (
  state: Products = defaultProductsState,
  action: Action,
): Products => {
  switch (action.type) {
    case 'GET_PRODUCTS_START': {
      return {
        ...state,
        activated: {
          ...state.activated,
          isFetching: action.isFetching,
        },
      };
    }
    case 'GET_PRODUCTS_SUCCESS': {
      const { isFetching, data } = action;
      return {
        ...state,
        activated: {
          isFetching,
          data,
        },
      };
    }
    case 'GET_PRODUCTS_ERROR': {
      return {
        ...state,
        activated: {
          ...state.activated,
          isFetching: action.isFetching,
          errorResponse: {
            statusCode:
              action?.errorResponse && 'statusCode' in action.errorResponse
                ? action.errorResponse?.statusCode
                : 400,
            message:
              action?.errorResponse && 'message' in action.errorResponse
                ? action.errorResponse?.message
                : 'An unknown error occurred',
          },
        },
      };
    }
    case 'GET_PRODUCT_CATALOGUE_START': {
      return {
        ...state,
        catalogue: {
          ...state.catalogue,
          isFetching: action.isFetching,
        },
      };
    }
    case 'GET_PRODUCT_CATALOGUE_SUCCESS': {
      const { isFetching, data } = action;
      return {
        ...state,
        catalogue: {
          isFetching,
          data,
        },
      };
    }
    case 'GET_PRODUCT_CATALOGUE_ERROR': {
      return {
        ...state,
        catalogue: {
          ...state.catalogue,
          isFetching: action.isFetching,
          errorResponse: action.errorResponse,
        },
      };
    }
    case 'ACTIVATE_PRODUCT_START': {
      const { isFetching, productId } = action;
      return {
        ...state,
        activate: {
          [productId]: {
            isUpdating: isFetching,
            error: false,
            success: false,
          },
        },
      };
    }
    case 'ACTIVATE_PRODUCT_SUCCESS': {
      const { isFetching, productId } = action;
      return {
        ...state,
        activate: {
          [productId]: {
            isUpdating: isFetching,
            error: false,
            success: true,
          },
        },
      };
    }
    case 'ACTIVATE_PRODUCT_ERROR': {
      const { isFetching, productId } = action;
      return {
        ...state,
        activate: {
          [productId]: {
            isUpdating: isFetching,
            error: true,
            success: false,
          },
        },
      };
    }
    case 'CANCEL_PRODUCT_START': {
      const { isFetching, productId } = action;
      return {
        ...state,
        cancel: {
          [productId]: {
            isUpdating: isFetching,
            error: false,
            success: false,
          },
        },
      };
    }
    case 'CANCEL_PRODUCT_SUCCESS': {
      const { isFetching, productId } = action;
      return {
        ...state,
        cancel: {
          [productId]: {
            isUpdating: isFetching,
            error: false,
            success: true,
          },
        },
        activated: {
          ...state.activated,
          data: {
            boltons: state.activated.data
              ? state.activated.data.boltons.filter(
                  b => b.productId !== productId,
                )
              : [],
            bundle:
              state.activated.data &&
              state.activated.data.bundle &&
              state.activated.data.bundle.productId !== productId
                ? state.activated.data.bundle
                : undefined,
          },
        },
        activate: null,
      };
    }
    case 'CANCEL_PRODUCT_ERROR': {
      const { isFetching, productId } = action;
      return {
        ...state,
        cancel: {
          [productId]: {
            isUpdating: isFetching,
            error: true,
            success: false,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default products;
