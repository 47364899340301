import {
  Card,
  Heading3,
  Margin,
  P,
  Small,
  Strong,
  TextLink,
} from '@ovotech/nebula';
import { AvailablePlan } from '@monovo/energy-renewals';
import { format } from 'date-fns';
import React from 'react';

import { DATE_FORMAT } from '../../shared';
import {
  CardCol,
  CardContent,
  CardContentTriangleFiller,
  CardContentWrapper,
  CardHeader,
  CardHeaderDescription,
  CardHeaderMask,
  CardHeaderTitleH4,
  CardRow,
  DisplayOnlyWeb,
  Divider,
  FooterNote,
  PrimarySpacer,
  StyledCard,
} from '../Card.styled';
import HomeplanBundleBanner from '../HomeplanBundleBanner';
import TariffHeading from './components/TariffHeading';
import { TariffInfo } from './components/TariffInfo';
import { sumAnnualConsumptions } from '@/src/utils';
import { formatCurrencyValue } from '@/src/utils/money';

interface Props {
  plan: AvailablePlan;
}

const PlanInfoCard = ({ plan }: Props) => {
  const tariff1 = plan.tariffs[0];
  const tariff2 = plan.tariffs[1];

  const isVariable = plan.type === 'variable';

  const planLength = isVariable ? 'N/A' : `${plan.lengthInMonths} months`;

  const planDescription = `${plan.subtitle} ${
    isVariable ? `(with ${plan.paymentMethod} payments)` : ''
  }`;

  const isFixAndProtect = plan.name.includes('2 Year Fix And Protect');

  return (
    <StyledCard as={Card} data-testid="eoc-plan-info-card">
      <CardHeader dark>
        <Margin left={6} right={24} top={6} bottom={4}>
          <Heading3 as={CardHeaderTitleH4}>{plan.title} plan</Heading3>
          <P as={CardHeaderDescription}>{planDescription}</P>
        </Margin>
        <CardHeaderMask />
      </CardHeader>
      <CardContentWrapper>
        <CardContent>
          <PrimarySpacer inverted />
          <Margin horizontal={6} top={6}>
            <TariffHeading tariff1={tariff1} tariff2={tariff2} />
            <CardRow>
              <CardCol>
                <P>
                  <Small>Supplier:</Small>
                </P>
              </CardCol>
              <CardCol>
                <P>
                  <Small>
                    <Strong>OVO Energy</Strong>
                  </Small>
                </P>
              </CardCol>
              {tariff2 ? (
                <CardCol>
                  <P>
                    <Small>
                      <Strong>OVO Energy</Strong>
                    </Small>
                  </P>
                </CardCol>
              ) : null}
            </CardRow>
            <Divider secondary />

            <CardRow>
              <CardCol>
                <P>
                  <Small>Plan name:</Small>
                </P>
              </CardCol>
              <CardCol>
                <P>
                  <Small>
                    <Strong>{plan.name}</Strong>
                  </Small>
                </P>
              </CardCol>
              {tariff2 ? (
                <CardCol>
                  <P>
                    <Small>
                      <Strong>{plan.name}</Strong>
                    </Small>
                  </P>
                </CardCol>
              ) : null}
            </CardRow>

            <Divider secondary />
            {plan.type ? (
              <CardRow>
                <CardCol>
                  <P>
                    <Small>Plan type:</Small>
                  </P>
                </CardCol>
                <CardCol>
                  <P style={{ textTransform: 'capitalize' }}>
                    <Small>
                      <Strong>{plan.type}</Strong>
                    </Small>
                  </P>
                </CardCol>
                {tariff2 ? (
                  <CardCol>
                    <P style={{ textTransform: 'capitalize' }}>
                      <Small>
                        <Strong>{plan.type}</Strong>
                      </Small>
                    </P>
                  </CardCol>
                ) : null}
              </CardRow>
            ) : null}

            <Divider secondary />
            <CardRow>
              <CardCol>
                <P>
                  <Small>Plan length:</Small>
                </P>
              </CardCol>
              <CardCol>
                <P>
                  <Small>
                    <Strong>{planLength}</Strong>
                  </Small>
                </P>
              </CardCol>
              {tariff2 ? (
                <CardCol>
                  <P>
                    <Small>
                      <Strong>{planLength}</Strong>
                    </Small>
                  </P>
                </CardCol>
              ) : null}
            </CardRow>

            <Divider secondary />
            <CardRow>
              <CardCol>
                <P>
                  <Small>Plan end date:</Small>
                </P>
              </CardCol>
              <CardCol>
                <P>
                  <Small>
                    <Strong>
                      {plan.tariffs[0] && plan.tariffs[0].endDate
                        ? format(plan.tariffs[0].endDate, DATE_FORMAT)
                        : 'N/A'}
                    </Strong>
                  </Small>
                </P>
              </CardCol>
              {tariff2 ? (
                <CardCol>
                  <P>
                    <Small>
                      <Strong>
                        {plan.tariffs[1] && plan.tariffs[1].endDate
                          ? format(plan.tariffs[1].endDate, DATE_FORMAT)
                          : 'N/A'}
                      </Strong>
                    </Small>
                  </P>
                </CardCol>
              ) : null}
            </CardRow>

            <Divider secondary />

            {(tariff1 && tariff1.exitFee) || (tariff2 && tariff2.exitFee) ? (
              <CardRow>
                <CardCol>
                  <P>
                    <Small>Exit fee:</Small>
                  </P>
                </CardCol>
                <CardCol>
                  <P data-testid="plan-info-card-exit-fee">
                    <Small>
                      <Strong>
                        {tariff1 && tariff1.exitFee
                          ? `${formatCurrencyValue(
                              { unit: 'GBP', dp: 0 },
                              tariff1.exitFee,
                            )}`
                          : `No exit fees`}
                      </Strong>
                    </Small>
                  </P>
                </CardCol>
                {tariff2 ? (
                  <CardCol>
                    <P data-testid="plan-info-card-exit-fee">
                      <Small>
                        <Strong>
                          {tariff2 && tariff2.exitFee
                            ? `${formatCurrencyValue(
                                { unit: 'GBP', dp: 0 },
                                tariff2.exitFee,
                              )}`
                            : `No exit fees`}
                        </Strong>
                      </Small>
                    </P>
                  </CardCol>
                ) : null}
              </CardRow>
            ) : null}
            <Divider secondary />
            <CardRow>
              <CardCol>
                <P>
                  <Small>Payment method:</Small>
                </P>
              </CardCol>
              <CardCol>
                <P>
                  <Small>
                    <Strong>{plan.paymentMethod}</Strong>
                  </Small>
                </P>
              </CardCol>
              {tariff2 ? (
                <CardCol>
                  <P>
                    <Small>
                      <Strong>{plan.paymentMethod}</Strong>
                    </Small>
                  </P>
                </CardCol>
              ) : null}
            </CardRow>

            <Divider secondary />

            <TariffInfo tariffs={plan.tariffs} />

            <Margin vertical={4}>
              <Divider />
            </Margin>
            <CardRow>
              <CardCol>
                <P>
                  <Small>Estimated yearly use:</Small>
                </P>
              </CardCol>
              <CardCol>
                <P>
                  <Small>
                    <Strong>
                      {sumAnnualConsumptions(
                        tariff1.estimatedAnnualConsumptions,
                      )}{' '}
                      kWh
                    </Strong>
                  </Small>
                </P>
              </CardCol>
              {tariff2 ? (
                <CardCol>
                  <P>
                    <Small>
                      <Strong>
                        {sumAnnualConsumptions(
                          tariff2.estimatedAnnualConsumptions,
                        )}{' '}
                        kWh
                      </Strong>
                    </Small>
                  </P>
                </CardCol>
              ) : null}
            </CardRow>
            <CardRow>
              <CardCol>
                <P>
                  <Small>Estimated yearly cost:</Small>
                </P>
              </CardCol>
              <CardCol>
                <P>
                  <Small>
                    <Strong>£{tariff1.estimatedAnnualCost.amount}</Strong>
                  </Small>
                </P>
              </CardCol>
              {tariff2 ? (
                <CardCol>
                  <P>
                    <Small>
                      <Strong>£{tariff2.estimatedAnnualCost.amount}</Strong>
                    </Small>
                  </P>
                </CardCol>
              ) : null}
            </CardRow>

            <Margin top={6} bottom={4}>
              {isFixAndProtect ? (
                <>
                  <Divider bold />
                  <HomeplanBundleBanner />
                  <Divider bold />
                </>
              ) : (
                <Divider />
              )}
            </Margin>
            <Margin bottom={6}>
              <P as={FooterNote}>
                All prices include VAT (currently 5% for domestic customers).
              </P>
              <P as={FooterNote}>
                Wherever possible we use your previous energy use to calculate
                these figures. If it's not available due to an error or missing
                meter reads, we look at the energy use of a{' '}
                <TextLink
                  opensInNewWindow
                  href="https://www.ofgem.gov.uk/gas/retail-market/monitoring-data-and-statistics/typical-domestic-consumption-values"
                >
                  typical energy consumer in the UK
                </TextLink>
                . This means, your actual energy use and cost might be
                different.
              </P>
            </Margin>
          </Margin>
        </CardContent>
        {!tariff2 && (
          <DisplayOnlyWeb>
            <CardContentTriangleFiller />
          </DisplayOnlyWeb>
        )}
      </CardContentWrapper>
    </StyledCard>
  );
};

export default PlanInfoCard;
