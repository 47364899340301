import { Card, Col, Row } from '@ovotech/nebula';
import React from 'react';
import { LoadingBannerWrapper } from './LoadingBanner.styled';
import { SkeletonCTA } from '@/src/components/SkeletonLoading/SkeletonCTA';
import { SkeletonCircle } from '@/src/components/SkeletonLoading/SkeletonCircle';
import { SkeletonHeading } from '@/src/components/SkeletonLoading/SkeletonHeading';
import { SkeletonText } from '@/src/components/SkeletonLoading/SkeletonText';

const LoadingBanner = () => (
  <LoadingBannerWrapper data-testid="loading-banner" as={Card}>
    <Row isNested>
      <Col span={9}>
        <SkeletonHeading />
        <SkeletonText lines={2} />
        <SkeletonCTA />
      </Col>
      <Col span={3}>
        <SkeletonCircle />
      </Col>
    </Row>
  </LoadingBannerWrapper>
);

export default LoadingBanner;
