import React from 'react';
import styled from 'styled-components';
import { ReactComponent as GreenerArrowDown } from '@/src/resources/greener-arrow-down.svg';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { space, fontWeights, fonts, colors } }) => `
    font-family: ${fonts.body};
    font-weight: ${fontWeights.semiBold};
    padding: ${space[4]};
    color: ${colors.canvas};
`}
`;

export const WhatIsIncluded = () => {
  return (
    <StyledWrapper>
      What's included?&nbsp;
      <GreenerArrowDown />
    </StyledWrapper>
  );
};
