import {
  FETCH_ACTION_KEY,
  FetchError,
  FetchStart,
  FetchSuccess,
  WithFetchAction,
} from '@/src/types/Action';
import { AjaxError } from '@/src/types/Ajax';
import { Omit } from '@/src/types/utils';

export function makeActionStart<R extends FetchStart, A extends any[] = []>(
  fn: (...args: A) => Omit<R, typeof FETCH_ACTION_KEY | 'isFetching'>,
): (...args: A) => WithFetchAction & R {
  return (...args) =>
    ({
      isFetching: true,
      ...fn(...args),
    } as R);
}

export const makeActionError =
  <R extends FetchError, A extends Array<any> = []>(
    fn: (
      ...args: A
    ) => Omit<
      R,
      typeof FETCH_ACTION_KEY | 'isFetching' | 'errorResponse' | 'error'
    >,
  ): ((
    id: undefined | string,
    error: AjaxError,
    ...args: A
  ) => FetchError & R) =>
  (id: undefined | string, error: AjaxError, ...args) =>
    ({
      [FETCH_ACTION_KEY]: id,
      isFetching: false,
      errorResponse: error.response || null,
      error,
      ...fn(...args),
    } as R);

export const makeActionSuccess =
  <R extends FetchSuccess, A extends any[] = []>(
    fn: (...args: A) => Omit<R, typeof FETCH_ACTION_KEY | 'isFetching'>,
  ): ((id: undefined | string, ...args: A) => FetchSuccess & R) =>
  (id: undefined | string, ...args) =>
    ({
      [FETCH_ACTION_KEY]: id,
      isFetching: false,
      ...fn(...args),
    } as R);
