import styled from 'styled-components';
import { mqDesktopNav } from '@/src/utils/mediaQuery';

export const SideNavigationWrapper = styled.div`
  ${({ theme: { space } }) => `
    padding-top: ${space[8]};
    padding-bottom: ${space[8]};
  `}
`;

export const NavAwareCol = styled.div<{ desktopNav?: number }>(
  ({ desktopNav }) => {
    const desktopNavPercentage = desktopNav ? (desktopNav / 12) * 100 : null;
    return desktopNavPercentage
      ? mqDesktopNav(`
          flex-basis: ${desktopNavPercentage}%;
          max-width: ${desktopNavPercentage}%;
        `)
      : null;
  },
);
