import { Link } from '@/src/components';
import { Card, Col } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  border: solid ${({ theme }) => theme.colors.brand.grey.light};
  display: flex;
  justify-content: center;
`;

interface PartnerCardProps {
  image: string;
  imageAlt: string;
  testId?: string;
  partnerURL: string;
  mixpanelEventName?: string;
}

export const PartnerCard = ({
  image,
  imageAlt,
  testId,
  partnerURL,
  mixpanelEventName,
}: PartnerCardProps) => {
  const partnerLogo = (
    <Link data-testid="partner-logo" to={partnerURL}>
      <img alt={imageAlt} src={image} />
    </Link>
  );

  return (
    <Col small={6} style={{ paddingLeft: 0 }}>
      <StyledCard data-testid={testId ?? ''}>
        {mixpanelEventName ? (
          <AnalyticsClick name={mixpanelEventName}>
            {partnerLogo}
          </AnalyticsClick>
        ) : (
          partnerLogo
        )}
      </StyledCard>
    </Col>
  );
};
