import React from 'react';
import styled from 'styled-components';

const nodeSize = 30;

export const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InactiveNode = styled.div`
  width: ${nodeSize}px;
  height: ${nodeSize}px;
  background-color: #d1d6de;
  border-color: #d1d6de;
  border-radius: 50%;
  color: #2f3749;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActiveNode = styled(InactiveNode)`
  background-color: white;
  border-color: #0d8426;
  color: #0d8426;
`;

const InactiveBar = styled.div<{ numberOfPages: number }>`
  height: 5px;
  background-color: #d1d6de;
  width: ${({ numberOfPages }) =>
    `calc(${100 / (numberOfPages - 1)}% - ${
      nodeSize + nodeSize / (numberOfPages - 1)
    }px)`};
`;

const ActiveBar = styled(InactiveBar)`
  background-color: white;
`;

export function Node({
  activePage,
  numberOfPages,
  pageNumber,
}: {
  activePage: number;
  numberOfPages: number;
  pageNumber: number;
}) {
  const Node = pageNumber === activePage ? ActiveNode : InactiveNode;
  const nodeIndex = pageNumber - 1;

  return (
    <>
      {pageNumber === 1 ? null : pageNumber === activePage ? (
        <ActiveBar
          numberOfPages={numberOfPages}
          data-testid={'progress-bar-node-active-' + nodeIndex}
        />
      ) : (
        <InactiveBar
          numberOfPages={numberOfPages}
          data-testid={'progress-bar-node-inactive-' + nodeIndex}
        />
      )}
      <Node>
        <p data-testid={'progress-bar-node-' + nodeIndex}>{pageNumber}</p>
      </Node>
    </>
  );
}
