import styled, { css } from 'styled-components';

export const StyledArrowButton = styled.button<{
  direction: 'right' | 'left';
  size: number;
  margin?: string;
}>`
  ${({ direction, size, margin, theme: { colors, focusRing } }) => `
    border-radius: 50%;
    min-width: ${size}px;
    width: ${size}px;
    height: ${size}px;
    display: inline;
    transform: rotateY(${direction === 'left' ? '180deg' : '0deg'});
    box-shadow: 0 0 2px 2px rgba(47, 55, 73, 0.2);
    background-color: ${colors.brand.grey.lightest};
    transition: 0.3s ease;
    transition-property: box-shadow;
    padding: 4px;
    border: none;
    margin: ${margin};
    
    &:hover {
      cursor: pointer;
      box-shadow: 0 0px 5px 2px rgba(47, 55, 73, 0.2);
    }


    &:focus {
      ${css(focusRing)};
    }

    &:disabled {
      cursor: not-allowed;
    }
  `}
`;

export const Chevron = styled.svg<{ disabled?: boolean }>`
  ${({ disabled, theme: { colors } }) => `
    path {
      transition: 0.3s ease;
      transition-property: stroke, fill;
      stroke: ${disabled ? '#D1D6DE' : colors.body};
      fill: ${disabled ? '#D1D6DE' : colors.body};
    }

    &:hover {
      path {
        stroke: ${disabled ? '#D1D6DE' : colors.focus}; // TODO: check color
        fill: ${disabled ? '#D1D6DE' : colors.focus}; // TODO: check color
      }
    }
  `}
`;
