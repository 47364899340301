import { createSelector } from 'reselect';
import { State } from '@/src/types/State';

const appSelector = ({ app }: State) => app;
export const tariffCostsSelector = ({ tariffCosts }: State) => tariffCosts;

export const hasSmartMeter = createSelector(
  appSelector,
  app =>
    app.gasSupply?.supplyPointInfo?.isSmart ||
    app.elecSupply?.supplyPointInfo?.isSmart ||
    false,
);

export const hasSMETS2Meters = createSelector(
  appSelector,
  app =>
    app.gasSupply?.supplyPointInfo?.hasSmets2Meter ||
    app.elecSupply?.supplyPointInfo?.hasSmets2Meter ||
    false,
);
