import { Margin, Card, Strong, TextLink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';

import { Link } from 'react-router-dom-v5';
import { EVHeaderCard } from '../components/EVHeaderCard/EVHeaderCard';
import { EVNews } from '../components/EVNews/EVNews';
import { EVOffers } from '../components/EVOffers/EVOffers';
import { Feedback } from '../components/Feedback/Feedback';
import { PageWithNavigation, ScrollToTopOnMount } from '@/src/components';
import { EV } from '@monovo/ev-anytime';
import { ROUTE_EV, ROUTE_EV_DISCONNECT } from '@/src/constants/routes';
import { useChargeAnytimeBoltonState } from '../../AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

const EV_PRODUCTS_SURVEY = 'ev-products';

export const EVsInlifePageNewsAndOffers = () => {
  const { anytimeBoltonActive } = useChargeAnytimeBoltonState();

  return (
    <PageWithNavigation
      analytics={{ name: EV.INLIFE.EVS_INLIFE_NEWS }}
      title="Your EV - Monthly"
    >
      <>
        <ScrollToTopOnMount />
        <EVHeaderCard
          title="EV news and offers"
          description="Get all the latest info about EVs and find out how you can save more with our amazing offers."
          linkText="< Back to Your EV hub"
          linkUrl={ROUTE_EV}
        />
        <Margin top={8}>
          <EVOffers />
          <Margin bottom={11} top={11}>
            <Feedback surveyId={EV_PRODUCTS_SURVEY}>
              Interested in getting more EV products? Just tell us which.
            </Feedback>
          </Margin>
          <EVNews />
          {!anytimeBoltonActive && (
            <AnalyticsClick name={EV.INLIFE.EVS_INLIFE_DISCONNECT_LINK_CLICKED}>
              <Margin top={3} bottom={3}>
                <Card>
                  <Strong>Don’t want to get EV insights anymore?</Strong> No
                  problem. Visit{' '}
                  <TextLink
                    to={ROUTE_EV_DISCONNECT}
                    as={Link}
                    data-testid="disconnect-smart-charger"
                  >
                    this page
                  </TextLink>{' '}
                  and we’ll sort it.
                </Card>
              </Margin>
            </AnalyticsClick>
          )}
        </Margin>
      </>
    </PageWithNavigation>
  );
};
