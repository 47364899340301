import styled from 'styled-components';
import { mqSmallDown, mqSmallUp } from '@/src/utils/mediaQuery';

export const StyledIconLabel = styled.div<{ alignCentre?: boolean }>`
  display: flex;
  align-items: center;
  ${({ alignCentre }) => `
    ${alignCentre ? 'justify-content: center;' : ''}
  `}
`;

export const StyledIconWrapper = styled.div`
  ${({ theme: { space } }) => `
    margin-right: ${space[2]};
    line-height: 0;
    ${mqSmallDown(`
      width: ${space[4]};
    `)}
    svg {
      max-height: 32px; /* IE11 SVG height bug fix */
    }
  `}
`;

export const StyledIconText = styled.div`
  ${({ theme: { colors, fontSizes, fontWeights } }) => `
    font-size: ${fontSizes[3]};
    font-weight: ${fontWeights.bold};
    color: ${colors.brand.grey.darker};
    ${mqSmallUp(`
      font-size: ${fontSizes[6]};
    `)}
  `}
`;
