import {
  Heading4,
  P,
  PrimaryCTALink,
  Strong,
  VisuallyHidden,
} from '@ovotech/nebula';

import React from 'react';
import { Link } from 'react-router-dom-v5';
import { carbonIntensityAssets } from '../../../CarbonIntensity/Components/CurrentIntensity/CurrentIntensity.assets';
import { hubTestIDs as testIDs } from '../../testIDs';
import {
  StyledNoMarginH2,
  StyledNoMarginP,
} from '../CarbonComparison/CarbonComparison.styled';
import {
  StyledCard,
  StyledColourBackground,
  StyledCTAContainer,
  StyledCurrentIntensityIndicator,
  StyledLowMarginP,
  StyledCarbonIntensityWidget,
} from './CarbonIntensityWidget.styled';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { GridContainer } from '@/src/pages/EnergyInsights/components';
import {
  CarbonIntensityLevel,
  NextGreenTimes,
} from '@/src/pages/EnergyInsights/types';
import AnalyticsService from '@/src/services/analyticsService';

type Props = {
  currentIntensity?: CarbonIntensityLevel;
  greentime?: NextGreenTimes | null;
};

const CarbonIntensityWidget = ({ currentIntensity, greentime }: Props) => {
  if (!currentIntensity) {
    return null;
  }

  const { topText, icon, indicatorBadge } =
    carbonIntensityAssets[currentIntensity];

  return (
    <GridContainer spanMedium={6} spanLarge={6} includeRow={false}>
      <StyledCarbonIntensityWidget data-testid={testIDs.carbonIntensityWidget}>
        <StyledNoMarginH2>Power up at greener times</StyledNoMarginH2>
        <StyledNoMarginP>
          Find out when the energy grid is producing the most planet-friendly
          power.
        </StyledNoMarginP>
        <StyledCard>
          <StyledColourBackground>
            <Heading4>{topText}</Heading4>
            {icon}
            <StyledCurrentIntensityIndicator>
              <StyledLowMarginP>
                Carbon Intensity on the grid is
                <VisuallyHidden>&nbsp;{currentIntensity}</VisuallyHidden>
              </StyledLowMarginP>
              {indicatorBadge}
            </StyledCurrentIntensityIndicator>
            {greentime?.start ? (
              <P>
                Next low carbon time will be <Strong>{greentime.day}</Strong> at{' '}
                <Strong>{greentime.start}</Strong>
              </P>
            ) : null}
            <StyledCTAContainer>
              <PrimaryCTALink
                as={Link}
                to={ROUTE_ENERGY_INSIGHTS.CARBON_INTENSITY}
                onClick={() => {
                  AnalyticsService.dispatch(
                    ENERGY_INSIGHTS.BUTTON_CLICK_HUB_NAVIGATE_TO_CARBON_INTENSITY,
                  );
                }}
              >
                See 48 hrs carbon forecast
              </PrimaryCTALink>
            </StyledCTAContainer>
          </StyledColourBackground>
        </StyledCard>
      </StyledCarbonIntensityWidget>
    </GridContainer>
  );
};

export default CarbonIntensityWidget;
