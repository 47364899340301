import {
  ProfileEditEmail,
  ProfileEditPhone,
  ProfileEditMarketing,
} from '@ovotech/energy-cx';
import EditMeterReadFrequency from '../EditMeterReadsFrequency/EditMeterReadsFrequency';
import React from 'react';
import { Route, Redirect } from 'react-router-dom-v5';

import {
  SwitchWithNotFound,
  PageWithNavigationAndSectionPadding,
  KapiFeature,
} from '@/src/components';
import {
  ROUTE_PROFILE,
  ROUTE_EDIT_PROFILE_PHONE,
  ROUTE_EDIT_PROFILE_LEGACY,
  ROUTE_EDIT_PROFILE_EMAIL,
  ROUTE_EDIT_PROFILE_MARKETING,
  ROUTE_EDIT_METER_READ_FREQUENCY,
  ROUTE_OUR_PARTNERS,
  ROUTE_PARTNERS_TESCO,
} from '@/src/constants/routes';
import { OurPartners } from '../OurPartners/OurPartners';
import { ProfileStack } from './ProfileStack';
import { PartnersTesco } from '../OurPartners/PartnersTesco';

const Profile = () => (
  <SwitchWithNotFound>
    <Route
      exact
      path={ROUTE_PROFILE}
      component={() => {
        return (
          <PageWithNavigationAndSectionPadding title="Profile">
            <ProfileStack />
          </PageWithNavigationAndSectionPadding>
        );
      }}
    />
    <Redirect
      exact
      from={ROUTE_EDIT_PROFILE_LEGACY}
      to={ROUTE_EDIT_PROFILE_EMAIL}
    />
    <Route
      exact
      path={ROUTE_EDIT_PROFILE_EMAIL}
      component={() => (
        <PageWithNavigationAndSectionPadding title="Edit email">
          <KapiFeature>
            <ProfileEditEmail />
          </KapiFeature>
        </PageWithNavigationAndSectionPadding>
      )}
    />
    <Route
      exact
      path={ROUTE_EDIT_PROFILE_PHONE}
      component={() => (
        <PageWithNavigationAndSectionPadding title="Edit phone">
          <KapiFeature>
            <ProfileEditPhone />
          </KapiFeature>
        </PageWithNavigationAndSectionPadding>
      )}
    />
    <Route
      exact
      path={ROUTE_EDIT_PROFILE_MARKETING}
      component={() => (
        <PageWithNavigationAndSectionPadding title="Edit marketing preferences">
          <KapiFeature>
            <ProfileEditMarketing />
          </KapiFeature>
        </PageWithNavigationAndSectionPadding>
      )}
    />
    <Route
      exact
      path={ROUTE_EDIT_METER_READ_FREQUENCY}
      component={() => (
        <PageWithNavigationAndSectionPadding title="Edit meter reads frequency">
          <EditMeterReadFrequency />
        </PageWithNavigationAndSectionPadding>
      )}
    />
    <Route
      exact
      path={ROUTE_OUR_PARTNERS}
      component={() => (
        <PageWithNavigationAndSectionPadding title="Our partners">
          <OurPartners />
        </PageWithNavigationAndSectionPadding>
      )}
    />
    <Route
      exact
      path={ROUTE_PARTNERS_TESCO}
      component={() => (
        <PageWithNavigationAndSectionPadding title="Our partners">
          <PartnersTesco />
        </PageWithNavigationAndSectionPadding>
      )}
    />
  </SwitchWithNotFound>
);

export { Profile };
