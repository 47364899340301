import { TableRow, TableCell, Strong, Heading3, P } from '@ovotech/nebula';
import styled from 'styled-components';

export const CardWithRightIcon = styled.div<{ image: any }>`
  ${({ theme: { mediaQueries }, image }) => `
    ${mediaQueries.mediumAndUp} {
       position: relative;
       &:after {
        content: '';
        position: absolute;
        top: -5px;
        right: -2px;
        width: 85px;
        height: 80px;
        display: block;
        background: transparent no-repeat center center;
        background-image: url(${image});
      }
    }
  `};
`;

export const CardWithRightIconTime = styled.div<{ image: any }>`
  ${({ theme: { mediaQueries }, image }) => `
    ${mediaQueries.mediumAndUp} {
       position: relative;
       &:after {
        content: '';
        position: absolute;
        top: -3px;
        right: -2px;
        width: 100px;
        height: 80px;
        display: block;
        background: transparent no-repeat center center;
        background-image: url(${image});
      }
    }
  `};
`;

export const StyledTableRow = styled(TableRow)`
  ${({ theme: { mediaQueries } }) => `
      display: grid;
      background-color: transparent;
      line-height: 3rem;
    ${mediaQueries.mediumAndUp} {
        background-color: transparent;
        display: table-row;
        line-height: 3rem;
      }
    }
  `};
`;

export const StyledTableCell = styled(TableCell)<{
  width: string;
}>`
  ${({ theme: { mediaQueries }, width }) => `
      border-bottom: none;
      padding: 0 1.2rem 0 0;
      margin: 0 4px;
      width: 100%;
      position: relative;
    ${mediaQueries.mediumAndUp} {
      border-bottom: none;
      padding: 0 1.2rem 0 0;
      margin: 0;
      width: ${width};
      }
    }
  `};
`;

export const Overlay = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.borderMuted};
  width: 100%;
  top: 0;
  bottom: 0;
  left: 4px;
  right: 0;
  height: 2rem;
`;
export const BarChart = styled.div<{ color: string; amount: number }>`
  width: ${({ amount }) => amount}%;
  height: 2rem;
  background: ${({ color }) => color};
  transition: width 2s ease;
  position: relative;
`;

export const StyledBarOverlayContainer = styled.div`
  top: 4px;
  position: relative;
  padding-left: 4px;
`;

export const StyledStrong = styled(Strong)`
  ${({ theme: { mediaQueries } }) => `
      margin-left: 0;
    ${mediaQueries.mediumAndUp} {
       margin-left: 16px;
      }
    }
  `};
`;

export const StyledP = styled.p`
  margin-top: 0;
`;

export const StyledHeading3 = styled.h3`
  margin-bottom: 0;
`;

export const StyledEllipse = styled.span<{ color: string }>`
  ${({ color, theme: { mediaQueries } }) => `
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${color};
    align-self: center;
    margin-right: 4px;
    display: inline-block;
    ${mediaQueries.mediumAndUp} {
      margin-left: 24px;
    }
  `};
`;

export const StyledHr = styled.div`
  ${({ theme: { mediaQueries } }) => `
      display: block;
      border-top: 1px solid rgba(17, 17, 17, 0.5);
      opacity: 0.5;
      margin: 4px 0;
    ${mediaQueries.mediumAndUp} {
      display: none;
      }
    }
  `};
`;

export const StyledBr = styled.br`
  ${({ theme: { mediaQueries } }) => `
      display: none;
    ${mediaQueries.mediumAndUp} {
      display: block;
      }
    }
  `};
`;

export const StyledDiv = styled.div`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;

  ${mediaQueries.mediumAndUp} {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: space-between;
    }
  `};
`;

export const StyledHeading2 = styled.h2`
  margin-bottom: 0px;
`;

export const StyledCentered = styled.div`
  text-align: center;
`;

export const StyledInlineFlex = styled.div`
  display: inline-flex;
  margin-top: 3rem;
`;

export const StyledHeadingContainer = styled.div`
  align-self: center;
  margin-left: 4px;
`;

export const StyledCTAContainer = styled.div`
  align-self: center;
  ${({ theme: { mediaQueries } }) => `
    align-self: center;
    margin-top: 3rem;

  ${mediaQueries.mediumAndUp} {
    align-self: center;
    margin-top: 3rem;
    }
  `};
`;

export const StyledMarginHeading3 = styled(Heading3)`
  margin-bottom: 0.6rem !important;
`;

export const StyledMarginP = styled(P)`
  margin-bottom: 0.6rem !important;
  margin-top: 0 !important;
`;
