import { P } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const StyledTadoThermostatImageWrapper = styled.div`
  position: relative;
  width: 40%;
  width: 300px;
  flex-shrink: 0;
  margin: 0 0 0 2rem;

  ${mqMediumDown(`
    width: 18rem;
  `)};
`;

export const StyledTadoThermostatImage = styled.img`
  z-index: 1;
  width: 100px;
  height: 100px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
`;

export const SuccessHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
`;

export const StyledPageContainer = styled.div`
  margin: 2rem 0 0 0;

  & p:first-of-type {
    margin: 0;
  }

  & .checkout__heading-wrapper {
    margin: 0 0 1rem 0;

    & h1 {
      margin: 0;
    }

    & .checkout__image-wrapper {
      ${mqMediumDown(`
        display: none;
      `)}

      display: block;
      margin: -2rem 0 0 1rem;
    }
  }

  & .checkout__subtitle {
    color: '#ffffff';
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const StyledParagraph = styled(P)`
  color: #ffffff;
  font-weight: 400;
  font-family: "'Montserrat', sans-serif;";
`;
