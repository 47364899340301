import { useQuery } from 'react-query';
import { carbonZeroBffEndpoints } from '@/src/constants/endpoints';
import { CarbonZeroNavigationResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

type Params = {
  accountId?: string | null;
  enabled?: boolean;
  onSuccess?: (data: CarbonZeroNavigationResponse) => void;
  onError?: (error: Error) => void;
};

export const useCarbonZeroNavigationQuery = ({
  accountId,
  enabled = true,
  onSuccess,
  onError,
}: Params) => {
  return useQuery(
    ['carbon-zero-web-navigation', accountId],
    () =>
      !accountId
        ? null
        : apiService.get<CarbonZeroNavigationResponse>({
            url: carbonZeroBffEndpoints.getWebNavigation(accountId),
          }),
    {
      enabled: enabled && !!accountId,
      onSuccess,
      onError,
    },
  );
};
