import React from 'react';
import { StyledBottomButtonBar } from '../Tip.styled';
import {
  AlreadyDoingButton,
  NotForMeButton,
  WillDoButton,
} from './TipInteractionButton';
import {
  HandleUpdateTipInteractionStatus,
  TipInteractionStatus,
} from '@/src/pages/EnergyInsights/hooks/useGetTipInteractionsFromLocalStorage';

type Props = {
  handleClick: HandleUpdateTipInteractionStatus;
  tipInteractionTitle: string;
  tipInteractionStatus: TipInteractionStatus;
};

export const TipInteractionButtonBar = ({
  tipInteractionTitle,
  handleClick,
  tipInteractionStatus,
}: Props) => {
  const onClick = handleClick(tipInteractionTitle);
  return (
    <StyledBottomButtonBar>
      <NotForMeButton
        currentTipInteractionStatus={tipInteractionStatus}
        onClick={onClick}
      />
      <WillDoButton
        currentTipInteractionStatus={tipInteractionStatus}
        onClick={onClick}
      />
      <AlreadyDoingButton
        currentTipInteractionStatus={tipInteractionStatus}
        onClick={onClick}
      />
    </StyledBottomButtonBar>
  );
};
