import isAfter from 'date-fns/is_after';
import { RenewablePlan } from '@/src/types/RenewablePlans';

export type PlanWithEndDate = Pick<
  RenewablePlan,
  Exclude<keyof RenewablePlan, 'endDate'>
> & {
  endDate: string;
};

function getPlansWithEndDates(renewablePlans: Array<RenewablePlan>) {
  return renewablePlans.filter(
    ({ endDate }) => !!endDate,
  ) as Array<PlanWithEndDate>;
}

export function getPlanWithNearestEndDate(
  renewablePlans: Array<RenewablePlan>,
): PlanWithEndDate | undefined {
  const plansWithEndDate = getPlansWithEndDates(renewablePlans);

  return plansWithEndDate.sort((a, b) =>
    isAfter(a.endDate, b.endDate) ? 1 : -1,
  )[0];
}
