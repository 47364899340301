import React from 'react';
import { TileWithClippedImage } from '../../components/TileWithClippedImage/TileWithClippedImage';
import testIds from '../../testIds';

import { GREENER_ENERGY_CARBON_NEUTRAL_CARD } from '@/src/constants/analytics-subjects';
import { TRACEABLE_ELECTRICITY_LINK } from '@/src/constants/routes';
import NatureEnergy from '@/src/resources/small-nature-energy.png';

export const TraceableElectricityCard = () => {
  return (
    <TileWithClippedImage
      data-testid={testIds.features.traceableElectricity}
      title="Traceable electricity"
      description="We’ll make sure we buy enough renewable electricity from UK-based renewable generators through contracts called Power Purchase Agreements (PPAs), to match the amount of electricity you use each year."
      linkText="Find out more"
      linkIcon="chevron-right"
      linkUrl={TRACEABLE_ELECTRICITY_LINK}
      imgUrl={NatureEnergy}
      tracking={GREENER_ENERGY_CARBON_NEUTRAL_CARD}
    />
  );
};
