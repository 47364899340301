import { Heading3, P, Ul, Li, Strong } from '@ovotech/nebula';
import { AnalyticsRender, useAnalytics } from '@ovotech/ui-tools';
import React from 'react';
import Offer from './Offer/Offer';

import {
  HOMEPLAN_THREE_MONTHS_FREE_CARD,
  HOMEPLAN_SIX_MONTHS_FREE_CARD,
} from '@/src/constants/analytics-subjects';
import { createHomePlanUrl } from '@/src/constants/endpoints';
import image from '@/src/resources/offers/boiler-cover.jpg';
import { FootnoteReferenced } from '@/src/components';
import {
  footnoteOHSQ43monthsFreePromo,
  footnoteOHSQ43monthsMaxSavingsFreePromo,
  footnoteOHSQ4FlashSalePromo,
  footnoteOHSQ4BlackFridayPromo,
  footnoteOHSQ46monthsMaxSavingsFreePromo,
} from '../FooterTermsAndConditions';
import styled from 'styled-components';
import { mqMediumUp } from '@/src/utils/mediaQuery';

import {
  HOMEPLAN_Q42024_FLASH_SALE_OFFER,
  HOMEPLAN_Q42024_BLACK_FRIDAY_OFFER,
} from '@/src/constants/feature-flags';
import { useDecision } from '@/src/utils/optimizely';

type Props = {
  accountId: string;
};

const Roundel = styled.div`
  ${({ theme: { colors } }) => `
  width: 115px;
  height: 115px;
  position: absolute;
  font-weight: 700;
  right: 5%;
  top: -28%;
  line-height:16px;
  border-radius: 50%;
  background-color: ${colors.green.energised};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${colors.green.midnight};

 
    ${mqMediumUp(`
      
      top: -20%;
    `)}
  `}
`;

const FreeMonthNumber = styled.h2`
  font-size: 42px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
`;

const SmallLineHeight = styled.span`
  line-height: 4px;
`;

const FreeStong = styled(Strong)`
  font-weight: 900;
`;

export const HomeplanQ4Offer = ({ accountId }: Props) => {
  const analytics = useAnalytics();

  const [homeplanQ4PromoFlashSaleOfferDecision] = useDecision(
    HOMEPLAN_Q42024_FLASH_SALE_OFFER,
  );
  const [homeplanQ4PromoBlackFridayOfferDecision] = useDecision(
    HOMEPLAN_Q42024_BLACK_FRIDAY_OFFER,
  );

  // six months free boiler cover
  const sixMonthsFreeOfferEnabled =
    homeplanQ4PromoFlashSaleOfferDecision.enabled ||
    homeplanQ4PromoBlackFridayOfferDecision.enabled;

  const footnote = homeplanQ4PromoFlashSaleOfferDecision.enabled
    ? footnoteOHSQ4FlashSalePromo
    : homeplanQ4PromoBlackFridayOfferDecision.enabled
    ? footnoteOHSQ4BlackFridayPromo
    : footnoteOHSQ43monthsFreePromo;

  return (
    <AnalyticsRender
      name={
        sixMonthsFreeOfferEnabled
          ? HOMEPLAN_SIX_MONTHS_FREE_CARD
          : HOMEPLAN_THREE_MONTHS_FREE_CARD
      }
    >
      <Offer
        image={image}
        offerId="homePlanPromo"
        linkUrl={createHomePlanUrl('homePlanQ4Promo', accountId)}
        linkText="Get cover"
        tracking={() => {
          analytics.dispatch({
            name: sixMonthsFreeOfferEnabled
              ? HOMEPLAN_SIX_MONTHS_FREE_CARD
              : HOMEPLAN_THREE_MONTHS_FREE_CARD,
            type: 'click',
          });
        }}
      >
        <Heading3 as="h2" data-testid="q4-offer-heading">
          Get {sixMonthsFreeOfferEnabled ? 6 : 3} months free boiler cover
          <FootnoteReferenced content={footnote} />
        </Heading3>
        <Roundel>
          <FreeMonthNumber>{sixMonthsFreeOfferEnabled ? 6 : 3}</FreeMonthNumber>
          <span>
            months
            <br />
            <span>
              <FreeStong>free</FreeStong> boiler
            </span>
            <br />
            <SmallLineHeight>
              cover
              <FootnoteReferenced content={footnote} />
            </SmallLineHeight>
          </span>
        </Roundel>
        <P>
          We helped with over 120,000 repairs last year, so you know you can
          count on us. You could save up to £
          {sixMonthsFreeOfferEnabled ? 192 : 96}.
          <FootnoteReferenced
            content={
              sixMonthsFreeOfferEnabled
                ? footnoteOHSQ46monthsMaxSavingsFreePromo
                : footnoteOHSQ43monthsMaxSavingsFreePromo
            }
          />{' '}
          All our plans come with:
        </P>

        <Ul>
          <Li>An annual boiler service worth £84</Li>
          <Li>Unlimited callouts</Li>
          <Li>A 24/7 emergency helpline</Li>
          <Li>Access to a network of Gas Safe registered engineers</Li>
        </Ul>
      </Offer>
    </AnalyticsRender>
  );
};
