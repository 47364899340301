import { useDecision } from '@optimizely/react-sdk';
import { TextLink } from '@ovotech/nebula';
import { AnalyticsClick, useAccountContext } from '@ovotech/ui-tools';
import React, { useContext } from 'react';
import { NewNavItemBadge } from '../../Badges/NewNavItemBadge';
import { useContractsQuery } from '@/src/api';
import { StyledNavLink } from '@/src/components/Header/Header.styled';
import { StyledLinkContent } from '@/src/components/Navigation/Links/NavLink.styled';
import { HOMEPLAN_NAV_LINK as ANALYTICS_HOMEPLAN_NAV_LINK } from '@/src/constants/analytics-subjects';
import { HOMEPLAN_NAV_LINK } from '@/src/constants/feature-flags';
import { hasActiveContract } from '@/src/utils/contracts';
import { OptimizelyContext } from '@/src/utils/optimizely';
import { createHomePlanUrl } from '@/src/constants/endpoints';
import { useLocalStorage } from '@/src/utils/useLocalStorage';

export const HomePlanLink = () => {
  const { optimizely } = useContext(OptimizelyContext);
  const [newBadgeDismissed, setNewBadgeDismissed] = useLocalStorage(
    'new_homeplan_link_badge_dismissed',
    false,
  );
  const { accountId } = useAccountContext();
  const contracts = useContractsQuery(accountId);
  const [key] = useDecision(HOMEPLAN_NAV_LINK);

  const variation = key.variationKey;

  if (!optimizely || !variation || !contracts?.data || !accountId) {
    return null;
  }

  const hasGas = hasActiveContract(contracts.data.gas);

  return !hasGas ? null : (
    <li>
      <AnalyticsClick
        name={ANALYTICS_HOMEPLAN_NAV_LINK}
        properties={{
          variation,
        }}
      >
        <StyledNavLink
          as={TextLink}
          href={createHomePlanUrl(
            'boiler-cover',
            accountId,
            '&utm_medium=MyOVO&utm_source=boiler-cover',
          )}
          data-testid="homeplan-link"
          onClick={() => {
            optimizely.track('homeplan_navlink_click');
            setNewBadgeDismissed(true);
          }}
        >
          <StyledLinkContent>
            {!newBadgeDismissed && <NewNavItemBadge />}
            {variation === 'boiler' ? 'Boiler cover' : 'HomePlan'}
          </StyledLinkContent>
        </StyledNavLink>
      </AnalyticsClick>
    </li>
  );
};
