import { Icon, Margin, P, SecondaryCTAButton } from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView } from '@ovotech/ui-tools';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Styled } from './HomeProfileWidget.styled';
import testIDs from './testIDs';
import { HomeProfileModal } from '@/src/pages/EnergyInsights/components';
import { CARBON_ZERO } from '@/src/constants/analytics';
import {
  HomeProfileWidgetContent,
  HomeProfileWidgetContext,
} from './HomeProfileWidget.types';
import { HomeProfileWidgetResponse } from '@/src/types/Response';

export type HomeProfileWidgetProps = HomeProfileWidgetResponse &
  HomeProfileWidgetContent &
  HomeProfileWidgetContext;

export const HomeProfileWidgetView = ({
  answers,
  analyticsId,
  heading,
  body,
  onDismiss,
  events,
  cta,
  Image: PropsImage,
}: HomeProfileWidgetProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { colors } = useTheme();

  return (
    <>
      {modalOpen && (
        <HomeProfileModal
          homeProfile={answers}
          onClose={() => setModalOpen(false)}
        />
      )}
      <AnalyticsView name={events.viewed} properties={{ analyticsId }}>
        <Styled.Card data-testid={analyticsId}>
          {!!onDismiss && (
            <AnalyticsClick
              name={
                events.dismissed ||
                CARBON_ZERO.HOME_PROFILE_WIDGET.CLOSE_BUTTON_CLICKED
              }
              properties={{ analyticsId }}
            >
              <Styled.DismissButton
                data-testid={testIDs.closeButton}
                onClick={onDismiss}
                aria-label="Dismiss"
              >
                <Icon name="cross" color={colors.brand.midnight} size={16} />
              </Styled.DismissButton>
            </AnalyticsClick>
          )}
          <Styled.LeftContent>
            <Styled.Heading data-testid={testIDs.heading}>
              {heading}
            </Styled.Heading>

            <Margin bottom={4} right={4}>
              <P data-testid={testIDs.body}>{body}</P>
            </Margin>

            <AnalyticsClick
              name={events.ctaClicked}
              properties={{ analyticsId }}
            >
              <SecondaryCTAButton
                data-testid={testIDs.cta}
                fullWidth="never"
                onClick={() => setModalOpen(true)}
              >
                {cta}
              </SecondaryCTAButton>
            </AnalyticsClick>
          </Styled.LeftContent>
          <Styled.RightContent>
            <PropsImage />
          </Styled.RightContent>
        </Styled.Card>
      </AnalyticsView>
    </>
  );
};
