import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Margin,
} from '@ovotech/nebula';
import React, { FunctionComponent, SVGProps } from 'react';
import styled from 'styled-components';

const StyledIconHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > * {
    margin-bottom: 0;
  }
`;

type Props = {
  heading: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  level: 1 | 2 | 3 | 4;
};

const StyledNoMarginContainer = styled.div`
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }
`;

export const IconHeader = ({ heading, Icon, level }: Props) => {
  const headingLevels = {
    H1: Heading1,
    H2: Heading2,
    H3: Heading3,
    H4: Heading4,
  };
  const HeadingLevel = headingLevels[`H${level}` as keyof typeof headingLevels];
  return (
    <StyledIconHeader>
      <Margin right={3}>
        <Icon style={{ height: 16, width: 16 }} />
      </Margin>
      <StyledNoMarginContainer>
        <HeadingLevel>{heading}</HeadingLevel>
      </StyledNoMarginContainer>
    </StyledIconHeader>
  );
};
