import React, { ReactElement } from 'react';
import {
  Heading1,
  P,
  Card,
  Stack,
  Heading4,
  Heading3,
  SecondaryCTALink,
} from '@ovotech/nebula';
import styled from 'styled-components';

type PowerMoveBenefit = {
  icon: ReactElement;
  title: string;
  description: string;
};

export const StyledHeading1 = styled(Heading1)`
  color: ${({ theme }) => theme.colors.ovo};
  line-height: 1;
`;

export const StyledHeading3 = styled(Heading3)`
  color: ${({ theme }) => theme.colors.ovo};
  line-height: 1;
`;

const StyledHeading4 = styled(Heading4)`
  color: ${({ theme }) => theme.colors.ovo};
  font-weight: 600;
  line-height: 1;
  margin: 1rem 0;
`;

export const StyledSubtitle = styled(P)`
  color: ${({ theme }) => theme.colors.ovo};
  font-size: 22px;
`;

export const StyledTagline = styled(P)`
  font-size: 18px;
`;

export const StyledDescription = styled(P)`
  margin: 0 0 2rem 0 !important;
  max-inline-size: 350px;
`;

export const StyledStack = styled(Stack)`
  display: flex;

  & svg {
    margin: 0 0 0 1rem;
    width: 50px;
    height: 50px;

    @media (max-width: 575px) {
      width: 40px;
      height: 40px;
    }
  }
`;

const StyledPowerMoveBenefit = styled('div')`
  display: flex;
  gap: 0.75rem;

  & .power-move__benefit-icon {
    flex-shrink: 0;
  }

  & .power-move__benefit-text {
    @media (max-width: 575px) {
      margin: 0.4rem 0 0 0;
    }
  }
`;

export const StyledPowerMoveApps = styled('div')`
  & img {
    margin: 2rem 0 0 0;
    display: inline;
    visibility: visible;

    @media (min-width: 800px) {
      margin: 0;
      display: none;
      visibility: hidden;
    }
  }

  & .power__move__apps {
    display: flex;
    gap: 1.5rem;
    margin: 2rem 0 0 0;

    & .power-move__app {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > svg {
        display: none;
        visibility: hidden;

        @media (min-width: 800px) {
          display: inline;
          visibility: visible;
        }
      }

      & button {
        all: unset;
        cursor: pointer;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
`;

export const StyledCard = styled(Card)`
  position: relative;
`;

export const StyledImage = styled('img')`
  display: none;
  visibility: hidden;

  @media (min-width: 800px) {
    display: inline;
    visibility: visible;
    position: absolute;
    bottom: 32px;
    right: 32px;
  }
`;

export const StyledLoginToJoinButton = styled(SecondaryCTALink)`
  display: 'inline-flex';
  @media (min-width: 800px) {
    display: none;
  }
`;

export const PowerMoveBenefit = ({
  icon,
  title,
  description,
}: PowerMoveBenefit) => (
  <StyledPowerMoveBenefit>
    <div className="power-move__benefit-icon">{icon}</div>
    <div className="power-move__benefit-text">
      <StyledHeading4>{title}</StyledHeading4>
      <StyledDescription>{description}</StyledDescription>
    </div>
  </StyledPowerMoveBenefit>
);
