import { Heading2, P, SecondaryCTALink } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';

export const SmartMeterBookingBookingNowAvailable: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const { accountId } = useAccountContext();

  return (
    <Banner
      icon="smartMeter"
      dismiss={dismiss}
      data-testid="smb-booking-now-available"
    >
      <Heading2>We’ve got that smart meter appointment you wanted</Heading2>
      <P>
        Just stopping by to say we’ve got more slots. It’ll just take a couple
        of minutes to book.
      </P>
      <SecondaryCTALink
        href={`${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=banner&utm_medium=MyOVO&utm_campaign=smart-meter-booking`}
        data-testid="sm-booking-link"
        onClick={onClick}
      >
        Book your free appointment
      </SecondaryCTALink>
    </Banner>
  );
};
