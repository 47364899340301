import {
  AvailablePlan,
  PlanFamily,
  refineAvailablePlans,
} from '@monovo/energy-renewals';
import { useMemo } from 'react';
import { isP1P2ConversionRenewal } from '../selectors';

import { TARIFFS_FILTERING } from '@/src/constants/feature-flags';
import {
  DEFAULT_FILTERS,
  FilterType,
  isPlanInTariffs,
} from '@/src/utils/filter-tariffs';
import { AvailablePlansResponse } from '@/src/types/AvailablePlans';
import { RenewablePlansResponse } from '@/src/types/RenewablePlans';
import { ContractsResponse } from '@/src/types/Response';
import { useFeature } from '@/src/utils/optimizely';

export type AvailablePlansUsage = {
  legal: AvailablePlansBreakdown;
  selfService: AvailablePlansBreakdown;
};

export type AvailablePlansBreakdown = {
  hero: AvailablePlan[];
  variable: AvailablePlan[];
  nonHeroFixed: AvailablePlan[];
};

function isFixed(plan: AvailablePlan) {
  return plan.type === 'fixed';
}

function isHeroPlan(plan: AvailablePlan) {
  return [
    PlanFamily.memberSpecial,
    PlanFamily.stayAndSave,
    PlanFamily.drive,
    PlanFamily.twoYearFixAndProtect,
  ].includes(plan.planType!);
}

export function useAvailablePlans(
  availablePlans: AvailablePlansResponse,
  renewablePlans: RenewablePlansResponse,
  contracts: ContractsResponse,
): AvailablePlansUsage {
  const isP1P2Renewal = isP1P2ConversionRenewal(contracts);

  const [filterTariffs, { filters }] = useFeature(TARIFFS_FILTERING);
  const { filterType, tariffs } = filters || DEFAULT_FILTERS;

  const canRenewToDrive = renewablePlans.some(
    plan =>
      plan.name.includes(PlanFamily.drive) ||
      plan.name.includes(PlanFamily.evEverywhere),
  );

  return useMemo(() => {
    const restrictedAvailablePlans =
      !filterTariffs || tariffs.length === 0
        ? availablePlans
        : filterType === FilterType.Include
        ? availablePlans.filter(({ name }) => isPlanInTariffs(tariffs, name))
        : availablePlans.filter(({ name }) => !isPlanInTariffs(tariffs, name));

    const allAvailablePlans = refineAvailablePlans(
      restrictedAvailablePlans as any, // fix the type issue in renewal-tools
      {
        canRenewToDrive,
        canRenewToStayAndSave: true,
        canRenewFixAndProtect: true,
        isP1P2Renewal,
      },
    );

    const heroPlans = allAvailablePlans.filter(plan => isHeroPlan(plan));
    const nonHeroFixedPlans = allAvailablePlans.filter(
      plan => isFixed(plan) && !isHeroPlan(plan),
    );
    const variablePlans = allAvailablePlans.filter(plan => !isFixed(plan));
    const ddVariablePlans = variablePlans.filter(
      plan => plan.paymentMethod === 'Direct Debit',
    );

    const ssVariablePlans = ddVariablePlans.length
      ? ddVariablePlans
      : variablePlans;

    const hasOnlyVariable =
      heroPlans.length === 0 && nonHeroFixedPlans.length === 0;

    return {
      legal: {
        hero: heroPlans,
        variable: variablePlans,
        nonHeroFixed: nonHeroFixedPlans,
      },
      selfService: {
        hero: hasOnlyVariable ? ssVariablePlans : heroPlans,
        nonHeroFixed: nonHeroFixedPlans,
        variable: hasOnlyVariable ? [] : ssVariablePlans,
      },
    };
  }, [
    filterTariffs,
    tariffs,
    availablePlans,
    filterType,
    canRenewToDrive,
    isP1P2Renewal,
  ]);
}
