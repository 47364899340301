import { Card, Heading2 } from '@ovotech/nebula';
import React, { memo } from 'react';
import { ErrorSection } from '@/src/components';
import { DD_CALCULATOR_ERROR } from '@/src/constants/error-section-ids';

export const ErrorPanel = memo(() => {
  return (
    <Card>
      <ErrorSection headingComponent={Heading2} id={DD_CALCULATOR_ERROR} />
    </Card>
  );
});

ErrorPanel.displayName = 'ErrorPanel';
