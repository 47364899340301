import { PrimaryCTALink } from '@ovotech/nebula';
import styled from 'styled-components';
import { svgWidth } from '@/src/components/styled-utils';
import { ReactComponent as checkIcon } from '@/src/resources/confirmation_icon.svg';
import { ReactComponent as smartMeter } from '@/src/resources/smart-meter.svg';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const ConfirmationIcon = styled(checkIcon)`
  ${svgWidth('20px', 20, 20)};
  margin-top: 14px;
  margin-right: 10px;
`;

export const SmartMeterSvg = styled(smartMeter)`
  ${svgWidth('100px', 100, 100)};
  margin-right: 10px;
  display: block;
  padding-left: 30px;
  ${mqMediumDown(`
        display: none;
        padding-left: 0;
    `)}
`;

export const CheckText = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const NextActionsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  ${mqMediumDown(`
        flex-direction: column;
    `)}
`;

export const GetYourSmartMeterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BookAppointmentLink = styled(PrimaryCTALink)`
  margin-right: 10px;
  margin-bottom: 0;
  ${mqMediumDown(`
        margin-right: 0;
        margin-bottom: 10px;
    `)}
`;
