import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledSlideItem = styled.div<{ active: boolean }>`
  display: flex;
  ${({ active }) => `
    z-index: ${active ? 1 : 2};
    visibility: ${active ? 'visible' : 'hidden'};
    position: ${active ? 'relative' : 'absolute'};
  `}
`;

export const StyledSlideshowNav = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  z-index: 3;

  ${mqSmallDown(`
    top: 43%;
    width: 114%;
    transform: translateX(-7%);
  `)}
`;

export const StyledSlideshow = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
`;

export const SlideCounterContainer = styled.div`
  display: flex;
  justify-content: center;
  ${mqSmallDown(`
    padding-top: 1em;
  `)}
`;

export const SlideCounterButton = styled.button<{ active: boolean }>`
  width: 50px;
  height: 7px;
  border: none;
  cursor: pointer;
  ${({ active, theme: { colors, space } }) => `
    background-color: ${
      active ? colors.brand.grey.dark : colors.brand.grey.base
    };
    margin-left: ${space[2]};
    margin-right: ${space[2]};
  `}

  ${mqSmallDown(`
    width: 20px;
    margin-left: 2px;
    margin-right: 2px;
  `)}
`;
