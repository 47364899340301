import styled from 'styled-components';
import { Heading1, TextLink } from '@ovotech/nebula';

export const StyledHeading = styled(Heading1)`
  line-height: 1.25;
  margin: 2rem 0;
`;

export const Separator = styled.hr`
  border: 0;
  height: 1px;
  background: #eee;
  margin: 2.5rem 0;
`;

export const StyledTextLink = styled(TextLink)`
  & svg {
    margin: 1rem 1rem 0 0;
  }
`;
