import { CTALink, Heading3, P } from '@ovotech/nebula';
import React from 'react';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { ROUTE_SETUP_DD } from '@/src/constants/routes';

export const SVTDirectDebitSetup: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  return (
    <Banner data-testid="svt-dd-setup-banner" icon="pound" dismiss={dismiss}>
      <Heading3 as="h2">
        Set up a Direct Debit and save up to £123 a year
      </Heading3>
      <P>
        It's the cheapest and easiest way to pay for your energy every month
      </P>
      <CTALink href={ROUTE_SETUP_DD} onClick={onClick} opensInNewWindow>
        Set up now
      </CTALink>
    </Banner>
  );
};
