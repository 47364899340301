import React from 'react';
import { TurbineAnimation, TurbineFill } from './TurbineAnimation';

export const AnimatedIconsMobile = () => (
  <>
    <TurbineAnimation
      style={{ left: '5%' }}
      turbineFill={TurbineFill.LIGHT}
      scale={1}
    />
    <TurbineAnimation
      style={{ left: '30%' }}
      turbineFill={TurbineFill.LIGHTEST}
      scale={1.1}
    />
    <TurbineAnimation style={{ left: '57%' }} scale={1.4} />
    <TurbineAnimation
      style={{ left: '85%' }}
      turbineFill={TurbineFill.DARK}
      scale={1.1}
    />
  </>
);
