import { Margin } from '@ovotech/nebula';
import { AnalyticsRender } from '@ovotech/ui-tools';
import React, { PropsWithChildren } from 'react';
import { PageWithNavigationAndSectionPadding } from '@/src/components';
import {
  ProgressBar,
  ProgressBarProps,
} from '@/src/pages/MoveIn/components/ProgressBar';

type MoveInPageProps = PropsWithChildren<{
  analyticsName: string;
  analyticsProps?: Record<string, any>;
  flowProgress: ProgressBarProps;
  title: string;
}>;

export function MoveInPage({
  children,
  analyticsName,
  analyticsProps,
  flowProgress,
  title,
}: MoveInPageProps) {
  return (
    <AnalyticsRender name={analyticsName} properties={analyticsProps}>
      <PageWithNavigationAndSectionPadding title={title}>
        <ProgressBar {...flowProgress} />
        <Margin top={4} data-testid={'move-in-page-' + title}>
          {children}
        </Margin>
      </PageWithNavigationAndSectionPadding>
    </AnalyticsRender>
  );
}
