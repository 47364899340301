import { BillBreakdownPage } from './pages/BillBreakdown/BillBreakdownPage';
import { CarbonIntensityPage } from './pages/CarbonIntensity/CarbonIntensityPage';
import { HubPage } from './pages/Hub/Hub';
import { EnergyInsightsTermsPage } from './pages/Terms/EnergyInsightsTerms';

export const EnergyInsightsPages = {
  Hub: HubPage,
  CarbonIntensity: CarbonIntensityPage,
  BillBreakdown: BillBreakdownPage,
  Terms: EnergyInsightsTermsPage,
};
