import { ChangeDirectDebitAmountConfirm } from '@ovotech/energy-cx';
import React from 'react';
import { PageWithNavigationAndSectionPadding } from '@/src/components';

const ChangeDirectDebitAmountConfirmPage = () => (
  <PageWithNavigationAndSectionPadding title="Change Direct Debit amount - confirmation">
    <ChangeDirectDebitAmountConfirm />
  </PageWithNavigationAndSectionPadding>
);

export default ChangeDirectDebitAmountConfirmPage;
