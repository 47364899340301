import { Card, PrimaryCTALink } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledSuccessCard = styled(Card)`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    margin 0 auto;
    flex-direction: column-reverse;
    ${mediaQueries.mediumAndUp} {
      display: flex;
      margin 0 auto;
      flex-direction: row;
    };
  `}
`;

export const StyledSuccessTextWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    flex-direction: column;
    ${mediaQueries.mediumAndUp} {
      flex: 0 0 50%;
    };
    padding: 0;
  `}
  padding-right: 10px;
`;

//success page
export const StyledSuccessImage = styled.img`
  ${({ theme: { mediaQueries } }) => `
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 4rem;
    ${mediaQueries.mediumAndUp} {
      flex: 0 0 50%;
      max-width: 50%;
      object-fit: unset;
      margin-bottom: 0;
    };
  `}
`;

export const StyledHeadstartCard = styled(Card)`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    margin 0 auto;
    flex-direction: column;
    ${mediaQueries.mediumAndUp} {
      display: flex;
      margin 0 auto;
      flex-direction: row;
    };
  `}
`;

export const StyledHeadstartTextWrapper = styled(StyledSuccessTextWrapper)`
  margin-top: 48px;
  padding-left: 20px;
`;

export const StyledEVSpinLink = styled(PrimaryCTALink)`
  margin-top: 48px;
`;

export const StyledGreenBox = styled.div`
  background: #f3faf4;
  border: 3px solid #006945;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
`;

export const CardWithRightIconTime = styled.div<{ image?: any }>`
  position: relative;
  background: #f9f9f9;
  img {
    position: absolute;
    right: 0;
    top: 0;
    max-height: 70px;
    margin-top: -2px;
  }
  ${({ theme: { mediaQueries } }) => `
    ${mediaQueries.smallAndUp} {
      img {
        max-height: 100%;
      }
    }
  `};
`;

export const TextInsideCard = styled.div`
  display: block;
  ${({ theme: { mediaQueries } }) => `
    ${mediaQueries.mediumAndUp} {
      display: flex;
      flex-direction: column;
      }
  `};
`;

export const StyledHeading1 = styled.h1`
  padding: 0;
  margin-bottom: 0;
  text-align: center;
  margin-left: -40px;
`;

export const StyledHeading4 = styled.h4`
  margin-top: 0;
  color: #0a9828;
  text-align: center;
  margin-left: -40px;
`;

export const StyledStrongSmall = styled.span`
  font-size: 16px;
`;

export const StyledStrongSmallGreen = styled.span`
  font-size: 16px;
  color: #0a9828;
`;
