import { isAfter, isBefore, isEqual } from 'date-fns';
import format from 'date-fns/format';
import { formatNumberWithSuffix, listOfWords } from './typography';

const months: ReadonlyArray<string> = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getMonth = (index: number): string =>
  index < 0 || index > 11 ? 'Unknown' : months[index];

export const getEpochDaysDate = (epochDays: number): Date =>
  new Date(epochDays * 24 * 60 * 60 * 1000);

export const formatDateAndMonth = (epochDays: number): string => {
  const date = getEpochDaysDate(epochDays);
  return `${date.getDate()} ${months[date.getMonth()]}`;
};

export const formatFullDate = (date: Date | string): string => {
  return format(date, 'Do MMMM YYYY');
};

export const formatDatesAndMonth = (
  dates: Array<number>,
  month: number,
): string => {
  const formattedDates = listOfWords(
    dates.map(date => formatNumberWithSuffix(date)),
  );

  return formattedDates ? `${formattedDates} ${getMonth(month)}` : '';
};

export const formatHours = (numberOfHours: number) => {
  const hoursPart = `0${Math.floor(numberOfHours)}`.slice(-2);
  return format(`2018-01-01T${hoursPart}:00`, 'ha');
};

export const getCurrentDate = () => new Date();

export const isValidToday = (
  startDate: string = format(new Date(Date.now()), 'YYYY-MM-DD'),
  endDate: string = format(new Date(Date.now()), 'YYYY-MM-DD'),
) => {
  const today = format(new Date(Date.now()), 'YYYY-MM-DD');
  return (
    (isAfter(today, startDate) || isEqual(today, startDate)) &&
    (isBefore(today, endDate) || isEqual(today, endDate))
  );
};

export const dateToEpochDays = (date: Date) => {
  return date.getTime() / 86400000;
};

export const dateToLocalIso = (date: Date) => {
  return format(date, 'YYYY-MM-DDTHH:mm:ss');
};
