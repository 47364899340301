import { useAccountContext } from '@ovotech/ui-tools';
import { AccountSupplyPointFragmentFragment } from '@/src/api/kapi/__generated__/graphql';
import { useSupplyPointsQuery } from '@/src/api/kapi/use-supply-points-query';
import { useEncryptedKantanPayloadMutation } from '@/src/api';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';

interface UseEncryptedTokenReturn {
  isLoading: boolean;
  isEncrypting: boolean;
  getEncryptedToken: () => Promise<string | undefined | null>;
}

type SPAddress = {
  line1: string | undefined;
  line2?: string | undefined;
  town: string | undefined;
  postcode: string | undefined;
};

export const parseSupplyPointsAddress = (
  supplyPoints: AccountSupplyPointFragmentFragment[] | undefined,
): SPAddress | undefined => {
  if (!supplyPoints?.length) return undefined;

  const supplyPoint = supplyPoints.find(
    supplyPoint => supplyPoint.supplyPoint.address?.addressLines?.length,
  )?.supplyPoint;

  const supplyPointAddress = supplyPoint?.address?.addressLines;

  if (!supplyPointAddress) return undefined;

  if (supplyPointAddress.length === 4) {
    return {
      line1: supplyPointAddress[0],
      line2: supplyPointAddress[1],
      town: supplyPointAddress[2],
      postcode: supplyPoint?.address?.postCode ?? supplyPointAddress[3],
    };
  }

  return {
    line1: supplyPointAddress[0],
    town: supplyPointAddress[1],
    postcode: supplyPoint?.address?.postCode ?? supplyPointAddress[2],
  };
};

export const useEncryptedToken = (): UseEncryptedTokenReturn => {
  const { accountId } = useAccountContext();
  const profile = useProfileQueryKapi();
  const supplyPoints = useSupplyPointsQuery();
  const { isLoading: isEncrypting, mutateAsync } =
    useEncryptedKantanPayloadMutation(accountId ?? '');

  const getEncryptedToken = async (): Promise<string | undefined | null> => {
    try {
      const response = await mutateAsync({
        emailAddress: profile.data?.customer_nextV1.contactEmail,
        phoneNumber: profile.data?.customer_nextV1.contactPhoneNumbers?.[0],
        address: parseSupplyPointsAddress([
          ...(supplyPoints.data?.account?.accountSupplyPoints ?? []),
        ]),
        fullName: `${profile.data?.customer_nextV1.givenName} ${profile.data?.customer_nextV1.familyName}`,
      });

      return response?.encryptedPayload;
    } catch (error) {
      return null;
    }
  };

  return {
    isEncrypting,
    isLoading: profile.isLoading || supplyPoints.isLoading,
    getEncryptedToken,
  };
};
