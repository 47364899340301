export default {
  features: {
    renewableGeneration: 'renewable-generation',
    traceableElectricity: 'traceable-electricity',
    button: 'tile-with-clipped-image-button',
  },
  signUp: {
    confirmation: {
      wrapper: 'signup-confirmation',
      cta: 'signup-confirmation-cta',
    },
    loading: 'signup-loading',
    error: 'signup-error',
    activationError: 'activation-error',
    agreement: 'signup-agreement',
  },
  home: {
    header: 'home-header',
  },
};
