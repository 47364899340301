import styled from 'styled-components';

export const StyledThermostatConnectedNotification = styled.div`
  ${({ theme: { space } }) => `
    position: relative;
    padding-top: ${space[2]};
    padding-left: ${space[2]};
    padding-right: ${space[2]};
  `}
`;
