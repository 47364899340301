import { Card, P } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  height: 380px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledColourBackground = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

export const StyledCarbonIntensityWidget = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledLowMarginP = styled(P)`
  margin-bottom: 12px;
`;

export const StyledCTAContainer = styled.div`
  padding-top: 18px;
`;

export const StyledCurrentIntensityIndicator = styled.div`
  display: block;
  width: 100%;

  padding: 12px 0;

  font-size: 16px;
`;
