import { INTERNAL_EVENT_FLAG } from '@ovotech/energy-cx';
import { Card, Heading1, P, PrimaryCTALink, TextLink } from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsPage } from '@ovotech/ui-tools';
import React from 'react';
import { PageWithSimpleHeader, Section } from '@/src/components';
import {
  INTERCEPT_METER_READ_LINK,
  INTERCEPT_PAGE_VIEWED,
  INTERCEPT_SOMETHING_ELSE_LINK,
} from '@/src/constants/analytics-subjects';

import { ROUTE_HOME, ROUTE_METER_READINGS } from '@/src/constants/routes';
import { InternalLink } from '@/src/components/Navigation/InternalLink';

type HighTrafficInterceptPageProps = { hasInteracted: () => void };

const HighTrafficInterceptPage = ({
  hasInteracted,
}: HighTrafficInterceptPageProps) => {
  return (
    <AnalyticsPage
      name={INTERCEPT_PAGE_VIEWED}
      properties={{ [INTERNAL_EVENT_FLAG]: true }}
    >
      <PageWithSimpleHeader
        data-testid="intercept-page"
        title="Meter Reads Day"
      >
        <Section>
          <Card>
            <Heading1>Welcome - what do you want to do today?</Heading1>
            <AnalyticsClick
              name={INTERCEPT_METER_READ_LINK}
              properties={{ [INTERNAL_EVENT_FLAG]: true }}
            >
              <PrimaryCTALink
                as={InternalLink}
                href={ROUTE_METER_READINGS}
                onClick={hasInteracted}
              >
                I'm here to give a meter reading
              </PrimaryCTALink>
            </AnalyticsClick>
            <P>
              <AnalyticsClick
                name={INTERCEPT_SOMETHING_ELSE_LINK}
                properties={{ [INTERNAL_EVENT_FLAG]: true }}
              >
                <TextLink
                  as={InternalLink}
                  href={ROUTE_HOME}
                  onClick={hasInteracted}
                >
                  I'm here for something else
                </TextLink>
              </AnalyticsClick>
            </P>
          </Card>
        </Section>
      </PageWithSimpleHeader>
    </AnalyticsPage>
  );
};

export { HighTrafficInterceptPage };
