import styled from 'styled-components';
import { mqMediumUp } from '@/src/utils/mediaQuery';

export const Fuels = styled.div<{ isColumnBased: boolean }>(
  ({ isColumnBased }) => `
  display: grid;
  grid-template-areas: 'electric' 'gas';

  ${
    !isColumnBased &&
    mqMediumUp(`
      grid-template-areas: 'electric gas';    
    `)
  }
`,
);

export const FuelWrapper = styled.div<{ area: string }>(
  ({ area }) => `
    grid-area: ${area}
  `,
);

export const FuelTitle = styled.h4`
  ${({ theme: { fontSizes, fontWeights } }) => `
    font-size: ${fontSizes[1]};
    margin-top: 10px;
    font-weight: ${fontWeights.bold};
    :first-letter {
      text-transform: capitalize;
    }
  `}
`;

export const RateRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RateRowTitle = styled.p`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[1]};
    width: 60%;
    :first-letter {
      text-transform: capitalize;
    }
  `}
`;

export const RateRowValue = styled.p`
  ${({ theme: { fontSizes } }) => `
    width: 40%;
    font-size: ${fontSizes[1]};
  `}
`;
