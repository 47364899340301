import { useAccountContext } from '@ovotech/ui-tools';
import { BannerId, banners } from '../../ActionCentre/Banners/banners';
import { useServiceBanners } from './useServiceBanners';
import { ServiceActionCodes } from './utils/rulesetForServiceBanners';

export type ServiceActionCard = {
  name: BannerId;
  actionCode: ServiceActionCodes;
  component: React.ComponentType<any>;
  onDismiss: () => void;
};

export const useHardcodedServiceActionCards = (): ServiceActionCard[] => {
  const { accountId } = useAccountContext();
  const serviceBanners = useServiceBanners(accountId);

  return serviceBanners.reduce<ServiceActionCard[]>((acc, currentVal) => {
    const component = banners?.[currentVal.bannerId];
    if (!component) return acc;
    return [
      ...acc,
      {
        name: currentVal.bannerId,
        actionCode: currentVal.actionCode,
        component,
        onDismiss: currentVal.onDismiss,
      },
    ];
  }, []);
};
