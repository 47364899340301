import {
  CTAButton,
  TextField,
  Card,
  CheckboxField,
  Checkbox,
  Heading1,
  Margin,
  P,
  TextLink,
} from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { useState } from 'react';
import { EVENT_CONFIRM, PAGE_PAYMENT_DETAILS } from '../../analytics';
import { usePostMoveIn } from '../../api/usePostMoveIn';
import { Divider } from '../../components/Divider.styled';
import { APIErrorNotification } from '../../components/Error';
import { StyledForm } from '../../components/Form.styled';
import { MoveInPage } from '../../components/MoveInPage';
import { BankAccountForm, FormErrors } from '../../types/form';
import { MoveInDetails, MoveInJourneyProps } from '../../types/moveIn';
import { formEventToObject } from '../../utils/formInput';
import { validateBankDetails } from './validateBank';
import { LoadingIcon } from '@/src/components/icons';
import {
  OVO_ENERGY_TERMS,
  OVO_PRIVACY_POLICY_LINK,
} from '@/src/constants/links';
import DirectDebitMandatePDF from '@/src/resources/dd-mandate-ovoenergy.pdf';

export function Bank({
  continueMoveIn,
  moveInDetails,
  progress,
  updateMoveIn,
}: MoveInJourneyProps) {
  const [formErrors, setFormErrors] = useState<FormErrors<BankAccountForm>>({});
  const sendMoveInRequest = usePostMoveIn();

  const onSubmit = (unknownForm: React.FormEvent<HTMLFormElement>) => {
    unknownForm.preventDefault();
    const formInputs = formEventToObject<BankAccountForm>(unknownForm);
    const validatedForm = validateBankDetails(formInputs);

    if (validatedForm.validation === 'error') {
      setFormErrors(validatedForm.errors);
      window.scrollTo(0, 0);
      return false;
    } else {
      updateMoveIn({
        bankDetails: validatedForm.data,
      });

      sendMoveInRequest.mutate({
        ...moveInDetails,
        bankDetails: validatedForm.data,
      } as Required<MoveInDetails>);

      return true;
    }
  };

  if (sendMoveInRequest.status === 'success') {
    continueMoveIn();
  }

  return sendMoveInRequest.error ? (
    <MoveInPage
      analyticsName={PAGE_PAYMENT_DETAILS}
      analyticsProps={{ status: `api-error` }}
      title="Your payment details"
      flowProgress={progress}
    >
      <APIErrorNotification />
    </MoveInPage>
  ) : (
    <MoveInPage
      analyticsName={PAGE_PAYMENT_DETAILS}
      title="Your payment details"
      flowProgress={progress}
    >
      <Card>
        <Heading1>Your payment details</Heading1>

        <Divider />

        <StyledForm onSubmit={onSubmit}>
          <TextField
            label="Account holder name"
            id="accountName"
            name="accountName"
            defaultValue={moveInDetails.bankDetails?.accountName ?? ''}
            error={formErrors.accountName}
            data-testid="account-name"
          />

          <TextField
            label="Account number"
            id="accountNumber"
            name="accountNumber"
            characters={10}
            defaultValue={moveInDetails.bankDetails?.accountNumber ?? ''}
            error={formErrors.accountNumber}
            data-testid="account-number"
          />

          <TextField
            label="Sort code"
            id="sortCode"
            name="sortCode"
            characters={10}
            defaultValue={moveInDetails.bankDetails?.sortCode ?? ''}
            error={formErrors.sortCode}
            data-testid="sort-code"
          />

          <TextLink
            href={DirectDebitMandatePDF}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="dd-mandate-link"
          >
            Download the Direct Debit mandate if needed for your reference
          </TextLink>

          <Margin vertical={2} />

          <CheckboxField label="Confirm your Direct Debit">
            <Checkbox
              label="The account is in my name"
              name="accountIsOwn"
              id="accountIsOwn"
              error={formErrors.accountIsOwn}
              data-testid="dd-confirm-checkbox"
            />

            <Checkbox
              label="I'm the only person who needs to 'okay' this Direct Debit"
              name="permission"
              id="permission"
              error={formErrors.permission}
              data-testid="dd-only-person-checkbox"
            />
          </CheckboxField>

          <P>
            By clicking 'Confirm and Move In' you agree to our{' '}
            <TextLink
              href={`${OVO_ENERGY_TERMS}/2022`}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="tc-link"
            >
              Terms & Conditions,
            </TextLink>
            and{' '}
            <TextLink
              href={OVO_PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="privacy-policy-link"
            >
              Privacy Policy
            </TextLink>
            .
          </P>

          <AnalyticsClick name={EVENT_CONFIRM}>
            <CTAButton
              variant="missionPrimary"
              iconRight={
                sendMoveInRequest.status !== 'loading'
                  ? 'chevron-right'
                  : 'none'
              }
              data-testid="confirm-button"
            >
              {sendMoveInRequest.status !== 'loading' ? (
                'Confirm and Move In'
              ) : (
                <LoadingIcon />
              )}
            </CTAButton>
          </AnalyticsClick>
        </StyledForm>
      </Card>
    </MoveInPage>
  );
}
