import { Card, Heading2, Li, Margin, P, TextLink, Ul } from '@ovotech/nebula';

import React from 'react';
import { Media } from '@/src/components/Media';
import { BuyNowButton } from '../BuyNowButton/BuyNowButton';
import {
  CardWrapper,
  HeaderWrapper,
  FooterWrapper,
  StyledImageWrapper,
  StyledMobileImageWrapper,
  ListContainer,
} from './CutWasteCard.styled';

import ImgMobile from '@/src/resources/cut-waste-mobile.png';
import Img from '@/src/resources/cut-waste.png';
import { breakpoints } from '@/src/utils/mediaQuery';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { FootnoteReferenced } from '@/src/components';
import { SMART_THERM_TERMS_AND_CONDITIONS_LINK } from '@/src/constants/routes';

export const CutWasteCard = () => {
  return (
    <CardWrapper>
      <Card>
        <Media query={breakpoints.mediumUp}>
          <StyledImageWrapper src={Img} />
        </Media>
        <Media query={breakpoints.mediumDown}>
          <StyledMobileImageWrapper src={ImgMobile} />
        </Media>
        <HeaderWrapper>
          <Heading2>
            Tips to help you cut waste and save energy
            <FootnoteReferenced
              content={{
                id: 'CutWasteTitle',
                body: [
                  <>
                    Savings on your energy as a result of receiving personalised
                    energy saving tips are not guaranteed and will only occur as
                    a result of behavioral changes leading to a reduction in
                    your energy consumption. Actual savings will also depend on
                    other factors such as the type of heating system in your
                    home and the way home is insulated.
                  </>,
                ],
              }}
            />
            '
          </Heading2>
          <P>
            We’ve teamed up with tado° to make their thermostats even better for
            OVO members when you connect your tado° to your OVO account. To get
            the most out of this you’ll need a gas smart meter
            <FootnoteReferenced
              style={{ fontSize: 9 }}
              content={{
                id: 'CutWasteDescription',
                body: [
                  <>
                    Eligibility requirements to get a gas smart meter apply,
                    please see our{' '}
                    <AnalyticsClick
                      name={
                        SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE
                          .CLICKED.TERMS_AND_CONDITIONS
                      }
                    >
                      <TextLink href={SMART_THERM_TERMS_AND_CONDITIONS_LINK}>
                        terms and conditions
                      </TextLink>
                    </AnalyticsClick>{' '}
                    for more information.
                  </>,
                ],
              }}
            />
            , it’s not essential but it will allow you to:
          </P>
        </HeaderWrapper>
        <ListContainer>
          <Ul>
            <Li>Get personalised heat saving tips</Li>
            <Li>See how much your heating costs you each day</Li>
            <Li>See exactly how much it costs to heat your home</Li>
          </Ul>
        </ListContainer>
        <FooterWrapper>
          <P>
            Our team is working on new ways to make this better all the time
            <FootnoteReferenced
              style={{ fontSize: 9 }}
              content={{
                id: 'CutWasteSecondDescription',
                body: [
                  <>
                    We are still developing this service, so we may make some
                    changes and improvements from time to time. As this is a
                    developing product things may not always work perfectly but
                    we will do our best to fix any problems as soon as we can.
                  </>,
                ],
              }}
            />
            . By getting your tado° now, you’re helping to shape the future of
            this clever tech.
          </P>
        </FooterWrapper>
        <Margin top={4}>
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED.BUY_NOW
            }
            properties={{ buttonPlacement: 'tips' }}
          >
            <BuyNowButton />
          </AnalyticsClick>
        </Margin>
      </Card>
    </CardWrapper>
  );
};
