import { Heading1, P } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqSmallDown, mqMediumDown } from '@/src/utils/mediaQuery';

export const StyledH1 = styled(Heading1)`
  margin-top: 0.1em;
  margin-bottom: 0.1em;

  ${mqSmallDown(`
    line-height: 1.1em;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
  `)}
`;

export const StyledSubtextP = styled(P)`
  margin-top: 0;
`;

export const StyledCarbonIntensityHeaderCardContents = styled.div`
  display: flex;
  justify-content: space-between;

  ${mqMediumDown(`
    flex-direction: column;
  `)}
`;

export const StyledCarbonIntensityPageHeadings = styled.div`
  ${mqMediumDown(`
      padding-bottom: 32px;
      margin-bottom: 8px;
  `)}
  position: relative;
  z-index: 2;
`;

export const SubTitleComponentContainer = styled.div`
  ${({ theme: { space } }) => `
    margin-top: ${space[2]};
  `}
`;

export const StyledTextLinkP = styled(P)`
  width: 0;
  display: inline-block;
  margin-right: 12px;
  text-decoration: none;
  ${({ theme: { colors } }) => `
      color: ${colors.brand.primary.base};
  `}
`;
