import React, { useState } from 'react';
import styled from 'styled-components';
import ForecastGraph from './ForecastGraph';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { SlideCounter } from '@/src/components/Slideshow/Slideshow';
import {
  StyledSlideshow,
  StyledSlideshowNav,
} from '@/src/components/Slideshow/Slideshow.styled';
import { ArrowButton } from '@/src/components/Slideshow/ArrowButton';
import { CarbonIntensityForecast } from '@/src/pages/EnergyInsights/types';
import { chunkArr } from '@/src/pages/EnergyInsights/utils/conversions';
import AnalyticsService from '@/src/services/analyticsService';

type Props = {
  forecastData: Array<CarbonIntensityForecast>;
  isMobile: boolean;
};

const StyledCounterContainer = styled.div`
  width: 50%;
  margin: auto;
  margin-top: 12px;
`;

const ForecastGraphSlideshow = ({ forecastData, isMobile }: Props) => {
  const chunkedData = chunkArr<CarbonIntensityForecast>(
    forecastData,
    isMobile ? 6 : 10,
  );
  const [positionInData, setPositionInData] = useState(0);

  const isDisabled = (direction?: 'left' | 'right'): boolean => {
    if (direction === 'left') {
      return positionInData === 0;
    } else if (direction === 'right') {
      return positionInData === chunkedData.length - 1;
    }
    return false;
  };

  const goForward = () => {
    if (positionInData < chunkedData.length - 1) {
      setPositionInData(positionInData + 1);
      AnalyticsService.dispatch(
        `${ENERGY_INSIGHTS.CARBON_INTENSITY_SCROLL} (Step: Right)`,
      );
      return;
    }
    AnalyticsService.dispatch(
      `${ENERGY_INSIGHTS.CARBON_INTENSITY_SCROLL} (Step: END)`,
    );
  };
  const goBackward = () => {
    if (positionInData > 0) {
      setPositionInData(positionInData - 1);
      AnalyticsService.dispatch(
        `${ENERGY_INSIGHTS.CARBON_INTENSITY_SCROLL} (Step: Left)`,
      );
    }
  };

  const goTo = (index: number) => {
    if (index < 0 || index > chunkedData.length - 1) {
      return;
    }

    setPositionInData(index);
  };

  return (
    <StyledSlideshow data-testid="carbon-intensity-graph">
      <StyledSlideshowNav>
        <ArrowButton
          disabled={isDisabled('left')}
          onClick={goBackward}
          direction="left"
          size={36}
          dataTestId="SlideshowLeftButton"
          ariaLabel="Slideshow next button"
        />
        <ForecastGraph
          graphShapeData={chunkedData}
          positionInData={positionInData}
          isMobile={isMobile}
        />
        <ArrowButton
          disabled={isDisabled('right')}
          onClick={goForward}
          direction="right"
          size={36}
          dataTestId="SlideshowRightButton"
          ariaLabel="Slideshow previous button"
        />
      </StyledSlideshowNav>

      <StyledCounterContainer>
        <SlideCounter
          slides={chunkedData}
          activeIndex={positionInData}
          goToSlide={goTo}
        />
      </StyledCounterContainer>
    </StyledSlideshow>
  );
};

export default ForecastGraphSlideshow;
