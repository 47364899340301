import styled from 'styled-components';

export const StyledFooter = styled.footer(
  ({ theme: { space, mediaQueries } }) => `
    padding-top: ${space[4]};
    padding-bottom: ${space[4]};
    overflow: hidden;
    text-align: center;

    ${mediaQueries.smallAndUp} {
      padding-top: ${space[8]};
      padding-bottom: ${space[8]};
    }
    ${mediaQueries.mediumAndUp} {
      text-align: left;
    }
  `,
);

export const StyledFooterBottom = styled.div(
  ({ theme: { colors, borderWidths, space, mediaQueries } }) => `
    padding-top: ${space[4]};
    margin-top: ${space[4]};
    border-top: ${borderWidths.standard} solid ${colors.borderMuted};
    ${mediaQueries.mediumAndUp} {
      display: flex;
      align-items: center;
    }
  `,
);

export const StyledFooterLogo = styled.svg`
  width: 8rem;
  height: auto;
  margin: 0 auto;
`;

export const StyledFooterContent = styled.div(
  ({ theme: { mediaQueries, space } }) => `
  margin-top: ${space[4]};
  ${mediaQueries.mediumAndUp} {
      flex: 1;
      text-align: right;
      margin-top: 0;
    }
  `,
);

export const StyledFooterSocialLinks = styled.ul(
  ({ theme: { space } }) => `
    li {
      margin-right: ${space[2]};
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }
    }
  `,
);

export const StyledFooterSupportingLinks = styled.ul(
  ({
    theme: { colors, borderWidths, space, responsiveFontSizes, mediaQueries },
  }) => `

    font-size: ${responsiveFontSizes.small[0]};
    ${mediaQueries.smallAndUp} {
      font-size: ${responsiveFontSizes.small[0]};
    }
    li {
      border-left: ${borderWidths.standard} solid ${colors.border};
      padding-left: ${space[2]};
      margin-right: ${space[2]};
      margin-bottom: ${space[1]};
      display: inline-block;

      &:first-child {
        border-left: none;
        padding-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  `,
);
