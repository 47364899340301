import styled from 'styled-components';

export const StyledHeading4 = styled.h4`
  text-align: center;
`;

export const StyledDiv = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;
