import {
  CTAButton,
  Disclosure,
  Margin,
  NumberField,
  P,
  Radio,
  RadioField,
} from '@ovotech/nebula';
import React, { useState } from 'react';
import { Divider } from '../../../components/Divider.styled';
import { FormErrors, QuoteRequestForm } from '../../../types/form';
import {
  AcquisitionFuelType,
  HouseSize,
  QuoteType,
} from '../../../types/moveIn';

type FilledUsageProps = Partial<{
  electricityUsage: number;
  gasUsage: number;
  selectedFuelType: AcquisitionFuelType;
  selectedQuoteType: QuoteType;
  selectedUsage: HouseSize;
}>;

export function Usage({
  formErrors,
  electricityUsage,
  gasUsage,
  selectedFuelType = 'Dual',
  selectedQuoteType = 'Estimated',
  selectedUsage = 'Low',
}: FilledUsageProps & {
  formErrors: FormErrors<QuoteRequestForm>;
}) {
  const [fuelType, setFuelType] =
    useState<AcquisitionFuelType>(selectedFuelType);
  const [quoteType, setQuoteType] = useState<QuoteType>(selectedQuoteType);

  return (
    <>
      <RadioField
        name="fuel"
        id="fuel"
        label="What do you power your home with?"
        defaultValue={fuelType}
        onValueChange={fuel => setFuelType(fuel! as AcquisitionFuelType)}
        error={formErrors.fuel}
        data-testid="fuel-question"
      >
        <Radio
          id="dual"
          label="Electricity and gas"
          value={'Dual'}
          data-testid="fuel-dual"
        />
        <Radio
          id="electric"
          label="Electricity only"
          value={'Electricity'}
          data-testid="fuel-electricity"
        />
      </RadioField>

      <Divider />

      <RadioField
        name={'quoteType'}
        label="Do you know how much energy you use?"
        hint="This helps us calculate a more accurate quote for you"
        defaultValue={quoteType}
        onValueChange={quote => setQuoteType(quote! as QuoteType)}
        error={formErrors.fuel}
        data-testid="usage-question"
      >
        <Radio
          id="estimated"
          label="No"
          value={'Estimated'}
          data-testid="usage-estimated"
        />
        <Radio
          id="usage"
          label="Yes"
          value={'UsageBased'}
          data-testid="usage-usage-based"
        />
      </RadioField>

      {quoteType === 'UsageBased' ? (
        <>
          <Margin top={3} />
          <Disclosure title="What is a kWh?">
            <P>
              A kWh stands for kilowatt-hour, and is the unit used to measure
              energy use. 1 kWh will power a 40 watt light bulb for 25 hours.
            </P>
          </Disclosure>
          <Margin vertical={4} />
          <Disclosure title="Where can I find my usage in kWh?">
            <P>
              You can find your usage in an energy bill or in your current
              supplier’s online account. We’re looking for your annual usage to
              refine your quote.
            </P>
          </Disclosure>
          <Margin vertical={4} />
          <NumberField
            id="electricityUsage"
            name="electricityUsage"
            label="Electricity used per year in kWhs"
            iconLeft="electricity"
            defaultValue={electricityUsage}
            error={formErrors.electricityUsage}
            data-testid="usage-electricity"
          />
          {fuelType === 'Dual' ? (
            <>
              <Margin vertical={4} />
              <NumberField
                id="gasUsage"
                name="gasUsage"
                label="Gas used per year in kWhs"
                iconLeft="gas"
                defaultValue={gasUsage}
                error={formErrors.gasUsage}
                data-testid="usage-gas"
              />
            </>
          ) : null}
        </>
      ) : (
        <>
          <Divider />
          <RadioField
            name="usage"
            label="How many bedrooms do you have?"
            error={formErrors.usage}
            defaultValue={selectedUsage}
            data-testid="usage-bedrooms-question"
          >
            <Radio
              id="houseSize1"
              label="1 bedroom"
              value={'Low'}
              data-testid="usage-1-bed"
            />
            <Radio
              id="houseSize2"
              label="2 to 3 bedrooms"
              value={'Medium'}
              data-testid="usage-2-bed"
            />
            <Radio
              id="houseSize3"
              label="4+ Bedroom"
              value={'High'}
              data-testid="usage-4-bed"
            />
          </RadioField>
        </>
      )}

      <P>
        By clicking "Next" you agree we can use your address to look up the
        meter details of your new home and who your current supplier is, using
        standard industry databases.
      </P>

      <CTAButton
        variant="missionPrimary"
        iconRight="chevron-right"
        data-testid="next-button"
      >
        Next
      </CTAButton>
    </>
  );
}
