import { Heading3, Heading4, P, Strong } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { LossBannerCTA } from '../LossBannerCTA';
import { useContractsQuery } from '@/src/api';
import { MeterSwitch, RouterLink } from '@/src/components';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { CollapsibleBlock } from '@/src/components/ActionCentre/Banners/CollapsibleBlock';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { LOSS_MESSAGE_SHOWN } from '@/src/constants/analytics';
import {
  ROUTE_MAKE_CARD_PAYMENT,
  ROUTE_METER_READINGS,
} from '@/src/constants/routes';
import {
  findContractInLoss,
  getFirstAndLastContractsInLoss,
  getOtherFuelType,
} from '@/src/utils/contracts';

export const LossCompleteSingleFuel: ActionCentreBanner = ({ onClick }) => {
  const { accountId } = useAccountContext();
  const { data: contracts, isLoading } = useContractsQuery(accountId);
  if (isLoading) return <SkeletonCard />;
  if (!contracts) return null;

  const electricityContract = findContractInLoss(contracts.electricity);
  const gasContract = findContractInLoss(contracts.gas);
  const [contractInLoss] = getFirstAndLastContractsInLoss(
    electricityContract,
    gasContract,
  );

  if (!contractInLoss) return null;

  const { fuel, lossDate, status } = contractInLoss;
  const otherFuel = getOtherFuelType(fuel);
  const lossCompletionDay = status.inLoss && status.lossComplete;
  const lossEvent = `Single fuel: ${fuel} Loss ${
    lossCompletionDay ? 'Completion Day' : 'Complete'
  }, ${otherFuel} staying`;

  return (
    <AnalyticsWrapper name={LOSS_MESSAGE_SHOWN} properties={{ lossEvent }}>
      <Banner icon="lossComplete" data-testid="loss-single-fuel">
        <Heading3 as="h2" data-testid="loss-complete-fuel">
          You&rsquo;ve switched to a new supplier for {fuel}
        </Heading3>
        <P>
          Your switch happened on{' '}
          <Strong data-testid="loss-complete-date">
            {format(new Date(lossDate as string), 'Do MMMM YYYY')}
          </Strong>
          .
        </P>
        <CollapsibleBlock bannerId="lossCompleteSingleFuel">
          <P>
            We&rsquo;ll send your final{' '}
            <span data-testid="loss-complete-fuel">{fuel}</span> statement
            within 6 weeks. This will include your {fuel} charges up to the date
            of your switch.
          </P>
          <MeterSwitch
            HasTraditional={() => (
              <>
                <Heading4 as="h3">Need to send a reading?</Heading4>
                <P>
                  Please send a reading if you haven't sent one recently to make
                  sure your final statement's accurate.{' '}
                  <RouterLink to={ROUTE_METER_READINGS}>
                    Send a reading
                  </RouterLink>
                  .
                </P>
              </>
            )}
          />
          <P>
            Please don't cancel your Direct Debit – we'll continue to collect it
            until your account's closed.
          </P>
          <P>
            If your final balance is in credit we'll refund this back to your
            bank account within 10 working days, then close your account.
          </P>
          <P>
            If your final balance is in debt, we'll continue collecting your
            Direct Debit until it's cleared. We'll refund any credit back to
            your bank account within 10 working days, then close your account.
          </P>
          <P>
            You can also make a top-up card payment on your online{' '}
            <RouterLink to={ROUTE_MAKE_CARD_PAYMENT}>account</RouterLink> to
            clear any outstanding debt and speed up the process.
          </P>
          <LossBannerCTA
            lossEvent={lossEvent}
            bannerId="lossCompleteSingleFuel"
            onClick={onClick}
          />
        </CollapsibleBlock>
      </Banner>
    </AnalyticsWrapper>
  );
};
