import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom-v5';
import { State } from '@/src/types/State';
import {
  connectionStatusToKaluzaSmartChargerStart,
  sendKaluzaAuthTokenStart,
  sendKaluzaTokenStart,
} from '@/src/redux/ducks/evs/evInlife';

export function useKaluzaSmartCharger() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const {
    connection,
    statusError,
    isFetchingToken,
    isFetchingStatus,
    tokenError,
  } = useSelector(({ evInlife }: State) => evInlife);

  const searchParams = new URLSearchParams(search);

  const token = searchParams.get('code') || searchParams.get('refresh_token');
  const refreshToken = searchParams.get('refresh_token');
  const accessToken = searchParams.get('code');
  const canMakePostTokenCallForV2 =
    connection === false && !statusError && refreshToken;
  const canMakePostTokenCallForV1OrOhme =
    connection === false && !statusError && accessToken;

  const callConnectionStatusEndpoint = useCallback(() => {
    dispatch(connectionStatusToKaluzaSmartChargerStart());
  }, [dispatch]);

  const sendRegistrationTokenToInlife = useCallback(() => {
    if (typeof refreshToken === 'string') {
      dispatch(sendKaluzaAuthTokenStart(refreshToken));
    }
  }, [dispatch, refreshToken]);

  const sendAuthTokenToInlife = useCallback(() => {
    if (typeof accessToken === 'string') {
      dispatch(sendKaluzaTokenStart(accessToken));
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (connection === null) {
      // Only call status endpoint when connection is in default state
      callConnectionStatusEndpoint();
    }
  }, [dispatch, callConnectionStatusEndpoint, connection]);

  useEffect(() => {
    if (canMakePostTokenCallForV2) {
      sendRegistrationTokenToInlife();
    }
  }, [dispatch, canMakePostTokenCallForV2, sendRegistrationTokenToInlife]);

  useEffect(() => {
    if (canMakePostTokenCallForV1OrOhme) {
      sendAuthTokenToInlife();
    }
  }, [dispatch, canMakePostTokenCallForV1OrOhme, sendAuthTokenToInlife]);

  return {
    connection,
    error: statusError || tokenError,
    isFetching: isFetchingToken || isFetchingStatus,
    hasConnectionCode: Boolean(token),
  };
}
