import { CTAButton, P, Stack } from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { Dispatch, SetStateAction } from 'react';

import {
  PROFILE_ADDON_GREENER_ELECTRICITY_MODAL_CLOSE,
  PROFILE_ADDON_CANCELLED,
  PROFILE_ADDON_CANCEL_KEEP,
} from '@/src/constants/analytics-subjects';
import { GreenerElectricityModal } from '@/src/pages/GreenerElectricity/components/GreenerElectricityModal/GreenerElectricityModal';
import { ProductUpdateStatus } from '@/src/types/State';

interface Props {
  handleCancel: () => void;
  setCancelPrompt: Dispatch<SetStateAction<boolean>>;
  cancellation: ProductUpdateStatus;
  cancelPrompt: boolean;
  addon: string | undefined;
}

const GreenerElectricityCancellationModal = (props: Props) => {
  const { dispatch } = useAnalytics();
  const { handleCancel, setCancelPrompt, cancellation, cancelPrompt, addon } =
    props;

  return (
    <GreenerElectricityModal
      title={'Are you sure you want to cancel?'}
      showGreenerElectricityModal={cancelPrompt}
      onClose={() => {
        dispatch({
          name: PROFILE_ADDON_GREENER_ELECTRICITY_MODAL_CLOSE,
          type: 'click',
        });
        setCancelPrompt(false);
      }}
    >
      <P>
        {`Cancelling means losing your supercharged upgrade. We'll stop providing you with 100% renewable electricity and we'll stop our annual contribution to supporting new renewable generation.`}
      </P>
      <P>
        {`While the electricity that powers your home will still come from a mix of sources, we'll stop buying the same amount of renewable electricity from our UK generator community to balance it all out.`}
      </P>
      <Stack spaceBetween={3}>
        <AnalyticsClick name={PROFILE_ADDON_CANCEL_KEEP} properties={{ addon }}>
          <CTAButton
            variant="secondary"
            type="button"
            onClick={() => setCancelPrompt(false)}
            disabled={cancellation.isUpdating}
            fullWidth="always"
          >
            No, keep going further for the planet
          </CTAButton>
        </AnalyticsClick>
        <AnalyticsClick name={PROFILE_ADDON_CANCELLED} properties={{ addon }}>
          <CTAButton
            variant="primary"
            type="button"
            onClick={handleCancel}
            disabled={cancellation.isUpdating}
            fullWidth="always"
          >
            Yes, cancel my subscription
          </CTAButton>
        </AnalyticsClick>
      </Stack>
    </GreenerElectricityModal>
  );
};

export default GreenerElectricityCancellationModal;
