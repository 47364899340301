import { FuelType } from './Account';
import {
  BoltonInstance,
  BoltonInstanceV2,
  BundleInstance,
  BundleInstanceV2,
  CatalogueBolton,
  CatalogueBundle,
  AvailableProduct,
  CatalogueBoltonV2,
  CatalogueBundleV2,
} from './Products';
import {
  CarbonIntensityForecast,
  CarbonIntensityLevel,
  NextGreenTimes,
  HomeProfileAnswers,
  CategoryDisplayName,
} from '../pages/EnergyInsights/types';
import { BreakdownWeek } from '../pages/EnergyInsights/types/bill-breakdown.types';
import {
  OrderType,
  PaymentType as ThermostatPaymentType,
} from '../pages/SmartThermostats/utils/types';

export { BootstrapResponse, DirectDebitResponse } from '@ovotech/energy-cx';

export type BootstrapAccountSupply = {
  mpxn: string;
  fuel: FuelType;
  isPayg: boolean;
  start: string;
  supplyPointInfo: null | {
    meterType: null | string;
    meterNotFound: boolean;
    address: string[];
  };
  isOnboarding: boolean;
};

type HalfHourlyUsageData = {
  interval: {
    start: string;
    end: string;
  };
  consumption: number;
  unit: 'kwh';
};

type HalfHourlyUsage = {
  next: boolean;
  prev: boolean;
  data: Array<HalfHourlyUsageData>;
};

export type HalfHourlyUsageResponse = {
  electricity: null | HalfHourlyUsage;
  gas: null | HalfHourlyUsage;
};

export type HomeProfileWidgetResponse = {
  variant: 'new-customer' | 'returning-customer';
  answers: HomeProfileAnswers | null;
  recentlyUpdated: boolean;
  analyticsId: string;
};

export type DailyUsageData = {
  interval: {
    start: string;
    end: string;
  };
  consumption: number;
  hasHhData: boolean;
  cost?: CurrencyValueResponse;
};

export type DailyUsage = {
  next: boolean;
  prev: boolean;
  prevYear: boolean;
  data: Array<DailyUsageData>;
};

export type MonthlyUsageData = {
  year: number;
  month: number;
  consumption: number;
  mpxn: string;
  cost?: CurrencyValueResponse;
};

export type MonthlyUsage = {
  next: boolean;
  prev: boolean;
  data: Array<MonthlyUsageData>;
};

export type MonthlyUsageResponse = {
  electricity: null | MonthlyUsage;
  gas: null | MonthlyUsage;
};

export type DailyUsageResponse = {
  electricity: null | DailyUsage;
  gas: null | DailyUsage;
};

export type Pounds = { pounds: string };

export type CurrencyValueResponse = {
  amount: string;
  currencyUnit: string;
};

export type Payment = {
  date?: string;
  description: string;
  credit: Pounds;
};

export type ReadingType =
  | 'SmartReading'
  | 'CustomerReadingContactCentre'
  | 'EstimatedReading'
  | 'CustomerSubmittedReading'
  | 'OnSiteReading'
  | 'MexReadingFinal'
  | 'MexReadingInitial'
  | 'MexReadingFinalEstimated'
  | 'MexReadingInitialEstimated'
  | 'MexReadingInitialDeemed'
  | 'MigrationReadingEstimated'
  | 'MigrationReadingActual'
  | 'OpeningReadingDeemed'
  | 'OpeningReadingActual'
  | 'OpeningReadingAgreed'
  | 'FinalReadingDeemed'
  | 'FinalReadingActual'
  | 'CorrectionReadEstimate'
  | 'CorrectionReadActual'
  | 'MeterConfigChangeInitial'
  | 'MeterConfigChangeFinal'
  | 'MeterConfigChangeInitialEstimated'
  | 'MeterConfigChangeFinalEstimated';

export type ProfileResponse = {
  id: string;
  email: string;
  title: string;
  givenName: string;
  familyName: string;
  address: Array<string>;
  phoneNumbers: Array<string>;
  marketingPreferences: Prefs[];
};

type Prefs = 'Email' | 'SMS' | 'Post' | 'Phone';

export type PlanContract = {
  name: string;
  unitRates: Array<{
    name: string;
    unitRate: CurrencyValueResponse;
  }>;
  standingCharge: CurrencyValueResponse;
  contractStartDate: string;
  contractEndDate: string | null;
  contractType: 'fixed' | 'variable';
  exitFee: CurrencyValueResponse;
  personalProjection: number | null;
  mpxn: string;
  msn: string;
  isInRenewal: boolean;
  hasFutureContracts: boolean;
};

type AccountCustomerBindings = {
  isFinanciallyLiable: boolean;
  accountCommsPreference: string[];
};

export type AccountGraphqlResponse = {
  account: {
    customerBindings: AccountCustomerBindings[];
  };
};

export interface CurrencyValueResponseWithVAT extends CurrencyValueResponse {
  amountWithVAT: string;
}

type FixedContractProperties = {
  type: 'fixed';
  expiryDate: number;
};

type VariableContractProperties = {
  type: 'variable';
  expiryDate: number | null;
};

type HHContractProperties = {
  type: 'half-hourly';
  expiryDate: number | null;
};

export type EnergyContractResponse = {
  contractId: string;
  mpxn: string;
  startDate: number;
  fuel: FuelType;
  standingRate: number;
  exitFee: number;
  planName: string;
  serviceAccountId: string;
  lengthInMonths: number | null;
  region: string;
  msn: string;
  lossDate: null | string;
  profileClass: string;
  status: {
    active: boolean;
    inRenewal: boolean;
    inLoss: boolean;
    lossComplete: boolean;
    hasFutureContracts: boolean;
  };
  unitRates: Array<{
    unitRate: number;
    clockingTimeLabel: string;
  }>;
} & (
  | FixedContractProperties
  | VariableContractProperties
  | HHContractProperties
);

export type ContractsResponse = {
  electricity: Array<EnergyContractResponse>;
  gas: Array<EnergyContractResponse>;
  hasOnlineDiscount: boolean;
};

type InterestRewardResponse = {
  currentPercentage: number;
};
/**
 * @deprecated
 */
export type LogoutResponse = {
  redirect: string;
};

type ProductsHistoryInstance = {
  boltons: Array<BoltonInstance>;
  bundles?: Array<BundleInstance>;
};

type ProductsHistoryInstanceV2 = {
  boltons: Array<BoltonInstanceV2>;
  bundles?: Array<BundleInstanceV2>;
};

export type ProductsResponse = {
  boltons: Array<BoltonInstance>;
  bundle?: BundleInstance;
  history?: ProductsHistoryInstance;
};

export type ProductsResponseV2 = {
  boltons: Array<BoltonInstanceV2>;
  bundle?: BundleInstanceV2;
  history?: ProductsHistoryInstanceV2;
};

export type ProductCatalogue = {
  boltons: Array<CatalogueBolton>;
  bundles: Array<CatalogueBundle>;
};

export type ProductCatalogueV2 = {
  boltons: Array<CatalogueBoltonV2>;
  bundles: Array<CatalogueBundleV2>;
};

export type AvailableProductsResponse = Array<AvailableProduct>;

export type MonthlyUsageGroupedByYear = {
  [key: string]: MonthlyUsageResponse;
};

type ProjectedCost = {
  cost: CurrencyValueResponse;
  days: number;
  date: string;
};

type RemainingDirectDebits = {
  count: number;
  includesCurrentMonth: boolean;
};

export type ProjectedCostsResponse = {
  electricity: null | Array<ProjectedCost>;
  gas: null | Array<ProjectedCost>;
  boltOns: null | Array<ProjectedCost>;
  recommendedDirectDebit: RecommendedDirectDebitResponse;
};

export type RecommendedDirectDebitResponse = {
  accountId: string;
  amount: CurrencyValueResponse;
  friendlyAmount: null | CurrencyValueResponse;
  updatedAt: string;
  predictedEnergyCost: CurrencyValueResponse;
  predictedBoltOnCost: CurrencyValueResponse;
  predictedFinalBalance: CurrencyValueResponse;
  balance: CurrencyValueResponse;
  remainingDirectDebits: RemainingDirectDebits;
  state: null | Array<string>;
  isVariable: boolean;
  calculationEndDate: Date;
};

export type AccountInfoResponse = {
  accountId: string;
  interestReward: null | InterestRewardResponse;
  balance: CurrencyValueResponse;
  moveOutDate?: null | string;
  canSelfServeMoveOut: boolean;
};

export type FinalStatementResponse = {
  electricity: boolean;
  gas: boolean;
};

export type ShowSmartMeterBookingBannerResponse = {
  showBanner: boolean;
};

export type SmartMeterBookingResponse = {
  eligibility: {
    isEligible: boolean;
    smets1: {
      isEligible: boolean;
      ineligibilityDetails: Array<{
        ineligibilityReason: string;
        metadata: {
          additionalProp1: string;
          additionalProp2: string;
          additionalProp3: string;
        };
      }>;
    };
    smets2: {
      isEligible: boolean;
      ineligibilityDetails: Array<{
        ineligibilityReason: string;
        metadata: {
          additionalProp1: string;
          additionalProp2: string;
          additionalProp3: string;
        };
      }>;
    };
  };
  booking: {
    hasBooking: boolean;
    status: SmartMeterBookingStatus;
    isBookable: boolean;
    outstandingBooking: boolean;
  };
  availability: {
    SMETS1: string;
    SMETS2: string;
    SMETS1Appointments: number;
    SMETS2Appointments: number;
  };
};

// ts-unused-exports:disable-next-line
export type SmartMeterBookingStatus =
  | 'Scheduled' // automatically pre-booked appointments that has not yet accepted by the customer
  | 'Booked' // ust a regular booking or an accepted pre-booked appointment
  | 'Completed'
  | 'Provisional' // not used
  | 'NotBooked'; // if the customer wont accept the booking within 7 days, we'll cancel it down automatically and the status will be set back to NotBooked

export type SmartThermostatsTempDataPoint = {
  interval: {
    start: string;
    end: string;
  };
  value?: number;
};

export type SmartThermostatsTempDataSeries = {
  data: SmartThermostatsTempDataPoint[];
};

export type SmartThermostatsInfo = {
  insideTemperature: SmartThermostatsTempDataSeries;
  outsideTemperature: SmartThermostatsTempDataSeries;
};

export type SmartThermostatsSavings = {
  savings: string;
};

export type SmartThermostatsTadoInfo = {
  setPoint: number;
  name: string;
};

export type ThermostatBoltonPricing = {
  total: number;
  discountedTotal?: number;
  discountPercentage?: number;
};

export type SmartThermostatsPricing = {
  [ThermostatPaymentType.MonthlyPayment]: {
    [OrderType.SelfInstall]: ThermostatBoltonPricing;
    [OrderType.ProfessionalInstall]: ThermostatBoltonPricing;
  };
  [ThermostatPaymentType.FullAmountPayment]: {
    [OrderType.SelfInstall]: ThermostatBoltonPricing;
    [OrderType.ProfessionalInstall]: ThermostatBoltonPricing;
  };
};

export type SmartThermostatsTadoLink = string;

export type SmartThermostatsTadoStatus = {
  connected: boolean;
  isInitialCollectionPeriod: boolean;
  link: string | null;
};

export type SmartThermostatsOrderInfo = {
  orderType: OrderType;
};

export type SmartThermostatsOrdersConfirmation = {
  orderId?: string;
  clientSecret?: string;
  paymentStatus?: string;
};

export type SmartHomeRegisterInterestResponse = {
  message: string;
};

export type SmartThermostatsHeatingResponse = SmartHomeRegisterInterestResponse;

export type SmartHomeInterestStatusResponse = {
  inWaitlist: boolean;
};

type AccountPaymentsData = {
  data: {
    payments: Array<Payment>;
    closingBalance: Pounds;
  };
};

type LatestAccountPayments = {
  latestPeriod: AccountPaymentsData;
};

export type AccountPaymentsGraphqlResponse = {
  billingSummary: LatestAccountPayments;
};

export type CreateCardPaymentSessionResponse = {
  SessionUrl: string;
};

export type CollectableBalanceResponse = {
  collectableBalance: {
    amount: string;
    currencyUnit: string;
  } | null;
};

export type MetersResponse = {
  meters: Meter[];
};

export type WarrantInstall = {
  mpxn: string;
  fuelType: string;
  isBookable: boolean;
  hasWarrantInstall: boolean;
};

export type Meter = {
  fuelType: Fuel | null;
  mpxn: string;
  msn: string;
  meterType: string | null;
  deviceId: string | null;
  isSmart: boolean;
  readingFrequency: ReadingFrequency | null;
  readingFrequencyChangeInProgress: boolean;
  readingFrequencyChangingTo: ReadingFrequency | null;
  readingFrequencyChangedAt: string | null;
  brand: string | null;
};

export enum Fuel {
  ELECTRICITY = 'Electricity',
  GAS = 'Gas',
}

export enum ReadingFrequency {
  HALFHOURLY = 'HalfHourly',
  DAILY = 'Daily',
  MONTHLY = 'Monthly',
}

export type EncryptedPayloadResponse =
  | {
      encryptedPayload: string;
    }
  | undefined;

type ForecastAndGreentime = {
  forecast: CarbonIntensityForecast[];
  greentime: NextGreenTimes | null;
};

type BreakdownWidgetData = {
  totalUsage: number;
  gasTotalUsage: number;
  elecTotalUsage: number;
  barWidths: { yourBarWidth: string; averageBarWidth: string };
  comparisonTotal: number;
  comparisonBarText: string;
  mostUsedCategories: {
    fuel: string;
    topCategory: CategoryDisplayName;
    secondCategory: CategoryDisplayName;
  };
} | null;

export type CarbonFigures =
  | CarbonFiguresGeneric<CarbonAmounts, null>
  | CarbonFiguresGeneric<null, CarbonAmounts>
  | null;

type CarbonFiguresGeneric<UsedCarbonType, SavedCarbonType> = {
  comparisonCarbonTonnes: CarbonAmounts;
  usedCarbonTonnes: UsedCarbonType;
  savedCarbonTonnes: SavedCarbonType;
};

type CarbonAmounts = {
  gas: number | null;
  electricity: number | null;
  total: number;
};

export type EnergyInsightsHubResponse = {
  homeProfileWidget: HomeProfileWidgetResponse | null;
  hasSmartMeter: boolean;
  halfHourlyReads: boolean | null;
  carbonFigures: CarbonFigures;
  breakdownWidget: BreakdownWidgetData | null;
  hasBreakdownData: boolean | null;
  currentIntensity: {
    index: CarbonIntensityLevel;
  } | null;
  carbonGreentime: ForecastAndGreentime | null;
};

// https://github.com/ovotech/smart-home-thermostat-api/blob/master/openapi.json
export type TadoPayUpfrontMutationResponse =
  | {
      // HTTP 201 - OK
      redirectUrl: string;
    }
  | {
      // HTTP 500 - Returned in the case that something went wrong and it's not up to the customer to fix it
      error: string;
    }
  | string; // HTTP 401 - Missing accountId

// https://github.com/ovotech/smart-home-thermostat-api/blob/master/openapi.json
export type TadoPayMonthlyResponse =
  | {
      // HTTP 201 - OK
      orderId: string;
    }
  | {
      // HTTP 500 - Returned in the case that something went wrong and it's not up to the customer to fix it
      message: string;
    };

export type CarbonIntensityResponse = {
  current: CarbonIntensityLevel;
  forecast: Array<CarbonIntensityForecast>;
  greentime: NextGreenTimes | null;
};

type BreakdownTabs = [Fuel, Fuel?];
export interface BreakdownPageResponse {
  visibleFuelTabs: BreakdownTabs;
  breakdown: BreakdownWeek[];
  homeProfileWidget: HomeProfileWidgetResponse;
}

export type CarbonZeroNavigationResponse = {
  showBreakdownLink: boolean;
};

export type FirstLoginResponse = {
  customerId: string;
  isFirstLogin: boolean;
};

export type TescoRewardResponse = {
  applicationAccountNumber: string;
  applicationCreated: boolean;
  applicationComplete: boolean;
  clubcardLinked: boolean;
  hasDirectDebit: boolean;
  hasSmartMeter: boolean;
  pointRedemptionDate: string | null;
};

export type TescoRewardAuthRequest = {
  successRedirectUrl: string;
  failureRedirectUrl: string;
};

export type TescoRewardAuthResponse = {
  authorisationUrl: string;
};

export type BeyondAccountStatusGraphqlResponse = {
  beyondAccountStatus: {
    status: BeyondAccountStatus;
  };
};

export enum BeyondAccountStatus {
  Activated = 'ACTIVATED',
  Enrolled = 'ENROLLED',
  Ineligible = 'INELIGIBLE',
  Prospective = 'PROSPECTIVE',
}
