import { CTAButton, P } from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { Dispatch, SetStateAction } from 'react';

import {
  PROFILE_ADDON_GREENER_ENERGY_MODAL_CLOSE,
  PROFILE_ADDON_CANCELLED,
  PROFILE_ADDON_CANCEL_KEEP,
} from '@/src/constants/analytics-subjects';
import { GreenerEnergyModal } from '@/src/pages/GreenerEnergy/components/GreenerEnergyModal/GreenerEnergyModal';
import { ProductUpdateStatus } from '@/src/types/State';

interface Props {
  handleCancel: () => void;
  setCancelPrompt: Dispatch<SetStateAction<boolean>>;
  cancellation: ProductUpdateStatus;
  cancelPrompt: boolean;
  addon: string | undefined;
}

const GreenerEnergyCancellationModal = (props: Props) => {
  const { dispatch } = useAnalytics();
  const { handleCancel, setCancelPrompt, cancellation, cancelPrompt, addon } =
    props;

  return (
    <GreenerEnergyModal
      title={'Are you sure you want to cancel?'}
      showGreenerEnergyModal={cancelPrompt}
      onClose={() => {
        dispatch({
          name: PROFILE_ADDON_GREENER_ENERGY_MODAL_CLOSE,
          type: 'click',
        });
        setCancelPrompt(false);
      }}
    >
      <P>
        {`Cancelling means losing your supercharged upgrade. We'll stop providing you with 100% renewable electricity and 100% carbon-neutral gas. And we'll stop our annual contribution to supporting new renewable generation.`}
      </P>
      <P>
        {`While the electricity that powers your home will still come from a mix of sources, we'll stop buying the same amount of renewable electricity from our UK generator community to balance it all out.`}
      </P>
      <div data-testid="cancel-prompt">
        <AnalyticsClick name={PROFILE_ADDON_CANCEL_KEEP} properties={{ addon }}>
          <CTAButton
            variant="secondary"
            data-testid="cancel-prompt-cancel-greener-energy-modal"
            type="button"
            onClick={() => setCancelPrompt(false)}
            disabled={cancellation.isUpdating}
            fullWidth="always"
          >
            No, keep going further for the planet
          </CTAButton>
        </AnalyticsClick>
        <AnalyticsClick name={PROFILE_ADDON_CANCELLED} properties={{ addon }}>
          <CTAButton
            variant="primary"
            data-testid="cancel-button-greener-energy-modal"
            type="button"
            onClick={handleCancel}
            disabled={cancellation.isUpdating}
            fullWidth="always"
            style={{ marginBottom: '1.2rem' }}
          >
            Yes, cancel my subscription
          </CTAButton>
        </AnalyticsClick>
      </div>
    </GreenerEnergyModal>
  );
};

export default GreenerEnergyCancellationModal;
