import {
  Card,
  Heading4,
  P,
  SkeletonCircle,
  SkeletonCTA,
  SkeletonHeading,
  SkeletonText,
  Strong,
} from '@ovotech/nebula';
import React from 'react';
import { useTadoPricingQuery } from '../hooks/use-tado-pricing';
import { OrderType, PaymentType } from '../utils/types';
import {
  TotalContentWrapper,
  TotalTextWrapper,
  Divider,
  OrderSummaryCard,
  OrderSummaryWrapper,
  StyledProductImageCard,
  StyledProductImageWrapper,
  StyledProductTextWrapper,
  StyledProductTitleWrapper,
  TotalWrapper,
  ShippingTextWrapper,
} from './TadoOrderSummary.styled';
import TadoStarterKitImage from '@/src/resources/tado-starter-kit.png';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import { getBoltonPrice } from '../utils/utils';

export const TadoOrderSummary = () => {
  const promoCode = getQueryParamValue('promoCode');
  const tadoPricing = useTadoPricingQuery(promoCode);

  if (tadoPricing.isLoading) {
    return (
      <Card>
        <SkeletonHeading />
        <SkeletonText lines={3} />
        <SkeletonCircle />
        <SkeletonCTA />
      </Card>
    );
  }

  if (tadoPricing.isError || tadoPricing.data === undefined) {
    return <P>Something went wrong</P>;
  }

  const upfrontBoltonPricing = getBoltonPrice(
    tadoPricing.data,
    PaymentType.FullAmountPayment,
    OrderType.SelfInstall,
  );

  return (
    <OrderSummaryCard>
      <Heading4>Your tado° order</Heading4>
      <OrderSummaryWrapper>
        <StyledProductImageCard>
          <StyledProductImageWrapper src={TadoStarterKitImage} />
        </StyledProductImageCard>
        <StyledProductTitleWrapper>
          <Strong>tado° Wireless Smart Thermostat Starter Kit V3+</Strong>
        </StyledProductTitleWrapper>
      </OrderSummaryWrapper>

      <TotalWrapper>
        <TotalContentWrapper>
          <TotalTextWrapper>
            <StyledProductTextWrapper>
              <Strong>Subtotal</Strong>
            </StyledProductTextWrapper>
          </TotalTextWrapper>
          <StyledProductTextWrapper>
            <Strong>£{Number(upfrontBoltonPricing).toFixed(2)}</Strong>
          </StyledProductTextWrapper>
        </TotalContentWrapper>
        <Divider margin={1} />

        <TotalContentWrapper>
          <TotalTextWrapper>
            <ShippingTextWrapper>
              <Strong>Shipping</Strong>
              <P>Free shipping (3-5 days)</P>
            </ShippingTextWrapper>
          </TotalTextWrapper>
          <StyledProductTextWrapper>
            <Strong>Free</Strong>
          </StyledProductTextWrapper>
        </TotalContentWrapper>
        <Divider margin={1} />

        <TotalContentWrapper>
          <TotalTextWrapper>
            <StyledProductTextWrapper>
              <Strong>Total due</Strong>
            </StyledProductTextWrapper>
          </TotalTextWrapper>
          <StyledProductTextWrapper>
            <Strong>£{Number(upfrontBoltonPricing).toFixed(2)}</Strong>
          </StyledProductTextWrapper>
        </TotalContentWrapper>
      </TotalWrapper>
    </OrderSummaryCard>
  );
};
