import { Heading1, Heading3 } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { mqLargeUp, mqMediumDown, mqLargeDown } from '@/src/utils/mediaQuery';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 1fr min-content;
  justify-content: center;
  align-items: center;

  ${({ theme }) => `
    padding: ${theme.space[4]};
    grid-gap calc(${theme.space[4]} /2);
    color: ${theme.colors.primaryContrast};
    ${mqLargeDown(`
      padding: 0 calc(${theme.space[4]} / 4) ${theme.space[4]};
    `)}
  `}
`;

const Title = styled.div`
  grid-column: 1/2;
  grid-row: 1/2;
`;

const SubTitle = styled.div`
  grid-column: 1/2;
  grid-row: 2/2;

  ${mqMediumDown(`
    grid-column: 1/3;
  `)}
`;

const StyledGreenerElectricityHeaderTitle = styled.h1`
  margin: unset;
  ${({ theme }) => `
    font-size: ${theme.fontSizes[6]};
    color: ${theme.colors.primaryContrast};
  `};
`;

const StyledGreenerElectricityHeaderSubtitle = styled.h3`
  white-space: pre-wrap;
  margin: unset;
  ${({ theme: { colors } }) => `
    color: ${colors.primaryContrast};
  `};
`;

const StyledGreenerElectricityHeaderBgWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  position: absolute;
`;

const StyledGreenerElectricityHeaderBg = styled.div`
  ${({ theme: { colors, zIndexes } }) => `
  background-color: ${colors.brand.primary.dark};
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: 0% 100%;
  transform: rotate(-5deg);
  background: @teal;
  width: 120%;
  height: 500px;
  margin-left: -60vw;
  overflow: hidden;
  z-index: ${zIndexes.ovoBeyondBackground};

  ${mqLargeUp(`
    width: 110%;
    margin-left: -50%;
  `)}

  &:before {
    content: '';
    font-size: 18px;
    position: absolute;
    bottom: 0;
    left: -4%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6em 12em 0;
    border-color: transparent transparent ${colors.primary} transparent;
    background: ${colors.brand.primary.lighter};
    transform: rotate(33deg);
  }

  &:after {
    content: '';
    font-size: 50px;
    position: absolute;
    top: -33%;
    right: 0;
    width: 10em;
    height: 10em;
    margin-right: -5em;
    border-radius: 50%;
    background: ${colors.brand.primary.darker};
  }

  @media screen and (max-width: 1300px) {
     &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
`};
`;

export const GreenerElectricityHeader = () => {
  return (
    <Grid>
      <Title>
        <StyledGreenerElectricityHeaderTitle as={Heading1}>
          {`Upgrade to\nGreener Electricity`}
        </StyledGreenerElectricityHeaderTitle>
      </Title>
      <SubTitle>
        <StyledGreenerElectricityHeaderSubtitle
          as={Heading3}
        >{`Be part of the renewable\nelectricity future`}</StyledGreenerElectricityHeaderSubtitle>
      </SubTitle>
      <StyledGreenerElectricityHeaderBgWrapper>
        <StyledGreenerElectricityHeaderBg />
      </StyledGreenerElectricityHeaderBgWrapper>
    </Grid>
  );
};
