import { Heading4 } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

const HeadingContainer = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  max-width: 80px;
  min-width: 30px;
  margin-right: 16px;

  ${mqMediumDown(`
    margin-right: 12px;
  `)}
`;

const Title = styled(Heading4)`
  margin: 0;
`;

export const SectionHeader = ({
  Icon,
  children,
}: {
  Icon: React.ElementType;
  children: string;
}) => (
  <HeadingContainer>
    <IconWrapper>
      <Icon />
    </IconWrapper>
    <Title>{children}</Title>
  </HeadingContainer>
);
