import React, { ReactNode } from 'react';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';

interface MediaProps {
  query: string;
  children: ReactNode;
}

interface ParsedQuery {
  type: 'min' | 'max';
  value: number;
}

function parseQuery(input: string): ParsedQuery | null {
  const match = input.match(/^\((min|max)-width:\s*(\d+)\s*px\)$/);

  if (match) {
    const [, type, value] = match;

    return { type: type as 'min' | 'max', value: Number(value) };
  }

  console.warn(`Invalid query format: "${input}"`);

  return null;
}

export const Media: React.FC<MediaProps> = ({ query, children }) => {
  const parsed = parseQuery(query);
  const { width } = useWindowDimensions();

  if (!parsed) return null;

  const { type, value } = parsed;
  const shouldRender = type === 'min' ? width > value : width <= value;

  return shouldRender ? <>{children}</> : null;
};
