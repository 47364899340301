import {
  ContractsResponse,
  RecommendedDirectDebitResponse,
  useContractsLegacyQuery,
  useRecommendedDirectDebitLegacyQuery,
} from '@ovotech/energy-cx';
import { Margin, PrimaryCTALink } from '@ovotech/nebula';
import { QueryResultRenderer, useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import RecommendedDirectDebitInfo from './RecommendedDirectDebitInfo/RecommendedDirectDebitInfo';
import selector from './selector';
import {
  ErrorSection,
  PageWithNavigation,
  RouterLink,
  Section,
} from '@/src/components';
import { RECOMMENDED_DD_ERROR } from '@/src/constants/error-section-ids';
import { ROUTE_CHANGE_DD_AMOUNT } from '@/src/constants/routes';
import { RECOMMENDED_DD_SECTION_ID } from '@/src/constants/sectionIds';

const RecommendedDirectDebit = ({
  data: [recommendedDirectDebit, contracts],
}: {
  data: readonly [RecommendedDirectDebitResponse, ContractsResponse];
}) => {
  const data = selector({ contracts, recommendedDirectDebit });

  return (
    <PageWithNavigation title="Recommended Direct Debit">
      <Section id={RECOMMENDED_DD_SECTION_ID}>
        <Margin bottom={6}>
          <RecommendedDirectDebitInfo {...data} />
          <PrimaryCTALink to={ROUTE_CHANGE_DD_AMOUNT} as={RouterLink}>
            Change Direct Debit amount
          </PrimaryCTALink>
        </Margin>
      </Section>
    </PageWithNavigation>
  );
};

RecommendedDirectDebit.displayName = 'RecommendedDirectDebit';

const Error = () => (
  <ErrorSection alertLevel="warn" id={RECOMMENDED_DD_ERROR} />
);

const RecommendedDirectDebitWithData = ({
  accountId,
}: {
  accountId: string;
}) => {
  const recommendedDDResult = useRecommendedDirectDebitLegacyQuery(accountId);
  const contractsResult = useContractsLegacyQuery(accountId);
  return (
    <QueryResultRenderer
      queryResults={[recommendedDDResult, contractsResult] as const}
      Error={Error}
      NoData={() => null}
      Data={RecommendedDirectDebit}
    />
  );
};

const RecommendedDirectDebitMain = () => {
  const { accountId } = useAccountContext();
  if (!accountId) {
    return null;
  }
  return <RecommendedDirectDebitWithData accountId={accountId} />;
};

export default RecommendedDirectDebitMain;
