import { Card } from '@ovotech/nebula';
import styled from 'styled-components';

const borderColour = '#F3F3F3';

export const MonthlySummarySection = styled(Card)`
  display: flex;
`;

export const MonthlySummary = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: center;
`;

export const MonthlySummaryWithLeftBorder = styled(MonthlySummary)`
  border-left: 2px solid ${borderColour};
`;

export const IntroText = styled.span`
  ${({ theme: { fontSizes, colors } }) => `
    font-size: ${fontSizes[1]};
    color: ${colors.shadeMid};
  `}
`;

export const EmphasisedText = styled.span`
  ${({ theme: { fontWeights, fontSizes, colors } }) => `
    font-size: ${fontSizes[1]};
    font-weight: ${fontWeights.bold};
    color: ${colors.shadeMid};
  `}
`;
