import { P, TextLink, Modal } from '@ovotech/nebula';
import React from 'react';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import AnalyticsService from '@/src/services/analyticsService';

export const HowWeCalculateItModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <P>
        <TextLink
          onClick={() => {
            AnalyticsService.dispatch(
              ENERGY_INSIGHTS.BUTTON_CLICK_BREAKDOWN_HOW_CALC,
            );
            return setIsOpen(true);
          }}
          href="#"
        >
          How we calculate your energy use
        </TextLink>
      </P>
      <Modal
        title="How we calculate your energy use"
        // TODO: Check if Nebula types are correct
        // @ts-ignore - it looks Nebula types might be missing this?
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <P>
          It all comes down to the magic of your smart meter. Because it sends
          us regular readings, we’re able to estimate how much energy you’re
          using. We then use that info to give you your very own energy-saving
          tips.
        </P>
      </Modal>
    </>
  );
};
