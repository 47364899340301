import { mqSmallDown } from '@/src/utils/mediaQuery';
import styled from 'styled-components';

export const StyledFeedbackPrompt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  ${({ theme: { space } }) => `
    margin-top: ${space[6]};
  `}
  ${mqSmallDown(`
      flex-flow: column
  `)}
`;

export const StyledFeedbackTextWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: bold;
    color: #2f3749;
    margin-top: 0;
  }
  svg {
    max-height: 3.5rem;
  }
  ${({ theme: { space } }) => `
    margin-right: ${space[2]};
    ${mqSmallDown(`
      margin-right: 0;
      margin-bottom: ${space[4]};
  `)}
  `}
`;
