import { PrimaryCTALink, PrimaryCTAButton } from '@ovotech/nebula';
import React, { useState } from 'react';

import { SMART_THERMOSTATS } from '@/src/constants/analytics';

export const ConnectButton = ({ authLink }: { authLink: string | null }) => {
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClick = () => {
    setButtonClicked(true);
  };
  if (!buttonClicked) {
    return (
      <PrimaryCTALink
        onClick={handleClick}
        variant="missionPrimary"
        href={authLink}
        data-event-name={
          SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.NOT_CONNECTED.CLICKED.CONNECT
        }
      >
        Connect
      </PrimaryCTALink>
    );
  } else {
    return (
      <PrimaryCTAButton variant="missionPrimary">
        Taking you to tado...
      </PrimaryCTAButton>
    );
  }
};
