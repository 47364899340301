import { useHistory } from 'react-router-dom-v5';

export type UrlActionMap = {
  url: string;
  action: () => void;
};

export const useContentfulActionUrlMap = () => {
  const history = useHistory();

  const ctaUrlToActionMap: Array<UrlActionMap> = [
    {
      url: 'https://account.ovoenergy.com/?modal=balance_review_has_dd',
      action: () =>
        history.push({
          search: `modal=balance_review_has_dd`,
        }),
    },
    {
      url: 'https://account.ovoenergy.com/?modal=balance_review_no_dd',
      action: () =>
        history.push({
          search: `modal=balance_review_no_dd`,
        }),
    },
  ];

  const getActionByUrl = (url: string | undefined) =>
    ctaUrlToActionMap.find(item => item.url === url) || undefined;

  return { getActionByUrl };
};
