export const listOfWords = (wordsArr?: null | Array<string>): null | string => {
  if (wordsArr == null || wordsArr.length === 0) {
    return null;
  }

  return wordsArr.reduce(
    (acc: string, curr: string, index: number, arr: Array<string>) => {
      if (arr.length === 1) {
        return acc;
      }
      if (index === arr.length - 1) {
        return `${acc} and ${curr}`;
      }
      return `${acc}, ${curr}`;
    },
  );
};

export const generateName = (
  title?: null | string,
  givenName?: null | string,
  familyName?: null | string,
): null | string => {
  const filteredItems = [title, givenName, familyName].filter(arg =>
    Boolean(arg),
  );

  return filteredItems.length === 0 ? null : filteredItems.join(' ');
};

export const formatNumberWithSuffix = (int: number): string => {
  if (int > 3 && int < 21) {
    return `${int}th`;
  }
  switch (int % 10) {
    case 1:
      return `${int}st`;
    case 2:
      return `${int}nd`;
    case 3:
      return `${int}rd`;
    default:
      return `${int}th`;
  }
};

export const capitalise = (str: string) =>
  str.replace(/^\w/, c => c.toUpperCase());
