import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from './Header';
import { State as ReduxState } from '@/src/types/State';
import { AccountSupplyPointFragmentFragment } from '@/src/api/kapi/__generated__/graphql';

type Props = {
  path: string;
  smartEligible: boolean;
  hasMultipleAccounts: boolean;
  hasSmartMeterBooking: boolean | undefined;
  supplies: readonly AccountSupplyPointFragmentFragment[];
};

type State = {
  isMobileMenuOpen: boolean;
};

class HeaderContainer extends Component<Props, State> {
  state = {
    isMobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen,
    });
  };

  closeMobileMenu = () => {
    if (this.state.isMobileMenuOpen) {
      this.setState({
        isMobileMenuOpen: false,
      });
    }
  };

  render() {
    return (
      <Header
        path={this.props.path}
        supplies={this.props.supplies}
        smartEligible={this.props.smartEligible}
        isMobileMenuOpen={this.state.isMobileMenuOpen}
        hasMultipleAccounts={this.props.hasMultipleAccounts}
        hasSmartMeterBooking={this.props.hasSmartMeterBooking}
        closeMobileMenu={this.closeMobileMenu}
        toggleMobileMenu={this.toggleMobileMenu}
      />
    );
  }
}

const mapStateToProps = ({
  user: { accountIds },
  smartMeterBooking,
}: ReduxState) => ({
  hasMultipleAccounts: accountIds.length > 1,
  smartEligible: smartMeterBooking.showBanner,
  hasSmartMeterBooking: smartMeterBooking.data?.booking.hasBooking,
});

export default connect(mapStateToProps)(HeaderContainer);
