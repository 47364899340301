import React, { ReactNode, useContext } from 'react';
import { AnimatedValue, useSpring } from 'react-spring/web.cjs';

import {
  StyledGreenCirclesShape,
  StyledGreenRectangle,
  StyledGreenSquare,
  StyledGreenPolygonShape,
  StyledGreenTriangleShape,
  StyledSmallGreenCirclesShape,
  StyledYellowDonutShape,
  StyledYellowDonutThin,
} from './ParallaxShape.styled';

type ShapeType =
  | 'greenCircles'
  | 'greenTriangle'
  | 'greenRectangle'
  | 'smallGreenCircles'
  | 'yellowDonut'
  | 'yellowDonutThin'
  | 'greenSquare'
  | 'greenPolygon';

type Props = {
  type: ShapeType;
  style?: React.CSSProperties;
};

type ProviderProps = {
  children: ReactNode;
};

const shapeTypes = {
  greenCircles: StyledGreenCirclesShape,
  yellowDonut: StyledYellowDonutShape,
  smallGreenCircles: StyledSmallGreenCirclesShape,
  greenTriangle: StyledGreenTriangleShape,
  greenSquare: StyledGreenSquare,
  yellowDonutThin: StyledYellowDonutThin,
  greenRectangle: StyledGreenRectangle,
  greenPolygon: StyledGreenPolygonShape,
};

const ParallaxShapeContext = React.createContext<AnimatedValue<any> | null>(
  null,
);

export function ParallaxShapeProvider({ children }: ProviderProps) {
  const [{ scrollY }, set] = useSpring(() => ({
    scrollY: 0,
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const onScroll = React.useCallback(
    () => set({ scrollY: window.scrollY / 10 }),
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <ParallaxShapeContext.Provider value={scrollY}>
      {children}
    </ParallaxShapeContext.Provider>
  );
}

function ParallaxShape({ type, style = {} }: Props) {
  const scrollY = useContext(ParallaxShapeContext);

  return React.createElement(shapeTypes[type], {
    style: {
      ...style,
      transform:
        scrollY &&
        scrollY.interpolate((scrollY: number) => `translateY(${scrollY}px)`),
    },
  });
}

export default ParallaxShape;
