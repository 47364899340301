import { P, PrimaryCTAButton, Strong, TextLink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';

import React, { Fragment } from 'react';
import { SMART_THERM_TERMS_AND_CONDITIONS_LINK } from '../constants';
import { getBoltonPrice } from '../utils/utils';
import { ConnectedWaitlistButton } from './ConnectedWaitlistButton';
import {
  StyledWhiteSegment,
  SegmentButton,
  FlexWrapperRow,
  NoMarginText,
} from './ProductContent.styled';
import { FootnoteReferenced } from '@/src/components';
import { PRIVACY_POLICY_LINK } from '@/src/constants/analytics-subjects';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { SmartThermostatsPricing } from '@/src/types/Response';
import { OrderType, PaymentType } from '../utils/types';

export const ActionSegment = ({
  eligible,
  goToCompatibilityStep,
  pricing,
}: {
  eligible: boolean | undefined;
  goToCompatibilityStep: () => void;
  pricing: SmartThermostatsPricing;
}) => {
  const upfrontTotal = getBoltonPrice(
    pricing,
    PaymentType.FullAmountPayment,
    OrderType.SelfInstall,
  );
  const monthlyPaymentAmount =
    getBoltonPrice(pricing, PaymentType.MonthlyPayment, OrderType.SelfInstall) /
    12;

  if (eligible) {
    return (
      <StyledWhiteSegment>
        <FlexWrapperRow>
          <NoMarginText as={P}>
            Pay <Strong>£{upfrontTotal} now</Strong>
          </NoMarginText>
          <NoMarginText as={P}>
            or <Strong>£{monthlyPaymentAmount} a month for 12 months</Strong>
            <FootnoteReferenced
              content={{
                id: 'PriceExplanation',
                body: [
                  <Fragment key="PriceExplanation">
                    You can pay upfront or spread the cost over 12 interest free
                    monthly installments of £{monthlyPaymentAmount}. Monthly
                    payment option is subject to eligibility and credit status.
                    Please see our tadoº{' '}
                    <AnalyticsClick
                      name={
                        SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE
                          .CLICKED.TERMS_AND_CONDITIONS
                      }
                    >
                      <TextLink
                        href={SMART_THERM_TERMS_AND_CONDITIONS_LINK}
                        opensInNewWindow
                      >
                        terms and conditions
                      </TextLink>
                    </AnalyticsClick>{' '}
                    for more information.
                  </Fragment>,
                ],
              }}
            />
          </NoMarginText>
        </FlexWrapperRow>
        <AnalyticsClick
          name={
            SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED.BUY_NOW
          }
          properties={{ buttonPlacement: 'top-banner' }}
        >
          <SegmentButton
            data-testid="tado-buy-now"
            as={PrimaryCTAButton}
            variant="secondary"
            onClick={goToCompatibilityStep}
          >
            Buy now
          </SegmentButton>
        </AnalyticsClick>
      </StyledWhiteSegment>
    );
  }

  return (
    <StyledWhiteSegment>
      <FlexWrapperRow>
        <NoMarginText as={P}>
          We can email you when it’s available.
        </NoMarginText>
        <NoMarginText as={P}>
          By clicking this, you agree with our{' '}
          <TextLink href={PRIVACY_POLICY_LINK} opensInNewWindow>
            Privacy Policy
          </TextLink>
          .
        </NoMarginText>
      </FlexWrapperRow>
      <ConnectedWaitlistButton />
    </StyledWhiteSegment>
  );
};
