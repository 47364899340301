import { Heading3, P, PrimaryCTALink } from '@ovotech/nebula';
import React from 'react';
import { RouterLink } from '@/src/components';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { BANNER_CTA_CLICKED } from '@/src/constants/analytics';
import { ROUTE_PROFILE } from '@/src/constants/routes';

export const CheckContactDetails: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  return (
    <Banner
      data-testid="check-contact-details"
      icon="checkPersonalDetails"
      dismiss={dismiss}
    >
      <Heading3 as="h2">Please update your contact information</Heading3>
      <P>
        You haven’t provided a valid email address or telephone number we can
        reach you on. Please update your contact detail and preferences.
      </P>
      <P>
        <PrimaryCTALink
          onClick={onClick}
          as={RouterLink}
          to={ROUTE_PROFILE}
          data-testid="check-contact-details-cta"
          data-event-name={BANNER_CTA_CLICKED}
          data-event-props={JSON.stringify({
            bannerId: 'checkContactDetails',
            ctaName: 'Update contact details',
          })}
        >
          Update contact details
        </PrimaryCTALink>
      </P>
    </Banner>
  );
};
