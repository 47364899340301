import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom-v5';
import { SimplePageHeader } from '@/src/components/Pages/SimplePageHeader';
import { EVInlifeSmartChargerDisconnectConfirmation } from '../components/EVInlifeDisconnect/EVInlifeSmartChargerDisconnectConfirmation';
import { EVInlifeSmartChargerDisconnectRequest } from '../components/EVInlifeDisconnect/EVInlifeSmartChargerDisconnectRequest';
import { PageWithNavigation } from '@/src/components';
import { EV } from '@monovo/ev-anytime';
import {
  ROUTE_EV,
  ROUTE_EV_DISCONNECT,
  ROUTE_EV_DISCONNECT_CONFIRMATION,
} from '@/src/constants/routes';

const NotFoundRedirect = () => <Redirect to={ROUTE_EV} />;

const SmartChargerDisconnectContent = () => (
  <Switch>
    <Route exact path={ROUTE_EV_DISCONNECT}>
      <EVInlifeSmartChargerDisconnectRequest />
    </Route>
    <Route exact path={ROUTE_EV_DISCONNECT_CONFIRMATION}>
      <EVInlifeSmartChargerDisconnectConfirmation />
    </Route>
    <Route component={NotFoundRedirect} />
  </Switch>
);

export const EVsInlifeSmartChargerDisconnect = () => (
  <PageWithNavigation
    analytics={{ name: EV.INLIFE.EVS_INLIFE_DISCONNECT_VIEWED }}
    title="Your EV - Disconnect"
  >
    <>
      <SimplePageHeader
        title="Your EV"
        subTitle="Amazing insights, exclusive offers, and the latest news – tailored to you"
      />
      <SmartChargerDisconnectContent />
    </>
  </PageWithNavigation>
);
