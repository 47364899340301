import { ErrorNotification, SkeletonCTA } from '@ovotech/nebula';
import React from 'react';
import TescoCta from '@/src/resources/tescoRewards/tesco-logo-sign-in.png';
import { useTescoRewardsAuthMutation } from '@/src/api/use-tesco-rewards-auth-mutation';
import { useAccountContext, useAnalytics } from '@ovotech/ui-tools';
import { TescoRewardAuthResponse } from '@/src/types/Response';
import { UnstyledButton } from './styles';
import { CLUBCARD_ERROR_QUERY_PARAM_KEY } from '../../constants';
import { TESCO_EVENTS } from '@/src/constants/analytics';

const onMutationSuccess = (data: TescoRewardAuthResponse) => {
  window.location.assign(data.authorisationUrl);
};

export const ClubcardSignup = () => {
  const { accountId } = useAccountContext();

  const { mutate, isLoading, isError } = useTescoRewardsAuthMutation(accountId);

  const { dispatch } = useAnalytics();

  if (isLoading) return <SkeletonCTA />;
  if (isError)
    return (
      <ErrorNotification id="tesco-reward-auth-error">
        We're sorry, something went wrong.
      </ErrorNotification>
    );

  return (
    <UnstyledButton
      onClick={() => {
        dispatch({
          name: TESCO_EVENTS.CLICKED_TESCO_SIGN_IN,
          type: 'click',
        });
        mutate(
          {
            successRedirectUrl: window.location.href,
            failureRedirectUrl: `${window.location.href}?${CLUBCARD_ERROR_QUERY_PARAM_KEY}=error`,
          },
          {
            onSuccess: onMutationSuccess,
          },
        );
      }}
    >
      <img src={TescoCta} alt="Sign in with Tesco" />
    </UnstyledButton>
  );
};
