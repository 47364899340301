import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const CardWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const HeaderWrapper = styled.div`
  width: 47%;
  ${({ theme: { space } }) => `
    margin-bottom: ${space[6]};
  `}
  ${mqMediumDown(`
    width: 100%;
    padding-top: 95%
  `)}
`;

export const StyledImageWrapper = styled.img`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  /* Custom breakpoints like these aren't ideal, but until this card is redesigned 
  with these 'in-between' sizes in mind it will have to do. Otherwise the image risks
  overlapping the text. */
  @media (max-width: 1160px) {
    right: -10%;
  }

  @media (max-width: 850px) {
    right: -12%;
  }
`;

export const StyledMobileImageWrapper = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
`;

export const FooterWrapper = styled.div`
  max-width: 50%;
  ${mqMediumDown(`
    max-width: 100%;
  `)}
  > p {
    ${({ theme: { fontSizes } }) => `
      font-size: ${fontSizes[1]};
    `}
  }
`;

export const ListContainer = styled.div`
  max-width: 50%;
  padding-bottom: 3.4rem;
  ${mqMediumDown(`
    max-width: 100%;
  `)}
`;
