import { createPostNextBestActionEndpoint } from '@/src/constants/endpoints';
import { NbaSubmissionRequestBody } from '@/src/types/NextBestAction';
import { apiService } from '@/src/services/api-service';
import { useMutation } from 'react-query';

export const useNextBestActionMutation = () => {
  const url = createPostNextBestActionEndpoint;

  return useMutation((body: NbaSubmissionRequestBody) => {
    return apiService.post({
      url,
      body,
    });
  });
};
