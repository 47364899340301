import React, { ComponentType, Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { FuelType } from '@/src/types/Account';
import { SelectedAccountSupply, State as ReduxState } from '@/src/types/State';

type Props = {
  gasSupply: SelectedAccountSupply;
  elecSupply: SelectedAccountSupply;
  IsTraditional?: null | ComponentType;
  IsSmart?: null | ComponentType;
  HasTraditional?: null | ComponentType<{ fuels: Array<FuelType> }>;
  HasSmart?: null | ComponentType<{ fuels: Array<FuelType> }>;
  IsMixed?: null | ComponentType;
};

const MeterSwitch: FunctionComponent<Props> = ({
  gasSupply,
  elecSupply,
  IsSmart = null,
  IsTraditional = null,
  IsMixed = null,
  HasTraditional = null,
  HasSmart = null,
}: Props) => {
  const smartFuels: Array<FuelType> = [];
  const tradFuels: Array<FuelType> = [];

  const isGasSmart =
    gasSupply && gasSupply.supplyPointInfo && gasSupply.supplyPointInfo.isSmart;

  const isElecSmart =
    elecSupply &&
    elecSupply.supplyPointInfo &&
    elecSupply.supplyPointInfo.isSmart;

  if (isElecSmart) {
    smartFuels.push('electricity');
  } else {
    tradFuels.push('electricity');
  }

  if (isGasSmart) {
    smartFuels.push('gas');
  } else {
    tradFuels.push('gas');
  }

  return (
    <Fragment>
      {isGasSmart || isElecSmart
        ? HasSmart && <HasSmart fuels={smartFuels} />
        : null}
      {!isGasSmart || !isElecSmart
        ? HasTraditional && <HasTraditional fuels={tradFuels} />
        : null}
      {isGasSmart && isElecSmart ? IsSmart && <IsSmart /> : null}
      {!isGasSmart && !isElecSmart ? IsTraditional && <IsTraditional /> : null}
      {(!isGasSmart && isElecSmart) || (isGasSmart && !isElecSmart)
        ? IsMixed && <IsMixed />
        : null}
    </Fragment>
  );
};

const mapStateToProps = ({ app: { gasSupply, elecSupply } }: ReduxState) => ({
  gasSupply,
  elecSupply,
});

export default connect(mapStateToProps)(MeterSwitch);
