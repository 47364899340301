import React, { PropsWithChildren, ReactNode } from 'react';
import Accordion, { Section, Header, Panel } from 'react-aria-accordion';

import {
  StyledCollapsibleContentHeader,
  StyledCollapsibleContentButton,
  StyledCollapsibleContentIconWrapper,
  StyledCollapsibleContentPanel,
  StyledCollapsibleContentSection,
} from './CollapsibleContent.styled';
import { ReactComponent as DownIcon } from '@/src/resources/chevron-down.svg';
import { ReactComponent as UpIcon } from '@/src/resources/chevron-up.svg';

type CollapsibleHeaderProps = {
  headerContent: ReactNode;
  arrowPosition?: 'left' | 'right';
  onClick?: () => any;
};

type ButtonToggleProps = {
  onClick: VoidFunction;
  'data-testid': string;
  arrowPosition: string | undefined;
};

type HeaderContentProps = {
  getButtonToggleProps: (props: ButtonToggleProps) => {
    arrowPosition: 'left' | 'right';
  };
  expanded: boolean;
};

export const CollapsibleHeader = ({
  headerContent,
  arrowPosition,
  onClick = () => null,
}: CollapsibleHeaderProps) => (
  <Header>
    {({ getButtonToggleProps, expanded }: HeaderContentProps) => (
      <StyledCollapsibleContentHeader as="h3">
        <StyledCollapsibleContentButton
          {...getButtonToggleProps({
            onClick,
            'data-testid': 'collapsible-header',
            arrowPosition,
          })}
        >
          {headerContent}
          <StyledCollapsibleContentIconWrapper arrowPosition={arrowPosition}>
            {expanded ? <UpIcon /> : <DownIcon />}
          </StyledCollapsibleContentIconWrapper>
        </StyledCollapsibleContentButton>
      </StyledCollapsibleContentHeader>
    )}
  </Header>
);

export const CollapsiblePanel = ({ children }: PropsWithChildren) => (
  <Panel>
    {({ getPanelProps }) => (
      <StyledCollapsibleContentPanel
        {...getPanelProps()}
        data-testid="collapsible-panel"
      >
        {children}
      </StyledCollapsibleContentPanel>
    )}
  </Panel>
);

type CollapsibleSectionProps = {
  defaultExpanded?: boolean;
  onToggle?: (collapsed: boolean) => void;
  isNested?: boolean;
  noPadding?: boolean;
};

export const CollapsibleSection = ({
  defaultExpanded,
  onToggle,
  children,
  isNested,
  noPadding,
}: PropsWithChildren<CollapsibleSectionProps>) => (
  <Section defaultExpanded={defaultExpanded} onToggle={onToggle}>
    {({ getSectionProps }) => (
      <StyledCollapsibleContentSection
        {...getSectionProps()}
        data-testid="collapsible-section"
        isNested={isNested}
        noPadding={noPadding}
      >
        {children}
      </StyledCollapsibleContentSection>
    )}
  </Section>
);

/**
 * @deprecated Accordion is from a very old library that uses vulnerable dependencies. We should remove it asap.
 * When we remove it, we can remove the custom resolution in package.json for ts-jest
 */
export const CollapsibleGroup = ({ children }: PropsWithChildren) => (
  <Accordion>
    {() => <div data-testid="collapsible-group">{children}</div>}
  </Accordion>
);
