import styled from 'styled-components';
import { mqMobileNav } from '@/src/utils/mediaQuery';

export const ResponsiveBr = styled.br`
  display: none;

  ${mqMobileNav(`
    display: block;
      `)}
`;
