import { mqLargeDown, mqMediumDown, mqSmallDown } from '@/src/utils/mediaQuery';
import styled from 'styled-components';

export const ThermostatInfoContainer = styled.div`
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  background-image: url("data:image/svg+xml,%3Csvg width='150' height='300' viewBox='0 0 160 112' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='110' cy='55' r='110' fill='%23006945'/%3E%3C/svg%3E");
  background-repeat: no-repeat, no-repeat;
  background-size: auto, 100%;
  background-position-y: center;
  background-position-x: right;
  border: none;
  ${mqSmallDown(`
    background-image: url("data:image/svg+xml,%3Csvg width='500' height='115' viewBox='0 0 288 82' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='144' cy='5.5' rx='185' ry='75.5' fill='%23006945'/%3E%3C/svg%3E%0A");
    background-size: auto,50%;
    background-position-y: top;
    background-position-x: center;
    flex-direction: column-reverse;
    align-items: start;
    * {
      text-align: center;
    }
  `)}
`;

export const HeadingNoMargin = styled.h2`
  margin: 0;
`;

export const StyledSubtitle = styled.p`
  font-size: 0.8em;
  color: #555c6b;
  font-weight: 400;
`;

export const ThermostatInfoRightSection = styled.div`
  text-align: right;
  max-width: 18%;
  * {
    color: white;
    word-break: break-word;
  }
  ${mqLargeDown(`
    max-width: 12%;
  `)}
  ${mqMediumDown(`
    max-width: 20%;
  `)}
  ${({ theme: { space } }) => `
    ${mqSmallDown(`
      margin-top: ${space[2]};
      margin-bottom: ${space[12]};
      max-width: 100%;
      width: 100%;
    `)}
  `}
`;

export const ThermostatInfoLeftSection = styled.div`
  ${({ theme: { space } }) => `
    ${mqSmallDown(`
      * {
        text-align: left;
      }
      margin-top: ${space[2]}
    `)}
  `}
`;
