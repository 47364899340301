import React, { useEffect, useState } from 'react';
import {
  P,
  Card,
  Heading2,
  Margin,
  ErrorNotification,
  Heading4,
} from '@ovotech/nebula';
import { NotFoundSection } from '@ovotech/energy-cx/dist/shared/core/components';
import { ENABLE_PARTNERS } from '@/src/constants/feature-flags';
import { useDecision } from '@/src/utils/optimizely';
import { EligibilityStatus } from './components/EligibilityStatus/EligibilityStatus';
import { QuestionsAndAnswers } from './components/QuestionsAndAnswers';
import { useHistory, useLocation } from 'react-router-dom-v5';
import { CLUBCARD_ERROR_QUERY_PARAM_KEY } from './constants';
import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';
import { RewardCard } from './components/RewardCard';
import { GetOffersCard } from './components/GetOffersCard';
import { useTescoRewards } from '@/src/api/use-tesco-rewards';
import { useAccountContext, useAnalytics } from '@ovotech/ui-tools';
import { ContentLoader } from './components/ContentLoader';
import { TescoError } from './components/TescoError';
import { TESCO_EVENTS } from '@/src/constants/analytics';

export const StyledCardsWrapper = styled.div`
  gap: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${mqMediumDown(`
    display: flex;
    flex-direction: column;
`)};
`;

const StyledHeading = styled(Heading4)`
  color: ${({ theme: { colors } }) => colors.shadeMid};
  margin-bottom: 0;
`;

export const PartnersTesco = () => {
  const [enablePartnersDecision] = useDecision(ENABLE_PARTNERS);
  const showTescoPartnership =
    enablePartnersDecision?.enabled && enablePartnersDecision?.variables?.tesco;
  const { accountId } = useAccountContext();
  const { data, isLoading, isFetched, isError, error } =
    useTescoRewards(accountId);
  const [hasClubcardLinkError, setHasClubcardLinkError] =
    useState<boolean>(false);
  const history = useHistory();
  const { search } = useLocation();
  const { dispatch } = useAnalytics();

  const hasDirectDebit = data?.hasDirectDebit;
  const hasSmartMeter = data?.hasSmartMeter;
  const hasClubcardLinked = data?.clubcardLinked;
  const hasActivatedOffer = data?.applicationCreated;
  const isApplicationComplete = data?.applicationComplete;

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const hasError =
      queryParams.get(CLUBCARD_ERROR_QUERY_PARAM_KEY) === 'error';

    if (hasError) {
      setHasClubcardLinkError(hasError);
      queryParams.delete(CLUBCARD_ERROR_QUERY_PARAM_KEY);
      history.replace({ search: `${queryParams.toString()}` });
    }
  }, [hasClubcardLinkError, history, search]);

  useEffect(() => {
    if (showTescoPartnership && hasDirectDebit !== undefined) {
      dispatch({
        name: TESCO_EVENTS.VIEWED_TESCO_PARTNERS_PAGE,
        type: 'view',
        properties: {
          hasDirectDebit: `${hasDirectDebit}`,
          hasSmartMeter: `${hasSmartMeter}`,
          clubcardLinked: `${hasClubcardLinked}`,
        },
      });
    }
    // dispatch is not stable across renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetched,
    hasClubcardLinked,
    hasDirectDebit,
    hasSmartMeter,
    showTescoPartnership,
  ]);

  return showTescoPartnership ? (
    <>
      <Card>
        {hasClubcardLinkError && (
          <ErrorNotification dismissable id="error-clubcard-link">
            Sorry, there was an error linking your Tesco Clubcard.
          </ErrorNotification>
        )}
        <StyledHeading>Tesco Clubcard</StyledHeading>
        <Heading2 style={{ marginTop: 0 }}>
          We’ve partnered with Tesco Clubcard
        </Heading2>
        <P>Join OVO on the Path to Zero and collect 2,500 Clubcard points.</P>
      </Card>
      {isLoading ? (
        <ContentLoader />
      ) : isError ? (
        <Margin top={10} bottom={10}>
          <TescoError error={error} />
        </Margin>
      ) : (
        <>
          <Margin top={10} bottom={10}>
            <Card>
              <Heading2>Check you're eligible</Heading2>
              <EligibilityStatus
                hasDirectDebit={hasDirectDebit}
                hasSmartMeter={hasSmartMeter}
                hasClubcardLinked={hasClubcardLinked}
              />
            </Card>
          </Margin>
          <Margin bottom={10}>
            <StyledCardsWrapper>
              <RewardCard
                hasDirectDebit={hasDirectDebit}
                hasSmartMeter={hasSmartMeter}
                hasClubcardLinked={hasClubcardLinked}
                hasActivatedOffer={hasActivatedOffer}
                isApplicationComplete={isApplicationComplete}
              />
              <GetOffersCard />
            </StyledCardsWrapper>
          </Margin>
        </>
      )}
      <QuestionsAndAnswers />
    </>
  ) : (
    <NotFoundSection />
  );
};
