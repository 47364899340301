import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ArrowButton } from './ArrowButton';
import {
  SlideCounterButton,
  SlideCounterContainer,
  StyledSlideItem,
  StyledSlideshow,
  StyledSlideshowNav,
} from './Slideshow.styled';

type SlideItemProps = {
  children: ReactNode;
  active: boolean;
};
const SlideItem = ({ children, active }: SlideItemProps) => (
  <StyledSlideItem aria-hidden={!active} active={active}>
    {children}
  </StyledSlideItem>
);

type SlideCounterProps = {
  // Need an array which is the same length as the number of components
  // Ideally this would be inferred from the children passed into the Slideshow
  // BUT this would require a more indepth refactor to achieve
  slides: Array<any>;
  activeIndex: number;
  goToSlide: (index: number) => void;
};

export const SlideCounter = ({
  slides,
  activeIndex,
  goToSlide,
}: SlideCounterProps) => (
  <SlideCounterContainer>
    {slides.map((_, index) => (
      <SlideCounterButton
        aria-label={`slide ${index + 1} of ${slides.length}, ${
          index === activeIndex ? 'selected' : 'unselected'
        }`}
        key={index}
        onClick={() => goToSlide(index)}
        active={index === activeIndex}
      />
    ))}
  </SlideCounterContainer>
);

type SideshowProps = {
  children: ReactNode[];
  wrap?: boolean;
  dataTestId?: string;
  trackingOptions?: {
    shiftLeft: () => void | undefined;
    shiftRight: () => void | undefined;
    trackEnd?: () => void | undefined;
  };
};

export const Slideshow = ({
  children,
  wrap = false,
  dataTestId,
  trackingOptions,
}: SideshowProps) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const isDisabled = useCallback(
    (direction?: 'left' | 'right'): boolean => {
      if (direction === 'left') {
        return activeSlide === 0;
      } else if (direction === 'right') {
        return activeSlide === children.length - 1;
      }
      return false;
    },
    [activeSlide, children],
  );

  useEffect(() => {
    if (trackingOptions?.trackEnd) {
      if (isDisabled('right') === true) {
        trackingOptions.trackEnd();
      }
    }
  }, [isDisabled, trackingOptions]);

  return (
    <StyledSlideshow data-testid={dataTestId}>
      <StyledSlideshowNav>
        <ArrowButton
          direction="left"
          disabled={isDisabled('left')}
          onClick={() => {
            setActiveSlide(
              activeSlide === 0
                ? wrap
                  ? children.length - 1
                  : activeSlide
                : activeSlide - 1,
            );
            if (trackingOptions?.shiftLeft) {
              trackingOptions.shiftLeft();
            }
          }}
          size={36}
          dataTestId="SlideshowLeftButton"
          ariaLabel="View previous slide"
          margin="0px 4px 0px 0px"
        />
        {children.map((Child, index) => (
          <SlideItem key={index} active={index === activeSlide}>
            {Child}
          </SlideItem>
        ))}
        <ArrowButton
          direction="right"
          disabled={isDisabled('right')}
          onClick={() => {
            setActiveSlide(
              activeSlide === children.length - 1
                ? wrap
                  ? 0
                  : activeSlide
                : activeSlide + 1,
            );
            if (trackingOptions?.shiftRight) {
              trackingOptions.shiftRight();
            }
          }}
          size={36}
          dataTestId="SlideshowRightButton"
          ariaLabel="View next slide"
          margin="0px 0px 0px 4px"
        />
      </StyledSlideshowNav>

      <SlideCounter
        slides={children}
        activeIndex={activeSlide}
        goToSlide={index => {
          if (index < 0 || index > children.length - 1) {
            return;
          }

          setActiveSlide(index);
        }}
      />
    </StyledSlideshow>
  );
};
