import { AvailablePlan } from './types';

export const VAT_RATE_MULTIPLIER = 1.05; // Add 5% to the cost.

export type UnitRateLabel =
  | 'Day unit rate'
  | 'Night unit rate'
  | 'Peak unit rate'
  | 'Off-peak unit rate'
  | 'Heating unit rate'
  | 'Evening and weekend unit rate'
  | 'Unit rate';

export enum PlanFamily {
  threeYearFixed = '3 Year Fixed',
  twoYearFixed = '2 Year Fixed',
  twoYearFixAndProtect = '2 Year Fix And Protect',
  betterEnergy = 'Better Energy',
  oneYearFixedBoilerCover = '1 Year Fixed + Boiler Cover',
  oneYearFixed = '1 Year Fixed',
  simplerEnergy = 'Simpler Energy',
  evEverywhere = 'EV Everywhere',
  drive = 'Drive',
  memberSpecial = 'Member Special',
  stayAndSave = '1 Year Fixed Stay & Save',
  betterSmart = 'Better Smart',
  oneYearFixedGreenerEnergy = '1 Year Fixed + Greener Energy',
  oneYearFixedLoyalty = '1 Year Fixed Loyalty',
  twoYearFixedAndHeatingControl = '2 Year Fixed + Heating Control',
  oneYearFixedGoSmart = '1 Year Fixed Go Smart',
  oneYearFixedGreenerElectricity = '1 Year Fixed + Greener Electricity',
  extendedFixed = 'Extended Fixed',
}

type PlanDetails = Pick<
  AvailablePlan,
  'title' | 'description' | 'features' | 'planType'
>;

const greenerBundle = {
  id: 'greenerBundle',
  text: '12 months’ Greener Energy upgrade included for the price of 10',
};

const greenerElectricityBundle = {
  id: 'greenerElectricityBundle',
  text: '12 months’ Greener Electricity upgrade included for the price of 10',
};

const greenElectric = {
  id: 'greenElectric',
  text: '100% renewable electricity from UK generators',
};

const greenElectricity = {
  id: 'greenElectricity',
  text: '100% renewable electricity from UK generators',
};

const greenGas = {
  id: 'greenGas',
  text: '10% green gas and 90% carbon offset',
};

const renewables = {
  id: 'renewables',
  text: '£20 contribution each year to support new renewable generators',
};

const smartThermostat = {
  id: 'smartThermostat',
  text: 'Includes a tado° Smart Thermostat',
};

const twoYearsFixedHC = {
  id: 'twoYearsFixedHC',
  text: 'Fixed unit rates and standing charges for 2 years',
};

const heatingCosts = {
  id: 'heatingCosts',
  text: 'Save up to 28% in heating usage',
};
const controlYourHeating = {
  id: 'controlYourHeating',
  text: 'Control your heating from your phone',
};

function planDetailsMap(
  planFamily: PlanFamily,
  planLength: number
): PlanDetails {
  switch (planFamily) {
    case PlanFamily.threeYearFixed:
      return {
        title: '3 Year Fixed',
        description:
          'Get peace of mind against energy price rises with our longest term fixed rate plan.',
        features: [],
        planType: PlanFamily.threeYearFixed,
      };
    case PlanFamily.twoYearFixed:
      return {
        title: '2 Year Fixed',
        description:
          'Enjoy price safety and fix your rates for 2 years with our most popular plan.',
        features: [],
        planType: PlanFamily.twoYearFixed,
      };
    case PlanFamily.twoYearFixAndProtect:
      return {
        title: '2 Year Fixed Loyalty Bundle',
        description:
          'Secure your rates for 2 whole years and keep ahead of energy market changes.',
        features: [],
        planType: PlanFamily.twoYearFixAndProtect,
      };
    case PlanFamily.oneYearFixedBoilerCover:
      return {
        title: '1 Year Fixed + Boiler Cover',
        description:
          'Secure your rates for a whole year and keep ahead of energy market changes.',
        features: [],
        planType: PlanFamily.oneYearFixedBoilerCover,
      };
    case PlanFamily.betterEnergy:
      return {
        title: '1 Year Fixed',
        description:
          'Protect yourself from price rises and fix your rates for a year.',
        features: [],
        planType: PlanFamily.betterEnergy,
      };
    case PlanFamily.oneYearFixed:
      return {
        title: '1 Year Fixed',
        description:
          'Protect yourself from price rises and fix your rates for a year.',
        features: [],
        planType: PlanFamily.oneYearFixed,
      };
    case PlanFamily.simplerEnergy:
      return {
        title: 'Variable',
        description:
          'Our rolling plan where prices can go up and down following market changes.',
        features: [],
        planType: PlanFamily.simplerEnergy,
      };
    case PlanFamily.evEverywhere:
      return {
        title: 'EV 2 year fixed',
        description:
          'Our exclusive 2 year fixed plan for electric vehicle drivers.',
        features: [],
        planType: PlanFamily.evEverywhere,
      };
    case PlanFamily.drive:
      return {
        title: 'EV 1 year fixed',
        description:
          'Our exclusive 1 year fixed plan for electric vehicle drivers.',
        features: [],
        planType: PlanFamily.drive,
      };
    case PlanFamily.memberSpecial:
      return {
        title: '1 Year Fixed Loyalty',
        description:
          'Just for you: our plan made for loyal members, with fair prices fixed for a year when you renew.',
        features: [],
        planType: PlanFamily.memberSpecial,
      };
    case PlanFamily.stayAndSave:
      return {
        title: '1 Year Fixed Loyalty',
        description:
          'Just for you: our plan made for loyal members, with fair prices fixed for a year when you renew.',
        features: [],
        planType: PlanFamily.stayAndSave,
      };
    case PlanFamily.betterSmart:
      return {
        title: '1 Year Fixed Smart',
        description:
          'For new and existing members who already have, or would like a smart meter installed',
        features: [],
        planType: PlanFamily.betterSmart,
      };
    case PlanFamily.oneYearFixedGreenerEnergy:
      return {
        title: '1 Year Fixed + Greener Energy',
        description:
          "Our greenest plan – stay in control of your energy costs and get our green upgrade included at a discounted rate. It's our traceable and transparent way to bring you renewable energy.",
        features: [greenerBundle, greenElectric, greenGas, renewables],
        planType: PlanFamily.oneYearFixedGreenerEnergy,
      };
    case PlanFamily.oneYearFixedLoyalty:
      return {
        title: '1 Year Fixed Loyalty',
        description:
          'Control your energy costs with our cheapest 1 year energy plan – exclusive to existing customers.',
        features: [],
        planType: PlanFamily.oneYearFixedLoyalty,
      };
    case PlanFamily.twoYearFixedAndHeatingControl:
      return {
        title: '2 Year Fixed + Heating Control',
        description:
          'Enjoy energy price security for 2 years and control your heating costs with a tado° Smart Thermostat included.',
        features: [
          smartThermostat,
          heatingCosts,
          controlYourHeating,
          twoYearsFixedHC,
        ],
        planType: PlanFamily.twoYearFixedAndHeatingControl,
      };
    case PlanFamily.oneYearFixedGoSmart:
      return {
        title: '1 Year Fixed Go Smart',
        description:
          'Get a smart meter installed within 90 days to unlock our cheapest plan.',
        features: [],
        planType: PlanFamily.oneYearFixedGoSmart,
      };
    case PlanFamily.oneYearFixedGreenerElectricity:
      return {
        title: '1 Year Fixed + Greener Electricity',
        description:
          "Our greenest plan. Stay in control of your energy costs and get our green upgrade included at a discounted rate. It's our traceable and transparent way to bring you renewable electricity",
        features: [greenerElectricityBundle, greenElectricity, renewables],
        planType: PlanFamily.oneYearFixedGreenerElectricity,
      };
    case PlanFamily.extendedFixed:
      return {
        title: 'Extended Fixed',
        description: `Guarantee your energy prices for ${planLength} months with our Extended Fixed plan`,
        features: [],
        planType: PlanFamily.extendedFixed,
      };
  }
}

export function determinePlanDetails(
  planName: string,
  planLength: number | null = null
): PlanDetails & { subtitle: string } {
  const planKey = Object.values(PlanFamily)
    .sort()
    .reverse()
    .find((planKey) => planName.startsWith(planKey)) as PlanFamily | undefined;

  // If title is not any of the above, it would not be in any computation.
  // So it can be assumed to be a title, but will always fail if used.
  return planKey
    ? { ...planDetailsMap(planKey, planLength ?? 0), subtitle: planName }
    : {
        title: planName,
        subtitle: '',
        description: '',
        features: [],
      };
}
