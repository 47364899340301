import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { OperationDefinitionNode, parse } from 'graphql';

const datadogGraphqlQueryNameMap = new Map();

const extractGraphqlQueryNameFromString = (
  query: string,
): string | undefined => {
  try {
    const parsedQuery = parse(query);
    if (parsedQuery.kind !== 'Document') return;
    const operationDefinition = parsedQuery.definitions.find(
      def => def.kind === 'OperationDefinition',
    ) as OperationDefinitionNode | undefined;
    return operationDefinition?.name?.value;
  } catch {
    console.error(
      'Failed to extract Graphql query name for datadog processing',
    );
  }
};

export const storeGraphqlQueryNameByRequestId = (id: string, query: string) => {
  const queryName = extractGraphqlQueryNameFromString(query);
  if (!queryName) return;
  datadogGraphqlQueryNameMap.set(id, queryName);
};

// Exported only for use in unit tests
export const clearQueryNameMap = () => {
  datadogGraphqlQueryNameMap.clear();
};

export const beforeSend: RumInitConfiguration['beforeSend'] = (
  event,
  context,
): boolean => {
  event.view.url = event.view.url
    .replace(/(refresh_token=)[^&]+/, `$1REDACTED`)
    .replace(/(code=)[^&]+/, `$1REDACTED`);

  // Add additional context to fetch requests
  if (
    event.type === 'resource' &&
    event.resource.type === 'fetch' &&
    'requestInput' in context &&
    typeof context.requestInput !== 'string'
  ) {
    const xTraceToken = context?.requestInput?.headers?.get('x-trace-token');

    if (xTraceToken) {
      const graphqlQueryName = datadogGraphqlQueryNameMap.get(xTraceToken);

      const additionalRequestContext = {
        xTraceToken,
        graphqlQueryName,
      };

      event.context = event.context
        ? { ...event.context, ...additionalRequestContext }
        : { ...additionalRequestContext };

      // Remove from map after it's sent to datadog as no longer needed
      datadogGraphqlQueryNameMap.delete(xTraceToken);
    }
  }
  return true;
};

export const init = () => {
  if (
    !(process.env.NODE_ENV === 'development') &&
    process.env.NX_DATADOG_APPLICATION_ID &&
    process.env.NX_DATADOG_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: process.env.NX_DATADOG_APPLICATION_ID,
      clientToken: process.env.NX_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'ovo-account-web',
      env: process.env.VERCEL_ENV === 'preview' ? 'staging' : 'prod',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enablePrivacyForActionName: true,
      version: process.env.VERCEL_DEPLOYMENT_ID ?? undefined,
      beforeSend,
    });
  } else {
    console.info('Datadog is not initialised');
  }
};

export const reportError = (error: unknown, context?: object) => {
  datadogRum.addError?.(error, context);
};

export const setInitialUserContext = (
  id: string | null | undefined,
  context?: object,
) => {
  if (id) {
    datadogRum.setUser({ id });
    // Replace the default context for all your RUM events
    if (context) {
      datadogRum.setGlobalContext(context);
    }
  }
};

export const trackUserAction = (name: string, context?: object) => {
  datadogRum.addAction(name, context);
};
