import { Checkbox, CTAButton, Heading4, TextLink } from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { PROFILE_ADDON_GREENER_ENERGY_MODAL_CLOSE } from '@/src/constants/analytics-subjects';
import { GREENER_ENERGY_TERMS } from '@/src/constants/routes';
import { CatalogueBolton, SignupSource } from '@/src/types/Products';
import { getGreenerEnergySourceFromURL } from '@/src/pages/GreenerEnergy/GreenerEnergySignup/utils';

import { GreenerEnergyModal } from '@/src/pages/GreenerEnergy/components/GreenerEnergyModal/GreenerEnergyModal';

import { OptimizelyContext } from '@/src/utils/optimizely';

interface Props {
  activateProduct: (product: CatalogueBolton, source?: SignupSource) => void;
  greenerEnergy: CatalogueBolton;
  setAcceptPrompt: Dispatch<SetStateAction<boolean>>;
  acceptPrompt: boolean;
}

const GreenerEnergyAcceptTermsModal = (props: Props) => {
  const { dispatch } = useAnalytics();
  const { activateProduct, greenerEnergy, setAcceptPrompt, acceptPrompt } =
    props;

  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [mustAgreeMessage, setMustAgreeMessage] = useState<boolean>(false);

  const { optimizely } = useContext(OptimizelyContext);

  const onSignupClick = () => {
    if (acceptedTerms) {
      activateProduct(greenerEnergy, getGreenerEnergySourceFromURL());
      optimizely?.track('Greener Energy Signup Click');
    } else {
      setMustAgreeMessage(true);
    }
  };

  const onPress = () => {
    setAcceptedTerms(!acceptedTerms);
    setMustAgreeMessage(false);
  };

  const onClose = () => {
    dispatch({
      name: PROFILE_ADDON_GREENER_ENERGY_MODAL_CLOSE,
      type: 'click',
    });
    setAcceptPrompt(false);
  };

  return (
    <GreenerEnergyModal
      testId="greener-energy-accept-terms-modal"
      title={'Add Greener Energy to your account'}
      showGreenerEnergyModal={acceptPrompt}
      onClose={onClose}
    >
      <Heading4>{`£10 monthly subscription.`}</Heading4>
      <Heading4>{`Cancel any time.`}</Heading4>
      <div data-testid="signup-agreement">
        <Checkbox
          id="greener-energy-terms-and-conditions-accept"
          data-testid="greener-energy-terms-and-conditions-accept"
          label={
            <>
              By continuing, you are agreeing to the Greener Energy terms and
              conditions.{' '}
              <TextLink target="_blank" href={GREENER_ENERGY_TERMS}>
                Read the Greener energy full terms and conditions.{' '}
              </TextLink>
            </>
          }
          onChange={onPress}
          error={
            mustAgreeMessage &&
            !acceptedTerms &&
            'You must agree to terms and conditions before continuing.'
          }
        />

        <CTAButton
          variant="primary"
          data-testid="accept-terms-and-conditions-greener-energy"
          type="button"
          onClick={onSignupClick}
          style={{ marginTop: '2rem' }}
          fullWidth="always"
        >
          {`Sign up  now`}
        </CTAButton>
      </div>
    </GreenerEnergyModal>
  );
};

export default GreenerEnergyAcceptTermsModal;
