import { Card, ErrorNotification, Margin, P } from '@ovotech/nebula';
import { AnalyticsPage } from '@ovotech/ui-tools';
import React from 'react';
import testIds from '../../testIds';
import { ContactUsLink, Section } from '@/src/components';
import { GREENER_ENERGY_SIGNUP_ERROR } from '@/src/constants/analytics-subjects';

const signUpTestIds = testIds.signUp;

export const Loading = () => {
  return (
    <Section data-testid={signUpTestIds.loading}>
      <Card>
        <P>Loading...</P>
      </Card>
    </Section>
  );
};

export const LoadError = () => {
  return (
    <AnalyticsPage name={GREENER_ENERGY_SIGNUP_ERROR}>
      <Section data-testid={signUpTestIds.error}>
        <ErrorNotification
          id="greener-energy-signup-error"
          title="You are ineligible for Greener Energy"
        >
          <P>
            If you believe you should be eligible please <ContactUsLink />.
          </P>
        </ErrorNotification>
      </Section>
    </AnalyticsPage>
  );
};

export const FormError = () => {
  return (
    <AnalyticsPage name={GREENER_ENERGY_SIGNUP_ERROR}>
      <Margin bottom={4} data-testid={signUpTestIds.activationError}>
        <ErrorNotification id="activation-error">
          <P>
            Sorry, we were unable to activate your upgrade. Please try again
            later. If this keeps happening please <ContactUsLink />.
          </P>
        </ErrorNotification>
      </Margin>
    </AnalyticsPage>
  );
};
