import { P } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledContent = styled.div`
  margin: 4%;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ theme: { space } }) => space[6]};
`;

export const StyledBox = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${mqSmallDown(`
    flex-direction: column;
    text-align: center;
  `)};
`;

export const StyledText = styled(P)`
  margin-left: 8px;
  margin-bottom: 0px;
  display: inline-block;
  margin-top: 3%;
  font-size: 16px;

  ${mqSmallDown(`
  margin-left: 5px;
  `)}
`;
