import { useQuery } from '@ovotech/ui-tools';

import { EVsInlifeAnytimeSavingsDataResponse } from '@/src/types/State';
import { generateAnytimeUrl } from '@/src/redux/ducks/evs/evInlife';
import { apiService } from '@/src/services/api-service';

export function useAnytimeCreditsQuery(
  accountId: string | null | undefined,
  anytimeStartDate: string | undefined,
  year: string,
  month: string,
  queryRunCondition: boolean,
) {
  let url = '';
  if (accountId && anytimeStartDate) {
    url = generateAnytimeUrl(accountId!, year, month, anytimeStartDate!);
  }
  return useQuery(
    [accountId, year, month, anytimeStartDate],
    () => {
      return apiService.get<EVsInlifeAnytimeSavingsDataResponse>({
        responseType: 'json',
        url: url,
      });
    },

    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: queryRunCondition,
      refetchOnWindowFocus: true,
    },
  );
}
