import Big from 'big.js';
import format from 'date-fns/format';
import { ProjectedCostChartData } from '@/src/components/Chart/ProjectedCostChart/ProjectedCostChart';

import { ProjectedCostsResponse } from '@/src/types/Response';
import { getPoundValue } from '@/src/utils/money';

const getPredictedBalance = (
  directDebitAmount: number,
  balance: number,
  projectedCosts: ProjectedCostsResponse,
) => {
  const {
    electricity: electricityCosts,
    gas: gasCosts,
    boltOns: boltOnCosts,
  } = projectedCosts;

  const totalCost = (electricityCosts || [])
    .concat(gasCosts || [])
    .concat(boltOnCosts || [])
    .reduce(
      (acc, curr) => acc.plus(curr?.cost ? curr?.cost?.amount : 0),
      Big(0),
    );

  const {
    recommendedDirectDebit: { remainingDirectDebits },
  } = projectedCosts;

  return Big(balance)
    .plus(Big(remainingDirectDebits.count).times(directDebitAmount))
    .minus(totalCost);
};

const mapToChartData = (
  projectedCosts: ProjectedCostsResponse,
): Array<ProjectedCostChartData> => {
  const { electricity, gas, boltOns } = projectedCosts;

  if (!electricity && !gas && !boltOns) {
    return [];
  }

  const dataPoints = (electricity || gas || boltOns)!.length;

  return [...Array(dataPoints)].reduce((result, _, currentIndex) => {
    const projectedElec = electricity && electricity[currentIndex];
    const projectedGas = gas && gas[currentIndex];
    const projectedBoltOns = boltOns && boltOns[currentIndex];

    const electricityCost =
      projectedElec && Big(getPoundValue(projectedElec.cost));
    const gasCost = projectedGas && Big(getPoundValue(projectedGas.cost));
    const boltOnCost =
      projectedBoltOns && Big(getPoundValue(projectedBoltOns.cost));

    if (
      (electricityCost && electricityCost.gt(0)) ||
      (gasCost && gasCost.gt(0)) ||
      (boltOnCost && boltOnCost.gt(0))
    ) {
      result.push({
        electricityCost,
        gasCost,
        boltOnCost,
        date: format(
          (projectedElec || projectedGas || projectedBoltOns)!.date,
          'MMMM YYYY',
        ),
        month: format(
          (projectedElec || projectedGas || projectedBoltOns)!.date,
          'MMM',
        ),
        days: Math.max(
          (projectedElec || { days: 0 }).days,
          (projectedGas || { days: 0 }).days,
          (projectedBoltOns || { days: 0 }).days,
        ),
      });
    }
    return result;
  }, []);
};

export { getPredictedBalance, mapToChartData };
