import styled, { css } from 'styled-components';
import { svgWidth } from '@/src/components/styled-utils';
import { ReactComponent as smallGreenShapes } from '@/src/resources/small-green-shapes.svg';
import { ReactComponent as triangle } from '@/src/resources/triangle-filler.svg';
import {
  mqMediumDown,
  mqMediumUp,
  mqSmallDown,
  mqLargeUp,
  mqIE11,
} from '@/src/utils/mediaQuery';

export const StyledCard = styled.div`
  padding: 0;
  overflow: auto;
`;

export const CardHeader = styled.div<{ dark?: boolean }>`
  overflow: hidden;
  position: relative;
  z-index: 10;
  ${({ theme: { colors }, dark = false }) => `
    background-color: ${
      dark ? colors.brand.primary.dark : colors.brand.primary.base
    };
  `}
`;

export const CardHeaderMask = styled.div`
  ${({ theme: { colors } }) => `
    background-color: ${colors.canvas};
  `}
  width: 73px;
  height: 101%;
  position: absolute;
  z-index: 11;
  right: -1px;
  top: 0;
  clip-path: polygon(60px 0, 100% 0, 100% 100%, 0% 100%);

  ${mqMediumUp(`
    width: 141px;
    clip-path: polygon(60px 0, 100% 0, 100% 100%, 0% 100%);
  `)}

  ${mqIE11(`
    display: none;
  `)}
`;

export const CardHeaderTitle = styled.h3`
  color: white;
  margin: 0 !important;
`;
export const CardHeaderTitleH4 = styled.h4`
  color: white;
  margin: 0 !important;
`;

export const CardHeaderDescription = styled.p`
  color: white;
  margin: 0 !important;
`;

export const Divider = styled.div<{ secondary?: boolean; bold?: boolean }>`
  ${({ theme: { colors, space }, secondary, bold = false }) => {
    return `
      margin-top: ${space[1]};
      margin-bottom: ${space[1]};
      border-top: ${
        secondary
          ? `1px solid ${colors.borderMuted}`
          : bold
          ? `2px solid ${'#0A9828'}`
          : `1px solid ${'#0A9828'}`
      };
    `;
  }}
`;

export const DashedDivider = styled.div`
  ${({ theme: { colors } }) => `
    border-top: 1px dashed ${colors.body};
  `}
`;

export const SolidGreyDivider = styled.div`
  ${({ theme: { colors } }) => `
    border-top: 2px solid ${colors.brand.grey.base};
  `}
`;

export const FooterNote = styled.p`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[0]} !important;
  `}
`;

export const DisplayOnlyMobile = styled.div`
  ${mqMediumUp(`
      display: none;
`)}
`;

export const DisplayOnlyWeb = styled.div`
  ${mqMediumDown(`
      display: none;
  `)}
`;

export const PrimarySpacer = styled(smallGreenShapes)<{
  inverted?: boolean;
}>`
  ${({ theme: { space }, inverted = false }) => `
    left: ${space[6]};
    position: absolute;
    ${inverted ? 'transform: rotateY(180deg)' : ''};
    top: ${inverted ? '10px' : '30px'};

    ${mqMediumDown(`
      ${svgWidth('70px', 107, 74)};
      top: ${inverted ? '20px' : '40px'};
    `)}

    ${mqSmallDown(`
      top: ${inverted ? '20px' : '40px'};
    `)}
  `}
`;

export const CardContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;
export const CardContentTriangleFiller = styled(triangle)`
  flex: 1;
  margin-right: -50px;
  transform: scale(1.4);
  max-width: 300px;
`;
export const CardContent = styled.div`
  flex: 2;
  overflow: auto;
  position: relative;
`;

export const CheapestTariffContainer = styled.div`
  ${({ theme: { space } }) => `
    padding-top: ${space[2]};
    padding-left: ${space[4]};
    padding-right: ${space[4]};
    overflow: auto;

    ${mqMediumUp(`
      padding-top: ${space[6]};
      padding-left: ${space[6]};
      padding-right: ${space[6]};
    `)}

    ${mqLargeUp(`
      padding-left: ${space[8]};
      padding-right: ${space[8]};
    `)}
  `}
`;

export const CardRow = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: row;
    gap: ${space[4]};
  `,
);

export const CardCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AsteriskContainer = styled.div`
  ${({ theme: { space } }) => `
    margin-right: ${space[1]};
  `}
`;

export const CheapestTariffColumnsSpacer = styled.div`
  display: none;

  ${mqMediumUp(`
    display: block;
    width: 60px;
  `)}
`;
