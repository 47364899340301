import { OptimizelyFeature } from '@optimizely/react-sdk';
import { CTALink, SecondaryCTAButton, Sup } from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsRender } from '@ovotech/ui-tools';
import { isAfter, isBefore } from 'date-fns';
import React from 'react';
import { HomePlan } from './HomePlan';
import {
  DefaultImageIncentiveXsell,
  StyledDefaultImageContainer,
  StyledHeading2,
  StyledHomePlanContainer,
  StyledHomePlanContainerBodyXsell,
  StyledParagraph,
  StyledTermsAndConditions,
} from './HomePlan.styled';
import { ActionCentreBanner } from '@/src/components/ActionCentre/Banners/Banner';
import { CTAWrapper } from '@/src/components/ActionCentre/Banners/banners.styled';
import { HOMEPLAN_ACTION_FOUR_MONTH_FREE_CENTRE_BANNER } from '@/src/constants/analytics-subjects';
import { PROMO_FREE_FOR_FIRST_FOUR_MONTHS_ENABLED } from '@/src/constants/feature-flags';
import { HOME_PLAN_BOILER_COVER_PROMO } from '@/src/constants/routes';
import {
  FREE_FOR_FOUR_OFFER_END_DATE,
  FREE_FOR_FOUR_OFFER_START_DATE,
} from '@/src/pages/Offers/constants';
import homePlanFreeForFourMonthsImg from '@/src/resources/homeplan-4-months-offer.png';

export const HomePlanFreeForFourMonths: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const currentDate = new Date();

  const isWithinFreeForFourMonthsOfferPeriod =
    isAfter(currentDate, FREE_FOR_FOUR_OFFER_START_DATE) &&
    isBefore(currentDate, FREE_FOR_FOUR_OFFER_END_DATE);

  return (
    <OptimizelyFeature feature={PROMO_FREE_FOR_FIRST_FOUR_MONTHS_ENABLED}>
      {isEnabled =>
        isEnabled && isWithinFreeForFourMonthsOfferPeriod ? (
          <AnalyticsRender name={HOMEPLAN_ACTION_FOUR_MONTH_FREE_CENTRE_BANNER}>
            <StyledHomePlanContainer>
              <StyledDefaultImageContainer>
                <DefaultImageIncentiveXsell
                  title="Get four months free"
                  imgUrl={homePlanFreeForFourMonthsImg}
                ></DefaultImageIncentiveXsell>
              </StyledDefaultImageContainer>
              <StyledHomePlanContainerBodyXsell>
                <StyledHeading2>Boiler cover you can count on.</StyledHeading2>
                <StyledParagraph>
                  Join the thousands who choose us for their boiler cover.{' '}
                  <Sup style={{ fontSize: 7 }}>2</Sup>
                </StyledParagraph>
                <CTAWrapper>
                  <AnalyticsClick
                    name={HOMEPLAN_ACTION_FOUR_MONTH_FREE_CENTRE_BANNER}
                  >
                    <CTALink
                      href={HOME_PLAN_BOILER_COVER_PROMO}
                      onClick={onClick}
                      variant="primary"
                    >
                      See our plans
                    </CTALink>
                  </AnalyticsClick>
                  <SecondaryCTAButton onClick={dismiss}>
                    Close
                  </SecondaryCTAButton>
                </CTAWrapper>
                <StyledTermsAndConditions>
                  <Sup style={{ fontSize: 7 }}>1</Sup>
                  Offer available to new customers only. Offer expires
                  12/12/2023.
                  <br />
                  <Sup style={{ fontSize: 7 }}>2</Sup>
                  Terms, conditions, eligibility criteria, exclusions and
                  cancellation charges apply.
                </StyledTermsAndConditions>
              </StyledHomePlanContainerBodyXsell>
            </StyledHomePlanContainer>
          </AnalyticsRender>
        ) : (
          <HomePlan dismiss={dismiss} onClick={onClick} />
        )
      }
    </OptimizelyFeature>
  );
};
