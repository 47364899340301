import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { createContractsUrl } from '@/src/constants/endpoints';
import { ContractsResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `orion-contracts-${string}`;

export const useContractsQuery = (
  accountId: string | null | undefined,
  options?: UseQueryOptions<
    ContractsResponse,
    unknown,
    ContractsResponse,
    QueryCacheKey
  >,
): UseQueryResult<ContractsResponse> =>
  useQuery(
    `orion-contracts-${accountId}` as QueryCacheKey,
    () =>
      apiService.get<ContractsResponse>({
        url: createContractsUrl(accountId ?? ''),
        headers: {
          'orion-endpoint-version': '5',
        },
      }),
    {
      enabled: !!accountId,
      cacheTime: 30000,
      staleTime: 30000,
      ...options,
    },
  );
