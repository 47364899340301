import styled from 'styled-components';

import { svgHeight } from '@/src/components/styled-utils';
import { ReactComponent as AnytimeIcon } from '@/src/resources/ev-anytime-icon.svg';
import { ReactComponent as EvEverywhereIcon } from '@/src/resources/ev-everywhere-default.svg';
import { ReactComponent as GreenBundleIcon } from '@/src/resources/green-bundle-default.svg';
import { ReactComponent as GreenElectricityIcon } from '@/src/resources/green-electricity-default.svg';
import { ReactComponent as GreenGasIcon } from '@/src/resources/green-gas-default.svg';
import { ReactComponent as HomeplanBoilerIcon } from '@/src/resources/homeplan-boiler.svg';
import { ReactComponent as OvoFoundationIcon } from '@/src/resources/ovo-foundation-default.svg';
import { ReactComponent as BPPulseIcon } from '@/src/resources/polar-plus-default.svg';
import { FunctionComponent, SVGProps } from 'react';

const styleSvg = (
  svgIcon: FunctionComponent<SVGProps<SVGSVGElement>>,
) => styled(svgIcon)`
  position: relative;
  top: 0.25em;
  ${svgHeight('1.4em', 41, 41)}
`;

export const StyledGreenBundleIcon = styleSvg(GreenBundleIcon);
export const StyledEvEverywhereIcon = styleSvg(EvEverywhereIcon);
export const StyledGreenElectricityIcon = styleSvg(GreenElectricityIcon);
export const StyledGreenGasIcon = styleSvg(GreenGasIcon);
export const StyledBPPulseIcon = styleSvg(BPPulseIcon);
export const StyledOvoFoundationIcon = styleSvg(OvoFoundationIcon);
export const StyledOvoHomeplanIcon = styleSvg(HomeplanBoilerIcon);
export const StyledAnytimeIcon = styleSvg(AnytimeIcon);
