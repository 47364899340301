import { P, Heading1 } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqSmallUp, mqMediumUp, mqMediumDown } from '@/src/utils/mediaQuery';

type ImageProps = {
  desktopImage?: string;
  mobileImage?: string;
};

export const StyledCardContainer = styled.div`
  ${({ theme: { colors, space } }) => `
    position: relative;
    margin-top: ${space[4]};
    background-color: ${colors.canvas};
    box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);

    &:first-of-type {
      margin-top: ${space[6]};

      ${mqSmallUp(`
        margin-top: ${space[8]};
      `)}
    }
  `}
`;

export const StyledContentContainer = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[6]} ${space[8]} ${space[4]};
  `}

  ${mqMediumUp(`
    padding-right: 30%;
  `)}
`;

export const StyledImageContainer = styled.div`
  width: 100%;
  height: 100%;

  ${mqMediumUp(`
    top: 0;
    margin: 0;
    width: 50%;
    right: -1px;
    height: 100%;
    position: absolute;
  `)}
`;

export const ClippedImg = styled.img<ImageProps>`
  height: 90%;
  width: 100%;
  display: block;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 75%, 10% 100%);
  ${({ desktopImage, mobileImage }) => `
    content: url(${desktopImage});

    ${mqMediumDown(`
      ${mobileImage && `content: url(${mobileImage})`};
    `)}
  `}

  ${mqMediumDown(`
    height:100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  `)}
`;

export const StyledHeading1 = styled(Heading1)`
  ${mqMediumUp(`width:60%`)};
  font-size: 2.5rem;
`;

export const StyledP = styled(P)`
  ${mqMediumUp(`
    width:60%
  `)}
`;
