import { P, Strong, TextLink, Heading3 } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { ActionCentreBanner, Banner } from './Banner';
import { useMoveOutDateQuery } from '@/src/api';
import { OVO_QUOTE_AND_SWITCH } from '@/src/constants/endpoints';
import { formatFullDate } from '@/src/utils/date';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';
import { formatPostalAddress } from '@/src/utils/format-postal-address';

export const MoveOutComplete: ActionCentreBanner = () => {
  const { accountId } = useAccountContext();

  const moveOutQuery = useMoveOutDateQuery(accountId || '');
  const profile = useProfileQueryKapi();
  const formattedAddress = formatPostalAddress(
    profile?.data?.customer_nextV1.contactAddress?.postalAddress,
  );

  // Ruleset enforced moveout data to exist
  const moveOutDate =
    moveOutQuery.isLoading || moveOutQuery.isError || !moveOutQuery.data
      ? ''
      : formatFullDate(new Date(moveOutQuery.data.moveOutDate!));
  return (
    <Banner icon="loss">
      <Heading3 as="h2">Your move out is complete</Heading3>
      <P>
        You told us that you moved out of{' '}
        <Strong>
          {formattedAddress ? formattedAddress : 'unknown address'}
        </Strong>{' '}
        on the
        {moveOutDate}. Your energy contract ended on that date. We've created a
        final statement which is available below.
      </P>
      <P>
        If you’d like your new home to be supplied by OVO Energy, then{' '}
        <TextLink href={OVO_QUOTE_AND_SWITCH} opensInNewWindow>
          enter your new address to get a quote.
        </TextLink>{' '}
      </P>
    </Banner>
  );
};
