import { Card, P, TextLink, Margin, Heading1 } from '@ovotech/nebula';
import React from 'react';
import { Link } from 'react-router-dom-v5';
import { StyledCard } from './EVHeaderCard.styled';

type HeaderCardType = {
  title: string;
  description: string;
  linkText: string;
  linkUrl: string;
};

export const EVHeaderCard = (props: HeaderCardType) => {
  const { title, description, linkText, linkUrl } = props;

  return (
    <StyledCard as={Card}>
      <P>
        <TextLink data-testid="back-header-link" to={linkUrl} as={Link}>
          {linkText}
        </TextLink>
      </P>
      <Margin top={3}>
        <Heading1>{title}</Heading1>
      </Margin>
      <P>{description}</P>
    </StyledCard>
  );
};
