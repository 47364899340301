import { configService } from '@ovotech/energy-cx';
import { AnalyticsPage } from '@ovotech/ui-tools';
import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';
import routeManager from 'screen-reader-route-manager';
import { Footer } from '../Footer/Footer';
import { Header, SimpleHeader } from '../Header';
import { PageContent, PageContentLayout } from '../PageContent/PageContent';
import { StyledApp } from '../layout';
import { Section } from '@/src/components';
import { State } from '@/src/types/State';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';
import { useSupplyPointsQuery } from '@/src/api/kapi/use-supply-points-query';
import { haveAllSuppliesBeenActive } from '@/src/utils/supplyPoints';

type LayoutProps = { children: ReactNode; layout: PageContentLayout };
function PageLayout({ children, layout }: LayoutProps) {
  const { location } = useHistory();

  const hasError = useSelector((state: State) => state.apiError);

  const { data, isError, isLoading } = useSupplyPointsQuery();

  const conditionalLayout = !haveAllSuppliesBeenActive(
    data?.account?.accountSupplyPoints ?? [],
  )
    ? 'basic'
    : layout;

  return (
    <>
      <Header
        path={location.pathname}
        supplies={data?.account?.accountSupplyPoints ?? []}
      />
      {isLoading ? (
        <SiteLoading />
      ) : (
        <PageContent layout={conditionalLayout} hasError={hasError || isError}>
          {children}
        </PageContent>
      )}
      <Footer />
    </>
  );
}

type SimpleHeaderLayoutProps = {
  children: ReactNode;
  headerLink?: JSX.Element;
};
function SimpleHeaderLayout({ children, headerLink }: SimpleHeaderLayoutProps) {
  const hasError = useSelector((state: State) => state.apiError);

  return (
    <StyledApp>
      <SimpleHeader headerLink={headerLink} />
      <PageContent layout="basic" hasError={hasError}>
        {children}
      </PageContent>
      <Footer />
    </StyledApp>
  );
}

type PageProps = {
  children: ReactNode;
  title: string;
  analytics?: {
    name: string;
    properties?: any;
  };
};

/**
 * Basic page functionality with no page layout.
 */
export function Page({ title, analytics, children }: PageProps) {
  const { siteName } = configService.getConfig();

  useEffect(() => {
    document.title = `${title} - ${siteName}`;
    routeManager.afterRouteChange();
  }, [title, siteName]);

  if (analytics?.name) {
    return (
      <AnalyticsPage name={analytics.name} properties={analytics.properties}>
        {children}
      </AnalyticsPage>
    );
  }
  return <>{children}</>;
}

/**
 * Page with super basic layout for rendering things like the global error page.
 */
export function PageWithSimpleHeader({
  children,
  headerLink,
  ...rest
}: PageProps & {
  headerLink?: JSX.Element;
}) {
  return (
    <Page {...rest}>
      <SimpleHeaderLayout headerLink={headerLink}>
        {children}
      </SimpleHeaderLayout>
    </Page>
  );
}

/**
 * Page with standard layout, needs a `Section` component for proper alignment.
 */
export function PageWithNavigation({ children, ...rest }: PageProps) {
  return (
    <Page {...rest}>
      <PageLayout layout="side-navigation">{children}</PageLayout>
    </Page>
  );
}

/**
 * Page with standard layout. Children are aligned with the nav.
 */
export function PageWithNavigationAndSectionPadding({
  children,
  ...rest
}: PageProps) {
  return (
    <PageWithNavigation {...rest}>
      <Section as="div">{children}</Section>
    </PageWithNavigation>
  );
}

/**
 * Page with without side navigation.
 */
export function PageWithoutNavigationAndSectionPadding({
  children,
  ...rest
}: PageProps) {
  return (
    <Page {...rest}>
      <PageLayout layout="basic">
        <Section as="div">{children}</Section>
      </PageLayout>
    </Page>
  );
}
