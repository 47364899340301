import { Card, Col, Heading1, Margin, P, Row } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React, { FormEvent, memo, useCallback, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { ErrorPanel } from './ErrorPanel';
import { LoadingPanel } from './LoadingPanel';
import { ProjectedBalance } from './ProjectedBalance/ProjectedBalance';
import { ProjectedCosts } from './ProjectedCosts/ProjectedCosts';
import SettingsPanel from './SettingsPanel';
import { GraphType } from './constants';
import { useProjectedCostsQuery } from '@/src/api';
import { SVTCustomersPayingDDOneTimeBanner } from '@/src/components';
import {
  ContractsResponse,
  CurrencyValueResponse,
  Pounds,
  ProjectedCostsResponse,
} from '@/src/types/Response';
import { recDdApproxEqualToCurrentDd } from '@/src/utils/shouldShowRecommendedDD';

interface DirectDebitCalculatorSettingsAndGraphProps {
  directDebitAmount: CurrencyValueResponse | undefined;
  balance: Pounds;
  contracts: ContractsResponse;
  newDirectDebitAmount: string;
  setNewDirectDebitAmount: (newValue: string) => void;
}

export const DirectDebitCalculatorSettingsAndGraph = memo(
  (props: DirectDebitCalculatorSettingsAndGraphProps) => {
    const {
      directDebitAmount,
      balance,
      contracts,
      newDirectDebitAmount,
      setNewDirectDebitAmount,
    } = props;

    const { accountId } = useAccountContext();

    const [selectedGraphType, setSelectedGraphType] = useState(
      GraphType.MONTHLY_END_BALANCE,
    );

    const onAmountChange = useCallback(
      (event: FormEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        let newValue = value;
        if (Number(value) > 2000) {
          newValue = '2000.00';
        }

        // CurrencyField cannot be constrained to type="number", so need to handle non-numeric entries
        if (!isNaN(Number(value))) {
          setNewDirectDebitAmount(newValue);
        }
      },
      [setNewDirectDebitAmount],
    );

    const projectedCosts: UseQueryResult<ProjectedCostsResponse> =
      useProjectedCostsQuery(accountId);

    const onGraphTypeChange = useCallback(
      (type: GraphType) => setSelectedGraphType(type),
      [],
    );

    const ProjectedCostsContainer = () => {
      if (projectedCosts.isError) {
        return <ErrorPanel />;
      }
      if (projectedCosts.data == null || projectedCosts.isFetching) {
        return <LoadingPanel />;
      }

      const payingRightAmount =
        !!directDebitAmount &&
        recDdApproxEqualToCurrentDd(
          projectedCosts.data.recommendedDirectDebit.friendlyAmount,
          directDebitAmount,
        );

      return selectedGraphType === GraphType.MONTHLY_ESTIMATED_COST ? (
        <ProjectedCosts
          balance={{
            amount: balance.pounds,
            currencyUnit: 'GBP',
          }}
          projectedCosts={projectedCosts.data}
          newDirectDebitAmount={newDirectDebitAmount}
          payingRightAmount={payingRightAmount}
          calculationEndDate={
            projectedCosts.data.recommendedDirectDebit.calculationEndDate
          }
        />
      ) : (
        <ProjectedBalance
          balance={{
            amount: balance.pounds,
            currencyUnit: 'GBP',
          }}
          newDirectDebitAmount={newDirectDebitAmount}
          projectedCosts={projectedCosts.data}
          payingRightAmount={payingRightAmount}
          calculationEndDate={
            projectedCosts.data.recommendedDirectDebit.calculationEndDate
          }
        />
      );
    };

    return (
      <>
        <SVTCustomersPayingDDOneTimeBanner containerStyle={{ marginTop: 0 }} />
        <Margin bottom={6}>
          <Card>
            <Heading1>Direct Debit calculator</Heading1>
            <P>
              Use this tool to see how your monthly payment amount affects your
              future balance.
            </P>
          </Card>
        </Margin>
        <Row isNested>
          <Col large={4} medium={4}>
            <SettingsPanel
              newDirectDebitAmount={newDirectDebitAmount}
              contracts={contracts}
              selectedGraphType={selectedGraphType}
              handleAmountChange={onAmountChange}
              handleGraphTypeChange={onGraphTypeChange}
            />
          </Col>
          <Col large={8} medium={8}>
            <Margin bottom={6}>
              <ProjectedCostsContainer />
            </Margin>
          </Col>
        </Row>
        <P>
          This tool predicts your future balance based on your energy use to
          date. The amount may change based on actual use and energy costs.
        </P>
      </>
    );
  },
);

DirectDebitCalculatorSettingsAndGraph.displayName =
  'DirectDebitCalculatorSettingsAndGraph';
