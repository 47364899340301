import { AvailablePlan } from '@monovo/energy-renewals';
import {
  AnalyticsData,
  AnalyticsPage,
  QueryResultRenderer,
  useAccountContext,
  useCustomerContext,
} from '@ovotech/ui-tools';
import { MutationResult } from '@ovotech/ui-tools/dist/api/types';
import { EOC1Compliance } from '@/src/pages/index';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom-v5';
import { ApiError, NotInRenewalError } from './Error/Error';
import { useAvailablePlans } from './hooks/useAvailablePlans';
import { hasFutureContracts, hasRenewingContracts } from './selectors';
import {
  useAvailablePlansQuery,
  useContractsQuery,
  useContractsRenewalMutation,
  useDirectDebitQuery,
  useRenewablePlansQuery,
} from '@/src/api';
import { ScrollToTopOnMount } from '@/src/components';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';
import { GEMINI_URL } from '@/src/constants/endpoints';
import { ROUTE_RENEWAL_EOC } from '@/src/constants/routes';
import { AvailablePlansResponse } from '@/src/types/AvailablePlans';
import { RenewablePlansResponse } from '@/src/types/RenewablePlans';
import { ContractsResponse } from '@/src/types/Response';
import { State } from '@/src/types/State';
import { RENEWAL_EOC1 } from '@/src/constants/analytics-subjects';

export function Renewal() {
  const { accountId } = useAccountContext();
  const { customerId } = useCustomerContext();
  const location = useLocation();

  if (ROUTE_RENEWAL_EOC === location.pathname) {
    return <OrionRenewal />;
  } else if (accountId && customerId) {
    window.location.replace(
      `${GEMINI_URL}?bundle=Renewal&accountId=${accountId}&customerId=${customerId}`,
    );
    return <></>;
  } else {
    return <SiteLoading />;
  }
}

function OrionRenewal() {
  const { selectedAccountId } = useSelector((state: State) => state.user);
  const availablePlans = useAvailablePlansQuery(selectedAccountId!);
  const renewablePlans = useRenewablePlansQuery(selectedAccountId!);
  const contracts = useContractsQuery(selectedAccountId!);
  const contractsRenewalMutation = useContractsRenewalMutation(
    selectedAccountId!,
  );

  if (contracts.isFetching) {
    return <SiteLoading />;
  }

  if (contracts.isError || contracts.status !== 'success') {
    return <ApiError />;
  }

  return (
    <AnalyticsData data={{ project: { team: 'retention' } }}>
      <QueryResultRenderer
        queryResults={[availablePlans, renewablePlans] as const}
        forwardProps={{
          contractsRenewalMutation,
          selectedAccountId: selectedAccountId!,
          contracts: contracts.data,
        }}
        Error={ApiError}
        NoData={SiteLoading}
        Data={Data}
      />
    </AnalyticsData>
  );
}

function Data({
  data: [availablePlans, renewablePlans],
  props: { contractsRenewalMutation, selectedAccountId, contracts },
}: {
  data: readonly [AvailablePlansResponse, RenewablePlansResponse];
  props: {
    contractsRenewalMutation: MutationResult<AvailablePlan, any, AvailablePlan>;
    selectedAccountId: string;
    contracts: ContractsResponse;
  };
}) {
  const availablePlansBreakdown = useAvailablePlans(
    availablePlans,
    renewablePlans,
    contracts,
  );

  const isInRenewal = hasRenewingContracts(contracts);

  const shouldRedirectToSuccess = Boolean(
    contractsRenewalMutation.status === 'success' ||
      (!isInRenewal && hasFutureContracts(contracts)),
  );

  const canRenew =
    (isInRenewal && availablePlans.length) || shouldRedirectToSuccess;

  const directDebitQuery = useDirectDebitQuery(selectedAccountId);
  const hasDirectDebit = !!directDebitQuery?.data?.amount;

  return contractsRenewalMutation.status === 'error' ? (
    <ApiError />
  ) : !canRenew ? (
    <NotInRenewalError
      contracts={contracts}
      availablePlansCount={
        availablePlansBreakdown.selfService.hero.length +
        availablePlansBreakdown.selfService.nonHeroFixed.length +
        availablePlansBreakdown.selfService.variable.length
      }
    />
  ) : (
    <>
      <ScrollToTopOnMount />
      <AnalyticsPage name={RENEWAL_EOC1}>
        <EOC1Compliance
          hasDirectDebit={hasDirectDebit}
          availablePlans={availablePlansBreakdown.legal}
          contractsData={contracts}
          renewablePlans={renewablePlans}
        />
      </AnalyticsPage>
    </>
  );
}
