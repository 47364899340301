import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addAccountToThermostatsWaitlistStart } from '@/src/redux/ducks/smartThermostats/smart-thermostats-info';

import { State } from '@/src/types/State';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';

export function addToWaitList(add: boolean) {
  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const smartThermostats = useSelector(
    (state: State) => state.smartThermostats,
  );

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const profile = useProfileQueryKapi();

  // TODO: See https://github.com/ovotech/orion-ui/issues/2861
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (add) {
      if (
        !smartThermostats.isFetching &&
        !smartThermostats.onWaitList &&
        !smartThermostats.errorResponse &&
        !profile.isError &&
        profile.data
      ) {
        dispatch(addAccountToThermostatsWaitlistStart());
      }
    }
  });
}
