import { subMonths } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  convertDateObjToMonthYear,
  convertMonthYearToDateObj,
} from '../../components/MonthlyNavigator/MonthlyNavigator';
import { formatDateForUsageQuery } from './utils';
import { useDailyUsageQuery } from '../../../../api/use-daily-usage-query';
import { useMonthlyUsageQuery } from '../../../../api/use-monthly-usage-query';
import { DailyUsage, MonthlyUsage } from '@/src/types/Response';
import { State, MonthYear } from '@/src/types/State';

export interface UsageSummaries {
  previousMonthGas: UsageSummary;
  currentNavigatedToGas: UsageSummary;
  isNavigatedToLatestMonth: boolean;
  error?: string;
}

export type UsageSummary = null | {
  error?: string;
  cost?: number;
  consumption?: number;
};

export const useMonthGasUsageSummaries = (
  monthYear: MonthYear,
  getCurrentDate = () => new Date(),
): UsageSummaries => {
  const date = convertMonthYearToDateObj(monthYear);

  // Preload 2 months ago to reduce delay viewing previous months
  useMonthGasUsage(subMonths(date, 2));

  const previousMonthGas = useMonthGasUsage(subMonths(date, 1));
  const monthNavigatedToGas = useMonthGasUsage(date);
  const currentMonthCumulativeGas = useCurrentMonthCumulativeGasUsage(date);

  const currentMonthYear = convertDateObjToMonthYear(getCurrentDate());
  const isNavigatedToLatestMonth =
    currentMonthYear.year === monthYear.year &&
    currentMonthYear.month === monthYear.month;

  return {
    isNavigatedToLatestMonth,
    previousMonthGas,
    currentNavigatedToGas: isNavigatedToLatestMonth
      ? currentMonthCumulativeGas
      : monthNavigatedToGas,
    error:
      monthNavigatedToGas?.error ||
      previousMonthGas?.error ||
      currentMonthCumulativeGas?.error,
  };
};

function useMonthGasUsage(date: Date): UsageSummary {
  const { selectedAccountId } = useSelector((state: State) => state.user);

  const queryResult = useMonthlyUsageQuery(
    selectedAccountId!,
    formatDateForUsageQuery(date),
  );

  // Type is missing `data` property
  const { data, error } = queryResult as any;
  const gas = data?.gas as MonthlyUsage | undefined;

  if (error) return { error };
  if (!gas) return null;

  const previousMonthData = gas.data.find(current => {
    return (
      current.year === date.getFullYear() &&
      current.month === date.getMonth() + 1
    );
  });

  return !previousMonthData?.cost?.amount
    ? null
    : {
        cost: parseFloat(previousMonthData.cost.amount),
        consumption: previousMonthData.consumption,
      };
}

function useCurrentMonthCumulativeGasUsage(date: Date): UsageSummary {
  const { selectedAccountId } = useSelector((state: State) => state.user);

  const queryResult = useDailyUsageQuery(
    selectedAccountId!,
    formatDateForUsageQuery(date),
  );

  const { data, error } = queryResult as any;
  const gas = data?.gas as DailyUsage | undefined;

  if (error) return { error };
  if (!gas) return null;

  const summaryWhenNotPopulated = { consumption: 0, cost: 0 };

  const cumulativeGasData = gas?.data.reduce(
    (runningTotals: typeof summaryWhenNotPopulated, current) => {
      return !current.consumption || !current.cost?.amount
        ? runningTotals
        : {
            consumption: runningTotals.consumption + current.consumption,
            cost: runningTotals.cost + parseFloat(current.cost.amount),
          };
    },
    summaryWhenNotPopulated,
  );

  return cumulativeGasData === summaryWhenNotPopulated
    ? null
    : cumulativeGasData;
}
