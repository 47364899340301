import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const GreenerElectricityHomeWelcome = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  position: relative;
  overflow: hidden;

  ${({ theme: { space } }) => `
    border-radius: ${space[1]};
    padding: ${space[6]};
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    margin-top: ${space[2]};

    ${mqMediumDown(`
      padding-top: ${space[8]};
    `)}
  `}
`;

export const GreenerElectricityHomeHeaderStyled = styled.h1`
  margin: unset;
  ${({ theme: { colors, fontSizes } }) => `
    font-size: ${fontSizes[6]};
    color: ${colors.primaryContrast};
  `};
`;

export const GreenerElectricityHomeHeaderAndLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqMediumDown(`
    width: 100%;
  `)}
`;

export const GreenerElectricityHomeWelcomeInner = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${mqMediumDown(`
    width: 100%;
  `)}
`;
