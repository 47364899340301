import cookie from 'js-cookie';

// need to set domain in non-dev environments
let options = {};
if (process.env.NODE_ENV === 'production') {
  options = { domain: '.ovoenergy.com' };
}

export const saveSelectedAccountCookie = (accountId: string) => {
  return cookie.set('selectedAccountId', accountId, options);
};
