import styled from 'styled-components';

export const StyledProductPrice = styled.div`
  display: inline-block;
`;

export const StyledProductOriginalPrice = styled.span`
  text-decoration: line-through;
  ${({ theme: { colors, space } }) => `
    color: ${colors.shadeMid};
    margin-right: ${space[2]};
  `};
`;
