import { Card, Heading1, Margin, P } from '@ovotech/nebula';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Route, Switch } from 'react-router-dom-v5';

import { GoToHomeLink, Section, PageWithNavigation } from '@/src/components';
import { PAGE_NOT_FOUND } from '@/src/constants/analytics-subjects';

const NotFoundSection = () => (
  <Section id="not-found">
    <Card>
      <Heading1 data-testid="not-found-heading">Page not found</Heading1>
      <P data-testid="not-found-details">
        Sorry, we couldn't find what you were looking for.
      </P>
      <Margin top={4}>
        <GoToHomeLink />
      </Margin>
    </Card>
  </Section>
);

export const NotFound = () => {
  return (
    <PageWithNavigation
      title="Page not found"
      analytics={{ name: PAGE_NOT_FOUND }}
    >
      <NotFoundSection />
    </PageWithNavigation>
  );
};

const SwitchWithNotFound: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <Switch>
    {children}
    <Route component={NotFound} />
  </Switch>
);

export default SwitchWithNotFound;
