import React from 'react';
import { useSelector } from 'react-redux';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';
import { State } from '@/src/types/State';

export function BoilerCover() {
  const { selectedAccountId } = useSelector((state: State) => state.user);

  const searchParams = window.location.search
    ? `${window.location.search}&accountId=${selectedAccountId}`
    : `?accountId=${selectedAccountId}`;
  if (selectedAccountId) {
    window.location.replace(`${process.env.NX_HOMEPLAN_URL}${searchParams}`);
    return <></>;
  } else {
    return <SiteLoading />;
  }
}
