import { UsageView } from '@ovotech/energy-cx';
import { Stack } from '@ovotech/nebula';
import React from 'react';
import { PageWithNavigationAndSectionPadding } from '@/src/components';
import { UsageHomeProfileWidget } from './components/HomeProfileWidget.Usage';
import { useUsageHomeProfileWidget } from './useUsageHomeProfileWidget';

export const UsagePageView = () => {
  const { widgetPosition } = useUsageHomeProfileWidget();
  return (
    <PageWithNavigationAndSectionPadding title="Your energy use">
      <Stack spaceBetween={4}>
        {/* We add these position checks to ensure the Stack doesn't display empty spaces if the component returns null */}
        {widgetPosition === 'top' && (
          <UsageHomeProfileWidget pagePosition="top" />
        )}
        <UsageView />
        {widgetPosition === 'bottom' && (
          <UsageHomeProfileWidget pagePosition="bottom" />
        )}
      </Stack>
    </PageWithNavigationAndSectionPadding>
  );
};
