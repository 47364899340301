import {
  Card,
  Margin,
  SkeletonCTA,
  SkeletonCircle,
  SkeletonHeading,
  SkeletonText,
} from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { FlexChild } from '@/src/components';

const StyledIconHeader = styled.div`
  display: flex;
  width: 100%;
`;

const StyledIconHeaderText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    margin: 0;
  }
`;

const StyledIconHeaderIcon = styled.div(
  ({ theme: { mediaQueries, space, colors } }) => `
    color: ${colors.primary};
    font-size: 4rem;
    line-height: 1;
    margin-top: ${space[1]};
    margin-right: ${space[2]};

    ${mediaQueries.smallAndUp} {
      font-size: 4.8rem;
      margin-right: ${space[3]};
    }
  `,
);

export const BalanceSummaryLoading = () => (
  <FlexChild as={Card} data-testid="collectable-balance-summary-loading">
    <div>
      <Margin bottom={[4, 8]}>
        <StyledIconHeader>
          <StyledIconHeaderIcon>
            <SkeletonCircle size="1em" />
          </StyledIconHeaderIcon>
          <StyledIconHeaderText>
            <SkeletonHeading />
          </StyledIconHeaderText>
        </StyledIconHeader>
      </Margin>
      <SkeletonText lines={3} />
      <SkeletonCTA />
    </div>
  </FlexChild>
);
