import { Strong } from '@ovotech/nebula';
import React from 'react';

import {
  StyledFormattedAmount,
  StyledFormattedAmountPence,
} from './FormattedAmount.styled';
import { Theme } from '@/src/types/Theme';

type Props = {
  amount: string;
  fontSize?: keyof Theme['fontSizes'];
  'data-testid'?: string;
};

const FormattedAmount = ({ amount, fontSize = 4, ...rest }: Props) => {
  const [pounds, pence] = amount.split('.');

  return pence == null ? (
    <StyledFormattedAmount fontSize={fontSize} {...rest} as={Strong}>
      {pounds}
    </StyledFormattedAmount>
  ) : (
    <StyledFormattedAmount fontSize={fontSize} {...rest} as={Strong}>
      {`${pounds}.`}
      <StyledFormattedAmountPence>{pence}</StyledFormattedAmountPence>
    </StyledFormattedAmount>
  );
};

export default FormattedAmount;
