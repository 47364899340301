import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';

import { StyledButtonLooksLikeLink, StyledDownloadIcon } from './styled';
import { RENEWAL_EOC1_DOWNLOAD } from '@/src/constants/analytics-subjects';

export function DownloadLink() {
  return (
    <AnalyticsClick name={RENEWAL_EOC1_DOWNLOAD}>
      <StyledButtonLooksLikeLink onClick={window.print}>
        <StyledDownloadIcon />
        Download this page
      </StyledButtonLooksLikeLink>
    </AnalyticsClick>
  );
}
