import { mqMediumDown } from '@/src/utils/mediaQuery';
import styled from 'styled-components';

export const CardWithRightIcon = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: -2px;
    width: 150px;
    height: 150px;
    ${mqMediumDown(`
      display: none;
  `)}
    display: block;
    background: transparent no-repeat center center
      url("data:image/svg+xml,%3Csvg width='149' height='141' viewBox='0 0 149 141' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='149' height='141'%3E%3Cpath d='M0.5 0.5H148.5V140.5H0.5V0.5Z' fill='white' stroke='%23F3F3F3'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath d='M-4.91974 -19.5975L160.859 -64.155L203.592 95.816L37.8124 140.374L-4.91974 -19.5975Z' fill='%23B6D727'/%3E%3C/g%3E%3Cpath d='M54.8333 86.8333H78.6667C78.6667 89 78.6667 90.8333 78.6667 90.8333H54.8333C54.8333 90.8333 54.8333 89 54.8333 86.8333Z' fill='%230A9928'/%3E%3Cpath opacity='0.3' d='M78.6667 51H54.8333V86.8333H78.6667V51Z' fill='%230A9928'/%3E%3Cpath opacity='0.3' d='M70.8003 62.035L59.5314 65.0543L62.5508 76.3232L73.8196 73.3038L70.8003 62.035Z' fill='%230A9928'/%3E%3Cpath d='M99.3334 39.9999C97.3334 39.9999 95.6667 41.6666 95.6667 43.6666C95.6667 45.6666 97.3334 47.3333 99.3334 47.3333C101.333 47.3333 103 45.6666 103 43.6666C103 41.6666 101.5 39.9999 99.3334 39.9999Z' fill='%230A9928'/%3E%3Cpath d='M78.6667 47V80.3333H104.667C110.833 80.3333 115.667 75.3333 115.667 69.3333V38.3333C115.667 32.3333 110.667 27.3333 104.667 27.3333H73.8333C67.6667 27.3333 62.8333 32.3333 62.8333 38.3333V46.8333H54.8333C54.8333 46.8333 54.8333 48.6666 54.8333 50.8333H78.6667V47ZM99.3333 53.8333C93.8333 53.8333 89.1667 49.3333 89.1667 43.6666C89.1667 38.1666 93.6667 33.5 99.3333 33.5C105 33.5 109.5 38 109.5 43.6666C109.5 49.1666 105 53.8333 99.3333 53.8333Z' fill='%230A9928'/%3E%3C/svg%3E%0A");
  }
`;

export const NextCardTitleWrapper = styled.div`
  width: 80%;
`;
