import { P, Small, Sup } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledEVSavingsPanel = styled.div`
  padding: 0;
  flex: 1;
  position: relative;
  overflow: hidden;

  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledCardHeader = styled.div`
  background-color: #0a9828;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  h3 {
    color: white;
  }
`;

export const StyledSup = styled(Sup)`
  margin-right: 5px;
  font-size: 10px !important;
`;

export const StyledContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme: { card, mediaQueries } }) => `
    padding: ${card.padding[0]};

    ${mediaQueries.smallAndUp} {
      padding: ${card.padding[4]};
    }
  `};
`;

export const StyledMiniCardWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;

  ${mediaQueries.mediumAndUp} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1 1 auto;
    }
  `};
`;

export const StyledMiniCard = styled.div`
  ${({ theme: { mediaQueries, card } }) => `
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
    padding: ${card.padding[0]};

  ${mediaQueries.mediumAndUp} {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    }
  `};
`;

export const StyledMiniCardImage = styled.img<{ center?: boolean }>`
  height: 60px;

  ${({ center }) => `
    ${
      center
        ? `
      display: block;
      margin: auto;`
        : ``
    }
  `}
`;

export const StyledEqualsImage = styled(StyledMiniCardImage)`
  margin-top: 20px;
  transform: scale(0.6);
  height: unset;
`;

export const StyledTextUnderImage = styled(P)`
  ${({ theme: { mediaQueries } }) => `
    text-align: center;
    margin-left: 1rem;
    margin-top: 1rem;
  ${mediaQueries.mediumAndUp} {
      text-align: center;
    }
  `};
`;

export const StyledSmall = styled(Small)`
  font-style: italic;
`;

export const StyledContainer = styled.div`
  display: flex;
`;
