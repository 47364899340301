const sandwichIds = {
  planPage: {
    page: 'oep-plan-page',
    intro: 'oep-plan-intro',
  },
  usagePage: {
    topStats: 'oepUsageTopStats',
  },
  globalErrorPage: {
    image: 'oepGlobalErrorPageImage',
  },
  homepage: {
    actionCentre: 'oepHomePageActionCentre',
    accountInfoCard: 'oepAccountInfoCard',
    accountTimeline: 'oepAccountTimeline',
  },
  core: {
    main: 'eopMainContent',
    footer: 'footer',
  },
  sideNavigation: {
    home: 'oepSideNavHome',
    usage: 'oepSideNavMeterSwitch',
    getSmartMeter: 'oepSideNavGetSmartMeter',
    meterReadings: 'oepSideNavMeterReadings',
    billing: 'oepSideNavBilling',
    payments: 'oepSideNavPayments',
    plan: 'oepSideNavPlan',
    offers: 'oepSideNavOffers',
    movingHome: 'oepSideNavMovingHome',
    onboardingHome: 'oepSideNavOnboardingMovingHome',
    onboardingMeterSwitch: 'oepSideNavOnboardingMeterSwitch',
    renewPlan: 'renewPlan',
    pathToZero: 'sideNavPathToZero',
    powerMove: 'sideNavPowerMove',
  },
  headerNavigation: {
    helpAndSupport: 'oepHeaderNavHelpAndSupport',
    accountLinks: 'oepHeaderNavAccountLinks',
    logout: 'oepHeaderNavLogout',
  },
  mobileNavigation: {
    home: 'oepMobileNavHome',
    usage: 'oepMobileNavUsage',
    getSmartMeter: 'oepMobileNavGetSmartMeter',
    meterReadings: 'oepMobileNavMeterReadings',
    billing: 'oepMobileNavBilling',
    payments: 'oepMobileNavPayments',
    plan: 'oepMobileNavPlan',
    offers: 'oepMobileNavOffers',
    movingHome: 'oepMobileNavMovingHome',
    onboardingHome: 'oepMobileNavOnboardingMovingHome',
    onboardingMeterSwitch: 'oepMobileNavOnboardingMeterSwitch',
    helpAndSupport: 'oepMobileNavHelpAndSupport',
    accountLinks: 'oepMobileNavAccountLinks',
    logout: 'oepMobileNavLogout',
    pathToZero: 'mobileNavPathToZero',
    powerMove: 'mobileNavPowerMove',
  },
};

export { sandwichIds };
