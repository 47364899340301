import {
  Heading3,
  Li,
  P,
  PrimaryCTALink,
  SecondaryCTAButton,
  Ul,
} from '@ovotech/nebula';
import React from 'react';
import { ActionCentreBanner } from '../Banner';
import {
  StyledHomePlanContainer,
  StyledHomePlanContainerBody,
  BoldText,
  SmallBoldText,
  DefaultImage,
  StyledImageContainer,
} from '@/src/components/ActionCentre/Banners/HomePlan/HomePlan.styled';
import { CTAWrapper } from '@/src/components/ActionCentre/Banners/banners.styled';
import homePlanDefault from '@/src/resources/homeplan-default.png';

export const HomePlanSmartInstall: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  return (
    <StyledHomePlanContainer>
      <StyledImageContainer>
        <DefaultImage imgUrl={homePlanDefault}></DefaultImage>
      </StyledImageContainer>
      <StyledHomePlanContainerBody>
        <Heading3 data-testid="smart-install-title">
          Boiler cover you can trust
        </Heading3>
        <BoldText>
          Our plans start from £15 a month and include all this good stuff as
          standard:
        </BoldText>
        <Ul>
          <Li>An annual boiler service</Li>
          <Li>Unlimited call outs for breakdowns and repairs</Li>
          <Li>24/7 emergency helpline</Li>
        </Ul>
        <CTAWrapper>
          <PrimaryCTALink
            href={
              'https://www.ovoenergy.com/boiler-cover?utm_medium=banner&utm_source=nba&utm_campaign=108_smart_headline_offer_nba_web'
            }
            onClick={onClick}
            data-testid="smart-install-action-button"
          >
            Get Boiler Cover
          </PrimaryCTALink>
          <SecondaryCTAButton onClick={dismiss}>Close</SecondaryCTAButton>
        </CTAWrapper>
        <P>
          <SmallBoldText> T&Cs apply. </SmallBoldText>
        </P>
      </StyledHomePlanContainerBody>
    </StyledHomePlanContainer>
  );
};
