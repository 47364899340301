import {
  addDays,
  isAfter,
  isBefore,
  isWithinRange,
  startOfDay,
  startOfToday,
  subDays,
} from 'date-fns';

import { AccountSupplyPointFragmentFragment } from '@/src/api/kapi/__generated__/graphql';

type OMRWindowStatus = {
  startDate: string;
  endDate: string;
  isBeforeWindow: boolean;
  isDuringWindow: boolean;
  isAfterWindow: boolean;
};

export const isActiveSupply = (
  supplyPoint: AccountSupplyPointFragmentFragment | undefined | null,
) => {
  if (!supplyPoint || !supplyPoint?.supplyPoint.meterTechnicalDetails.length) {
    return false;
  }

  if (supplyPoint?.end?.date) {
    const endDateIsNotInThePast = !isBefore(
      supplyPoint.end.date,
      subDays(new Date(), 1),
    );
    return endDateIsNotInThePast;
  }

  return supplyPoint.end === null;
};

export const hasAtLeastOneActiveSupply = (
  supplyPoints:
    | readonly AccountSupplyPointFragmentFragment[]
    | undefined
    | null,
) => {
  if (!supplyPoints) return false;

  return supplyPoints.some(isActiveSupply);
};

export const areAllSuppliesActive = (
  supplyPoints: readonly AccountSupplyPointFragmentFragment[],
) => {
  if (!supplyPoints?.length) return false;

  return supplyPoints.every(isActiveSupply);
};

export const haveAllSuppliesBeenActive = (
  supplyPoints: readonly AccountSupplyPointFragmentFragment[] = [],
) => {
  if (!supplyPoints) return true;

  return !supplyPoints.some(
    supplyPoint =>
      !supplyPoint?.supplyPoint ||
      !supplyPoint?.supplyPoint.meterTechnicalDetails.length ||
      isAfter(supplyPoint.startDate, startOfToday()),
  );
};

export const noOfElecSupplies = (
  supplyPoints: readonly AccountSupplyPointFragmentFragment[],
) => {
  if (!supplyPoints || !supplyPoints?.length) return 0;

  return supplyPoints.reduce(
    (count: number, curr: AccountSupplyPointFragmentFragment) =>
      curr?.supplyPoint?.fuelType === 'ELECTRICITY' ? count + 1 : count,
    0,
  );
};

export const isRelatedMPANCustomer = (
  supplyPoints: readonly AccountSupplyPointFragmentFragment[],
) => noOfElecSupplies(supplyPoints) > 1;

export const areComplexSupplies = (
  supplyPoints:
    | readonly AccountSupplyPointFragmentFragment[]
    | undefined
    | null,
) => {
  if (!supplyPoints) return false;

  return (
    supplyPoints.filter(
      supplyPoint =>
        isActiveSupply(supplyPoint) &&
        supplyPoint.supplyPoint.fuelType === 'ELECTRICITY',
    ).length > 1
  );
};

export function getOMRWindowStatus(supplyStart: string): OMRWindowStatus {
  const fromDaysBeforeSSD = 0;
  const toDaysAfterSSD = 5;
  const currentDate = startOfToday();

  const windowStart = subDays(startOfDay(supplyStart), fromDaysBeforeSSD);
  const windowEnd = addDays(startOfDay(supplyStart), toDaysAfterSSD);

  const isBeforeWindow = isBefore(currentDate, windowStart);
  const isDuringWindow = isWithinRange(currentDate, windowStart, windowEnd);

  return {
    startDate: windowStart.toISOString(),
    endDate: windowEnd.toISOString(),
    isBeforeWindow,
    isDuringWindow,
    isAfterWindow: !isBeforeWindow && !isDuringWindow,
  };
}

export const areAllSuppliesPastWindow = (
  supplyPoints: readonly AccountSupplyPointFragmentFragment[],
) =>
  !supplyPoints.some(
    supplyPoint => !getOMRWindowStatus(supplyPoint.startDate).isAfterWindow,
  );
