import { CurrencyValueResponse } from '@/src/types/Response';
import { getCurrencyValueAsNumber } from '@/src/utils/money';

export const recDdIsHigherThanMinimum = (
  friendlyAmount: null | CurrencyValueResponse,
) => {
  if (friendlyAmount == null) return false;

  const recommendedPounds = getCurrencyValueAsNumber(
    { unit: 'GBP' },
    friendlyAmount,
  );

  return recommendedPounds > 5;
};

export const recDdApproxEqualToCurrentDd = (
  friendlyAmount: null | CurrencyValueResponse,
  currentAmount: CurrencyValueResponse,
) => {
  return (
    friendlyAmount != null &&
    Math.abs(
      getCurrencyValueAsNumber({ unit: 'GBP' }, currentAmount) -
        getCurrencyValueAsNumber({ unit: 'GBP' }, friendlyAmount),
    ) < 5
  );
};
