import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import { Em, P, Strong } from '@ovotech/nebula';
import React, { ReactNode } from 'react';

const getRichTextOptions = (): Options => {
  return {
    renderMark: {
      [MARKS.BOLD]: text => <Strong>{text}</Strong>,
      [MARKS.ITALIC]: text => <Em>{text}</Em>,
      [MARKS.CODE]: () => null,
      [MARKS.SUBSCRIPT]: () => null,
      [MARKS.SUPERSCRIPT]: () => null,
      [MARKS.UNDERLINE]: () => null,
    },
    renderNode: {
      [BLOCKS.DOCUMENT]: (_node, children) => children,
      [BLOCKS.PARAGRAPH]: (_node, children) => <P>{children}</P>,
      [BLOCKS.HEADING_1]: () => null,
      [BLOCKS.HEADING_2]: () => null,
      [BLOCKS.HEADING_3]: () => null,
      [BLOCKS.HEADING_4]: () => null,
      [BLOCKS.HEADING_5]: () => null,
      [BLOCKS.HEADING_6]: () => null,
      [BLOCKS.EMBEDDED_ENTRY]: () => null,
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [BLOCKS.EMBEDDED_RESOURCE]: () => null,
      [BLOCKS.UL_LIST]: () => null,
      [BLOCKS.OL_LIST]: () => null,
      [BLOCKS.LIST_ITEM]: () => null,
      [BLOCKS.HR]: () => null,
      [BLOCKS.QUOTE]: () => null,
      [BLOCKS.TABLE]: () => null,
      [BLOCKS.TABLE_CELL]: () => null,
      [BLOCKS.TABLE_HEADER_CELL]: () => null,
      [BLOCKS.TABLE_ROW]: () => null,
    },
  };
};

export const getDocumentAsReactNode = (document?: Document): ReactNode => {
  if (!document) return;
  const options = getRichTextOptions();

  return documentToReactComponents(document, options);
};
