import styled from 'styled-components';
import { Theme } from '@/src/types/Theme';

export const StyledFormattedAmount = styled.strong<{
  fontSize?: keyof Theme['fontSizes'];
}>`
  ${({ theme: { fontSizes }, fontSize }) =>
    fontSize == null ? '' : `font-size: ${fontSizes[fontSize]};`}
  ${({ theme: { colors } }) => `
    color: ${colors.heading};
  `}
`;

export const StyledFormattedAmountPence = styled.span`
  font-size: 0.75em;
`;
