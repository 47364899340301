import { Card, Small, Theme } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledRadio = styled.div`
  & fieldset {
    & div {
      &:nth-of-type(1) {
        margin: 0 0 1rem 0;
      }

      & label {
        padding-left: 3rem;
        padding-top: 0.25rem;
        min-height: 2rem;

        &::before {
          width: 1.9rem;
          height: 1.9rem;
        }

        &::after {
          width: 1.1rem;
          height: 1.1rem;
          top: 0.815rem;
          left: 0.415rem;
        }

        & span {
          font-weight: 400;

          &:nth-of-type(2) {
            color: #666d78;
          }
        }
      }
    }
  }
`;

export const Divider = styled.div<{
  margin: keyof Theme['space'];
}>`
  background-color: #d1d6de;
  height: 1px;
  border: 0;
  ${({ margin, theme: { space } }) => `
        margin-bottom: ${space[margin]};
        margin-top: ${space[margin]};
      `}
`;

export const StyledPayNowCard = styled(Card)`
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

export const StyledHint = styled(Small)`
  ${({ theme: { colors } }) => `
    color: ${colors.border}
`}
`;
