import { Heading3, P, Ul, Li } from '@ovotech/nebula';
import { AnalyticsRender, useAnalytics } from '@ovotech/ui-tools';
import React from 'react';
import Offer from './Offer/Offer';

import { HOMEPLAN_FIXED_PLUS_BC_CARD } from '@/src/constants/analytics-subjects';
import { createHomePlanUrl } from '@/src/constants/endpoints';
import image from '@/src/resources/offers/boiler-cover2.png';

import { Roundel } from '@/src/pages/Offers/components/cards/HomePlan.styled';

type Props = {
  accountId: string;
};

export const HomePlanFixedPlusBC = ({ accountId }: Props) => {
  const analytics = useAnalytics();

  return (
    <>
      <AnalyticsRender name={HOMEPLAN_FIXED_PLUS_BC_CARD}>
        <Offer
          image={image}
          offerId="Fixed+BC"
          linkUrl={createHomePlanUrl(
            'offers-and-upgrades',
            accountId,
            '&utm_medium=banner&utm_source=offers-and-upgrades&utm_campaign=fixed_addon',
          )}
          linkText="Get cover"
          tracking={() => {
            analytics.dispatch({
              name: HOMEPLAN_FIXED_PLUS_BC_CARD,
              type: 'click',
            });
          }}
        >
          <Heading3 as="h2">
            Get £100 energy credit when you add boiler cover to your fixed
            energy plan.
          </Heading3>
          <Roundel>£100 energy credit</Roundel>
          <P>
            We helped with over 120,000 repairs last year, so you know you can
            count on us. All our plans come with:
          </P>
          <Ul>
            <Li>An annual boiler service worth £84</Li>
            <Li>Unlimited callouts</Li>
            <Li>A 24/7 emergency helpline</Li>
            <Li>Access to a network of Gas Safe registered engineers</Li>
          </Ul>
        </Offer>
      </AnalyticsRender>
    </>
  );
};
