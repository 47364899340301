import { useAccountContext, useCustomerContext } from '@ovotech/ui-tools';
import { useQuery } from 'react-query';
import { SMART_THERMOSTATS_ENDPOINT_V1 } from '@/src/constants/endpoints';
import { SmartThermostatsPricing } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

export const useTadoPricingQuery = (promoCode?: string) => {
  const { accountId } = useAccountContext();
  const { customerId } = useCustomerContext();
  const url = `${SMART_THERMOSTATS_ENDPOINT_V1}/pricing/account/${accountId}/customer/${customerId}${
    promoCode ? `?promoCode=${promoCode}` : ''
  }`;

  return useQuery(
    `tado-price-${accountId}`,
    () => apiService.get<SmartThermostatsPricing>({ url }),
    {
      enabled: !!accountId && !!customerId,
    },
  );
};
