import { ContractsResponse } from '@/src/types/Response';

/**
 * Figures out if the customer has (or had) a duel fuel contract.
 * This means both electricity and gas are active status OR in loss status (they HAD a contract for that fuel type).
 *
 * @param contracts the contracts data to check
 * @returns boolean representing if they are duel fuel customer or not
 */
export const isDualFuelContract = (contracts: ContractsResponse): boolean => {
  if (!contracts.electricity.length || !contracts.gas.length) return false;

  const elecStatus = contracts.electricity[0].status;
  const elecStatusIsActiveOrLoss =
    elecStatus.active || elecStatus.inLoss || elecStatus.lossComplete;

  const gasStatus = contracts.gas[0].status;
  const gasStatusIsActiveOrLoss =
    gasStatus.active || gasStatus.inLoss || gasStatus.lossComplete;

  return elecStatusIsActiveOrLoss && gasStatusIsActiveOrLoss;
};
