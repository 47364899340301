import { Heading2, P } from '@ovotech/nebula';
import React from 'react';

import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { formatPoundsAmount } from '@/src/utils/money';
import { isValidNumber } from '@/src/utils/number';

export const DirectDebitReduction: ActionCentreBanner = ({
  dismiss,
  metaData,
}) => {
  return (
    <Banner icon="costCutting" dismiss={dismiss}>
      <Heading2>We’ve reduced your Direct Debit</Heading2>
      {isValidNumber(metaData?.current_dd_amount) &&
      isValidNumber(metaData?.previous_dd_amount) ? (
        <>
          <P>
            Good news, your monthly direct debit has gone down from{' '}
            {formatPoundsAmount(metaData?.previous_dd_amount as string)} to{' '}
            {formatPoundsAmount(metaData?.current_dd_amount as string)}.
          </P>
          <P>
            We looked at your current balance and how much energy you are likely
            to use until 31 March. This new amount should keep your balance
            healthy.
          </P>
        </>
      ) : (
        <>
          <P>Good news, your monthly direct debit has gone down.</P>
          <P>
            We looked at your current balance and how much energy you are likely
            to use until 31 March. The new amount should keep your balance
            healthy.
          </P>
        </>
      )}
    </Banner>
  );
};
