import React from 'react';
import styled from 'styled-components';
import { ActionCentreBanner } from '../Banner';
import { mqMediumDown, mqSmallDown } from '@/src/utils/mediaQuery';
import { SWITCH_TO_FIXED_PLAN } from '@/src/constants/links';
import { ClippedImageBanner } from '@/src/components/ActionCentre/Banners/ClippedImageBanner/ClippedImageBanner';
import svtFixedImage from '@/src/resources/SVTFixed.jpg';

const ClippedImg = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 30%;
  background-image: url(${svtFixedImage});
  background-size: 250px 250px;

  ${mqMediumDown(`
    background-size: 320px 350px;
    background-position: center;
  `)}

  ${mqSmallDown(`
    background-size: 60%;   
    background-position: top;
  `)}
`;

export const SVTFixedSmartEligible: ActionCentreBanner = ({
  onClick,
  dismiss,
}) => {
  return (
    <ClippedImageBanner
      analyticsName=""
      heading="Beat January's price cap increase"
      body="Switch to a fixed plan today to fix your prices below the new energy price cap."
      linkTo={SWITCH_TO_FIXED_PLAN}
      onClick={onClick}
      onDismiss={dismiss}
      ctaTitle="Fix now"
      cancelTitle="Close"
      iconRight="off"
      CroppedImage={ClippedImg}
    />
  );
};
