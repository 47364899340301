import React from 'react';
import { GreenerElectricityContent } from './GreenerElectricityContent/GreenerElectricityContent';
import { GreenerElectricitySignup } from './GreenerElectricitySignup/GreenerElectricitySignup';
import { SectionWrapper } from './GreenerElectricitySignup/GreenerElectricitySignup.styled';
import { SignupSuccess } from './GreenerElectricitySignup/components/GreenerElectricitySignupSuccess';
import { PageWithNavigation } from '@/src/components';

export const GreenerElectricity = () => (
  <PageWithNavigation title="Greener Electricity">
    <GreenerElectricityContent />
  </PageWithNavigation>
);

export const GreenerElectricitySignupPage = () => (
  <PageWithNavigation title="Greener Electricity Signup">
    <SectionWrapper>
      <GreenerElectricitySignup />
    </SectionWrapper>
  </PageWithNavigation>
);

export const GreenerElectricitySignupSuccess = () => (
  <PageWithNavigation title="Greener Electricity Signup Success">
    <SignupSuccess />
  </PageWithNavigation>
);
