import { Heading2, Margin, P, TextLink } from '@ovotech/nebula';
import React from 'react';
import {
  ABS_NORMAL_LINK,
  ABS_TERMS_LINK,
  ANNUAL_BOILER_SERVICE_DESCRIPTION,
  ANNUAL_BOILER_SERVICE_GET_SERVICE_PLAN,
  ANNUAL_BOILER_SERVICE_INFO_LINK_TEXT,
  ANNUAL_BOILER_SERVICE_TITLE,
  BOILER_LIST,
} from '../../constants';
import { Price } from '../Price';
import Offer from './Offer/Offer';
import { NEW_OFFERS_PAGE } from '@/src/constants/analytics';
import AnnualBoilerServiceImage from '@/src/resources/annual-boiler-service.png';
import { UnorderedListSection } from '@/src/pages/Offers/components/UnorderedListSection';

interface AnnualBoilerServiceProps {
  accountId: string;
}

export const AnnualBoilerService = ({
  accountId,
}: AnnualBoilerServiceProps) => {
  return (
    <Offer
      offerId={NEW_OFFERS_PAGE.BOILER_SERVICE_ID}
      image={AnnualBoilerServiceImage}
      linkUrl={`${process.env.NX_KANTAN_ABS_SERVICE_PLAN_JOURNEY_URL}/?accountId=${accountId}&utm_medium=banner&utm_source=offers-and-upgrades&utm_campaign=abs`}
      linkText={ANNUAL_BOILER_SERVICE_GET_SERVICE_PLAN}
      lowerBody={
        <TextLink
          style={{ textAlign: 'center' }}
          href={ABS_NORMAL_LINK}
          opensInNewWindow
        >
          {ANNUAL_BOILER_SERVICE_INFO_LINK_TEXT}
        </TextLink>
      }
    >
      <Heading2>{ANNUAL_BOILER_SERVICE_TITLE}</Heading2>
      <P>{ANNUAL_BOILER_SERVICE_DESCRIPTION}</P>
      <Margin bottom={2}>
        <UnorderedListSection
          title={'Your boiler must be:'}
          list={BOILER_LIST}
        />
      </Margin>
      <Price price="£7 a month, for 12 months" />
      <P>
        View the{' '}
        <TextLink href={ABS_TERMS_LINK} opensInNewWindow>
          annual boiler service terms
        </TextLink>
      </P>
    </Offer>
  );
};
