import { Observable } from 'rxjs';

import { SMART_THERMOSTATS_ENDPOINT_V1 } from '@/src/constants/endpoints';
import { AjaxResponse } from '@/src/types/Ajax';
import { ProductId } from '@/src/types/Products';
import {
  OrderType,
  PaymentType,
} from '@/src/pages/SmartThermostats/utils/types';
import apiService from '@/src/services/apiService';

const makeOrdersUrl = (): string => {
  return SMART_THERMOSTATS_ENDPOINT_V1 + `/orders/confirmation`;
};

const parseAddress = (addressList: string[]) => {
  if (addressList.length === 4) {
    return {
      addressLine1: addressList[0],
      addressLine2: addressList[1],
      city: addressList[2],
      postalCode: addressList[3],
    };
  } else {
    return {
      addressLine1: addressList[0],
      city: addressList[1],
      postalCode: addressList[2],
    };
  }
};

export const callOrdersAPI = (
  accountId: string,
  phone: string,
  address: string[],
  orderType: OrderType,
  paymentType: PaymentType,
  productId: ProductId,
  paymentToken?: string,
  paymentIntentId?: string,
  promoCode?: string,
): Observable<AjaxResponse> => {
  const url = makeOrdersUrl();
  const parsedAddress = parseAddress(address);
  return apiService.post({
    responseType: 'json',
    url: url,
    body: {
      accountId,
      orderType,
      paymentType,
      productId,
      phone,
      paymentToken,
      paymentIntentId,
      promoCode,
      ...parsedAddress,
    },
  });
};

export const callWaitlistAPI = (reasons: string[]) => {
  const url = SMART_THERMOSTATS_ENDPOINT_V1 + '/register-interest';
  return apiService.post({
    responseType: 'json',
    url: url,
    body: {
      reasons,
    },
  });
};

export const callWaitlistStatusAPI = (
  accountId: string,
): Observable<{ inWaitlist: boolean }> => {
  const url =
    SMART_THERMOSTATS_ENDPOINT_V1 +
    `/register-interest/status?accountId=${accountId}`;
  return apiService
    .get({
      responseType: 'json',
      url: url,
    })
    .map(resp => {
      return resp.response;
    });
};

export const callHeatingSetupAPI = (
  item: 'boiler_type' | 'thermostat_type' | 'thermostat_count',
  answer: string,
) => {
  const url = SMART_THERMOSTATS_ENDPOINT_V1 + '/heating-setup';
  return apiService
    .post({
      responseType: 'json',
      url: url,
      body: {
        item,
        answer,
      },
    })
    .subscribe(resp => {
      return resp;
    });
};
