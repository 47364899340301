import { Heading3 } from '@ovotech/nebula';
import React from 'react';

import { UsageSummary } from '../../pages/MyThermostatPage/useMonthGasUsageSummaries';

import {
  EmphasisedText,
  IntroText,
  MonthlySummary,
  MonthlySummarySection,
  MonthlySummaryWithLeftBorder,
} from './MonthlySummaries.styled';

type Props = {
  monthOnLeft?: UsageSummary;
  monthOnRight?: UsageSummary;
  isNavigatedToLatestMonth: boolean;
};

const toTwoDecimalPlace = (num: number) => {
  return num.toFixed(2);
};

function deriveGasDescription(differenceInGasUsage: number) {
  const roundedDifference = Math.round(differenceInGasUsage);
  if (differenceInGasUsage > 0) {
    return `+${roundedDifference} kWh`;
  }
  return `${roundedDifference} kWh`;
}

function deriveCostDescription(differenceInCost: number) {
  const differenceAsPositive = Math.abs(differenceInCost);
  const roundedDifferenceAsPositive = toTwoDecimalPlace(differenceAsPositive);
  if (differenceAsPositive === differenceInCost) {
    return `You used £${roundedDifferenceAsPositive} more`;
  }
  return `You saved £${roundedDifferenceAsPositive}`;
}

export const MonthlySummaries: React.FC<Props> = ({
  monthOnLeft,
  monthOnRight,
  isNavigatedToLatestMonth,
}) => {
  if (
    !monthOnLeft ||
    !monthOnRight ||
    !monthOnLeft.consumption ||
    !monthOnRight.consumption ||
    !monthOnLeft.cost ||
    !monthOnRight.cost
  ) {
    return null;
  }

  if (isNavigatedToLatestMonth) {
    return (
      <React.Fragment>
        <MonthlySummarySection>
          <MonthlySummary data-testid="monthly-summary-previous">
            <IntroText>Previous month</IntroText>
            <Heading3>{Math.round(monthOnLeft.consumption)} kWh</Heading3>
            <EmphasisedText>
              £{toTwoDecimalPlace(monthOnLeft.cost)}
            </EmphasisedText>
          </MonthlySummary>
          <MonthlySummaryWithLeftBorder data-testid="monthly-summary-current">
            <IntroText>So far this month</IntroText>
            <Heading3>{Math.round(monthOnRight.consumption)} kWh</Heading3>
            <EmphasisedText>
              £{toTwoDecimalPlace(monthOnRight.cost)}
            </EmphasisedText>
          </MonthlySummaryWithLeftBorder>
        </MonthlySummarySection>
      </React.Fragment>
    );
  }
  const differenceInCost = monthOnRight.cost - monthOnLeft.cost;
  const differenceInGasUsage =
    monthOnRight.consumption - monthOnLeft.consumption;
  return (
    <React.Fragment>
      <MonthlySummarySection>
        <MonthlySummary data-testid="monthly-summary-previous">
          <IntroText>Previous month</IntroText>
          <Heading3>{Math.round(monthOnLeft.consumption)} kWh</Heading3>
          <EmphasisedText>
            £{toTwoDecimalPlace(monthOnLeft.cost)}
          </EmphasisedText>
        </MonthlySummary>
        <MonthlySummaryWithLeftBorder data-testid="monthly-summary-current">
          <IntroText>This month</IntroText>
          <Heading3>{Math.round(monthOnRight.consumption)} kWh</Heading3>
          <EmphasisedText>
            £{toTwoDecimalPlace(monthOnRight.cost)}
          </EmphasisedText>
        </MonthlySummaryWithLeftBorder>
        <MonthlySummaryWithLeftBorder data-testid="monthly-summary-comparison">
          <IntroText>How this compares</IntroText>
          <Heading3>{deriveGasDescription(differenceInGasUsage)}</Heading3>
          <EmphasisedText>
            {deriveCostDescription(differenceInCost)}
          </EmphasisedText>
        </MonthlySummaryWithLeftBorder>
      </MonthlySummarySection>
    </React.Fragment>
  );
};
