import {
  Accordion,
  AccordionGroup,
  Card,
  Heading2,
  Heading3,
  Heading4,
  Margin,
  P,
  SecondaryCTALink,
} from '@ovotech/nebula';
import { AnalyticsRender, AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import {
  ConfirmationIcon,
  CheckText,
  NextActionsContainer,
  GetYourSmartMeterContainer,
  SmartMeterSvg,
  BookAppointmentLink,
} from './GetASmartMeter.styled';
import { PageWithNavigation, Toggleable } from '@/src/components';
import {
  MY_THERMOSTAT_GET_A_SMART_METER_PAGE,
  MY_THERMOSTAT_BOOK_APPOINTMENT_BUTTON,
} from '@/src/constants/analytics-subjects';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import { SMART_METERS_LINK } from '@/src/constants/routes';

export const GetASmartMeter = () => (
  <AnalyticsRender name={MY_THERMOSTAT_GET_A_SMART_METER_PAGE}>
    <PageWithNavigation title="My Thermostat">
      <Margin top={8}>
        <Margin bottom={1}>
          <Card>
            <Heading2>My thermostat</Heading2>
            <P>
              Upgrade to a smart meter to learn even more about your heating
            </P>
          </Card>
        </Margin>
        <Margin bottom={1}>
          <Card>
            <GetYourSmartMeterContainer>
              <div>
                <Heading3>Get your smart meter installed for free</Heading3>
                <Toggleable title="What is a smart meter?">
                  <P>
                    A smart meter is a digital gas or electricity meter that
                    communicates with your energy supplier so they can receive
                    your readings automatically. They measure how much energy
                    you use, then share this info with you in your online
                    account and through a clever gadget called an In-Home
                    Display (IHD).
                  </P>
                </Toggleable>
                <Margin top={6}>
                  <CheckText>
                    <ConfirmationIcon />
                    <P>
                      Spot patterns in your gas and electricity use and find out
                      where to make savings.
                    </P>
                  </CheckText>
                  <CheckText>
                    <ConfirmationIcon />
                    <P>
                      Get more accurate bills without having to send us a meter
                      reading ever again.
                    </P>
                  </CheckText>
                  <CheckText>
                    <ConfirmationIcon />
                    <P>Fight the climate crisis by saving energy.</P>
                  </CheckText>
                  <CheckText>
                    <ConfirmationIcon />
                    <P>
                      Connect your tado with OVO and learn more about how you
                      heat your home.
                    </P>
                  </CheckText>
                </Margin>
                <Margin top={4}>
                  <NextActionsContainer>
                    <AnalyticsClick
                      name={MY_THERMOSTAT_BOOK_APPOINTMENT_BUTTON}
                    >
                      <BookAppointmentLink
                        variant="missionPrimary"
                        iconRight="chevron-right"
                        href={`${SMART_BOOKING_URL}?utm_source=MyOVO&utm_medium=banner&utm_campaign=thermostat`}
                        data-testid="book-appointment"
                      >
                        Book an appointment
                      </BookAppointmentLink>
                    </AnalyticsClick>

                    <SecondaryCTALink
                      variant="missionSecondary"
                      iconRight="chevron-right"
                      href={SMART_METERS_LINK}
                      data-testid="more-about-smart-meters"
                    >
                      More about smart meters
                    </SecondaryCTALink>
                  </NextActionsContainer>
                </Margin>
              </div>
              <SmartMeterSvg />
            </GetYourSmartMeterContainer>
          </Card>
        </Margin>
        <AccordionGroup style={{ padding: 0 }}>
          <Accordion
            title="What if I can't get a smart meter?"
            headingComponent={Heading4}
          >
            <P>
              If you're not able to get a gas smart meter installed, we won't be
              able to provide you with the additional energy-saving features,
              because that relies on us being able to view your daily gas
              consumption.
            </P>{' '}
            <P>
              Watch this space though, our team is working on new ways to make
              this better all the time.
            </P>
          </Accordion>
        </AccordionGroup>
      </Margin>
    </PageWithNavigation>
  </AnalyticsRender>
);
