import { TextLink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { colours } from '../CarbonIntensityShared.assets';
import { carbonIntensityAssets } from '../CurrentIntensity/CurrentIntensity.assets';
import {
  StyledHeaderCircle,
  StyledCarbonIntensityHeaderWidget,
  StyledIntensityIcon,
  StyledIntensityBadge,
  StyledHeaderRectangle,
} from './CarbonIntensityHeaderWidget.styled';
import { Link } from '@/src/components';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';

import { CarbonIntensityLevel } from '@/src/pages/EnergyInsights/types/carbon-intensity.types';

type Props = {
  currentIntensity?: CarbonIntensityLevel;
};

export const CarbonIntensityHeaderWidget = ({ currentIntensity }: Props) => {
  if (!currentIntensity) {
    return null;
  }

  return (
    <>
      <StyledCarbonIntensityHeaderWidget
        colour={colours[currentIntensity].muted}
      >
        <StyledIntensityIcon>
          {carbonIntensityAssets[currentIntensity].icon}
        </StyledIntensityIcon>
        <StyledIntensityBadge>
          {carbonIntensityAssets[currentIntensity].indicatorBadge}
        </StyledIntensityBadge>
        <AnalyticsClick
          name={ENERGY_INSIGHTS.BUTTON_CLICK_CARBON_INTENSITY_HUB_HEADER_LINK}
        >
          <TextLink as={Link} to={ROUTE_ENERGY_INSIGHTS.CARBON_INTENSITY}>
            See more
          </TextLink>
        </AnalyticsClick>
      </StyledCarbonIntensityHeaderWidget>
      <StyledHeaderCircle colour={colours[currentIntensity].muted} />
      <StyledHeaderRectangle colour={colours[currentIntensity].muted} />
    </>
  );
};
