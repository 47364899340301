import { CTALink, Heading3, Margin, P } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom-v5';
import NumberedCard from '../components/NumberedCard';
import { DATE_FORMAT } from '../shared';
import { PlanWithEndDate } from '../utils';
import { RENEWAL_EOC1 } from '@/src/constants/analytics-subjects';
import { ROUTE_RENEWAL } from '@/src/constants/routes';
import { RenewablePlansResponse } from '@/src/types/RenewablePlans';

export const PotentialActions = ({
  planWithNearestEndDate,
  renewablePlans,
}: {
  planWithNearestEndDate?: PlanWithEndDate;
  renewablePlans: RenewablePlansResponse;
}) => {
  return (
    <>
      {renewablePlans && planWithNearestEndDate && (
        <P>
          Your {planWithNearestEndDate.name} plan is ending on{' '}
          {format(planWithNearestEndDate.endDate, DATE_FORMAT)}. So here’s what
          you can do:
        </P>
      )}
      <Margin top={4}>
        <NumberedCard orderNumber={1}>
          <Heading3 as="h2">Renew your plan – it’s easy</Heading3>
          <P>
            Our fixed energy plans offer you peace of mind by locking your
            prices until the end of your contract.
          </P>
          <AnalyticsClick name={RENEWAL_EOC1}>
            <CTALink
              variant="missionPrimary"
              as={Link}
              to={`${ROUTE_RENEWAL}?utm_source=eoc-page`}
            >
              Pick a plan today
            </CTALink>
          </AnalyticsClick>
        </NumberedCard>
      </Margin>
      <Margin top={4}>
        <NumberedCard orderNumber={2}>
          <Heading3 as="h2">Do nothing</Heading3>
          <P>
            If you don’t renew your plan
            {planWithNearestEndDate?.endDate
              ? ` by ${format(planWithNearestEndDate.endDate, DATE_FORMAT)}`
              : ''}
            , we’ll follow industry rules and move you to our variable plan,
            Simpler Energy, where prices can go up and down. But if you switch
            to a fixed plan, your prices will be locked until your contract
            ends.
          </P>
        </NumberedCard>
      </Margin>
      <Margin top={4}>
        <NumberedCard orderNumber={3}>
          <Heading3 as="h2">Consider shopping around</Heading3>
          <P>
            We’d love for you to stay, but if you want to change suppliers, we
            understand. If you decide to do so, you don’t need to tell us – your
            new supplier will let us know.
          </P>
        </NumberedCard>
      </Margin>
    </>
  );
};
