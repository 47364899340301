import { ReactElement, useEffect } from 'react';

import AnalyticsService from '@/src/services/analyticsService';

type Props = {
  name: string;
  properties?: Record<string, unknown> | null;
  children: ReactElement | null;
};

export const AnalyticsWrapper = ({ children, name, properties }: Props) => {
  useEffect(() => {
    AnalyticsService.dispatch(name, properties);
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default AnalyticsWrapper;
