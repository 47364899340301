import { INTERNAL_EVENT_FLAG, useComponents } from '@ovotech/energy-cx';
import { TextLink, Icon, P } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledTextLink = styled.a(
  ({ theme: { space } }) => `
    > span {
      display: inline-flex;
      line-height: 1.2;
      gap: ${space[2]};
    }
  `,
);

type Props = JSX.IntrinsicElements['a'];

export const StyledBreadCrumb = forwardRef<HTMLAnchorElement, Props>(
  ({ children, href, ...rest }, ref) => {
    const { TextLink: TL } = useComponents(); // TODO: will be removed in MOSS-1454

    return (
      <P>
        <AnalyticsClick
          name="Breadcrumb"
          properties={{ [INTERNAL_EVENT_FLAG]: true }}
        >
          <StyledTextLink
            ref={ref}
            href={href}
            // TODO MOSS Remove this dirty hack. This component should be refactored in MOSS-1454
            as={href ? TL : TextLink}
            {...rest}
          >
            <Icon name="chevron-left" size="1.2em" />
            {children}
          </StyledTextLink>
        </AnalyticsClick>
      </P>
    );
  },
);
