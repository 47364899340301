import { SignupSource } from '@/src/types/Products';

export function getGreenerElectricitySourceFromURL() {
  const utmSourceValue = new URLSearchParams(window.location.search).get(
    'utm_source',
  );

  const isValid = Object.values(SignupSource).some(
    (source: SignupSource) => source === utmSourceValue,
  );

  return isValid ? (utmSourceValue as SignupSource) : undefined;
}
