import styled from 'styled-components';
import { mqSmallUp } from '@/src/utils/mediaQuery';

export const CTAWrapper = styled.div`
  ${({ theme: { space } }) => `
    display: flex;
    gap: ${space[4]};
    flex-direction: column;

    ${mqSmallUp(`
      gap: ${space[6]};
      flex-direction: row;
    `)}
  `}
`;
