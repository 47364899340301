import {
  Card,
  ErrorNotification,
  P,
  PrimaryCTAButton,
  TextLink,
} from '@ovotech/nebula';
import React, { useEffect, useState } from 'react';
import { useEncryptedToken } from './hooks/use-encrypted-token';
import { LoadingSpinner, PageWithNavigation } from '@/src/components';
import { createEnergyExpertsJourneyUrl } from '@/src/constants/endpoints';

const openInCurrentTab = (url: string) => {
  window.open(url, '_self', 'noreferrer');
};

export const EnergyExpertsRedirect = () => {
  const { getEncryptedToken, isEncrypting, isLoading } = useEncryptedToken();
  const [encryptionError, setEncryptionError] = useState<boolean>(false);

  const fetchToken = async () => {
    const encryptedToken = await getEncryptedToken();
    if (encryptedToken) {
      openInCurrentTab(createEnergyExpertsJourneyUrl(encryptedToken));
    } else {
      setEncryptionError(true);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      fetchToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <PageWithNavigation title="Energy Experts">
      <Card>
        {encryptionError ? (
          <ErrorNotification
            id="api-error"
            title="Sorry, there's a bit of a problem"
          >
            <P>Please try again.</P>
            <PrimaryCTAButton
              type="button"
              onClick={() => {
                setEncryptionError(false);
                fetchToken();
              }}
              disabled={isEncrypting}
            >
              Retry
            </PrimaryCTAButton>
          </ErrorNotification>
        ) : (
          <>
            <LoadingSpinner text="You're being redirected to the Energy Experts booking site." />
            <P style={{ textAlign: 'center' }}>
              <TextLink href={fetchToken}>Click here</TextLink> if you have not
              been redirected automatically.
            </P>
          </>
        )}
      </Card>
    </PageWithNavigation>
  );
};
