import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const FeaturesWrapper = styled.div`
  column-count: 2;
  column-width: 300px;
  column-gap: 2rem;
  display: flex;

  ${mqMediumDown(`
    columns: initial;
    display: flex;
    flex-direction: column;
  `)};
`;
