import React from 'react';
import { LabelProps } from 'recharts';
import {
  StyledCarbonTonnes,
  StyledLabel,
  StyledLabelName,
} from './CarbonComparisonCustomLabel.styled';

import { AVERAGE_HOME_CARBON_KG_TOTAL } from '@/src/constants/stats';
import { kgToTonne } from '@/src/pages/EnergyInsights/utils/conversions';

type Props = {
  carbonTonnes: number;
};

export const CarbonComparisonCustomLabel = (props: LabelProps & Props) => {
  const { x, y, carbonTonnes, name } = props;

  const isYourImpact = name === 'Your impact';

  const tonnes = isYourImpact
    ? carbonTonnes.toFixed(1)
    : Math.round(kgToTonne(AVERAGE_HOME_CARBON_KG_TOTAL) * 10) / 10;

  const yPosition = isYourImpact ? Number(y) - 60 : Number(y) - 80;

  return (
    <g>
      <foreignObject x={x} y={yPosition} width="100%" height="100%">
        <StyledLabel>
          <StyledCarbonTonnes>{tonnes} tonnes</StyledCarbonTonnes>
          <StyledLabelName>{name}</StyledLabelName>
        </StyledLabel>
      </foreignObject>
    </g>
  );
};
