import React from 'react';
import { NoUsageBreakdown } from '../../../NoUsageBreakdown';
import FuelBreakdownContent from './FuelBreakdownContent';
import { Fuel } from '@/src/types/Response';
import { BreakdownWeek } from '@/src/pages/EnergyInsights/types/bill-breakdown.types';

type Props = {
  breakdownWeek: BreakdownWeek;
  lastWeekBreakdown?: BreakdownWeek;
  fuel: Fuel;
};

export const FuelBreakdown = ({
  breakdownWeek,
  fuel,
  lastWeekBreakdown,
}: Props) => {
  const { dataStatus } = breakdownWeek[fuel] ?? {};

  return (
    <>
      {dataStatus?.dataUnavailableMessage ? (
        <NoUsageBreakdown
          statusCode={dataStatus.code}
          message={dataStatus.dataUnavailableMessage}
        />
      ) : (
        <FuelBreakdownContent
          fuel={fuel}
          breakdownWeek={breakdownWeek}
          lastWeekBreakdown={lastWeekBreakdown}
        />
      )}
    </>
  );
};
