import React from 'react';
import {
  StyledCircle,
  StyledDoughnut,
  StyledTriangle,
  StyledGreenerEnergyModalContent,
  StyledContent,
} from './GreenerEnergyModal.styled';

interface Props extends React.HTMLProps<HTMLDivElement> {
  onClose: any;
  title: string;
  showGreenerEnergyModal: boolean;
  testId?: string;
}

export const GreenerEnergyModal = ({
  children,
  onClose,
  title,
  showGreenerEnergyModal,
  testId,
}: Props) => {
  return (
    <StyledGreenerEnergyModalContent
      title={title}
      isOpen={showGreenerEnergyModal}
      onClose={onClose}
      data-testid={testId}
    >
      <StyledTriangle
        width="46"
        height="135"
        viewBox="0 0 46 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45.7053 0.568542L9.84225 134.411L-88.1373 36.4316L45.7053 0.568542Z"
          fill="#B6D727"
        />
      </StyledTriangle>

      <StyledCircle
        width="118"
        height="38"
        viewBox="0 0 118 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="59" cy="-21" r="59" fill="#0A9828" />
      </StyledCircle>

      <StyledDoughnut
        width="87"
        height="86"
        viewBox="0 0 87 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M173 86.5C173 134.273 134.273 173 86.5 173C38.7274 173 0 134.273 0 86.5C0 38.7274 38.7274 0 86.5 0C134.273 0 173 38.7274 173 86.5ZM53.6096 86.5C53.6096 104.665 68.3352 119.39 86.5 119.39C104.665 119.39 119.39 104.665 119.39 86.5C119.39 68.3352 104.665 53.6096 86.5 53.6096C68.3352 53.6096 53.6096 68.3352 53.6096 86.5Z"
          fill="#6CBD27"
        />
      </StyledDoughnut>
      <StyledContent>{children}</StyledContent>
    </StyledGreenerEnergyModalContent>
  );
};
