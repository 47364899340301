import { PlanFamily } from '../planConstants';
import { AvailablePlanKaluza, PaymentMethod } from '../types';
import { PossibleAvailablePlans } from './generateMockPlan';

type MockPlanDetails = Omit<AvailablePlanKaluza, 'tariffs' | 'exitFeesTotal'>;

const variablePlanDetails = (
  name: PlanFamily,
  paymentMethod: PaymentMethod
): MockPlanDetails => ({
  name,
  paymentMethod,
  type: 'variable',
  estimatedAnnualCost: { amount: '2234.01', currencyUnit: 'GBP' },
  estimatedMonthlyCost: { amount: '186', currencyUnit: 'GBP' },
});

const fixedPlanDetails = (
  name: PlanFamily,
  lengthInMonths: number
): MockPlanDetails => ({
  name,
  lengthInMonths,
  type: 'fixed',
  paymentMethod: 'Direct Debit',
  estimatedAnnualCost: { amount: '2234.01', currencyUnit: 'GBP' },
  estimatedMonthlyCost: { amount: '186', currencyUnit: 'GBP' },
});

export const mockAvailablePlansKaluza: {
  [key in PossibleAvailablePlans]: MockPlanDetails;
} = {
  'Simpler Energy DD': variablePlanDetails(
    PlanFamily.simplerEnergy,
    'Direct Debit'
  ),
  'Simpler Energy OD': variablePlanDetails(
    PlanFamily.simplerEnergy,
    'On Demand'
  ),
  'Three Year Fixed': fixedPlanDetails(PlanFamily.threeYearFixed, 36),
  'Two Year Fixed': fixedPlanDetails(PlanFamily.twoYearFixed, 24),
  'Two Year Fix And Protect': fixedPlanDetails(
    PlanFamily.twoYearFixAndProtect,
    24
  ),
  'One Fixed Boiler Cover': fixedPlanDetails(
    PlanFamily.oneYearFixedBoilerCover,
    12
  ),
  'One Year Fixed': fixedPlanDetails(PlanFamily.oneYearFixed, 12),
  'Better Energy': fixedPlanDetails(PlanFamily.betterEnergy, 12),
  Drive: fixedPlanDetails(PlanFamily.drive, 12),
  'Member Special': fixedPlanDetails(PlanFamily.memberSpecial, 12),
  'Stay and Save': fixedPlanDetails(PlanFamily.stayAndSave, 12),
  '1 Year Fixed + Greener Energy': fixedPlanDetails(
    PlanFamily.oneYearFixedGreenerEnergy,
    12
  ),
  '1 Year Fixed Loyalty': fixedPlanDetails(PlanFamily.oneYearFixedLoyalty, 12),
  '2 Year Fixed + Heating Control': fixedPlanDetails(
    PlanFamily.twoYearFixedAndHeatingControl,
    24
  ),
  '1 Year Fixed Go Smart': fixedPlanDetails(PlanFamily.oneYearFixedGoSmart, 12),
  '1 Year Fixed + Greener Electricity': fixedPlanDetails(
    PlanFamily.oneYearFixedGreenerElectricity,
    12
  ),
  'Extended Fixed': fixedPlanDetails(PlanFamily.extendedFixed, 18),
};
