import { Card } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqSmallDown, mqSmallToMedium } from '@/src/utils/mediaQuery';

export const ImageWrapper = styled.div<{
  backgroundImage: string;
}>`
  ${({ backgroundImage }) => `
  background-image:  url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  `}
  padding-top: 10px;
  padding-bottom: 10px;
  height: 300px;
  width: 350px;
  align-self: center;
  clip-path: polygon(0% 14%, 23% 100%, 100% 86%, 100% 10%, 96% 0%);
  ${mqSmallToMedium(`
    clip-path: polygon(0% 0%, 18% 100%, 100% 60%, 100% 0%);
    background-position: 30% 15%;
    width: 100%;
    height: 180px;
    padding: 0;
  `)}
  ${mqSmallDown(`
    clip-path: polygon(0% 0%, 18% 100%, 100% 60%, 100% 0%);
    background-position: 20% 10%;
    width: 100%;
    height: 180px;
    padding: 0;
  `)}
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: block;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  background-color: #ffffff;
  ${mqSmallDown(`
    flex-direction: column-reverse;
`)}
  ${mqSmallToMedium(`
    flex-direction: column-reverse;
  `)}
`;

export const FindAnInstallerCard = styled.div`
  ${({ theme: { space } }) => `
    margin-top: ${space[9]};
  `}
  border-radius: 5px;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #ecf0fd;
`;

export const FindAnInstallerContent = styled(Card)<{
  backgroundColor?: string;
}>`
  ${({ backgroundColor }) => `
    background-color: ${backgroundColor};
  `}
  padding: 10px;
`;

export const InfoWrapper = styled.div`
  padding: 30px;
  width: 100%;
`;
