import { P, TextLink } from '@ovotech/nebula';
import React from 'react';
import {
  BANNER_CTA_CLICKED,
  NOT_REQUESTED_SWITCH_CTA_CLICKED,
} from '@/src/constants/analytics';

interface LossBannerCTAProps {
  lossEvent: string;
  bannerId: string;
  onClick?: () => void;
}

export const LossBannerCTA = (props: LossBannerCTAProps) => {
  const { lossEvent, bannerId, onClick } = props;

  return (
    <P>
      Didn't ask to switch?{' '}
      <TextLink
        onClick={onClick}
        opensInNewWindow
        data-event-name={BANNER_CTA_CLICKED}
        data-event-props={JSON.stringify({
          lossEvent,
          ctaName: NOT_REQUESTED_SWITCH_CTA_CLICKED,
          bannerId,
        })}
        href="https://www.ovoenergy.com/help/erroneous-transfers"
      >
        Let us know
      </TextLink>
      .
    </P>
  );
};
