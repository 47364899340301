import { Heading4, Li, Ul } from '@ovotech/nebula';
import React, { useState } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';
import { breakpointVals } from '@/src/utils/mediaQuery';
import { ReactComponent as PrimaryDownIcon } from '@/src/resources/chevron-down-mission-primary.svg';
import { ReactComponent as PrimaryUpIcon } from '@/src/resources/chevron-up-mission-primary.svg';

interface UnorderedListSectionProps {
  title: string;
  list: Array<string | React.ReactNode>;
}

const StyledHeading4 = styled(Heading4)`
  margin: 16px 0;
`;

const StyledUl = styled(Ul)`
  list-style-type: none;
  margin: 0 0 0 12px;
`;

const StyledLi = styled(Li)`
  position: relative;
  padding-left: 25px;
  margin-bottom: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 17px;
    height: 17px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg clip-path='url(%23clip0_9745_4623)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.51853 14.6446L16.4687 3.59493L15.0423 2.18136L5.50569 11.8046L1.90953 8.24083L0.495966 9.66724L5.51853 14.6446Z' fill='%230D8426'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_9745_4623'%3E%3Crect width='16' height='16' fill='white' transform='translate(0.454346 0.457336) rotate(-0.259173)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const StyledTextLink = styled.a`
  color: #de1c8c;
  text-decoration: underline;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  & .arrow-icon {
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  & .arrow-icon svg {
    margin-left: 5px; /* Add some spacing between the anchor text and the SVG icon */
  }
`;

export const UnorderedListSection = (props: UnorderedListSectionProps) => {
  const { width } = useWindowDimensions();
  const isMobile = width < breakpointVals.medium;
  const [isOpen, setIsOpen] = useState(!isMobile);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isMobile && (
        <StyledTextLink onClick={toggleDropdown}>
          Read {isOpen ? 'Less' : 'More'}
          <span className="arrow-icon">
            {isOpen ? <PrimaryUpIcon /> : <PrimaryDownIcon />}
          </span>
        </StyledTextLink>
      )}
      {isOpen && (
        <>
          <StyledHeading4>{props.title}</StyledHeading4>
          <StyledUl>
            {props.list.map((item, idx) => (
              <StyledLi key={idx}>{item}</StyledLi>
            ))}
          </StyledUl>
        </>
      )}
    </>
  );
};
