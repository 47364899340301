import {
  Card,
  Heading4,
  Margin,
  Notification,
  P,
  Strong,
} from '@ovotech/nebula';
import React from 'react';

import { getPredictedBalance, mapToChartData } from './utils';
import { LiveRegion, ProjectedCostChart } from '@/src/components';
import {
  CurrencyValueResponse,
  ProjectedCostsResponse,
} from '@/src/types/Response';
import { recDdIsHigherThanMinimum } from '@/src/utils/shouldShowRecommendedDD';

import {
  formatCurrencyValue,
  formatPounds,
  getPoundValueAsNumber,
} from '@/src/utils/money';
import { formatFullDate } from '@/src/utils/date';

type Props = {
  balance: CurrencyValueResponse;
  projectedCosts: ProjectedCostsResponse;
  newDirectDebitAmount: string;
  payingRightAmount: boolean;
  calculationEndDate: Date;
};

type AdvicePanelProps = {
  newDirectDebit: CurrencyValueResponse;
  payingRightAmount: boolean;
  balance: CurrencyValueResponse;
  projectedCosts: ProjectedCostsResponse;
  calculationEndDate: Date;
};

const AdvicePanel = ({
  balance,
  newDirectDebit,
  projectedCosts,
  payingRightAmount,
  calculationEndDate,
}: AdvicePanelProps) => {
  const newDirectDebitAmount = getPoundValueAsNumber(newDirectDebit);
  const predictedBalance = getPredictedBalance(
    newDirectDebitAmount,
    Number(balance ? balance?.amount : 0),
    projectedCosts,
  );

  const inDebt = predictedBalance
    ? Math.round(Number(predictedBalance)) < 0
    : false;

  const liveContent = (
    <Heading4 style={{ fontWeight: 'normal' }}>
      A monthly payment of: <Strong>{formatPounds(newDirectDebit)}</Strong>
      <br /> would bring your balance to:{' '}
      <Strong>
        {predictedBalance?.round(0, 2).gte(0)
          ? `£${predictedBalance?.round(0, 2)}`
          : `-£${predictedBalance?.round(0, 2).abs()}`}
      </Strong>
      <br />
      {`by ${formatFullDate(calculationEndDate)}`}.
    </Heading4>
  );

  const statusList = projectedCosts.recommendedDirectDebit.state || [];

  if (statusList.includes('AccountInLoss')) {
    return (
      <Notification
        id="advice-notification"
        variant={inDebt ? 'error' : 'info'}
      >
        <P>
          <Strong>
            Because you’re switching away, we’re not able to show you a payment
            recommendation.
          </Strong>
        </P>
      </Notification>
    );
  } else if (
    statusList.includes('BillingOnHold') ||
    statusList.includes('AmountOverSensibleUpperLimit') ||
    projectedCosts.recommendedDirectDebit === null ||
    !predictedBalance
  ) {
    return (
      <Notification
        id="advice-notification"
        variant={inDebt ? 'error' : 'info'}
      >
        <P>
          <Strong>
            Sorry, we’re not able to show you a payment recommendation right
            now.
          </Strong>
          We’re working super hard to fix this.
        </P>
      </Notification>
    );
  } else if (statusList.includes('RecentlyOnboarded')) {
    return (
      <Notification
        id="advice-notification"
        variant={inDebt ? 'error' : 'info'}
      >
        <P>
          <Strong>
            Once you’ve been with us for 8 weeks, we can start showing you
            payment recommendations.
          </Strong>
        </P>
        <P>
          By that point, we should have a nice, clear picture of your monthly
          energy use.
        </P>
      </Notification>
    );
  } else {
    return (
      <Notification
        id="advice-notification"
        variant={inDebt ? 'error' : 'info'}
      >
        <LiveRegion>{liveContent}</LiveRegion>
        {liveContent}
        {payingRightAmount ? (
          <P>
            We think you’re paying the right amount for the energy you’re using.
          </P>
        ) : !recDdIsHigherThanMinimum(
            projectedCosts.recommendedDirectDebit.friendlyAmount,
          ) ? (
          <P>
            We recommend you change your Direct Debit to <Strong>£5</Strong> –
            our minimum amount.
          </P>
        ) : (
          <P>
            <Strong>
              {formatCurrencyValue(
                { unit: 'GBP', dp: 0, ceil: true },
                projectedCosts.recommendedDirectDebit.friendlyAmount!,
              )}
            </Strong>{' '}
            is the minimum monthly payment to keep your balance on track.
          </P>
        )}
      </Notification>
    );
  }
};

const recommendedAmount = (projectedCosts: ProjectedCostsResponse) => {
  const states = [
    'AmountOverSensibleUpperLimit',
    'BillingOnHold',
    'RecentlyOnboarded',
    'AccountInLoss',
  ];

  if (
    states.some(x => projectedCosts.recommendedDirectDebit.state?.includes(x))
  ) {
    return 0;
  } else if (projectedCosts.recommendedDirectDebit.friendlyAmount) {
    return getPoundValueAsNumber(
      projectedCosts.recommendedDirectDebit.friendlyAmount,
    );
  } else {
    return 0;
  }
};

export const ProjectedCosts = ({
  balance,
  projectedCosts,
  newDirectDebitAmount,
  payingRightAmount,
  calculationEndDate,
}: Props) => {
  const newDirectDebit: CurrencyValueResponse = {
    amount: newDirectDebitAmount || '0',
    currencyUnit: 'GBP',
  };

  return (
    <>
      <AdvicePanel
        data-testid="dd-calc-projected-costs"
        newDirectDebit={newDirectDebit}
        balance={balance}
        projectedCosts={projectedCosts}
        payingRightAmount={payingRightAmount}
        calculationEndDate={calculationEndDate}
      />
      <Margin top={2}>
        <Card>
          <ProjectedCostChart
            data={mapToChartData(projectedCosts)}
            recommendedDirectDebit={recommendedAmount(projectedCosts)}
            currentDirectDebit={Number(newDirectDebitAmount)}
          />
        </Card>
      </Margin>
    </>
  );
};
