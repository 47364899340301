import { PageWithSimpleHeader } from '@/src/components';
import React from 'react';
import {
  HeadingWrapper,
  StyledPageContainer,
  StyledTadoThermostatImage,
  StyledTadoThermostatImageWrapper,
} from '../SmartThermostatOffer.styled';
import { NavigationBreadCrumb } from '../components/NavigationBreadcrumb';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { ErrorNotification, Heading1, P } from '@ovotech/nebula';
import TadoThermostatImage from '@/src/resources/tado-thermostat.png';
import styled from 'styled-components';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';

const StyledHeading1 = styled(Heading1)`
  ${({ theme }) => `
    color: ${theme.colors.errorContrast} // #FFFFFF
  `};
`;

export const Error = () => {
  return (
    <PageWithSimpleHeader
      title="tado Checkout"
      analytics={{
        name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.ORDER_YOUR_TADO.ERROR
          .PAYMENT,
      }}
    >
      <StyledPageContainer>
        <NavigationBreadCrumb
          href={ROUTE_SMART_THERMOSTATS_OFFER + '/checkout'}
        />
        <HeadingWrapper className="checkout__heading-wrapper">
          <StyledHeading1>Order your tado°</StyledHeading1>
          <StyledTadoThermostatImageWrapper className="checkout__image-wrapper">
            <StyledTadoThermostatImage src={TadoThermostatImage} />
          </StyledTadoThermostatImageWrapper>
        </HeadingWrapper>

        <ErrorNotification id="error-notification">
          <P>Please try again later.</P>
        </ErrorNotification>
      </StyledPageContainer>
    </PageWithSimpleHeader>
  );
};
