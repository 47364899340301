import { Observable, Subscription } from 'rxjs';

type Tracker = {
  track(name: string, properties?: Record<string, unknown> | null): void;
  identify(id: string): void;
  register(properties: Record<string, unknown>): void;
  reset(): void;
};

class AnalyticsService {
  source$: Observable<Event> = new Observable();
  trackers: Tracker[];
  private subscription$: Subscription = new Subscription();

  constructor() {
    this.trackers = [];
  }

  public initialize(trackers: Tracker[] = []) {
    trackers.forEach(tracker => this.trackers.push(tracker));

    this.source$ = Observable.fromEvent(document, 'click');
    this.subscription$ = this.source$.subscribe(this.clickListener);
  }

  public destroy() {
    this.subscription$.unsubscribe();
  }

  public dispatch(
    name: string,
    properties: Record<string, unknown> | null = {},
  ) {
    this.trackers.forEach((tracker: Tracker) => {
      if (tracker && typeof tracker.track === 'function') {
        tracker.track(name, properties);
      }
    });
  }

  public identify(id: string) {
    this.trackers.forEach((tracker: Tracker) => {
      if (tracker && typeof tracker.identify === 'function') {
        tracker.identify(id);
      }
    });
  }

  public register(properties: Record<string, unknown>) {
    this.trackers.forEach((tracker: Tracker) => {
      if (tracker && typeof tracker.register === 'function') {
        tracker.register(properties);
      }
    });
  }

  public reset() {
    this.trackers.forEach((tracker: Tracker) => {
      if (tracker && typeof tracker.reset === 'function') {
        tracker.reset();
      }
    });
  }

  private clickListener = (event: Event) => {
    const { name, props } = this.getData(event.target);

    if (name) {
      this.dispatch(name, props);
    }
  };

  private getData(target: any) {
    let name = target?.dataset?.eventName;
    let props =
      target?.dataset?.eventProps && JSON.parse(target?.dataset?.eventProps);

    if (!name) {
      name = target?.parentElement?.dataset?.eventName;
      props =
        target?.parentElement?.dataset?.eventProps &&
        JSON.parse(target?.parentElement?.dataset?.eventProps);
    }

    return { name, props };
  }
}

const Analytics = new AnalyticsService();

export default Analytics;
