import React from 'react';
import { ROUTE_PROFILE } from '@/src/constants/routes';
import { ENABLE_MHHS_READ_FREQUENCY_SETTINGS } from '@/src/constants/feature-flags';

import { Card, TextLink, Icon, ErrorNotification, P } from '@ovotech/nebula';
import { NotFoundSection } from '@ovotech/energy-cx/dist/shared/core/components';
import { MeterContent } from '@/src/components/MeterContent/MeterContent';
import {
  StyledHeading,
  Separator,
  StyledTextLink,
} from './EditMeterReadsFrequency.styled';

import { useFeature } from '@/src/utils/optimizely';
import { RouterLink } from '@/src/components';
import { useConsentQuery, useUpdateSupplyPointConsent } from '@/src/api';
import { LoadingCard } from './LoadingCard';
import { IneligibleSection } from './IneligibleSection';
import { ConsentLevel, UpdateSupplyPointConsentInput } from '@monovo/kapi';
import {
  HHS_FAQ_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from '@/src/constants/links';

export const EditMeterReadFrequencyHeading = () => (
  <>
    <StyledTextLink to={ROUTE_PROFILE} as={RouterLink}>
      <Icon name="chevron-left" />
      Back to profile
    </StyledTextLink>

    <StyledHeading>Your meter read settings</StyledHeading>

    <P>
      Choose how often your meter sends readings – every half hour, once a day
      or once a month.
    </P>
    <P>
      By updating your settings, you agree to our{' '}
      <TextLink href={TERMS_AND_CONDITIONS_LINK} opensInNewWindow>
        terms
      </TextLink>{' '}
      and{' '}
      <TextLink href={PRIVACY_POLICY_LINK} opensInNewWindow>
        privacy policy
      </TextLink>
      .
    </P>
    <TextLink href={HHS_FAQ_LINK} opensInNewWindow>
      See our FAQs for help choosing
    </TextLink>
    <Separator />
  </>
);

const EditMeterReadFrequency = () => {
  const [isMHHSFeatureEnabled] = useFeature(
    ENABLE_MHHS_READ_FREQUENCY_SETTINGS,
  );
  const consentQuery = useConsentQuery();
  const billingMutation = useUpdateSupplyPointConsent('billing');
  const settlementMutation = useUpdateSupplyPointConsent('settlement');

  const handleRadioChange = (
    sprn: string,
    consentLevel: string,
    policyName: string,
  ): void => {
    switch (policyName) {
      case 'billing':
        billingMutation.mutate({
          input: {
            sprn,
            policyName: 'BILLING_CONSENT',
            consentLevel: consentLevel as ConsentLevel,
            consentType: 'EXPLICIT',
          } as UpdateSupplyPointConsentInput,
        });
        return;
      case 'settlement':
        settlementMutation.mutate({
          input: {
            sprn,
            policyName: 'SETTLEMENT_CONSENT',
            consentLevel: consentLevel as ConsentLevel,
            consentType: 'EXPLICIT',
          } as UpdateSupplyPointConsentInput,
        });
        return;
      default:
        return;
    }
  };

  const renderContent = () => {
    if (consentQuery.isLoading) return <LoadingCard />;

    if (consentQuery.isError)
      return (
        <ErrorNotification id="mhhs-consent-query-error">
          Sorry, something went wrong. Try again later.
        </ErrorNotification>
      );

    if (
      !consentQuery.data?.hasValidSupplyPointsAndMeters &&
      consentQuery.data !== undefined
    )
      return <IneligibleSection />; // come up with better content when user is on a trad or inactive smart meter

    return (
      <>
        {consentQuery.data?.consentData.map(consentData => (
          <MeterContent
            key={consentData.sprn}
            fuel={consentData.fuel}
            sprn={consentData.sprn}
            billingConsent={consentData.consent.billing}
            settlementConsent={consentData.consent.settlement}
            onRadioChange={handleRadioChange}
            isMutationLoading={
              billingMutation.isLoading || settlementMutation.isLoading
            }
          />
        ))}
      </>
    );
  };

  if (!isMHHSFeatureEnabled) return <NotFoundSection />;

  return (
    <Card>
      <EditMeterReadFrequencyHeading />
      {renderContent()}
    </Card>
  );
};

export default EditMeterReadFrequency;
