import React from 'react';
import { Step } from './HomeProfileForm.styled';

type HomeProfileWizardStepProps = {
  children: React.ReactNode;
  visible: boolean;
  dataTestId?: string;
};

export const HomeProfileFormWizardStep = ({
  visible,
  children,
  dataTestId,
}: HomeProfileWizardStepProps) => (
  <Step visible={visible} aria-hidden={!visible} data-testid={dataTestId}>
    {children}
  </Step>
);
