import { useAccountContext } from '@ovotech/ui-tools';
import { useProductCatalogueQuery, useProductsQuery } from '@/src/api';
import { PRODUCT_IDS } from '@/src/constants/products';

export enum IneligibleReasons {
  PURCHASED_TADO_BEFORE = 'PURCHASED_TADO_BEFORE',
  ALL_SMART_THERMOSTATS_BOLTONS_NOT_ELIGIBLE = 'ALL_SMART_THERMOSTATS_BOLTONS_NOT_ELIGIBLE',
}

type UseTadoEligibleReturn = {
  isLoading: boolean;
  isError: boolean;
  isEligible: boolean;
  ineligibleReasons: Array<IneligibleReasons>;
};

export const useTadoEligible = (): UseTadoEligibleReturn => {
  const { accountId } = useAccountContext();
  const productCatalogue = useProductCatalogueQuery();
  const activeProducts = useProductsQuery(accountId);
  const ineligibleReasons: IneligibleReasons[] = [];

  const smartThermProducts = [
    PRODUCT_IDS.smartThermostatSelfInstall,
    PRODUCT_IDS.smartThermostats,
    PRODUCT_IDS.smartThermostatsPayUpfrontProInstall,
    PRODUCT_IDS.smartThermostatsPayUpfrontSelfInstall,
    PRODUCT_IDS.heatingControlTwoYrFix,
  ];

  if (productCatalogue.isLoading || activeProducts.isLoading) {
    return {
      isLoading: true,
      isError: false,
      isEligible: false,
      ineligibleReasons,
    };
  }

  if (productCatalogue.isError || activeProducts.isError) {
    return {
      isLoading: false,
      isError: true,
      isEligible: false,
      ineligibleReasons,
    };
  }

  const hasPurchasedTado = activeProducts.data?.boltons.some(bolton =>
    smartThermProducts.includes(bolton.productId),
  );

  const hasNoEligibleSmartThermostatBoltons = productCatalogue.data?.boltons
    .filter(bolton => smartThermProducts.includes(bolton.productId))
    .every(bolton => bolton.eligibility.eligible === false);

  if (hasPurchasedTado) {
    ineligibleReasons.push(IneligibleReasons.PURCHASED_TADO_BEFORE);
  }

  if (hasNoEligibleSmartThermostatBoltons) {
    ineligibleReasons.push(
      IneligibleReasons.ALL_SMART_THERMOSTATS_BOLTONS_NOT_ELIGIBLE,
    );
  }

  return {
    isLoading: false,
    isError: false,
    isEligible: ineligibleReasons.length === 0,
    ineligibleReasons,
  };
};
