import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '@/src/resources/logo.svg';

const StyledLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    width: 64px;
  }
`;

const SiteLoading = () => {
  return (
    <StyledLoading data-testid="site-loading">
      <Logo />
      Loading...
    </StyledLoading>
  );
};

export default SiteLoading;
