import { P, Strong } from '@ovotech/nebula';
import React from 'react';
import { CurrencyValueResponse, Payment } from '@/src/types/Response';
import { formatCurrencyValue } from '@/src/utils/money';
import { dateToEpochDays, formatDateAndMonth } from '@/src/utils/date';
import { useAccountContext } from '@ovotech/ui-tools';
import { useAccountPaymentsQuery } from '@/src/api';
import { AccountInfoLoading } from '@/src/pages/Payments/components/AccountPaymentSummary/AccountInfoLoading';

const DirectDebitInfo = () => {
  const { accountId } = useAccountContext();
  const accountPaymentsQueryResult = useAccountPaymentsQuery(accountId);
  const payments =
    accountPaymentsQueryResult.data?.billingSummary.latestPeriod.data.payments;

  if (accountPaymentsQueryResult.isLoading) {
    return <AccountInfoLoading />;
  }

  if (!payments || payments.length === 0) {
    return null;
  }

  const lastDD: Payment | undefined | null = payments
    .filter(
      ({ description }: { description: string }) =>
        description === 'Direct Debit',
    )
    .shift();

  if (!lastDD || !lastDD.date) {
    return null;
  }

  return (
    <P>
      You made a Direct Debit payment of{' '}
      <Strong>
        {formatCurrencyValue(
          {
            unit: 'GBP',
            abs: true,
            dp: 2,
          },
          {
            amount: lastDD.credit.pounds,
            currencyUnit: 'GBP',
          },
        )}
      </Strong>{' '}
      on the {formatDateAndMonth(dateToEpochDays(new Date(lastDD.date)))}.
    </P>
  );
};

const formatLiveBalanceAmount = (amount: string) => {
  const liveBalance = {
    amount,
    currencyUnit: 'GBP',
  } as CurrencyValueResponse;

  const liveBalanceSuffix =
    Number(amount) === 0 ? '' : `in ${amount[0] === '-' ? 'debit' : 'credit'}.`;

  return `${formatCurrencyValue(
    {
      unit: 'GBP',
      abs: true,
      dp: 2,
    },
    liveBalance,
  )} ${liveBalanceSuffix}`;
};

type Props = {
  liveBalance: string;
  displayLastDD?: boolean;
};

export const PaymentsLiveBalanceSummary = ({
  liveBalance,
  displayLastDD = true,
}: Props) => {
  return (
    <>
      <P data-testid="live-balance-summary">
        {'Your account balance is '}
        <Strong>{formatLiveBalanceAmount(liveBalance)}</Strong>
      </P>
      {displayLastDD && <DirectDebitInfo />}
    </>
  );
};
