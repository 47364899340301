import React from 'react';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { CustomerInformationCard } from './components/CustomerInformationCard/CustomerInformationCard';

const modify = {
  [sandwichIds.homepage.accountInfoCard]: ({ children }: any) => (
    <>{children}</>
  ),
  [sandwichIds.homepage.accountTimeline]: ({ children }: any) => (
    <>
      {children}
      <CustomerInformationCard />
    </>
  ),
};

export { modify };
