import { P } from '@ovotech/nebula';
import React from 'react';
import { TipInteractionButtonBar } from './Components/TipInteractionButtonBar';
import { StyledIconHeaderContainer, StyledTextContainer } from './Tip.styled';
import { ActionSlideCard, ActionSlideCardContent } from './TipSlideshow.styled';
import { ReactComponent as BulbIcon } from '@/src/resources/bulb_icon.svg';
import { IconHeader } from '@/src/pages/EnergyInsights/components';
import {
  HandleUpdateTipInteractionStatus,
  TipInteractionStatus,
} from '@/src/pages/EnergyInsights/hooks/useGetTipInteractionsFromLocalStorage';
import { TipContent } from '@/src/pages/EnergyInsights/utils/tips';

type Props = TipContent & {
  handleUpdateTipInteractionStatus: HandleUpdateTipInteractionStatus;
  tipInteractionStatus: TipInteractionStatus;
};

const ExperiementalTipInteractionVariant = ({
  title,
  description,
  handleUpdateTipInteractionStatus,
  tipInteractionStatus,
}: Props) => (
  <ActionSlideCard inline>
    <ActionSlideCardContent data-testid={`action-${title}`}>
      <StyledIconHeaderContainer>
        <IconHeader heading={title} Icon={BulbIcon} level={4} />
      </StyledIconHeaderContainer>
      <StyledTextContainer>
        <P>{description}</P>
      </StyledTextContainer>
      <TipInteractionButtonBar
        tipInteractionTitle={title}
        tipInteractionStatus={tipInteractionStatus}
        handleClick={handleUpdateTipInteractionStatus}
      />
    </ActionSlideCardContent>
  </ActionSlideCard>
);

const Tip = (props: Props) => <ExperiementalTipInteractionVariant {...props} />;

export default Tip;
