import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { ENDPOINT_TARIFF_COSTS } from '@/src/constants/endpoints';
import apiService from '@/src/services/apiService';
import {
  Action,
  FetchTariffCostsError,
  FetchTariffCostsStart,
  FetchTariffCostsSuccess,
} from '@/src/types/Action';
import {
  FetchTariffCostsResponse,
  TariffCostsState,
  TariffCostsStatus,
} from '@/src/types/TariffCosts';

export const fetchTariffCostsStart = (): FetchTariffCostsStart => ({
  type: 'FETCH_TARIFF_COSTS_START',
});

export const fetchTariffCostsSuccess = (
  response: FetchTariffCostsResponse,
): FetchTariffCostsSuccess => ({
  type: 'FETCH_TARIFF_COSTS_SUCCESS',
  data: response,
});

export const fetchTariffCostsError = (): FetchTariffCostsError => ({
  type: 'FETCH_TARIFF_COSTS_ERROR',
});

export const fetchTariffCostsEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  action$.ofType('FETCH_TARIFF_COSTS_START').switchMap(() => {
    const {
      user: { selectedAccountId },
    } = store.getState();
    if (!selectedAccountId) {
      return Observable.of(fetchTariffCostsError());
    }

    return apiService
      .get({
        responseType: 'json',
        url: `${ENDPOINT_TARIFF_COSTS}/${selectedAccountId}`,
      })
      .map(({ response }: { response: FetchTariffCostsResponse }) =>
        fetchTariffCostsSuccess(response),
      )
      .catch(() => Observable.of(fetchTariffCostsError()));
  });

export const defaultState: TariffCostsState = {
  status: TariffCostsStatus.Idle,
  data: null,
};

const tariffCosts = (
  state: TariffCostsState = defaultState,
  action: Action,
) => {
  switch (action.type) {
    case 'FETCH_TARIFF_COSTS_START': {
      return {
        ...state,
        status: TariffCostsStatus.Started,
      };
    }
    case 'FETCH_TARIFF_COSTS_SUCCESS': {
      return {
        ...state,
        status: TariffCostsStatus.Resolved,
        data: action.data,
      };
    }
    case 'FETCH_TARIFF_COSTS_ERROR': {
      return {
        ...state,
        status: TariffCostsStatus.Rejected,
      };
    }
    default: {
      return state;
    }
  }
};

export default tariffCosts;
