import { CTAButton, Disclosure, Heading3, Margin, P } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { useState } from 'react';
import { Divider } from '../../../components/Divider.styled';
import { Features } from '../../../components/Features';
import { Rates } from '../../../components/Rates';
import { Tariff } from '../../../types/quoteRequest';
import { getPlanDetails } from '../../../utils/planDetails';
import { Header, HeaderTitle, HeaderSubtitle } from './Header.styled';
import { ColumnTitle, QuoteCard, TotalPrice } from './QuotePlan.styled';
import { TermsLinks } from './TermsLinks';
import { EVENT_SELECTED_TARIFF } from '@/src/pages/MoveIn/analytics';

export const QuotePlan = ({
  tariff,
  updateMoveIn,
}: {
  tariff: Tariff;
  updateMoveIn: (tariff: Tariff) => void;
}) => {
  const { exitFees, monthlyCost, planDetails } = getPlanDetails(tariff);

  const [isShowingPlanDetails, setShowingPlanDetails] = useState(false);

  return (
    <QuoteCard>
      <Header>
        <HeaderTitle as={Heading3}>{planDetails.title}</HeaderTitle>
        <HeaderSubtitle>({planDetails.subtitle})</HeaderSubtitle>
      </Header>

      <Margin all={4}>
        <P>{planDetails.description}</P>

        <Divider />

        <ColumnTitle>Estimated energy cost</ColumnTitle>
        <TotalPrice data-testid={'estimated-price-' + planDetails.title}>
          £{monthlyCost}
          <P> a month</P>
        </TotalPrice>

        <AnalyticsClick
          name={EVENT_SELECTED_TARIFF}
          properties={{ name: tariff.name }}
        >
          <CTAButton
            type="button"
            iconRight="chevron-right"
            variant="missionPrimary"
            onClick={() => updateMoveIn(tariff)}
            data-testid={'select-plan-button-' + planDetails.title}
          >
            Select Plan
          </CTAButton>
        </AnalyticsClick>

        <TermsLinks tariffName={tariff.name} />

        <Divider />

        <Disclosure
          title={
            (isShowingPlanDetails ? 'Hide' : 'Show') + ' energy plan details'
          }
          onToggle={setShowingPlanDetails}
        >
          <Rates electricity={tariff.tils.Electricity} gas={tariff.tils.Gas} />

          <Divider dashed />

          <Features
            exitFees={exitFees}
            partialFeatures={planDetails.features}
          />
        </Disclosure>
      </Margin>
    </QuoteCard>
  );
};
