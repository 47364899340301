import React from 'react';
import { GridContainer } from '../../../components/GridContainer';
import { CARBON_ZERO, ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { HomeProfileWidgetResponse } from '@/src/types/Response';

import {
  HomeProfileWidgetWithResponsiveContent,
  HomeProfileWidgetEvents,
  HomeProfileWidgetPosition,
  homeProfileWidgetTestIDs,
} from '../../../components/HomeProfileWidget';
import { useHubHomeProfileWidget } from '../useHubHomeProfileWidget';

type Props = {
  homeProfileWidget: HomeProfileWidgetResponse;
  pagePosition: HomeProfileWidgetPosition;
};

const events: HomeProfileWidgetEvents = {
  viewed: CARBON_ZERO.HOME_PROFILE_WIDGET.VIEWED,
  ctaClicked: ENERGY_INSIGHTS.BUTTON_CLICK_UPDATE_HOME_PROFILE_HUB,
  dismissed: CARBON_ZERO.HOME_PROFILE_WIDGET.CLOSE_BUTTON_CLICKED,
};

export const HomeProfileWidgetHub = ({
  homeProfileWidget,
  pagePosition,
}: Props) => {
  const { onClose } = useHubHomeProfileWidget(homeProfileWidget);

  return (
    <GridContainer>
      <div data-testid={homeProfileWidgetTestIDs.widgetPosition(pagePosition)}>
        <HomeProfileWidgetWithResponsiveContent
          {...homeProfileWidget}
          events={events}
          onDismiss={onClose}
        />
      </div>
    </GridContainer>
  );
};
