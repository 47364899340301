import styled from 'styled-components';

export const StyledGhost = styled.span<{ width?: string; height?: string }>`
  ${({ width, height }) => `
    width: ${width};
    height: ${height};
    display: block;
    background-color: rgba(0, 0, 0, 0.1);
    min-width: 100%;
    margin: 0 0 1.5rem 0;
    animation: ghostPulse 1.25s ease-in-out infinite;

    @media (min-width: 480px) {
      min-width: auto;
    }
    @keyframes ghostPulse {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.5;
      }
    }
  `}
`;
