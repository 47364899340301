import { useQuery } from 'react-query';
import { createTescoRewardsGetUrl } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { TescoRewardResponse } from '../types/Response';

type QueryCacheKey = `tesco-rewards-${string}`;

export const getTescoRewardsQueryCacheKey = (
  accountId: string | null | undefined,
) => `tesco-rewards-${accountId}`;

export const useTescoRewards = (accountId: string | null | undefined) =>
  useQuery<TescoRewardResponse>(
    `tesco-rewards-${accountId}` as QueryCacheKey,
    () =>
      apiService.get({
        url: createTescoRewardsGetUrl(accountId ?? ''),
      }),
    {
      enabled: !!accountId,
      /**
       * Disable cache so we get latest state after update to any elibility status
       * inc.direct debit, smart mater and linked clubcard
       */
      cacheTime: 0,
    },
  );
