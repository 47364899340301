import { Heading3, P } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import {
  FootnoteReferenced,
  FootnoteReferencedContent,
} from '@/src/components';

interface PriceProps {
  price: string;
  strikeOutText?: string;
  footerNoteContent?: FootnoteReferencedContent;
}

const StyledPriceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  display: block;
`;

const StyledStrikeOutText = styled(P)`
  color: var(--content-text, #2f3749);
  font-size: 1.8rem;
  text-decoration: line-through;
  margin-top: 3px;
`;

const StyledH3 = styled(Heading3)`
  margin-bottom: 10px;
  padding-right: 5px;
`;

export const Price = (props: PriceProps) => (
  <StyledPriceContainer>
    <StyledH3>
      {props.price}
      {props.footerNoteContent && (
        <FootnoteReferenced content={props.footerNoteContent} />
      )}
    </StyledH3>{' '}
    {props?.strikeOutText && (
      <StyledStrikeOutText>{props.strikeOutText}</StyledStrikeOutText>
    )}
  </StyledPriceContainer>
);
