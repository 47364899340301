import { CTAButton, Heading3, P } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { ButtonWrapper } from '../HomeProfileForm/HomeProfileForm.styled';
import { HomeProfileFormWizardStep } from '../HomeProfileForm/HomeProfileFormWizardStep';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';

type Props = {
  onClickCTA: VoidFunction;
};

export const HomeProfileOutro = ({ onClickCTA }: Props) => (
  <HomeProfileFormWizardStep visible>
    <Heading3>Thanks!</Heading3>

    <P>We’ll finish setting this up. It usually takes up to 24 hours.</P>

    <ButtonWrapper>
      <AnalyticsClick
        name={ENERGY_INSIGHTS.BUTTON_CLICK_HOME_PROFILE_SEE_ENERGY_SPOTLIGHTS}
      >
        <CTAButton
          type="button"
          data-testid="SeeSpotlightsCloseModal"
          onClick={onClickCTA}
        >
          Finish
        </CTAButton>
      </AnalyticsClick>
    </ButtonWrapper>
  </HomeProfileFormWizardStep>
);
