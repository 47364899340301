import { Heading3, P, PrimaryCTALink, Strong } from '@ovotech/nebula';
import { format } from 'date-fns';
import React from 'react';
import { Banner } from '../../Banner';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import {
  BANNER_CTA_CLICKED,
  GET_A_QUOTE_CTA_CLICKED,
  LOSS_MESSAGE_SHOWN,
} from '@/src/constants/analytics';
import { OVO_QUOTE_AND_SWITCH } from '@/src/constants/endpoints';
import { formatCurrencyValue } from '@/src/utils/money';

interface Props {
  bannerId: string;
  lossDate: string;
  lossEvent: string;
  balance: string;
}

export const FinalStatementReadyBalanceInCredit = (props: Props) => {
  const { bannerId, lossDate, lossEvent, balance } = props;
  const amount = formatCurrencyValue(
    {
      unit: 'GBP',
      abs: true,
      dp: 2,
    },
    { amount: balance, currencyUnit: 'GBP' },
  );

  return (
    <AnalyticsWrapper name={LOSS_MESSAGE_SHOWN} properties={{ lossEvent }}>
      <Banner icon="finalStatement" data-testid="balance-in-credit">
        <Heading3 as="h2">You've switched to a new energy supplier</Heading3>
        <P>
          Your switch happened on{' '}
          <Strong data-testid="loss-final-statement-date">
            {format(new Date(lossDate), 'Do MMMM YYYY')}.
          </Strong>
        </P>
        <P>
          Your closing balance was {amount}, we'll refund this to your bank
          account within 10 working days.
        </P>
        <P>
          We'd love to have you back – check out our plans to see if you could
          pay less for your energy.
        </P>
        <P>
          <PrimaryCTALink
            opensInNewWindow
            data-event-name={BANNER_CTA_CLICKED}
            data-event-props={JSON.stringify({
              bannerId: `${bannerId}-balanceInCredit`,
              ctaName: GET_A_QUOTE_CTA_CLICKED,
              lossEvent,
            })}
            href={OVO_QUOTE_AND_SWITCH}
          >
            Get a quote
          </PrimaryCTALink>
        </P>
      </Banner>
    </AnalyticsWrapper>
  );
};
