import { P, PrimaryCTALink, Strong } from '@ovotech/nebula';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom-v5';
import styled, { ThemeContext } from 'styled-components';

import { hubTestIDs } from '../../../testIDs';
import UsageBarAndFigure from './UsageBarAndFigure';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { mqSmallDown } from '@/src/utils/mediaQuery';
import { BreakdownWidgetData } from '@/src/pages/EnergyInsights/types';
import AnalyticsService from '@/src/services/analyticsService';

const BreakdownSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.5em;
  ${mqSmallDown(`
    height: 250px;
  `)}
`;

export const BreakdownSection = ({
  totalUsage,
  barWidths,
  comparisonTotal,
  comparisonBarText,
  mostUsedCategories,
}: BreakdownWidgetData) => {
  const themeContext = useContext(ThemeContext);
  return (
    <BreakdownSectionContainer data-testid={hubTestIDs.breakdownWidget}>
      <UsageBarAndFigure
        totalUsage={totalUsage}
        barWidth={barWidths.yourBarWidth}
        barColour={themeContext.colors.brand.primary.lighter}
        barText="Your home"
        testId={hubTestIDs.breakdownYourHome}
      />
      <UsageBarAndFigure
        totalUsage={comparisonTotal}
        barWidth={barWidths.averageBarWidth}
        barColour={themeContext.colors.brand.grey.base}
        barText={comparisonBarText}
        testId={hubTestIDs.breakdownSimilarHome}
      />

      <P>
        You used most {mostUsedCategories.fuel} on{' '}
        <Strong>{mostUsedCategories.topCategory}</Strong> &{' '}
        <Strong>{mostUsedCategories.secondCategory}</Strong>
      </P>

      <PrimaryCTALink
        as={Link}
        to={ROUTE_ENERGY_INSIGHTS.BILL_BREAKDOWN}
        data-testid={hubTestIDs.breakdownCTA}
        onClick={() => {
          AnalyticsService.dispatch(
            ENERGY_INSIGHTS.BUTTON_CLICK_HUB_NAVIGATE_TO_BREAKDOWN,
          );
        }}
      >
        See all categories and tips
      </PrimaryCTALink>
    </BreakdownSectionContainer>
  );
};
