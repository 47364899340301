import { Heading3, P, Ul, Li } from '@ovotech/nebula';
import { AnalyticsRender, useAnalytics } from '@ovotech/ui-tools';
import React from 'react';
import Offer from './Offer/Offer';

import { HOMEPLAN_FIXED_PLUS_BC_CARD_Q1_PROMO_OFFER } from '@/src/constants/analytics-subjects';

import { FootnoteReferenced } from '@/src/components';
import {
  footnoteOHSFixedQ1PromoOfferNoClaims,
  footnoteOHSFixedQ1PromoOfferSavings,
} from '../FooterTermsAndConditions';

import { createHomePlanUrl } from '@/src/constants/endpoints';

import image from '@/src/resources/offers/boiler-cover2.png';

import { Roundel } from '@/src/pages/Offers/components/cards/HomePlan.styled';

type Props = {
  accountId: string;
};

export const HomePlanFixedPlusBCQ1PromoOffer = ({ accountId }: Props) => {
  const analytics = useAnalytics();

  return (
    <AnalyticsRender name={HOMEPLAN_FIXED_PLUS_BC_CARD_Q1_PROMO_OFFER}>
      <Offer
        image={image}
        offerId="fixedQ1PromoOffer"
        linkUrl={createHomePlanUrl(
          'offers-and-upgrades',
          accountId,
          '&utm_medium=banner&utm_campaign=fixed_addon_q1_offer',
        )}
        linkText="Get cover"
        tracking={() => {
          analytics.dispatch({
            name: HOMEPLAN_FIXED_PLUS_BC_CARD_Q1_PROMO_OFFER,
            type: 'click',
          });
        }}
      >
        <Heading3 as="h2">
          Get half-price boiler cover for 6 months.
          <FootnoteReferenced content={footnoteOHSFixedQ1PromoOfferNoClaims} />
        </Heading3>
        <Roundel>£100 energy credit</Roundel>

        <P>Plus, get £100 energy credit.</P>

        <P>
          Add boiler cover to your fixed energy plan and save up to £196.
          <FootnoteReferenced content={footnoteOHSFixedQ1PromoOfferSavings} />
        </P>

        <Ul>
          <Li>Unlimited callouts and a 24/7 emergency helpline</Li>
          <Li>Choose the cover that works for you</Li>
          <Li>Plans include an annual boiler service worth £84</Li>
          <Li>We helped with over 120,000 repairs last year</Li>
        </Ul>
      </Offer>
    </AnalyticsRender>
  );
};
