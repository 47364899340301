import styled from 'styled-components';
import { mqMediumDown, mqMediumUp } from '@/src/utils/mediaQuery';

export const StyledFeatureImageWrapper = styled.img`
  width: 100%;
  height: auto;
`;

export const StyledFeatureRow = styled.div<{
  isImageFirst: boolean;
}>`
  ${mqMediumDown(`
    align-items: center;
  `)}
  ${({ isImageFirst }) => `
    ${mqMediumDown(`
      flex-direction: ${isImageFirst === true ? 'column' : 'column-reverse'};
    `)}
  `}
`;

export const StyledFeatureCol = styled.div<{
  isImageFirst: boolean;
}>`
  ${({ isImageFirst }) => `
  ${mqMediumUp(`
  padding-right: ${isImageFirst === true ? '0' : ''};
  padding-left: ${isImageFirst === false ? '0' : ''};
`)}
${mqMediumDown(`
  margin-bottom: 0;
`)}
  `}
`;

export const IconWrapper = styled.div`
  > svg {
    display: block;
    ${mqMediumDown(`
    display: none;
      `)}
    ${({ theme: { space } }) => `
    margin-bottom: ${space[2]};
  `}
  }
`;

export const StyledInfoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme: { space } }) => `
    padding-left: ${space[4]};

    ${mqMediumDown(`
    padding-left: 0;
    `)}
  `}
`;

export const StyledCard = styled.div`
  > :not(:first-child) {
    margin-top: 1.6rem;
  }
  ${mqMediumDown(`
  > :not(:first-child):not(:last-child) {
    margin-top: 3.6rem;
  }
      `)}
`;

export const Heading4NoMargin = styled.h4`
  ${mqMediumUp(`
    margin: 0;
      `)}
`;

export const PTextCenter = styled.p`
  ${({ theme: { space } }) => `
    text-align: center;
    padding-bottom: ${space[2]};
  `}
`;

export const GreyCard = styled.div`
  ${({ theme: { space } }) => `
  background: #f3f3f3;
  border-radius: ${space[1]};
  padding: ${space[10]};
  text-align: center;
  margin-bottom: 0;
  ${mqMediumDown(`
    padding: ${space[6]};
    `)}
    `}
`;
