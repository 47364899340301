import React, { ReactNode } from 'react';

import {
  BannerContent,
  BannerWrapper,
  CloseButton,
  Icon,
} from './Banner.styled';
// Bring in all banner copy
// Used via <ContactUsLink> not directly
// TODO: banner copy to common?
import { ReactComponent as CloseIcon } from '@/src/resources/offer-cross.svg';

export interface BannerProps {
  icon: Icon;
  children: ReactNode;
  className?: string;
  'data-testid'?: string;
  containerStyle?: React.CSSProperties;
  dismiss?: () => void;
  isCornerIcon?: boolean;
}

export const Banner = ({
  children,
  className,
  containerStyle,
  dismiss,
  ...rest
}: BannerProps) => (
  <BannerWrapper className={className} style={containerStyle}>
    <BannerContent {...rest}>{children}</BannerContent>
    {dismiss && (
      <div>
        <CloseButton
          data-testid="action-centre:dismiss"
          onClick={() => {
            dismiss();
          }}
          aria-label="Dismiss"
        >
          <CloseIcon />
        </CloseButton>
      </div>
    )}
  </BannerWrapper>
);

export type { ActionCentreBanner } from './banners';
