import { Strong } from '@ovotech/nebula';
import React from 'react';
import {
  StyledBarText,
  StyledUsageBreakdownBar,
  StyledUsageBreakdownBarFigure,
  StyledUsageBreakdownBarWrapper,
} from './UsageBarAndFigure.styled';

type Props = {
  totalUsage: number;
  barWidth: string;
  barColour: string;
  barText: string;
  testId: string;
};

const UsageBarAndFigure = ({
  totalUsage,
  barWidth,
  barColour,
  barText,
  testId,
}: Props) => (
  <StyledUsageBreakdownBarWrapper data-testid={testId}>
    <StyledUsageBreakdownBarFigure>
      <Strong>{totalUsage} kWh</Strong>
    </StyledUsageBreakdownBarFigure>
    <StyledUsageBreakdownBar top="0" width={barWidth} colour={barColour}>
      <StyledBarText>{barText}</StyledBarText>
    </StyledUsageBreakdownBar>
  </StyledUsageBreakdownBarWrapper>
);

export default UsageBarAndFigure;
