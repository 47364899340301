import { useMutation, useQueryClient } from 'react-query';
import { graphql } from './__generated__';
import { apiService } from '@/src/services/api-service';
import { KAPI_URL } from '@/src/constants/endpoints';
import { UpdateSupplyPointConsentInput } from '@monovo/kapi';
import { useAccountContext } from '@ovotech/ui-tools';
import { print } from 'graphql';

export const MHHS_CONSENT_MUTATION = graphql(/* kapi-codegen-graphql */ `
  mutation UpdateSupplyPointConsent($input: UpdateSupplyPointConsentInput!) {
    updateSupplyPointConsent(input: $input) {
      sprn
    }
  }
`);

export const useUpdateSupplyPointConsent = (type: 'billing' | 'settlement') => {
  const { accountId } = useAccountContext();
  const queryClient = useQueryClient();

  return useMutation(
    (variables: { input: UpdateSupplyPointConsentInput }) => {
      return apiService.graphql({
        responseType: 'json',
        url: KAPI_URL!,
        body: {
          query: print(MHHS_CONSENT_MUTATION),
          variables,
        },
      });
    },
    {
      mutationKey: `update-supply-point-consent-${type}`,
      onSuccess: () => {
        queryClient.invalidateQueries(`consent-${accountId}`);
      },
      onError: error => {
        console.error('Error updating supply point consent:', error);
      },
    },
  );
};
