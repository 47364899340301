import React, { PropsWithChildren } from 'react';

import { ErrorSection } from '@/src/components';
import { ErrorSectionId } from '@/src/types/ErrorSectionIds';

type ErrorProps = PropsWithChildren<{
  errorId: ErrorSectionId;
}>;

class ErrorBoundary extends React.Component<ErrorProps> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = { hasError: false };

  render() {
    if (this.state.hasError) {
      return <ErrorSection id={this.props.errorId} />;
    }
    return this.props.children;
  }
}

export { ErrorBoundary };
