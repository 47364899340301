import decode from 'jwt-decode';
import { getCustomerAccessToken } from './getCustomerAccessToken';

// we only care about 'sub' which is the customer id
export type DecodedToken = {
  sub: string;
  exp: number;
};

export async function getCustomerId() {
  try {
    const token = await getCustomerAccessToken();

    if (token && token.accessToken) {
      const decoded: DecodedToken = decode(token.accessToken.value);
      return decoded.sub;
    }

    throw new Error('Token is undefined');
  } catch (e) {
    throw new Error('Failed to fetch token');
  }
}
