import {
  CTAButton,
  CTALink,
  Heading3,
  Li,
  Margin,
  Ul,
  VisuallyHidden,
} from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsRender } from '@ovotech/ui-tools';
import React from 'react';
import { RenewalBannerWarning } from './RenewalBannerWarning';
import { RenewalMessage } from './RenewalMessage';
import { SecondaryButton, StyledLink } from './styled';
import { Banner } from '@/src/components/ActionCentre/Banners/Banner';
import { ACTION_CENTRE } from '@/src/constants/analytics-subjects';
import { ROUTE_RENEWAL } from '@/src/constants/routes';
import { EnergyContract } from '@/src/types/Account';

function FixedContractRenewal({
  id,
  utmSource,
  firstName,
  earliestContract,
  dismiss,
  onClick,
}: {
  id: string;
  utmSource: string;
  firstName: string;
  earliestContract: EnergyContract;
  dismiss?: (daysToExpiration?: number) => void;
  onClick: () => void;
}) {
  return (
    <AnalyticsRender name={ACTION_CENTRE} properties={{ id }}>
      <Banner icon="renewal" data-testid="renewal-notification-fixed">
        <Heading3 as="h2">
          It's time to choose a new fixed plan
          {firstName ? `, ${firstName}` : ''}
        </Heading3>
        <RenewalMessage expiryDate={earliestContract.expiryDate!} />
        <VisuallyHidden>
          <h3>Renewal benefits</h3>
        </VisuallyHidden>
        <Ul>
          <Li>
            4-5% Interest Reward on your credit balance (if you pay by
            DirectDebit)
          </Li>
        </Ul>
        <RenewalBannerWarning />
        <Margin top={4}>
          <AnalyticsClick name={ACTION_CENTRE} properties={{ id }}>
            <CTALink
              as={StyledLink}
              data-testid="renew-button"
              to={`${ROUTE_RENEWAL}?utm_source=${utmSource}`}
              variant="missionPrimary"
              onClick={onClick}
            >
              Pick a fixed plan now
            </CTALink>
          </AnalyticsClick>
          <CTAButton
            as={SecondaryButton}
            variant="missionSecondary"
            onClick={() => {
              dismiss?.(14);
            }}
          >
            Remind me later
          </CTAButton>
        </Margin>
      </Banner>
    </AnalyticsRender>
  );
}

export default FixedContractRenewal;
