import styled from 'styled-components';

export const StyledBottomButtonBar = styled.div`
  ${({ theme }) => `
  margin-top: auto;
  width: 100%;
  padding: ${theme.space[4]};
  display: flex;
  border-top: 1px solid #e5e5e5;
  
`}
`;

export const StyledIconHeaderContainer = styled.div`
  ${({ theme }) => `
    padding: ${theme.space[4]} ${theme.space[2]} 0 ${theme.space[2]};
  `}
`;

export const StyledTextContainer = styled.div`
  ${({ theme }) => `
    padding: 0 ${theme.space[2]};
    padding-bottom: ${theme.space[4]};
  `}
`;
