import React from 'react';
import { ActionCentreBanner } from '../Banner';
import { ClippedImg } from './PromoteOnTrustpilot.style';
import { RATE_US_ON_TRUSTPILOT_LINK } from '@/src/constants/links';
import { ClippedImageBanner } from '@/src/components/ActionCentre/Banners/ClippedImageBanner/ClippedImageBanner';

export const PromoteOnTrustpilot: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => (
  <ClippedImageBanner
    analyticsName=""
    heading="Like being with OVO?"
    body="Spread the word by sharing your experience."
    linkTo={RATE_US_ON_TRUSTPILOT_LINK}
    target="_blank"
    onClick={onClick}
    onDismiss={dismiss}
    ctaTitle="Review us on Trustpilot"
    cancelTitle="Not now"
    CroppedImage={ClippedImg}
  />
);
