import { Heading1, Heading2, Margin, P, Small } from '@ovotech/nebula';
import { AnalyticsRender } from '@ovotech/ui-tools';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { DisplayHeading, Section } from './EOC1Compliance.styled';
import { DashedDivider, SolidGreyDivider } from './components/Card.styled';
import CheapestTariffCard from './components/CheapestTariffCard';
import { FixedLoyaltyPlan } from './components/FixedLoyaltyPlan';
import { PageWrapper } from './components/PageWrapper';
import CurrentPlanCard from './components/PlanCard/CurrentPlanCard';
import PlansList from './components/PlansList';
import { GoodToKnow } from './sections/GoodToKnow';
import { LegalBits } from './sections/LegalBits';
import { PotentialActions } from './sections/PotentialActions';
import { Skeleton } from './sections/Skeleton';
import { getPlanWithNearestEndDate } from './utils';
import { RENEWAL_EOC1 } from '@/src/constants/analytics-subjects';
import { AvailablePlansBreakdown } from '@/src/pages/Renewal/hooks/useAvailablePlans';
import { tariffCostsSelector } from '@/src/pages/Renewal/selectors';
import { RenewablePlansResponse } from '@/src/types/RenewablePlans';
import { ContractsResponse } from '@/src/types/Response';
import ParallaxShape, {
  ParallaxShapeProvider,
} from '@/src/components/ParallaxShape/ParallaxShape';
import { fetchTariffCostsStart } from '@/src/redux/ducks/tariffCosts/tariffCosts';
import { Action } from '@/src/types/Action';
import { TariffCostsStatus } from '@/src/types/TariffCosts';

type Props = {
  availablePlans: AvailablePlansBreakdown;
  contractsData: ContractsResponse;
  renewablePlans: RenewablePlansResponse;
  hasDirectDebit: boolean;
};

const EOC1Compliance = ({
  availablePlans,
  contractsData,
  renewablePlans,
  hasDirectDebit,
}: Props) => {
  const tariffCosts = useSelector(tariffCostsSelector);

  const fixedLoyaltyPlans = availablePlans.hero;
  const fixedPlans = availablePlans.nonHeroFixed;
  const variablePlans = availablePlans.variable;

  const dispatch: Dispatch<Action> = useDispatch();

  React.useEffect(() => {
    dispatch(fetchTariffCostsStart());
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (tariffCosts.status === TariffCostsStatus.Started) {
    return (
      <PageWrapper title="Loading - End of Contract Options">
        <Section>
          <Skeleton />
        </Section>
      </PageWrapper>
    );
  }

  const planWithNearestEndDate = getPlanWithNearestEndDate(renewablePlans);
  const canRefix = fixedPlans.length > 0 || fixedLoyaltyPlans.length > 0;

  return (
    <>
      <AnalyticsRender name={RENEWAL_EOC1}>
        <PageWrapper title="End of Contract Options">
          <Section>
            {canRefix ? (
              <>
                <Heading1
                  data-testid="renewal-eoc-page-heading"
                  as={DisplayHeading}
                >
                  What to do when your plan ends
                </Heading1>
                <PotentialActions
                  planWithNearestEndDate={planWithNearestEndDate}
                  renewablePlans={renewablePlans}
                />
              </>
            ) : (
              <Heading1
                data-testid="renewal-eoc-page-heading"
                as={DisplayHeading}
              >
                Plan Information
              </Heading1>
            )}
          </Section>

          <Section>
            <CurrentPlanCard
              renewablePlans={renewablePlans}
              contracts={contractsData}
            />
          </Section>

          {fixedLoyaltyPlans.length > 0 && (
            <Section>
              <FixedLoyaltyPlan fixedYearLoyaltyPlans={fixedLoyaltyPlans} />
            </Section>
          )}

          <Section>
            <PlansList
              fixedPlans={fixedPlans}
              variablePlans={variablePlans}
              hasDirectDebitSetUp={hasDirectDebit}
              canRefix={canRefix}
            />
          </Section>

          {tariffCosts.status === TariffCostsStatus.Resolved && (
            <Section>
              <Margin bottom={6}>
                <Heading2 as="h3">Cheapest plans from the OVO family</Heading2>
                <P>
                  If one of these plans is from a different brand in the OVO
                  family and you want to move to that plan, you'll need to
                  switch to that supplier by contacting them directly. Remember
                  that terms and conditions and eligibility criteria apply.
                </P>
              </Margin>
              <CheapestTariffCard tariffCosts={tariffCosts.data} />
            </Section>
          )}

          <Section>
            <LegalBits planWithNearestEndDate={planWithNearestEndDate} />
          </Section>
          <SolidGreyDivider />

          {canRefix ? (
            <Section>
              <GoodToKnow />
            </Section>
          ) : null}
          <DashedDivider />

          <P>
            <Small>
              OVO Energy Ltd, registered office: 1 Rivergate Temple Quay
              Bristol, BS1 6ED, company no. 06890795 registered in England and
              Wales, VAT No. 100119879
            </Small>
          </P>
        </PageWrapper>
      </AnalyticsRender>
      <ParallaxShapeProvider>
        <ParallaxShape type="greenPolygon" />
      </ParallaxShapeProvider>
    </>
  );
};

export default EOC1Compliance;
