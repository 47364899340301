import { useContext } from 'react';
import { OptimizelyContext } from '@/src/utils/optimizely';

/*
  Using hook for optimizely context allows us to mock optimizely in jest.

  jest.mock('@/src/utils/useOptimizely', () => ({
    useOptimizely: jest.fn().mockImplementation(() => ({
      setForcedVariation: jest.fn(),
      track: jest.fn(),
    })),
  }));
*/
function useOptimizely() {
  const { optimizely } = useContext(OptimizelyContext);

  return optimizely;
}

export { useOptimizely };
