export const PRODUCT_BASKET_ERROR = 'product-basket-error';
export const BILLING_ERROR = 'billing-error';
export const USAGE_ERROR = 'usage-error';
export const USAGE_DATA_ERROR = 'usage-data-error';
export const ACCOUNT_INFO_ERROR = 'account-info-error';
export const METER_READING_SUMMARY_ERROR = 'meter-reading-summary_error';
export const METER_READING_FORM_ERROR = 'meter-reading-form_error';
export const METER_READING_VALIDATION_PROMPT_ERROR =
  'meter-reading-validation-prompt_error';
export const METER_READING_HISTORY_ERROR = 'meter-reading-history_error';
export const METER_READING_CONFIRM_ERROR = 'meter-reading-confirm_error';
export const SUPPLY_POINT_ERROR = 'supply_point_error';
export const PAYMENT_ERROR = 'payment-error';
export const CHANGE_DD_AMOUNT_ERROR = 'change-dd-amount_error';
export const CHANGE_DD_DATE_ERROR = 'change-dd-date-error';
export const CHANGE_DIRECT_DEBIT_DATE_ERROR = 'change-direct-debit-date-error';
export const BANK_DETAILS_ERROR = 'bank_details_error';
export const DD_CALCULATOR_ERROR = 'dd-calculator-error';
export const RECOMMENDED_DD_ERROR = 'recommended-dd-error';
export const SETUP_DD_ERROR = 'setup-dd-error';
export const PLAN_ERROR = 'plan_error';
export const BOOTSTRAP_ERROR = 'bootstrap-error';
export const GENERIC_PAGE_ERROR = 'generic-page-error';
export const GREEN_SIGNUP_ERROR = 'green-signup-error';

export const SMART_THERMOSTATS_STATUS_ERROR = 'smart-thermostats-status-error';
export const EVS_INLIFE_STATUS_ERROR = 'evsinlife-status-error';
export const INSUFFICIENT_RENEWAL_DATA = 'insufficient-renewal-data';
export const REFUND_ERROR = 'refund-error';
export const MONTHLY_DIRECT_DEBIT_ERROR = 'monthly-direct-debit-error';
export const SELECT_ACCOUNT_ERROR = 'select-account-error';
export const MOVE_OUT_IN_PROGRESS_ERROR = 'move-out-in-progress-error';
export const FINAL_STATEMENT_READY_ERROR = 'final-statement-ready-error';

export const MAIN_PAGE_ERROR = 'main-page-error-boundary';
export const METER_READ_PAGE_ERROR = 'meter-read-page-error-boundary';
export const ACTION_CENTRE_ERROR = 'homepage-action-centre-error-boundary';
export const ACCOUNT_INFO_WIDGET_ERROR = 'homepage-account-info-error-boundary';
export const BILLING_SUMMARY_WIDGET_ERROR =
  'homepage-billing-summary-error-boundary';
export const LATEST_BILLING_WIDGET_ERROR =
  'homepage-latest-billing-error-boundary';
export const LAST_STATEMENT_WIDGET_ERROR =
  'homepage-last-statement-error-boundary';
export const USAGE_SUMMARY_WIDGET_ERROR =
  'homepage-usage-summary-error-boundary';
export const METER_READS_WIDGET_ERROR = 'homepage-meter-reads-error-boundary';
export const DD_WIDGET_ERROR = 'homepage-direct-debit-error-boundary';

export const PAYMENTS_PAGE_ERROR = 'payments-page-error-boundary';

export const SMART_THERMOSTATS_PAGE_ERROR =
  'smart-thermostats-page-error-boundary';
export const SMART_THERMOSTATS_CHECKOUT_ERROR =
  'smart-thermostats-checkout-error-boundary';
export const SMART_THERMOSTATS_SUCCESS_ERROR =
  'smart-thermostats-success-error-boundary';

export const BILLING_HISTORY_PAGE_ERROR = 'billing_history_page_error';
export const BILLING_DETAILS_PAGE_ERROR = 'billing_details_page_error';

export const PATH_TO_ZERO_PAGE_ERROR = 'path-to-zero-page-error';
