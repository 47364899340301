import { PrimaryCTALink, SecondaryCTAButton } from '@ovotech/nebula';
import React from 'react';
import { ActionCentreBanner } from '../Banner';
import {
  StyledP,
  ClippedImg,
  StyledHeading1,
  StyledCardContainer,
  StyledImageContainer,
  StyledContentContainer,
} from './D2VDownloadCard.styled';
import { CTAWrapper } from '@/src/components/ActionCentre/Banners/banners.styled';
import { OVO_CHARGE_ANYTIME_LINK } from '@/src/constants/links';
import coupleChargingEVDriveImage from '@/src/resources/couple-charging-ev-drive.jpg';

export const D2VDownloadCard: ActionCentreBanner = ({ dismiss, onClick }) => (
  <StyledCardContainer>
    <StyledImageContainer>
      <ClippedImg desktopImage={coupleChargingEVDriveImage}></ClippedImg>
    </StyledImageContainer>
    <StyledContentContainer>
      <StyledHeading1>Get UK's cheapest home EV charging</StyledHeading1>
      <StyledP>
        Add Charge Anytime to your plan for free and smart charge for 7p per
        kWh. Terms apply.
      </StyledP>
      <CTAWrapper>
        <PrimaryCTALink
          iconRight="off"
          href={OVO_CHARGE_ANYTIME_LINK}
          onClick={onClick}
        >
          Get cheaper charging
        </PrimaryCTALink>
        <SecondaryCTAButton onClick={dismiss}>Close</SecondaryCTAButton>
      </CTAWrapper>
    </StyledContentContainer>
  </StyledCardContainer>
);
