import { Card, P } from '@ovotech/nebula';
import styled from 'styled-components';
import {
  breakpoints,
  mqMediumDown,
  mqMediumUp,
  mqSmallUp,
} from '@/src/utils/mediaQuery';

const IMAGE_SIZE = '360px';
const IMAGE_OFFSET = '80px';

export const StyledContentContainer = styled.div<{ hasImage: boolean }>`
  ${({ theme: { space }, hasImage }) => `
  margin-bottom: ${space[4]};
  ${mqMediumUp(`
    width: ${
      hasImage ? `calc(100% - (${IMAGE_SIZE} - ${IMAGE_OFFSET}))` : '100%'
    };
  `)}
    `}
`;

export const StyledBody = styled(P)<{ inverted?: boolean }>`
  ${({ theme: { colors }, inverted }) => `
    color: ${inverted ? `${colors.shadeLightest}` : `${colors.roundalDark}`};
    `}
`;

export const StyledImageContainer = styled.div`
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
  position: absolute;
  margin: 0;
  top: -4px;
  right: -${IMAGE_OFFSET};
  clip-path: polygon(0% 20%, 80% 0%, 100% 80%, 20% 100%);
  ${mqMediumDown(`
    z-index: -2;
`)}
`;

export const ClippedImg = styled.div<{ url: string }>`
  ${({ url }) => `
    display: none;

    ${mqMediumUp(`
      background-image: url(${url});
      background-repeat: no-repeat;
      background-size: 100%;
      height: 100%;
      display: block
    `)}
  `}
`;

export const CTAWrapper = styled.div`
  ${({ theme: { space } }) => `
    display: flex;
    gap: ${space[4]};
    flex-direction: column;

    ${mqSmallUp(`
      gap: ${space[6]};
      flex-direction: row;
    `)}
  `}
`;

export const StyledCard = styled(Card)<{
  inverted?: boolean;
  inline?: boolean;
}>`
  position: relative;
  border-width: 0px;
  overflow: hidden;
  ${({ theme: { colors, semantic, space }, inverted, inline }) => `
    border-radius: ${inline ? space[6] : 0};
    background-color: ${
      inverted ? `${semantic.inverted.surface}` : `${colors.shadeLightest}`
    };
    margin-top: ${space[6]};
  `}

  @media ${breakpoints.smallUp} {
    margin-top: ${({ theme }) => theme.space[8]};
  }
`;
