import { ChangeDirectDebitDate } from '@ovotech/energy-cx';
import React from 'react';
import { PageWithNavigationAndSectionPadding } from '@/src/components';

const ChangeDirectDebitDatePage = () => (
  <PageWithNavigationAndSectionPadding title="Change Direct Debit date">
    <ChangeDirectDebitDate />
  </PageWithNavigationAndSectionPadding>
);

export default ChangeDirectDebitDatePage;
