import { Heading2, Margin, P, Strong, TextLink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { DATE_FORMAT } from '../shared';
import { PlanWithEndDate } from '../utils';
import { RENEWAL_TC } from '@/src/constants/analytics-subjects';
import { OVO_ENERGY_TERMS } from '@/src/constants/links';

export const LegalBits = ({
  planWithNearestEndDate,
}: {
  planWithNearestEndDate?: PlanWithEndDate;
}) => {
  return (
    <>
      <Heading2 as="h4">Legal bits</Heading2>

      <P style={{ marginBottom: 0 }}>
        <Strong>No exit fees for you</Strong>
      </P>
      {planWithNearestEndDate && (
        <>
          <P style={{ marginTop: 0 }}>
            Since your plan is coming to an end, you won’t pay any exit fees if
            you switch suppliers before{' '}
            {format(planWithNearestEndDate.endDate, DATE_FORMAT)}.
          </P>
          <P style={{ marginBottom: 0 }}>
            <Strong>
              If you switch away within 20 days after{' '}
              {format(planWithNearestEndDate.endDate, DATE_FORMAT)}
            </Strong>
          </P>
        </>
      )}
      <P style={{ marginTop: 0 }}>
        If you decide to switch to another supplier, you’ll keep the same prices
        until your switch is confirmed – up to 72 days.
      </P>
      {planWithNearestEndDate && (
        <>
          <P style={{ marginBottom: 0 }}>
            <Strong>We have new terms and conditions</Strong>
          </P>
          <P style={{ marginTop: 0 }}>
            They’ll affect how you manage and pay for your energy. So please
            read them carefully.
          </P>
          <P>
            If you agree with them, there’s nothing you need to do. They’ll
            apply to you automatically from{' '}
            {format(planWithNearestEndDate.endDate, DATE_FORMAT)}. We'd love you
            to stay with us, but if you're not happy with the new terms, you
            have the option to move to a different supplier.{' '}
            <AnalyticsClick name={RENEWAL_TC}>
              <TextLink opensInNewWindow href={`${OVO_ENERGY_TERMS}/2022`}>
                Read OVO’s new terms
              </TextLink>
            </AnalyticsClick>
            .
          </P>
        </>
      )}
      <P style={{ marginBottom: 0 }}>
        <Strong>If you change your mind</Strong>
      </P>
      <Margin bottom={2}>
        <P style={{ marginTop: 0 }}>
          If you renew your plan, you have a 14-day cooling-off period in case
          you want to cancel.
        </P>
      </Margin>
    </>
  );
};
