import { Tab, TabPanel } from '@ovotech/nebula';
import styled, { css } from 'styled-components';
import { FlexCard } from '../../../../components';
import { mqSmallUp } from '@/src/utils/mediaQuery';

const StyledFlexCard = styled(FlexCard)`
  ${({ theme: { space } }) => css`
    margin: ${space[8]} 0;
    // I don't know why but without this border the week navigator arrows jump to the top of the card
    // if the fuel tab selector is hidden (e.g. if the user only has one fuel)
    border-top: 0.1px solid transparent;
  `}
`;

const StyledTab = styled(Tab)<{ active: boolean }>`
  ${({ theme: { colors, focusRing }, active }) => `
    flex: 1 0 auto;
    ${mqSmallUp(`
      flex: 1 1 0;
    `)}
    text-align: center;
    padding: 13px 0;
    list-style: none;
    cursor: pointer;
    background: rgba(47, 176, 229, 0.15);
    box-shadow: inset -21px -21px 6px -20px rgba(0,0,0,0.34);
    color: ${colors.brand.grey.dark};
    z-index: 0;


    &:not(:first-child) {
      margin-left: 0;
    }

    ${
      active
        ? css`
            border: none; // no border on selected tab
            background-color: ${colors.brand.grey.lightest};
            position: relative;
            border-top: 3px solid ${colors.brand.primary.dark};
            box-shadow: none;
            background-color: #fff;
          `
        : css``
    } 

    &:focus {
      ${css(focusRing)};
    }
  `}
`;

const StyledTabPanel = styled(TabPanel)`
  position: relative;
  z-index: 1;
  border-radius: 3px;
  border-width: 0;
  ${({ theme: { space } }) => `
    padding: 0 ${space[6]};
  `}
`;

export { StyledTab, StyledTabPanel, StyledFlexCard };
