import styled from 'styled-components';
import { mqMediumUp } from '@/src/utils/mediaQuery';

export const StyledOvoBrandHeaderBgWrapper = styled.div`
  ${mqMediumUp(`
      height: 300px;
  `)}
  width: 100%;
  height: 225px;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 0;
  transform-origin: 0% 100%;
  transform: skewY(-5deg);
`;

export const StyledOvoBrandHeaderBg = styled.div`
  ${({ theme: { colors, zIndexes } }) => `
  background-color: ${colors.brand.ovo};
  position: absolute;
  background: @teal;
  width: 100%;
  height: 500px;
  overflow: hidden;
  z-index: ${zIndexes.ovoBeyondBackground};
`};
`;
