import { CTALink, Heading2, P } from '@ovotech/nebula';
import { AnalyticsRender, useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { ActionCentreBanner, Banner } from '../Banner';
import { HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED } from '@/src/constants/analytics-subjects';
import {
  ENERGY_CREDITS_PROMO_FIXED,
  HOMEPLAN_Q1_PROMO_OFFER,
} from '@/src/constants/feature-flags';
import { HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED_OFFER } from '@/src/constants/routes';

import { useDecision, useFeature } from '@/src/utils/optimizely';
import { isAfter, isBefore } from 'date-fns';
import {
  OHS_Q1_OFFER_START_DATE,
  OHS_Q1_OFFER_END_DATE,
} from '@/src/pages/Offers/constants';
import { HomePlan } from '@/src/components/ActionCentre/Banners/HomePlan/HomePlan';

export const HomePlanEnergyCreditsPromoFixed: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const { accountId } = useAccountContext();

  const homeplanUrl =
    process.env.NX_HOMEPLAN_URL || `https://homeplan.ovoenergy.com`;

  const redirectUrl = `${homeplanUrl}?accountId=${accountId}&${HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED_OFFER}`;

  const currentDate = new Date();
  const isWithinQ1PromoOfferPeriod =
    isAfter(currentDate, OHS_Q1_OFFER_START_DATE) &&
    isBefore(currentDate, OHS_Q1_OFFER_END_DATE);

  const [{ enabled: isHomePlanQ1Offer }] = useDecision(HOMEPLAN_Q1_PROMO_OFFER);
  const [isEnergyCreditsPromoFixed] = useFeature(ENERGY_CREDITS_PROMO_FIXED);

  if (
    isEnergyCreditsPromoFixed &&
    isWithinQ1PromoOfferPeriod &&
    isHomePlanQ1Offer
  ) {
    return (
      <AnalyticsRender name={HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED}>
        <Banner icon="boilerGreen" dismiss={dismiss}>
          <Heading2 data-testid="banner-title">
            6 months half price boiler cover
          </Heading2>
          <P data-testid="banner-body">
            Plus £100 energy credit. Terms and cancellation fees apply. Half
            price offer ends 21/03/24.
          </P>
          <CTALink href={redirectUrl} onClick={onClick}>
            See plans
          </CTALink>
        </Banner>
      </AnalyticsRender>
    );
  }

  if (isEnergyCreditsPromoFixed) {
    return (
      <AnalyticsRender name={HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED}>
        <Banner icon="boilerGreen" dismiss={dismiss}>
          <Heading2 data-testid="banner-title">
            Expert boiler cover + £100 credit
          </Heading2>
          <P data-testid="banner-body">
            Get £100 energy credit when you take one of our plans. Terms and
            cancellation fees apply.
          </P>
          <CTALink href={redirectUrl} onClick={onClick}>
            See plans
          </CTALink>
        </Banner>
      </AnalyticsRender>
    );
  }

  return <HomePlan onClick={onClick} dismiss={dismiss} />;
};
