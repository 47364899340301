import React from 'react';
import { EligibilityStatusContainer } from './styles';
import { useSelector } from 'react-redux';
import { State } from '@/src/types/State';
import { ClubcardStatus } from './ClubcardStatus';
import { SmartMeterStatus } from './SmartMeterStatus';
import { DirectDebitStatus } from './DirectDebitStatus';
import { getSmartMeterStatus } from './utils/getSmartMeterStatus';

interface EligibilityProps {
  hasDirectDebit: boolean | undefined;
  hasSmartMeter: boolean | undefined;
  hasClubcardLinked: boolean | undefined;
}

export const EligibilityStatus = ({
  hasDirectDebit,
  hasSmartMeter,
  hasClubcardLinked,
}: EligibilityProps) => {
  const smartMeterBooking = useSelector(
    (state: State) => state.smartMeterBooking,
  );

  const directDebitStatus = hasDirectDebit ? 'eligible' : 'ineligible';
  const hasSmartMeterBooking =
    !!smartMeterBooking?.data?.booking?.outstandingBooking;
  const smartMeterStatus = getSmartMeterStatus(
    !!hasSmartMeter,
    hasSmartMeterBooking,
  );
  const clubcardStatus = hasClubcardLinked ? 'eligible' : 'ineligible';

  return (
    <EligibilityStatusContainer>
      <DirectDebitStatus status={directDebitStatus} />
      <SmartMeterStatus status={smartMeterStatus} />
      <ClubcardStatus status={clubcardStatus} />
    </EligibilityStatusContainer>
  );
};
