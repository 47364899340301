import { useQuery } from '@ovotech/ui-tools';
import { createAvailablePlansEndpoint } from '@/src/constants/endpoints';
import { AvailablePlansResponse } from '@/src/types/AvailablePlans';
import { apiService } from '@/src/services/api-service';

export function useAvailablePlansQuery(accountId: string) {
  const availablePlansEndpoint = createAvailablePlansEndpoint(accountId);

  return useQuery(
    `orion-available-plans-${accountId}`,
    () =>
      apiService.get<AvailablePlansResponse>({
        url: availablePlansEndpoint,
        headers: {
          'orion-endpoint-version': '4',
        },
      }),
    {
      cacheTime: 30000,
      staleTime: 30000,
    },
  );
}
