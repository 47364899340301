import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PRODUCT_IDS } from '@/src/constants/products';
import { CatalogueBolton, SignupSource } from '@/src/types/Products';
import { ProductUpdateStatus, State } from '@/src/types/State';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import { activateProductStart } from '@/src/redux/ducks/products/products';
import {
  getGreenProducts,
  isGreenerElectricityCustomer,
  getProductFromCatalogue,
  isGreenerElectricityBundleCustomer,
} from '@/src/utils/products';
import { useAccountContext } from '@ovotech/ui-tools';
import { useProductCatalogueQuery, useProductsQuery } from '@/src/api';

const defaultActivate: ProductUpdateStatus = {
  isUpdating: false,
  error: false,
  success: false,
};

export type UseGreenerElectricitySignupResult = {
  isFetching: boolean;
  activateProduct: (product: CatalogueBolton, source?: SignupSource) => void;
  greenerElectricity: CatalogueBolton | null | undefined;
  activation: ProductUpdateStatus;
  greenProducts?: any;

  /**
   * Does the user have the Greener Electricity add-on as a standalone item
   */
  hasStandaloneGreenerElectricityAddOn: boolean;

  /**
   * Does the user have the Greener Electricity add-on via either tariff bundle
   * like 1 Year Fixed + Greener Electricity or as a standalone add-on
   */
  hasAnyGreenerElectricityAddon: boolean;
};

export function useGreenerElectricitySignup(): UseGreenerElectricitySignupResult {
  const promoCode = getQueryParamValue('promoCode');

  const dispatch = useDispatch();
  const { accountId } = useAccountContext();
  const productsQuery = useProductsQuery(accountId);
  const productCatalogueQuery = useProductCatalogueQuery(promoCode);

  const activeBoltons =
    productsQuery.data?.boltons.filter(b => b.status === 'Active') || [];

  const greenerElectricity = productCatalogueQuery.data
    ? getProductFromCatalogue(
        productCatalogueQuery.data,
        PRODUCT_IDS.greenerElectricity,
      )
    : null;

  const greenProducts = productsQuery?.data
    ? getGreenProducts(productsQuery.data)
    : [];

  const hasStandaloneGreenerElectricityAddOn =
    isGreenerElectricityCustomer(activeBoltons);
  const hasBundledGreenerElectricityAddOn =
    isGreenerElectricityBundleCustomer(activeBoltons);

  const productsFromState = useSelector((state: State) => state.products);

  const activation =
    productsFromState.activate?.[PRODUCT_IDS.greenerElectricity] ||
    defaultActivate;

  const isFetching =
    productsQuery.isFetching || productCatalogueQuery.isFetching;

  const activateProduct = useCallback(
    (product: CatalogueBolton, source?: SignupSource) => {
      if (product.promoDiscount) {
        dispatch(
          activateProductStart(
            product.productId,
            product.versionId,
            product.promoDiscount.code,
            product.displayName,
            undefined,
            source,
            product.additionalData,
          ),
        );
      } else {
        dispatch(
          activateProductStart(
            product.productId,
            product.versionId,
            undefined,
            product.displayName,
            undefined,
            source,
            product.additionalData,
          ),
        );
      }
    },
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    isFetching,
    activateProduct,
    greenerElectricity,
    activation,
    greenProducts,
    hasStandaloneGreenerElectricityAddOn,
    hasAnyGreenerElectricityAddon:
      hasStandaloneGreenerElectricityAddOn || hasBundledGreenerElectricityAddOn,
  };
}
