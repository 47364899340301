import { addDays, format } from 'date-fns';
import React from 'react';
import {
  Line,
  LineBreakContainer,
  StyledSpotlightsWeekNavigation,
  StyledWeeklySubHeading,
} from './index.styled';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { ArrowButton } from '@/src/components/Slideshow/ArrowButton';
import AnalyticsService from '@/src/services/analyticsService';

type Props = {
  fromDate: Date;
  forward?: () => void;
  backward?: () => void;
  forwardDisabled?: boolean;
  backDisabled?: boolean;
};

const WeekNavigationForSpotlightsPage = ({
  fromDate,
  forward,
  backward,
  forwardDisabled = true,
  backDisabled = true,
}: Props) => {
  const endDate = addDays(fromDate, 6);

  const _format = (date: Date) => format(date, 'ddd Do MMMM');
  const currentDateRange = (
    <LineBreakContainer>
      <Line data-testid="breakdown-from-date">{`${_format(fromDate)}`}</Line>
      <Line>&nbsp;- {`${_format(endDate)}`}</Line>
    </LineBreakContainer>
  );

  if (backDisabled) {
    AnalyticsService.dispatch(
      `${ENERGY_INSIGHTS.SPOTLIGHT_WEEK_SCROLL} (Scroll: END)`,
    );
  }

  const handleBack = () => {
    backward && backward();
    AnalyticsService.dispatch(
      `${ENERGY_INSIGHTS.SPOTLIGHT_WEEK_SCROLL} (Scroll: Back)`,
    );
  };

  return (
    <StyledSpotlightsWeekNavigation data-testid={`week-navigation`}>
      <ArrowButton
        direction="left"
        onClick={handleBack}
        disabled={backDisabled}
        dataTestId="back-week-arrow"
        ariaLabel="Back one week"
      />
      <StyledWeeklySubHeading>{currentDateRange}</StyledWeeklySubHeading>
      <ArrowButton
        direction="right"
        onClick={forward}
        disabled={forwardDisabled}
        dataTestId="forward-week-arrow"
        ariaLabel="Forward one week"
      />
    </StyledSpotlightsWeekNavigation>
  );
};

export default WeekNavigationForSpotlightsPage;
