import { Card, Heading1, P } from '@ovotech/nebula';
import React from 'react';
import { PAGE_CONFIRMATION } from '../../analytics';
import { CostCard } from '../../components/CostCard';
import { MoveInPage } from '../../components/MoveInPage';
import { MoveInJourneyProps } from '../../types/moveIn';
import {
  Cart,
  CostGrid,
  HeaderGrid,
  BodyGrid,
} from './QuoteConfirmation.styled';
import { PlanCard } from './components/PlanCard';

export function QuoteConfirmation({
  continueMoveIn,
  moveInDetails,
  progress,
}: MoveInJourneyProps) {
  if (!moveInDetails.selectedTariff) {
    return <></>;
  }

  const tariff = moveInDetails.selectedTariff;

  return (
    <MoveInPage
      analyticsName={PAGE_CONFIRMATION}
      title="Your plan"
      flowProgress={progress}
    >
      <Cart>
        <HeaderGrid>
          <Card>
            <Heading1>Plan Page</Heading1>
          </Card>
        </HeaderGrid>

        <BodyGrid>
          <PlanCard tariff={tariff} />
        </BodyGrid>

        <CostGrid>
          <CostCard tariff={tariff!} continueMoveIn={continueMoveIn} />
        </CostGrid>
      </Cart>
      <P>All prices include VAT (currently 5% for domestic customers).</P>
    </MoveInPage>
  );
}
