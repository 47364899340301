import { P, Small, Strong } from '@ovotech/nebula';
import {
  ClockingTimeLabel,
  AvailablePlanTariff,
  UnitRateWithVAT,
} from '@monovo/energy-renewals';
import React from 'react';

import { Divider, CardCol, CardRow } from '../../Card.styled';
import { RenewablePlanTariffWithVAT } from '@/src/types/RenewablePlans';

export function TariffInfo({
  tariffs,
}: {
  tariffs: (RenewablePlanTariffWithVAT | AvailablePlanTariff)[];
}): JSX.Element {
  const clockingTimeLabels = [
    ...new Set<ClockingTimeLabel>([
      'anytime' as ClockingTimeLabel,
      ...tariffs.flatMap(t => t.unitRates.map(u => u.clockingTimeLabel)),
    ]),
  ];

  return (
    <>
      {clockingTimeLabels.map(clockingTimeLabel => {
        const rowUnitRates = tariffs.map(t =>
          t.unitRates.find(u => u.clockingTimeLabel === clockingTimeLabel),
        );

        return rowUnitRates.some(Boolean) ? (
          <React.Fragment key={clockingTimeLabel}>
            <CardRow>
              <CardCol>
                <P>
                  <Small>
                    {getLabelForUnitRate(rowUnitRates.filter(Boolean)[0]!)}:
                  </Small>
                </P>
              </CardCol>
              {rowUnitRates.map(unitRate => (
                <CardCol
                  key={
                    clockingTimeLabel + unitRate?.unitRateInPence.amountWithVAT
                  }
                >
                  <P>
                    <Small>
                      <Strong>
                        {unitRate
                          ? unitRate?.unitRateInPence.amountWithVAT + 'p/kWh'
                          : '-'}
                      </Strong>
                    </Small>
                  </P>
                </CardCol>
              ))}
            </CardRow>
            <Divider secondary />
          </React.Fragment>
        ) : null;
      })}

      <CardRow>
        <CardCol>
          <P>
            <Small>Standing charge:</Small>
          </P>
        </CardCol>
        {tariffs.map(t => (
          <CardCol key={'standingCharge' + t.contractId}>
            <P>
              <Small>
                <Strong>{t.standingRate.amountWithVAT}p/day</Strong>
              </Small>
            </P>
          </CardCol>
        ))}
      </CardRow>
    </>
  );
}

function getLabelForUnitRate(unitRate: UnitRateWithVAT): string {
  switch (unitRate.timingCategory) {
    case 'peak':
      return 'Peak unit rate';
    case 'offpeak':
      return 'Off-peak unit rate';
    case 'heating':
      return 'Heating unit rate';
    case 'evening_and_weekend':
      return 'Evening and weekend unit rate';
    case 'anytime':
      return 'Unit rate';
    default:
      return 'Unit rate';
  }
}
