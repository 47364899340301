import styled from 'styled-components';
import { mqMediumUp } from '@/src/utils/mediaQuery';

export const PlanCardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mqMediumUp(`
    flex-direction: row;
  `)}
`;
