import { Card } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqMediumUp } from '@/src/utils/mediaQuery';

export const MobileContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  img {
    border-radius: 4px;
    width: 100%;
  }
`;

export const DesktopContainer = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  ${mqMediumUp(`
    width: 66%;
  `)}
`;

export const RightContent = styled.div`
  display: none;
  margin: 0;
  padding: 0;
  position: relative;
  img {
    position: absolute;
    top: -33px;
    right: -41px;
  }
  svg {
    position: absolute;
    z-index: 99;
    top: 64px;
    right: -24px;
  }
  ${mqMediumUp(`
    width: 33%;
    display: inline-block;
  `)}
`;

export const VolvoMobileContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  img {
    border-radius: 4px;
    width: 260px;
  }
`;

export const VolvoLeftContent = styled.div`
  display: none;
  margin: 0;
  padding: 0;
  position: relative;
  img {
    width: 150px;
  }
  ${mqMediumUp(`
    img {
      width: 240px;
    }
    width: 33%;
    display: inline-block;
  `)}
`;

export const VolvoRightContent = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  ${mqMediumUp(`
    width: 66%;
  `)}
`;
