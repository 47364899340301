import {
  CTAButton,
  Card,
  ErrorNotification,
  Heading4,
  Margin,
  P,
  Strong,
} from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { useAccountContext, useAnalytics } from '@ovotech/ui-tools';
import { useTescoRewardsCreateApplicationMutation } from '@/src/api/use-tesco-rewards-create-application-mutation';
import { TESCO_EVENTS } from '@/src/constants/analytics';

const StyledOfferActivatedBadge = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.backgroundGlobal};
    color: ${theme.colors.roundalDark};
    `}
  width: fit-content;
  padding: 10px;
  border-radius: 30px;
`;

const CTAWrapper = styled.div`
  width: fit-content;
`;

interface RewardCardProps {
  hasDirectDebit: boolean | undefined;
  hasSmartMeter: boolean | undefined;
  hasClubcardLinked: boolean | undefined;
  hasActivatedOffer: boolean | undefined;
  isApplicationComplete: boolean | undefined;
}

const RewardMessage = () => (
  <P data-testid="tesco-reward-message">
    Tesco Clubcard will credit your 2,500 points within 60 days of activating
    the offer. Eligibility applies.
  </P>
);

export const RewardCard = ({
  hasDirectDebit,
  hasSmartMeter,
  hasClubcardLinked,
  hasActivatedOffer,
  isApplicationComplete,
}: RewardCardProps) => {
  const isEligible = hasDirectDebit && hasSmartMeter && hasClubcardLinked;

  const { accountId } = useAccountContext();
  const { dispatch } = useAnalytics();
  const {
    mutate,
    isLoading: createApplicationIsLoading,
    isError: mutationError,
  } = useTescoRewardsCreateApplicationMutation(accountId);

  const onClickHandler = () => {
    dispatch({
      type: 'click',
      name: TESCO_EVENTS.CLICKED_TESCO_ACTIVATE_OFFER,
    });
    mutate(accountId);
  };

  const loadContent = () => {
    if (!isEligible)
      return <P>Meet eligibility to collect 2,500 Tesco Clubcard points.</P>;

    if (!hasActivatedOffer) {
      return (
        <>
          <RewardMessage />
          <CTAWrapper>
            <CTAButton
              variant="secondary"
              onClick={onClickHandler}
              disabled={createApplicationIsLoading}
            >
              {createApplicationIsLoading ? 'Loading...' : 'Activate offer'}
            </CTAButton>
          </CTAWrapper>
          {mutationError && (
            <Margin top={5}>
              <ErrorNotification id="tesco-reward-activate-offer-error">
                Sorry, there was an error activating your offer.
              </ErrorNotification>
            </Margin>
          )}
        </>
      );
    }

    if (isApplicationComplete) {
      return (
        <P>Tesco Clubcard has credited your 2,500 points to your account.</P>
      );
    }

    return (
      <>
        <RewardMessage />
        <StyledOfferActivatedBadge>
          <Strong>Offer activated</Strong>
        </StyledOfferActivatedBadge>
      </>
    );
  };

  return (
    <Card>
      <Heading4>Reward</Heading4>
      {loadContent()}
    </Card>
  );
};
