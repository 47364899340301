import { differenceInYears, differenceInDays } from 'date-fns';

function toDoubleDigit(value: number): string {
  return value < 10 ? `0${value}` : `${value}`;
}

function formDateToISO(day: number, month: number, year: number) {
  return year > 999 && month < 13
    ? `${year}-${toDoubleDigit(month)}-${toDoubleDigit(day)}T00:00:00.000Z`
    : `NaN`;
}

export function formDateToDate(day: number, month: number, year: number) {
  return new Date(formDateToISO(day, month, year));
}

export function toShortISODate(date: Date) {
  return date.toISOString().split('T')[0];
}

export function isValidDate(date: Date): boolean {
  return !isNaN(date.getTime());
}

export function isAfter1901(date: Date) {
  return date.getUTCFullYear() >= 1901;
}

export function isOver18(dateOfBirth: Date) {
  return Math.abs(differenceInYears(new Date(), dateOfBirth)) >= 18;
}

export function isUpTo365DaysAway(date: Date) {
  return 365 >= Math.abs(differenceInDays(new Date(), date));
}
