import { useMutation } from 'react-query';
import { apiService } from '@ovotech/energy-cx';
import { createChargeAnytimeActivationUrl } from '@/src/constants/endpoints';
import { AcquisitionBody } from '@monovo/ev-anytime';
import { AxiosError } from 'axios';

export const useChargeAnytimeAcquisitionMutation = () => {
  return useMutation<unknown, AxiosError, AcquisitionBody>({
    mutationFn: (body: AcquisitionBody) => {
      return apiService.post({
        url: createChargeAnytimeActivationUrl(),
        body,
      });
    },
  });
};
