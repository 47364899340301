import { Card, Heading1, Heading2, P } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { PAGE_SUCCESS } from '../../analytics';
import { MoveInPage } from '../../components/MoveInPage';
import { MoveInJourneyProps } from '../../types/moveIn';
import { Circle, Square, StyledFeaturesList } from './Success.styled';
import { useOptimizely } from '@/src/utils/useOptimizely';

export function Success({ moveInDetails, progress }: MoveInJourneyProps) {
  const optimizely = useOptimizely();

  useEffect(() => {
    if (optimizely) {
      optimizely.track('rum-moved-in');
    }
  }, [optimizely]);

  return (
    <MoveInPage
      analyticsName={PAGE_SUCCESS}
      title="Move In Success"
      flowProgress={progress}
    >
      <Card data-testid="success-card">
        <Heading1>
          Thank you for taking us with you, {moveInDetails.customer!.firstName}!
        </Heading1>

        <P>
          We're so excited that you've chosen to continue going green with us.
        </P>

        <Heading2>What's next?</Heading2>
        <StyledFeaturesList>
          <Square>
            We will switch the supplier of your new home to us. We’ll take care
            of everything for you and email you when it’s done.
          </Square>
          <Circle>
            We’ll also email you around that time to ask you for your first
            meter reading.
          </Circle>
        </StyledFeaturesList>
      </Card>
    </MoveInPage>
  );
}
