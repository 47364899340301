import { Heading1, Heading3, Margin, P } from '@ovotech/nebula';
import React from 'react';
import {
  GreenerEnergyHomeHeaderStyled,
  GreenerEnergyHomeWelcome,
  GreenerEnergyHomeWelcomeInner,
  GreenerEnergyHomeHeaderAndLogoWrapper,
} from './GreenerEnergyHomeHeader.styled';
import { useProfileQueryKapi } from '@/src/api/kapi/use-profile-query';

interface GreenerEnergyHomeHeaderProps {
  headingContent?: string;
}

const GreenerEnergyHomeHeader = ({
  headingContent = 'Greener Energy',
}: GreenerEnergyHomeHeaderProps) => {
  const profile = useProfileQueryKapi();

  const givenName = profile?.data?.customer_nextV1.givenName ?? '';

  return (
    <Margin top={8} bottom={8}>
      <Heading1 as={GreenerEnergyHomeHeaderStyled}>{headingContent}</Heading1>
      <GreenerEnergyHomeWelcome data-testid="home-header">
        <GreenerEnergyHomeHeaderAndLogoWrapper>
          <GreenerEnergyHomeWelcomeInner>
            <Heading3>
              Welcome to Greener Energy
              {givenName ? `, ${givenName}!` : ''}
            </Heading3>
            <P>
              Greener Energy means growing a greener grid for the UK, for now
              and the future.
            </P>
          </GreenerEnergyHomeWelcomeInner>
        </GreenerEnergyHomeHeaderAndLogoWrapper>
      </GreenerEnergyHomeWelcome>
    </Margin>
  );
};

export default GreenerEnergyHomeHeader;
