import { WarningNotification, Heading2 } from '@ovotech/nebula';
import { AnalyticsView } from '@ovotech/ui-tools';
import React from 'react';
import { DisabledAccountRow } from './SelectAccount.styled';
import { ACCOUNT_SELECTOR_NO_ACCOUNT_WARNING } from '@/src/constants/analytics-subjects';

const NoAccountWarning = ({ accountId }: { accountId: string }) => (
  <AnalyticsView name={ACCOUNT_SELECTOR_NO_ACCOUNT_WARNING}>
    <DisabledAccountRow data-testid={`disabled-account-${accountId}`}>
      <WarningNotification
        noMargin
        data-testid="select-account-no-account-warning"
        id="select-account-no-account-warning"
      >
        <Heading2>{accountId}</Heading2>
        <span>No supply points found</span>
      </WarningNotification>
    </DisabledAccountRow>
  </AnalyticsView>
);

export default NoAccountWarning;
