import { ENABLE_PARTNERS } from '@/src/constants/feature-flags';
import { NotFoundSection } from '@ovotech/energy-cx/dist/shared/core/components';
import { Card, Heading1, Margin, Row } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { PartnerCard } from './PartnerCard';
import Tesco from '@/src/resources/tesco.svg';
import { useDecision } from '@/src/utils/optimizely';
import { ROUTE_PARTNERS_TESCO } from '@/src/constants/routes';
import { useAnalytics } from '@ovotech/ui-tools';
import { PARTNERS_EVENTS } from '@/src/constants/analytics';

export const OurPartners = () => {
  const [enablePartnersDecision] = useDecision(ENABLE_PARTNERS);

  const { dispatch } = useAnalytics();

  const showOurPartners = enablePartnersDecision?.enabled;
  const showTescoPartnership =
    showOurPartners && enablePartnersDecision?.variables?.tesco;

  useEffect(() => {
    if (showOurPartners) {
      dispatch({
        name: PARTNERS_EVENTS.OUR_PARTNERS,
        type: 'view',
      });
    }
    // `dispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showOurPartners ? (
    <Card>
      <Heading1>Our partners</Heading1>
      <Margin top={10}>
        <Row style={{ padding: 0 }}>
          {showTescoPartnership && (
            <PartnerCard
              image={Tesco}
              imageAlt="tesco"
              testId="tesco-card"
              partnerURL={ROUTE_PARTNERS_TESCO}
              mixpanelEventName={PARTNERS_EVENTS.CLICKED_TESCO_CLUBCARD_ITEM}
            />
          )}
        </Row>
      </Margin>
    </Card>
  ) : (
    <NotFoundSection />
  );
};
