import { AjaxRequest, Observable } from 'rxjs';
import 'rxjs/add/observable/dom/ajax';
import { from } from 'rxjs/observable/from';
import { catchError, mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { getCustomerAccessToken } from '@/src/api/utils/getCustomerAccessToken';
import { AjaxResponse } from '@/src/types/Ajax';

type AjaxOptionsPassthrough = Pick<AjaxRequest, 'withCredentials'>;
type Options = AjaxOptionsPassthrough & {
  url: string;
  responseType: 'json' | 'text';
  headers?: { [key: string]: any };
  body?: { [key: string]: any } | string;
  timeout?: number;
};

function getTokenObservable(): Observable<string> {
  return from(
    (async () => {
      const token = await getCustomerAccessToken();
      return token.accessToken.value;
    })(),
  );
}

const request = (method: string, options: Options): Observable<AjaxResponse> =>
  getTokenObservable().pipe(
    mergeMap(accessToken =>
      Observable.ajax({
        ...options,
        withCredentials: false,
        headers: {
          ...(options.body != null
            ? { 'Content-Type': 'application/json' }
            : {}),
          ...(options.headers || {}),
          Authorization: `Bearer ${accessToken}`,
          'X-Trace-Token': uuidv4(),
        },
        method,
        responseType: options.responseType,
      }).pipe(
        catchError((err: any) => {
          if (err.status && err.status === 401) {
            // this handles checking token is still valid and will logout if not
            return getTokenObservable();
          }
          return Observable.throw(err);
        }),
      ),
    ),
  );

const get = (options: Options) => request('GET', options);

const post = (options: Options) => request('POST', options);

const put = (options: Options) => request('PUT', options);

const del = (options: Options) => request('DELETE', options);

type GraphQLOptions = {
  url: string;
  body: {
    operationName?: string;
    query: string;
    variables?: { [key: string]: any };
  };
};

const graphql = (options: GraphQLOptions) =>
  post({ ...options, responseType: 'json' });

/**
 * We are migrating away from Redux for data fetching, use React Query instead.
 * @deprecated use {@link file://./api-service.ts} instead.
 */
export default {
  delete: del,
  get,
  graphql,
  post,
  put,
  request,
};
