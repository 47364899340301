export const OVO_ENERGY_TERMS = 'https://www.ovoenergy.com/terms';
export const OVO_FOUNDATION_LINK =
  'https://www.ovofoundation.org.uk/our-projects/';
export const HANDRAISER_LINK = 'https://hello.ovoenergy.com/handraiser-thanks';
export const RENEWAL_LINK =
  'https://account.ovoenergy.com/renewal?utm_source=menu&utm_medium=banner&utm_source=nba&utm_campaign=ovo_handraiser_135-4_nba';
export const OVO_CHARGE_ANYTIME_LINK =
  'https://www.ovoenergy.com/electric-cars/charge-anytime?utm_medium=banner&utm_source=nba&utm_campaign=133_charge_anytime_nba_web';
export const KALUZA_PRIVACY_POLICY_LINK =
  'https://app.kaluza.com/privacy-policy';
export const KALUZA_PRIVACY_POLICY_WEB_LINK =
  'https://www.kaluza.com/privacy-policy';
export const OVO_PRIVACY_POLICY_LINK =
  'https://www.ovoenergy.com/privacy-policy';
export const CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK = `${OVO_ENERGY_TERMS}/charge-anytime-terms`;
export const VOLVO_FREE_MILES_OFFER_LINK = `${OVO_ENERGY_TERMS}/volvo-free-miles`;
export const VWG_FREE_MILES_OFFER_LINK = `${OVO_ENERGY_TERMS}/vwg-charge-anytime`;
export const EV_ANYTIME_APP_STORE_URL =
  'https://apps.apple.com/gb/app/charge-anytime/id1660027420';
export const EV_ANYTIME_GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.kaluza.flex.anytime&gl=GB';
export const EV_ANYTIME_SMART_CHARGER_LINK =
  'https://www.ovoenergy.com/electric-cars/smart-charger';
export const SOLAR_PANELS_SIGNUP_LINK =
  'https://www.ovoenergy.com/solar-panels/signup?utm_medium=banner&utm_source=nba&utm_campaign=322_solar_soft_launch_nba_web';
export const DD_REDUCTION_LINK = 'https://www.ovoenergy.com/pricecap';
export const ENERGY_EXPERT_VISITS_LINK =
  'https://www.ovoenergy.com/energy-experts?utm_source=web&utm_medium=action_center&utm_campaign=introductory_offer';
export const DEBT_ASSISTANCE_URL = 'https://www.ovoenergy.com/payment-support';
export const DD_REDUCTION_TERMS_AND_CONDITION_URL = `${OVO_ENERGY_TERMS}/direct-debit-reduction-terms`;
export const SMART_METER_HELP_GUIDE_LINK =
  'https://www.ovoenergy.com/help/article/smart-meter-troubleshooting';
export const HELP_CONTACT_US_LINK = 'https://help.ovoenergy.com#contact-us';
export const FACEBOOK_OVO_ENERGY_LINK = 'https://www.facebook.com/ovoenergy';
export const TWITTER_OVO_ENERGY_LINK = 'https://twitter.com/ovoenergy';
export const INSTAGRAM_OVO_ENERGY_LINK = 'https://www.instagram.com/ovoenergy/';
export const LINKEDIN_OVO_ENERGY_LINK =
  'https://www.linkedin.com/company/ovo-energy';
export const HELP_OVO_ENERGY_EMERGENCY =
  'https://help.ovoenergy.com/article/cb446da1-ce67-45bb-9c67-71e5731596f9-orion';
export const HELP_OVO_ENERGY_PAY_AS_YOU_GO =
  'https://help.ovoenergy.com/payasyougo';
export const OVO_ENERGY_DEBT_AND_ENERGY_ASSISTANCE =
  'https://www.ovoenergy.com/help/debt-and-energy-assistance';
export const HELP_OVO_ENERGY_INTEREST_REWARD_CALCULATION =
  'https://www.ovoenergy.com/ovo-interest-reward';
export const OVO_ENERGY_ANYTIME_CREDIT_EXPLAINED =
  'https://ovoenergy.com/electric-cars/anytime-credit-explained';

export const HELP_OVO_ENERGY_METER_READINGS =
  'https://www.ovoenergy.com/help/article/meter-readings';
export const OVO_ENERGY_PAYMENT_PORTAL =
  'https://ovoenergypayments.paypoint.com/Home/index';
export const HELP_OVO_ENERGY_TRADITIONAL_METER =
  'https://www.ovoenergy.com/help/article/topping-up-traditional-meter';

export const DD_GUARANTEE =
  'https://www.directdebit.co.uk/DirectDebitExplained/pages/directdebitguarantee.aspx';
export const HOME_RECOVER_JOURNEY_LINK =
  'https://homeplan.ovoenergy.com/home-recover';

export const RATE_US_ON_TRUSTPILOT_LINK =
  'https://uk.trustpilot.com/review/www.ovoenergy.com';

export const BOILER_COVER_REWARD_TERMS =
  'https://www.ovoenergy.com/terms/energy-boiler-cover-reward';

export const SWITCH_TO_FIXED_PLAN =
  'https://account.ovoenergy.com/renewal?utm_medium=banner&utm_source=nba&utm_campaign=138&utm_content=action-centre-app';

export const SWITCH_TO_OVO_ELECTRICITY =
  'https://products.ovoenergy.com/journey/switch/get-quote';

export const LEARN_ABOUT_BALANCE_LINK =
  'https://www.ovoenergy.com/help/article/how-your-account-balance-works';

export const MARKETING_EMAIL_REFERRER_URL =
  process.env.NX_MARKETING_EMAIL_REFERRER_URL;

export const HHS_FAQ_LINK =
  'https://www.ovoenergy.com/help/article/half-hourly-settlement';
export const TERMS_AND_CONDITIONS_LINK = 'https://www.ovoenergy.com/terms/2022';
export const PRIVACY_POLICY_LINK = 'https://www.ovoenergy.com/privacy-policy';

export const BEYOND_TERMS =
  'https://www.ovoenergy.com/terms/beyond-terms-and-conditions';
