import { Card } from '@ovotech/nebula';
import React from 'react';
import {
  StyledLoadingContainer,
  StyledLoadingRow,
} from './SkeletonCard.styled';
import { FlexChild } from '@/src/components';
import { SkeletonCTA } from '@/src/components/SkeletonLoading/SkeletonCTA';
import { SkeletonCircle } from '@/src/components/SkeletonLoading/SkeletonCircle';
import { SkeletonHeading } from '@/src/components/SkeletonLoading/SkeletonHeading';
import { SkeletonText } from '@/src/components/SkeletonLoading/SkeletonText';

export const SkeletonCard = ({ testId }: { testId?: string }) => {
  return (
    <FlexChild as={Card} data-testid={testId}>
      <SkeletonHeading />
      <SkeletonText lines={3} />
      <SkeletonCTA />
    </FlexChild>
  );
};

export const SkeletonCardWithCTA = ({ testId }: { testId?: string }) => {
  return (
    <FlexChild as={Card} data-testid={testId}>
      <SkeletonHeading />
      <StyledLoadingRow>
        <StyledLoadingContainer>
          <SkeletonText lines={3} />
        </StyledLoadingContainer>
        <SkeletonCircle />
      </StyledLoadingRow>
      <SkeletonCTA />
    </FlexChild>
  );
};
