import { BillingDetailsView, RedirectToBilling } from '@ovotech/energy-cx';
import React from 'react';
import { Route } from 'react-router-dom-v5';
import {
  SwitchWithNotFound,
  PageWithNavigationAndSectionPadding,
} from '@/src/components';
import { ROUTE_BILLING_DETAILS } from '@/src/constants/routes';
import { RouterProps } from '@/src/types/Router';

const BillingDetails = () => (
  <SwitchWithNotFound>
    <Route
      exact
      path={ROUTE_BILLING_DETAILS}
      component={() => (
        <PageWithNavigationAndSectionPadding title="Billing details">
          <BillingDetailsView period="latest" />
        </PageWithNavigationAndSectionPadding>
      )}
    />
    <Route
      path={`${ROUTE_BILLING_DETAILS}/:year(\\d{4})/:month(\\d{2})`}
      component={(props: any) => {
        const { year, month } = props.match.params;
        return <RedirectToBilling year={Number(year)} month={Number(month)} />;
      }}
    />
    <Route
      path={`${ROUTE_BILLING_DETAILS}/:index(\\d+)`}
      component={(props: RouterProps) => {
        const { index } = props.match.params;
        return (
          <PageWithNavigationAndSectionPadding title="Billing details">
            <BillingDetailsView period={Number(index)} />
          </PageWithNavigationAndSectionPadding>
        );
      }}
    />
  </SwitchWithNotFound>
);

export default BillingDetails;
