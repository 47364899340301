import { P } from '@ovotech/nebula';
import { isEmpty } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  SmartThermostatsChartDataPoint,
  State as ReduxState,
} from '@/src/types/State';
import {
  StyledToolTip,
  StyledToolTipDate,
  StyledToolTipInfo,
  StyledToolTipInfoTitle,
  StyledToolTipInfoValue,
} from './ChartCustomTooltip.styled';
import { getDateFromParams } from '../../pages/MyThermostatPage/utils';

function isAllDataMissed(oneDayData: SmartThermostatsChartDataPoint) {
  return (
    oneDayData.ind === undefined &&
    oneDayData.out === undefined &&
    oneDayData.cost === undefined
  );
}

function isCurrentDay(year: number, month: number, date: number) {
  return (
    new Date().getFullYear() === year &&
    new Date().getMonth() + 1 === month &&
    new Date().getDate() === date
  );
}

export const ChartCustomTooltip = (info: any) => {
  const { active, payload }: TooltipType = info;
  const dates = useSelector(
    (state: ReduxState) => state.smartThermostats.toolTips,
  );

  const date = getDateFromParams();
  if (active && !isEmpty(dates)) {
    const payloadData = payload[0].payload;
    const monthObj = dates[date.year][date.month];

    // When navigating to a new month, there is a short period
    // of time where we've not yet got the data for these
    // tooltips.  In this case, where monthObj is not defined,
    // just return null.
    if (!monthObj) {
      return null;
    }
    const dayObj = {
      day: monthObj.weekdays[payloadData.day],
      month: monthObj.month,
      year: String(date.year),
    };

    if (isCurrentDay(date.year, date.month, payloadData.day)) {
      return (
        <StyledToolTip className="custom-tooltip">
          <StyledToolTipDate className="date" as={P}>
            {payloadData.day} {dayObj.month.slice(0, 3)} {dayObj.year}
          </StyledToolTipDate>
          <P>Collecting today’s data</P>
        </StyledToolTip>
      );
    }

    if (isAllDataMissed(payloadData)) {
      return (
        <StyledToolTip className="custom-tooltip">
          <StyledToolTipDate className="date" as={P}>
            {payloadData.day} {dayObj.month.slice(0, 3)} {dayObj.year}
          </StyledToolTipDate>
          <P>Heating data not available</P>
        </StyledToolTip>
      );
    }

    return (
      <StyledToolTip className="custom-tooltip">
        <StyledToolTipDate className="date" as={P}>
          {payloadData.day} {dayObj.month.slice(0, 3)} {dayObj.year}
        </StyledToolTipDate>
        <StyledToolTipInfo>
          <StyledToolTipInfoTitle as={P}>Indoor</StyledToolTipInfoTitle>
          <StyledToolTipInfoValue className="value" as={P}>
            {payloadData.ind ? `${payloadData.ind.toFixed(0)}°C` : '-'}
          </StyledToolTipInfoValue>
        </StyledToolTipInfo>
        <StyledToolTipInfo>
          <StyledToolTipInfoTitle as={P}>Outside</StyledToolTipInfoTitle>
          <StyledToolTipInfoValue as={P}>
            {payloadData.out ? `${payloadData.out.toFixed(0)}°C` : '-'}
          </StyledToolTipInfoValue>
        </StyledToolTipInfo>
        <StyledToolTipInfo>
          <StyledToolTipInfoTitle as={P}>Cost</StyledToolTipInfoTitle>
          <StyledToolTipInfoValue as={P}>
            {payloadData.cost ? `£${payloadData.cost.toFixed(2)}` : '-'}
          </StyledToolTipInfoValue>
        </StyledToolTipInfo>
      </StyledToolTip>
    );
  }

  return null;
};

interface TooltipType {
  active: boolean;
  payload: PayloadType[];
  label: string;
}

interface PayloadType {
  color: string;
  dataKey: 'gas' | 'out' | 'ind';
  fill: string;
  formatter: unknown;
  name: string;
  payload: SmartThermostatsChartDataPoint;
  type: unknown;
  unit: unknown;
  value: number;
}
