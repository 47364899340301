import { LOCATION_CHANGE } from 'connected-react-router';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';

import { filter, switchMap } from 'rxjs/operators';
import { Action, FetchError, LegacyFetchError } from '@/src/types/Action';

export const setApiError = (): Action => ({
  type: 'API_ERROR',
  apiError: true,
});

export const resetApiError = (): Action => ({
  type: 'RESET_API_ERROR',
  apiError: false,
});

// @ts-ignore yes. This typeguard will only accept LegacyFetchError and FetchError
function isApiError(action: Action): action is LegacyFetchError | FetchError {
  // eslint-disable-next-line no-prototype-builtins
  return action.hasOwnProperty('errorResponse');
}

function isLegacyError(
  action: LegacyFetchError | FetchError,
): action is LegacyFetchError {
  // `error` only exists is FetchError
  // eslint-disable-next-line no-prototype-builtins
  return !action.hasOwnProperty('error');
}

/* List of old-style HTTP Action that should *not*
 * trigger the global error screen.
 * The default assumption being that FetchError (the new style)
 * are gracefully degradable, so will never trigger
 * that screen, but some old actions did not rely on the global screen
 */
const GLOBAL_ERROR_ACTION_BLACKLIST = [
  'ACTIVATE_PRODUCT_ERROR',
  'APPLY_FOR_REFUND_ERROR',
  'CANCEL_PRODUCT_ERROR',
  'SETUP_DIRECT_DEBIT_ERROR',
  'UPDATE_DIRECT_DEBIT_AMOUNT_ERROR',
  'UPDATE_DIRECT_DEBIT_DATE_ERROR',
  'UPDATE_PROFILE_ERROR',
  'GET_PRODUCTS_ERROR', // home components currently rely on this not triggering any errors
  'GET_SMART_METER_BOOKING_ERROR', // could happen on all pages with navigation
  'GET_PRODUCT_CATALOGUE_ERROR', // could happen on all pages with navigation
  'CANCEL_ANYTIME_PRODUCT_ERROR',
  'ACTIVATE_ANYTIME_PRODUCT_ERROR',
];

export const apiErrorEpic = (action$: ActionsObservable<Action>) =>
  // @ts-ignore Type narrowing is not working very well
  action$.pipe(
    // @ts-ignore was not erroring before move to monovo; this will be removed when all redux has been converted to React Query
    filter(isApiError),
    filter(isLegacyError),
    /* TODO This filter can be nuked once LegacyFetchError is removed
     * (or once only blacklist members are using LegacyFetchError)
     * This filter exists because some form HTTP actions have
     * their own error processor and some HTTP actions had been designed
     * to ignore errors entirely. All that should eventually change
     * with graceful degradation work.
     */
    filter(
      (action: Action) => !GLOBAL_ERROR_ACTION_BLACKLIST.includes(action.type),
    ),
    switchMap(() => Observable.of(setApiError())),
  );

export const resetApiErrorEpic = (action$: ActionsObservable<Action>) =>
  action$
    .ofType(LOCATION_CHANGE)
    .switchMap(() => Observable.of(resetApiError()));

export const defaultApiErrorState = false;

const apiError = (
  state: boolean = defaultApiErrorState,
  action: Action,
): boolean => {
  switch (action.type) {
    case 'API_ERROR':
    case 'RESET_API_ERROR': {
      return action.apiError;
    }
    default: {
      return state;
    }
  }
};

export default apiError;
