import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledActionButtonContainer = styled.button`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  border: 0;
  background: white;
  padding: 0;

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    ${mqSmallDown(`
      width: 18px;
      height: 18px;
      flex-grow: 1;
    `)}
  }

  :hover {
    path {
      fill: rgb(10, 153, 40);
    }

    p {
      color: rgb(10, 153, 40);
    }
  }
`;

export const StyledTextContainer = styled.div<{
  selected: boolean;
}>`
  font-size: 18px;
  p {
    color: ${(props: any) => (props.selected ? 'rgb(10, 153, 40)' : 'black')};
  }

  ${mqSmallDown(`
      font-size: 16px;
      width: 60%;
      flex-grow: 1;
      p {
        line-height: 14px;
        height: 28px;
      }
    `)}
`;
