import { OptimizelyContext } from '@optimizely/react-sdk';
import { useAccountContext, useCustomerContext } from '@ovotech/ui-tools';
import {
  useContentfulNextBestActionsQuery,
  useNextBestActionQuery,
} from '@/src/api';
import { getShowableCards } from './utils/getShowableCards';
import { useCallback, useContext, useState } from 'react';
import { getActionCodeName } from './utils/getActionCodeName';
import { getDocumentAsReactNode } from '../utils/getDocumentAsReactNode';
import { Document } from '@contentful/rich-text-types';
import { useHardcodedNbaActionCards } from './useHardcodedNbaActionCards';
import { useHardcodedServiceActionCards } from './useHardcodedServiceActionCards';
import { NbaActionCodes, NbaResponseType } from '@/src/types/NextBestAction';
import { ServiceActionCodes } from './utils/rulesetForServiceBanners';
import { useNextBestActionMutation } from '@/src/api/use-next-best-action-mutation';
import { ActionCta, createCta } from './utils/createCta';
import { useContentfulActionUrlMap } from './useContentfulActionUrlMap';

type ServiceActionCard = ActiveCard & {
  onDismiss: () => void;
  data: null;
};

type ContentfulActionCard = {
  title?: string | null;
  cta?: ActionCta | null;
  nonRoundedEdges?: boolean | null;
  canBeDismissed?: boolean | null;
  image: {
    url?: string | null;
    label?: string | null;
    width?: number | null;
  };
  body: React.ReactNode;
  inverted?: boolean | null;
};

type NbaActionCard = ActiveCard & {
  onDismiss: undefined;
  data?: ContentfulActionCard;
};

type ActiveCard = {
  actionCode?: string;
  mixpanelId?: string | null;
  fallbackComponent?: React.ComponentType<any>;
};

type ActionCard = {
  isLoading: boolean;
  reject: (actionCode: string, onDismiss?: () => void) => void;
  accept: (actionCode: string, onDismiss?: () => void) => void;
  present: (actionCode: string) => void;
  activeCard: ServiceActionCard | NbaActionCard | null;
};

export const useContentfulActionCards = (
  cardsToShowByActionCode?: Array<string | ServiceActionCodes>,
): ActionCard => {
  const { accountId } = useAccountContext();
  const { customerId } = useCustomerContext();
  const { optimizely } = useContext(OptimizelyContext);
  const isFeatureEnabledQuery = (flag: string): boolean =>
    optimizely?.isFeatureEnabled?.(flag) ?? false;
  const { mutate: nbaMutate } = useNextBestActionMutation();
  const { getActionByUrl } = useContentfulActionUrlMap();

  const [dismissed, setDismissed] = useState(() => new Set<string>());

  const { isLoading: isContentfulLoading, data: contentfulCards } =
    useContentfulNextBestActionsQuery(
      process.env.NX_CONTENTFUL_NBA_CONTAINER_ID!,
    );
  const { isLoading: isNbaLoading, data: nbaData } = useNextBestActionQuery(
    accountId,
    'web',
  );

  const hardcodedNbaActionCards = useHardcodedNbaActionCards();
  const hardcodedServiceActionCards = useHardcodedServiceActionCards();

  const cardAction = useCallback(
    (action: NbaResponseType, actionCode: string, onDismiss?: () => void) => {
      try {
        const nbaInfo = nbaData?.find(c => getActionCodeName(c) === actionCode);

        if (nbaInfo && accountId && customerId) {
          nbaMutate({
            Response_Type: action,
            Account_No: accountId,
            Action_Code: nbaInfo?.Action_Code,
            Action_Sub_Category: nbaInfo?.Action_Sub_Category,
            Action_Priority_Order: nbaInfo?.Action_Priority_Order,
            Guid: customerId,
            NBA_ID: nbaInfo?.NBA_ID,
            Channel: 'web',
          });
        }

        if (action !== 'PRESENTED') {
          setDismissed(prev => new Set(prev).add(actionCode));
          if (onDismiss) onDismiss();
        }
      } catch (e) {
        console.error(`Card Action Failed for action code: ${actionCode}`, {
          cause: e,
        });
      }
    },
    [nbaData, accountId, customerId, nbaMutate],
  );

  const reject = useCallback(
    (actionCode: string, onDismiss?: () => void) => {
      onDismiss && onDismiss();
      cardAction('REJECTED', actionCode);
    },
    [cardAction],
  );

  const accept = useCallback(
    (actionCode: string, onDismiss?: () => void) => {
      onDismiss && onDismiss();
      cardAction('ACCEPTED', actionCode);
    },
    [cardAction],
  );

  const present = useCallback(
    (actionCode: string) => cardAction('PRESENTED', actionCode),
    [cardAction],
  );

  const isLoading = isContentfulLoading || isNbaLoading;

  const showableNbaCards = getShowableCards({
    nbaData,
    contentFragment:
      contentfulCards?.buildNextBestAction?.actionCardsCollection,
    hardcodedNbaActionCards,
    isFeatureEnabledQuery,
  });

  // It is important that Service Action Codes are first in the array as these are the highest priority to show first
  const showableCards = [...hardcodedServiceActionCards, ...showableNbaCards];

  const activeCard = showableCards.find(card => {
    const actionCode = card?.actionCode as NbaActionCodes | ServiceActionCodes;
    if (!actionCode) return false;
    if (
      cardsToShowByActionCode?.length &&
      !cardsToShowByActionCode.includes(actionCode)
    )
      return false;
    return !dismissed.has(actionCode);
  });

  const defaultReturnProperties = {
    isLoading,
    reject,
    accept,
    present,
  };

  if (isLoading) {
    return {
      ...defaultReturnProperties,
      activeCard: null,
    };
  }

  // Return active card as service banner data
  if (activeCard && 'component' in activeCard) {
    return {
      ...defaultReturnProperties,
      activeCard: {
        actionCode: activeCard?.actionCode,
        fallbackComponent: activeCard?.component,
        // Service banners have an additional dismiss function that
        // manages hiding the banner via local storage
        onDismiss: activeCard?.onDismiss,
        mixpanelId: activeCard?.name,
        data: null,
      },
    };
  }

  // Return active card as NBA card data
  return {
    ...defaultReturnProperties,
    activeCard: {
      actionCode: activeCard?.actionCode,
      data: activeCard?.data
        ? {
            title: activeCard.data?.text?.header,
            cta: createCta(
              activeCard.data?.cta,
              activeCard.data?.ctaBehaviour,
              getActionByUrl,
            ),
            nonRoundedEdges: activeCard.data?.nonRoundedEdges,
            canBeDismissed: activeCard.data?.canBeDismissed,
            image: {
              url: activeCard.data?.image?.desktopImage?.url,
              label: activeCard.data?.image?.altText
                ? activeCard.data.image.altText
                : undefined,
              width: activeCard.data?.image?.desktopImage?.width,
            },
            body: getDocumentAsReactNode(
              activeCard.data?.text?.text?.json as Document,
              !!activeCard.data?.inverted,
            ),
            inverted: activeCard?.data?.inverted,
          }
        : undefined,
      mixpanelId: activeCard?.mixpanelId,
      fallbackComponent: activeCard?.fallbackComponent,
      onDismiss: undefined,
    },
  };
};
