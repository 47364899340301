import { useAccountContext } from '@ovotech/ui-tools';
import React from 'react';
import { NewNavItemBadge } from '../Badges/NewNavItemBadge';
import NavLink from './Links/NavLink';
import { SMART_THERMOSTATS_MAINTENANCE } from '@/src/constants/feature-flags';
import { ROUTE_MY_THERMOSTAT } from '@/src/constants/routes';
import { useFeature } from '@/src/utils/optimizely';
import { useLocalStorage } from '@/src/utils/useLocalStorage';
import { format } from 'date-fns';
import { useHasThermostatBolton } from '@/src/pages/SmartThermostats/hooks/useHasThermostatBolton';

interface Props {
  closeMobileMenu?: () => void;
  path: string;
}

const defaultDate = (): string => `${format(new Date(), 'YYYY/MM')}`;

const yearAndMonth = defaultDate();

export const SmartThermostatLinksWrapper = (props: Props) => {
  const { accountId } = useAccountContext();
  const [maintenanceMode] = useFeature(SMART_THERMOSTATS_MAINTENANCE);

  if (!props.path || !accountId || maintenanceMode) {
    return null;
  } else {
    return <Content {...props} />;
  }
};

function Content({ closeMobileMenu, path }: Props) {
  const { hasBolton } = useHasThermostatBolton();
  const [
    isNewMyThermostatLinkBadgeDismissed,
    updateNewMyThermostatLinkBadgeDismissed,
  ] = useLocalStorage('new_my_thermostat_link_badge_dismissed', false);

  return !hasBolton ? null : (
    <li>
      <NavLink
        badge={<NewNavItemBadge />}
        data-testid="my-thermostat-link"
        label="My thermostat"
        path={path}
        route={`${ROUTE_MY_THERMOSTAT}/${yearAndMonth}`}
        showBadge={!isNewMyThermostatLinkBadgeDismissed}
        onClick={() => {
          closeMobileMenu && closeMobileMenu();

          if (!isNewMyThermostatLinkBadgeDismissed) {
            updateNewMyThermostatLinkBadgeDismissed(true);
          }
        }}
      />
    </li>
  );
}
