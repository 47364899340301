import { useQuery } from 'react-query';
import { graphql } from './__generated__';
import { fetchContentfulData } from '@/src/services/contentful/fetchContentfulData';

const customerInformationCardQuery = graphql(/* contentful-codegen-graphql */ `
  query customerInformationCardQuery($containerId: String!, $preview: Boolean) {
    buildCustomerInformationCard(id: $containerId, preview: $preview) {
      actionCardsCollection(limit: 1) {
        items {
          text {
            header
            text {
              json
            }
          }
          mixpanelId
          cta {
            ...PackCTAFragment
          }
        }
      }
    }
  }
`);
type QueryCacheKey = `customerInformationCard-${string}`;

export const useCustomerInformationCardQuery = () =>
  useQuery(
    `customerInformationCard-${process.env.NX_CONTENTFUL_CUSTOMER_INFORMATION_CARD_CONTAINER_ID}` as QueryCacheKey,
    () =>
      fetchContentfulData(customerInformationCardQuery, {
        containerId:
          process.env.NX_CONTENTFUL_CUSTOMER_INFORMATION_CARD_CONTAINER_ID!,
      }),
    {
      retry: 3,
    },
  );
