import { useLocalStorage } from '@/src/utils/useLocalStorage';
import { addHours } from 'date-fns';

export function useHighTrafficInterceptPageViewed(): [boolean, () => void] {
  const now = Date.now();
  const NEVER_VIEWED_EXPIRY = 0;
  const TTL_HOURS = 1;

  const [expiry, setExpiry] = useLocalStorage(
    'intercept-page-viewed',
    NEVER_VIEWED_EXPIRY,
  );

  const hasBeenViewedWithinExpiryWindow = expiry > now;

  return [
    hasBeenViewedWithinExpiryWindow,
    () => {
      setExpiry(addHours(now, TTL_HOURS).getTime());
    },
  ];
}
