import React, { FunctionComponent } from 'react';
import { StyledGhost } from './Ghost.styled';

type IGhost = {
  width?: string;
  height?: number;
};

const Ghost: FunctionComponent<IGhost> = ({ width, height = 1 }) => (
  <StyledGhost
    width={width ? width : ''}
    height={height ? height * 1.5 + 'rem' : ''}
  />
);

export default Ghost;
