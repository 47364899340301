import { useDecision } from '@optimizely/react-sdk';
import { TextLink } from '@ovotech/nebula';
import { AnalyticsClick, useAccountContext } from '@ovotech/ui-tools';
import React, { FC, useContext } from 'react';
import { useContractsQuery } from '@/src/api';
import { StyledNavLink } from '@/src/components/Header/Header.styled';
import { StyledLinkContent } from '@/src/components/Navigation/Links/NavLink.styled';
import { HOMERECOVER_NAV_LINK as ANALYTICS_HOMERECOVER_NAV_LINK } from '@/src/constants/analytics-subjects';
import { HOMERECOVER_NAV_LINK } from '@/src/constants/feature-flags';
import { createHomeRecoverUrl } from '@/src/constants/endpoints';
import { NewNavItemBadge } from '@/src/components/Badges/NewNavItemBadge';
import { OptimizelyContext } from '@/src/utils/optimizely';
import { useLocalStorage } from '@/src/utils/useLocalStorage';

export const HomeRecoverLink: FC = () => {
  const { accountId } = useAccountContext();
  const contracts = useContractsQuery(accountId);
  const { optimizely } = useContext(OptimizelyContext);
  const [newBadgeDismissed, setNewBadgeDismissed] = useLocalStorage(
    'new_homeplan_link_badge_dismissed',
    false,
  );
  const [key] = useDecision(HOMERECOVER_NAV_LINK);

  const variation = key.variationKey;

  if (!optimizely || !variation || !contracts?.data || !accountId) {
    return null;
  }

  return (
    <li>
      <AnalyticsClick
        name={ANALYTICS_HOMERECOVER_NAV_LINK}
        properties={{
          variation,
        }}
      >
        <StyledNavLink
          as={TextLink}
          href={createHomeRecoverUrl('homerecover-navlink', accountId)}
          data-testid="homerecover-link"
          onClick={() => {
            optimizely.track('homerecover_navlink_click');
            setNewBadgeDismissed(true);
          }}
        >
          <StyledLinkContent>
            {!newBadgeDismissed && <NewNavItemBadge />}
            Need a home repair?
          </StyledLinkContent>
        </StyledNavLink>
      </AnalyticsClick>
    </li>
  );
};
