import React, { ReactNode } from 'react';
import {
  StyledIconLabel,
  StyledIconText,
  StyledIconWrapper,
} from './IconLabel.styled';
import { ElectricityIcon, GasIcon } from '@/src/components/icons';
import theme from '@/src/core/theme';

type Title = 'Electricity' | 'Gas';
type Props = {
  alignCentre?: boolean;
  title: Title;
  label?: ReactNode;
};

const iconLookup = {
  Electricity: (
    <ElectricityIcon color={theme.colors.fuelElec} aria-hidden="true" />
  ),
  Gas: <GasIcon color={theme.colors.fuelGas} aria-hidden="true" />,
};

// className="icon-label-desc" is used by parent component to control selected appearance
export const SelectableIconLabel = ({
  alignCentre,
  title,
  ...props
}: Props) => (
  <StyledIconLabel alignCentre={alignCentre} {...props}>
    <StyledIconWrapper>{iconLookup[title]}</StyledIconWrapper>
    <StyledIconText className="icon-label-desc">{title}</StyledIconText>
  </StyledIconLabel>
);
