import { useMutation, UseMutationResult } from 'react-query';
import { createEncryptedKantanPayloadEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { EncryptedPayloadResponse } from '@/src/types/Response';

export type BookingFlowEncryptionPayload = {
  phoneNumber?: string;
  emailAddress?: string;
  fullName?: string;
  address?: {
    line1?: string;
    line2?: string;
    postcode?: string;
    town?: string;
  };
};

type QueryCacheKey = `orion-encrypted-kantan-payload-${string}`;

export const useEncryptedKantanPayloadMutation = (
  accountId: string,
): UseMutationResult<
  EncryptedPayloadResponse,
  unknown,
  BookingFlowEncryptionPayload
> => {
  return useMutation({
    mutationFn: (body: BookingFlowEncryptionPayload) =>
      apiService.post<EncryptedPayloadResponse>({
        url: createEncryptedKantanPayloadEndpoint(accountId),
        body,
      }),
    mutationKey: `orion-encrypted-kantan-payload-${accountId}` as QueryCacheKey,
  });
};
