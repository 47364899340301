import { CTAButton, Heading3, P } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { GREENER_ELECTRICITY_SIGNUP_PURCHASE_BUTTON } from '@/src/constants/analytics-subjects';
import { CatalogueBolton, SignupSource } from '@/src/types/Products';
import { ProductUpdateStatus } from '@/src/types/State';
import { mqSmallDown } from '@/src/utils/mediaQuery';
import { extractGreenerElectricityPriceInfo } from '@/src/utils/products';
import GreenerElectricityAcceptTermsModal from '@/src/pages/GreenerElectricity/GreenerElectricitySignup/components/GreenerElectricityAcceptTermsModal';

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
  position: relative;
  justify-content: space-between;
  ${({ theme: { colors, space } }) => `
    background-color: ${colors.canvas};
    padding: ${space[4]};
  `}

  ${mqSmallDown(`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h3`
  margin: unset;
  ${mqSmallDown(`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const StyledDescription = styled.p`
  margin: unset;
`;

const CTAWrapper = styled.div`
  height: 48px;

  ${mqSmallDown(`
    width:100%;
    margin-top: 10px;
  `)}
`;

const PeriodWrapper = styled.span`
  ${({ theme: { fontSizes, fonts, fontWeights } }) => `
     ${mqSmallDown(`
      width:100%;
      margin-top: 10px;
      margin: 0;
      font-weight: ${fontWeights.normal};
      font-size: ${fontSizes[2]};
      font-family: ${fonts.body};
  `)}
  `}
`;

const StyledLockupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${mqSmallDown(`width: 100%;`)}
`;

interface Props {
  activateProduct: (product: CatalogueBolton, source?: SignupSource) => void;
  activation: ProductUpdateStatus;
  greenerElectricity: CatalogueBolton;
  setAcceptPrompt: Dispatch<SetStateAction<boolean>>;
  acceptPrompt: boolean;
}

export const SignupBanner = ({
  activateProduct,
  activation,
  greenerElectricity,
  setAcceptPrompt,
  acceptPrompt,
}: Props) => {
  const { currentPrice, initialPrice, discountedLength, hasPromo } =
    extractGreenerElectricityPriceInfo(greenerElectricity);

  const promotionString = currentPrice === 0 ? 'free' : '£' + currentPrice;

  const buttonLabel = hasPromo
    ? `Join Greener Electricity, now ${promotionString} for ${discountedLength} ${
        discountedLength! > 1 ? 'months' : 'month'
      }, then just £${initialPrice} a month`
    : `Join Greener Electricity, only ${initialPrice} a month`;

  const greenerElectricityBannerOffer =
    hasPromo && discountedLength ? (
      <>
        <StyledTitle as={Heading3}>
          Now {promotionString}{' '}
          <PeriodWrapper>
            for {discountedLength} {discountedLength > 1 ? 'months' : 'month'}
          </PeriodWrapper>
        </StyledTitle>
        <StyledDescription as={P}>
          Then just £{initialPrice} a month
        </StyledDescription>
      </>
    ) : (
      <>
        <Heading3>Upgrade Now</Heading3>
        <StyledTitle as={P}>
          <span style={{ fontWeight: 'bold', color: '#2F3749' }}>
            {' '}
            £{initialPrice} monthly subscription,{' '}
          </span>
          cancel anytime.
        </StyledTitle>
      </>
    );

  return (
    <StyledCard>
      <StyledLockupWrapper aria-hidden="true">
        <StyledContentWrapper>
          {greenerElectricityBannerOffer}
        </StyledContentWrapper>
      </StyledLockupWrapper>
      <CTAWrapper>
        <AnalyticsClick name={GREENER_ELECTRICITY_SIGNUP_PURCHASE_BUTTON}>
          <CTAButton
            variant="primary"
            disabled={activation.isUpdating}
            onClick={() => setAcceptPrompt(true)}
            aria-label={buttonLabel}
          >
            Add Greener Electricity
          </CTAButton>
        </AnalyticsClick>
      </CTAWrapper>
      {acceptPrompt && (
        <GreenerElectricityAcceptTermsModal
          setAcceptPrompt={setAcceptPrompt}
          acceptPrompt={acceptPrompt}
          activateProduct={activateProduct}
          greenerElectricity={greenerElectricity}
        />
      )}
    </StyledCard>
  );
};
