import {
  balanceTextVariations,
  formatCollectableBalanceAmount,
} from '@/src/components/BalanceSummary/utils';
import { P, Strong } from '@ovotech/nebula';
import React from 'react';

export const PaymentsCollectableBalanceSummary = ({
  collectableBalance,
}: {
  collectableBalance: string;
}) => {
  const showBalanceInfoText = Number(collectableBalance) >= 0;

  return (
    <P data-testid="collectable-balance-summary">
      {balanceTextVariations(collectableBalance).headingText}
      <Strong>
        {': '}
        {formatCollectableBalanceAmount(collectableBalance)}
      </Strong>
      {showBalanceInfoText && (
        <P>{balanceTextVariations(collectableBalance).balanceInfoText}</P>
      )}
    </P>
  );
};
