import styled from 'styled-components';

import { mqSmallUp, mqMediumUp } from '@/src/utils/mediaQuery';

export const Section = styled.section`
  ${({ theme: { space } }) => `
    padding-top: ${space[4]};
    padding-bottom: ${space[4]};

    ${mqSmallUp(`
      padding-top: ${space[6]};
      padding-bottom: ${space[6]};
    `)}
  `};
`;

export const DisplayHeading = styled.h1`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[6]};

    ${mqMediumUp(`
      font-size: ${fontSizes[7]};
    `)}
  `};
`;
