import { P, TextLink } from '@ovotech/nebula';
import styled from 'styled-components';

export const EligibilityStatusContainer = styled.div`
  max-width: 560px;
`;

export const StatusBlockContainer = styled.div`
  ${({ theme: { colors, space } }) => `
  &:not(:last-child) {
    border-bottom: 2px solid ${colors.borderMuted};
    padding-bottom: ${space[4]}
  };
  &:not(:first-child) {
    padding-top: ${space[4]}
  };
`};
`;

export const StatusMessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PlaceholderText = styled(P)`
  color: ${({ theme: { colors } }) => colors.shadeMid};
`;

export const TermsAndConditonsTextLink = styled(TextLink)`
  color: ${({ theme: { colors } }) => colors.shadeMid};
`;

export const UnstyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
