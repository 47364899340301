import React from 'react';
import styled from 'styled-components';
import { StyledBreadCrumb as BreadCrumb } from '@/src/components';

const StyledBreadcrumb = styled(BreadCrumb)`
  & span {
    text-decoration: unset;
  }
  color: white;
  text-decoration: none;
`;

interface NavigationBreadCrumbProps {
  href: string;
  label?: string;
  dataTestId?: string;
  onClick?: () => void;
}

export const NavigationBreadCrumb = ({
  href,
  dataTestId,
  onClick,
  label = 'Back',
}: NavigationBreadCrumbProps) => (
  <StyledBreadcrumb data-testid={dataTestId} href={href} onClick={onClick}>
    {label}
  </StyledBreadcrumb>
);
