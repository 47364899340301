import React from 'react';
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import { Em, Li, Ol, P, Strong, TextLink, Ul } from '@ovotech/nebula';
import { EntryHyperLinkFragmentFragment } from '@/src/api/contentful/__generated__/graphql';
import {
  EntryHyperlink,
  HyperLinkEntry,
} from '@/src/components/Contentful/EntryHyperlink';

const getRichTextOption = (
  links: EntryHyperLinkFragmentFragment | null | undefined,
): Options => {
  const hyperlinkEntryMap: Map<string, HyperLinkEntry> = new Map();

  if (links) {
    for (const entry of links.entries.hyperlink) {
      if (entry?.sys.id) {
        hyperlinkEntryMap.set(entry?.sys.id, entry);
      }
    }
  }

  return {
    renderMark: {
      [MARKS.BOLD]: text => <Strong>{text}</Strong>,
      [MARKS.ITALIC]: text => <Em>{text}</Em>,
      [MARKS.CODE]: () => null,
      [MARKS.SUBSCRIPT]: () => null,
      [MARKS.SUPERSCRIPT]: () => null,
      [MARKS.UNDERLINE]: () => null,
    },
    renderNode: {
      [BLOCKS.DOCUMENT]: (_node, children) => children,
      [BLOCKS.PARAGRAPH]: (_node, children) => <P>{children}</P>,
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [BLOCKS.EMBEDDED_ENTRY]: () => null,
      [BLOCKS.EMBEDDED_RESOURCE]: () => null,
      [BLOCKS.HEADING_1]: (_node, children) => <P>{children}</P>,
      [BLOCKS.HEADING_2]: (_node, children) => <P>{children}</P>,
      [BLOCKS.HEADING_3]: (_node, children) => <P>{children}</P>,
      [BLOCKS.HEADING_4]: (_node, children) => <P>{children}</P>,
      [BLOCKS.HEADING_5]: (_node, children) => <P>{children}</P>,
      [BLOCKS.HEADING_6]: (_node, children) => <P>{children}</P>,
      [BLOCKS.HR]: () => null,
      [BLOCKS.OL_LIST]: (_node, children) => <Ol>{children}</Ol>,
      [BLOCKS.UL_LIST]: (_node, children) => <Ul>{children}</Ul>,
      [BLOCKS.LIST_ITEM]: (_node, children) => <Li>{children}</Li>,
      [BLOCKS.QUOTE]: () => null,
      [BLOCKS.TABLE]: () => null,
      [BLOCKS.TABLE_CELL]: () => null,
      [BLOCKS.TABLE_HEADER_CELL]: () => null,
      [BLOCKS.TABLE_ROW]: () => null,

      [INLINES.ASSET_HYPERLINK]: () => null,
      [INLINES.EMBEDDED_RESOURCE]: () => null,
      [INLINES.EMBEDDED_ENTRY]: () => null,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <EntryHyperlink entryMap={hyperlinkEntryMap} node={node}>
            {children}
          </EntryHyperlink>
        );
      },
      [INLINES.RESOURCE_HYPERLINK]: () => null,
      [INLINES.HYPERLINK]: (node, children) => {
        const url = node.data.uri as string;
        return <TextLink href={url}>{children}</TextLink>;
      },
    },
  };
};

export const getContentfulDocumentAsReactNode = (
  document: Document,
  links: EntryHyperLinkFragmentFragment | null | undefined,
): React.ReactNode => {
  if (!document) return;
  const options = getRichTextOption(links);
  return documentToReactComponents(document, options);
};
