import React from 'react';
import theme from '@/src/core/theme';
import { PlaceholderText, StatusMessageContainer } from './styles';
import { Icon, Margin, SecondaryCTAButton } from '@ovotech/nebula';

import {
  Icon as IconType,
  Status,
  StatusContentProps,
  StatusMessageProps,
} from './types';

const iconMap: Record<Status, IconType> = {
  eligible: {
    iconName: 'check',
    color: theme.colors.primary,
  },
  pending: {
    iconName: 'info',
    color: theme.colors.error,
  },
  ineligible: {
    iconName: 'cross',
    color: theme.colors.error,
  },
};

export const StatusMessage = ({ title, status }: StatusMessageProps) => (
  <StatusMessageContainer>
    <Icon name={iconMap[status].iconName} color={iconMap[status].color} />
    <Margin left={3}>{title}</Margin>
  </StatusMessageContainer>
);

export const StatusContent = ({
  buttonName,
  placeholderText,
  onClick,
}: StatusContentProps) => (
  <>
    <SecondaryCTAButton onClick={onClick}>{buttonName}</SecondaryCTAButton>
    <PlaceholderText>{placeholderText}</PlaceholderText>
  </>
);
