import { CTALink, Heading2, P } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React, { useMemo } from 'react';
import { OneTimeBanner } from './OneTimeBanner';
import { useContractsQuery, useDirectDebitQuery } from '@/src/api';
import { SHOW_DD_SVT_BANNER } from '@/src/constants/feature-flags';
import { hasAnyVariableContract } from '@/src/pages/Renewal/selectors';
import { DD_REDUCTION_LINK } from '@/src/constants/links';
import { useFeature } from '@/src/utils/optimizely';

interface SVTCustomersPayingDDOneTimeBannerProps {
  containerStyle?: React.CSSProperties;
  dismiss?: () => void;
}

export const SVTCustomersPayingDDOneTimeBanner = ({
  containerStyle,
  dismiss,
}: SVTCustomersPayingDDOneTimeBannerProps) => {
  const { accountId } = useAccountContext();
  const directDebit = useDirectDebitQuery(accountId);
  const contracts = useContractsQuery(accountId);

  const hasDirectDebit = !!directDebit?.data?.amount;
  const hasSVTContract = useMemo(() => {
    return contracts?.data && hasAnyVariableContract(contracts.data);
  }, [contracts]);
  const [showSVTBanner] = useFeature(SHOW_DD_SVT_BANNER);

  const isBannerVisible = showSVTBanner && hasSVTContract && hasDirectDebit;

  if (!isBannerVisible) {
    return null;
  }

  return (
    <OneTimeBanner
      containerStyle={containerStyle}
      path="svt-customers-paying-direct-debit-one-time-banner-shown"
      icon="costCutting"
      dismiss={dismiss}
    >
      <Heading2>Energy prices are going down</Heading2>
      <P>
        If your Direct Debit payments need to change, we’ll be in touch with you
        directly.
      </P>
      <CTALink iconRight="off" href={DD_REDUCTION_LINK} opensInNewWindow>
        Find out more
      </CTALink>
    </OneTimeBanner>
  );
};
