import { AxiosError } from 'axios';

export type ChargeAnytimeErrorResponse = {
  code: string;
  message: string;
  path: [string];
}[];

export const badRequestErrors = (
  data: ChargeAnytimeErrorResponse
): Record<string, string> => {
  return data.reduce((errors, issue) => {
    const [field] = issue.path;
    return { ...errors, [field]: issue.message };
  }, {});
};

export const isServerError = (error: AxiosError): boolean =>
  (error.status ?? 500) >= 500;
