import {
  P,
  Card,
  Heading3,
  Skeleton,
  SkeletonCTA,
  PrimaryCTALink,
} from '@ovotech/nebula';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  CLICKED_OFFER_EVENT,
  NEW_OFFERS_PAGE,
  VIEWED_OFFER_EVENT,
} from '@/src/constants/analytics';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import analyticsService from '@/src/services/analyticsService';
import { useSmartMeterMarketingQuery } from '@/src/api';
import { useAccountContext } from '@ovotech/ui-tools';

const StyledCardContainer = styled(Card)`
  padding: 24px 12px;
  border-radius: 4px;
  margin: 0 0 2.4rem 0;
`;

const StyledContentContainer = styled.div`
  margin-top: 8px;
  padding: 12px 10px 16px 10px;
  background-color: #0069451a;
`;

const StyledSkeletonHeading = styled(Skeleton)`
  width: 50%;
  height: 46px;
  background-color: #d9d9d9;
`;

const StyledSkeletonContent = styled(Skeleton)`
  height: 48px;
  margin-top: 24px;
  background-color: #d9d9d9;
`;

const StyledSkeletonCTA = styled(SkeletonCTA)`
  width: 100%;
  height: 44px;
  background-color: #d9d9d9;

  &:last-child {
    margin-bottom: 16px;
  }
`;

export const GetSmartMeterCard = () => {
  const { accountId } = useAccountContext();
  const { data, isLoading } = useSmartMeterMarketingQuery(accountId);

  const isEligibleForSmartMeter =
    data?.eligibility?.isEligible && data?.booking?.isBookable === true;

  useEffect(() => {
    analyticsService.dispatch(VIEWED_OFFER_EVENT, {
      offerType: NEW_OFFERS_PAGE.BEFORE_YOU_GET_STARTED_BANNER_ID,
    });
  }, []);

  if (isLoading) {
    return (
      <StyledCardContainer data-testid="get-smart-meter-skeleton">
        <StyledSkeletonHeading />
        <StyledSkeletonContent />
        <StyledSkeletonCTA />
      </StyledCardContainer>
    );
  }

  if (!isEligibleForSmartMeter) return null;

  const handleOnClick = () => {
    analyticsService.dispatch(CLICKED_OFFER_EVENT, {
      offerType: NEW_OFFERS_PAGE.BEFORE_YOU_GET_STARTED_BANNER_ID,
    });
  };

  return (
    <StyledCardContainer>
      <Heading3>Before you get started</Heading3>
      <StyledContentContainer>
        <P>
          You need a smart meter to be eligible for the Path to Zero offers and
          upgrades. With a smart meter you can see what you’re spending on
          energy to help you save money.
        </P>
        <PrimaryCTALink
          iconRight="off"
          style={{ width: '100%' }}
          href={SMART_BOOKING_URL}
          onClick={handleOnClick}
        >
          Get a smart meter
        </PrimaryCTALink>
      </StyledContentContainer>
    </StyledCardContainer>
  );
};
