import { useQuery } from '@ovotech/ui-tools';
import { EVS_INLIFE_ENDPOINT } from '@/src/constants/endpoints';
import { EVsInlifeSavingsAndInsightDataResponse } from '@/src/types/State';

import { apiService } from '@/src/services/api-service';

export function useSavingsAndInsightDataFromKaluza(
  connection: boolean | null,
  accountId: string | null | undefined,
) {
  const savingsAndInsightEndpoint = `${EVS_INLIFE_ENDPOINT}/account/${accountId}/savingsandinsights`;

  return useQuery(
    `orion-savings-and-insights-${accountId}`,
    () =>
      apiService.get<EVsInlifeSavingsAndInsightDataResponse>({
        url: savingsAndInsightEndpoint,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!connection,
      refetchOnWindowFocus: true,
    },
  );
}
