import {
  PrimaryCTAButton,
  Margin,
  P,
  SelectField,
  TextField,
  Field,
  Checkbox,
  SecondaryCTAButton,
} from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useGetAcquisitionAddress } from '../api/useGetAcquisitionAddress';
import { AddressWithSupplyPoint } from '../types/addressRequest';
import { AddressForm, FormErrors } from '../types/form';
import { validatePostcode } from '../utils/address';
import { AddressErrorNotification } from './Error';
import { LoadingIcon } from '@/src/components/icons';
import {
  EVENT_ADDRESS_LOOKUP,
  EVENT_MANUAL_ADDRESS,
} from '@/src/pages/MoveIn/analytics';

type FilledAddressProps = Partial<AddressForm>;

type GlobalAddressProps<T extends AddressForm> = {
  errors: FormErrors<T>;
  setFormErrors: (errors: FormErrors<T>) => void;
  filledValues: FilledAddressProps;
};

type AddressProps<T extends AddressForm> = GlobalAddressProps<T> & {
  postcode: string;
  toggleManualAddress: (arg: boolean) => void;
  updatePostcode: (arg: string) => void;
  addressQuery: UseQueryResult<AddressWithSupplyPoint[]>;
};

export function Address<T extends AddressForm>(props: GlobalAddressProps<T>) {
  const [manualAddress, setManualAddress] = useState<boolean>(
    props.filledValues.manualAddress || false,
  );
  const [postcode, updatePostcode] = useState<string>(
    props.filledValues.postCode || '',
  );
  const addressesQuery = useGetAcquisitionAddress(postcode);

  useEffect(() => {
    if (addressesQuery.isError) {
      setManualAddress(true);
    }
  }, [addressesQuery.isError]);

  return (
    <>
      <Field label="What's your address?" id="address" />
      {/* Private for form */}
      <div tabIndex={-1} style={{ display: 'none' }} aria-hidden>
        <Checkbox
          label="Manual Address"
          name="manualAddress"
          id="manualAddress"
          checked={manualAddress}
        />
      </div>

      {addressesQuery.isError && <AddressErrorNotification />}

      {manualAddress ? (
        <ManualAddress
          {...props}
          postcode={postcode}
          addressQuery={addressesQuery}
          toggleManualAddress={setManualAddress}
          updatePostcode={updatePostcode}
        />
      ) : (
        <AddressLookup
          {...props}
          postcode={postcode}
          addressQuery={addressesQuery}
          toggleManualAddress={setManualAddress}
          updatePostcode={updatePostcode}
        />
      )}
    </>
  );
}

function ManualAddress<T extends AddressForm>({
  errors,
  filledValues,
  postcode,
  toggleManualAddress,
}: AddressProps<T>) {
  return (
    <>
      <TextField
        name="addressLine1"
        id="addressLine1"
        label="Address line 1"
        error={errors.addressLine1}
        defaultValue={
          (filledValues.manualAddress && filledValues.addressLine1) || ''
        }
        data-testid="line-1"
      />
      <TextField
        name="addressLine2"
        id="addressLine2"
        label="Address line 2"
        defaultValue={
          (filledValues.manualAddress && filledValues.addressLine2) || ''
        }
        data-testid="line-2"
        optional
      />
      <TextField
        name="town"
        id="town"
        label="City/Town"
        error={errors.town}
        defaultValue={(filledValues.manualAddress && filledValues.town) || ''}
        data-testid="town"
      />
      <TextField
        name="postCode"
        id="postCode"
        label="Postcode"
        error={errors.postCode}
        defaultValue={postcode}
        data-testid="postcode"
      />

      <Margin top={4}>
        <SecondaryCTAButton onClick={() => toggleManualAddress(false)}>
          Find address from postcode
        </SecondaryCTAButton>
      </Margin>
    </>
  );
}

function AddressLookup<T extends AddressForm>({
  addressQuery,
  errors,
  postcode,
  updatePostcode,
  setFormErrors,
  toggleManualAddress,
}: AddressProps<T>) {
  const addresses = addressQuery.data
    ? [{ value: '', label: '--- Select Address ---' }].concat(
        addressQuery.data.map(address => ({
          value: JSON.stringify(address),
          label: address.fullAddress,
        })),
      )
    : [{ value: '', label: 'No Address Found' }];

  const onFindClick = () => {
    const postcode = (document.getElementById('postCode') as HTMLInputElement)
      .value;

    const postcodeError = validatePostcode(postcode);

    if (postcodeError) {
      setFormErrors({
        ...errors,
        postCode: postcodeError,
      });
    } else {
      setFormErrors({
        ...errors,
        postCode: undefined,
      });
      updatePostcode(postcode!);
    }

    return false;
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <TextField
          name="postCode"
          id="postCode"
          label="Postcode"
          error={errors.postCode}
          defaultValue={postcode}
          data-testid="postcode-input"
        />
        <Margin horizontal={2} />
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <AnalyticsClick name={EVENT_ADDRESS_LOOKUP}>
            <PrimaryCTAButton type="button" onClick={onFindClick}>
              {addressQuery.isLoading ? <LoadingIcon /> : 'Find'}
            </PrimaryCTAButton>
          </AnalyticsClick>
        </div>
      </div>

      <Margin vertical={2}>
        <SelectField
          name="fullAddress"
          id="fullAddress"
          label="Address"
          options={addresses}
          error={errors.fullAddress}
        />
      </Margin>

      <P>
        If you can’t find your address then give us a call on 0330 303 5063 and
        one of our friendly advisors can help you out.
      </P>

      <AnalyticsClick name={EVENT_MANUAL_ADDRESS}>
        <SecondaryCTAButton
          onClick={() => toggleManualAddress(true)}
          data-testid="address-lookup-manual"
        >
          Is your address not listed?
        </SecondaryCTAButton>
      </AnalyticsClick>
    </>
  );
}
