import {
  Heading3,
  Margin,
  Card,
  Heading4,
  Ul,
  Li,
  Strong,
  P,
  SecondaryCTALink,
} from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom-v5';
import {
  StyledNewsPanel,
  StyledNewsText,
  StyledImageMobile,
  StyledNewsImage,
} from '../EVNews/EVNews.styled';
import { useAnytimeBoltonPricingQuery } from '@/src/api';
import { EV } from '@monovo/ev-anytime';
import {
  DRIVE_ANYTIME,
  ALLOW_ANYTIME_SIGNUP,
} from '@/src/constants/feature-flags';
import { ROUTE_CHARGE_ANYTIME_ELIGIBILITY } from '@/src/constants/routes';

import { getProductCatalogueStart } from '@/src/redux/ducks/products/products';
import { OptimizelyFeature } from '@/src/utils/optimizely';
import EVAnytimeMobile from '@/src/resources/anytime-offer-mobile.jpg';
import EVAnytime from '@/src/resources/anytime-offer.png';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

export const EVOffers = () => {
  const dispatch = useDispatch();
  const { hasChargeAnytime } = useChargeAnytimeBoltonState();
  const anytimeBoltonPricing = useAnytimeBoltonPricingQuery();

  const getContent = () => {
    if (anytimeBoltonPricing.isError)
      return 'Smart charge and save with our super-low Charge Anytime rate';
    if (anytimeBoltonPricing.isLoading)
      return (
        <>
          Enjoy a super-low EV charging rate of only <Strong>XXp/kWh</Strong>{' '}
          when you smart charge your car
        </>
      );

    if (anytimeBoltonPricing.data)
      return (
        <>
          Enjoy a super-low EV charging rate of only{' '}
          <Strong>{anytimeBoltonPricing.data.formattedPence}</Strong> when you
          smart charge your car
        </>
      );
  };

  useEffect(() => {
    dispatch(getProductCatalogueStart());
  }, [dispatch]);

  return (
    <>
      <Heading3>Great OVO offers, for your eyes only</Heading3>
      <OptimizelyFeature feature={DRIVE_ANYTIME}>
        {isEnabled => {
          return (
            isEnabled &&
            !hasChargeAnytime && (
              <Margin bottom={5}>
                <StyledNewsPanel as={Card}>
                  <StyledNewsImage alt="" src={EVAnytime} aria-hidden="true" />
                  <StyledImageMobile
                    alt=""
                    src={EVAnytimeMobile}
                    aria-hidden="true"
                  />

                  <StyledNewsText>
                    <Heading4>
                      Charge Anytime: our electrifying new add-on for EV drivers
                    </Heading4>
                    <Ul>
                      <Li>{getContent()}</Li>
                      <Li>
                        Get insights about your charging habits and tips on
                        cutting carbon
                      </Li>
                      <Li>
                        If you need to boost charge your EV, you'll be charged
                        your standard energy rate
                      </Li>
                    </Ul>

                    <P>
                      <OptimizelyFeature feature={ALLOW_ANYTIME_SIGNUP}>
                        {isEnabled =>
                          isEnabled ? (
                            <AnalyticsClick
                              name={
                                EV.ELIGIBILITY
                                  .EVS_OVO_CHARGE_ANYTIME_GO_TO_ELIGIBILITY_PAGE_BUTTON_CLICKED
                              }
                            >
                              <SecondaryCTALink
                                data-event-name={
                                  'EVS - EVS OVO Drive Anytime Navigate to Eligibility page button clicked'
                                }
                                to={ROUTE_CHARGE_ANYTIME_ELIGIBILITY}
                                as={Link}
                              >
                                Check I'm eligible
                              </SecondaryCTALink>
                            </AnalyticsClick>
                          ) : (
                            <Strong>
                              Charge Anytime will be available from February
                              2023
                            </Strong>
                          )
                        }
                      </OptimizelyFeature>
                    </P>
                  </StyledNewsText>
                </StyledNewsPanel>
              </Margin>
            )
          );
        }}
      </OptimizelyFeature>
    </>
  );
};
