import { subMonths } from 'date-fns';
import { useSelector } from 'react-redux';
import { convertMonthYearToDateObj } from '../../components/MonthlyNavigator/MonthlyNavigator';
import { formatDateForUsageQuery } from './utils';
import { useDailyUsageQuery } from '../../../../api/use-daily-usage-query';
import { DailyUsageData } from '@/src/types/Response';
import { MonthYear, State } from '@/src/types/State';

export interface GasChartDataResult {
  error?: string;
  gasDailyUsageData?: DailyUsageData[];
}

export const useGasChartData = (monthYear: MonthYear): GasChartDataResult => {
  const { selectedAccountId } = useSelector((state: State) => state.user);

  const dateSelected = convertMonthYearToDateObj(monthYear);

  // Preload data for previous month to improve navigation performance
  useDailyUsageQuery(
    selectedAccountId!,
    formatDateForUsageQuery(subMonths(dateSelected, 1)),
  );

  const selectedMonthQueryResult = useDailyUsageQuery(
    selectedAccountId!,
    formatDateForUsageQuery(dateSelected),
  );

  const { data, error } = selectedMonthQueryResult as any;

  return {
    error,
    gasDailyUsageData: data?.gas?.data,
  };
};
