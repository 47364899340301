import React from 'react';
import { URL_BEYOND } from '../../Shop/Shop';
import {
  StyledDivContainer,
  StyledHeading3,
  StyledP,
  StyledSecondaryCTALink,
} from './BeyondBanner.styled';
import {
  FootnoteReferenced,
  FootnoteReferencedContent,
} from '@/src/components';

type BeyondBannerProps = {
  heading: string;
  subHeading: string;
  ctaText: string;
  footnote?: FootnoteReferencedContent;
};

const BeyondBanner = ({
  heading,
  subHeading,
  ctaText,
  footnote,
}: BeyondBannerProps) => (
  <StyledDivContainer color="black">
    <StyledHeading3 color="white">
      {heading}
      {footnote && <FootnoteReferenced content={footnote} />}
    </StyledHeading3>
    <StyledP>{subHeading}</StyledP>
    <StyledSecondaryCTALink href={URL_BEYOND} target="_blank">
      {ctaText}
    </StyledSecondaryCTALink>
  </StyledDivContainer>
);

export default BeyondBanner;
