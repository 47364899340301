import { Heading3, Icon, Modal, P } from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView } from '@ovotech/ui-tools';
import React, { useState } from 'react';
import styled from 'styled-components';
import carbonNeutral from '@/src/resources/carbon-neutral.png';
import { Card, SkinnyModal } from '../GreenerElectricitySignup.styled';
import { GREENER_ELECTRICITY_SIGNUP_CARBON_NEUTRAL_CARD } from '@/src/constants/analytics-subjects';
import { mqLargeDown } from '@/src/utils/mediaQuery';

const ClippedContent = styled.div`
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;

  clip-path: polygon(
    calc(50% - 20px) 0,
    calc(50% + 60px) 30px,
    calc(50% - 55px) 100%,
    0 100%,
    0 0
  );

  ${({ theme: { colors, space } }) => `
    padding: ${space[4]};
    background-color: ${colors.canvas};
  `}
`;

const CarbonNeutralBackgroundImg = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url(${carbonNeutral});
  background-position: center center;
  background-size: cover;
`;

const Wrapper = styled.div`
  max-width: 50%;
`;

const LinkTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }

  ${({ theme: { colors, responsiveFontSizes } }) => `
    color: ${colors.primary};
    text-decoration: underline;

    ${mqLargeDown(`
      font-size: ${responsiveFontSizes.body[0]};
    `)}
  `};
`;

const IconWrapper = styled.div`
  min-width: 20px;
`;

export const CarbonNeutralCard = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <AnalyticsView name={GREENER_ELECTRICITY_SIGNUP_CARBON_NEUTRAL_CARD}>
      <Card>
        <ClippedContent>
          <Wrapper>
            <Heading3>100% renewable electricity</Heading3>
            <AnalyticsClick
              name={GREENER_ELECTRICITY_SIGNUP_CARBON_NEUTRAL_CARD}
            >
              <LinkTextWrapper onClick={() => setShowModal(true)}>
                What difference does this make?
                <IconWrapper>
                  <Icon name="chevron-right" />
                </IconWrapper>
              </LinkTextWrapper>
            </AnalyticsClick>
          </Wrapper>
        </ClippedContent>
        <CarbonNeutralBackgroundImg />
        <SkinnyModal
          as={Modal}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title="100% renewable electricity!"
        >
          <P>
            Greener Electricity gives you 100% renewable electricity. As well as
            supercharging support for the UK&apos;s renewable electricity
            system.
          </P>
          <P>
            It brings you 100% renewable electricity from traceable sources.
            While the electricity that powers your home comes from a mix of
            sources, we buy the same amount of renewable electricity to balance
            this all out. This is backed by PPAs (Power Purchase Agreements).
            PPAs are agreements to buy renewable electricity from the source
            (like a solar farm). This electricity can be traced back to the
            generator.
          </P>
        </SkinnyModal>
      </Card>
    </AnalyticsView>
  );
};
