import { useAccountContext } from '@ovotech/ui-tools';
import { useQuery, UseQueryResult } from 'react-query';
import { createFinalStatementEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { FinalStatementResponse } from '@/src/types/Response';

type QueryCacheKey = `orion-final-statement-${string}`;

export const useFinalStatementQuery =
  (): UseQueryResult<FinalStatementResponse> => {
    const { accountId } = useAccountContext();
    return useQuery(
      [`orion-final-statement-${accountId}` as QueryCacheKey],
      () =>
        apiService.get<FinalStatementResponse>({
          url: createFinalStatementEndpoint(accountId as string),
        }),
      {
        enabled: !!accountId,
      },
    );
  };
