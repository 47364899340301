import styled from 'styled-components';

export const StyledOfferPanel = styled.div`
  padding: 0;
  flex: 1;
  position: relative;
  overflow: hidden;
  border: none;
  > :last-child {
    margin-bottom: 0;
  }
`;
export const StyledOfferBody = styled.div`
  position: relative;
  ${({ theme: { card, mediaQueries } }) => `
    padding: ${card.padding[0]};

    ${mediaQueries.smallAndUp} {
      padding: ${card.padding[1]};
    }
  `};
`;

export const StyledOfferImage = styled.img`
  width: 100%;
  max-height: 300px;
  z-index: 1;
`;
export const StyledPromoImage = styled.img`
  position: absolute;
  width: 25%;
  right: 1%;
  z-index: 2;
`;
