import {
  Heading1,
  P,
  PrimaryCTALink,
  SecondaryCTAButton,
  Strong,
} from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { ActionCentreBanner } from '../Banner';
import { CTAWrapper } from '@/src/components/ActionCentre/Banners/banners.styled';
import { SOLAR_PANELS_SIGNUP_LINK } from '@/src/constants/links';
import solarImage from '@/src/resources/SolarImage.jpg';
import { mqMediumDown, mqMediumUp, mqSmallUp } from '@/src/utils/mediaQuery';

const StyledSolarPanelsCardLockupWrap = styled.div`
  width: 100%;
  height: 100%;

  ${mqMediumUp(`
      position: absolute;
      margin: 0;
      top: 0;
      right: -1px;
      width: 50%;
      height: 100%;
    `)}
`;

const StyledSolarPanelsCardBody = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[6]} ${space[8]} ${space[4]};
  `}

  ${mqMediumUp(`
    padding-right: 30%;
`)}
`;

const StyledSolarPanelsP = styled(P)`
  ${mqMediumUp(`
    width:60%
`)}
`;

const StyledSolarPanelsCard = styled.div`
  ${({ theme: { colors, space } }) => `
    position: relative;
    background-color: ${colors.canvas};
    box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
    margin-top: ${space[4]};

    &:first-of-type {
      margin-top: ${space[6]};

      ${mqSmallUp(`
      margin-top: ${space[8]};
    `)}
    }
  `}
`;

StyledSolarPanelsCard.displayName = 'SolarPanels';

type ImageProps = {
  desktopImage?: string;
  mobileImage?: string;
};

const ClippedImg = styled.img<ImageProps>`
  display: block;
  height: 90%;
  width: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 75%, 10% 100%);
  ${({ desktopImage, mobileImage }) => `
    content: url(${desktopImage});

    ${mqMediumDown(`
      ${mobileImage && `content: url(${mobileImage})`};
    `)}
  `}

  ${mqMediumDown(`
    height:100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  `)}
`;

ClippedImg.displayName = 'ClippedImg';

export const SolarPanelsCard: ActionCentreBanner = ({ dismiss, onClick }) => {
  return (
    <StyledSolarPanelsCard>
      <StyledSolarPanelsCardLockupWrap>
        <ClippedImg desktopImage={solarImage}></ClippedImg>
      </StyledSolarPanelsCardLockupWrap>
      <StyledSolarPanelsCardBody>
        <Heading1
          data-testid="solar-panels-title"
          style={{ width: '60%', fontSize: '2.5rem' }}
          as="h1"
        >
          Here’s a bright idea
        </Heading1>
        <StyledSolarPanelsP>
          Get solar panels with us and cut your energy bills by up to £1,400 a
          year.
        </StyledSolarPanelsP>
        <P>
          <Strong>We’ve got solar, sorted.</Strong>
        </P>
        <CTAWrapper>
          <PrimaryCTALink
            data-testid="solar-panels-action-button"
            variant="primary"
            iconRight="off"
            href={SOLAR_PANELS_SIGNUP_LINK}
            onClick={onClick}
          >
            Start saving with solar
          </PrimaryCTALink>
          <SecondaryCTAButton onClick={dismiss}>Close</SecondaryCTAButton>
        </CTAWrapper>
      </StyledSolarPanelsCardBody>
    </StyledSolarPanelsCard>
  );
};
