import { ChangeDirectDebitAmount } from '@ovotech/energy-cx';
import { ErrorNotification, P, Stack } from '@ovotech/nebula';
import React, { createRef, useEffect, useState } from 'react';
import { OneClickPaymentReductionMain } from '../components/OneClickPaymentReduction/OneClickPaymentReductionMain';
import { StruggleToPay } from './StruggleToPay';
import {
  SVTCustomersPayingDDOneTimeBanner,
  PageWithNavigationAndSectionPadding,
  ContactUsLink,
} from '@/src/components';
import { ONE_CLICK_PAYMENT_REDUCTION } from '@/src/constants/feature-flags';
import { OptimizelyFeature } from '@/src/utils/optimizely';

const ChangeDirectDebitAmountRoute = () => {
  const [errorState, setErrorState] = useState<boolean>(false);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    if (errorState && ref.current) {
      ref.current.focus();
    }
  }, [errorState, ref]);

  return (
    <PageWithNavigationAndSectionPadding title="Change direct debit amount">
      <ErrorNotification id="change-dd-amount_error" ref={ref}>
        {errorState ? (
          <P>
            You can try again or <ContactUsLink>contact us</ContactUsLink>.{' '}
            <br />
            <br />
            Only the account holder can change the Direct Debit amount. If you
            are not the account owner, you'll need to ask the person who pays
            for the bill to login and make the change.
          </P>
        ) : null}
      </ErrorNotification>
      <SVTCustomersPayingDDOneTimeBanner containerStyle={{ marginTop: 0 }} />
      <Stack spaceBetween={[4, 8]}>
        <ChangeDirectDebitAmount />
        <OptimizelyFeature feature={ONE_CLICK_PAYMENT_REDUCTION}>
          {isEnabled =>
            isEnabled ? (
              <OneClickPaymentReductionMain setErrorState={setErrorState} />
            ) : (
              <StruggleToPay />
            )
          }
        </OptimizelyFeature>
      </Stack>
    </PageWithNavigationAndSectionPadding>
  );
};

export default ChangeDirectDebitAmountRoute;
