import { useMutation } from 'react-query';
import { TESCO_REWARDS_AUTH_URL } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import {
  TescoRewardAuthRequest,
  TescoRewardAuthResponse,
} from '../types/Response';

type QueryCacheKey = `tesco-rewards-auth-${string}`;

export const useTescoRewardsAuthMutation = (
  accountId: string | null | undefined,
) => {
  return useMutation({
    mutationFn: (body: TescoRewardAuthRequest) =>
      apiService.post<TescoRewardAuthResponse>({
        url: TESCO_REWARDS_AUTH_URL,
        body,
      }),
    mutationKey: `tesco-rewards-auth-${accountId}` as QueryCacheKey,
  });
};
