import { CTAButton, P } from '@ovotech/nebula';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import {
  StyledFeedbackPrompt,
  StyledFeedbackTextWrapper,
} from './FeedbackPrompt.styled';
import { ReactComponent as BulbIcon } from '@/src/resources/bulb_icon.svg';
import { NoWrap } from '@ovotech/energy-cx/dist/shared/core/components';

export const FeedbackPrompt = () => {
  const cookieName = 'CLICKED_THERMOSTAT_INSIGHTS_FEEDBACK';
  const cookie = Cookies.get(cookieName);
  const [isInsightsFeedbackClicked, setIsInsightsFeedbackClicked] =
    useState<boolean>(Boolean(cookie));

  const onFeedbackClick = () => {
    const oneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
    Cookies.set(cookieName, 'true', {
      expires: oneHour,
    });
    setIsInsightsFeedbackClicked(true);
  };
  return (
    <StyledFeedbackPrompt>
      {!isInsightsFeedbackClicked ? (
        <>
          <StyledFeedbackTextWrapper>
            <BulbIcon />
            <P>What else would you like to know about your heating?</P>
          </StyledFeedbackTextWrapper>
          <CTAButton
            variant="secondary"
            fullWidth="small"
            onClick={onFeedbackClick}
            iconRight="chevron-right"
          >
            <NoWrap>Give feedback</NoWrap>
          </CTAButton>
        </>
      ) : null}
    </StyledFeedbackPrompt>
  );
};
