import { trackUserAction } from '../services/datadog';

let loadTimeSent = false;

// @ts-ignore
const spaLoaded = (pathname: string) => {
  try {
    if (pathname === '/' && !loadTimeSent) {
      const finish = new Date().getTime();
      // @ts-ignore
      const start = window.ddAppStart;
      trackUserAction('SPA loaded', {
        spaLoadTime: finish - start,
      });
      loadTimeSent = true;
    }
  } catch (e) {
    // @ts-ignore
  }
};

export default spaLoaded;
