import { P, Strong } from '@ovotech/nebula';
import React from 'react';

import { ClippedImageBanner } from '../ClippedImageBanner/ClippedImageBanner';
import { ActionCentreBanner } from '@/src/components/ActionCentre/Banners/Banner';
import { GREENER_ENERGY_ACTION_CENTRE_BANNER } from '@/src/constants/analytics-subjects';
import { ROUTE_GREENER_ENERGY_SIGNUP } from '@/src/constants/routes';
import naturalEnergy from '@/src/resources/greener-turbines.jpg';

export const GreenerEnergyBanner: ActionCentreBanner = ({
  onClick,
  dismiss,
}) => {
  return (
    <ClippedImageBanner
      analyticsName={GREENER_ENERGY_ACTION_CENTRE_BANNER}
      imageUrl={naturalEnergy}
      heading="Say hello to Greener Energy"
      body={
        <P>
          100% renewable electricity from traceable sources and 100%
          carbon-neutral gas. <Strong>For £10 a month.</Strong>
        </P>
      }
      linkTo={ROUTE_GREENER_ENERGY_SIGNUP}
      onClick={onClick}
      onDismiss={dismiss}
      ctaTitle="Upgrade to Greener Energy"
    />
  );
};
