import React from 'react';
import {
  StatusBlockContainer,
  PlaceholderText,
  TermsAndConditonsTextLink,
} from './styles';
import { StatusBlockProps } from './types';
import { Margin } from '@ovotech/nebula';
import { StatusMessage } from './StatusComponents';
import { ClubcardSignup } from './ClubcardSignup';
import { TESCO_TERMS } from '../../constants';

export const ClubcardStatus = ({ status }: StatusBlockProps) => (
  <StatusBlockContainer>
    <Margin bottom={2}>
      <StatusMessage title="Link Tesco Clubcard" status={status} />
    </Margin>
    {status === 'ineligible' && <ClubcardSignup />}
    <PlaceholderText>
      By linking your Tesco Clubcard, you confirm you’ve read and agree to the
      offer{' '}
      <TermsAndConditonsTextLink href={TESCO_TERMS}>
        terms and conditions
      </TermsAndConditonsTextLink>
      .
    </PlaceholderText>
  </StatusBlockContainer>
);
