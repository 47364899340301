import { isBefore } from 'date-fns';
import { EnergyContract } from '@/src/types/Account';

type GetFirstAndLastContractsInLossReturn = [
  EnergyContract | null,
  EnergyContract | null,
];

/**
 * Returns a tuple representing the first of 2 contracts to be lost if both available
 * or the available contract (if at all).
 *
 * @param electricityContract the contract for electricity, or null
 * @param gasContract the contract for gas, or null
 * @returns a tuple of [firstContract | null, lastContract | null]
 */
export const getFirstAndLastContractsInLoss = (
  electricityContract: EnergyContract | null,
  gasContract: EnergyContract | null,
): GetFirstAndLastContractsInLossReturn => {
  // We have both contracts lossDate available
  if (electricityContract?.lossDate && gasContract?.lossDate) {
    const isElectricityContractLossDateEarlier = isBefore(
      electricityContract.lossDate,
      gasContract.lossDate,
    );

    return isElectricityContractLossDateEarlier
      ? [electricityContract, gasContract]
      : [gasContract, electricityContract];
  }

  // We only have electricity lossDate
  if (electricityContract?.lossDate) {
    return [electricityContract, null];
  }

  // We only have gas lossDate
  if (gasContract?.lossDate) {
    return [gasContract, null];
  }

  // We have no contracts :(
  return [null, null];
};
