import { PromoType } from './anytime-vehicles';
import { SmartChargers } from './anytime-chargers';

export const TRACKING = {
  ELIGIBILITY: {
    EVS_VOLVO_ELIGIBLE_NO_SOLAR_PANELS:
      'EVS - EVS OVO Charge Anytime Volvo eligible no solar panels',
    EVS_VOLVO_ELIGIBLE_NO_SOLAR_PANELS_WITH_PROMO:
      'EVS - EVS OVO Charge Anytime Volvo eligible no solar panels with promo code',
    EVS_VOLVO_ELIGIBLE_INDRA_AND_SOLAR_PANELS:
      'EVS - EVS OVO Charge Anytime Volvo eligible with Indra and solar panels',
    EVS_VOLVO_ELIGIBLE_HYPERVOLT_AND_SOLAR_PANELS:
      'EVS - EVS OVO Charge Anytime Volvo eligible with Hypervolt and solar panels',
    EVS_VOLVO_ELIGIBLE_ANDERSEN_AND_SOLAR_PANELS:
      'EVS - EVS OVO Charge Anytime Volvo eligible with Andersen and solar panels',
  },
};

export const getVolvoTrackingEventName = (
  isEligibleVolvo: PromoType | boolean,
  solarPanels: boolean,
  smartCharger: SmartChargers | null,
  promoCode: string
) => {
  if (!isEligibleVolvo) {
    return;
  }
  let eventName: string | undefined;
  if (solarPanels && SmartChargers.Indra === smartCharger) {
    eventName = TRACKING.ELIGIBILITY.EVS_VOLVO_ELIGIBLE_INDRA_AND_SOLAR_PANELS;
  }
  if (solarPanels && SmartChargers.Hypervolt === smartCharger) {
    eventName =
      TRACKING.ELIGIBILITY.EVS_VOLVO_ELIGIBLE_HYPERVOLT_AND_SOLAR_PANELS;
  }
  if (solarPanels && SmartChargers.Andersen === smartCharger) {
    eventName =
      TRACKING.ELIGIBILITY.EVS_VOLVO_ELIGIBLE_ANDERSEN_AND_SOLAR_PANELS;
  }

  if (!solarPanels) {
    eventName = promoCode
      ? TRACKING.ELIGIBILITY.EVS_VOLVO_ELIGIBLE_NO_SOLAR_PANELS_WITH_PROMO
      : TRACKING.ELIGIBILITY.EVS_VOLVO_ELIGIBLE_NO_SOLAR_PANELS;
  }
  return eventName;
};
