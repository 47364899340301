import { useAccountContext } from '@ovotech/ui-tools';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { createRecommendedDirectDebitEndpoint } from '@/src/constants/endpoints';
import { RecommendedDirectDebitResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `recommended-direct-debit-${string}`;

export const useRecommendedDirectDebitQuery = (
  options?: UseQueryOptions<
    RecommendedDirectDebitResponse | null,
    AxiosError,
    RecommendedDirectDebitResponse,
    QueryCacheKey
  >,
): UseQueryResult<RecommendedDirectDebitResponse> => {
  const { accountId } = useAccountContext();

  return useQuery(
    `recommended-direct-debit-${accountId}` as QueryCacheKey,
    () =>
      apiService
        .get<RecommendedDirectDebitResponse>({
          url: createRecommendedDirectDebitEndpoint(accountId ?? ''),
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 404) {
            return null;
          }
          throw err;
        }),
    {
      enabled: !!accountId,
      ...options,
    },
  );
};
