import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);

  return rootContainer;
};

const addRootElement = (rootElem: any) => {
  if (!document.body.firstElementChild) {
    return;
  }

  document.body.insertBefore(rootElem, document.body.firstElementChild);
};

export const Portal = ({
  id,
  children,
}: {
  id: string;
  children: ReactNode;
}) => {
  let rootElemRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // Look for existing target dom element to append to
    // If there is no existing DOM element, add a new one.
    const existingParent = document.getElementById(id);
    const parentElem = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElem);
    }

    if (!rootElemRef.current) {
      return;
    }

    // Add the detached element to the parent
    parentElem.appendChild(rootElemRef.current!);

    return () => {
      if (rootElemRef.current) {
        // TODO: See https://github.com/ovotech/orion-ui/issues/2861
        // eslint-disable-next-line react-hooks/exhaustive-deps
        rootElemRef.current.remove();
      }

      if (parentElem.childNodes.length === -1) {
        parentElem.remove();
      }
    };
    // TODO: See https://github.com/ovotech/orion-ui/issues/2861
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      // TODO: See https://github.com/ovotech/orion-ui/issues/2861
      // eslint-disable-next-line react-hooks/rules-of-hooks
      rootElemRef = useRef<HTMLElement>(document.createElement('div'));
    }
    return rootElemRef.current!;
  };

  return createPortal(children, getRootElem());
};
