import { Card, Heading3, P, SecondaryCTALink, Small } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import format from 'date-fns/format';
import React from 'react';
import { getNextDdPaymentDate } from '../../utils';
import { useDirectDebitQuery } from '@/src/api';
import { ErrorSection, RouterLink } from '@/src/components';
import { SummaryCard } from '@/src/components/SummaryCard';
import { PAYMENTS } from '@/src/constants/analytics';
import { CHANGE_DD_DATE_ERROR } from '@/src/constants/error-section-ids';
import { ROUTE_CHANGE_DD_DATE } from '@/src/constants/routes';

const ChangeDdDate = () => {
  const { accountId } = useAccountContext();
  const directDebit = useDirectDebitQuery(accountId);

  if (directDebit.isError) {
    return (
      <Card>
        <ErrorSection headingComponent={Heading3} id={CHANGE_DD_DATE_ERROR} />
      </Card>
    );
  }

  if (
    !directDebit?.data ||
    directDebit?.data?.dayOfMonth === undefined ||
    directDebit?.data?.dayOfMonth === null
  ) {
    return null;
  }

  const desiredPaymentDate = format(
    getNextDdPaymentDate(directDebit.data),
    'Do',
  );

  const ownsDirectDebit = directDebit?.data.customerOwnsMandate !== false;

  return (
    <SummaryCard
      icon="ovocalendar"
      heading="Direct Debit date"
      footer={
        ownsDirectDebit ? (
          <P>
            <SecondaryCTALink
              to={ROUTE_CHANGE_DD_DATE}
              as={RouterLink}
              data-event-name={PAYMENTS.CHANGE_DD_DATE}
            >
              Change Direct Debit date
            </SecondaryCTALink>
          </P>
        ) : (
          <P>
            <Small>
              Sorry, this Direct Debit can only be changed by the person who set
              it up.
            </Small>
          </P>
        )
      }
    >
      <P>
        Your payments will be taken on the {desiredPaymentDate} of each month.
      </P>
    </SummaryCard>
  );
};

export default ChangeDdDate;
