import { ErrorNotification } from '@ovotech/nebula';
import { AnalyticsRender } from '@ovotech/ui-tools';
import React from 'react';
import { EVENT_MOVE_IN_ERROR } from '../analytics';
import { ErrorMessages } from '../types/form';
import { GENERIC_PAGE_ERROR } from '@/src/constants/error-section-ids';

type ErrorProps = {
  errorMessage: string | JSX.Element;
  errorTitle: string;
  id: string;
};

function NotificationError({ errorMessage, errorTitle, id }: ErrorProps) {
  return (
    <div style={{ paddingTop: 32 }} data-testid={GENERIC_PAGE_ERROR}>
      <AnalyticsRender name={EVENT_MOVE_IN_ERROR} properties={{ id }}>
        <ErrorNotification title={errorTitle} id={id}>
          {errorMessage}
        </ErrorNotification>
      </AnalyticsRender>
    </div>
  );
}

export function AddressErrorNotification() {
  return (
    <NotificationError
      id="move-in-address-error"
      errorMessage={ErrorMessages.ADDRESS_ERROR}
      errorTitle="We’re sorry, something went wrong"
    />
  );
}

export function QuoteErrorNotification() {
  return (
    <NotificationError
      id="move-in-quote-error"
      errorTitle="Failed to obtain quote"
      errorMessage={ErrorMessages.QUOTE_FAILURE}
    />
  );
}

export function APIErrorNotification() {
  return (
    <NotificationError
      id="move-in-quote-error"
      errorTitle="We’re sorry, something went wrong"
      errorMessage={ErrorMessages.HAVING_TROUBLE}
    />
  );
}
