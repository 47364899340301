import {
  Col,
  Icon,
  P,
  Row,
  Small,
  Stack,
  TextLink,
  VisuallyHidden,
} from '@ovotech/nebula';
import { AnalyticsClick, Sandwich } from '@ovotech/ui-tools';
import { getYear, startOfToday } from 'date-fns';
import React from 'react';
import {
  StyledFooter,
  StyledFooterBottom,
  StyledFooterLogo,
  StyledFooterContent,
  StyledFooterSocialLinks,
  StyledFooterSupportingLinks,
} from './Footer.styled';
import { FOOTER_LINK } from '@/src/constants/analytics-subjects';
import { ROUTE_HELP } from '@/src/constants/routes';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import {
  FACEBOOK_OVO_ENERGY_LINK,
  HELP_OVO_ENERGY_EMERGENCY,
  INSTAGRAM_OVO_ENERGY_LINK,
  LINKEDIN_OVO_ENERGY_LINK,
  OVO_ENERGY_TERMS,
  OVO_PRIVACY_POLICY_LINK,
  TWITTER_OVO_ENERGY_LINK,
} from '@/src/constants/links';
import { ReactComponent as Logo } from '@/src/resources/logo.svg';

const year = getYear(startOfToday());

const newWindowProps = {
  rel: 'noopener',
  target: '_blank',
  opensInNewWindow: false,
};

const SocialLinks = () => {
  const LinkedInLink = ({ children }: { children?: any }) => {
    return (
      <AnalyticsClick
        name={FOOTER_LINK}
        properties={{
          linkId: 'linkedin',
        }}
      >
        <TextLink href={LINKEDIN_OVO_ENERGY_LINK} {...newWindowProps}>
          <Icon name="linkedin" size="2rem" />
          <VisuallyHidden>{children}</VisuallyHidden>
        </TextLink>
      </AnalyticsClick>
    );
  };

  const FacebookLink = ({ children }: { children?: any }) => {
    return (
      <AnalyticsClick
        name={FOOTER_LINK}
        properties={{
          linkId: 'facebook',
        }}
      >
        <TextLink href={FACEBOOK_OVO_ENERGY_LINK} {...newWindowProps}>
          <Icon name="facebook" size="2rem" />
          <VisuallyHidden>{children}</VisuallyHidden>
        </TextLink>
      </AnalyticsClick>
    );
  };

  const TwitterLink = ({ children }: { children?: any }) => {
    return (
      <AnalyticsClick
        name={FOOTER_LINK}
        properties={{
          linkId: 'twitter',
        }}
      >
        <TextLink href={TWITTER_OVO_ENERGY_LINK} {...newWindowProps}>
          <Icon name="twitter" size="2rem" />
          <VisuallyHidden>{children}</VisuallyHidden>
        </TextLink>
      </AnalyticsClick>
    );
  };

  const InstagramLink = ({ children }: { children?: any }) => {
    return (
      <AnalyticsClick
        name={FOOTER_LINK}
        properties={{
          linkId: 'instagram',
        }}
      >
        <TextLink href={INSTAGRAM_OVO_ENERGY_LINK} {...newWindowProps}>
          <Icon name="instagram" size="2rem" />
          <VisuallyHidden>{children}</VisuallyHidden>
        </TextLink>
      </AnalyticsClick>
    );
  };

  return (
    <StyledFooterSocialLinks>
      <li>
        <LinkedInLink>LinkedIn (opens in new window)</LinkedInLink>
      </li>
      <li>
        <FacebookLink>Facebook (opens in new window)</FacebookLink>
      </li>
      <li>
        <TwitterLink>Twitter (opens in new window)</TwitterLink>
      </li>
      <li>
        <InstagramLink>Instagram (opens in new window)</InstagramLink>
      </li>
    </StyledFooterSocialLinks>
  );
};

const SupportingLinks = () => {
  const EmergencyLink = ({ children }: { children?: any }) => {
    return (
      <AnalyticsClick
        name={FOOTER_LINK}
        properties={{
          linkId: 'emergency',
        }}
      >
        <TextLink href={HELP_OVO_ENERGY_EMERGENCY} opensInNewWindow>
          {children}
        </TextLink>
      </AnalyticsClick>
    );
  };

  const TermsAndConditionsLink = ({ children }: { children?: any }) => {
    return (
      <AnalyticsClick
        name={FOOTER_LINK}
        properties={{
          linkId: 'terms',
        }}
      >
        <TextLink href={OVO_ENERGY_TERMS} opensInNewWindow>
          {children}
        </TextLink>
      </AnalyticsClick>
    );
  };

  const PrivacyPolicyLink = ({ children }: { children?: any }) => {
    return (
      <AnalyticsClick
        name={FOOTER_LINK}
        properties={{
          linkId: 'privacy',
        }}
      >
        <TextLink href={OVO_PRIVACY_POLICY_LINK} opensInNewWindow>
          {children}
        </TextLink>
      </AnalyticsClick>
    );
  };

  return (
    <StyledFooterSupportingLinks>
      <li>
        <EmergencyLink>Emergency</EmergencyLink>
      </li>
      <li>
        <TermsAndConditionsLink>Terms & Conditions</TermsAndConditionsLink>
      </li>
      <li>
        <PrivacyPolicyLink>Privacy Policy & Cookies</PrivacyPolicyLink>
      </li>
    </StyledFooterSupportingLinks>
  );
};

export const Footer = () => {
  const HelpLink = ({ children }: { children?: any }) => {
    return (
      <AnalyticsClick
        name={FOOTER_LINK}
        properties={{
          linkId: 'help',
        }}
      >
        <TextLink href={ROUTE_HELP} opensInNewWindow>
          {children}
        </TextLink>
      </AnalyticsClick>
    );
  };

  return (
    <Sandwich id={sandwichIds.core.footer}>
      <StyledFooter>
        <Row>
          <Col>
            <P>
              <Small>
                Need help? You can get support on our{' '}
                <HelpLink>help centre </HelpLink>
              </Small>
            </P>
            <StyledFooterBottom>
              <StyledFooterLogo as={Logo} />
              <StyledFooterContent>
                <Stack spaceBetween={[2, 3]}>
                  <SocialLinks />
                  <SupportingLinks />
                </Stack>
                <P>
                  <Small>
                    © Copyright {year} OVO Energy Ltd. All rights reserved.
                  </Small>
                </P>
              </StyledFooterContent>
            </StyledFooterBottom>
          </Col>
        </Row>
      </StyledFooter>
    </Sandwich>
  );
};
