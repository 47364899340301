import styled from 'styled-components';
import { mqMediumUp } from '@/src/utils/mediaQuery';

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ theme: { space } }) => `
      margin-bottom: ${space[5]};
    }
  `}
`;

export const StyledCircleEV = styled.div`
  background: #197278;
  margin: 0 auto;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { space } }) => `
      margin-bottom: ${space[2]};
    }
  `}
`;

export const StyledCircleHome = styled.div`
  background: #e7a234;
  margin: 0 auto;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { space } }) => `
      margin-bottom: ${space[2]};
    }
  `}
`;

export const StyledCircleContainer = styled.div`
  ${({ theme: { space } }) => `
    ${mqMediumUp(`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: ${space[7]};
    `)}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  `}
`;

export const StyledCircleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme: { colors } }) => `
    color: ${colors.canvas};
  `};
`;

export const StyledText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { space } }) => `
      margin-top: ${space[2]};
      padding-bottom: ${space[4]};
    }
  `}
`;

export const StyledHeading = styled.h4<{ light: boolean }>`
  ${({ theme: { colors }, light }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
    color: ${light ? colors.canvas : colors.heading};

    }
  `}
`;

export const StyledStrong = styled.strong<{ light: boolean }>`
  ${({ theme: { fontSizes, colors }, light }) => `  
    display: flex;
    white-space: pre-wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: ${fontSizes[0]};
    color: ${light ? colors.canvas : colors.heading};
  `};
`;

export const StyledTotalHeading = styled.h4`
  margin-bottom: 0;
`;

export const StyledPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  ${({ theme: { fontSizes, fontWeights } }) => `
    font-size: ${fontSizes[4]};
    font-weight: ${fontWeights.semiBold};
  `};
`;

export const StyledSpan = styled.span`
  display: inline-flex;
  align-items: center;
`;

export const StyledSuperScript = styled.span`
  ${({ theme: { fonts, fontWeights } }) => `
    font-family: ${fonts.body};
    font-weight: ${fontWeights.normal};
  `}
`;

export const StyledDivContainer = styled.div`
  ${({ theme: { mediaQueries } }) => `
    display: block;
    margin-bottom: 16px;
    margin-top: 16px;
    ${mediaQueries.mediumAndUp} {
      display: block;
      margin-bottom: 0;
      margin-top: 0;
    }
  `};
`;
