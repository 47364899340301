import { useQuery } from '@ovotech/ui-tools';
import { EVS_INLIFE_ENDPOINT } from '@/src/constants/endpoints';
import { EVsInlifeVehicleDataResponse } from '@/src/types/State';
import { apiService } from '@/src/services/api-service';

export function useKaluzaChargerVehicleDataQuery(
  connection: boolean | null,
  accountId: string | null | undefined,
) {
  const url = `${EVS_INLIFE_ENDPOINT}/account/${accountId}/vehicle`;
  return useQuery(
    [accountId],
    () => {
      return apiService.get<EVsInlifeVehicleDataResponse>({
        responseType: 'json',
        url: url,
      });
    },

    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!connection,
      refetchOnWindowFocus: true,
    },
  );
}
