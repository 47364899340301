import { Card, P, Small } from '@ovotech/nebula';
import styled from 'styled-components';

export const WhatHappensNextContainer = styled(Card)`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const NextActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledSmall = styled(Small)`
  ${({ theme: { fontWeights } }) => `
    font-weight: ${fontWeights.bold};
  `}
  margin-bottom: 10px;
`;

export const StyledTitle = styled(P)`
  ${({ theme: { colors, fontWeights } }) => `
    color: ${colors.headerPrimary};
    font-weight: ${fontWeights.bold};
  `}
`;
