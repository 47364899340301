import { CTAButton } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { logout } from '@/src/api/utils/logout';
import { DELETE_COOKIE_BUTTON } from '@/src/constants/analytics-subjects';

const LOGOUT_TIMEOUT = 150;

function deleteCookies() {
  // won't delete path or httponly cookies
  const cookies = document.cookie.split(';');
  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  });
}

const DeleteCookies = ({ children }: { children?: Array<any> | string }) => {
  return (
    <AnalyticsClick name={DELETE_COOKIE_BUTTON}>
      <CTAButton
        onClick={() => {
          deleteCookies();
          setTimeout(() => {
            logout();
          }, LOGOUT_TIMEOUT);
        }}
      >
        {children}
      </CTAButton>
    </AnalyticsClick>
  );
};

export default DeleteCookies;
