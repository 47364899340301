import { useAccountContext } from '@ovotech/ui-tools';
import { useMutation, useQueryClient } from 'react-query';
import { getHubQueryKey } from './use-energy-insights-hub-query';
import { getHomeProfileWidgetQueryKey } from './use-home-profile-widget-query';
import { carbonZeroBffEndpoints } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { HomeProfileAnswers } from '@/src/pages/EnergyInsights/types';
import { getBreakdownPageQueryKey } from './use-breakdown-page-query';

export const useHomeProfileMutation = () => {
  const queryClient = useQueryClient();
  const { accountId } = useAccountContext();

  return useMutation({
    mutationKey: `home-profile-${accountId}`,
    mutationFn: (homeProfile: Partial<HomeProfileAnswers>) =>
      !accountId
        ? Promise.reject()
        : apiService.put({
            responseType: 'json',
            url: carbonZeroBffEndpoints.putHomeProfile(accountId),
            body: homeProfile,
          }),
    // Invalidate all query keys which contain home profile data when its updated
    onSuccess: () => {
      queryClient.invalidateQueries(getHomeProfileWidgetQueryKey(accountId));
      queryClient.invalidateQueries(getHubQueryKey(accountId));
      queryClient.invalidateQueries(getBreakdownPageQueryKey(accountId));
    },
  });
};
