import { Modal, P, Strong, TextLink, Ul } from '@ovotech/nebula';
import React from 'react';
import { StyledLi } from '@/src/pages/MoveIn/pages/MeterDetails/components/modal.styled';
import MPRNImage from '@/src/resources/MPRN.png';

export function WhatsMprnModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal
      title={`What's my MPRN`}
      isOpen={isOpen}
      onClose={onClose}
      data-testid="mprn-modal"
    >
      <P>
        MPRN (Meter Point Reference Number) is a 7 to 10-digit number that
        identifies your <Strong>gas supply</Strong>.
      </P>
      <Ul>
        <Strong>You can find your MPRN:</Strong>
        <StyledLi>in a recent bill</StyledLi>
        <StyledLi>on your physical gas meter</StyledLi>
        <StyledLi>
          or you can request it from your{' '}
          <TextLink
            opensInNewWindow
            href="https://www.ovoenergy.com/guides/energy-guides/dno"
          >
            District Network Operator
          </TextLink>
        </StyledLi>
      </Ul>
      <P>
        On your bill, your MPRN is the 6-digit to 10-digit number which should
        be shown on the page with the details about your gas use.
      </P>
      <img
        src={MPRNImage}
        alt="location of MPRN on bill - top left under supply address"
      />
      <P>
        Your MPRN confirms the location and structure of the supply you are on.
        It helps us to switch the correct supply and prevent switching a wrong
        one.
      </P>
    </Modal>
  );
}
