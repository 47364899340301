import { Row, Col, Heading4, P, Notification } from '@ovotech/nebula';
import React, { useState } from 'react';
import { StyledThermostatConnectedNotification } from './ThermostatConnectedNotification.styled';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';

export const ThermostatConnectedNotification = () => {
  const localStorageKey = 'thermostatConnectedNotification:viewed';
  const hasViewedConnectedNotification = localStorage.getItem(localStorageKey);
  const [isDismissed, setIsDismissed] = useState(
    !!hasViewedConnectedNotification,
  );

  if (!hasViewedConnectedNotification) {
    localStorage.setItem(localStorageKey, 'true');
  }

  const dismissConnectedNotification = () => {
    setIsDismissed(true);
  };

  return (
    <>
      {isDismissed ? null : (
        <AnalyticsWrapper
          name={
            SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.SUCCESS_NOTIFICATION.VIEWED
          }
        >
          <Notification
            id="thermostat-connected"
            variant="success"
            dismissable
            onDismiss={dismissConnectedNotification}
          >
            <Row isNested>
              <Col span={12}>
                <StyledThermostatConnectedNotification>
                  <Heading4>
                    Connected{' '}
                    <span role="img" aria-label="Celebrate">
                      🎉
                    </span>
                  </Heading4>
                  <P>
                    You’ve successsfully connected your tado° to your OVO
                    account
                  </P>
                </StyledThermostatConnectedNotification>
              </Col>
            </Row>
          </Notification>
        </AnalyticsWrapper>
      )}
    </>
  );
};
