import { Modal } from '@ovotech/nebula';
import styled from 'styled-components';

export const StyledGreenerElectricityModalContent = styled(Modal)`
  max-width: 60rem;
  width: 100%;

  ${({ theme: { mediaQueries, space } }) => `
    padding: ${space[6]};

    ${mediaQueries.smallAndUp} {
      padding-top: ${space[11]};
      padding-bottom: ${space[8]};
      padding-left: ${space[12]};
      padding-right: ${space[12]};
    };
    
    ${mediaQueries.mediumAndUp} {
      padding-top: ${space[8]};
    }
  `}
`;

export const StyledContent = styled.div`
  z-index: 10;
`;
