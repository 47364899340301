export enum OrderType {
  SelfInstall = 'TadoSelfInstall',
  ProfessionalInstall = 'TadoProInstall',
}

export enum PaymentType {
  MonthlyPayment = 'Monthly Bolt On',
  FullAmountPayment = 'Upfront Payment',
}

export type AddressFormatted = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
};
