import {
  Accordion,
  AccordionGroup,
  Heading3,
  Heading4,
  Margin,
  P,
  TextLink,
} from '@ovotech/nebula';
import React, { ReactNode } from 'react';
import {
  SMART_THERM_EMAIL_ADDRESS,
  SMART_THERM_PHONE_NUMBER,
  SMART_METERS_LINK,
  TADO_CHAT_LINK,
  TADO_GUIDE,
  TADO_SPEC_LINK,
  TADO_SUPPORT_LINK,
  TADO_TERMS_AND_CONDITIONS,
} from '@/src/constants/routes';
import { useAnalytics } from '@ovotech/ui-tools';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';

interface Props {
  isMobile: boolean;
}

function TadoLink({ children }: any) {
  return (
    <TextLink href={TADO_SPEC_LINK} opensInNewWindow>
      {children}
    </TextLink>
  );
}

function GuideLink({ children }: any) {
  return (
    <TextLink href={TADO_GUIDE} opensInNewWindow>
      {children}
    </TextLink>
  );
}

function TadoSupportLink({ children }: any) {
  return (
    <TextLink href={TADO_SUPPORT_LINK} opensInNewWindow>
      {children}
    </TextLink>
  );
}

function TadoChatLink({ children }: any) {
  return (
    <TextLink href={TADO_CHAT_LINK} opensInNewWindow>
      {children}
    </TextLink>
  );
}

function PhoneLink({ children, phoneEventName }: any) {
  return (
    <TextLink
      href={SMART_THERM_PHONE_NUMBER}
      opensInNewWindow
      data-event-name={phoneEventName ? phoneEventName : undefined}
    >
      {children}
    </TextLink>
  );
}

function EmailLink({ children, emailEventName }: any) {
  return (
    <TextLink
      href={`mailto:${SMART_THERM_EMAIL_ADDRESS}`}
      opensInNewWindow
      data-event-name={emailEventName ? emailEventName : undefined}
    >
      {children}
    </TextLink>
  );
}
function TadoTermsAndConditionsLink({ children }: any) {
  return (
    <TextLink href={TADO_TERMS_AND_CONDITIONS} opensInNewWindow>
      {children}
    </TextLink>
  );
}

function SmartMetersLink({ children }: any) {
  return (
    <TextLink href={SMART_METERS_LINK} opensInNewWindow>
      {children}
    </TextLink>
  );
}

function AnalyticsAccordion({
  title,
  children,
  headingComponent,
  ...props
}: {
  title: string;
  children: ReactNode;
  headingComponent: string | React.ComponentType<any> | undefined;
}) {
  const { dispatch } = useAnalytics();

  return (
    <Accordion
      title={title}
      headingComponent={headingComponent}
      onToggle={() =>
        dispatch({
          type: 'click',
          name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED.FAQ,
          properties: {
            question: title,
          },
        })
      }
      {...props}
    >
      {children}
    </Accordion>
  );
}

export const FAQSection = ({ isMobile }: Props) => {
  return (
    <>
      <Heading3>Questions about smart thermostats</Heading3>
      <Margin top={4}>
        <AccordionGroup style={{ padding: 0 }}>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="Which tado° Smart Thermostat will I get?"
          >
            <P>
              You'll get a{' '}
              <TadoLink>
                tado° Starter Kit: Wireless Smart Thermostat V3+
              </TadoLink>
              . It has everything you need to control your home's heating and
              hot water from anywhere.
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="How do smart thermostats work?"
          >
            <P>
              This clever tech connects your heating system to the internet, via
              your wifi. The tado° lets you do things like turn your home's
              temperature up or down, turn your hot water on or off, and set a
              daily schedule – all through an easy-to-use app.
            </P>
            <P>
              Forgotten to turn your heating off before going on holiday? All
              good. You can control your tado° from anywhere. Find out more
              about how smart thermostats work in{' '}
              <GuideLink>our handy guide</GuideLink>.
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="What's the difference between a smart meter and a smart thermostat?"
          >
            <P>
              Smart meters and smart thermostats are two separate things. A
              smart meter takes automatic readings and allows you to track your
              energy use in £ and p – so you can see where you can make changes
              and lower your bills. And a smart thermostat gives you extra
              control over your heating, from anywhere, so you can easily make
              those changes and lower those bills even more.
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="Does the tado° Smart Thermostat work with voice assistants?"
          >
            <P>
              Yes – tado° works with most voice assistants including Amazon
              Alexa, Google Assistant, and Apple HomeKit. Add tado° to your
              smart home setup to easily control your home's heating (without
              ever having to leave the sofa).
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="Is a smart thermostat right for me?"
          >
            <P>
              If you'd like to be the boss of your heating and reduce your bills
              with some easy tech – then the answer is yes!
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="How will my tado° Smart Thermostat arrive?"
          >
            <P>
              We'll post it to you free via Royal Mail. You'll get it within 3-5
              working days.
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="How do I connect my tado° to my OVO account?"
          >
            <P>
              Once your thermostat is installed, log in to your OVO account.
              Under smart devices on the left-hand menu, you'll see a smart
              thermostat option. When you click it, there'll be a button to link
              your tado°.
            </P>
            <P>
              But don't worry about remembering all of this. Our friends at
              tado° will remind you how to do it as soon as you've set your
              thermostat up.
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="What should I do if I have a problem with my thermostat?"
          >
            <P>
              If you have any issues with your thermostat, please get in touch
              with tado° first. On the tado° app, select the 'more' tab and tap
              on any one of the topics. You can also visit their help centre at{' '}
              <TadoSupportLink>support.tado.com/en</TadoSupportLink> or use
              their online chat at <TadoChatLink>tado.com/en</TadoChatLink>.
            </P>
            <P>
              For anything else, feel free to give us a call on{' '}
              <PhoneLink>+44 330 175 9691</PhoneLink>, from Monday to Friday
              9am-5pm. Or email us at{' '}
              <EmailLink>{SMART_THERM_EMAIL_ADDRESS}</EmailLink>. Our team will
              be happy to help.
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="Do I get a warranty for my smart thermostat?"
          >
            <P>
              The tado° thermostats come with a 2-year warranty. Check out
              section 13 of tado°'s{' '}
              <TadoTermsAndConditionsLink>
                terms and conditions
              </TadoTermsAndConditionsLink>{' '}
              for more information.
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="What happens if I leave OVO early?"
          >
            <P>
              If you decide to pay monthly and want to leave us before the
              12-month period, that's okay. We'll just add the remaining cost of
              the tado° to your final bill.
            </P>
          </AnalyticsAccordion>
        </AccordionGroup>
      </Margin>
      <Margin top={isMobile ? 12 : 30}>
        <Heading3>Questions about smart meters</Heading3>
        <AccordionGroup style={{ padding: 0 }}>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="What is a smart meter?"
          >
            <P>
              A smart meter is a meter that communicates with your energy
              supplier so they can receive your readings automatically. They
              measure how much energy you use, then share this info with you in
              your online account and an In-Home Display (IHD).
            </P>
            <P>
              Unlike your old meter, you can see how much energy you’re using –
              so you can make small changes to stop wasting energy, which can
              help you save money.{' '}
              <SmartMetersLink>
                Find out more about smart meters.
              </SmartMetersLink>
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="What if I don't have a gas smart meter?"
          >
            <P>
              You’ll still benefit from the cool features within the tado° app!
            </P>
          </AnalyticsAccordion>
          <AnalyticsAccordion
            headingComponent={Heading4}
            title="Do I have to get a smart meter?"
          >
            <P>You don’t have to, but it would be super great to have one.</P>
            <P>
              Thanks to its automatic half-hourly or daily readings, a gas smart
              meter will show you exactly how much it costs to heat your home.
              And right now, this is the one way we can bring you personalised
              tips on how to waste less energy.
            </P>
            <P>
              Watch this space: our team is working on finding new ways to
              change this.
            </P>
          </AnalyticsAccordion>
        </AccordionGroup>
      </Margin>
    </>
  );
};
