import { Heading3, Heading4, P, Strong } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { ActionCentreBanner, Banner } from '../../Banner';
import { CollapsibleBlock } from '../../CollapsibleBlock';
import { LossBannerCTA } from '../LossBannerCTA';
import { useContractsQuery } from '@/src/api';
import { MeterSwitch, RouterLink } from '@/src/components';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { LOSS_MESSAGE_SHOWN } from '@/src/constants/analytics';
import { ROUTE_METER_READINGS } from '@/src/constants/routes';
import {
  findContractInLoss,
  getFirstAndLastContractsInLoss,
  getOtherFuelType,
} from '@/src/utils/contracts';

const bannerId = 'lossCompleteSingleLoss';

export const LossCompleteSingleLoss: ActionCentreBanner = ({ onClick }) => {
  const { accountId } = useAccountContext();
  const { data: contracts, isLoading } = useContractsQuery(accountId);
  if (isLoading) return <SkeletonCard />;
  if (!contracts) return null;

  const electricityContract = findContractInLoss(contracts.electricity);
  const gasContract = findContractInLoss(contracts.gas);
  const [contractInLoss] = getFirstAndLastContractsInLoss(
    electricityContract,
    gasContract,
  );

  if (!contractInLoss) return null;

  const { fuel, lossDate, status } = contractInLoss;
  const otherFuel = getOtherFuelType(fuel);
  const lossCompletionDay = status.inLoss && status.lossComplete;
  const lossEvent = `Dual fuel: ${fuel} Loss ${
    lossCompletionDay ? 'Completion Day' : 'Complete'
  }, ${otherFuel} staying`;

  return (
    <AnalyticsWrapper
      name={LOSS_MESSAGE_SHOWN}
      properties={{
        lossEvent,
      }}
    >
      <Banner icon="lossComplete" data-testid="loss-single-loss">
        <Heading3 as="h2" data-testid="loss-complete-fuel">
          You&rsquo;ve switched to a new supplier for {fuel}
        </Heading3>
        <P>
          Your switch happened on{' '}
          <Strong data-testid="loss-complete-date">
            {format(new Date(lossDate as string), 'Do MMMM YYYY')}
          </Strong>
          .
        </P>
        <P data-testid="loss-complete-other-fuel">
          We&rsquo;ll keep supplying your {otherFuel} as usual.
        </P>
        <CollapsibleBlock bannerId={bannerId}>
          <P data-testid="loss-complete-switch-fuel">
            We&rsquo;ll send your final {fuel} statement within 6 weeks. This
            will include your {fuel} charges up to the date of your switch.
          </P>
          <MeterSwitch
            HasTraditional={() => (
              <>
                <Heading4 as="h3">Need to send a reading?</Heading4>
                <P>
                  Please send a reading if you haven't sent one recently to make
                  sure your final statement's accurate.{' '}
                  <RouterLink to={ROUTE_METER_READINGS}>
                    Send a reading
                  </RouterLink>
                  .
                </P>
              </>
            )}
          />
          <LossBannerCTA
            lossEvent={lossEvent}
            bannerId={bannerId}
            onClick={onClick}
          />
        </CollapsibleBlock>
      </Banner>
    </AnalyticsWrapper>
  );
};
