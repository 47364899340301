import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

export const HomeProfileFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const Step = styled.div<{ visible: boolean }>`
  ${({ visible }) => `
    display: ${visible ? 'block' : 'none'};
  `}
  align-self: center;

  legend {
    ${mqSmallDown(`
         padding-right: 10%;
    `)}
  }
`;

export const HomeProfileFormButtonNav = styled.div`
  ${({ theme: { space } }) => `
    margin-top: ${space[6]};
  `}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
