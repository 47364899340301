import React, { useState } from 'react';
import { FuelTabs } from './FuelTabs/FuelTabs';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { BreakdownPageResponse, Fuel } from '@/src/types/Response';
import AnalyticsService from '@/src/services/analyticsService';
import { HomeProfilePromptBreakdownContainer } from './HomeProfileWidget.Breakdown';
import { Stack } from '@ovotech/nebula';

export const Breakdown = (props: BreakdownPageResponse) => {
  const [fuel, setFuel] = useState(props.visibleFuelTabs[0]);

  return (
    <Stack spaceBetween={6}>
      <FuelTabs
        {...props}
        fuel={fuel}
        isSingleFuel={props.visibleFuelTabs.length === 1}
        handleFuelChange={(fuel: Fuel) => {
          setFuel(fuel);
          AnalyticsService.dispatch(
            ENERGY_INSIGHTS.spotlightsFuelTabChange(fuel),
          );
        }}
      />

      <HomeProfilePromptBreakdownContainer
        homeProfileWidget={props.homeProfileWidget}
      />
    </Stack>
  );
};
