import App from './App';
import createWrappedApp from './create-wrapped-app';
import { appStartEpic } from '@/src/redux/ducks/app/app';
import createStore from '@/src/redux/create-store';

export const store = createStore({
  epics: [appStartEpic],
});

const WrappedApp = createWrappedApp({ store, App });

export default WrappedApp;
