import { Heading3, P, SecondaryCTALink } from '@ovotech/nebula';
import React from 'react';
import { connect } from 'react-redux';
import { ActionCentreBannerProps } from '../banners';
import { Banner } from '@/src/components/ActionCentre/Banners/Banner';
import { BANNER_CTA_CLICKED } from '@/src/constants/analytics';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import { State } from '@/src/types/State';

type Props = ActionCentreBannerProps & {
  accountId: string;
};

function SmartMeterBookingOffer({ accountId, dismiss, onClick }: Props) {
  const handleDismiss = () => {
    dismiss && dismiss();
  };

  return (
    <Banner icon="smartMeter" dismiss={handleDismiss} data-testid="smb-offer">
      <Heading3 as="h2">
        Smart meters can help save energy, money and carbon
      </Heading3>
      <P>
        <SecondaryCTALink
          href={`${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=banner&utm_medium=MyOVO&utm_campaign=smart-meter-booking`}
          data-event-name={BANNER_CTA_CLICKED}
          data-event-props={JSON.stringify({
            bannerId: 'smartMeterBookingOffer',
            ctaName: 'Smart Meter Book Now',
          })}
          data-testid="booking-link"
          onClick={onClick}
        >
          Book Now
        </SecondaryCTALink>
      </P>
    </Banner>
  );
}

const mapStateToProps = ({ user: { selectedAccountId } }: State) => ({
  accountId: selectedAccountId as string,
});

export default connect(mapStateToProps)(SmartMeterBookingOffer);
