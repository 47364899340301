import styled from 'styled-components';

export const Divider = styled.div<{ dashed?: boolean }>`
  ${({ dashed, theme: { space, colors } }) => `
    width: 100%;
    height: 0;
    margin: ${space[6]} 0;
    border-bottom: 1px ${dashed ? 'dashed' : 'solid'} ${colors.primary};
  `}
`;
