import { OptimizelyFeature } from '@optimizely/react-sdk';
import { P, SecondaryCTAButton, Margin, CTALink } from '@ovotech/nebula';

import {
  AnalyticsClick,
  AnalyticsRender,
  useAccountContext,
} from '@ovotech/ui-tools';
import { isAfter, isBefore } from 'date-fns';
import React from 'react';
import {
  StyledHomePlanContainerBodyXsell,
  StyledTermsAndConditions,
} from './HomePlan.styled';
import {
  StyledImg,
  Roundel,
  StyledH4,
  StyledHeading3,
  StyledHomePlanContainer,
  StyledHomeRecoverCardLockupWrap,
  StyledLi,
  StyledUl,
} from './HomePlanFreeForFourMonthsPlusCredit.styled';
import { ActionCentreBanner } from '@/src/components/ActionCentre/Banners/Banner';
import { CTAWrapper } from '@/src/components/ActionCentre/Banners/banners.styled';
import { HOMEPLAN_ACTION_FOUR_MONTH_FREE_PLUS_CREDIT_CENTRE_BANNER } from '@/src/constants/analytics-subjects';
import {
  PROMO_FREE_FOR_FIRST_FOUR_MONTHS_ENABLED,
  ENERGY_CREDITS_PROMO,
} from '@/src/constants/feature-flags';
import { HOME_PLAN_BOILER_COVER_FREE_FOUR_MONTHS_PLUS_CREDIT_PROMO } from '@/src/constants/routes';

import {
  FREE_FOR_FOUR_OFFER_END_DATE,
  FREE_FOR_FOUR_OFFER_START_DATE,
} from '@/src/pages/Offers/constants';
import homePlanFreeForFourMonthsPlusCreditImg from '@/src/resources/homeplan-4-months-offer-plus-credit.png';
import { HomePlanFreeForFourMonths } from './HomePlanFreeForFourMonths';
import { useFeature } from '@/src/utils/optimizely';

export const HomePlanFreeForFourMonthsPlusCredit: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const { accountId } = useAccountContext();
  const [energyCreditsPromo] = useFeature(ENERGY_CREDITS_PROMO);

  const homeplanUrl =
    process.env.NX_HOMEPLAN_URL || `https://homeplan.ovoenergy.com`;

  const redirectUrl = `${homeplanUrl}?accountId=${accountId}&${HOME_PLAN_BOILER_COVER_FREE_FOUR_MONTHS_PLUS_CREDIT_PROMO}`;

  const currentDate = new Date();

  const isWithinFreeForFourMonthsOfferPeriod =
    isAfter(currentDate, FREE_FOR_FOUR_OFFER_START_DATE) &&
    isBefore(currentDate, FREE_FOR_FOUR_OFFER_END_DATE);

  return (
    <OptimizelyFeature feature={PROMO_FREE_FOR_FIRST_FOUR_MONTHS_ENABLED}>
      {isEnabled =>
        isEnabled &&
        isWithinFreeForFourMonthsOfferPeriod &&
        energyCreditsPromo ? (
          <AnalyticsRender
            name={HOMEPLAN_ACTION_FOUR_MONTH_FREE_PLUS_CREDIT_CENTRE_BANNER}
          >
            <StyledHomePlanContainer data-testid="home-plan">
              <StyledHomeRecoverCardLockupWrap>
                <Roundel data-testid="roundel">
                  <StyledH4>Double savings!</StyledH4>
                </Roundel>
                <StyledImg
                  title="Get 4 months free Boiler Cover plus £100 energy credit"
                  imgUrl={homePlanFreeForFourMonthsPlusCreditImg}
                ></StyledImg>
              </StyledHomeRecoverCardLockupWrap>
              <StyledHomePlanContainerBodyXsell>
                <StyledHeading3>
                  Get 4 months free Boiler Cover plus £100 energy credit
                </StyledHeading3>
                <P>
                  Add a Boiler Cover plan, and get these great rewards. We have
                  3 plans to choose from, all include:
                </P>
                <StyledUl>
                  <StyledLi>An annual boiler service</StyledLi>
                  <StyledLi>
                    Unlimited call outs for breakdown and repairs
                  </StyledLi>
                  <StyledLi>24/7 emergency helpline</StyledLi>
                </StyledUl>
                <Margin top={4} />
                <CTAWrapper>
                  <AnalyticsClick
                    name={
                      HOMEPLAN_ACTION_FOUR_MONTH_FREE_PLUS_CREDIT_CENTRE_BANNER
                    }
                  >
                    <CTALink
                      href={redirectUrl}
                      onClick={onClick}
                      variant="missionPrimary"
                      iconRight="off"
                    >
                      View plans
                    </CTALink>
                  </AnalyticsClick>
                  <SecondaryCTAButton onClick={dismiss}>
                    Close
                  </SecondaryCTAButton>
                </CTAWrapper>
                <StyledTermsAndConditions>
                  Terms, conditions, eligibility criteria, exclusions and
                  cancellation charges apply for each offer. No claims can be
                  made within the first 30 days. 4 months free offer expires on
                  12/12/23.
                </StyledTermsAndConditions>
              </StyledHomePlanContainerBodyXsell>
            </StyledHomePlanContainer>
          </AnalyticsRender>
        ) : (
          <HomePlanFreeForFourMonths dismiss={dismiss} onClick={onClick} />
        )
      }
    </OptimizelyFeature>
  );
};
