import React from 'react';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { Em, P, Strong } from '@ovotech/nebula';
import styled from 'styled-components';

const StyledP = styled(P)<{ inverted?: boolean }>(
  ({ theme: { colors }, inverted }) => `
  color: ${inverted ? colors.shadeLightest : colors.roundalDark};`,
);

const getRichTextOptions = (inverted: boolean): Options => {
  return {
    renderMark: {
      [MARKS.BOLD]: text => <Strong>{text}</Strong>,
      [MARKS.ITALIC]: text => <Em>{text}</Em>,
      [MARKS.CODE]: () => null,
      [MARKS.SUBSCRIPT]: () => null,
      [MARKS.SUPERSCRIPT]: () => null,
      [MARKS.UNDERLINE]: () => null,
    },
    renderNode: {
      [BLOCKS.DOCUMENT]: (_node, children) => children,
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <StyledP inverted={inverted}>{children}</StyledP>
      ),
      [BLOCKS.HEADING_1]: () => null,
      [BLOCKS.HEADING_2]: () => null,
      [BLOCKS.HEADING_3]: () => null,
      [BLOCKS.HEADING_4]: () => null,
      [BLOCKS.HEADING_5]: () => null,
      [BLOCKS.HEADING_6]: () => null,
      [BLOCKS.EMBEDDED_ENTRY]: () => null,
      [BLOCKS.EMBEDDED_ASSET]: () => null,
      [BLOCKS.EMBEDDED_RESOURCE]: () => null,
      [BLOCKS.UL_LIST]: () => null,
      [BLOCKS.OL_LIST]: () => null,
      [BLOCKS.LIST_ITEM]: () => null,
      [BLOCKS.HR]: () => null,
      [BLOCKS.QUOTE]: () => null,
      [BLOCKS.TABLE]: () => null,
      [BLOCKS.TABLE_CELL]: () => null,
      [BLOCKS.TABLE_HEADER_CELL]: () => null,
      [BLOCKS.TABLE_ROW]: () => null,

      [INLINES.ASSET_HYPERLINK]: () => null,
      [INLINES.EMBEDDED_ENTRY]: () => null,
      [INLINES.EMBEDDED_RESOURCE]: () => null,
      [INLINES.ENTRY_HYPERLINK]: () => null,
      [INLINES.HYPERLINK]: () => null,
      [INLINES.RESOURCE_HYPERLINK]: () => null,
    },
  };
};

export const getDocumentAsReactNode = (
  document: Document,
  inverted: boolean,
): React.ReactNode => {
  if (!document) return;
  const options = getRichTextOptions(!!inverted);
  return documentToReactComponents(document, options);
};
