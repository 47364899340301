import React from 'react';
import { P, AccordionGroup, Accordion, Heading3 } from '@ovotech/nebula';
import { useTescoQuestionAnswerQuery } from '@/src/api/contentful/use-tesco-question-answer-query';
import { getContentfulDocumentAsReactNode } from '../utils/getContentfulDocumentAsReactNode';
import { Document } from '@contentful/rich-text-types';
import { getFragmentData } from '@/src/api/contentful/__generated__';
import { QuestionAndAnswersLoader } from './QuestionsAndAnswersLoader';
import { TescoError } from './TescoError';
import { EntryHyperLinkFragment } from '@/src/components/Contentful/EntryHyperlink';

export const QuestionsAndAnswers = () => {
  const { isLoading, data, isError } = useTescoQuestionAnswerQuery();

  if (isLoading) return <QuestionAndAnswersLoader />;
  if (isError) return <TescoError />;
  if (!data?.buildFaQs?.structuredTextCollection?.items) return null;

  const questions = data?.buildFaQs?.structuredTextCollection?.items;

  return (
    <>
      <Heading3>Questions and answers</Heading3>
      <AccordionGroup>
        {questions?.map(question => {
          const linksFragment = getFragmentData(
            EntryHyperLinkFragment,
            question?.text?.links,
          );

          const answer = getContentfulDocumentAsReactNode(
            question?.text?.json as Document,
            linksFragment,
          );
          if (question?.header && answer) {
            return (
              <Accordion title={question?.header} key={question?.sys.id}>
                <P>{answer}</P>
              </Accordion>
            );
          }
          return null;
        })}
      </AccordionGroup>
    </>
  );
};
