import { PackCtaFragmentFragment } from '@/src/api/contentful/__generated__/graphql';
import { ROUTE_EDIT_PROFILE_MARKETING } from '@/src/constants/routes';
import { getCallToAction } from '@/src/utils/contentful/getCallToAction';
import { UrlActionMap } from '../useContentfulActionUrlMap';

export type ActionCta = {
  label: string | null | undefined;
  action: () => void;
  type?: string;
};

const actionNameToDefaultActionMap: Record<string, () => void> = {
  consentToEmailMarketingComms: () =>
    window.location.assign(ROUTE_EDIT_PROFILE_MARKETING),
};

export const getCtaActionFromUserActionType = (
  label: string | null | undefined,
  actionName: string | null | undefined,
): ActionCta | null => {
  if (!actionName) return null;
  const action = actionNameToDefaultActionMap[actionName];

  if (!action) return null;

  return {
    type: actionName,
    label,
    action,
  };
};

export const createCta = (
  cta?: PackCtaFragmentFragment | null,
  ctaBehaviour?: string | null,
  getActionByUrl?: (url: string | undefined) => UrlActionMap | undefined,
): ActionCta | null => {
  if (!cta) return null;

  if (cta?.destination?.__typename === 'PackUserAction') {
    return getCtaActionFromUserActionType(
      cta?.label,
      cta?.destination?.actionName,
    );
  }

  const callToAction = getCallToAction(cta);
  const actionByUrl = getActionByUrl && getActionByUrl(callToAction?.url);

  if (actionByUrl) {
    return {
      label: callToAction?.label,
      action: actionByUrl.action,
    };
  }

  if (callToAction?.url) {
    const target = ctaBehaviour === 'Open in new tab' ? '_blank' : '_self';
    return {
      label: callToAction?.label,
      action: () => window.open(callToAction.url, target),
    };
  }

  return null;
};
