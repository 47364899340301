import {
  Card,
  ErrorNotification,
  P,
  Heading4,
  Heading1,
  CTALink,
} from '@ovotech/nebula';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5';
import { roundTo2DP } from '@/src/utils/number';
import {
  StyledMiniCardImage,
  StyledTextUnderImage,
} from '../EVSavings/EVSavings.styled';
import {
  StyledDiv,
  StyledHeading2,
  StyledCentered,
  StyledHeadingContainer,
  StyledInlineFlex,
  StyledCTAContainer,
  StyledMarginHeading3,
  StyledMarginP,
} from './EVInsights.styled';
import CO2Tonnes from '@/src/resources/co2Tonnes.svg';
import { useSavingsAndInsightDataFromKaluza } from '@/src/api';
import { ROUTE_EV } from '@/src/constants/routes';
import { SkeletonCard } from '@/src/pages/Home/components/SkeletonCard';
import { State } from '@/src/types/State';
import { useAccountContext } from '@ovotech/ui-tools';

export const EVInsightsAndSavingsTile = () => {
  const { connection } = useSelector(({ evInlife }: State) => evInlife);
  const { accountId } = useAccountContext();
  const savingsAndInsightDataResponse = useSavingsAndInsightDataFromKaluza(
    connection,
    accountId,
  );

  const currentMonth =
    savingsAndInsightDataResponse.status === 'data' &&
    savingsAndInsightDataResponse.data.savings.currentMonth;
  const loading =
    savingsAndInsightDataResponse.status === 'no-data' ||
    savingsAndInsightDataResponse.isFetching;

  if (loading) {
    return (
      <>
        <StyledMarginHeading3>Carbon cutting progress</StyledMarginHeading3>
        <StyledMarginP>
          Want the low-down on your EV charging habits? Look no further.
        </StyledMarginP>
        <SkeletonCard />
      </>
    );
  }

  if (savingsAndInsightDataResponse.status === 'error') {
    return (
      <>
        <StyledMarginHeading3>Carbon cutting progress</StyledMarginHeading3>
        <StyledMarginP>
          Want the low-down on your EV charging habits? Look no further.
        </StyledMarginP>
        <Card>
          <ErrorNotification id="AnytimeError" title="There’s a slight problem">
            <P>
              We’re working hard to fix it. But in the meantime, please try
              refreshing the page.
            </P>
          </ErrorNotification>
        </Card>
      </>
    );
  }
  if (currentMonth) {
    return (
      <>
        <StyledMarginHeading3>Carbon cutting progress</StyledMarginHeading3>
        <StyledMarginP>
          Want the low-down on your EV charging habits? Look no further.
        </StyledMarginP>
        <Card>
          <StyledDiv>
            <StyledCentered>
              <StyledTextUnderImage>
                <Heading4>Carbon you’ve cut this month</Heading4>
              </StyledTextUnderImage>
              <StyledInlineFlex>
                <StyledMiniCardImage src={CO2Tonnes} alt="co2 icon" center />
                <StyledHeadingContainer>
                  <StyledHeading2 as={Heading1}>
                    <span data-tesid="carbon-savings">
                      {roundTo2DP(currentMonth.carbonInKg)}
                    </span>{' '}
                    kg
                  </StyledHeading2>
                </StyledHeadingContainer>
              </StyledInlineFlex>
            </StyledCentered>
            <StyledCTAContainer>
              <CTALink
                variant="primary"
                iconRight="chevron-right"
                as={Link}
                to={`${ROUTE_EV}/insights`}
                data-testid="navigate-to-insights"
              >
                See this month's insights
              </CTALink>
            </StyledCTAContainer>
          </StyledDiv>
        </Card>
      </>
    );
  } else {
    return null;
  }
};
