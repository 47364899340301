import { usageLinkWithParams } from './linkGenerators';
import {
  OVO_QUOTE_AND_SWITCH,
  RESET_PASSWORD_URL,
} from '@/src/constants/endpoints';
import {
  ROUTE_PROFILE,
  ROUTE_EDIT_PROFILE_EMAIL,
  ROUTE_EDIT_PROFILE_PHONE,
  ROUTE_EDIT_PROFILE_MARKETING,
  ROUTE_MOVING_HOME,
  ROUTE_MOVING_HOME_CONFIRM,
  ROUTE_SELECT_ACCOUNT,
  ROUTE_PLAN,
  ROUTE_RENEWAL,
  ROUTE_HELP,
  ROUTE_HOME,
  BAL_ROUTE_BILLING_DOWNLOAD_BILL,
  ROUTE_BILLING_DOWNLOAD_STATEMENT,
  ROUTE_BILLING_HISTORY,
  ROUTE_METER_READINGS_NEW,
  ROUTE_BILLING_DETAILS,
  ROUTE_METER_READINGS,
  ROUTE_PAYMENTS,
  ROUTE_CHANGE_DD_DATE,
  ROUTE_CHANGE_DD_DATE_CONFIRM,
  ROUTE_SETUP_DD,
  ROUTE_SETUP_DD_CONFIRM,
  ROUTE_MAKE_CARD_PAYMENT,
  ROUTE_MAKE_CARD_PAYMENT_STATUS,
  ROUTE_CHANGE_DD_AMOUNT,
  ROUTE_CHANGE_DD_AMOUNT_CONFIRM,
  ROUTE_OPENING_METER_READINGS,
  ROUTE_REFUNDS_CONFIRM,
  ROUTE_REFUNDS_NOT_ELIGIBLE,
  ROUTE_REFUNDS_ONE_STOP,
  ROUTE_METER_READINGS_HISTORY,
  ROUTE_MOVING_HOME_MOVE_IN,
} from '@/src/constants/routes';
import {
  DD_GUARANTEE,
  FACEBOOK_OVO_ENERGY_LINK,
  HELP_CONTACT_US_LINK,
  HELP_OVO_ENERGY_EMERGENCY,
  HELP_OVO_ENERGY_INTEREST_REWARD_CALCULATION,
  HELP_OVO_ENERGY_PAY_AS_YOU_GO,
  INSTAGRAM_OVO_ENERGY_LINK,
  LINKEDIN_OVO_ENERGY_LINK,
  OVO_ENERGY_ANYTIME_CREDIT_EXPLAINED,
  OVO_ENERGY_DEBT_AND_ENERGY_ASSISTANCE,
  OVO_ENERGY_TERMS,
  OVO_PRIVACY_POLICY_LINK,
  TWITTER_OVO_ENERGY_LINK,
} from '@/src/constants/links';
import { Links } from '@/src/types/Links';

/**
 * This object is used by the Kaluza Web components.
 */
const links: Links = {
  home: () => ROUTE_HOME,
  selectAccount: () => ROUTE_SELECT_ACCOUNT,
  profile: () => ROUTE_PROFILE,
  profileEditEmail: () => ROUTE_EDIT_PROFILE_EMAIL,
  profileEditPhone: () => ROUTE_EDIT_PROFILE_PHONE,
  profileEditMarketing: () => ROUTE_EDIT_PROFILE_MARKETING,
  plan: () => ROUTE_PLAN,
  moveOut: () => ROUTE_MOVING_HOME,
  moveOutConfirm: () => ROUTE_MOVING_HOME_CONFIRM,
  renewal: () => ROUTE_RENEWAL,
  setupRegularPayment: () => '', // TODO
  updateRegularPaymentAmount: () => '', // TODO
  contactUs: () => HELP_CONTACT_US_LINK,
  helpCentre: () => 'https://help.ovoenergy.com/',
  privacyPolicy: () => OVO_PRIVACY_POLICY_LINK,
  resetPassword: () => RESET_PASSWORD_URL as string,
  quoteAndSwitch: () => ROUTE_MOVING_HOME_MOVE_IN,
  erroneousTransferHelp: () =>
    'https://www.ovoenergy.com/help/erroneous-transfers',
  help: () => ROUTE_HELP,
  billingHistory: () => ROUTE_BILLING_HISTORY,
  billingDetails: (params?: any) =>
    params && params.periodId !== undefined && params.periodId !== null // handle '0' as periodId
      ? `${ROUTE_BILLING_DETAILS}/${params.periodId}`
      : `${ROUTE_BILLING_DETAILS}`,
  billingStatementDownload: params =>
    params && params.accountId && params.from && params.to
      ? `${BAL_ROUTE_BILLING_DOWNLOAD_BILL}/${params.accountId}?from=${params.from}&to=${params.to}`
      : `${BAL_ROUTE_BILLING_DOWNLOAD_BILL}`,
  migratedStatementDownload: params =>
    params && params.accountId && params.statementId
      ? `${ROUTE_BILLING_DOWNLOAD_STATEMENT}/account/${params.accountId}/statement/${params.statementId}`
      : ROUTE_BILLING_DOWNLOAD_STATEMENT,
  meterReadsNew: params =>
    params && params.fuelType
      ? `${ROUTE_METER_READINGS_NEW}/${params.fuelType}`
      : ROUTE_METER_READINGS_NEW,
  meterReadsHistory: params =>
    params && params.fuelType
      ? `${ROUTE_METER_READINGS_HISTORY}/${params.fuelType}`
      : ROUTE_METER_READINGS_HISTORY,
  meterReadsConfirmation: () => `${ROUTE_METER_READINGS}/confirm`,
  openingMeterReads: () => ROUTE_OPENING_METER_READINGS,
  meterReads: () => ROUTE_METER_READINGS,
  usage: params => usageLinkWithParams(params),
  payments: () => ROUTE_PAYMENTS,
  cardPayment: () => ROUTE_MAKE_CARD_PAYMENT,
  cardPaymentStatus: () => ROUTE_MAKE_CARD_PAYMENT_STATUS,
  updateDirectDebitDate: () => ROUTE_CHANGE_DD_DATE,
  updateDirectDebitDateConfirmation: () => ROUTE_CHANGE_DD_DATE_CONFIRM,
  updateDirectDebitAmount: () => ROUTE_CHANGE_DD_AMOUNT,
  updateDirectDebitAmountConfirmation: () => ROUTE_CHANGE_DD_AMOUNT_CONFIRM,
  facebook: () => FACEBOOK_OVO_ENERGY_LINK,
  twitter: () => TWITTER_OVO_ENERGY_LINK,
  instagram: () => INSTAGRAM_OVO_ENERGY_LINK,
  linkedin: () => LINKEDIN_OVO_ENERGY_LINK,
  emergency: () => HELP_OVO_ENERGY_EMERGENCY,
  termsAndConditions: () => OVO_ENERGY_TERMS,
  switchToOvo: () => OVO_QUOTE_AND_SWITCH,
  doNotCancelDirectDebit: () =>
    'https://help.ovoenergy.com/article/bf1e92b2-6cca-4833-abeb-cffbbc897aa1-orion',
  meterReadsNonCommHelp: () =>
    'https://help.ovoenergy.com/article/aaa55057-6ecf-4d51-8d9c-d2adaaacd58f-orion',
  meterReadsHowToReadSmartMeters: () =>
    'https://help.ovoenergy.com/article/24dde1a3-8d0d-4ce4-9a18-7b361d08b2ff-orion',
  usageEnergyUseHelp: () =>
    'https://help.ovoenergy.com/article/e9be7fc7-7b26-4a78-9d95-fba33535735d-orion',
  understandingYourBalance: () =>
    'https://help.ovoenergy.com/article/3a8ed44a-96c6-4312-b55a-df5484ff2e82-orion',
  interestRewardCalculation: () => HELP_OVO_ENERGY_INTEREST_REWARD_CALCULATION,
  switchingMoveOut: () =>
    'https://help.ovoenergy.com/categories?c=switching_and_moving_home',
  helpNegativeCharge: () =>
    'https://help.ovoenergy.com/article/b0c68af9-da6d-47fc-a220-56b9785d6dd1-orion',
  howBillingWorks: () =>
    'https://help.ovoenergy.com/article/b3258d11-ae37-47dc-8ead-df7e68a7a82c-orion',
  howIsInterestRewardCalculated: () =>
    HELP_OVO_ENERGY_INTEREST_REWARD_CALCULATION,
  howIsAnytimeChargingCreditCalculated: () =>
    OVO_ENERGY_ANYTIME_CREDIT_EXPLAINED,
  refundPolicyHelp: () =>
    'https://help.ovoenergy.com/article/ac4113f0-40fd-4715-a801-3f8798e41aa5-orion',
  applyForRefund: () => ROUTE_REFUNDS_ONE_STOP,
  refundConfirm: () => ROUTE_REFUNDS_CONFIRM,
  refundNotEligible: () => ROUTE_REFUNDS_NOT_ELIGIBLE,
  refundOneStop: () => ROUTE_REFUNDS_ONE_STOP,
  coronavirusUpdate: () => 'https://www.ovoenergy.com/coronavirus-update',
  coronavirusHardship: () =>
    'https://www.ovoenergy.com/coronavirus-hardship-scheme-terms-and-conditions',
  directDebitGuarantee: () => DD_GUARANTEE,
  downloadDirectDebitMandate: () =>
    'https://www.ovobyus.com/m/193f0ae8598f626b/original/direct-debit-mandate.pdf',
  goCardlessPrivacy: () => 'https://gocardless.com/legal/privacy/',
  setupDirectDebit: () => ROUTE_SETUP_DD,
  setupDirectDebitConfirmation: () => ROUTE_SETUP_DD_CONFIRM,
  paygHelp: () => HELP_OVO_ENERGY_PAY_AS_YOU_GO,
  debtAndEnergyHelp: () => OVO_ENERGY_DEBT_AND_ENERGY_ASSISTANCE,
  onboarding: () => '/onboarding',
  directDebitRequestServiceAgreement: () => '/',
  teleswitchInfo: () =>
    'https://www.ovoenergy.com/help/electric-heating/radio-tele-switching',
};

export { links };
