import { Heading2, P, Small } from '@ovotech/nebula';
import styled from 'styled-components';
import {
  mqMediumDown,
  mqMediumUp,
  mqSmallDown,
  mqSmallToMedium,
  mqSmallUp,
} from '@/src/utils/mediaQuery';

export const StyledHomePlanContainer = styled.div`
  ${({ theme: { colors, space } }) => `
    position: relative;
    background-color: ${colors.canvas};
    box-shadow: 0 2px 8px 0 rgba(12, 0, 51, 0.1);
    margin-top: ${space[4]};
    overflow: hidden;

    ${mqMediumUp(`
      padding-right: 30%;
    `)}

    &:first-of-type {
      margin-top: ${space[6]};

      ${mqSmallUp(`
      margin-top: ${space[8]};
    `)}
    }

  `}
`;

export const StyledHomePlanContainerBody = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[6]};
    width: 89%;
  `}
`;

export const StyledHomePlanContainerBodyXsell = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[6]};
    width: 89%;
  `}

  ${mqMediumDown(`
  width: 100%;
  padding-top: 0;
`)}
`;

export const BoldText = styled.strong`
  ${({ theme: { space } }) => `
    font-weight: bold;
    margin: ${space[2]} 0;
    display: block;
  `}
`;

export const StyledDefaultImageContainer = styled.div`
  width: 100%;
  height: 300px;

  ${mqMediumUp(`
      position: absolute;
      margin: 0;
      top: -8%;
      right: 1%;
      width: 30%;
      height: 110%;
    `)}

  ${mqSmallToMedium(`
    height: 300px;
    width: 300px;
    position: relative;
    left: 20%;
  `)}

  ${mqSmallDown(`
    height: 300px;
    width: 70%;
    position: relative;
    left: 15%;
  `)}
`;

export const StyledImageContainer = styled.div`
  width: 100%;
  height: 300px;

  ${mqMediumUp(`
      position: absolute;
      margin: 0;
      top: 4%;
      right: 1%;
      width: 40%;
      height: 90%;
    `)}

  ${mqSmallToMedium(`
    height: 300px;
    width: 300px;
    position: relative;
    left: 20%;
  `)}
`;

export const StyledHeading2 = styled(Heading2)`
  color: #20993e;
  width: 80%;

  ${mqMediumDown(`
  width: 100%;
`)}
`;

export const StyledParagraph = styled(P)`
  color: #20993e;
  max-width: 80%;

  ${mqMediumDown(`
  width: 100%;
`)}
`;
export const StyledTermsAndConditions = styled(P)`
  font-size: 8px;
`;

export const DefaultImage = styled.div<{
  imgUrl: string;
}>`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;

  ${({ imgUrl }) => `
    background-image: url(${imgUrl});
    background-size: 100%;
    background-position: center;
  `}
`;

export const DefaultImageIncentiveXsell = styled.div<{
  imgUrl: string;
}>`
  width: 420px;
  height: 100%;
  background-repeat: no-repeat;
  margin-left: -160px;

  ${mqMediumDown(`
  margin: auto;
  max-width: 130%;
  margin-left: -40px;
`)}

  ${({ imgUrl }) => `
    background-image: url(${imgUrl});
    background-size: 100%;
    background-position: bottom;

    ${mqMediumDown(`
    background-position: center;
  `)}
  `}
`;

export const SmallBoldText = styled(Small)`
  font-weight: bold;
`;

export const HomePlanDefaultImageContainer = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 35%;

  ${mqMediumDown(`
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    padding: 0 20%;
    text-align: center;
  `)}
`;

export const HomePlanDefaultImage = styled.img`
  transform: scale(1.5);
  transform-origin: 50% 5%;

  ${mqMediumDown(`
    max-height: 200px;
  `)}
`;
