import styled from 'styled-components';

export const StyledMaintenancePage = styled.div`
  ${({ theme: { colors } }) => `
    background-color: ${colors.canvasMuted};
    width: 70vw;
    height: 100vh;
    position: relative;
    margin-left: 15vw;
  `}
`;

export const StyledContent = styled.div`
  text-align: center;
  position: relative;
  top: 5rem;
`;
