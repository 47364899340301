import { Heading4, Small, Strong, Heading2 } from '@ovotech/nebula';
import React from 'react';
import { isMissingAnyRequestedMonthAggregateData } from '../../utils';
import { roundTo2DP } from '@/src/utils/number';
import {
  StyledCircleContainer,
  StyledCircleEV,
  StyledCircleHome,
  StyledDiv,
  StyledCircleContent,
  StyledText,
  StyledHeading,
  StyledStrong,
  StyledPrice,
  StyledSpan,
  StyledDivContainer,
  StyledTotalHeading,
  StyledSuperScript,
} from './Summary.styled';
import { EVsInlifeSummaryMonthly } from '@/src/types/State';
import { ReactComponent as CarChargingSVG } from '@/src/resources/car-charging-icon.svg';
import { ReactComponent as EnergySupplySVG } from '@/src/resources/energy-supply-icon.svg';

type UsageUnitType = 'kwh' | 'pounds';

export const MonthlySummary = ({
  usageUnit,
  monthlyData,
}: {
  usageUnit: UsageUnitType;
  monthlyData: EVsInlifeSummaryMonthly;
}) => {
  const showCostValues = usageUnit === 'pounds';

  const hasLastMonthValues = Boolean(
    monthlyData?.previousMonth.totalCost &&
      monthlyData?.previousMonth.totalUsage,
  );

  const isMissingData = isMissingAnyRequestedMonthAggregateData(
    monthlyData.requestedMonth,
    usageUnit,
  );

  if (!monthlyData || !monthlyData.requestedMonth || isMissingData) {
    return null;
  }

  return (
    <>
      <StyledDiv>
        <StyledTotalHeading as={Heading4}>
          <StyledSpan>{`Total electricity ${
            showCostValues ? 'cost' : 'usage'
          }`}</StyledSpan>
          <StyledSuperScript>{`${
            showCostValues ? '¹' : ''
          }`}</StyledSuperScript>
        </StyledTotalHeading>
        <StyledPrice as={Heading4}>
          {showCostValues
            ? `£${roundTo2DP(monthlyData.requestedMonth.totalCost as number)}` // must be number here due to isMissingAnyRequestedMonthAggregateData check
            : `${roundTo2DP(
                monthlyData.requestedMonth.totalUsage as number,
              )} kWh`}
        </StyledPrice>
        <Small>
          {hasLastMonthValues && (
            <Strong data-testid="last-month-electricity-total">
              {showCostValues
                ? `(compared to £${monthlyData?.previousMonth.totalCost} last month)`
                : `(compared to ${monthlyData?.previousMonth.totalUsage} kWh last month)`}
            </Strong>
          )}
        </Small>
      </StyledDiv>
      <StyledCircleContainer>
        <StyledDivContainer>
          <StyledCircleEV>
            <StyledCircleContent>
              <CarChargingSVG />
              <StyledHeading as={Heading2} light={true}>
                {showCostValues
                  ? `£${roundTo2DP(
                      monthlyData?.requestedMonth.electricVehicle.cost || 0,
                    )}`
                  : `${roundTo2DP(
                      monthlyData?.requestedMonth.electricVehicle.usageInKWH ||
                        0,
                    )} kWh`}
              </StyledHeading>
              {hasLastMonthValues && (
                <StyledStrong
                  data-testid="last-month-vehicle-total"
                  as={Strong}
                  light={true}
                >
                  {showCostValues
                    ? `(compared to £${roundTo2DP(
                        monthlyData?.previousMonth.electricVehicle.cost || 0,
                      )}\nlast month)`
                    : `(compared to ${roundTo2DP(
                        monthlyData?.previousMonth.electricVehicle.usageInKWH ||
                          0,
                      )}\nkWh last month)`}
                </StyledStrong>
              )}
            </StyledCircleContent>
          </StyledCircleEV>
          <StyledText as={Heading4}>{`Your car's ${
            showCostValues ? 'costs' : 'usage'
          }`}</StyledText>
        </StyledDivContainer>
        <StyledDivContainer>
          <StyledCircleHome>
            <StyledCircleContent>
              <EnergySupplySVG />
              <StyledHeading as={Heading2} light={false}>
                {showCostValues
                  ? `£${roundTo2DP(monthlyData?.requestedMonth.home.cost || 0)}`
                  : `${roundTo2DP(
                      monthlyData?.requestedMonth.home.usageInKWH || 0,
                    )} kWh`}
              </StyledHeading>
              {hasLastMonthValues && (
                <StyledStrong
                  as={Strong}
                  light={false}
                  data-testid="last-month-home-total"
                >
                  {showCostValues
                    ? `(compared to £${roundTo2DP(
                        monthlyData?.previousMonth.home.cost || 0,
                      )}\nlast month)`
                    : `(compared to ${roundTo2DP(
                        monthlyData?.previousMonth.home.usageInKWH || 0,
                      )}\nkWh last month)`}
                </StyledStrong>
              )}
            </StyledCircleContent>
          </StyledCircleHome>
          <StyledText as={Heading4}>{`Your home's ${
            showCostValues ? 'costs' : 'usage'
          }`}</StyledText>
        </StyledDivContainer>
      </StyledCircleContainer>
    </>
  );
};
