import addMonths from 'date-fns/add_months';
import setDate from 'date-fns/set_date';
import startOfToday from 'date-fns/start_of_today';
import { getEpochDaysDate } from '../../utils/date';

export const getNextDdPaymentDate = ({
  dayOfMonth,
  lockedInPayment,
}: {
  dayOfMonth?: number;
  lockedInPayment?: { date: number };
}) => {
  if (lockedInPayment) {
    return getEpochDaysDate(lockedInPayment.date);
  }

  const today = startOfToday();
  const ddDateThisMonth = setDate(today, dayOfMonth!);

  return ddDateThisMonth < today
    ? addMonths(ddDateThisMonth, 1)
    : ddDateThisMonth;
};

export const formatCurrencyAmountAsPence = (amount: string) => {
  const [pounds, pence] = `${amount}.00`.split('.');
  return Number(pounds) * 100 + Number(pence);
};
