import { useQuery, UseQueryResult } from 'react-query';
import { createAcquisitionAddressUrl } from '@/src/pages/MoveIn/api/urls';
import {
  AddressWithSupplyPoint,
  AddressWithSupplyPointResponse,
} from '@/src/pages/MoveIn/types/addressRequest';
import { apiService } from '@/src/services/api-service';

export function useGetAcquisitionAddress(
  postcode: string,
): UseQueryResult<AddressWithSupplyPoint[]> {
  const url = createAcquisitionAddressUrl(postcode);

  return useQuery(
    ['move-in-address', postcode],
    () =>
      apiService
        .get<AddressWithSupplyPointResponse>({ url, withCredentials: false })
        .then(response =>
          response.map(r => ({
            ...r,
            postcode,
          })),
        ),
    {
      enabled: postcode !== '',
    },
  );
}
