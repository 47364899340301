import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { createGetDirectDebitEndpoint } from '@/src/constants/endpoints';
import { DirectDebitResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `cx-direct-debit-${string}`;

export const useDirectDebitQuery = (
  accountId: string | null | undefined,
  options?: UseQueryOptions<
    DirectDebitResponse,
    unknown,
    DirectDebitResponse,
    QueryCacheKey
  >,
): UseQueryResult<DirectDebitResponse> =>
  useQuery(
    `cx-direct-debit-${accountId}` as QueryCacheKey,
    () =>
      apiService.get<DirectDebitResponse>({
        url: createGetDirectDebitEndpoint(accountId ?? ''),
      }),
    {
      enabled: !!accountId,
      ...options,
    },
  );
