import { Col, Row } from '@ovotech/nebula';
import React, { ComponentProps } from 'react';

import { DownloadLink } from '../DownloadLink';
import {
  ContentContainer,
  ContentRow,
  HeaderRow,
  LinkCol,
  PageContainer,
} from './styled';
import { Page } from '@/src/components/Pages/Pages';
import RenewalHeader from '@/src/pages/Renewal/ovo-retail/RenewalHeader/RenewalHeader';

export function PageWrapper({
  children,
  ...rest
}: ComponentProps<typeof Page>) {
  return (
    <Page {...rest}>
      <PageContainer>
        <Row as={HeaderRow}>
          <Col span={4}>
            <RenewalHeader />
          </Col>
          <Col as={LinkCol} span={8}>
            <DownloadLink />
          </Col>
        </Row>
        <Row as={ContentRow}>
          <Col>
            <ContentContainer id="main">{children}</ContentContainer>
          </Col>
        </Row>
      </PageContainer>
    </Page>
  );
}
