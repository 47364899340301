import React from 'react';
import { StatusBlockContainer } from './styles';
import { Margin } from '@ovotech/nebula';
import { ROUTE_SETUP_DD } from '@/src/constants/routes';
import { StatusBlockProps } from './types';
import { StatusContent, StatusMessage } from './StatusComponents';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { TESCO_EVENTS } from '@/src/constants/analytics';

const onClickHandler = () => {
  window.location.assign(ROUTE_SETUP_DD);
};

export const DirectDebitStatus = ({ status }: StatusBlockProps) => {
  return (
    <StatusBlockContainer>
      <StatusMessage title="Pay by Direct Debit" status={status} />
      {status === 'ineligible' && (
        <Margin top={2}>
          <AnalyticsClick name={TESCO_EVENTS.CLICKED_UPDATE_PAYMENT_METHOD}>
            <StatusContent
              onClick={onClickHandler}
              buttonName="Set up Direct Debit"
              placeholderText="Setting this up can take up to 5 working days to update."
            />
          </AnalyticsClick>
        </Margin>
      )}
    </StatusBlockContainer>
  );
};
