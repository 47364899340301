import { P } from '@ovotech/nebula';
import React from 'react';
import { StyledSkeletonText } from './SkeletonLoading.styled';

type Props = Omit<JSX.IntrinsicElements['p'], 'ref'> & {
  lines?: number;
};

export const SkeletonText = ({ lines = 1, ...rest }: Props) => {
  const generatedLines = [];

  for (let i = 1; i <= lines; i++) {
    generatedLines.push(<StyledSkeletonText key={`line-${i}`} />);
  }

  return <P {...rest}>{generatedLines}</P>;
};
