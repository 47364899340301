import { Card, Li, P, Strong, TextLink, Ul } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { TADO_TEL, TADO_EMAIL, TADO_FAQ } from '../constants';
import { SMART_THERM_EMAIL_ADDRESS } from '@/src/constants/routes';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';

const Container = styled.div`
  padding: 30px;

  & p {
    margin: 1.5rem 0 3rem 0;
  }
`;

export const GotAQuestion = () => {
  return (
    <Container as={Card}>
      <Strong>Got a question?</Strong>
      <P>Let’s find an answer! You can...</P>
      <Ul>
        <Li>
          Check out our{' '}
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.CLICKED.FAQ
            }
          >
            <TextLink href={TADO_FAQ}>Frequently Asked Questions</TextLink>
          </AnalyticsClick>
        </Li>
        <Li>
          Email us on{' '}
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.CLICKED
                .TO_EMAIL
            }
          >
            <TextLink href={TADO_EMAIL}>{SMART_THERM_EMAIL_ADDRESS}</TextLink>
          </AnalyticsClick>
        </Li>
        <Li>
          Call us on{' '}
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GOT_YOUR_ORDER.CLICKED
                .TO_CALL
            }
          >
            <TextLink href={TADO_TEL}>+44 330 175 9691</TextLink>
          </AnalyticsClick>{' '}
          (Monday to Friday 9am–5pm)
        </Li>
      </Ul>
    </Container>
  );
};
