import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { makeActionError, makeActionStart, makeActionSuccess } from '../http';
import {
  createGetShowSmartMeterBookingBannerEndpoint,
  createGetSmartMeterBookingEndpoint,
} from '@/src/constants/endpoints';
import apiService from '@/src/services/apiService';
import {
  Action,
  FETCH_ACTION_KEY,
  GetShowSmartMeterBookingBannerError,
  GetShowSmartMeterBookingBannerStart,
  GetShowSmartMeterBookingBannerSuccess,
  GetSmartMeterBookingError,
  GetSmartMeterBookingStart,
  GetSmartMeterBookingSuccess,
} from '@/src/types/Action';
import {
  ShowSmartMeterBookingBannerResponse,
  SmartMeterBookingResponse,
} from '@/src/types/Response';
import { SmartMeterBookingState } from '@/src/types/State';

export const getShowSmartMeterBookingBannerStart =
  makeActionStart<GetShowSmartMeterBookingBannerStart>(() => ({
    type: 'GET_SHOW_SMART_METER_BOOKING_BANNER_START',
  }));

export const getShowSmartMeterBookingBannerSuccess = makeActionSuccess<
  GetShowSmartMeterBookingBannerSuccess,
  [ShowSmartMeterBookingBannerResponse | null]
>(data => ({
  type: 'GET_SHOW_SMART_METER_BOOKING_BANNER_SUCCESS',
  data,
}));

export const getShowSmartMeterBookingBannerError =
  makeActionError<GetShowSmartMeterBookingBannerError>(() => ({
    type: 'GET_SHOW_SMART_METER_BOOKING_BANNER_ERROR',
  }));

export const getShowSmartMeterBookingBannerEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) =>
  // @ts-ignore Typescript does not like action$.ofType
  action$
    .ofType('GET_SHOW_SMART_METER_BOOKING_BANNER_START')
    .exhaustMap(
      ({ [FETCH_ACTION_KEY]: id }: GetShowSmartMeterBookingBannerStart) => {
        const accountId = store.getState().user.selectedAccountId;
        if (accountId) {
          return apiService
            .get({
              responseType: 'json',
              url: createGetShowSmartMeterBookingBannerEndpoint(accountId),
            })
            .map(({ response }) =>
              getShowSmartMeterBookingBannerSuccess(id, response),
            )
            .catch(err =>
              Observable.of(getShowSmartMeterBookingBannerError(id, err)),
            );
        } else {
          return Observable.of({
            type: 'GET_SHOW_SMART_METER_BOOKING_BANNER_ERROR',
          });
        }
      },
    );

export const getSmartMeterBookingStart =
  makeActionStart<GetSmartMeterBookingStart>(() => ({
    type: 'GET_SMART_METER_BOOKING_START',
  }));

export const getSmartMeterBookingSuccess = makeActionSuccess<
  GetSmartMeterBookingSuccess,
  [SmartMeterBookingResponse | null]
>(data => ({
  type: 'GET_SMART_METER_BOOKING_SUCCESS',
  data,
}));

export const getSmartMeterBookingError =
  makeActionError<GetSmartMeterBookingError>(() => ({
    type: 'GET_SMART_METER_BOOKING_ERROR',
  }));

export const getSmartMeterBookingEpic = (
  action$: ActionsObservable<Action>,
  store: Store,
) => {
  return (
    // @ts-ignore Typescript does not like action$.ofType
    action$
      .ofType('GET_SMART_METER_BOOKING_START')
      .exhaustMap(({ [FETCH_ACTION_KEY]: id }: GetSmartMeterBookingStart) => {
        const accountId = store.getState().user.selectedAccountId;

        if (accountId) {
          return apiService
            .get({
              responseType: 'json',
              url: createGetSmartMeterBookingEndpoint(accountId),
            })
            .map(({ response }) => getSmartMeterBookingSuccess(id, response))
            .catch(err => Observable.of(getSmartMeterBookingError(id, err)));
        } else {
          return Observable.of({
            type: 'GET_SMART_METER_BOOKING_ERROR',
          });
        }
      })
  );
};
export const defaultSmartMeterBookingState: SmartMeterBookingState = {
  showBanner: false,
  data: null,
  isFetching: false,
};

const smartMeterBooking = (
  state: SmartMeterBookingState = defaultSmartMeterBookingState,
  action: Action,
) => {
  switch (action.type) {
    case 'GET_SMART_METER_BOOKING_START':
      return {
        ...state,
        isFetching: true,
      };
    case 'GET_SMART_METER_BOOKING_SUCCESS':
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case 'GET_SMART_METER_BOOKING_ERROR':
      return {
        ...state,
        isFetching: false,
        data: null,
      };
    case 'GET_SHOW_SMART_METER_BOOKING_BANNER_SUCCESS':
      return {
        ...state,
        showBanner: !!(action.data && action.data.showBanner),
      };
    default:
      return state;
  }
};

export default smartMeterBooking;
