import { Heading4, P, Strong, VisuallyHidden } from '@ovotech/nebula';
import React from 'react';
import { carbonIntensityAssets } from './CurrentIntensity.assets';
import {
  StyledCarbonIntensityCurrent,
  StyledCarbonIntensityCurrentDiv,
  StyledCurrentIntensityFooter,
} from './CurrentIntensity.styled';
import { CarbonIntensityLevel } from '../../../../types/carbon-intensity.types';

type Props = {
  intensity: CarbonIntensityLevel;
};

const CarbonIntensityCurrent = ({ intensity }: Props) => {
  const { topText, subText, icon, indicatorBadge } =
    carbonIntensityAssets[intensity];
  return (
    <StyledCarbonIntensityCurrent data-testid="carbon-intensity-current">
      <StyledCarbonIntensityCurrentDiv cardColour={intensity}>
        <div>
          <Heading4>{topText}</Heading4>
          <P>{subText}</P>
        </div>
        {icon}
        <StyledCurrentIntensityFooter>
          <Strong>
            Carbon Intensity on the national grid is
            <VisuallyHidden> {intensity}</VisuallyHidden>
          </Strong>
          {indicatorBadge}
        </StyledCurrentIntensityFooter>
      </StyledCarbonIntensityCurrentDiv>
    </StyledCarbonIntensityCurrent>
  );
};

export default CarbonIntensityCurrent;
