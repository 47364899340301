import { Heading3 } from '@ovotech/nebula';
import styled from 'styled-components';
import { ReactComponent as ChevronLeft } from '@/src/resources/chevron-left.svg';
import { ReactComponent as ChevronRight } from '@/src/resources/chevron-right.svg';

const borderColour = '#F3F3F3';
const cardBackgroundColour = '#FFFFFF';

export const MonthNavSection = styled.div`
  background: ${cardBackgroundColour};
  border-radius: 0.4rem;
  border: 0.1rem solid ${borderColour};
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: baseline;
  justify-content: center;
  ${({ theme: { space } }) => `
      margin-bottom: ${space[1]};
    }
  `}
`;

const chevronWidth = '13.6px';
const chevronHeight = '24px';

export const NavChevronRight = styled(ChevronRight)`
  fill: currentColor;
  width: ${chevronWidth};
  height: ${chevronHeight};
`;

export const NavChevronLeft = styled(ChevronLeft)`
  fill: currentColor;
  width: ${chevronWidth};
  height: ${chevronHeight};
`;

export const MonthlyNavHeading = styled(Heading3)`
  padding: 0 16px 6px;
`;
