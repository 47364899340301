import { P, SecondaryCTALink, Strong } from '@ovotech/nebula';
import styled from 'styled-components';
import greenBackgroundSquaresDesktop from '@/src/resources/offers/green-background-squares-desktop.svg';
import greenBackgroundSquaresMobile from '@/src/resources/offers/green-background-squares-mobile.svg';

export const StyledDivContainer = styled.div`
  ${({ theme: { mediaQueries, space, colors } }) => `
    display: block;
    margin-top: ${space[4]};
    margin-bottom: ${space[5]};
    padding: 35px 65px 35px 19px;

    border: 5px ${colors.brand.white} solid;
    border-radius: ${space[1]};

    background-image: ${`url(${greenBackgroundSquaresMobile})`}, radial-gradient(circle, #1DB637 0%, #0A9026 100%);
    background-size: contain;
    background-position-x: left;
    background-position-y: top;
    background-repeat: no-repeat;

    ${mediaQueries.smallAndUp} {
      margin-top: 23px;
      margin-bottom: 31px;
      padding: 35px;

      background-image: ${`url(${greenBackgroundSquaresDesktop})`}, radial-gradient(circle, #1DB637 0%, #0A9026 100%);
      background-position-x: right;
    }
  `};
`;

export const StyledHeading3 = styled(Strong)`
  ${({ theme: { colors, space, mediaQueries } }) => `
    font-size: ${space[8]};
    line-height: ${space[8]};
    letter-spacing: -4%;
    color: ${colors.brand.white};

    ${mediaQueries.smallAndUp} {
      font-size: 35px;
      line-height: 35px;
      letter-spacing: -1%;
    }
  `}
`;

export const StyledP = styled(P)`
  ${({ theme: { colors, space } }) => `
    margin-bottom: ${space[6]};
    font-size: ${space[5]};
    line-height: ${space[6]};
    letter-spacing: -2%;
    color: ${colors.brand.white};
  `}
`;

export const StyledSecondaryCTALink = styled(SecondaryCTALink)`
  ${({ theme: { fontWeights } }) => `
    width: unset;
    font-weight: ${fontWeights.normal};
  `}
`;
