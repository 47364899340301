export default {
  greenlightBanner: {
    banner: 'greenlight-banner',
    link: 'greenlight-banner-cta',
  },
  features: {
    green: 'feature-green',
    spotlight: 'feature-spotlights',
    spotlightCTA: 'feature-spotlights-cta',
    trees: 'feature-trees',
    habitats: 'feature-habitats',
    forests: 'feature-forests',
    onDemand: 'feature-ondemand',
    bowerCTA: 'feature-bower',
    carbonNeutral: 'feature-carbon-neutral',
    energySpotlights: 'feature-energySpotlights',
    tinyForest: 'feature-tiny-forests',
    treePlanting: 'feature-tree-planting',
    renewableGeneration: 'renewable-generation',
    traceableElectricity: 'traceable-electricity',

    spotlights: {
      defaultDesc: 'spotlights-desc-default',
      noMeterDesc: 'spotlights-desc-no-meter',
    },

    button: 'tile-with-clipped-image-button',
  },
  header: {},
  offer: {
    offer: 'offer',
    close: 'offer-dismiss',
    cta: 'offer-cta',
  },
  signUp: {
    confirmation: {
      wrapper: 'signup-confirmation',
      cta: 'signup-confirmation-cta',
    },
    loading: 'signup-loading',
    error: 'signup-error',
    activationError: 'activation-error',
    covidAlert: 'covid-alert',
    promoAlert: 'promo-alert',
    tadoPromo: 'signup-tado-promo',
  },
  terms: {},
  zone: {
    loadingSection: 'zone-loading',
    headerTitle: 'zone-title',
    sectionWrapper: 'zone-section',
    promotionalBannerST: 'zone-promo-smart-thermostat',
  },
};
