import { Margin } from '@ovotech/nebula';
import { AnalyticsData } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom-v5';

import { PageWithNavigation, SwitchWithNotFound } from '@/src/components';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { useHasThermostatBolton } from '../hooks/useHasThermostatBolton';
import { MyThermostatsPage } from './MyThermostatPage';

function defaultDateRedirectPath(path: string, date: Date): string {
  const defaultDatePath = `${path}/${format(date, 'YYYY/MM')}`;
  return defaultDatePath;
}

const SmartThermostatsInLife = () => {
  const { hasBolton, isLoading } = useHasThermostatBolton();
  const { path } = useRouteMatch();

  return (
    <AnalyticsData
      data={{
        project: {
          team: 'THERM',
          section: 'in-life',
        },
      }}
    >
      {isLoading ? (
        <PageWithNavigation title="Loading - My Thermostat">
          <Margin top={8}>
            <SkeletonCard />
          </Margin>
        </PageWithNavigation>
      ) : (
        <SwitchWithNotFound>
          {hasBolton && (
            <>
              <Route
                exact
                path={`${path}/:year(\\d{4})/:month(\\d{2})`}
                component={MyThermostatsPage}
              />
              <Redirect
                to={{ pathname: defaultDateRedirectPath(path, new Date()) }}
              />
            </>
          )}
        </SwitchWithNotFound>
      )}
    </AnalyticsData>
  );
};

export default SmartThermostatsInLife;
