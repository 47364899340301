import React from 'react';
import { StyledContent } from './CarbonIntensityFooter.styled';
import { IntensityLevel } from './IntensityLevel';
import { CarbonIntensityLevel } from '../../../../types/carbon-intensity.types';

const CarbonIntensityFooter = () => (
  <div>
    <StyledContent>
      <IntensityLevel
        intensity={CarbonIntensityLevel.Low}
        text="Low carbon / Plug in"
      ></IntensityLevel>
      <IntensityLevel
        intensity={CarbonIntensityLevel.Moderate}
        text="Moderate carbon / Unplug"
      ></IntensityLevel>
      <IntensityLevel
        intensity={CarbonIntensityLevel.High}
        text="High carbon / Unplug"
      ></IntensityLevel>
    </StyledContent>
  </div>
);

export default CarbonIntensityFooter;
